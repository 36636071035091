import { BaseHelper } from './BaseGraphQLAPIHelper';

const PROGRESSIVE_PROFILING_NODE = `{
  id
  pk
  part1LifetimePoints
  part2LifetimePoints
}`;

export const getProgressiveProfilingLifetimePoints = (id) => {
  const query = `{
    customerProgressiveProfilingLifetimePoints(id: "${id}") ${PROGRESSIVE_PROFILING_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

const updateProgressiveProfilingLifetimePointsContent = `
  node ${PROGRESSIVE_PROFILING_NODE}
  errors {
    field
    messages
  }
`;

export const updateProgressiveProfilingLifetimePoints = (values) => {
  let query = `mutation UpdateCustomerProgressiveProfilingLifetimePoints($input: UpdateCustomerProgressiveProfilingLifetimePointsInput!) {
      updateCustomerProgressiveProfilingLifetimePoints(input: $input) {
        ${updateProgressiveProfilingLifetimePointsContent}
      }
    }
    `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
