import React from 'react';
import { connect } from 'react-redux';
import ContentSections from '../../../components/base/ContentSections';
import Loading from '../../../components/base/Loading';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { APIStatus } from '../../../config/CustomEnums';

const RewardCodeOverview = ({
  rewardCodeDetail,
  rewardCodeAPIStatus,
}) => {

  return (
    <ContentSections
      sections={
        rewardCodeAPIStatus === APIStatus.calling
          ? [<Loading />]
          : [
              <MembershipInfoCard
                title="information"
                data={rewardCodeDetail}
                fields={[
                  [{ title: 'Status', field: 'status' }, { title: 'Used Codes/Total Codes', field: 'usedCodesWithTotalCodes' }],
                  [{ title: 'Code Type', field: 'displayCodeType' }, { title: 'Reward Type', field: 'displayRewardType' }],
                  [{ title: 'No Of Points', field: 'points' }, { title: 'Related Brand', field: 'brandName' }],
                  [{ title: 'Coupon Set', field: 'couponName' }, { title: 'Quantity', field: 'couponQuantity' }],
                  [{ title: 'Active Period', field: 'displayActivePeriod' }, { title: 'Create At', field: 'overviewCreationDate' }],
                  [{ title: 'Last Modified ', field: 'overviewLastModifiedDate' }],
                ]}
              />,
            ]
      }
      hidePreview
    />
  );
}

const mapStateToProps = (state) => ({
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
  rewardCodeAPIStatus: state.rewardCode.rewardCodeAPIStatus,
});

export default connect(mapStateToProps)(RewardCodeOverview);
