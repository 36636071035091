import React, { useEffect, useState } from 'react';
import CustomEditor from './CustomEditor';
import './CustomMarkDownArea.scss';
import ErrorFieldMessage from './ErrorFieldMessage';
import { isLongScreen } from '../../utils/ScreenUtil';
import CustomTips from './CustomTips';

function CustomMarkDownArea(props) {
  const areaTitle = props.areaTitle;
  const titleMaxLength = props.title?.maxLength;
  const titleShowCount = props.title?.showCount;
  const titleLabel = props.title.label;
  const titleTips = props.title.tips;
  const titleValue = props.title.value;
  const titleValueChange = props.title.valueChange || (() => {});
  const titleFocus = props.title.focus || (() => {});
  const titleError = props.title.error;
  const titleErrorMessage = props.title.errorMessage;
  const titleErrorId = props.title.errorId;

  // const contentMaxLength = props.content?.maxLength;
  // const contentShowCount = props.content?.showCount;
  const contentLabel = props.content.label;
  const contentTips = props.content.tips;
  const contentInitValue = props.content.value;
  const contentValueChange = props.content.valueChange || (() => {});
  const contentFocus = props.content.focus || (() => {});
  const contentError = props.content.error;
  const contentErrorMessage = props.content.errorMessage;
  const contentErrorId = props.content.errorId;

  const [titleInput, setTitleInput] = useState();
  const [titleLength, setTitleLength] = useState(titleValue?.length || 0);
  // const [contentLength, setContentLength] = useState(contentInitValue?.length || 0);
  // const [resetContentValue, setResetContentValue] = useState(false);

  useEffect(() => {
    setTitleInput(titleValue);
    setTitleLength(titleValue?.length || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleValue]);

  const labelText = (labelText, className) => {
    return (
      <label className={`create-section-label ${className}`}>{labelText}</label>
    );
  };

  const getLabel = (tips, label) => {
    if (!tips) {
      return labelText(label, 'create-section-label-bottom-space');
    }
    return (
      <div className="create-section-label-bottom-space">
        <CustomTips detail={tips.text} innerImage={tips.image}>
          {labelText(label)}
        </CustomTips>
      </div>
    );
  };
  return (
    <>
      <label className="create-section-title">{areaTitle}</label>
      {getLabel(titleTips, titleLabel)}
      <div 
        className="custom-markdown-area-title-with-count"
        onFocus={() => titleFocus()}
      >
        <input
          type="text"
          onChange={({ target }) => {
            setTitleInput(target.value);
            setTitleLength(target.value?.length);
            titleValueChange(target.value);
          }}
          value={titleInput || ''}
          className={`custom-markdown-area-title-with-count-input ${
            titleError ? 'error-field' : ''
          } ${isLongScreen ? '' : 'custom-markdown-area-title-short'}`}
          maxLength={titleMaxLength}
        />
        {titleShowCount && titleMaxLength
          ? <span className="custom-markdown-area-title-count">
              {`${titleLength} / ${titleMaxLength}`}
          </span>
          : null
        }
      </div>
      <ErrorFieldMessage
        id={titleErrorId}
        error={titleError}
        message={titleErrorMessage}
      />
      {getLabel(contentTips, contentLabel)}
      <CustomEditor
        initialValue={contentInitValue}
        onValueChange={(data) => contentValueChange(data)}
        // resetValue={resetContentValue}
        // resetValueAction={() => setResetContentValue(false)}
        // onValueChange={(data) => {
        //   if (contentMaxLength && data?.length > contentMaxLength) {
        //     setResetContentValue(true);
        //     return;
        //   }
        //   setContentLength(data?.length);
        //   contentValueChange(data);
        // }}
        onFocus={() => contentFocus()}
        errorMessage={contentErrorMessage}
        error={contentError}
      />
      {/* {contentShowCount && contentMaxLength
        ?<span className="custom-markdown-area-content-count">
            <span className="custom-markdown-area-content-count-number">
              {`${contentLength} / ${contentMaxLength}`}
            </span>
        </span>
        : null
      } */}
      {contentErrorId ? <span id={contentErrorId} /> : null}
    </>
  );
}

export default CustomMarkDownArea;
