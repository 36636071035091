import React, { useState, useEffect } from 'react';
import '../../../components/campaign/campaignCreation/CreateCampaignStepTwo.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  CampaignType,
  LanguageConfig,
  EarningRuleType,
} from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import OverviewSection from '../../../components/earning/OverviewSection';
import DetailSection from '../../../components/campaign/campaignCreation/DetailSection';
import InstructionSection from '../../../components/campaign/campaignCreation/InstructionSection';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../../../components/base/BottomStepComponent';
import {
  defaultPreviewImage,
  earningRulesCampaignPreview,
} from '../../../components/campaign/campaignCreation/CampaignStepTwoPreviewConfig';
import { getObjectFromSessionStorage } from '../../../utils';
import { isSpecificEarningRule } from '../../../models/CreateEarningRulesModel';

let dataArray = [];

function CreateEarningRuleStepTwo(props) {
  const dispatch = useDispatch();

  const inputWithImageConfig = earningRulesCampaignPreview;
  const defaultImage = defaultPreviewImage(CampaignType.earningRulesCampaign);
  const tempEarningRule = getObjectFromSessionStorage('tempEarningRule') || [];

  const [error, setError] = useState(false);
  const [activeSection, setActiveSection] = useState();
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });

  const { errorFields, earningRuleType } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
    earningRuleType: state.createEarningRules.earningRule.type,
  }));
  const isSpecificEarning = isSpecificEarningRule(earningRuleType);
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (!onCheck.value) {
      dataArray = [];
      setError(errorFields.length > 0);
    } else {
      let formatedData = {
        [LanguageConfig.english]: {},
        [LanguageConfig.simplifiedChinese]: {},
        [LanguageConfig.traditionalChinese]: {},
      };
      dataArray.forEach((item, index) => {
        const language = item.language;
        const data = item.data;
        formatedData = {
          ...formatedData,
          [language]: {
            ...formatedData[language],
            ...data,
          },
        };
      });

      dispatch({
        type: 'createEarningRules/stepChange',
        payload: {
          data: formatedData,
          isBack: onCheck.isBack,
          step: isSpecificEarning ? 0 : 1,
        },
      });
      setError(false);
      stopCheck();
    }
  }, [dispatch, errorFields, onCheck.isBack, onCheck.value, tempEarningRule]);

  const submitAction = (language, data) => {
    dataArray.push({ language, data });
  };

  const getTabs = (language) => {
    return [
      <OverviewSection
        focusChange={(inputArea) => setActiveSection(inputArea)}
        language={language}
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(language, data);
        }}
        areaTitle={'overview'}
        defaultValue={tempEarningRule['en']}
        model={'createEarningRules'}
      />,

      <InstructionSection
        focusChange={(inputArea) => setActiveSection(inputArea)}
        language={language}
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(language, data);
        }}
        defaultValue={tempEarningRule[language]}
        valueChangedAction={'createEarningRules/changeVals'}
      />,

      <DetailSection
        focusChange={(inputArea) => setActiveSection(inputArea)}
        language={language}
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(language, data);
        }}
        defaultValue={tempEarningRule[language]}
        valueChangedAction={'createEarningRules/changeVals'}
      />,
    ];
  };

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
    hansContainer: getTabs(LanguageConfig.simplifiedChinese),
  };

  return (
    <>
      <ContentSections
        fieldsError={error}
        languageTabContent={languageTabConfig}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultImage}
        activeSection={activeSection}
      />
      {isSpecificEarning ? (
        <OnlyContinueButton
          continueAction={() => {
            isChecking({ isBack: false });
          }}
        />
      ) : (
        <ContinueWithBackButtons
          continueAction={() => {
            isChecking({ isBack: false });
          }}
          backAction={() => {
            isChecking({ isBack: true });
          }}
        />
      )}
    </>
  );
}

export default CreateEarningRuleStepTwo;
