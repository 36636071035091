import { LanguageConfig, APIStatus } from '../config/CustomEnums';
import {
  createCouponTemplate,
  generateCoupon,
  updateCouponSet,
} from '../services/CouponAPIHelper';
import {
  createAction,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  getFileNameFromUrl,
} from '../utils';
import { ToastType } from './NavBarModel';
import { getCouponExpiryDate } from '../utils/TimeFormatUtil';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { CouponErrorFields } from '../components/coupon/couponCreation/CouponHandleError';
import { apiWithResponseHandle } from './LoadingUtil';
import { IMAGE_TYPES } from './UploadFilesModel';
export const VALID_PERIOD_TYPE = {
  ALL_TIME: 'ALL_TIME',
  ABSOLUTE: 'ABSOLUTE',
  RELATIVE: 'RELATIVE',
};
const stepNameList = ['Content', 'Properties'];

export const sessionDataKey = {
  objectKey: 'createCoupon',
  stepEndKey: 'createCouponStepEnd',
  origionalData: 'createCouponOriginalData',
};

function checkFields(data, coverPhoto) {
  const generalName = data[LanguageConfig.english].name;
  let errorFields = [];
  if (!generalName) {
    errorFields.push(CouponErrorFields.generalName.name);
  }
  if (
    !coverPhoto ||
    coverPhoto.length < 1 ||
    coverPhoto.type !== IMAGE_TYPES.TYPE_URL
  ) {
    errorFields.push(CouponErrorFields.coverPhotoEmpty.name);
  }
  return {
    invalid: errorFields.length > 0,
    errorFields,
    data: {
      translations: { ...data },
    },
  };
}

function parsePhotoUrlForAPI(image) {
  let imageUrl = image;
  if (image && image.value) {
    imageUrl = image.value;
  }
  // const imageUrlSplitList = imageUrl.split('/');
  // return imageUrlSplitList[imageUrlSplitList.length - 1];
  return getFileNameFromUrl(imageUrl);
}

function parseOtherPhotoForAPI(otherPhotos) {
  let detailPhotos = {};
  if (otherPhotos && otherPhotos.length > 0) {
    otherPhotos.forEach((image, index) => {
      const detailPhotoKey = 'detailPhoto' + (index + 1);
      detailPhotos[detailPhotoKey] = parsePhotoUrlForAPI(image);
    });
  }
  return detailPhotos;
}

function getCouponValideType(state) {
  if (state.isAllTime) {
    return VALID_PERIOD_TYPE.ALL_TIME;
  } else if (state.isAbsolute) {
    return VALID_PERIOD_TYPE.ABSOLUTE;
  } else if (state.isRelative) {
    return VALID_PERIOD_TYPE.RELATIVE;
  }
}

function paseConponSetInputBody(couponTemplate) {
  const validPeriodType =
    couponTemplate.validPeriodType || getCouponValideType(couponTemplate);
  const otherPhotos = parseOtherPhotoForAPI(
    couponTemplate.translations[LanguageConfig.english].otherPhoto,
  );
  let brandPK = null;
  if (couponTemplate.selectedBrand) {
    brandPK = couponTemplate.selectedBrand.pk;
  }
  let applicableStores = null;
  if (couponTemplate.selectedStores) {
    applicableStores = couponTemplate.selectedStores.map((store) => {
      return store.storePK;
    });
  }
  const inputBody = {
    name: couponTemplate.translations[LanguageConfig.english].name,
    instructionSectionTitle:
      couponTemplate.translations[LanguageConfig.english]
        .instructionSectionTitle,
    instructionSectionContent:
      couponTemplate.translations[LanguageConfig.english]
        .instructionSectionContent,
    detailSectionTitle:
      couponTemplate.translations[LanguageConfig.english].detailSectionTitle,
    detailSectionContent:
      couponTemplate.translations[LanguageConfig.english].detailSectionContent,
    validPeriodType: validPeriodType,
    coverPhoto: parsePhotoUrlForAPI(
      couponTemplate.translations[LanguageConfig.english].coverPhoto,
    ),
    formats: couponTemplate.formats,
    ...otherPhotos,
  };

  if (validPeriodType === VALID_PERIOD_TYPE.ABSOLUTE) {
    inputBody.absoluteEffectiveDate =
      couponTemplate.startDateTime || couponTemplate.absoluteEffectiveDate;
    inputBody.absoluteExpiryDate = couponTemplate.endDateTime
      ? getCouponExpiryDate(couponTemplate.endDateTime)
      : couponTemplate.absoluteExpiryDate;
  } else if (validPeriodType === VALID_PERIOD_TYPE.RELATIVE) {
    inputBody.numberOfDaysToExpireAfterAcquisition =
      couponTemplate.numberOfDaysToExpireAfterAcquisition;
  }
  if (brandPK) {
    inputBody.brand = brandPK;
    inputBody.shouldUseInStore = couponTemplate.shouldBeUsedInStores;
    inputBody.useStaffCode = couponTemplate.useStaffCode
    if (applicableStores) {
      inputBody.applicableStores = applicableStores;
    }
  }
  return inputBody;
}

function parseCouponSetTranslation(
  couponTemplatePK,
  currentLanguageContent,
  language,
) {
  let otherPhotos = [];
  if (currentLanguageContent.otherPhoto) {
    otherPhotos = parseOtherPhotoForAPI(currentLanguageContent.otherPhoto);
  }

  const inputBody = {
    // source: couponTemplatePK,
    language: language,
    name: currentLanguageContent.name ? currentLanguageContent.name : null,
    coverPhoto: currentLanguageContent.coverPhoto
      ? parsePhotoUrlForAPI(currentLanguageContent.coverPhoto)
      : null,
    instructionSectionTitle: currentLanguageContent.instructionSectionTitle,
    instructionSectionContent: currentLanguageContent.instructionSectionContent,
    detailSectionTitle: currentLanguageContent.detailSectionTitle,
    detailSectionContent: currentLanguageContent.detailSectionContent,
    ...otherPhotos,
  };
  if (currentLanguageContent.pk) {
    inputBody['id'] = currentLanguageContent.pk;
  }

  return inputBody;
}

function getInitState() {
  return {
    couponTemplate: {
      selectedBrand: null,
      shouldBeUsedInStores: false,
      useStaffCode: false,
      selectedStores: null,
      isAllTime: true,
      isAbsolute: false,
      startDateTime: null,
      endDateTime: null,
      isRelative: false,
      numberOfDaysToExpireAfterAcquisition: null,
      generateCoupon: false,
      pk: null,
      id: null,
      translations: {
        [LanguageConfig.english]: {
          name: null,
          source: {
            id: null,
          },
          instructionSectionTitle: '',
          instructionSectionContent: '',
          detailSectionTitle: '',
          detailSectionContent: '',
          coverPhoto: null,
          otherPhoto: null,
        },
        [LanguageConfig.traditionalChinese]: {
          name: null,
          source: {
            id: null,
          },
          instructionSectionTitle: '',
          instructionSectionContent: '',
          detailSectionTitle: '',
          detailSectionContent: '',
          coverPhoto: null,
          otherPhoto: null,
          pk: null,
          id: null,
        },
        [LanguageConfig.simplifiedChinese]: {
          name: null,
          source: {
            id: null,
          },
          instructionSectionTitle: '',
          instructionSectionContent: '',
          detailSectionTitle: '',
          detailSectionContent: '',
          coverPhoto: null,
          otherPhoto: null,
          pk: null,
          id: null,
        },
      },
    },
    isSomeFieldError: false,
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    previewImageStatus: 0,
    fromBack: false,
    errorFields: [],
    createCouponTemplateStatus: APIStatus.none,
    generateCouponStatus: APIStatus.none,
    couponQuantity: 0,
    couponGenerated: false,
    formChanged: false,
  };
}

export default {
  namespace: 'createCoupon',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateTranslation(state, { payload }) {
      const { language, changedStates } = payload;
      return {
        ...state,
        couponTemplate: {
          ...state.couponTemplate,
          translations: {
            ...state.couponTemplate.translations,
            [language]: {
              ...state.couponTemplate.translations[language],
              ...changedStates,
            },
          },
        },
      };
    },

    updateCouponTemplate(state, { payload }) {
      if (payload.absoluteEffectiveDate) {
        payload.startDateTime = payload.absoluteEffectiveDate;
      }
      if (payload.absoluteExpiryDate) {
        payload.endDateTime = payload.absoluteExpiryDate;
      }
      const coupon = {
        ...state.couponTemplate,
        ...payload,
      };
      if (!payload.notSaveToSession) {
        delete coupon.notSaveToSession;
        saveToSessionStorage(sessionDataKey.objectKey, coupon);
      }
      return {
        ...state,
        couponTemplate: coupon,
      };
    },

    updateCouponTemplateWithSetData(state, { payload }) {
      const { selectedStores } = payload;
      let shouldBeUsedInStores = state.couponTemplate.shouldBeUsedInStores;
      if (selectedStores && selectedStores.length > 0) {
        shouldBeUsedInStores = true;
      }
      const useStaffCode = state.couponTemplate.shouldBeUsedInStores && state.couponTemplate.useStaffCode
      const coupon = {
        ...state.couponTemplate,
        ...payload,
        shouldBeUsedInStores: shouldBeUsedInStores,
        useStaffCode,
        selectedStores: selectedStores,
      };
      if (!payload.notSaveToSession) {
        delete coupon.notSaveToSession;
        saveToSessionStorage(sessionDataKey.origionalData, coupon);
        saveToSessionStorage(sessionDataKey.objectKey, coupon);
      }
      return {
        ...state,
        couponTemplate: coupon,
      };
    },

    saveOrRemoveCouponFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    loadCouponFromCookie(state, { payload }) {
      const coupon = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!coupon) {
        return {
          ...state,
        };
      }
      delete coupon.notSaveToSession;
      saveToSessionStorage(sessionDataKey.origionalData, coupon);
      saveToSessionStorage(sessionDataKey.objectKey, coupon);
      return {
        ...state,
        couponTemplate: {
          ...coupon,
        },
      };
    },

    stepChange(state, { payload }) {
      let { isBack, step, data } = payload;
      const coverPhoto =
        state.couponTemplate.translations[LanguageConfig.english].coverPhoto;
      const result = checkFields(data.translations, coverPhoto);
      const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );
      if (!result.invalid) {
        if (isBack) {
          step = step - 1;
        } else {
          step = step + 1;
        }
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
        errorFields: errorFields,
        couponTemplate: {
          ...state.couponTemplate,
          ...result.data,
          translations: {
            [LanguageConfig.english]: {
              ...state.couponTemplate.translations[LanguageConfig.english],
              ...result.data.translations[LanguageConfig.english],
            },
            [LanguageConfig.simplifiedChinese]: {
              ...state.couponTemplate.translations[
                LanguageConfig.simplifiedChinese
              ],
              ...result.data.translations[LanguageConfig.simplifiedChinese],
            },
            [LanguageConfig.traditionalChinese]: {
              ...state.couponTemplate.translations[
                LanguageConfig.traditionalChinese
              ],
              ...result.data.translations[LanguageConfig.traditionalChinese],
            },
          },
        },
      };
    },

    clearState(state, { payload }) {
      return { ...getInitState() };
    },
  },

  effects: {
    *initCoupon({ payload }, { select }) {
      const oldCoupon = yield select(
        (state) => state.createCoupon.couponTemplate,
      );
      delete oldCoupon.notSaveToSession;
      saveToSessionStorage(sessionDataKey.origionalData, oldCoupon);
    },
    *setFieldToSession({ payload }, { select }) {
      const oldCoupon = yield select(
        (state) => state.createCoupon.couponTemplate,
      );
      const language = payload.language;
      let coupon = {};
      // console.log('@@408: ', payload);
      if (language) {
        delete payload.language;
        coupon = {
          ...oldCoupon,
          translations: {
            ...oldCoupon.translations,
            [language]: {
              ...oldCoupon.translations[language],
              ...payload,
            },
          },
        };
      } else {
        coupon = { ...oldCoupon, ...payload };
      }
      // console.log('@@424: ', coupon);
      delete coupon.notSaveToSession;
      saveToSessionStorage(sessionDataKey.objectKey, coupon);
    },
    createCouponTemplate: [
      function* ({ payload }, { all, select, put }) {
        yield put(
          createAction('updateState')({
            createCouponTemplateStatus: APIStatus.calling,
          }),
        );
        const couponTemplate = yield select(
          (state) => state.createCoupon.couponTemplate,
        );

        let inputBody = paseConponSetInputBody(couponTemplate);

        if (payload.isDuplicate) {
          if (
            couponTemplate.translations[LanguageConfig.simplifiedChinese] &&
            couponTemplate.translations[LanguageConfig.simplifiedChinese].pk
          ) {
            delete couponTemplate.translations[LanguageConfig.simplifiedChinese]
              .pk;
          }

          if (
            couponTemplate.translations[LanguageConfig.traditionalChinese] &&
            couponTemplate.translations[LanguageConfig.traditionalChinese].pk
          ) {
            delete couponTemplate.translations[
              LanguageConfig.traditionalChinese
            ].pk;
          }

          inputBody.name = `Copy of ${inputBody.name}`;
        }

        const data = {
          ...inputBody,
          translations: [
            couponTemplate.translations[LanguageConfig.simplifiedChinese]
              ? parseCouponSetTranslation(
                  null,
                  couponTemplate.translations[LanguageConfig.simplifiedChinese],
                  LanguageConfig.simplifiedChinese,
                )
              : { language: LanguageConfig.simplifiedChinese },
            couponTemplate.translations[LanguageConfig.traditionalChinese]
              ? parseCouponSetTranslation(
                  null,
                  couponTemplate.translations[
                    LanguageConfig.traditionalChinese
                  ],
                  LanguageConfig.traditionalChinese,
                )
              : { language: LanguageConfig.traditionalChinese },
          ],
        };

        const serviceArgs = [createCouponTemplate, data];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onFailed() {
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.failed,
            }),
          );
          yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });
        }

        function* onSuccess(data) {
          const couponTemplateInfo = data.createCouponTemplate.node;
          if (couponTemplateInfo) {
            yield put(
              createAction('updateCouponTemplate')({
                pk: couponTemplateInfo.pk,
                id: couponTemplateInfo.id,
              }),
            );

            yield put(
              createAction('updateState')({
                createCouponTemplateStatus: APIStatus.success,
              }),
            );
          }
          yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });

          if (payload.afterAction) {
            yield payload.afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],

    generateCoupon: [
      function* ({ payload }, { call, select, put, all }) {
        const redirectAction = payload.redirectAction || (() => {});
        const { store, quantity } = payload;
        const { couponTemplate, couponQuantity } = yield select((state) => ({
          couponTemplate: state.createCoupon.couponTemplate.pk,
          couponQuantity: state.createCoupon.couponQuantity,
        }));
        yield put(
          createAction('updateState')({
            generateCouponStatus: APIStatus.calling,
            couponQuantity: (
              parseInt(quantity) + parseInt(couponQuantity)
            ).toLocaleString(),
          }),
        );

        const inputBody = {
          couponTemplate: couponTemplate,
          quantity: quantity,
          store: store,
        };
        const serviceArgs = [generateCoupon, inputBody];
        function* onFailed() {
          yield put(
            createAction('updateState')({
              generateCouponStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          if (data.generateCoupons.success) {
            const couponTemplatePK = yield select(
              (state) => state.createCoupon.couponTemplate.pk,
            );
            yield all([
              put(
                createAction('updateState')({
                  generateCouponStatus: APIStatus.success,
                  couponGenerated: true,
                }),
              ),
              put({ type: 'saveOrRemoveCouponFromCookie', payload: false }),
              put({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.couponGenerate,
                  },
                },
              }),
              redirectAction(couponTemplatePK),
            ]);
            saveToSessionStorage(sessionDataKey.stepEndKey, true);
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCouponTemplateInput: [
      function* ({ payload }, { call, put, select }) {
        yield put(
          createAction('updateState')({
            createCouponTemplateStatus: APIStatus.calling,
          }),
        );
        const isGenerater = payload.isGenerater;
        const couponTemplate = yield select(
          (state) => state.createCoupon.couponTemplate,
        );
        yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });
        const inputBody = paseConponSetInputBody(couponTemplate);
        inputBody.id = couponTemplate.pk;

        const serviceArgs = [
          updateCouponSet,
          {
            ...inputBody,
            translations: [
              couponTemplate.translations[LanguageConfig.simplifiedChinese]
                ? parseCouponSetTranslation(
                    null,
                    couponTemplate.translations[
                      LanguageConfig.simplifiedChinese
                    ],
                    LanguageConfig.simplifiedChinese,
                  )
                : {},
              couponTemplate.translations[LanguageConfig.traditionalChinese]
                ? parseCouponSetTranslation(
                    null,
                    couponTemplate.translations[
                      LanguageConfig.traditionalChinese
                    ],
                    LanguageConfig.traditionalChinese,
                  )
                : {},
            ],
          },
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onFailed() {
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          yield put(
            createAction('couponList/getCurrentPageTemplateList')({
              rank: true,
              search: '',
              page: 1,
            }),
          );
          if (isGenerater) {
            yield put(
              createAction('createCoupon/updateCouponTemplate')({
                generateCoupon: true,
              }),
            );
          }
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.success,
            }),
          );
          removeFromSessionStorage(sessionDataKey.objectKey);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
