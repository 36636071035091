import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import BasePrompt from '../base/prompt/BasePrompt';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
  CustomNumberInputWithUnit,
} from './CustomBaseComponments';
import { Image } from 'react-bootstrap';
import addImage from '../../assets/images/addx1.png';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import {
  CampaignType,
  EarningRuleType,
  EarningRuleRewardType,
  PAPER_SUBCATEGORY_ID,
  FOOD_WASTE_SUBCATEGORY_ID,
} from '../../config/CustomEnums';
import { getCampaignTypeSelectPromptConfig } from '../campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { useCompare } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';

function RewardSection(props) {
  const radioOptions = [
    { label: 'Coupons', value: EarningRuleRewardType.coupons },
    { label: 'Points', value: EarningRuleRewardType.points },
  ];
  const promptconfig = getCampaignTypeSelectPromptConfig(
    CampaignType.couponsCampaign,
  );

  const defaultValue = props.defaultValue || {};
  const hideTitle = props.hideTitle || false;
  const groupKey = props.groupKey;
  const showSkuPattern = props.showSkuPattern;
  const showCouponRemoveButton = props.showCouponRemoveButton || false;
  const hideCarbonInput = props.hideCarbonInput || false;
  const hideRewardType = props.hideRewardType || false;
  const customPointsTitle = props.customPointsTitle;
  const rewardChange = props.rewardChange || (() => { });
  const defaultRewardType = props.defaultRewardType || EarningRuleRewardType.coupons;

  const [rewardType, setRewardType] = useState(
    defaultValue.rewardType || defaultRewardType,
  );
  const [selectedCoupons, setSelectedCoupons] = useState(defaultValue.coupons);
  const [quantity, setQuantity] = useState(defaultValue.quantity);
  const [rewardTypeX, setRewardTypeX] = useState(defaultValue.rewardTypeX);
  const [carbon, setCarbon] = useState(defaultValue.carbon);
  const [skuPattern, setCurrentSkuPattern] = useState(defaultValue.skuPattern);
  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  //   const [showCoupons, setShowCoupons] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const extraPointUnit = () => {
    if (rewardType === EarningRuleRewardType.points) {
      if (groupKey == PAPER_SUBCATEGORY_ID) {
        return "(every 200g)";
      }
      else if (groupKey == FOOD_WASTE_SUBCATEGORY_ID) {
        return "(every 100g)";
      }
    }
    return "";
  }

  const extraCarbonUnit = () => {
    if (groupKey == PAPER_SUBCATEGORY_ID) {
      return "(every 100g)";
    }
    else if (groupKey == FOOD_WASTE_SUBCATEGORY_ID) {
      return "(every 1g)";
    }
    return "carbon saving per number";
  };

  const vals = {
    rewardType,
    coupons: selectedCoupons,
    quantity,
    rewardTypeX,
    carbon,
    skuPattern,
  };
  const hasValchanged = useCompare(vals);
  useEffect(() => {
    if (hasValchanged) {
      rewardChange(vals, groupKey);
      dispatch({
        type: 'createEarningRules/changeVals',
        payload: { vals, groupKey },
      });
    }
  });
  useEffect(() => {
    if (groupKey && !hasValchanged) {
      setRewardType(defaultValue.rewardType || defaultRewardType);
      setSelectedCoupons(defaultValue.coupons);
      setQuantity(defaultValue.quantity);
      // setRewardTypeX(defaultValue.setRewardTypeX);
      setCarbon(defaultValue.carbon || "");
      setCurrentSkuPattern(defaultValue.skuPattern || "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => { });
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));
  const isError = (name) => {
    let names = Array.isArray(name) ? name : [name];
    let errors = [];
    names.forEach((field) => {
      const error = isShowError(
        EarningRuleErrorHandleField[field].name,
        errorFields,
      );
      if (error) {
        errors.push(error);
      }
    });

    return errors[0];
  };
  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.coupons.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  const rewardQuality = () => {
    if (showSkuPattern) {
      if (rewardType === EarningRuleRewardType.points) {
        return 'points per amount unit';
      }
      return 'coupons per amount unit';
    }
    if (rewardType === EarningRuleRewardType.points) {
      return 'points';
    }
    return 'coupons per number';
  };

  return (
    <>
      <div className="sku-pattern-title">
        {showSkuPattern ? (
          <>
            <CustomTitleLabel title="SKU pattern" />
            <input
              type="text"
              onChange={({ target }) => setCurrentSkuPattern(target.value)}
              className="custom-markdown-area-title"
              value={skuPattern}
            />
          </>
        ) : null}
      </div>
      {hideTitle ? null : (
        <label className="create-section-title">Reward</label>
      )}

      {hideRewardType ? null : (
        <>
          <CustomTitleLabel title="Reward type" />
          <div style={{ display: 'flex' }}>
            <CustomRadios
              onChange={(value) => {
                setRewardType(value);
              }}
              default={rewardType}
              options={radioOptions}
            />
          </div>
        </>
      )}

      {rewardType === EarningRuleRewardType.coupons ? (
        <>
          <CouponSelectDropdown
            title={'Coupon set'}
            defaultValue={{
              value: selectedCoupons,
              label: selectedCoupons?.name,
            }}
            setValue={setSelectedCoupons}
            addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            showRemoveButton={showCouponRemoveButton}
            onRemoveButtonClick={() => setSelectedCoupons(null)}
            isPrompt
            errors={errorFields}
            errorName={EarningRuleErrorHandleField.coupons.name}
            addButtonInfo={{
              requires: PermissionCodes.addCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
          />
        </>
      ) : null}
      {defaultValue.type === EarningRuleType.generalPurchase &&
        rewardType === EarningRuleRewardType.points ? (
        <>
          <CustomTitleLabel title="Reward points" />
          <label className="tips-message">
            Eg. Customer earns 3 points for every 5 dollars spent. HK$ 5 = 3
            points
          </label>
          <CustomNumberMutipleInputWithUnit
            numberTitle={'HK$'}
            firstDefaultValue={rewardTypeX}
            setFirstValue={setRewardTypeX}
            secondDefaultValue={quantity}
            setSecondValue={setQuantity}
            per="="
            unit="points"
          />
          <ErrorFieldMessage
            id={`${EarningRuleErrorHandleField.quantity.name}`}
            error={isError(['rewardTypeX', 'quantity'])}
            message={`${EarningRuleErrorHandleField.quantity.message}`}
          />
        </>
      ) : (
        <>
          <CustomTitleLabel title={customPointsTitle || "Quantity"} />
          <div style={{ display: 'flex' }}>
            <input
              type="number"
              onChange={({ target }) => setQuantity(target.value)}
              className="custom-number-input-short"
              value={quantity}
              onFocus={() => { }}
            />
            <label className="help-message margin-left-10-percent">
              {rewardQuality()}
            </label>
            <label className="help-message margin-left-10-percent">
              {extraPointUnit()}
            </label>
          </div>
          <ErrorFieldMessage
            id={`${EarningRuleErrorHandleField.quantity.name}`}
            error={isError('quantity')}
            message={`${EarningRuleErrorHandleField.quantity.message}`}
          />
        </>
      )}
      {[
        EarningRuleType.habit,
        EarningRuleType.firstTry,
        EarningRuleType.carbonImprove,
      ].includes(defaultValue.type) || hideCarbonInput ? null : (
        <CustomNumberInputWithUnit
          title={showSkuPattern ? 'Carbon saving quantity' : 'Carbon'}
          unit={showSkuPattern ? 'carbon saving per amount unit' : `g ${extraCarbonUnit()}`}
          defaultValue={carbon}
          setValue={setCarbon}
        />
      )}

      <BasePrompt
        show={showCreateNewLinkPrompt}
        closeAction={() => setShowCreateNewLinkPrompt(false)}
        rightButton={{
          text: promptconfig.button,
          action: () => {
            history.push({
              pathname: '/coupons/create',
              state: {
                from: params.id ? `/earns/${params.id}/edit/` : '/earns/create',
                title: 'Continue to Create Earning Rule',
                content: 'You can continue to create the earning rule.',
              },
            });
          },
        }}
        title={promptconfig.title}
        description={
          'You will leave the campaign creation process. After you create a coupon set, you can come back to create a earning rule.'
        }
      />
    </>
  );
}

export default RewardSection;
