import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image } from 'react-bootstrap';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import { firstError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { useCompare } from '../../utils';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import './RewardSKUSection.scss';
import RewardSection from './RewardSection';

const defaultContent = (defaultValue) => {
  if (defaultValue.skuRewards?.length) {
    const rewardContents = defaultValue.skuRewards?.map((reward, index) => {
      return {
        skuName: `SKU ${index + 1}`,
        ...reward,
      };
    });
    return rewardContents;
  }
  return [];
};

function RewardSKUSection(props) {
  const dispatch = useDispatch();
  const [content, setContent] = useState(defaultContent(props.defaultValue));
  const vals = {
    skuRewards: content,
  };
  const hasValsChanged = useCompare(vals);
  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});

  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));

  useEffect(() => {
    if (onSubmit) {
      console.log('useEffectvals:', vals);
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.coupons.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">REWARD</label>
      {content.map((item, index) => {
        return (
          <>
            <div className="reward-sku-title">
              <label className="create-section-title-without-upper">
                {item.skuName}
              </label>
              <Image
                onClick={() => {
                  const afterRemoveContent = content.filter(
                    (val) => val.skuName !== item.skuName,
                  );
                  const newContent = afterRemoveContent?.map((val, index) => ({
                    ...val,
                    skuName: `SKU ${index + 1} `,
                  }));
                  setContent(newContent);
                  dispatch({
                    type: 'createEarningRules/removeVals',
                    payload: { rewardsGroupKey: item.pk },
                  });
                }}
                src={DeleteIcon}
                className="content-delete-button reward-sku-delete-button"
              />
            </div>

            <div
              className={`reward-content-area ${
                errorFields.includes('skuRewards') ||
                errorFields.includes('skuPatternDuplicate')
                  ? 'error-field'
                  : ''
              }`}
            >
              <div className="display-flex-area reward-content">
                <div className="reward-content">
                  <RewardSection
                    onSubmit={onSubmit}
                    onSubmitAction={(data) => {
                      onSubmitAction(data);
                    }}
                    showSkuPattern
                    defaultValue={item}
                    hideTitle
                    groupKey={`SKU${index + 1}`}
                    rewardChange={(reward, group) => {
                      const newContent = content?.map((data, index) => {
                        if (parseInt(group?.replace("SKU", "")) === index + 1) {
                          return {
                            ...data,
                            ...reward,
                          };
                        }
                        return data;
                      });
                      setContent(newContent);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        );
      })}

      <ErrorFieldMessage
        id={'skuRewards'}
        error={errorFields.includes('skuRewards')}
        message={EarningRuleErrorHandleField['skuRewards'].message}
      />
      <ErrorFieldMessage
        id={'skuPatternDuplicate'}
        error={errorFields.includes('skuPatternDuplicate')}
        message={EarningRuleErrorHandleField['skuPatternDuplicate'].message}
      />

      <button
        onClick={() => {
          setContent([
            ...content,
            {
              skuName: `SKU ${content?.length + 1 || 1} `,
              skuPattern: null,
              rewardType: 'COUPON',
              coupons: null,
              carbon: null,
              quantity: null,
            },
          ]);
        }}
        className="btn-back-button-common content-add-button dropdown-toggle btn btn-none"
      >
        Add SKU pattern
      </button>
    </>
  );
}

export default RewardSKUSection;
