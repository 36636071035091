import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import {
  LanguageConfig,
  APIStatus,
  DELETE_RELATED_SECTIONS,
  CategoryType,
} from '../../../config/CustomEnums';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';
import i18n from '../../../I18n';
import CustomSwitchButton from '../../../components/base/CustomSwitchButton';
import Loading from '../../../components/base/Loading';
import {
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import { CAMPAIGN_CATEGORY_SESSION_KEY } from '../../../models/CreateCampaignCategoryModel';
import CustomRadios from '../../../components/base/CustomRadios';
import { Row } from 'react-bootstrap';

const getRadioOptions = () => [
  {
    label: 'Both',
    value: CategoryType.BOTH,
  },
  {
    label: 'Earning campaign',
    value: CategoryType.EARNING_CAMPAIGN,
  },
  {
    label: 'Reward campaign',
    value: CategoryType.REWARD_CAMPAIGN,
  },
];

const getError = (errors, fieldName) => {
  let errorMessage = '';
  let hasError = false;
  errors.forEach((error) => {
    if (error.field === fieldName) {
      errorMessage = error.message;
      hasError = true;
    }
  });
  return [hasError, errorMessage];
};

const CreateCampaignCategory = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const caution = () => {
    if (params.id) {
      return {
        detail: '', //Need fill in the category name and display order
        title: category.name,
        model: 'createCampaignCategory',
      };
    } else {
      return {
        detail: '', //Need fill in the category name and display order
        title: 'Create campaign category',
        model: 'createCampaignCategory',
      };
    }
  };
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
  const [name, setName] = React.useState(null);
  const [translations, setTranslations] = React.useState([]);
  const [order, setOrder] = React.useState(null);
  const [isForcedInactive, setIsForcedInactive] = React.useState(false);
  const [categoryType, setCategoryType] = React.useState(CategoryType.BOTH);
  const { errorFields, category, createStatus } = useSelector((state) => ({
    errorFields: state.createCampaignCategory.errorFields,
    category: state.createCampaignCategory.category,
    createStatus: state.createCampaignCategory.createStatus,
  }));
  React.useEffect(() => {
    const categoryID = params.id;
    if (categoryID) {
      dispatch(
        createAction('createCampaignCategory/getCategory')({ id: categoryID }),
      );
    } else {
      dispatch({
        type: 'createCampaignCategory/loadCampaignCategoryFromSessionStorage',
      });
    }
    return () => {
      dispatch({ type: 'createCampaignCategory/reset' });
    };
  }, [dispatch, params.id]);
  const formChanged = () => {
    dispatch({ type: 'createCampaignCategory/formHasChanged' });
  };
  React.useEffect(() => {
    if (
      createStatus === APIStatus.success &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/campaign_category');
      return;
    }
    const {
      name,
      order,
      isForcedInactive,
      translations,
      categoryType,
    } = category;
    setName(name);
    setTranslations(translations);
    setOrder(order);
    setIsForcedInactive(isForcedInactive);
    setCategoryType(categoryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus, category]);
  const [hasNameError, nameErrorMessage] = getError(errorFields, 'name');
  const [hasOrderError, orderErrorMessage] = getError(errorFields, 'order');

  const getLanguageTab = (language) => {
    if (createStatus === APIStatus.calling) {
      return [<Loading />];
    }
    const isEnglish = language === LanguageConfig.english;
    return [
      <>
        <div className="create-section-title">
          {i18n.t('content', { locale: language })}
        </div>
        <label className="create-section-label create-section-label-bottom-space">
          {i18n.t('name', { locale: language })}
        </label>
        <>
          <input
            type="text"
            name="generalName"
            onChange={({ target }) => {
              const { value } = target;
              formChanged();
              if (isEnglish) {
                setName(value);
              } else {
                let updated = false;
                const newTranslations = [];
                translations.forEach((translation) => {
                  if (translation.language === language) {
                    updated = true;
                    if (value !== '') {
                      newTranslations.push({ language, name: value });
                    }
                  } else {
                    newTranslations.push(translation);
                  }
                });
                if (!updated && value !== '') {
                  newTranslations.push({
                    language,
                    name: value,
                  });
                }
                setTranslations(newTranslations);
              }
            }}
            defaultValue={
              isEnglish
                ? name
                : translations.filter((t) => t.language === language)[0]?.name
            }
            className={`custom-markdown-area-title `}
          />
          {isEnglish ? (
            <ErrorFieldMessage
              error={hasNameError}
              message={nameErrorMessage}
            />
          ) : null}
        </>

        {isEnglish ? (
          <>
            <label className="create-section-label create-section-label-bottom-space">
              Display order
            </label>
            <input
              name="order"
              type="number"
              min="1"
              onChange={({ target }) => {
                setOrder(target.value);
                formChanged();
              }}
              defaultValue={order}
              className={`coupon-campaign-section-input `}
            />
            <ErrorFieldMessage
              error={hasOrderError}
              message={orderErrorMessage}
            />
            <label className="coupon-campaign-section-caution-description">
              Minimum value is 1. The smaller value, the higher priority.
            </label>
            <div className="create-section-label-bottom-space">
              <label className="create-section-label featured-exclusive-row-tip-label">
                Category type
              </label>
              <Row className="featured-exclusive-row">
                <CustomRadios
                  onChange={(value) => {
                    setCategoryType(value);
                  }}
                  default={categoryType}
                  options={getRadioOptions()}
                />
              </Row>
            </div>
            <label className="create-section-label create-section-label-bottom-space">
              Is forced inactive
            </label>
            <CustomSwitchButton
              defaultChecked={isForcedInactive}
              onChange={(isChecked) => {
                setIsForcedInactive(isChecked);
                formChanged();
              }}
            />
          </>
        ) : null}
      </>,
    ];
  };
  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };
  const renderForm = () => (
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Updated' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={() => {
          console.log(name, translations, order, isForcedInactive);
          if (params.id) {
            dispatch({
              type: 'createCampaignCategory/checkAndUpdate',
              payload: {
                name,
                translations,
                order,
                isForcedInactive,
                categoryID: params.id,
                categoryType: categoryType,
              },
            });
          } else {
            dispatch({
              type: 'createCampaignCategory/checkAndSave',
              payload: {
                name,
                translations,
                order,
                isForcedInactive,
                categoryType: categoryType,
              },
            });
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
    </>
  );
  return (
    <div className="campaign-category">
      <ScrollableComponent
        tempSave={(save) => {
          if (save) {
            saveToSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY, {
              name,
              order,
              isForcedInactive,
              translations,
            });
          } else {
            removeFromSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Campaign category is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to campaign cataegory list',
              action: () => {
                history.push({
                  pathname: '/campaign_category',
                });
              },
            },
          ],
        }}
        content={[renderForm()]}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        caution={caution()}
      />
      <DeletePrompt
        message={{
          title: 'Delete this campaign category',
          content: 'Did you confirm to delete this campaign category?',
        }}
        title={'Campaign category'}
        data={category}
        relatedSections={DELETE_RELATED_SECTIONS.CAMPAIGN_CATEGORY}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('createCampaignCategory/deleteCategories')({
              ids: [params.id],
            }),
          );
        }}
      />
    </div>
  );
};

export default CreateCampaignCategory;
