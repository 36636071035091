import React, { useEffect, useState } from 'react';
import './CreateCampaign.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CreateCampaignStepOne from '../../../components/campaign/campaignCreation/CreateCampaignStepOne';
import CreateCampaignStepTwo from '../../../components/campaign/campaignCreation/CreateCampaignStepTwo';
import CreateCampaignStepThree from '../../../components/campaign/campaignCreation/CreateCampaignStepThree';
import CreateCampaignStepFour from '../../../components/campaign/campaignCreation/CreateCampaignStepFour';
import {
  createCampaignBreads,
  editCampaignBreads,
} from '../../../config/CustomBreadConfig';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import { sessionDataKey } from '../../../models/CreateCampaignModel';
import {
  getCmapaignCreateSucessStepBar,
  getCampaignStepBarNameWithType,
} from '../../../components/base/prompt/NextStepConfig';
import { URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import queryString from 'query-string';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import CampaignEditStorePrompt from '../../../components/campaign/CampaignEditStorePrompt';
function CreateCampaign() {
  const {
    stepConfig,
    currentStep,
    campaignType,
    campaignId,
    campaignPublish,
    linkedEarningRules,
    linkedCoupon,
  } = useSelector((state) => ({
    stepConfig: state.createCampaign.stepConfig,
    currentStep: state.createCampaign.currentStep,
    campaignType: state.createCampaign.campaign.campaignType,
    campaignId: state.createCampaign.campaign.pk,
    linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
    linkedCoupon: state.createCampaign.campaign.linkedCoupon,
    campaignPublish: state.createCampaign.campaignPublish,
  }));
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(history.location.search);

  const [showEditStorePrompt, setShowEditStorePrompt] = useState(false);
  const [showFinishPrompt, setShowFinishPrompt] = useState(false);
  useEffect(() => {
    if (campaignPublish === APIStatus.success) {
      setShowFinishPrompt(true);
    }
  }, [campaignPublish]);

  useEffect(() => {
    if (history.location.search) {
      const type = search.type;
      const id = search.id;
      const actionName =
        type === CampaignType.couponsCampaign
          ? 'getAndLinkCouponDetail'
          : 'getAndLinkEarningRuleDetail';
      dispatch(
        createAction(`createCampaign/updateCampaign`)({
          campaignType: type,
        }),
      );
      dispatch(
        createAction(`createCampaign/${actionName}`)({
          couponId: id,
          earningRuleId: id,
          afterAction: () =>
            dispatch({
              type: 'createCampaign/stepChange',
              payload: { step: 0 },
            }),
        }),
      );
    } else {
      const campaignId = params.id;
      if (campaignId) {
        dispatch(
          createAction('createCampaign/getOneCampaign')({ id: campaignId }),
        );
      } else {
        dispatch({
          type: 'createCampaign/loadCampaignFromCookie',
        });
      }
    }
    return () => {
      dispatch({ type: 'createCampaign/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepSet = [
    <CreateCampaignStepOne />,
    <CreateCampaignStepTwo />,
    <CreateCampaignStepThree />,
    <CreateCampaignStepFour />,
  ];

  const getBread = () => {
    const path = location.pathname;
    const isWhatsNewList = path.indexOf('what_is_new') > -1
    const title = {};
    if (path.indexOf('edit') > -1) {
      title.breadcrumb = editCampaignBreads(isWhatsNewList);
      title.caution = {
        detail: '', //Edit a campaign to engage your custoemrs. You can edit different types of campaign.
        title: 'Edit Campaign ',
      };
    } else {
      title.breadcrumb = createCampaignBreads();
      title.caution = {
        detail: '', //Create a campaign to engage your custoemrs. You can create different types of campaign.
        title: 'Create Campaign ',
      };
    }

    return title;
  };

  const toCampaignList = () => {
    history.push({
      pathname: '/campaigns',
      hash: URLFragments.list,
    });
  };

  const getCompleteSteps = () => {
    const urlCampaignType = search.type;
    const urlId = search.id;
    const linkedId =
      CampaignType.couponsCampaign === campaignType
        ? linkedCoupon?.pk
        : linkedEarningRules?.pk;
    let showMoreSteps = location.search;
    if (urlCampaignType !== campaignType) {
      showMoreSteps = false;
    }
    if (urlId + '' !== linkedId + '') {
      showMoreSteps = false;
    }
    return getCmapaignCreateSucessStepBar(campaignType, showMoreSteps);
  };

  const getSearch = () => {
    const urlCampaignType = search.type;
    if (urlCampaignType != campaignType) {
      return `&type=${campaignType}&campaign=${campaignId}`;
    };
    return `${location.search}&campaign=${campaignId}`;
  };

  return (
    <>
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({
            type: 'createCampaign/saveOrRemoveCampaignFromCookie',
            payload: save,
          });
        }}
        showFinishPop={showFinishPrompt}
        nextStepConfig={{
          title: 'Successfully Published!',
          description:
            'The campaign is successfully published. Let your customers know about the campaign.',
          steps: {
            stepNames: getCompleteSteps(),
            current: getCampaignStepBarNameWithType(campaignType),
          },
          buttons: [
            {
              text: 'Link related store',
              action: () => {
                setShowFinishPrompt(false);
                setShowEditStorePrompt(true);
              },
              requires: PermissionCodes.changeCampaign,
            },
            {
              text: 'Create message',
              action: () => {
                history.push({
                  pathname: '/messages/create',
                  search: getSearch(),
                });
              },
              requires: PermissionCodes.addMessage,
            },
            {
              text: 'Maybe Later',
              action: toCampaignList,
              requires: PermissionCodes.changeCampaign,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: toCampaignList,
          onClose: toCampaignList,
        }}
        sessionDataKey={sessionDataKey}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={<CustomBreadcrumb breadcrumbs={getBread().breadcrumb} />}
        stepsConfig={stepConfig}
        caution={getBread().caution}
      />
      <CampaignEditStorePrompt
        show={showEditStorePrompt}
        campaignID={campaignId}
        closeAction={() => {
          setShowEditStorePrompt(false);
          setShowFinishPrompt(true);
        }}
      />
    </>
  );
}

export default CreateCampaign;
