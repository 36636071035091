import React, { useState, useEffect } from 'react';
import './StoreList.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { ImportResource } from '../../../models/DownloadImportModel';

function StoreList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
    importedFile,
  } = useSelector((state) => ({
    listFields: state.storeModel.listDisplayFields,
    dataList: state.storeModel.pagedStoreList,
    pageInfo: state.storeModel.pageInfo,
    totalPage: state.storeModel.totalPage,
    totalCount: state.storeModel.totalCount,
    checkedList: state.storeModel.checkedList,
    importedFile: state.uploadFiles.importedFile,
  }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );
    event.target.value = null;
  };

  const exportCSVAction = () => {
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.stores,
      }),
    );
  };

  const createStoreAction = () => {
    history.push('/stores/create');
  };

  useEffect(() => {
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.stores,
          file: importedFile,
        }),
      );
    }
  }, [dispatch, importedFile]);

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          title="Create Store"
          action={createStoreAction}
          requires={PermissionCodes.addStore}
          customClass={'store-list-create-button-margin-right btn-further'}
        />,
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Export .csv"
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,

        <AuthButton
          title={'Import'}
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addStore,
          action: createStoreAction,
          content: 'Create Store',
        },
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: 'Export .csv',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Import',
        },
      ]}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv, .zip"
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteStore,
    },
  ];

  const orderChange = (item, order) => {
    if (item.order === order) {
      return;
    }
    dispatch(
      createAction('storeModel/createOrUpdate')({
        data: {
          id: item.pk,
          displayPriority: order,
        },
        afterAction: () => history.push(location),
      }),
    );
  };

  const tabs = [
    {
      name: 'List of Stores',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'storeModel'}
              permissionGroup={PermissionCodes.store}
              deleteInfo={{
                data: [],
                title: 'store',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.STORE,
              }}
              sortable
              sortDisabled={false}
              rankDisabled
              onSortEnd={(oldIndex, newIndex) => {
                const newOrderObject = dataList[newIndex];
                const movedItem = dataList[oldIndex];
                orderChange(movedItem, newOrderObject.order)
              }}
              onOrderChangedByEdit={(item, newOrder) => {
                orderChange(item,newOrder)
              }}
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              hasOtherActions
              customActions={{
                moveToFirst: (item) => {
                  orderChange(item, 1)
                },
                moveToLast: (item) => {
                  orderChange(item, totalCount)
                },
              }}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: 'storeModel/clearData' });

    const queryString = require('query-string');
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);

      dispatch(
        createAction('storeModel/getPagedStoreList')({
          ...querySearch,
          page,
          reverse: rank,
          search: searchKey,
        }),
      );
    }
    dispatch(createAction('storeCategoryList/getAllStoreSubcategoryList')({}));
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'After you create stores, you can specify which store the coupon can be used in. \
            A store may belongs to a brand.',
          title: NavBarNames.stores,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
      />
      <DeletePrompt
        data={checkedList}
        title={'stores'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.STORE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('storeModel/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

export default StoreList;
