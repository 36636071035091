import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function BarcodeSelectDropdown(props) {
  const dispatch = useDispatch();

  const defaultSelectedBarcode = props.selectedBarcode;
  const selectBarcode = props.selectBarcode || (() => { });

  const { barcodeList } = useSelector((state) => ({
    barcodeList: state.barcodes.allList,
  }));

  useEffect(() => {
    dispatch({ type: "barcodes/getCurrentPageList", payload: { isSelectorLoad: true } });
  }, [dispatch]);

  return (
    <div>
      <CustomTitleWithDropDown
        title="Barcode"
        defaultValue={{
          value: defaultSelectedBarcode,
          label: defaultSelectedBarcode?.productName,
        }}
        source={barcodeList}
        setValue={(item) => {
          selectBarcode(item.value);
        }}
        addButtonInfo={null}
        loadMoreAction={'barcodes/getCurrentPageList'}
        filterAction={'barcodes/getCurrentPageList'}
        showRemoveButton={true}
        onRemoveButtonClick={()=> selectBarcode(null)}
      />
    </div>
  );
}

export default BarcodeSelectDropdown;
