import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContentSections from '../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../components/base/BottomStepComponent';
import DeletePrompt from '../../components/base/DeletePrompt';
import {
  LanguageConfig,
  DELETE_RELATED_SECTIONS,
  APIStatus,
  PublishTagType,
} from '../../config/CustomEnums';
import { PermissionCodes } from '../../config/PermissionCodes';
import Loading from '../../components/base/Loading';
import GeneralSection from './GeneralSection';
import VisiblePeriodSection from './VisiblePeriodSection';


const CreateSplashAdStepOne = ({
  splashAdDetail,
  splashAdAPIStatus,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const isPublished = splashAdDetail.status === PublishTagType.published;
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const getLanguageTab = (language) => ([
    <GeneralSection language={language} />,
    language === LanguageConfig.english ? <VisiblePeriodSection /> : null,
  ])

  const goToListAfterCreateOrUpdate = () => {
    if (id) {
      history.push('/splash_ad');
    };
  };

  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  if (splashAdAPIStatus === APIStatus.calling) {
    return <ContentSections sections={[<Loading />]} hidePreview />;
  };

  return (
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />

      <SaveAndBackButtons
        secondSaveTempText={id ? 'Delete splash ad' : null}
        saveTempText={id ? 'Update' : "Save"}
        saveText={`${id ? 'Update' : 'Save'} and ${isPublished ? 'un' : ''}publish`}
        backAction={() => history.goBack()}
        secondSaveTempAction={() => setShowDeletePrompt(true)}
        saveTempAction={() => {
          dispatch({
            type:
              'splashAd/checkValid',
            payload: {
              hasPublishOrUnpublishAction: false,
              afterAction: goToListAfterCreateOrUpdate,
            },
          });
        }}
        saveAction={() => {
          dispatch({
            type: 'splashAd/checkValid',
            payload: {
              hasPublishOrUnpublishAction: true,
              afterAction: goToListAfterCreateOrUpdate,
            },
          });
        }}
      />
      <DeletePrompt
        message={{
          title: 'Delete this splash ad',
          content: 'Did you confirm to delete this splash ad?',
        }}
        title={'Splash ad'}
        data={splashAdDetail}
        relatedSections={DELETE_RELATED_SECTIONS.SPLASH_AD}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'splashAd/saveOrRemoveSplashAdFromCookie',
            payload: false,
          });
          dispatch({
            type: 'splashAd/delete',
            payload: {
              deletePks: [id],
              afterAction: () => {
                history.push('/splash_ad');
              },
            },
          });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  splashAdDetail: state.splashAd.splashAdDetail,
  splashAdAPIStatus: state.splashAd.splashAdAPIStatus,
});

export default connect(mapStateToProps)(CreateSplashAdStepOne);
