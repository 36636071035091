import React, { useEffect, useState } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import { createAction, useCompare } from '../../utils';
import CustomInputWithTitle from './CustomInputWithTitle';
import CustomRadios from '../base/CustomRadios';
import CustomRadioWithTitleAndRelated from './CustomRadioWithTitleAndRelated';
import { CustomerErrorHandleFields } from '../../containers/customers/customer/CustomerErrorHandleFields';

function ContactInfoEditCard({ title, customer, errorFields }) {
  const dispatch = useDispatch();

  const [
    mobilePhoneNumberCountryCode,
    setMobilePhoneNumberCountryCode,
  ] = useState({
    label: `${customer.mobilePhoneNumberCountryCode}`,
    value: customer.mobilePhoneNumberCountryCode,
  });
  const [
    mobilePhoneNumberSubscriberNumber,
    setMobilePhoneNumberSubscriberNumber,
  ] = useState(customer.mobilePhoneNumberSubscriberNumber);
  const [emailAddress, setEmailAddress] = useState(customer.emailAddress);
  const [socialMedia, setSocialMedia] = useState();
  const [socialMediaInput, setSocialMediaInput] = useState();
  const [address, setAddress] = useState(customer.address);

  const vals = {
    mobilePhoneNumberCountryCode,
    mobilePhoneNumberSubscriberNumber,
    emailAddress,
    socialMedia,
    socialMediaInput,
    address,
  };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'customerList/changeVals', payload: { vals } });
    }
  });

  return (
    <div>
      <label className="create-section-title">Contact Information</label>

      <CustomTitleWithDropDownAndInput
        title="Mobile number"
        source={[
          { name: '856', pk: '856' },
          { name: '852', pk: '852' },
          { name: '86', pk: '86' },
        ]}
        defaultValue={mobilePhoneNumberCountryCode}
        defaultInputValue={mobilePhoneNumberSubscriberNumber}
        needFilter={false}
        customClass="dropdown-short"
        inputCustomClass="dropdown-input-long"
        setValue={setMobilePhoneNumberCountryCode}
        setInputValue={setMobilePhoneNumberSubscriberNumber}
        disabled={true}
      />

      <CustomInputWithTitle
        title={'Email'}
        defaultValue={emailAddress}
        setValue={setEmailAddress}
        error={{
          id: 'emailAddress',
          error: errorFields?.fields?.includes('emailAddress'),
          message: errorFields?.messages?.map((item) => {
            if (item.field === 'emailAddress') {
              return CustomerErrorHandleFields[item.field][item.errorType];
            }
          }),
        }}
      />

      {/* <CustomRadioWithTitleAndRelated
        title="Social media (optional)"
        options={[
          { label: 'Facebook', value: 'Facebook' },
          { label: 'Wechat', value: 'Wechat' },
        ]}
        defaultValue={socialMedia}
        setValue={setSocialMedia}
        related={{
          type: 'input',
          defaultValue: socialMediaInput,
          setValue: setSocialMediaInput,
        }}
      /> */}

      <CustomInputWithTitle
        title={'Address (optional)'}
        defaultValue={address}
        setValue={setAddress}
        error={{}}
      />
    </div>
  );
}

export default ContactInfoEditCard;
