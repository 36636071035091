import React, { useEffect, useState } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import {
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import CustomAddRemoveMutipleSelector from './CustomAddRemoveMutipleSelector';
import { useCompare, checkHasPermission } from '../../utils';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';

function MembershipInfoEditCard({ title, customer }) {
  const dispatch = useDispatch();

  const {
    user,
  } = useSelector((state) => {
    return {
      user: state.users,
    };
  });

  const [pointTransaction, setPointTransaction] = useState();
  const [transactionPoint, setTransactionPoint] = useState();
  const [pointTransactionRemark, setPointTransactionRemark] = useState('');

  const [removedCoupons, setRemovedCoupons] = useState([]);
  const [leaveGroups, setLeaveGroups] = useState([]);

  const [referrerUser, setReferrerUser] = useState(customer.referrerUser);

  const [isForcedInactive, setIsForcedInactive] = useState(
    customer.isForcedInactive,
  );

  const vals = {
    pointTransaction,
    transactionPoint,
    pointTransactionRemark,
    removedCoupons,
    leaveGroups,
    referrerUser,
    isForcedInactive,
  };
  const valsChanged = useCompare(vals);

  useEffect(() => {
    if (valsChanged) {
      dispatch({ type: 'customerList/changeVals', payload: { vals } });
    }
  }, [dispatch, valsChanged, vals]);

  const getPointTransactionSource = () => {
    let source = [];
    if (checkHasPermission(user, PermissionCodes.addCustomerPoints))
      source.push({ name: 'Add transaction', pk: 'ADD_POINTS' });
    if (checkHasPermission(user, PermissionCodes.removeCustomerPoints))
      source.push({ name: 'Remove transaction', pk: 'REMOVE_POINTS' });

    return source;
  };

  return (
    <div>
      <label className="create-section-title">{title}</label>

      <CustomTitleWithDropDownAndInput
        title="Change points transaction(Optional)"
        source={getPointTransactionSource()}
        defaultValue={''}
        needFilter={false}
        unit="Points"
        customClass="edit-custom-transaction-selector"
        setValue={setPointTransaction}
        setInputValue={setTransactionPoint}
      />

      <CustomTitleLabel title="Points transaction remark(Optional)" />
      <CustomEditor
        initialValue={''}
        onValueChange={(data) => setPointTransactionRemark(data)}
        onFocus={() => {}}
        errorMessage={''}
        error={''}
      />

      <CustomAddRemoveMutipleSelector
        title={'Owned coupons(Optional)'}
        data={customer.ownedCoupons}
        selectedList={[]}
        addButton={{ title: 'Add Single Coupons ', link: { pathname: '/coupons', hash: 'List of Coupon sets' } }}
        setValue={setRemovedCoupons}
        sourceReminderText={'All coupon(s)'}
        amidReminderText={'Remove coupon(s)'}
      />

      <CustomAddRemoveMutipleSelector
        title={'Customer groups(Optional)'}
        data={customer.inGroups}
        selectedList={[]}
        setValue={setLeaveGroups}
        sourceReminderText={'All group(s)'}
        amidReminderText={'Leave group(s)'}
      />

      <CustomerFilterableDropdown
        title={'Referrer(Optional)'}
        showMembershipId={true}
        defaultSelectedCustomer={referrerUser}
        selectCustomer={setReferrerUser}
      />

      <CustomTitleWithSwitch
        title={'Is forced inactive'}
        defaultValue={isForcedInactive}
        setValue={setIsForcedInactive}
      />
    </div>
  );
}

export default MembershipInfoEditCard;
