export const EarningRuleErrorHandleField = {
  generalName: {
    name: 'generalName',
    message: 'Please provide name, limit 50 characters',
  },
  birthday: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  birthStart: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  birthEnd: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide short description.',
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide customer group.',
  },
  coupons: {
    name: 'coupons',
    message: 'Please select coupon.',
  },
  rewardType: {
    name: 'rewardType',
    message: 'Please select reward type.',
  },
  quantity: {
    name: 'quantity',
    message: 'Please provide required quantity.',
  },
  rewardTypeX: {
    name: 'rewardTypeX',
    message: 'Please provide required points.',
  },
  latitude: {
    name: 'latitude',
    message: 'Please provide required latitude.',
  },
  latitudeLimit: {
    name: 'latitudeLimit',
    message: 'Please make sure the length before dot shoud not longer than 3.',
  },
  longitude: {
    name: 'longitude',
    message: 'Please provide required longitude.',
  },
  longitudeLimit: {
    name: 'longitudeLimit',
    message: 'Please make sure the length before dot shoud not longer than 3.',
  },
  radius: {
    name: 'radius',
    message: 'Please provide required radius.',
  },
  qrCodes: {
    name: 'qrCodes',
    message: 'Please provide required qrcodes.',
  },
  increasePercentage: {
    name: 'increasePercentage',
    message: 'Please provide required increase percentage.',
  },
  goal: {
    name: 'goal',
    message: 'Please provide required goal.',
  },

  habitCampaigns: {
    name: 'habitCampaigns',
    message: 'Please provide required campaigns.',
  },

  photoTakingStores: {
    name: 'photoTakingStores',
    message: 'Please provide required stores.',
  },

  rewards: {
    name: 'rewards',
    message: 'Please provide at least 1 item reward',
  },

  perItemAmountLimit: {
    name: 'perItemAmountLimit',
    message: 'Please provide per item amount limit',
  },
  perHeadTotalAmountLimit: {
    name: 'perHeadTotalAmountLimit',
    message:
      'Please provide per head total amount limit, and the number should largger than per item amount limit',
  },
  merchantName: {
    name: 'merchantName',
    message: 'Please provide required merchantName',
  },
  merchantMapping: {
    name: 'merchantMapping',
    message: 'Please provide at least one of Merchant Name (for internal mapping) or Branch',
  },
  branchMapping: {
    name: 'branchMapping',
    message: 'Please provide at least one of Merchant Name (for internal mapping) or Branch',
  },
  octopusMerchantId: {
    name: 'octopusMerchantId',
    message: 'Please provide required octopusMerchantId',
  },
  // receiptNumberPattern: {
  //   name: 'receiptNumberPattern',
  //   message: 'Please provide required receipt number pattern',
  // },
  skuPattern: {
    name: 'skuPattern',
    message: 'Please provide required sku pattern',
  },
  skuPatternDuplicate: {
    name: 'skuPatternDuplicate',
    message: 'SKU patterns are duplicate, please check and try again',
  },
  skuRewards: {
    name: 'skuRewards',
    message: 'Please provide at least 1 item reward',
  },
  octopusExtraRewards: {
    name: 'octopusExtraRewards',
    message: 'Please provide at least one of the rewards',
  },
  minDistance: {
    name: 'minDistance',
    message: 'Please provide required minimum distance',
  },
  maxDistance: {
    name: 'maxDistance',
    message: 'Please provide required maximum distance',
  },
  wrongDistance: {
    name: 'wrongDistance',
    message: "Maximum distance can't be less than minimum distance",
  },
  reachMinimumCoupons: {
    name: 'reachMinimumCoupons',
    message: 'Please select coupon.',
  },
  overMinimumCoupons: {
    name: 'overMinimumCoupons',
    message: 'Please select coupon.',
  },
  reachMinimumQuantity: {
    name: 'reachMinimumQuantity',
    message: 'Please provide required quantity.',
  },
  overMinimumQuantity: {
    name: 'overMinimumQuantity',
    message: 'Please provide required quantity.',
  },
};
