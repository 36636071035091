import React, { useState, useEffect } from 'react';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './MessageGeneralSection.scss';
import { createAction } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateMessageError } from '../../models/CreateMessageModel';
import MessageSelectDropdown from './MessageSelectDropdown'
import { useParams } from 'react-router-dom';

function InformationBarMessageMaintenanceGeneralSection(props) {
  const language = props.language || LanguageConfig.english;
  const onSubmit = props.onSubmit || false;
  const onSubmitData = props.onSubmitData || (() => { });

  const dispatch = useDispatch()

  const {
    defaultMessageId,
    defaultInformationBar,
    message,
    informationBarName,
    informationBarContent,
    translations,
    informationBarTranslations,
    errorFields,
  } = useSelector(
    (state) => ({
      defaultMessageId: state.createMessage.message.informationBar.messageId,
      defaultInformationBar: state.createMessage.message.informationBar,
      message: state.createMessage.message.informationBar.message,
      informationBarName: state.createMessage.message.informationBar.name,
      informationBarContent: state.createMessage.message.informationBar.content,
      translations: state.createMessage.message.translations,
      informationBarTranslations: state.createMessage.message.informationBar.translations,
      errorFields: state.createMessage.errorFields,
      segments: state.segments.segmentList,
      groups: state.customerGroup.groupList,
      currentPageMessageList: state.messageList.currentPageMessageList,
    }),
  );

  const [messageId, setMessageId] = useState(defaultMessageId)
  const [name, setName] = useState(language === LanguageConfig.english ? informationBarName : informationBarTranslations[language]?.name,)
  const [informationBarContentState, setInformationBarContentState] = useState(language === LanguageConfig.english ? informationBarContent : informationBarTranslations[language]?.content,)
  const [messageTitle, setMessageTitle] = useState(defaultInformationBar.message?.name || '')
  const [selectedMessage, setSelectedMessage] = useState(defaultInformationBar.message || '')

  const options = (source) => {
    if (!source) {
      return [];
    }
    return source.map((item) => {
      return {
        name: item.name,
        value: item,
      };
    });
  };


  useEffect(() => {
    if (onSubmit) {
      // todo add informationbar pk
      const data = {
        informationBar: {
          name: name,
          content: informationBarContentState,
          messageId: messageId,
          messageTitle: messageTitle,
          language: language,
          message: selectedMessage
        },
        language: language,
      };
      if (language !== LanguageConfig.english && translations[language]?.pk) {
        data.informationBar.id = informationBarTranslations[language]?.id;
        data.informationBar.pk = informationBarTranslations[language]?.pk;
      }

      onSubmitData(data);
    }
  }, [
    language,
    name,
    informationBarContentState,
    messageTitle,
    messageId,
    onSubmit,
    onSubmitData,
    translations,
  ]);

  useEffect(() => {
    firstError(
      `${CreateMessageError.name.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      `${CreateMessageError.content.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      `${CreateMessageError.messageId.name}-${language}`,
      errorFields,
      language,
    );
  });

  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, language);
  };

  return (
    <>
      <label className="create-section-title">
        {i18n.t('information_bar_maintenance', { locale: language })}
      </label>
      <CustomTitleLabel
        title={i18n.t('information_bar_name', { locale: language })}
      />
      <label className="create-message-suggest">
        {i18n.t('information_bar_name_hint', { locale: language })}
      </label>
      <input
        type="text"
        className={`text-input-field title-message-input`}
        defaultValue={name}
        onChange={(e) => setName(e.target.value)}
      />
      <ErrorFieldMessage
        id={`${CreateMessageError.name.name}-${language}`}
        error={isError(CreateMessageError.name.name)}
        message={`${CreateMessageError.name.message}`}
      />

      <CustomTitleLabel title={i18n.t('content', { locale: language })} />
      <input
        type="text"
        className={`text-input-field title-message-input`}
        defaultValue={informationBarContentState}
        onChange={(e) => setInformationBarContentState(e.target.value)}
      />

      <ErrorFieldMessage
        id={`${CreateMessageError.content.name}-${language}`}
        error={isError(CreateMessageError.content.name)}
        message={`${CreateMessageError.content.message}`}
      />
      <CustomTitleLabel title='Inbox message' />
      {language !== LanguageConfig.english
        ? (message?.translations?.['zh-Hant']?.name ?? message?.name ?? '-')
        : (
          <MessageSelectDropdown
            defaultValue={{ label: message?.title, value: message }}
            setValue={(value) => {
              dispatch(
                createAction('createMessage/updateMessageState')({
                  informationBar: {
                    messageTitle: value.title,
                    messageId: value.pk,
                    message: value,
                  }
                }),
              );
              setSelectedMessage(value)
              setMessageTitle(value.title)
              setMessageId(value.pk)
            }}
            errors={errorFields}
            errorName={CreateMessageError.messageId.name}
            errorMessage={CreateMessageError.messageId.message}
          />
        )}

    </>
  );
}

export default InformationBarMessageMaintenanceGeneralSection;
