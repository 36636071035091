import React, { useState, useEffect } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../utils/TimeFormatUtil';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateMessageError } from '../../models/CreateMessageModel';
import { LanguageConfig } from '../../config/CustomEnums';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import AddCustomerGroupSegmentPrompt, { NewPromptType } from '../base/prompt/AddCustomerGroupSegmentPrompt';

function MessageSettingSection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = props.onSubmit || false;
  const onSubmitData = props.onSubmitData || (() => { });
  const [createNewType, setCreateNewType] = useState(NewPromptType.none);

  const {
    customerGroupList,
    segmentList,
    targetCustomerGroup,
    targetedSegments,
    defaultScheduleDate,
    errorFields,

  } = useSelector((state) => ({
    customerGroupList: state.customerGroup.groupList,
    segmentList: state.segments.allSegmentList,
    targetCustomerGroup: state.createMessage.message.targetCustomerGroup,
    targetedSegments: state.createMessage.message.targetedSegments,
    defaultScheduleDate: state.createMessage.message.scheduledDate,
    errorFields: state.createMessage.errorFields,
  }));

  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [scheduledDate, setScheduleDate] = useState();

  useEffect(() => {
    if (onSubmit) {
      const formatData = {
        targetCustomerGroup: selectedCustomerGroup,
        targetedSegments: selectedSegments,
        scheduledDate: scheduledDate,
      };
      onSubmitData(formatData);
    }
  });

  useEffect(() => {
    firstError(
      CreateMessageError.scheduledDate.name,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = () => {
    return isShowError(
      CreateMessageError.scheduledDate.name,
      errorFields,
      LanguageConfig.english,
    );
  };

  return (
    <>
      <label className="create-section-title">SETTING</label>

      <CustomAddRemoveMutipleSelector
        title={'Target customer groups'}
        data={customerGroupList?.map((item) => {
          return {
            name: item.name,
            value: item,
          };
        })}
        selectedList={targetCustomerGroup?.map((item) => {
          return { name: item.name, value: item };
        })}
        setValue={(options) => {
          setSelectedCustomerGroup(options);
          dispatch(
            createAction('createMessage/updateMessageState')({
              targetCustomerGroup: options.map((item) => item.value),
              displayTargetCustomer: options
                .map((item) => item.value.name)
                .toString(),
            }),
          );
        }}
        sourceReminderEnabelSelectAll={true}
        addButton={{
          title: 'Add Customer Group',
          action: () => setCreateNewType(NewPromptType.customerGroup),
        }}
        requires={PermissionCodes.addCustomerGroup}
        selectAllTitle={'All customer groups'}
        loadMoreAction={'customerGroup/getCurrentPageList'}
      />
      <CustomAddRemoveMutipleSelector
        title={'Target segments'}
        data={segmentList?.map((item) => {
          return {
            name: item.name,
            value: item,
          };
        })}
        selectedList={targetedSegments?.map((item) => {
          return {
            name: item.name,
            value: item,
          };
        })}
        setValue={(options) => {
          setSelectedSegments(options);
          dispatch(
            createAction('createMessage/updateMessageState')({
              targetedSegments: options.map((item) => item.value),
              displayTargetedSegments: options
                .map((item) => item.value.name)
                .toString(),
            }),
          );
        }}
        sourceReminderEnabelSelectAll={true}
        addButton={{
          title: 'Add Segments',
          action: () => setCreateNewType(NewPromptType.segments),
        }}
        requires={PermissionCodes.addSegment}
        selectAllTitle={'All segments'}
        loadMoreAction={'segments/getSegmentsList'}
      />

      <CustomTitleLabel title="Schedule time" />
      <CustomDateTimeSelect
        defaultTime={
          defaultScheduleDate ? new Date(defaultScheduleDate) : new Date()
        }
        onTimeChange={(datetime) => {
          setScheduleDate(datetime);
          const deliveryDate = formatDate(
            datetime,
            'DD MMM yyyy (ddd),HH:mm a',
          );
          dispatch(
            createAction('createMessage/updateMessageState')({
              scheduledDate: datetime,
              deliveryDate: deliveryDate,
            }),
          );
        }}
        error={isError()}
      />
      <ErrorFieldMessage
        id={CreateMessageError.scheduledDate.name}
        error={isError()}
        message={CreateMessageError.scheduledDate.message}
      />

      <AddCustomerGroupSegmentPrompt
        createItem="message"
        promptType={createNewType}
        setPromptType={setCreateNewType}
      />
    </>
  );
}

export default MessageSettingSection;
