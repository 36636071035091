import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getPagedNewsFeeds = (search = {}) => {
  const pageSize = search.pageSize || 20;
  const afterCursor = search.afterCursor || '';
  const orderBy = search.sort || '-pk';
  let searchValue = search.search ? `, nameIcontains:"${search.search}"` : '';

  if (search.display_in) {
    searchValue += `, displayIn:"${search.display_in}"`;
  };
  if (search.status) {
    searchValue += `, isPublished:${search.status}`;
  };
  if (search.communityId) {
    searchValue += `, communityIn:"${search.communityId}"`;
  };

  const query = `{
    newsFeeds(first:${pageSize}, after:"${afterCursor}", orderBy:"${orderBy}"${searchValue}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          description
          tag
          coverPhoto
          displayIn
          communities {
              edges {
                  node {
                      pk
                      name
                  }
              }
          }
          isPublished
          publicationDate
          creationDate
          lastModifiedDate
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getOneObject = (id) => {
  const query = `{
    newsFeed(id: "${id}") {
      id
      pk
      name
      description
      tag
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      displayIn
      communities {
          edges {
              node {
                  pk
                  name
              }
          }
      }
      isPublished
      publicationDate
      creationDate
      lastModifiedDate
      translations {
           edges {
              node {
                  pk
                  language
                  name
                  description
                  tag
                  coverPhoto
                  detailPhoto1
                  detailPhoto2
                  detailPhoto3
                  detailPhoto4
              }
          }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteItems = (ids) => {
  const query = `mutation DeleteNewsFeeds($input: DeleteNewsFeedsInput!) {
    deleteNewsfeeds(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdate = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}NewsFeed($input: ${action}NewsFeedInput!) {
    ${action.toLowerCase()}Newsfeed(input: $input) {
      success
        node {
          id
          pk
          name 
        }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishOrUnpublishNewsFeed = ({ id, isPublish }) => {
  const action = isPublish ? 'Publish' : 'Unpublish';
  let query = `mutation ${action}NewsFeed($input: ${action}NewsFeedInput!) {
    ${action.toLowerCase()}Newsfeed(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: { id } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteNewsFeedsInCommunity = ({ communityId, ids }) => {
  const query = `mutation DeleteNewsFeedsInCommunity($input: DeleteNewsFeedsInCommunityInput!) {
    deleteNewsFeedsInCommunity(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = { input: { communityId: communityId, ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
}
