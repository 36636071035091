export const ErrorHandleFields = {
  name: {
    required: 'Please provide name',
  },
  termsAndCondition: {
    required: 'Please provide terms & condition',
  },
  coverPhoto: {
    required: 'Please provide cover photo',
  },
  communityCode: {
    required: 'Unique code, maximum 8 digits with a mix of capital letters and number',
  },
  communityLimit: {
    valid: 'Please provide valid number',
  },
};
