import React from 'react';
import { Image, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  LanguageConfig,
  LanguageTabTransLation,
  MissionType,
} from '../../config/CustomEnums';
import './ContentSection.scss';
import { MissionEarningIcon } from '../../models/MissionModel';

const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];


function ContentSection({
  missionDetail,
  icon,
  trigger,
  translations = {},
  missionType,
  linkedCampaign,
  joinedTimes,
  recyclingItems,
  missionEarningType,
  setLevels,
  missionItems,
  totalTarget,
  individualTarget,
  communityOnly,
  linkedCommunity,
}) {
  const isHabitual = missionType === MissionType.habitual;
  const isGroup = missionType === MissionType.group;

  const showdown = require('showdown');
  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value) : '-',
        }}
      ></td>
    );
  };

  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const sectionValues = (valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) =>
          fieldValueText(translations[language]?.[valueKey], index),
        )}
      </>
    );
  };

  const getMissionIcon = (value, needIcon = true) => {
    return (
      <>
        {fieldsLanguages.map((language, index) => (
          <td key={`mission-icon-${language}-${index}`}>
            {language === LanguageConfig.english
              ? (
                <div>
                  {needIcon ? (
                    <Image
                      className="mission-content-section-icon"
                      src={value?.value?.icon}
                    />
                  ) : null}
                  <label
                    className={needIcon ? 'mission-content-section-icon-label' : ''}
                  >
                    {value?.label || '-'}
                  </label>
                </div>
              )
              : ('-')}
          </td>
        ))}
      </>
    );
  };

  const getTableRow = (name, value, hasTranslation = false, valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) => (
          <td key={`${name}-${language}-${index}`}>
            {hasTranslation
              ? value?.translations?.[language]?.[valueKey] || '-'
              : language === LanguageConfig.english && value
                ? value
                : '-'}
          </td>
        ))}
      </>
    );
  };

  const getLevelRow = (name, value, levelGrade) => {
    return (
      <>
        {fieldsLanguages.map((language, index) => {
          const currentLevelData = value[language]?.filter(
            (item) => item.levelGrade === levelGrade,
          )[0];
          let secondRowValue = '-';
          if (currentLevelData?.joinedTimes) {
            secondRowValue = `${currentLevelData?.joinedTimes} times`;
          } else if (currentLevelData?.recyclingItems) {
            secondRowValue = `${currentLevelData?.recyclingItems} items`
          }
          return (
            <td key={`${name}-${language}-${index}`}>
              <div className="d-flex flex-column">
                <label className="mission-content-section-icon-label">
                  {currentLevelData?.levelName || '-'}
                </label>
                <label className="mission-content-section-icon-label">
                  {secondRowValue}
                </label>
              </div>
            </td>
          );
        })}
      </>
    );
  };

  const getMissionTypeSection = (missionType) => {
    switch (missionType) {
      case MissionType.onboardingMission:
        return (
          <tr>
            {fieldNameText('Trigger Page')}
            {getTableRow('trigger-page', trigger?.label)}
          </tr>
        );
      case MissionType.campaignLinked:
        return (
          <>
            <tr>
              {fieldNameText('Link to campaign')}
              {getTableRow('link-campaign', linkedCampaign?.value, true, 'generalName')}
            </tr>

            <tr>
              {fieldNameText('Earning rule’s name')}
              {getTableRow('earning-rule', linkedCampaign?.value?.earningRule?.name)}
            </tr>

            <tr>
              {fieldNameText('Joined times')}
              {getTableRow('joined-times', joinedTimes)}
            </tr>

            <tr>
              {fieldNameText('Recycling items')}
              {getTableRow('recyling-items', recyclingItems)}
            </tr>
          </>
        );
      case MissionType.habitual:
        const enHabitualLevels = translations?.[LanguageConfig.english]?.habitualLevels || [];
        const habitualLevels = {
          [LanguageConfig.english]: enHabitualLevels,
          [LanguageConfig.simplifiedChinese]: translations?.[LanguageConfig.simplifiedChinese]?.habitualLevels || [],
          [LanguageConfig.traditionalChinese]: translations?.[LanguageConfig.traditionalChinese]?.habitualLevels || [],
        }
        return (
          <>
            <tr>
              {fieldNameText('Progress unit')}
              {sectionValues('progressUnit')}
            </tr>

            <tr>
              {fieldNameText('Link to campaign')}
              {getTableRow('link-campaign', linkedCampaign?.value, true, 'generalName')}
            </tr>

            <tr>
              {fieldNameText('Earning rule’s name')}
              {getTableRow('earning-rule', linkedCampaign?.value?.earningRule?.name)}
            </tr>

            <tr>
              {fieldNameText('Set levels')}
              {getTableRow('set-levels', setLevels?.label)}
            </tr>

            {enHabitualLevels.map((item, index) => (
              <tr key={`level-${item.levelGrade}`}>
                {fieldNameText(`Level ${item.levelGrade}`)}
                {getLevelRow('level', habitualLevels, item.levelGrade)}
              </tr>
            ))}
          </>
        );
      case MissionType.group:
        return (
          <>
            {missionEarningType?.value?.value === MissionEarningIcon.PHOTO_TAKING.value ||
              (!missionEarningType?.value?.value && linkedCampaign?.value?.earningRule?.type === MissionEarningIcon.PHOTO_TAKING.value)
              ? <tr>
                {fieldNameText('Mission item(s)')}
                {getTableRow('mission-items', missionItems?.label)}
              </tr>
              : null
            }
            <tr>
              {fieldNameText('Link to campaign')}
              {getTableRow('link-campaign', linkedCampaign?.value, true, 'generalName')}
            </tr>

            <tr>
              {fieldNameText('Earning rule’s name')}
              {getTableRow('earning-rule', linkedCampaign?.value?.earningRule?.name)}
            </tr>
            <tr>
              {fieldNameText('Mission Target')}
              {getTableRow('total-target', totalTarget)}
            </tr>
            <tr>
              {fieldNameText('Reminder bar: Min. requirement checking')}
              {getTableRow('individual-target', individualTarget)}
            </tr>
            <tr>
              {fieldNameText('Reminder bar: Eligible to collect')}
              {getTableRow('reminder-eligible', missionDetail, true, 'reminderEligible')}
            </tr>
            <tr>
              {fieldNameText('Reminder bar: Ineligible to collect')}
              {getTableRow('reminder-ineligible', missionDetail, true, 'reminderIneligible')}
            </tr>
            <tr>
              {fieldNameText('Reminder bar: Completed')}
              {getTableRow('reminder-complete', missionDetail, true, 'reminderComplete')}
            </tr>
            <tr>
              {fieldNameText('Reminder bar: Expired')}
              {getTableRow('reminder-expired', missionDetail, true, 'reminderExpired')}
            </tr>
          </>
        )
      default:
        break;
    }
  }

  return (
    <>
      <label className="create-section-title">CONTENT</label>
      <PerfectScrollbar>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
              {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText('Name')}
              {sectionValues('name')}
            </tr>

            {isHabitual || isGroup ? null : (
              <tr>
                {fieldNameText('Description title')}
                {sectionValues('descriptionTitle')}
              </tr>
            )}

            <tr>
              {fieldNameText('Detail section content')}
              {sectionValues('descriptionContent')}
            </tr>
            {
              isGroup && communityOnly
                ? (
                  <tr>
                    {fieldNameText('Link to community')}
                    {getTableRow(
                      'link-community',
                      typeof linkedCommunity?.value?.name == "object"
                        ? linkedCommunity?.value?.name?.en
                        : linkedCommunity?.value?.name
                    )}
                  </tr>
                )
                : null
            }
            {isHabitual || isGroup ? (
              <tr>
                {fieldNameText('Mission earning type')}
                {getMissionIcon(missionEarningType, false)}
              </tr>
            ) : (
              <tr>
                {fieldNameText('Mission icon')}
                {getMissionIcon(icon)}
              </tr>
            )}

            {getMissionTypeSection(missionType)}
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  missionDetail: state.mission.missionDetail,
  icon: state.mission.missionDetail.icon,
  trigger: state.mission.missionDetail.trigger,
  translations: state.mission.missionDetail.translations,
  missionType: state.mission.missionDetail.missionType,
  linkedCampaign: state.mission.missionDetail.linkedCampaign,
  joinedTimes: state.mission.missionDetail.joinedTimes,
  recyclingItems: state.mission.missionDetail.recyclingItems,
  missionEarningType: state.mission.missionDetail.missionEarningType,
  setLevels: state.mission.missionDetail.setLevels,
  missionItems: state.mission.missionDetail.missionItems,
  totalTarget: state.mission.missionDetail.totalTarget,
  individualTarget: state.mission.missionDetail.individualTarget,
  communityOnly: state.mission.missionDetail.communityOnly,
  linkedCommunity: state.mission.missionDetail.linkedCommunity,
});

export default connect(mapStateToProps)(ContentSection);