import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomRadios from '../base/CustomRadios';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from '../earning/CustomBaseComponments';
import { Image } from 'react-bootstrap';
import addImage from '../../assets/images/addx1.png';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { firstError, isShowError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { PermissionCodes } from '../../config/PermissionCodes';
import { CreateRewardCodeError, RewardCodeRewardType, RewardCodeRewardTypeList, RewardCodeType } from '../../models/RewardCodeModel';
import BasePrompt from '../base/prompt/BasePrompt';

const RewardSection = ({
  rewardCodeDetail,
  errorFields,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);

  const {
    pk,
    codeType,
    rewardType,
    linkedCouponSet,
    couponQuantity,
    points,
    perHeadLimit,
  } = rewardCodeDetail || {};

  const isError = (field) => {
    return isShowError(field, errorFields);
  };

  useEffect(() => {
    [
      CreateRewardCodeError.couponSet.name,
      CreateRewardCodeError.couponQuantity.name,
      CreateRewardCodeError.points.name,
      CreateRewardCodeError.perHeadLimit.name,
    ].forEach(item => {
      firstError(item, errorFields);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">REWARD</label>
      <CustomTitleLabel title="Reward type" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          default={rewardType}
          onChange={(value) => {
            dispatch({
              type: 'rewardCode/updateDetail',
              payload: { rewardType: value },
            });
          }}
          options={RewardCodeRewardTypeList}
        />
      </div>

      {rewardType === RewardCodeRewardType.COUPONS.value ? (
        <>
          <CouponSelectDropdown
            showMultiLine
            title='Coupon set'
            titleTips='Reminder: "Total available coupon sets" has to be more than or equal to "Total Number of Codes x Quantity"'
            defaultValue={{
              value: linkedCouponSet,
              label: linkedCouponSet ? `${linkedCouponSet?.name} (ID: ${linkedCouponSet?.pk})` : ''
            }}
            setValue={(value) => {
              dispatch({
                type: 'rewardCode/updateDetail',
                payload: { linkedCouponSet: value },
              });
            }}
            sourceShowId={true}
            addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            isPrompt
            errors={errorFields}
            errorName={CreateRewardCodeError.couponSet.name}
            addButtonInfo={{
              requires: PermissionCodes.addCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
          />
          <CustomNumberInputWithUnit
            title={'Quantity'}
            unit={'coupons per code'}
            defaultValue={couponQuantity}
            setValue={(value) => {
              dispatch({
                type: 'rewardCode/updateDetail',
                payload: { couponQuantity: value },
              });
            }}
            errorId={CreateRewardCodeError.couponQuantity.name}
            error={isError(CreateRewardCodeError.couponQuantity.name)}
            errorMessage={CreateRewardCodeError.couponQuantity.message}
          />
        </>
      ) : null}

      {rewardType === RewardCodeRewardType.POINTS.value ? (
        <CustomNumberInputWithUnit
          title={'No of points'}
          unit={'points'}
          defaultValue={points}
          setValue={(value) => {
            dispatch({
              type: 'rewardCode/updateDetail',
              payload: { points: value },
            });
          }}
          errorId={CreateRewardCodeError.points.name}
          error={isError(CreateRewardCodeError.points.name)}
          errorMessage={CreateRewardCodeError.points.message}
        />
      ) : null}

      {
        codeType === RewardCodeType.UNIQUE_CODE.value
          ? <CustomNumberInputWithUnit
            title={'Per head limit'}
            unit={'times'}
            defaultValue={perHeadLimit}
            setValue={(value) => {
              dispatch({
                type: 'rewardCode/updateDetail',
                payload: { perHeadLimit: value },
              });
            }}
            errorId={CreateRewardCodeError.perHeadLimit.name}
            error={isError(CreateRewardCodeError.perHeadLimit.name)}
            errorMessage={CreateRewardCodeError.perHeadLimit.message}
          />
          : null
      }

      <BasePrompt
        show={showCreateNewLinkPrompt}
        closeAction={() => setShowCreateNewLinkPrompt(false)}
        rightButton={{
          text: 'Go to create coupon set',
          action: () => {
            history.push({
              pathname: '/coupons/create',
              state: {
                from: pk ? `/reward_code/${pk}/edit/` : '/reward_code/create',
                title: 'Continue To Create Registration Code',
                content: 'You can continue to create the registration code.',
              },
            });
          },
        }}
        title={'Go to create coupon set'}
        description={
          'You will leave the registration code creation process. After you create a coupon set, you can come back to create a registration code.'
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
  errorFields: state.rewardCode.errorFields,
});

export default connect(mapStateToProps)(RewardSection);
