import { useDispatch, useSelector, dispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import BaseEditSection from '../../base/BaseEditSection';
import { createAction, getError, useCompare } from '../../../utils';
import { AfcDataRecordErrorHandleFields } from '../../../models/AfcDataRecordModel';
import { APIStatus, AfcDataStatus } from '../../../config/CustomEnums';
import { Row } from 'react-bootstrap';
import CustomRadios from '../../../components/base/CustomRadios';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import CustomDateTimeSelect from '../../../components/base/CustomDateTimeSelect';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';
import userEvent from '@testing-library/user-event';
import CustomerFilterableDropdown from '../../../components/transactions/CustomerFilterableDropdown';
import Loading from '../../../components/base/Loading';




function CreateOrUpdateAfcDataRecord() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorFields, setErrorFields] = useState({ fields: [], messages: [] });
  const apiStatus = useSelector((state) => state.afcDataRecords.apiStatus);  
  const { afcDataRecord } = useSelector((state) => ({
    afcDataRecord: state.afcDataRecords.afcDataRecord || {},
  }));


  useEffect(() => {
    return () => {
      dispatch(createAction('afcDataRecords/clearData')({}));
    };
  }, []);

  const [customerId, setCustomerId] = useState('');
  const [octopusId, setOctopusId] = useState('');
  const [stationFrom, setStationFrom] = useState(null);
  const [stationTo, setStationTo] = useState(null);
  const [status, setStatus] = useState(AfcDataStatus.SUCCESS)
  const [transactionDatetime, setTransactionDatetime] = useState(null);
  const [customer, setCustomer] = useState({});
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (apiStatus === APIStatus.success) {
      if(params.id)
        history.push('/afc_data_record/' + params.id + '/');
      else 
        history.push('/afc_data_record');
    }
  }, [apiStatus]);

  useEffect(() => {

    setTimeout(() => {
      dispatch({
        type: 'afcDataRecords/updateState',
        payload: { formChanged: false },
      });
    }, 500);
  }, [dispatch]);

  
  useEffect(() => {
    return () => {
      dispatch(createAction('appVersion/clearData')({}));
    };
  }, []);

  useEffect(() => {
    console.log('@53', params, afcDataRecord)

    if(params.id){
      setCustomerId(afcDataRecord.customerId)
      setOctopusId(afcDataRecord.octopusId)
      setStationFrom(afcDataRecord.stationFrom)
      setStationTo(afcDataRecord.stationTo)
      setStatus(afcDataRecord.status)
      setTransactionDatetime(afcDataRecord.transactionDatetime)
      setCustomer(afcDataRecord.customer || {})

      // console.log('@69a', afcDataRecord.transactionDatetime, transactionDatetime)

      const recordId = params.id
      // load data from api
      if(!afcDataRecord.id){
        dispatch(
          createAction('afcDataRecords/getOneAfcDataRecord')({ id: recordId }),
        );
      }
      else {
        setIsLoaded(true)
      }
    }
  },  [dispatch, params.id, afcDataRecord.id]);

  const getTransactionDateControl = () => {
    const transactionDateError = getError(
      'transactionDate',
      errorFields,
      AfcDataRecordErrorHandleFields,
    );

    

    // console.log('@69aa||' + transactionDatetime + '||')

    const defaultDatetime = transactionDatetime ? new Date(transactionDatetime) : new Date()

    // console.log('@69b', transactionDateError, transactionDatetime, defaultDatetime, new Date(transactionDatetime))
    // console.log('@69bxxxx', defaultDatetime)

    return (
      <div>
      <CustomTitleLabel title="Transaction date" />
      <CustomDateTimeSelect
        defaultTime={defaultDatetime}
        error={transactionDateError.error}
        onTimeChange={(date) => {
          // console.log('@69c, 108', date)
          setTransactionDatetime(date)
        }}
      />
    </div>
    )
  }


  const getRadioOptions = () => [
    {
      label: 'Failed',
      value: AfcDataStatus.FAILED
    },
    {
      label: 'Success',
      value: AfcDataStatus.SUCCESS,
    },
  ];

  let sections

  if (params.id && !isLoaded) { // edit mode, data not loaded
    sections = [ <Loading/> ]
  }
  else 
    sections = [
    <BaseEditSection
      title="General"
      fields={[

      <CustomerFilterableDropdown
        title={'Customer'}
        showMembershipId={true}
        errorFields={errorFields.fields}
        defaultSelectedCustomer={{
          label: customer.firstName ? customer.firstName + ' ' + customer.lastName : '',
          value: customer,
        }}
        showRemoveButton={true}
        onRemoveButtonClick={()=>{
          console.log('@191');
          setCustomer({})
        }}
        needLevelFilter={true}
        selectCustomer={(customer) => {
          console.log('@163', customer)
          setCustomer(customer.value)
        }}
      />,

        <CustomTitleWithInput
          title={'Octopus Id'}
          defaultValue={octopusId}
          setValue={(value) => {
            let data = value;
            setOctopusId(data);
          }}
          error={getError(
            'octopusId',
            errorFields,
            AfcDataRecordErrorHandleFields,
          )}
        />,

  
        <CustomTitleWithInput
        title={'Station From'}
        defaultValue={stationFrom}
        setValue={(value) => {
          let data = value;
          data = data.replace(/[^0-9.]/g, '');
          setStationFrom(data);
        }}
        error={getError(
          'stationFrom',
          errorFields,
          AfcDataRecordErrorHandleFields,
        )}
      />,

      <CustomTitleWithInput
      title={'Station To'}
      defaultValue={stationTo}
      setValue={(value) => {
        let data = value;
        data = data.replace(/[^0-9.]/g, '');
        setStationTo(data);
      }}
      error={getError(
        'stationTo',
        errorFields,
        AfcDataRecordErrorHandleFields,
      )}
    />,
    getTransactionDateControl(),

  
  <div className="create-section-label-bottom-space">
  <label className="create-section-label featured-exclusive-row-tip-label">
    Status
  </label>
  <Row className="featured-exclusive-row">
    <CustomRadios
      onChange={(value) => {
        setStatus(value);
      }}
      default={status}
      options={getRadioOptions()}
    />
  </Row>
</div>

      ]}
    />,
  ];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />
      
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={(event) => {
          const fields = [];
          const messages = [];
          if(octopusId === '' || octopusId === undefined){
            fields.push('octopusId');
            messages.push({
              field: 'octopusId',
              errorType: 'required',
            });
          }
          if (stationFrom === null || stationFrom === undefined) {
            fields.push('stationFrom');
            messages.push({
              field: 'stationFrom',
              errorType: 'required',
            });
          }
          if (stationTo === null || stationTo === undefined) {
            fields.push('stationTo');
            messages.push({
              field: 'stationTo',
              errorType: 'required',
            });
          }
 
          setErrorFields({
            fields,
            messages,
          });
          if (fields.length > 0) {
            return;
          }
          const formAction = params.id ? 'edit' : 'create'
          
          // console.log('@181a', customer)
          dispatch(
            createAction('afcDataRecords/createOrUpdateAfcDataRecord')({
              octopusId, stationFrom, stationTo, status, transactionDatetime, formAction,
              customer: customer.pk ? customer.pk : null,
              id: params.id
            }),
          );

        }}
        backAction={() => history.goBack()}
      />
    </>,
  ];



  const titleAction = params.id ? 'Edit' : 'Create'
  return (
    <ScrollableComponent
      content={stepSet}
      currentStep={0}
      breadcrumb={<CustomBreadcrumb name={titleAction + ' AFC Data Record'} />}
      caution={{
        detail: '',
        title: titleAction + ' AFC Data Record',
        model: 'afcDataRecords',
      }}
    />
  );
}

export default CreateOrUpdateAfcDataRecord;
