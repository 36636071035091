import React from 'react';
import { connect } from 'react-redux';
import { getReadablePeriod } from '../../utils/TimeFormatUtil';
import { RewardCodeRewardType, RewardCodeRewardTypeList, RewardCodeType, RewardCodeTypeList } from '../../models/RewardCodeModel';

const ProfileSection = ({
  rewardCodeDetail,
}) => {
  const {
    pk,
    category,
    linkedBrand,
    codeType,
    code,
    totalCodes,
    totalNumberOfSingleCodes,
    translations = {},
    rewardType,
    linkedCouponSet,
    couponQuantity,
    points,
    perHeadLimit,
    isAlwaysActivePeriod,
    activeStartDate,
    activeEndDate,
  } = rewardCodeDetail || {};

  const readableCodeType = RewardCodeTypeList.filter(item => item.value === codeType)?.[0]?.label;
  const readableRewardType = RewardCodeRewardTypeList.filter(item => item.value === rewardType)?.[0]?.label;

  const profileField = (field, value, defaultValue) => {
    return (
      <div className='d-flex flex-column' style={{ flex: 1 }}>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        <label className="create-section-field-input profile-section-label-bottom">
          {value || defaultValue || '-'}
        </label>
      </div>
    );
  };

  return (
    <>
      <label className="create-section-title">PROFILES</label>
      <div className='d-flex flex-row'>
        {profileField(
          'Active period',
          getReadablePeriod(activeStartDate, activeEndDate, isAlwaysActivePeriod),
        )}
        {profileField('Code type', readableCodeType)}
        {profileField(
          'Total number of codes',
          codeType === RewardCodeType.GENERIC_CODE.value ? totalNumberOfSingleCodes : totalCodes,
          "0"
        )}
        {profileField('Related brand', linkedBrand?.name)}
      </div>
      <div className="d-flex flex-row">
        {profileField('Reward type', readableRewardType)}
        {profileField('No of points', rewardType === RewardCodeRewardType.POINTS.value ? points : null)}
        {profileField('', '', ' ')}
        {profileField('', '', ' ')}
      </div>
      <div className="d-flex flex-row">
        {profileField('Coupon set', rewardType === RewardCodeRewardType.COUPONS.value && linkedCouponSet ? `${linkedCouponSet?.name} (ID: ${linkedCouponSet?.pk})` : null)}
        {profileField('Quantity', rewardType === RewardCodeRewardType.COUPONS.value ? couponQuantity : null)}
        {profileField(
          codeType === RewardCodeType.GENERIC_CODE.value ? '' : 'Per head limit',
          codeType === RewardCodeType.GENERIC_CODE.value ? '' : perHeadLimit,
          codeType === RewardCodeType.GENERIC_CODE.value ? ' ' : '-',
        )}
        {profileField('', '', ' ')}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
});

export default connect(mapStateToProps)(ProfileSection);