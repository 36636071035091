import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import AuthButton from '../../../components/base/AuthButton';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { NavBarNames } from '../../../config/NavBarNameList';
import { createAction } from '../../../utils';
import BaseListContainer from '../../base/BaseListContainer';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import DeletePrompt from '../../../components/base/DeletePrompt';

function AppVersionList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { listFields, dataList, pageInfo, totalPage, totalCount, checkedList } =
    useSelector((state) => ({
      listFields: state.appVersion.listDisplayFields,
      dataList: state.appVersion.appVersionList,
      pageInfo: state.appVersion.pageInfo,
      totalPage: state.appVersion.totalPage,
      totalCount: state.appVersion.totalCount,
      checkedList: state.appVersion.checkedList,
    }));

  const createAppVersion = () => {
    // dispatch({ type: `AppVersion/create` });
    history.push('/app_version/create');
  };

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={580}
      hideAllButtonWidth={580}
      primaryButton={
        <AuthButton
          title="Add new version"
          action={createAppVersion}
          requires={PermissionCodes.changeAppVersion}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.changeAppVersion,
        action: createAppVersion,
        content: 'AppVersion',
      }}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeAppVersion,
    },
  ];

  const tabs = [
    {
      name: 'List of app version',
      content: (
        <BaseTabListContainer
          hideTab={true}
          hideSearch={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'appVersion'}
              permissionGroup={PermissionCodes.appVersion}
              deleteInfo={{
                data: [],
                title: 'App version',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.APPVERSION,
              }}
              actions={'Delete'}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: `appVersion/clearData` });
    dispatch(
      createAction('appVersion/getAppVersions')({
        page: 1,
        reverse: true,
      }),
    );
  }, [dispatch, history.location]);

  return (
    <>
      <CustomListComponent
        caution={{
          title: NavBarNames.appversion,
        }}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'App Version'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.APPVERSION}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('appVersion/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

export default AppVersionList;
