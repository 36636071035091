import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import i18n from "../../I18n";
import { IMAGE_TYPES } from "../../models/UploadFilesModel";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Table, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  LanguageTabTransLation,
  LanguageConfig,
} from '../../config/CustomEnums';
import { createAction } from "../../utils";

const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
];

function WhatToDoNextKeyVisualCard({
  language,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    whatToDoNext,
  } = useSelector((state) => ({
    whatToDoNext: state.whatToDoNextList.whatToDoNext,
  }));
  const data = {};

  useEffect(() => {
    dispatch(
      createAction('whatToDoNextList/getWhatToDoNext')({
        id: params.id,
      }),
    );
  }, [dispatch, params.id]);

  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value?.replace(/<br>\n/g, '<br>')?.replace(/\\,/g, ',')) : '-',
        }}
      ></td>
    );
  };

  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const getCustomImages = (deviceSize) => {
    return (
      <>
        {fieldsLanguages.map((language, index) => {
          let images = []
          if (language === LanguageConfig.english) {
            if (deviceSize === 'small') {
              images.push(whatToDoNext.translations[language].customImageSmallDevice1)
              images.push(whatToDoNext.translations[language].customImageSmallDevice2)
              images.push(whatToDoNext.translations[language].customImageSmallDevice3)
              images.push(whatToDoNext.translations[language].customImageSmallDevice4)
              images.push(whatToDoNext.translations[language].customImageSmallDevice5)
              images.push(whatToDoNext.translations[language].customImageSmallDevice6)
            } else if (deviceSize === 'large') {
              images.push(whatToDoNext.translations[language].customImageLargeDevice1)
              images.push(whatToDoNext.translations[language].customImageLargeDevice2)
              images.push(whatToDoNext.translations[language].customImageLargeDevice3)
              images.push(whatToDoNext.translations[language].customImageLargeDevice4)
              images.push(whatToDoNext.translations[language].customImageLargeDevice5)
              images.push(whatToDoNext.translations[language].customImageLargeDevice6)
            }

          } else if (language === LanguageConfig.traditionalChinese) {
            if (deviceSize === 'small') {
              images.push(whatToDoNext.translations[language].customImageSmallDevice1)
              images.push(whatToDoNext.translations[language].customImageSmallDevice2)
              images.push(whatToDoNext.translations[language].customImageSmallDevice3)
              images.push(whatToDoNext.translations[language].customImageSmallDevice4)
              images.push(whatToDoNext.translations[language].customImageSmallDevice5)
              images.push(whatToDoNext.translations[language].customImageSmallDevice6)
            } else if (deviceSize === 'large') {
              images.push(whatToDoNext.translations[language].customImageLargeDevice1)
              images.push(whatToDoNext.translations[language].customImageLargeDevice2)
              images.push(whatToDoNext.translations[language].customImageLargeDevice3)
              images.push(whatToDoNext.translations[language].customImageLargeDevice4)
              images.push(whatToDoNext.translations[language].customImageLargeDevice5)
              images.push(whatToDoNext.translations[language].customImageLargeDevice6)
            }
          }
          return (
            <td key={`coverImage-${language}-${index}`}>
              {
                images.map(i => i === '' ? '' : <Image
                  className="content-section-rectangle-image"
                  src={i}
                />)
              }
            </td>
          );
        })}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">{i18n.t('what_to_do_next_key_visual', { locale: language })}</label>
      <div style={{ marginTop: 10 }}>
        <label>{i18n.t('what_to_do_next_cover_image', { locale: language })}</label>

      </div>
      <PerfectScrollbar>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText('Custom image (Small device)')}
              {getCustomImages('small')}
            </tr>
            <tr>
              {fieldNameText('Custom image (Large device)')}
              {getCustomImages('large')}
            </tr>
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}


export default WhatToDoNextKeyVisualCard;
