import React, { useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { createAction } from "../../utils";
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import i18n from "../../I18n";
import { Image } from 'react-bootstrap';
import './WhatToDoNext.scss';

function WhatToDoNextGeneralCard({
  language,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    whatToDoNext,
  } = useSelector((state) => ({
    whatToDoNext: state.whatToDoNextList.whatToDoNext,
  }));

  useEffect(() => {
    dispatch(
      createAction('whatToDoNextList/getWhatToDoNext')({
        id: params.id,
      }),
    );
  }, [dispatch, params.id]);

  console.log('@whatToDoNext', whatToDoNext)

  return (
    <>
      <div className="first-section-title">{i18n.t('what_to_do_next_general', { locale: language })}</div>
      <CustomTitleLabel title={i18n.t('what_to_do_next_name')} />
      <label>{whatToDoNext.translations.en.name}</label>

      <CustomTitleLabel title={i18n.t('what_to_do_next_cover_image')} />
      <Image src={whatToDoNext.coverPhoto} className="cover-image" />

      <CustomTitleLabel title={i18n.t('what_to_do_next_display_in_guest_view')} />
      <label>{whatToDoNext.displayInGuestView ? i18n.t('yes') : i18n.t('no')}</label>

      <CustomTitleLabel title={i18n.t('what_to_do_next_display_order')} />
      <label>{whatToDoNext.order}</label>
    </>
  );
}

export default WhatToDoNextGeneralCard
// const mapStateToProps = (state) => ({});

// export default connect(mapStateToProps)(WhatToDoNextGeneralCard);
