import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import {
  APIStatus,
  LanguageConfig,
  LanguageUpperConfig,
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';

const LanguageList = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
]

const showdown = require('showdown');
const converter = new showdown.Converter();
converter.setOption('simpleLineBreaks', true);
const MutipleLanguageContentCard = ({ fields, data }) => {
  console.log('@@21: ', fields);
  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(800);

  const getTranslation = (language, field) => {
    if (data?.storeTranslations) {
      return data?.storeTranslations[language]
        ? data?.storeTranslations[language][field.name]
        : '-';
    }
    return '-';
  };
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, []);

  const fieldValueText = (fieldName, value, index) => {
    return (
      <td
        key={`fieldValueText-${fieldName}-${index}`}
        style={{
          maxWidth: `${(tableWidth - 161) / 3}px`,
          whiteSpace: 'pre-wrap',
          lineBreak: 'anywhere',
        }}
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value) : '-',
        }}
      ></td>
    );
  };

  const sectionValues = (field) => {
    return (
      <>
        {LanguageList.map((language, index) => {
          return fieldValueText(field?.displayName, data?.[field?.name]?.[language], index)
        })}
      </>
    )
  }

  return (
    <>
      <label className="create-section-title">CONTENT</label>
      <PerfectScrollbar>
        <table ref={tableRef} className="campaign-category-detail-table">
          <thead>
            <th></th>
            <th>English</th>
            <th>繁體</th>
            <th>简体</th>
          </thead>
          <tbody>
            {fields.map((field) => (
              <tr>
                <td>{field.displayName}</td>
                {sectionValues(field)}
              </tr>
            ))}
          </tbody>
        </table>
      </PerfectScrollbar>
    </>
  );
};

function StoreDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { store, status } = useSelector((state) => ({
    store: state.storeModel.oneStore,
    status: state.loading.status,
  }));

  const tabs = [
    {
      name: 'Detail',
      content: (
        <ContentSections
          sections={
            status === APIStatus.calling
              ? [<Loading />]
              : [
                  <MutipleLanguageContentCard
                    fields={[
                      { displayName: 'Name', name: 'name' },
                      { displayName: 'Description', name: 'description' },
                      { displayName: 'Address', name: 'address' },
                    ]}
                    data={store}
                  />,
                  <MembershipInfoCard
                    title="information"
                    data={store}
                    fields={[
                      [{ title: 'Display order', field: 'displayPriority' }],
                      [{ title: 'Cover image', field: 'coverPhoto' }],
                      [{ title: 'Other image', field: 'otherPhoto'}],
                      [{ title: 'District', field: 'displayDistrict' }],
                      [{ title: 'Phone number', field: 'displayPhoneNumner' }],
                      [{ title: 'Email', field: 'emailAddress' }],
                      [{ title: 'Latitude', field: 'latitude' }],
                      [{ title: 'Longitude', field: 'longitude' }],
                      [{ title: 'Staff code', field: 'staffCode' }],
                      [{ title: 'Subcategories', field: 'displaySubcategory' }],
                      [{ title: 'Brand', field: 'displayBrand' }],
                      [{ title: 'Reverse Vending Machine', field: 'isReverseVendingMachineDisplay' }],
                      [{ title: 'Security Key', field: "securityKey"}]
                    ]}
                  />,
                ]
          }
          hidePreview
          // sectionStyle="detail-info-section-item"
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        // dispatch({ type: 'customerList/clearData' });
        dispatch({ type: 'storeModel/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeStore}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('storeModel/getOneStore')({ id: params.id }),
      );
    }
    return () => {
      dispatch({ type: 'storeModel/clearData' });
    };
  }, [dispatch, params.id]);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: store.name?.en,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={store.name?.en} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

export default StoreDetail;
