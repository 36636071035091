import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomSwitchButton from '../base/CustomSwitchButton';
import BasePrompt from '../base/prompt/BasePrompt';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
  CustomTitleWithRadio,
} from './CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { createAction, useCompare } from '../../utils';
import { useHistory } from 'react-router-dom';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import { CampaignType, EarningRuleType } from '../../config/CustomEnums';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function HabitSection(props) {
  const dispatch = useDispatch();
  const defaultValue = props.defaultValue || {};

  const [isAllCampaign, setIsAllCampaign] = useState(
    defaultValue.isAllCampaign || false,
  );
  const [habitCampaigns, setHabitCampaigns] = useState(
    defaultValue.habitCampaigns,
  );
  const [habitTimePeriod, setHabitTimePeriod] = useState(
    defaultValue.habitTimePeriod || EarningRuleType.weeklyAchievement,
  );
  const [goal, setGoal] = useState(defaultValue.goal);
  const [habitPerHeadLimit, setHabitPerHeadLimit] = useState(
    defaultValue.habitPerHeadLimit,
  );

  const vals = {
    isAllCampaign,
    habitCampaigns,
    habitTimePeriod,
    goal,
    habitPerHeadLimit,
  };
  const hasValsChanged = useCompare(vals);
  const history = useHistory();

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields, campaignList } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
    campaignList: state.campaignList.allCampaignList,
  }));
  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    dispatch(
      createAction('campaignList/getCampaignList')({
        type: CampaignType.earningRulesCampaign,
        isSelectorLoad: true,
        expired: false,
        status: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.goal.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">Habit</label>

      {/* <CustomTitleWithRadio
        title=""
        setValue={setIsAllCampaign}
        defaultValue={isAllCampaign}
        options={[
          { label: 'All earning camaigns', value: true },
          { label: 'Special camaigns', value: false },
        ]}
      />
      {!isAllCampaign ? (
        <CustomAddRemoveMutipleSelector
          title={''}
          data={campaignList}
          selectedList={habitCampaigns}
          setValue={(value) => {
            setHabitCampaigns(value);
          }}
          sourceReminderText={'All earning campaign(s)'}
          amidReminderText={'Selected earning campaign(s)'}
          onFocusFunc={() => {}}
          // addButton={{
          //   action: () => setShowAddBrand(true),
          //   title: 'Create Now',
          //   requires: PermissionCodes.addBrand,
          //   customClass: 'general-section-add-new-brand btn-add-button-common',
          // }}
        />
      ) : null} */}
      {isAllCampaign ? null : (
        <>
          <CustomTitleWithDropDown
            title={'Select campaign to earn rewards'}
            // titleStyle={isPrompt ? '' : 'create-section-label-no-top-space'}
            defaultValue={habitCampaigns}
            setValue={setHabitCampaigns}
            source={campaignList}
            loadMoreAction={'campaignList/getCampaignList'}
            filterAction={'campaignList/getCampaignList'}
            defaultFilter={{
              type: CampaignType.earningRulesCampaign,
              expired: false,
              status: true,
              isAllActive: true,
            }}
            errors={errorFields}
            errorName={'habitCampaigns'}
          />
          <CustomTitleWithRadio
            title="Time period"
            setValue={setHabitTimePeriod}
            defaultValue={habitTimePeriod}
            options={[
              { label: 'Weekly', value: EarningRuleType.weeklyAchievement },
              { label: 'Monthly', value: EarningRuleType.monthlyAchievement },
            ]}
          />
        </>
      )}

      <CustomNumberInputWithUnit
        title="Required carbon saving value"
        unit="g"
        errorId={`${EarningRuleErrorHandleField.goal.name}`}
        error={isError('goal')}
        errorMessage={`${EarningRuleErrorHandleField.goal.message}`}
        defaultValue={goal}
        setValue={setGoal}
      />

      <CustomTitleLabel title="Per head limit (optional)" />
      <label className="tips-message">
        Maximum number of times to do habit per day.
      </label>
      <CustomNumberInputWithUnit
        defaultValue={habitPerHeadLimit}
        setValue={setHabitPerHeadLimit}
        unit="times"
      />
    </>
  );
}

export default HabitSection;
