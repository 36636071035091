import React, { useState, useEffect } from 'react';
import './MTRCreateTransactionStepThree.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Image, Row } from 'react-bootstrap';
import ContentSections from '../base/ContentSections';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import { createAction, isShowError } from '../../utils';
import {
  PhotoTakingSetItemsCom,
  UploadImageCom,
} from '../recylingRecords/PhotoTakingContent';
import { CustomNumberInputWithUnit, CustomTitleLabel } from '../earning/CustomBaseComponments';
import { CreateTransactionError, TRANSACTION_REWARD_TYPE } from '../../models/CreateTransactionModel';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { APIStatus, EarningRuleType, LanguageConfig, PaymentMethod } from '../../config/CustomEnums';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import CampaignSelectDropdown from '../message/CampaignSelectDropdown';
import CustomRadios from '../base/CustomRadios';
import { GeneralPurchaseSubType, GeneralPurchaseSubTypeOptions } from '../../models/CreateEarningRulesModel';
import MerchantNameFilterableDropdown from './MerchantNameFilterableDropdown';

const radioOptions = Object.keys(TRANSACTION_REWARD_TYPE).map(key => TRANSACTION_REWARD_TYPE[key])

const AdminCreateDetails = ({ transaction, errorFields }) => {
  const dispatch = useDispatch();
  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };
  return (
    <>
      <label className="create-section-title">Add carbon saving / CW Point(s)</label>
      <CustomTitleLabel title={'Reward type'} />
      <Row className="featured-exclusive-row">
        <CustomRadios
          disabled={false}
          onChange={(value) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                rewardType: value,
              })
            );
          }}
          default={transaction?.rewardType || TRANSACTION_REWARD_TYPE.CARBON_ONLY.value}
          options={radioOptions}
        />
      </Row>
      {
        transaction?.rewardType == TRANSACTION_REWARD_TYPE.POINT_ONLY.value
          ? null
          : <CustomNumberInputWithUnit
            title={'Value'}
            defaultValue={transaction?.rewardCarbonValue}
            setValue={(value) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  rewardCarbonValue: value,
                })
              );
            }}
            unit={'Carbon Savings'}
            errorId={CreateTransactionError.rewardCarbonValue.name}
            error={isError(CreateTransactionError.rewardCarbonValue.name)}
            errorMessage={CreateTransactionError.rewardCarbonValue.message}
          />
      }
      {
        transaction?.rewardType == TRANSACTION_REWARD_TYPE.CARBON_ONLY.value || !transaction?.rewardType
          ? null
          : <CustomNumberInputWithUnit
            title={'Value'}
            defaultValue={transaction?.rewardPointValue}
            setValue={(value) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  rewardPointValue: value,
                })
              );
            }}
            unit={'CW Point(s)'}
            errorId={CreateTransactionError.rewardPointValue.name}
            error={isError(CreateTransactionError.rewardPointValue.name)}
            errorMessage={CreateTransactionError.rewardPointValue.message}
          />
      }
    </>
  );
};


const WaterFilingDetails = ({ transaction, errorFields }) => {
  const dispatch = useDispatch();
  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };
  return (
    <>
      <label className="create-section-title">Water filling details</label>
      <CustomTitleLabel title={'Water volume(ml)(optional)'} />
      <div>
        <input
          type="text"
          className="text-input-field"
          onChange={(e) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                waterVolume: e.target.value,
              }),
            );
          }}
          value={transaction.waterVolume}
        />
      </div>
      <CustomTitleLabel title={'Machine ID'} />
      <label className="create-message-suggest">
        Need to fill in either Machine ID or Related campaign.
      </label>
      <div>
        <input
          disabled={transaction?.campaign}
          type="text"
          className={`text-input-field ${isError(CreateTransactionError.waterFillingMachineId.name) ||
            isError(CreateTransactionError.invalidMachineId.name)
            ? 'error-field'
            : ''
            }`}
          onChange={(e) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                machineId: e.target.value,
              }),
            );
          }}
          value={transaction.machineId}
        />
      </div>
      <ErrorFieldMessage
        error={
          isError(CreateTransactionError.waterFillingMachineId.name) ||
          isError(CreateTransactionError.invalidMachineId.name)
        }
        id={CreateTransactionError.waterFillingMachineId.name}
        message={
          isError(CreateTransactionError.invalidMachineId.name)
            ? CreateTransactionError.invalidMachineId.message
            : CreateTransactionError.waterFillingMachineId.message
        }
      />
      <CustomTitleLabel title={'Related campaign'} />
      <label className="create-message-suggest">
        Need to fill in either Machine ID or Related campaign.
      </label>
      <div
        style={{
          pointerEvents: transaction.machineId ? 'none' : 'auto',
        }}
      >
        <CampaignSelectDropdown
          showRemoveButton
          onRemoveButtonClick={() => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                campaign: null,
              }),
            );
          }}
          defaultFilter={{ earningRuleType: EarningRuleType.waterFilling }}
          defaultValue={{ label: transaction?.campaign?.name }}
          setValue={(campaign) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                campaign,
              }),
            );
          }}
          errors={errorFields}
          errorName={CreateTransactionError.waterFillingMachineId.name}
          errorMessage={CreateTransactionError.waterFillingMachineId.message}
        />
      </div>
    </>
  );
};

const GreenDiningDetails = ({ transaction, errorFields }) => {
  const dispatch = useDispatch();
  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };
  const paymentMethodOptions = [
    { label: 'Cash', value: PaymentMethod.CASH },
    { label: 'Credit card', value: PaymentMethod.CREDIT_CARD },
    { label: 'Octopus', value: PaymentMethod.OCTOPUS },
  ];

  return (
    <>
      <label className="create-section-title">General purchase details</label>

      <div>
        <CustomTitleLabel title="Payment method" />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <CustomRadios
            onChange={(value) => {
              console.log("@@206", value)
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  paymentMethod: value,
                }),
              );
            }}
            default={transaction.paymentMethod}
            options={paymentMethodOptions}
          />
        </div>
        <div>
          <ErrorFieldMessage
            id={CreateTransactionError.paymentMethod.name}
            error={isError(CreateTransactionError.paymentMethod.name)}
            message={CreateTransactionError.paymentMethod.message}
          />
        </div>
      </div>

      <div>

        <MerchantNameFilterableDropdown
          title={'Merchant name'}
          defaultSelectedMerchantName={transaction.merchantName}
        />
        <div>
          <ErrorFieldMessage
            id={CreateTransactionError.merchantName.name}
            error={isError(CreateTransactionError.merchantName.name)}
            message={CreateTransactionError.merchantName.message}
          />
        </div>
      </div>

      <div>
        <CampaignSelectDropdown
          title={'Campaign'}
          isPrompt
          defaultValue={{ label: transaction.campaign?.name, value: transaction.campaign }}
          defaultFilter={{
            expired: 'false',
            isAllActive: true,
            merchantName: transaction.merchantName?.value,
          }}
          setValue={(value) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                campaign: value
              }),
            );
          }}
        />
        <div>
          <ErrorFieldMessage
            id={CreateTransactionError.transactionCampaign.name}
            error={isError(CreateTransactionError.transactionCampaign.name)}
            message={CreateTransactionError.transactionCampaign.message}
          />
        </div>
      </div>



      <div>
        <CustomTitleLabel title={'Transaction amount'} />
        <div>
          <input
            type="text"
            className={`text-input-field ${isError(CreateTransactionError.transactionAmountValue.name)
              ? 'error-field'
              : ''
              }`}
            onChange={(e) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  totalValue: e.target.value,
                }),
              );
            }}
            value={transaction.totalValue}
          />
        </div>
        <div>
          <ErrorFieldMessage
            id={CreateTransactionError.transactionAmountValue.name}
            error={isError(CreateTransactionError.transactionAmountValue.name)}
            message={CreateTransactionError.transactionAmountValue.message}
          />
        </div>
      </div>
      <UploadImageCom
        defaultValue={transaction}
        errorFields={errorFields}
        fieldName="Receipt image"
        field="receiptImage"
        minWidth={1}
        minHeight={1}
        hint="Less than 2MB, support JPG, PNG and GIF only"
      />
    </>
  );
};

export const GreenDiningSkuItems = ({
  skuItems = [],
  isSeparateTitle = false,
}) => {
  const dispatch = useDispatch();
  const errorFields = useSelector((state) => state.createTransaction.errorFields) || [];
  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };

  return (
    <>

      {
        isSeparateTitle
          ? <>
            <div className='transaction-general-purchase-detail-separete-line' />
            <CustomTitleLabel title='SKU' className={'transaction-general-purchase-detail-separete-title'} />
          </>
          : <label className="create-section-title">SKU</label>
      }
      {skuItems.map((item, index) => (
        <>
          <div style={{ position: 'relative' }}>
            <label className="create-section-title-without-upper create-section-label-bottom-space transaction-general-purchase-detail-sku-reward-title">
              Item {index + 1}:
            </label>
          </div>

          <div>
            <input
              type="text"
              className="text-input-field"
              onChange={(e) => {
                dispatch(
                  createAction('createTransaction/changeSkuItems')({
                    sku: e.target.value,
                    index,
                  }),
                );
              }}
              value={item}
            />
            <button
              className="reset-button"
              style={{ position: 'absolute', marginTop: 5 }}
              onClick={() => {
                dispatch(
                  createAction('createTransaction/changeSkuItems')({
                    deleted: true,
                    index,
                  }),
                );
              }}
            >
              <Image src={DeleteIcon} className="content-delete-button" />
            </button>
          </div>
        </>
      ))}
      <ErrorFieldMessage
        id={CreateTransactionError.skuItems.name}
        error={isError(CreateTransactionError.skuItems.name)}
        message={CreateTransactionError.skuItems.message}
      />
      <Button
        onClick={() => {
          dispatch(
            createAction('createTransaction/changeSkuItems')({
              sku: '',
              index: skuItems.length,
            }),
          );
        }}
        className="btn-back-button-common"
        style={{ marginTop: 30 }}
      >
        + Add SKU
      </Button>
    </>
  );
};

function MTRCreateTransactionStepThree() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { transaction, errorFields, loadingStatus } = useSelector((state) => ({
    transaction: state.createTransaction.transaction,
    errorFields: state.createTransaction.errorFields,
    loadingStatus: state.createTransaction.loadingStatus,
  }));
  const transactionType = transaction.transactionDisplayType;

  const getSections = () => {
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) {
      return [
        <AdminCreateDetails
          transaction={transaction}
          errorFields={errorFields}
        />,
      ];
    };
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING) {
      return [
        <WaterFilingDetails
          transaction={transaction}
          errorFields={errorFields}
        />,
      ];
    }
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
      return [
        <GreenDiningDetails
          transaction={transaction}
          errorFields={errorFields}
        />,
        <GreenDiningSkuItems skuItems={transaction.skuItems || []} />,
      ];
    }
    return [
      <UploadImageCom
        defaultValue={transaction}
        errorFields={errorFields}
        title="Recycling RECEIPT"
      />,
      <PhotoTakingSetItemsCom
        defaultValue={transaction}
        store={transaction.store}
        barcode={transaction.barcode}
        errorFields={errorFields}
      />,
    ];
  };

  return (
    <>
      <ContentSections
        sections={getSections()}
        hidePreview={true}
        sectionStyle={
          transactionType === TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING
            ? 'create-water-filling-section-container'
            : ''
        }
      />
      <SaveAndBackWithOutTempButtons
        backAction={() =>
          dispatch(
            createAction('createTransaction/stepChange')({
              isBack: true,
              step: 2,
            }),
          )
        }
        saveButtonDisabled={loadingStatus === APIStatus.calling}
        saveText={transactionType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE ? 'Continue' : 'Save'}
        saveAction={() => {
          dispatch(
            createAction('createTransaction/checkStepThree')({
              isBack: false,
              afterActions: () => {
                if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) {
                  dispatch(
                    createAction('createTransaction/stepChange')({
                      isBack: false,
                      step: 2,
                    }),
                  )
                  return;
                }
                if (location.pathname.includes('edit')) {
                  dispatch(
                    createAction('createTransaction/updateTransaction')(),
                  );
                } else {
                  dispatch(
                    createAction('createTransaction/createTransaction')(),
                  );
                }
              },
            }),
          );
        }}
      />
    </>
  );
}

export default MTRCreateTransactionStepThree;
