import { BaseHelper } from './BaseGraphQLAPIHelper';

export const importCustomers = (values) => {
  const query = `
  mutation CreateImportJob($input: CreateImportJobInput!) {
    createImportJob(input: $input) {
      node {
        id
        pk
        resource #CUSTOMER,COUPON,TRANSACTION, STORE
        file
        status #PENDING,PROCESSING,COMPLETED,ERROR
      }
      errors {
        field
        messages
      }
    }
  }

  `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createDownloadTask = (values) => {
  const query = `
  mutation CreateExportJob($input: CreateExportJobInput!) {
    createExportJob(input: $input) {
      node {
        id
        pk
        resource #CUSTOMER,TRANSACTION,COUPON_RECORD,POINTS_RECORD, STORE
        file
        status #PENDING,PROCESSING,COMPLETED,ERROR
      }
      errors {
        field
        messages
      }
    }
  }


    `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const downloadFile = (id) => {
  const query = `
  {
    exportJob(id: "${id}") {
      id
      pk
      resource
      file
      status
    }
  }

      `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getExportTasks = () => {
  const query = `
  {
    exportJobs(first: 10, after: "", orderBy: "-pk",) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          resource
          file
          fileUrl
          fileName
          status
          creationDate
        }
      }
    }
  }


        `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSystemTasks = () => {
  const query = `
  {
    systemTasks(first: 10, orderBy: "-creationDate") {
      pendingAndProcessingCount
      edges {
        cursor
        node {
          id
          pk
          type
          resource
          fileUrl
          fileName
          status
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const cancelSystemTask = (value) => {
  const query = `
  mutation DeleteExportJob($input: DeleteExportJobInput!) {
    deleteExportJob(input: $input) {
      success
    }
  }

          `;
  const variables = { input: value };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getExportJobFileUrl = (payload) => {
  const query = `
  mutation GetExportJobFileUrl($input: GetExportJobFileUrlInput!) {
    getExportJobFileUrl(input: $input) {
      success
      fileUrl
    }
  }
  `;
  const variables = { input: payload };
  return BaseHelper.callGraphQLAPI({ query, variables });
}
