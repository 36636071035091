import React from 'react';
import './CountComponent.scss';
function CountComponent(props) {
  const { start, end, total } = props;

  return (
    <label className="count-text">
      Showing {start || 0} to {end || 0} of {total || 0} entries
    </label>
  );
}

export default CountComponent;
