import React, { useState, useEffect, useRef } from 'react';
import {
  Nav,
  TabPane,
  TabContent,
  TabContainer,
  Image,
  Dropdown,
} from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { List, AutoSizer } from 'react-virtualized';
import { connect } from 'react-redux';
import SearchIcon from '../../assets/images/drop_down_filter.svg';
import AuthButton from '../base/AuthButton';
import BasePrompt from '../base/prompt/BasePrompt';
import './CampaignEditStorePrompt.scss';
import { createAction } from '../../utils';
import Loading from '../base/Loading';

const SORT_KEYS = {
  ID_ASC: 1,
  ID_DESC: 2,
  NAME_ASC: 3,
  NAME_DESC: 4,
};

const CampaignEditStorePrompt = ({
  dispatch,
  allStores,
  campaignSelectedStores,
  loading,
  show,
  closeAction,
  campaignID,
}) => {
  const selectedListRef = useRef(null);
  const unSelectedListRef = useRef(null);
  const [selectedSortKey, setSelectedSortKey] = useState(SORT_KEYS.ID_ASC);
  const [unSelectedSortKey, setUnSelectedSortKey] = useState(SORT_KEYS.ID_ASC);
  const listRefs = [selectedListRef, unSelectedListRef];
  const [selectedSearchKey, setSelectedSearchKey] = useState('');
  const [selectedStores, setSelectedStores] = useState([]);
  const selectedStoreIds = selectedStores.map((store) => store.node.pk);
  const unSelectedStores = allStores.filter(
    (store) => !selectedStoreIds.includes(store.node.pk),
  );
  const [unSelectedSearchKey, setUnSelectedSearchKey] = useState('');
  const [checkedStores, setCheckedStores] = useState([]);
  const checkedStoreIds = checkedStores.map((store) => store.node.pk);
  const tabs = [
    {
      key: 'selected',
      name: `Selected (${selectedStores.length})`,
    },
    {
      key: 'unselected',
      name: `Unselected (${unSelectedStores.length})`,
    },
  ];
  useEffect(() => {
    if (show) {
      dispatch(createAction('campaignStore/getCampaignStores')({ campaignID }));
    } else {
      setTimeout(() => {
        dispatch(createAction('campaignStore/resetState')());
        setSelectedSearchKey('');
        setUnSelectedSearchKey('');
        setSelectedStores([]);
        setCheckedStores([]);
        setActiveTab(tabs[0].key);
        setSelectedSortKey(SORT_KEYS.ID_ASC);
        setUnSelectedSortKey(SORT_KEYS.ID_ASC);
      }, 200);
    }
  }, [show]);
  useEffect(() => {
    if (loading) {
      setSelectedStores([]);
      setCheckedStores([]);
    } else {
      setSelectedStores(campaignSelectedStores);
    }
  }, [loading]);
  const [activeTab, setActiveTab] = useState(tabs[0]?.key);
  return (
    <BasePrompt
      show={show}
      customClass="store-selection-modal"
      customHeaderClass="store-selection-header"
      customButtonClass="store-buttons"
      closeAction={closeAction}
      rightButton={{
        text: 'Done',
        class: 'test',
        action: () => {
          if (!loading) {
            dispatch(
              createAction('campaignStore/updateCampaignStores')({
                campaignID,
                selectedStoreIds,
                afterAction: closeAction,
              }),
            );
          }
        },
      }}
      leftButton={{
        text: 'Cancel and back to campaign list',
        action: closeAction,
      }}
      title={'Related store'}
      otherBody={() => (
        <TabContainer
          activeKey={activeTab || tabs[0]?.key}
          className={`list-section-container-content-bar`}
          onSelect={(key) => {
            setActiveTab(key);
            setCheckedStores([]);
          }}
        >
          <Nav className="list-section-container-content-bar nav nav-tabs">
            {tabs.map((tab) => (
              <Nav.Link className="nav-item" eventKey={tab.key}>
                {tab.name}
              </Nav.Link>
            ))}
          </Nav>
          <TabContent>
            {tabs.map((tab, tabIndex) => {
              let searchKey = selectedSearchKey;
              let setSearchKey = setSelectedSearchKey;
              let sortKey = selectedSortKey;
              let setSortKey = setSelectedSortKey;
              if (tabIndex === 1) {
                searchKey = unSelectedSearchKey;
                setSearchKey = setUnSelectedSearchKey;
                sortKey = unSelectedSortKey;
                setSortKey = setUnSelectedSortKey;
              }
              const unProcessedStores =
                tabIndex === 0 ? selectedStores : unSelectedStores;
              const stores = unProcessedStores
                .filter(
                  (store) =>
                    store.node.name
                      .toLowerCase()
                      .includes(searchKey.toLowerCase()) ||
                    store.node.pk.toString().includes(searchKey.toLowerCase()),
                )
                .sort((a, b) => {
                  switch (sortKey) {
                    case SORT_KEYS.ID_ASC:
                    default:
                      return a.node.pk - b.node.pk;
                    case SORT_KEYS.ID_DESC:
                      return b.node.pk - a.node.pk;
                    case SORT_KEYS.NAME_ASC:
                      return a.node.name.localeCompare(b.node.name);
                    case SORT_KEYS.NAME_DESC:
                      return b.node.name.localeCompare(a.node.name);
                  }
                });
              return (
                <TabPane eventKey={tab.key} key={tab.key}>
                  {loading ? (
                    <Loading customClass="campaign-edit-loading" />
                  ) : (
                    <>
                      <div className="store-search-container store-content-padding">
                        <div className="left-container">
                          <input
                            className="all-list-scrollable-area-checkbox"
                            checked={checkedStores.length > 0}
                            type="checkbox"
                            onChange={(e) => {
                              if (checkedStores.length === 0) {
                                setCheckedStores([...stores]);
                              } else {
                                setCheckedStores([]);
                              }
                            }}
                            id="select-all"
                          />

                          <label className="select-label" for="select-all">
                            {checkedStores.length === 0
                              ? 'select all'
                              : `${checkedStores.length} Select`}
                          </label>
                        </div>
                        <div className="right-container">
                          <div className="store-fitler-container">
                            <Image
                              src={SearchIcon}
                              className="store-filter-icon"
                            />
                            <input
                              name="searchKey"
                              value={searchKey}
                              onChange={({ target }) => {
                                setSearchKey(target.value);
                              }}
                              type="text"
                              className="list-filter-search-field search-field-input"
                              placeholder="Search by name or ID"
                            />
                          </div>
                          <Dropdown className="store-sort-btn">
                            <Dropdown.Toggle
                              className="btn-back-button-common btn-download "
                              id="dropdown-basic"
                            >
                              Sort by
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-container">
                              <Dropdown.Item
                                onSelect={() => {
                                  setSortKey(SORT_KEYS.ID_ASC);
                                }}
                              >
                                Ascending order according ID
                              </Dropdown.Item>
                              <Dropdown.Item
                                onSelect={() => {
                                  setSortKey(SORT_KEYS.ID_DESC);
                                }}
                              >
                                Descending order according ID
                              </Dropdown.Item>
                              <Dropdown.Item
                                onSelect={() => {
                                  setSortKey(SORT_KEYS.NAME_ASC);
                                }}
                              >
                                Store name A-Z
                              </Dropdown.Item>
                              <Dropdown.Item
                                onSelect={() => {
                                  setSortKey(SORT_KEYS.NAME_DESC);
                                }}
                              >
                                Store name Z-A
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {checkedStores.length === 0 ? null : (
                            <AuthButton
                              title={
                                tabIndex === 0
                                  ? 'Set as unselected'
                                  : 'Set as selected'
                              }
                              customClass="btn-back-button-common btn-download store-selected-btn"
                              action={() => {
                                if (tabIndex === 0) {
                                  setSelectedStores(
                                    selectedStores.filter(
                                      (store) =>
                                        !checkedStoreIds.includes(
                                          store.node.pk,
                                        ),
                                    ),
                                  );
                                } else {
                                  setSelectedStores([
                                    ...selectedStores,
                                    ...checkedStores,
                                  ]);
                                }
                                setCheckedStores([]);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="store-content-padding store-selection-list-container">
                        <AutoSizer>
                          {({ height, width }) => (
                            <Scrollbars
                              style={{ height, width }}
                              onScroll={({ target }) => {
                                const { scrollTop, scrollLeft } = target;
                                const { Grid: grid } = listRefs[
                                  tabIndex
                                ].current;
                                grid.handleScrollEvent({
                                  scrollTop,
                                  scrollLeft,
                                });
                              }}
                            >
                              <List
                                ref={listRefs[tabIndex]}
                                width={width}
                                height={height}
                                rowCount={stores.length}
                                style={{
                                  overflowX: false,
                                  overflowY: false,
                                }}
                                rowHeight={60}
                                rowRenderer={({
                                  key, // Unique key within array of rows
                                  index, // Index of row within collection
                                  isScrolling, // The List is currently being scrolled
                                  isVisible, // This row is visible within the List (eg it is not an overscanned row)
                                  style, // Style object to be
                                }) => {
                                  const store = stores[index].node;
                                  return (
                                    <div
                                      className="store-row"
                                      key={`${activeTab}-${key}`}
                                      style={style}
                                    >
                                      <input
                                        className="all-list-scrollable-area-checkbox store-row-checkbox"
                                        checked={checkedStoreIds.includes(
                                          store.pk,
                                        )}
                                        type="checkbox"
                                        onChange={(e) => {
                                          const test = checkedStores.filter(
                                            (checkedStore) =>
                                              checkedStore.node.pk !== store.pk,
                                          );
                                          if (
                                            test.length === checkedStores.length
                                          ) {
                                            test.push(stores[index]);
                                          }
                                          setCheckedStores(test);
                                        }}
                                      />
                                      <div className="store-info-container">
                                        <div className="store-name">
                                          {store.name}
                                        </div>
                                        <div className="store-id">
                                          ID: {store.pk}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              />
                            </Scrollbars>
                          )}
                        </AutoSizer>
                      </div>
                    </>
                  )}
                </TabPane>
              );
            })}
          </TabContent>
        </TabContainer>
      )}
    />
  );
};
const mapStateToProps = (state) => ({
  allStores: state.campaignStore.allStores,
  campaignSelectedStores: state.campaignStore.selectedStores,
  loading: state.campaignStore.loading,
});
export default connect(mapStateToProps)(CampaignEditStorePrompt);
