import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CampaignType } from '../../config/CustomEnums';
import { createAction } from '../../utils';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { CustomNumberInputWithUnit } from '../earning/CustomBaseComponments';
import { listItem } from '../../components/banners/DisplayItems';
import { CreateMissionError } from '../../models/MissionModel';

function LinkedCampaignSection({
  campaignList,
  errorFields,
  linkedCampaign,
  joinedTimes,
  recyclingItems,
  isRecycling,
  hideNumberSection = false,
  showRemoveButton = false,
  disableChangeCampaign = false
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      createAction('campaignList/getCampaignList')({
        type: CampaignType.earningRulesCampaign,
        earningRuleTypeIn: 'PHOTO_TAKING,GENERAL_PURCHASE,ACTIVE_TRACKING,MEMBER_REFERRAL,QR_CODE_SCANNING,QR_CODE_RECYCLING_EXTRA_REWARDS',
        isSelectorLoad: true,
        expired: false,
        status: true,
        isAllActive: true,
      }),
    );
  }, []);

  useEffect(() => {
    if (isRecycling && !hideNumberSection) {
      dispatch({
        type: 'mission/updateMissionDetail',
        payload: { joinedTimes: '' },
      });
    } else if (!isRecycling && !hideNumberSection) {
      dispatch({
        type: 'mission/updateMissionDetail',
        payload: { recyclingItems: '' },
      });
    }
  }, [isRecycling]);
  return (
    <>
      <CustomTitleWithDropDown
        title={'Link to campaign'}
        source={campaignList}
        defaultValue={linkedCampaign}
        loadMoreAction={'campaignList/getCampaignList'}
        filterAction={'campaignList/getCampaignList'}
        showRemoveButton={showRemoveButton}
        disabled={disableChangeCampaign}
        onRemoveButtonClick={() => {
          dispatch({
            type: 'mission/updateMissionDetail',
            payload: { linkedCampaign: '' },
          });
        }}
        defaultFilter={{
          type: CampaignType.earningRulesCampaign,
          earningRuleTypeIn: 'PHOTO_TAKING,GENERAL_PURCHASE,ACTIVE_TRACKING,MEMBER_REFERRAL,QR_CODE_SCANNING,QR_CODE_RECYCLING_EXTRA_REWARDS',
          expired: false,
          status: true,
          isAllActive: true,
        }}
        setValue={(value) => {
          dispatch({
            type: 'mission/updateMissionDetail',
            payload: { linkedCampaign: value },
          });
        }}
        errors={errorFields}
        errorName={CreateMissionError.linkedCampaign.name}
      />
      {linkedCampaign
        ? listItem(
          'Earning rule’s name',
          linkedCampaign?.value?.earningRule?.name,
        )
        : null}
      {hideNumberSection ? null : (
        <>
          <div style={{ opacity: isRecycling ? 0.5 : 1 }}>
            <CustomNumberInputWithUnit
              title={'Joined times'}
              unit={'times'}
              defaultValue={joinedTimes}
              disabled={isRecycling || disableChangeCampaign}
              error={errorFields.includes(CreateMissionError.joinedTimes.name)}
              errorId={CreateMissionError.joinedTimes.name}
              errorMessage={CreateMissionError.joinedTimes.message}
              setValue={(value) => {
                dispatch({
                  type: 'mission/updateMissionDetail',
                  payload: { joinedTimes: value },
                });
              }}
            />
          </div>
          {isRecycling ? (
            <div style={{ opacity: disableChangeCampaign ? 0.5 : 1 }}>
              <CustomNumberInputWithUnit
                title={'Recycling items'}
                unit={'items'}
                defaultValue={recyclingItems}
                disabled={disableChangeCampaign}
                error={errorFields.includes(
                  CreateMissionError.recyclingItems.name,
                )}
                errorId={CreateMissionError.recyclingItems.name}
                errorMessage={CreateMissionError.recyclingItems.message}
                setValue={(value) => {
                  dispatch({
                    type: 'mission/updateMissionDetail',
                    payload: { recyclingItems: value },
                  });
                }}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  campaignList: state.campaignList.allCampaignList,
});

export default connect(mapStateToProps)(LinkedCampaignSection);
