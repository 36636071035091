import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { Button } from 'react-bootstrap';


const AfcSettingDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { apiStatus, afcSettings={} } = useSelector((state) => ({
    afcSettings: state.afcSettings.afcSettings,
    apiStatus: state.afcDataRecords.apiStatus
  }));

  console.log('@28', afcSettings)
  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={afcSettings}
                fields={[
                  [{ title: 'Level 1 Carbon Savings', field: 'level1CarbonSavings' }],
                  [{ title: 'Level 1 Points', field: 'level1Points' }],
                  [{ title: 'Level 2 Carbon Savings', field: 'level2CarbonSavings' }],
                  [{ title: 'Level 2 Points', field: 'level2Points' }],
                  [{ title: 'Level 3 Carbon Savings', field: 'level3CarbonSavings' }],
                  [{ title: 'Level 3 Points', field: 'level3Points' }],
                  [{ title: 'Level 4 Carbon Savings', field: 'level4CarbonSavings' }],
                  [{ title: 'Level 4 Points', field: 'level4Points' }],
                  [{ title: 'Level 5 Carbon Savings', field: 'level5CarbonSavings' }],
                  [{ title: 'Level 5 Points', field: 'level5Points' }],
                  [{ title: 'Extra Carbon Savings After Level 5', field: 'level6ExtraCarbonSavings' }],
                  [{ title: 'AFC Campaign ID', field: 'afcCampaignId' }],
                  [{ title: 'Error Reporting Email List', field: 'errorReportingEmailList' }],
                ]}
              />,
            ]}
            hidePreview={true}
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <Button
      className="btn-further"
      onClick={() => {
        // dispatch({ type: 'customerList/clearData' });
        history.push({
          pathname: 'afc_settings/edit/',
        });
      }}
    >
      Edit
    </Button>,
  ];

  useEffect(() => {
    
    dispatch(
        createAction('afcSettings/getAfcSettings')({  }),
    );

  }, [dispatch]);

  const [activeTab, setActiveTab] = useState();

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: 'AFC Settings',
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name='' />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

export default AfcSettingDetail;
