import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { useSelector } from 'react-redux';
import { ReportTypes } from '../../../config/CustomEnums';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const parsedSearch = queryString.parse(location.search);
  const searchSubcategory = parsedSearch['activity_type'] || '';
  const searchReportType = parsedSearch['report_type'] || '';

  const [subcategory, setSubcategory] = useState(
    ArrayStringData(searchSubcategory),
  );

  const [reportType, setReportType] = useState(
    ArrayStringData(searchReportType),
  );

  const { categoryList } = useSelector((state) => ({
    categoryList: state.storeCategoryList.categoryList,
  }));
  console.log('categoryList:', categoryList[0]?.subcategories);
  const reportTypes = Object.keys(ReportTypes)?.map((key) => ({
    pk: ReportTypes[key]?.toLowerCase(),
    name: key,
  }));

  const content = [
    {
      title: 'Activity Type',
      data: categoryList[0]?.subcategories || [],
      value: subcategory,
      setValue: setSubcategory,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Report Type',
      data: reportTypes || [],
      value: reportType,
      setValue: setReportType,
      componment: FilterTypeEnum.choice,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
