import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, connect } from 'react-redux';
import CustomListComponent from '../../components/base/CustomListComponent';
import { NavBarNames } from '../../config/NavBarNameList';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../base/BaseTabListContainer';
import BaseListContainer from '../base/BaseListContainer';
import DeletePrompt from '../../components/base/DeletePrompt';
import { ActionsDropdownForItem } from '../../components/base/ActionsDropdown';
import { PermissionCodes } from '../../config/PermissionCodes';
import AuthButton from '../../components/base/AuthButton';
import { ImportResource } from '../../models/DownloadImportModel';
import Filter from './Filter'
import { BarcodeValidateStatus } from '../../config/CustomEnums';

const ValidateNewBarcodeList = ({
  newBarcodeList,
  listDisplayFields,
  checkedList,
  pageInfo,
  totalCount,
  totalPage,
}) => {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const deletePks = deleteItems?.map(item => item.pk);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);
  let currentPage = parseInt(searchQuery?.page);
  if (isNaN(currentPage)) {
    currentPage = 1;
  }

  const searchKey = searchQuery?.search;
  const fetchData = () => {
    dispatch({
      type: 'barcodeValidate/getNewBarcodeList',
      payload: {
        ...searchQuery,
        page: currentPage,
        searchKey,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: 'brand/getCurrentPageBrands', payload: {
        isAll: true, moreSearch: {
          showInBarcode: true,
        },
        isAllActive: true,
      }
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [location]);

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeSubmittednewbarcode,
    },
  ];

  const tabs = [
    {
      name: 'Validate New Barcode',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter
          }}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={newBarcodeList}
              totalPage={totalPage}
              currentPage={currentPage}
              customClassName="new-barcode-list"
              model={'barcodeValidate'}
              permissionGroup={PermissionCodes.barcode}
              deleteInfo={{
                data: [],
                title: 'New barcode',
                relatedName: '',
                onComfirm: {},
                relatedSections: null,
              }}
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'View details',
                    action: () => {
                      history.push(`/barcode_validate/${item.pk}`);
                    },
                    requires: PermissionCodes.changeSubmittednewbarcode,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.changeSubmittednewbarcode,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={
                      item.status === BarcodeValidateStatus.WAITING_FOR_APPROVAL.name
                        ? [{
                          name: 'Reject',
                          action: () => {
                            dispatch({
                              type: 'barcodeValidate/rejectNewBarcode',
                              payload: { 
                                id: item.pk,
                                afterAction: fetchData,
                              }
                            });
                          },
                          requires: PermissionCodes.changeSubmittednewbarcode,
                        },
                        ]
                        : []
                    }
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Export CSV"
      action={() => {
        dispatch({
          type: "downloadAndImport/createDownloadTask", payload: {
            from: ImportResource.newBarcode,
          }
        })
      }}
      requires={PermissionCodes.changeSubmittednewbarcode}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: "",
          title: NavBarNames.validateNewBarcode,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="New barcode"
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch({
            type: 'barcodeValidate/delete',
            payload: {
              ids: deletePks,
              afterAction: fetchData,
            }
          })
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  newBarcodeList: state.barcodeValidate.currentPageNewBarcodeList,
  listDisplayFields: state.barcodeValidate.listDisplayFields,
  pageInfo: state.barcodeValidate.pageInfo,
  totalCount: state.barcodeValidate.totalCount,
  totalPage: state.barcodeValidate.totalPage,
  checkedList: state.barcodeValidate.checkedList,
});

export default connect(mapStateToProps)(ValidateNewBarcodeList);
