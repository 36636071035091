import React, { Component } from 'react';
import './CouponGeneral.scss';
import { connect } from 'react-redux';
import {
  COUPON_TEMPLATE_TYPE,
  COUPON_TEMPLATE_TYPE_NAME,
  LanguageConfig,
} from '../../../config/CustomEnums';
import { createAction } from '../../../utils';
import CustomMultipleSelect from '../../base/CustomMultipleSelect';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import {
  isShowError,
  firstError,
} from '../../campaign/campaignCreation/CreateCampaignHandleError';
import { CouponErrorFields } from './CouponHandleError';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import CustomTips from '../../base/CustomTips';
import i18n from '../../../I18n';
import { withRouter } from 'react-router-dom';
import BrandSelectDropdown from '../../campaign/campaignCreation/BrandSelectDropdown';
import BasePrompt from '../../base/prompt/BasePrompt';
import {
  CustomTitleLabel,
  CustomTitleWithRadio,
} from '../../earning/CustomBaseComponments';

const CREATE_NEW_TYPE = {
  brand: 'brand',
  store: 'store',
  none: 'none',
};

@connect(({ createCoupon, brand }) => ({
  errorFields: createCoupon.errorFields,
  name: createCoupon.couponTemplate.name,
  selectedBrand: createCoupon.couponTemplate.selectedBrand,
  shouldBeUsedInStores: createCoupon.couponTemplate.shouldBeUsedInStores,
  useStaffCode: createCoupon.couponTemplate.useStaffCode,
  selectedStore: createCoupon.couponTemplate.selectedStores,
  translations: createCoupon.couponTemplate.translations,
  couponTemplate: createCoupon.couponTemplate,
}))
class CouponGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.shouldBeUsedInStores,
      useStaffCode: props.useStaffCode,
      selectedStore: props.selectedStore,
      generalName: props.translations[props.language]?.name,
      createNewType: CREATE_NEW_TYPE.none,
      couponFormat:
        props.couponTemplate.formats || COUPON_TEMPLATE_TYPE.storeCode,
    };
  }

  componentDidMount() {
    this.props.dispatch(createAction('brand/getCurrentPageBrands')());
  }

  componentDidUpdate(prevProps) {
    const oldErrors = JSON.stringify(prevProps.errorFields);
    const currentErrors = JSON.stringify(this.props.errorFields);
    if (currentErrors !== oldErrors) {
      firstError(
        `${CouponErrorFields.generalName.name}-${this.props.language}`,
        this.props.errorFields,
        this.props.language,
      );
    }
    if (
      prevProps.onSubmit !== this.props.onSubmit &&
      this.props.onSubmit === true
    ) {
      this.props.onSubmitAction({
        name:
          this.state.generalName ||
          this.props.translations[this.props.language]?.name ||
          null,
        formats: this.state.format,
      });
    }

    if (!prevProps.couponTemplate.formats) {
      this.props.dispatch({
        type: 'createCoupon/updateCouponTemplate',
        payload: { formats: this.state.couponFormat },
      });
    } else {
      if (this.props.couponTemplate.formats !== this.state.couponFormat) {
        console.log('@@84: ', prevProps.couponTemplate.formats);
        this.setState({ couponFormat: this.props.couponTemplate.formats });
      }
    }
  }

  handleUseStaffCodeChange = (checked) => {
    this.setState({ useStaffCode: checked });
    this.props.dispatch(
      createAction('createCoupon/updateCouponTemplate')({
        useStaffCode: checked,
      }),
    );
  };

  handleChange = (checked) => {
    this.setState({ checked: checked });
    console.log("staffhandleChange:", checked)
    if (!checked) {
      this.setState({ useStaffCode: false });
      this.props.dispatch(
        createAction('createCoupon/updateCouponTemplate')({
          useStaffCode: false,
        }),
      );
    }
    this.props.dispatch(
      createAction('createCoupon/updateCouponTemplate')({
        shouldBeUsedInStores: checked,
      }),
    );
    if (checked) {
      this.setState({
        selectedStore: this.props.selectedBrand?.brandStores,
      });
      this.props.dispatch(
        createAction('createCoupon/updateCouponTemplate')({
          selectedStores: this.props.selectedBrand?.brandStores,
        }),
      );
    }
  };

  selectBrand = (brand) => {
    this.props.dispatch(
      createAction('createCoupon/updateCouponTemplate')({
        selectedBrand: brand,
      }),
    );
    if (this.props.shouldBeUsedInStores) {
      this.setState({
        selectedStore: brand.brandStores,
      });
      this.props.dispatch(
        createAction('createCoupon/updateCouponTemplate')({
          selectedStores: brand.brandStores,
        }),
      );
    } else {
      this.setState({
        selectedStore: [],
      });
    }
  };

  switchHiddenState = () => {
    let hidden = true;
    if (this.props.selectedBrand) {
      hidden = false;
    }
    return hidden;
  };

  storeListHiddenState = () => {
    let hidden = true;
    if (this.props.shouldBeUsedInStores) {
      hidden = false;
    }
    return hidden;
  };

  selectBrandSectionView = () => {
    return (
      <BrandSelectDropdown
        title={i18n.t('related_brand_optional', {
          locale: this.props.language,
        })}
        defaultValue={{
          value: this.props.selectedBrand,
          label: this.props.selectedBrand?.name,
        }}
        onSelectChange={(item) => {
          this.props.inputChange('relatedBrand');
          this.selectBrand(item.value);
        }}
        addButton={{
          title: 'Create Now',
          action: () => this.setState({ createNewType: CREATE_NEW_TYPE.brand }),
          customClass: 'multiple-select-option-add btn-add-button-common',
        }}
        editable={this.props.language === LanguageConfig.english}
        onFocus={() => this.props.inputChange('relatedBrand')}
      />
    );
  };

  shouldShowInStoreView = () => {
    switch (this.props.language) {
      case LanguageConfig.english:
        return (
          <CustomSwitchButton
            defaultChecked={
              this.state.checked || this.props.shouldBeUsedInStores
            }
            onChange={(isChecked) => {
              this.handleChange(isChecked);
            }}
          />
        );
      case LanguageConfig.simplifiedChinese:
      case LanguageConfig.traditionalChinese:
        if (this.props.shouldBeUsedInStores) {
          return (
            <div className="second-section-content">
              {i18n.t('yes', { locale: this.props.language })}
            </div>
          );
        } else {
          return (
            <div className="second-section-content">
              {i18n.t('no', { locale: this.props.language })}
            </div>
          );
        }

      default:
        break;
    }
  };

  shouldUseStaffCodeView = () => {
    switch (this.props.language) {
      case LanguageConfig.english:
        return (
          <CustomSwitchButton
            defaultChecked={
              (this.state.checked || this.props.shouldBeUsedInStores) && (this.state.useStaffCode || this.props.useStaffCode)
            }
            onChange={(isChecked) => {
              this.handleUseStaffCodeChange(isChecked);
            }}
          />
        );
      case LanguageConfig.simplifiedChinese:
      case LanguageConfig.traditionalChinese:
        if (this.props.useStaffCode) {
          return (
            <div className="second-section-content">
              {i18n.t('yes', { locale: this.props.language })}
            </div>
          );
        } else {
          return (
            <div className="second-section-content">
              {i18n.t('no', { locale: this.props.language })}
            </div>
          );
        }

      default:
        break;
    }
  };

  parseStoreList = (storeList) => {
    return storeList?.map((store) => {
      if (
        this.props.language !== LanguageConfig.english &&
        store.storeTranslations[this.props.language]
      ) {
        return {
          name: store.storeTranslations[this.props.language].storeName,
          value: store.storePK,
        };
      }
      return {
        name: store.storeName,
        value: store.storePK,
      };
    });
  };

  selectedStoreOnChange = (options) => {
    this.props.inputChange('selectedStores');
    const selectedStoreList = [];
    options.forEach((store) => {
      const selected = this.props.selectedBrand?.brandStores.filter(
        (item) => item.storePK === store.value,
      );
      if (selected) {
        selectedStoreList.push(...selected);
      }
    });
    if (selectedStoreList.length > 0) {
      this.setState({
        selectedStore: selectedStoreList,
      });
      this.props.dispatch(
        createAction('createCoupon/updateCouponTemplate')({
          selectedStores: selectedStoreList,
        }),
      );
    } else {
      this.props.dispatch(
        createAction('createCoupon/updateCouponTemplate')({
          selectedStores: this.props.selectedBrand?.brandStores,
        }),
      );
      this.setState({
        selectedStore: this.props.selectedBrand?.brandStores,
      });
    }
  };

  showStoreName = (store) => {
    if (
      this.language !== LanguageConfig.english &&
      store.storeTranslations &&
      store.storeTranslations[this.props.language]
    ) {
      return store.storeTranslations[this.props.language].storeName;
    }
    return store.storeName;
  };

  selectStoreView = () => {
    const hiddenState = this.storeListHiddenState();
    if (hiddenState === false) {
      switch (this.props.language) {
        case 'en':
          return (
            <div>
              <CustomMultipleSelect
                options={this.parseStoreList(
                  this.props.selectedBrand?.brandStores || [],
                )}
                title={i18n.t('applicable_stores', {
                  locale: this.props.language,
                })}
                onSelectChange={(options) =>
                  this.selectedStoreOnChange(options)
                }
                addAction={() => {
                  this.setState({ createNewType: CREATE_NEW_TYPE.store });
                }}
                defaultSelected={
                  this.props.location.pathname.includes('edit')
                    ? this.parseStoreList(this.props.selectedStore)
                    : this.parseStoreList(this.state.selectedStore)
                }
                addButtonExtraClass={'add-store-button-left-margin'}
              />
            </div>
          );
        case 'zh-Hant':
        case 'zh-Hans':
          if (this.props.selectedStore && this.props.selectedStore.length > 0) {
            return (
              <div>
                {this.props.selectedStore.map((store) => {
                  return (
                    <div
                      className="second-section-content"
                      key={store.storeName}
                    >
                      {this.showStoreName(store)}
                    </div>
                  );
                })}
              </div>
            );
          } else {
            return <div>-</div>;
          }
        default:
          break;
      }
    }
  };

  couponTemplateName = (e) => {
    let textContent = e.target.value;
    this.props.dispatch({
      type: 'createCoupon/setFieldToSession',
      payload: {
        name:
          textContent ||
          this.props.translations[this.props.language]?.name ||
          null,
        language: this.props.language,
      },
    });
    this.setState({
      generalName: textContent,
    });
  };

  createNewText = () => {
    return this.state?.createNewType === CREATE_NEW_TYPE.brand
      ? 'brand'
      : 'store';
  };

  render() {
    console.log("staffhandleChange:", this.state.useStaffCode, (this.state.checked || this.props.shouldBeUsedInStores) && (this.state.useStaffCode || this.props.useStaffCode))
    const generalNameError = isShowError(
      CouponErrorFields.generalName.name,
      this.props.errorFields,
      this.props.language,
    );
    return (
      <>
        <div className="first-section-title">
          {i18n.t('general', { locale: this.props.language })}
        </div>
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">
              {i18n.t('name', { locale: this.props.language })}
            </div>
          </div>
          <div>
            <input
              type="text"
              className={`text-input-field ${generalNameError ? 'error-field' : ''
                }`}
              onFocus={() => this.props.inputChange('name')}
              onChange={this.couponTemplateName}
              defaultValue={this.props.translations[this.props.language]?.name}
            />
          </div>
          <ErrorFieldMessage
            id={`${CouponErrorFields.generalName.name}-${this.props.language}`}
            error={generalNameError}
            message={CouponErrorFields.generalName.message}
          />
        </div>
        {/* <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">

            </div>
          </div>

        </div> */}
        {this.selectBrandSectionView()}
        <div
          className="second-section"
          hidden={this.switchHiddenState()}
          onFocus={() => this.props.inputChange('selectedStores')}
        >
          <div className="d-flex second-title-section">
            <div className="second-section-title">
              {i18n.t('use_store_switch', { locale: this.props.language })}
            </div>
            <CustomTips detail="Turn on this switch to select stores." />
          </div>
          {this.shouldShowInStoreView()}
        </div>
        {this.state.checked || this.props.shouldBeUsedInStores ?
          <div
            className="second-section"
            hidden={this.switchHiddenState()}
          >
            <div className="d-flex second-title-section">
              <div className="second-section-title">
                {i18n.t('use_staff_code_switch', { locale: this.props.language })}
              </div>
              <CustomTips detail="Turn on this switch to use staff code in app." />
            </div>
            {this.shouldUseStaffCodeView()}
          </div> : null
        }

        {this.props.language !== LanguageConfig.english &&
          this.props.shouldBeUsedInStores ? (
          <div className="create-section-label create-section-label-bottom-space">
            {i18n.t('applicable_stores', { locale: this.props.language })}
          </div>
        ) : null}
        {this.selectStoreView()}
        <BasePrompt
          show={this.state.createNewType !== CREATE_NEW_TYPE.none}
          closeAction={() => {
            this.setState({ createNewType: CREATE_NEW_TYPE.none });
          }}
          rightButton={{
            text: `Go to create ${this.createNewText()}`,
            action: () => {
              this.setState({ createNewType: CREATE_NEW_TYPE.none });
              const pathname =
                this.state.createNewType === CREATE_NEW_TYPE.brand
                  ? '/brands/create'
                  : '/stores/create';
              this.props.history.push({
                pathname: pathname,
                state: {
                  from: this.props.history.location,
                  title: 'Continue to create coupon?',
                  content: 'You can continue to create the coupon.',
                },
              });
            },
          }}
          title={`Go to create a ${this.createNewText()}?`}
          description={`You will leave the coupon creation process. After you create a new ${this.createNewText()}, you can back to this page.`}
        />
        {this.props.language === LanguageConfig.english ? (
          <CustomTitleWithRadio
            title={i18n.t('coupon_format', {
              locale: this.props.language,
            })}
            options={[
              { label: 'Store code', value: COUPON_TEMPLATE_TYPE.storeCode },
              { label: 'Coupon code', value: COUPON_TEMPLATE_TYPE.couponCode },
              {
                label: 'Coupon QR code',
                value: COUPON_TEMPLATE_TYPE.couponQRCode,
              },
              {
                label: 'Coupon code image',
                value: COUPON_TEMPLATE_TYPE.couponCodeImage,
              },
              {
                label: 'Coupon MTR code',
                value: COUPON_TEMPLATE_TYPE.couponMTRCode,
              },
            ]}
            defaultValue={this.state.couponFormat}
            setValue={(value) => {
              this.setState({ couponFormat: value });
              this.props.dispatch({
                type: 'createCoupon/updateCouponTemplate',
                payload: { formats: value },
              });
            }}
          />
        ) : (
          <>
            <CustomTitleLabel
              title={i18n.t('coupon_format', {
                locale: this.props.language,
              })}
            />
            <label>
              {COUPON_TEMPLATE_TYPE_NAME[this.state.couponFormat] || '-'}
            </label>
          </>
        )}
      </>
    );
  }
}

export default withRouter(CouponGeneral);
