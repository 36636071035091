import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { removeElementFromArray } from '../../utils';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import deleteImage from '../../assets/images/earning/deletex1.png';
import ErrorFieldMessage from './ErrorFieldMessage';

const CustomTagSection = ({
  tagTitle,
  tagDescription,
  stateTag,
  setStateTag,
  tagSecondTitle,
  tagSecondDescription,
  errorId,
  isError,
  errorMessage,
}) => {
  return (
    <>
      <div className="second-section">
        <div className="second-section-title">{tagTitle}</div>
        <div className="second-section_description create-brand-description">
          {tagDescription}
        </div>
        <textarea
          onChange={({ target }) => {
            const currentTag = target.value.split(',');
            setStateTag(currentTag);
          }}
          className="custom-textarea-input-box custom-textarea-input-font "
          onFocus={() => { }}
          value={stateTag?.join(',')}
        />
      </div>
      <ErrorFieldMessage
        id={errorId}
        error={isError}
        message={errorMessage}
      />

      <CustomTitleLabel title={tagSecondTitle} />
      {
        tagSecondDescription
          ? <div className="second-section_description create-brand-description">
            {tagSecondDescription}
          </div>
          : null
      }
      <div>
        {stateTag?.map((element, index) => {
          if (!element?.length) {
            return null;
          };
          return (
            <div className="brand-tag-delete-button" key={`brand-tag-${index}`}>
              <label className="custom-textarea-input-font">
                {element}
              </label>
              <Button
                className="custom-image-button"
                onClick={() => {
                  setStateTag(removeElementFromArray(stateTag, element));
                }}
              >
                <Image src={deleteImage} />
              </Button>
            </div>
          );
        })}
      </div>
    </>
  )
};

export default CustomTagSection;
