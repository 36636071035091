import React, { useEffect } from 'react';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import ContentSections from '../base/ContentSections';
import InformationBarMessageSettingSection from './InformationBarMessageSettingSection';
import { useState } from 'react';

function CreateInformationBarMessageStepThree() {
  const dispatch = useDispatch();

  const [submitData, setSubmitData] = useState({});
  // let submitData = {}
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    console.log('@71', onCheck)
    console.log('@72', submitData);
    if (onCheck.value) {
      console.log('@73', submitData);
      dispatch(
        createAction('createMessage/stepChange')({
          data: submitData,
          isBack: onCheck.isBack,
          step: 2,
        }),
      );
      stopCheck();
    }
  }, [onCheck.value, onCheck.isBack, submitData, dispatch]);

  const sections = [
    <InformationBarMessageSettingSection
      onSubmit={onCheck.value}
      onSubmitData={(data) => {
        console.log('@70', data);
        // submitData = data
        setSubmitData(data);
      }}
    />,
  ];
  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />

      <ContinueWithBackButtons
        backAction={() => {
          isChecking({ isBack: true });
        }}
        continueAction={() => {
          isChecking({ isBack: false });
        }}
      />
    </>
  );
}

export default CreateInformationBarMessageStepThree;
