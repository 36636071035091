import React, { useEffect } from 'react';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import { Collapse } from 'react-bootstrap';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import { isShowError, firstError } from './CreateCampaignHandleError';

function PeriodSection(props) {
  const title = props.title;
  const titleDescription = props.titleDescription;
  const switchButtonTitle = props.switchButtonTitle;
  const isAlwaysPeriod = props.isAlwaysPeriod;
  const switchOnChange = props.switchOnChange || (() => {});
  const startDate = props.startDate;
  const startDateChange = props.startDateChange || (() => {});
  const endDate = props.endDate;
  const endDateChange = props.endDateChange || (() => {});
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const errorFields = props.errorFields;
  const fieldName = props.fieldName;
  const fieldErrorMessage = props.fieldErrorMessage;
  const disabled = props.disabled;
  const filterStartDate =
    props.filterStartDate ||
    ((date) => {
      return true;
    });

  const filterEndDate =
    props.filterEndDate ||
    ((date) => {
      return true;
    });
  const hideTime = props.hideTime || false;
  useEffect(() => {
    firstError(fieldName, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);
  return (
    <>
      <label className="create-section-title">{title}</label>
      <label className="section-short-description">{titleDescription}</label>
      <label className="create-section-label create-section-label-bottom-space">
        {switchButtonTitle}
      </label>
      <CustomSwitchButton
        disabled={disabled}
        defaultChecked={isAlwaysPeriod}
        onChange={switchOnChange}
      />
      <Collapse in={!isAlwaysPeriod}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            Start date
          </label>
          <CustomDateTimeSelect
            disabled={isAlwaysPeriod}
            defaultTime={startDate}
            maxDate={maxDate}
            onTimeChange={startDateChange}
            filterDate={filterStartDate}
            hideTime={hideTime}
          />
          <label className="create-section-label create-section-label-bottom-space">
            End date
          </label>
          <CustomDateTimeSelect
            error={isShowError(fieldName, errorFields)}
            minDate={minDate}
            defaultTime={endDate}
            disabled={isAlwaysPeriod}
            onTimeChange={endDateChange}
            filterDate={filterEndDate}
            hideTime={hideTime}
          />
          <ErrorFieldMessage
            id={fieldName}
            error={isShowError(fieldName, errorFields)}
            message={fieldErrorMessage}
          />
        </div>
      </Collapse>
    </>
  );
}

export default PeriodSection;
