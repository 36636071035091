import React, { useState, useEffect } from "react";
import { LanguageConfig } from "../../config/CustomEnums";
import i18n from "../../I18n";
import { CustomTitleLabel } from "../earning/CustomBaseComponments";
import { createAction } from "../../utils";
import "./MessageGeneralSection.scss";
import ImageUploader from "../base/ImageUploader";
import { useSelector, useDispatch } from "react-redux";
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from "../campaign/campaignCreation/CreateCampaignHandleError";
import { CreateMessageError } from "../../models/CreateMessageModel";
import ErrorFieldMessage from "../base/ErrorFieldMessage";
import CustomEditor from "../base/CustomEditor";
import { TARGET_USERS } from "../../models/CreateCampaignModel";
import { Row, Collapse } from "react-bootstrap";
import CustomRadios from "../base/CustomRadios";
import CustomAddRemoveMutipleSelector from "../customer/CustomAddRemoveMutipleSelector";
import { useParams } from "react-router-dom";
import CustomInputWithMaxLength from "../base/CustomInputWithMaxLength";

function InformationBarMessageOthersGeneralSection(props) {
  const dispatch = useDispatch();
  const params = useParams();

  const language = props.language || LanguageConfig.english;
  const onSubmit = props.onSubmit || false;
  const onSubmitData = props.onSubmitData || (() => {});

  const {
    defaultInformationBar,
    informationBarName,
    informationBarContent,
    title,
    content,
    photo,
    url,
    translations,
    informationBarTranslations,
    errorFields,
    segments,
    groups,
  } = useSelector((state) => ({
    defaultInformationBar: state.createMessage.message.informationBar,
    informationBarName: state.createMessage.message.informationBar.name,
    informationBarContent: state.createMessage.message.informationBar.content,
    title: state.createMessage.message.name,
    content: state.createMessage.message.content,
    photo: state.createMessage.message.photo,
    url: state.createMessage.message.url,
    translations: state.createMessage.message.translations,
    informationBarTranslations:
      state.createMessage.message.informationBar.translations,
    errorFields: state.createMessage.errorFields,
    segments: state.segments.segmentList,
    groups: state.customerGroup.groupList,
  }));
  const [name, setName] = useState(
    language === LanguageConfig.english
      ? informationBarName
      : informationBarTranslations[language]?.name,
  );
  const [informationBarContentState, setInformationBarContentState] = useState(
    language === LanguageConfig.english
      ? informationBarContent
      : informationBarTranslations[language]?.content,
  );
  // const [informationBarContentState, setInformationBarContentState] = useState(informationBarContent)

  const [targetUserDisplay, setTargetUser] = useState("All users");

  const [targetGroupDisplay, setTargetGroupDisplay] = useState([]);
  const [targetSegmentDisplay, setTargetSegmentDisplay] = useState([]);
  const [messageTitle, setMessageTitle] = useState(
    language === LanguageConfig.english ? title : translations[language]?.name,
  );
  const [messageContent, setMessageContent] = useState(
    language === LanguageConfig.english
      ? content
      : translations[language]?.content,
  );
  const [messagePhoto, setMessagePhoto] = useState(
    language === LanguageConfig.english ? photo : translations[language]?.photo,
  );
  const [messageUrl, setMessageUrl] = useState(url);

  const options = (source) => {
    if (!source) {
      return [];
    }
    return source.map((item) => {
      return {
        name: item.name,
        value: item,
      };
    });
  };

  const maxLength = language === LanguageConfig.english ? 115 : 65;

  const customerGroupConfig = {
    title: "Target customer groups",
    titleTips: "Select target customer group(s)",
    data: options(groups),
    selectedList: targetGroupDisplay,
    setValue: (options) => {
      dispatch({
        type: "createMessage/updateMessageState",
        payload: { targetCustomerGroup: options.map((item) => item.value) },
      });
      setTargetGroupDisplay(options);
    },
    sourceReminderText: "Select customer groups",
    amidReminderText: "Please select customer group",
    error: {
      error: isShowError(
        CampaignErrorHandleField.customerGroup.name,
        errorFields,
      ),
      message: CampaignErrorHandleField.customerGroup.message,
    },
    selectAllTitle: "all customer groups",
    loadMoreAction: "customerGroup/getCurrentPageList",
    filterAction: "customerGroup/getCurrentPageList",
    isSourceReminderFilterable: true,
  };

  const segmentConfig = {
    title: "Target segments",
    titleTips: "Select target customer segment(s)",
    data: options(segments),
    selectedList: targetSegmentDisplay,
    setValue: (options) => {
      dispatch({
        type: "createMessage/updateMessageState",
        payload: { targetedSegments: options.map((item) => item.value) },
      });
      setTargetSegmentDisplay(options);
    },
    sourceReminderText: "Select customer segment(s)",
    amidReminderText: "Please select customer segment",
    error: {
      error: isShowError(CampaignErrorHandleField.segment.name, errorFields),
      message: CampaignErrorHandleField.segment.message,
    },
    selectAllTitle: "all segments",
    loadMoreAction: "segments/getSegmentsList",
    filterAction: "segments/getSegmentsList",
    isSourceReminderFilterable: true,
  };

  const numberInput = ({
    id,
    labelText,
    type,
    valueChange,
    fieldDescription,
    defaultValue = undefined,
    onError = false,
  }) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {labelText}
        </label>
        <div>
          <input
            value={defaultValue}
            type="number"
            min="0"
            className={`coupon-campaign-section-input ${
              onError ? "error-field" : ""
            }`}
            onChange={({ target }) => {
              let value = target.value;
              if (value === "") {
                value = null;
              }
              valueChange(value);
            }}
          />
          <label className="section-short-description">{type}</label>
        </div>
        {fieldDescription ? (
          <label
            id={id}
            className={`${
              onError
                ? "error-field-message-style"
                : "coupon-campaign-section-caution-description"
            } `}
          >
            {fieldDescription}
          </label>
        ) : null}
      </>
    );
  };

  const getSelectorWithConfig = (config) => {
    return (
      <CustomAddRemoveMutipleSelector
        title={config.title}
        titleTips={config.titleTips}
        data={config.data}
        selectedList={config.selectedList}
        setValue={config.setValue}
        sourceReminderText={config.sourceReminderText}
        amidReminderText={config.amidReminderText}
        sourceReminderEnabelSelectAll={true}
        addButton={config.addButton}
        error={config.error ?? {}}
        requires={config.requires}
        selectAllTitle={config.selectAllTitle}
        loadMoreAction={config.loadMoreAction}
        defaultFilter={config.defaultFilter}
        isSourceReminderFilterable={config.isSourceReminderFilterable}
        filterAction={config.filterAction}
      />
    );
  };

  useEffect(() => {
    if (onSubmit) {
      const data = {
        informationBar: {
          name: name,
          content: informationBarContentState,
          language: language,
        },
        language: language,
        name: messageTitle,
        content: messageContent,
        photo: messagePhoto,
        url: messageUrl,
      };

      if (language !== LanguageConfig.english && translations[language]?.pk) {
        data.pk = translations[language]?.pk;
        data.id = translations[language]?.id;

        console.log("@tran", informationBarTranslations);

        data.informationBar.id = informationBarTranslations[language]?.id;
        data.informationBar.pk = informationBarTranslations[language]?.pk;
      }

      console.log("@sub", data);
      // if (params.id) {
      //   data.informationBar.pk = defaultInformationBar.pk
      // }

      onSubmitData(data);
    }
  }, [
    language,
    name,
    informationBarContentState,
    messagePhoto,
    messageTitle,
    messageContent,
    messageUrl,
    onSubmit,
    onSubmitData,
    translations,
  ]);

  useEffect(() => {
    firstError(
      `${CreateMessageError.infoBarName.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      `${CreateMessageError.infoBarContent.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      `${CreateMessageError.title.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      `${CreateMessageError.content.name}-${language}`,
      errorFields,
      language,
    );
  }, [errorFields]);

  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, language);
  };

  return (
    <>
      <label className="create-section-title">
        {i18n.t("information_bar_other", { locale: language })}
      </label>
      <CustomTitleLabel
        title={i18n.t("information_bar_name", { locale: language })}
      />
      <label className="create-message-suggest">
        {i18n.t("information_bar_name_hint", { locale: language })}
      </label>
      <input
        type="text"
        className={`text-input-field title-message-input`}
        defaultValue={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <ErrorFieldMessage
        id={`${CreateMessageError.infoBarName.name}-${language}`}
        error={isError(CreateMessageError.infoBarName.name)}
        message={`${CreateMessageError.infoBarName.message}`}
      />

      <CustomInputWithMaxLength
        sectionTitle={i18n.t("content", { locale: language })}
        value={informationBarContentState}
        valueChange={(value) => {
          console.log("@content", value);
          setInformationBarContentState(value);
        }}
        showCount
        maxLength={maxLength}
        type="text"
        className={`text-input-field title-message-input`}
        defaultValue={informationBarContentState}
        onChange={(e) => {
          setInformationBarContentState(e.target.value);
        }}
      />
      <ErrorFieldMessage
        id={`${CreateMessageError.infoBarContent.name}-${language}`}
        error={isError(CreateMessageError.infoBarContent.name)}
        message={`${CreateMessageError.infoBarContent.message}`}
      />

      <CustomTitleLabel
        title={i18n.t("information_bar_redirect_page", { locale: language })}
      />

      <CustomTitleLabel title={i18n.t("title", { locale: language })} />
      <label className="create-message-suggest">
        {i18n.t("use_in_inbox_push", { locale: language })}
      </label>
      <input
        type="text"
        className={`text-input-field title-message-input`}
        defaultValue={messageTitle}
        onChange={(e) => {
          setMessageTitle(e.target.value);
        }}
      />
      <ErrorFieldMessage
        id={`${CreateMessageError.title.name}-${language}`}
        error={isError(CreateMessageError.title.name)}
        message={`${CreateMessageError.title.message}`}
      />

      <CustomTitleLabel title={i18n.t("content", { locale: language })} />
      <label className="create-message-suggest">
        {i18n.t("use_in_inbox_push", { locale: language })}
      </label>

      <CustomEditor
        initialValue={messageContent}
        onValueChange={(e) => {
          setMessageContent(e);
        }}
        toolBars={["link"]}
        pureCopy
      />
      <ErrorFieldMessage
        id={`${CreateMessageError.content.name}-${language}`}
        error={isError(CreateMessageError.content.name)}
        message={`${CreateMessageError.content.message}`}
      />

      <CustomTitleLabel
        title={i18n.t("image(optional)", { locale: language })}
      />
      <label className="create-message-suggest">
        {i18n.t("message_photo_suggest", { locale: language })}
      </label>
      <ImageUploader
        minWidth={560}
        minHeight={420}
        images={
          messagePhoto
            ? Array.isArray(messagePhoto)
              ? messagePhoto.map((photo) => photo)
              : [messagePhoto]
            : []
        }
        onImageStateChange={(newState) => {
          setMessagePhoto(newState);
        }}
        aspect={4 / 3}
        maxImageNum={1}
        maxImageSize={1}
        language={language}
        uploadImageClicked={() => {}}
      />
      {language == LanguageConfig.english ? (
        <>
          <CustomTitleLabel title={"Url(optional)"} />
          <input
            type="text"
            className={`text-input-field title-message-input`}
            defaultValue={messageUrl}
            onChange={(e) => {
              setMessageUrl(e.target.value);
            }}
          />
        </>
      ) : null}
      <div>
        <label className="create-section-label create-section-label-bottom-space">
          TARGET CUSTOMERS
        </label>
        <Row className="featured-exclusive-row">
          <CustomRadios
            onChange={(value) => {
              setTargetUser(value);
            }}
            default={targetUserDisplay}
            options={Object.entries(TARGET_USERS).map(([key, value]) => ({
              label: value,
              value: value,
            }))}
          />
        </Row>
        {/* <ErrorFieldMessage
          id={CampaignErrorHandleField.discountTargetedUser.name}
          error={isShowError(
            CampaignErrorHandleField.discountTargetedUser.name,
            errorFields,
          )}
          message={CampaignErrorHandleField.discountTargetedUser.message}
        /> */}
      </div>
      <Collapse in={targetUserDisplay == TARGET_USERS.withTarget}>
        <div>
          {getSelectorWithConfig(customerGroupConfig)}
          {getSelectorWithConfig(segmentConfig)}
          {/* <ErrorFieldMessage
            id={CampaignErrorHandleField.discountTargetedUserGroup.name}
            error={isShowError(
              CampaignErrorHandleField.discountTargetedUserGroup.name,
              errorFields,
            )}
            message={CampaignErrorHandleField.discountTargetedUserGroup.message}
          /> */}
        </div>
      </Collapse>
    </>
  );
}

export default InformationBarMessageOthersGeneralSection;
