import { BaseHelper } from './BaseGraphQLAPIHelper';

const BRAND_CONTENT = `
id
pk
name
icon
description
coverPhoto
displayPriority
activityType
brandPageLink
showInBarcode
barcodePartner
receiptPartner
oclPartner
stores {
  edges {
    node {
      id
      pk
      name
    }
  }
}
translations {
  edges {
    node {
      pk
      language
      name
    }
  }
}
`;

export const getBrands = (
  afterCursor = '',
  rank = true,
  searchKey = '',
  isAll = false,
  moreSearch,
) => {
  let searchString = isAll ? 'first: 100' : 'first: 20';
  let orderBy = '-pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch?.sort;
  }
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  searchString += `, orderBy: "${orderBy}"`;
  if (searchKey) {
    searchString += `, name: "${searchKey}"`;
  }
  if (moreSearch?.showInBarcode) { 
    searchString += `, showInBarcode: true`;
  }
  if (moreSearch?.barcodePartner) {
    searchString += `, barcodePartner: true`;
  }
  if (moreSearch?.receiptPartner) {
    searchString += `, receiptPartner: true`;
  }
  if (moreSearch?.oclPartner) {
    searchString += `, oclPartner: true`;
  }

  const brandContent = isAll
    ? `id
  pk
  name
  icon
  description`
    : BRAND_CONTENT;
  // console.log('@@68: ', brandContent);
  const query = `{
    brands(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${brandContent}
        }
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getBrand = (brandID) => {
  const query = `
  {
    brand(id: "${brandID}") {
      id
      pk
      name
      description
      icon
      isForcedInactive
      coverPhoto
      displayPriority
      activityType
      brandTag
      brandPageLink
      showInBarcode
      barcodePartner
      receiptPartner
      oclPartner
      translations {
        edges {
          node {
            pk
            language
            name
            description
            brandTag
          }
        }
      }
      stores {
        edges {
          node {
            pk
            id
            name
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteBrand = (inputBody) => {
  const query = `
  mutation DeleteBrands($input: DeleteBrandsInput!) {
    deleteBrands(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createBrand = (inputBody) => {
  const query = `
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      node {
        id
        pk
        name
        icon
        description
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateBrand = (inputBody) => {
  const query = `
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      node {
        id
        pk
        name
        icon
        description
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
