import React, { useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import TransactionGeneralSection from './TransactionGeneralSection';
import TransactionAmountSection from './TransactionAmountSection';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../base/BottomStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { useLocation } from 'react-router-dom';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import { LanguageConfig } from '../../config/CustomEnums';

function CreateTransactionStepTwo() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { selectedStore, transactionType } = useSelector((state) => ({
    selectedStore: state.createTransaction.transaction.store,
    transactionType: state.createTransaction.transaction.transactionDisplayType,
  }));

  const sections = [
    <TransactionGeneralSection language={LanguageConfig.english}/>,
    // <TransactionAmountSection />,
  ];

  const getTabs = (language) => {
    return [
      <TransactionGeneralSection language={language}/>
    ];
  };

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
    hansContainer: getTabs(LanguageConfig.simplifiedChinese),
  };

  return (
    <>
      {transactionType == TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE 
        ? <ContentSections
          languageTabContent={languageTabConfig}
          hidePreview={true}
        />
        : <ContentSections sections={sections} hidePreview={true} />
      }
      {location.pathname.includes('edit') ? (
        <OnlyContinueButton
          disabledContinue={false}
          continueAction={() => {
            dispatch(
              createAction('createTransaction/stepChange')({
                isBack: false,
                step: 1,
              }),
            );
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backAction={() => {
            dispatch(
              createAction('createTransaction/stepChange')({
                isBack: true,
                step: 1,
              }),
            );
          }}
          continueAction={() => {
            dispatch(
              createAction('createTransaction/stepChange')({
                isBack: false,
                step: 1,
              }),
            );
            if (transactionType == TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING) {
              dispatch(
                createAction('campaignList/getCampaignList')({
                  isSelectorLoad: true,
                  storeIn: selectedStore.pk,
                }),
              );
            }
          }}
        />
      )}
    </>
  );
}

export default CreateTransactionStepTwo;
