import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import ContentSections from '../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../components/base/BottomStepComponent';
import {
  LanguageConfig,
  APIStatus,
} from '../../config/CustomEnums';
import { PermissionCodes } from '../../config/PermissionCodes';
import Loading from '../../components/base/Loading';
import GeneralSection from './GeneralSection';
import RewardSection from './RewardSection';
import ActivePeriodSection from './ActivePeriodSection';


const CreateRewardCodeStepOne = ({
  rewardCodeAPIStatus,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getLanguageTab = (language) => ([
    <GeneralSection language={language} />,
    language === LanguageConfig.english ? <RewardSection /> : null,
    language === LanguageConfig.english ? <ActivePeriodSection /> : null,
  ])

  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  if (rewardCodeAPIStatus === APIStatus.calling) {
    return <ContentSections sections={[<Loading />]} hidePreview />;
  };

  return (
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />

      <SaveAndBackButtons
        saveText={'continue'}
        backAction={() => history.goBack()}
        saveAction={() => {
          dispatch({
            type: 'rewardCode/stepChange', 
            payload: {
              isBack: false,
              step: 0,
            }
          })
        }}
        tempRequires={PermissionCodes.changeRewardCode}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  rewardCodeAPIStatus: state.rewardCode.rewardCodeAPIStatus,
});

export default connect(mapStateToProps)(CreateRewardCodeStepOne);
