import React, { useState, useEffect } from 'react';
import { Row, Collapse } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ImageUploader from '../../base/ImageUploader';
import './KeyVisualSection.scss';
import CustomRadios from '../../base/CustomRadios';
import { CampaignHomeSection, CampaignType, LanguageConfig } from '../../../config/CustomEnums';
import { IMAGE_TYPES } from '../../../models/UploadFilesModel';
import i18n from '../../../I18n';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import CustomSwitchButton from '../../base/CustomSwitchButton';

const CampaignLabel = {
  none: 'none',
  isFeatured: 'isFeatured',
  isExclusive: 'isExclusive',
};

const CampaignSponsoredLabel = {
  none: 'none',
  isSponsored: 'isSponsored',
  isSupported: 'isSupported',
}

const getHomeSectionRadioOptions = (language) => [
  {
    label: i18n.t('campaign.earn', { locale: language }),
    value: CampaignHomeSection.earnSection.value,
  },
  {
    label: i18n.t('campaign.redeem', { locale: language }),
    value: CampaignHomeSection.redeemSection.value,
  }
];

const getRadioOptions = (language) => [
  {
    label: i18n.t('campaign.none', { locale: language }),
    value: CampaignLabel.none,
  },
  {
    label: i18n.t('campaign.featured', { locale: language }),
    value: CampaignLabel.isFeatured,
  },
  {
    label: i18n.t('campaign.exclusive', { locale: language }),
    value: CampaignLabel.isExclusive,
  },
];

const getSponsoredRadioOptions = (language) => [
  {
    label: i18n.t('campaign.none', { locale: language }),
    value: CampaignSponsoredLabel.none,
  },
  {
    label: i18n.t('campaign.sponsored', { locale: language }),
    value: CampaignSponsoredLabel.isSponsored,
  },
  {
    label: i18n.t('campaign.supported', { locale: language }),
    value: CampaignSponsoredLabel.isSupported,
  },
];

const getFeaturedOptions = (language) => [
  {
    label: i18n.t('campaign.none', { locale: language }),
    value: CampaignLabel.none,
  },
  {
    label: i18n.t('campaign.featured', { locale: language }),
    value: CampaignLabel.isFeatured,
  },
];

const getExclusiveOptions = (language) => [
  {
    label: i18n.t('campaign.none', { locale: language }),
    value: CampaignLabel.none,
  },
  {
    label: i18n.t('campaign.exclusive', { locale: language }),
    value: CampaignLabel.isExclusive,
  },
];


const DisplayInSection = ({
  language,
  campaignType,
  displayInHome,
  updateCampaignState,
}) => {
  const radioText = displayInHome ? 'campaign.yes' : 'campaign.none';
  return (
    <>
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('campaign.display_in_whats_new', { locale: language })}
      </label>
      <div>
        {language !== LanguageConfig.english ? (
          i18n.t(radioText, { locale: language })
        ) : (
          <CustomSwitchButton
            disabled={false}
            defaultChecked={displayInHome}
            onChange={(value) => {
              console.log("@@220", value)
              updateCampaignState({
                displayInDiscover: campaignType === CampaignType.couponsCampaign ? false : !value,
                displayInHome: value,
                isFeatured: false,
                isExclusive: false,
              })
            }}
          />
        )}
      </div>
    </>
  )
}

const HomeSection = ({
  language,
  campaignType,
  displayInHome,
  homeSection,
  updateCampaignState,
}) => {
  const radioOptions = getHomeSectionRadioOptions(language);
  const defaultSection =
    homeSection === CampaignHomeSection.redeemSection.value
      ? CampaignHomeSection.redeemSection.value
      : CampaignHomeSection.earnSection.value;

  const radioText = radioOptions.filter((item) => item.value === defaultSection)[0].label;

  return (
    <>
      <Collapse in={campaignType === CampaignType.generalMessageCampaign && displayInHome}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            {i18n.t('campaign.select_home_section', { locale: language })}
          </label>
          <Row className="featured-exclusive-row">
            {language !== LanguageConfig.english ? (
              radioText
            ) : (
              <CustomRadios
                disabled={language !== LanguageConfig.english}
                onChange={(value) => {
                  console.log("@@change home section", value)
                  updateCampaignState({
                    homeSection: value,
                  })
                }}
                default={defaultSection}
                options={radioOptions}
              />
            )}
          </Row>
        </div>
      </Collapse>
    </>
  );
}

const FeaturedOrExclusiveSection = ({
  language,
  campaignType,
  isExclusive,
  isFeatured,
  displayInHome,
  displayInDiscover,
  updateCampaignState,
}) => {
  const radioOptions = getRadioOptions(language);
  const featuredOptions = getFeaturedOptions(language);
  const exclusiveOptions = getExclusiveOptions(language);

  const getDefaultLabel = () => {
    if (isExclusive) {
      return CampaignLabel.isExclusive;
    }
    if (isFeatured) {
      return CampaignLabel.isFeatured;
    }
    return CampaignLabel.none;
  };

  const getRadioText = () => {
    let label = CampaignLabel.none;
    if (isFeatured) {
      label = CampaignLabel.isFeatured;
    }
    if (isExclusive) {
      label = CampaignLabel.isExclusive;
    }
    return radioOptions.filter((item) => item.value === label)[0].label;
  };

  const showExclusive = displayInHome || campaignType === CampaignType.couponsCampaign;


  return (
    <>
      <Collapse in={campaignType !== CampaignType.couponsCampaign
        || (campaignType === CampaignType.couponsCampaign && displayInHome)}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            {i18n.t(showExclusive
              ? 'campaign.it_is_an_exclusive_campaign'
              : 'campaign.it_is_a_featured_campaign',
              { locale: language }
            )}
          </label>
          <Row className="featured-exclusive-row">
            {language !== LanguageConfig.english ? (
              getRadioText()
            ) : (
              <CustomRadios
                disabled={language !== LanguageConfig.english}
                onChange={(value) => {
                  console.log("@@275", value)
                  updateCampaignState({
                    isFeatured: value === CampaignLabel.isFeatured,
                    isExclusive: value === CampaignLabel.isExclusive,
                  })
                }}
                default={getDefaultLabel()}
                options={showExclusive ? exclusiveOptions : featuredOptions}
              />
            )}
          </Row>
        </div>
      </Collapse>
    </>
  )
};

const SponsoredOrSupportedSection = ({
  language,
  isSponsored,
  isSupported,
  updateCampaignState,
}) => {
  const radioOptions = getSponsoredRadioOptions(language);

  const getDefaultLabel = () => {
    if (isSponsored) {
      return CampaignSponsoredLabel.isSponsored;
    }
    if (isSupported) {
      return CampaignSponsoredLabel.isSupported;
    }
    return CampaignSponsoredLabel.none;
  };

  const getRadioText = () => {
    let label = CampaignSponsoredLabel.none;
    if (isSponsored) {
      label = CampaignSponsoredLabel.isSponsored;
    }
    if (isSupported) {
      label = CampaignSponsoredLabel.isSupported;
    }
    return radioOptions.filter((item) => item.value === label)[0].label;
  };

  return (
    <div>
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('campaign.it_is_a_sponsored_or_supported_campaign', { locale: language })}
      </label>
      <Row className="featured-exclusive-row">
        {language !== LanguageConfig.english ? (
          getRadioText()
        ) : (
          <CustomRadios
            disabled={language !== LanguageConfig.english}
            onChange={(value) => {
              console.log("@@281", value)
              updateCampaignState({
                isSponsored: value === CampaignSponsoredLabel.isSponsored,
                isSupported: value === CampaignSponsoredLabel.isSupported,
              })
            }}
            default={getDefaultLabel()}
            options={radioOptions}
          />
        )}
      </Row>
    </div>
  )
}

function KeyVisualSection(props) {
  const focusChange = props.focusChange;
  const language = props.language;
  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => { });
  const {
    campaignType,
    coverPhoto,
    squareCoverPhoto,
    detailPhotoTwo,
    detailPhotoOne,
    detailPhotoThree,
    detailPhotoFour,
    isExclusive,
    isFeatured,
    isSponsored,
    isSupported,
    displayInDiscover,
    displayInHome,
    homeSection,
    errorFields,
  } = useSelector((state) => ({
    campaignType: state.createCampaign.campaign.campaignType,
    coverPhoto: state.createCampaign.campaign.translations[language].coverPhoto,
    squareCoverPhoto:
      state.createCampaign.campaign.translations[language].squareCoverPhoto,
    detailPhotoOne:
      state.createCampaign.campaign.translations[language].detailPhotoOne,
    detailPhotoTwo:
      state.createCampaign.campaign.translations[language].detailPhotoTwo,
    detailPhotoThree:
      state.createCampaign.campaign.translations[language].detailPhotoThree,
    detailPhotoFour:
      state.createCampaign.campaign.translations[language].detailPhotoFour,
    isExclusive: state.createCampaign.campaign.isExclusive,
    isFeatured: state.createCampaign.campaign.isFeatured,
    isSponsored: state.createCampaign.campaign.isSponsored,
    isSupported: state.createCampaign.campaign.isSupported,
    displayInDiscover: state.createCampaign.campaign.displayInDiscover,
    displayInHome: state.createCampaign.campaign.displayInHome,
    homeSection: state.createCampaign.campaign.homeSection,
    errorFields: state.createCampaign.errorFields,
  }));
  const defaultCoverPhoto = [coverPhoto].filter((coverPhoto) => coverPhoto);
  const defaultSquareCoverPhoto = [squareCoverPhoto].filter(
    (squareCoverPhoto) => squareCoverPhoto,
  );
  const defaultOtherPhotos = [
    detailPhotoOne,
    detailPhotoTwo,
    detailPhotoThree,
    detailPhotoFour,
  ].filter((item) => item);

  const [campaignCoverPhoto, setCoverPhoto] = useState(defaultCoverPhoto);
  const [campaignSquareCoverPhoto, setSquareCoverPhoto] = useState(
    defaultSquareCoverPhoto,
  );

  const [campaignOtherPhotos, setOtherPhotos] = useState(defaultOtherPhotos);
  const dispatch = useDispatch();

  const getImageUrl = (data) => {
    if (!data) {
      return '';
    }
    if (data.type !== IMAGE_TYPES.TYPE_URL) {
      return '';
    }
    return data.value;
  };
  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        coverPhoto: getImageUrl(campaignCoverPhoto[0]),
        squareCoverPhoto: getImageUrl(campaignSquareCoverPhoto[0]),
        detailPhotoOne: getImageUrl(campaignOtherPhotos[0]),
        detailPhotoTwo: getImageUrl(campaignOtherPhotos[1]),
        detailPhotoThree: getImageUrl(campaignOtherPhotos[2]),
        detailPhotoFour: getImageUrl(campaignOtherPhotos[3]),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(
      `${CampaignErrorHandleField.coverPhoto.name}-${LanguageConfig.english}`,
      errorFields,
      language,
    );
    firstError(
      `${CampaignErrorHandleField.squarePhoto.name}-${LanguageConfig.english}`,
      errorFields,
      language,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  const updateCampaignState = (payload) => {
    dispatch({
      type: 'createCampaign/updateCampaign',
      payload: payload,
    });
  };

  return (
    <>
      <label className="create-section-title">
        {i18n.t('key_visual', { locale: language })}
      </label>
      {language === LanguageConfig.english ? null : (
        <label className="create-campaign-cover-photo-reminder">
          {i18n.t('campaign.cover_photo_reminder', { locale: language })}
        </label>
      )}

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        enableGif={true}
        minWidth={900}
        minHeight={600}
        images={campaignCoverPhoto}
        onImageStateChange={(newState) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: { coverPhoto: getImageUrl(newState[0]), language },
          });
          setCoverPhoto(newState);
        }}
        aspect={3 / 2}
        maxImageNum={1}
        language={language}
        photoSectionTitle={'cover_photo'}
        uploadImageClicked={() => focusChange('coverPhoto')}
        errorMessage={`${isShowError(
          CampaignErrorHandleField.coverPhoto.name,
          errorFields,
          language,
        )
          ? CampaignErrorHandleField.coverPhoto.message
          : ''
          }`}
      />
      <span id={`${CampaignErrorHandleField.coverPhoto.name}-${language}`} />

      <DisplayInSection
        language={language}
        campaignType={campaignType}
        displayInHome={displayInHome}
        updateCampaignState={updateCampaignState}
      />

      <HomeSection
        language={language}
        campaignType={campaignType}
        displayInHome={displayInHome}
        homeSection={homeSection}
        updateCampaignState={updateCampaignState}
      />

      <FeaturedOrExclusiveSection
        language={language}
        campaignType={campaignType}
        isExclusive={isExclusive}
        isFeatured={isFeatured}
        displayInHome={displayInHome}
        displayInDiscover={displayInDiscover}
        updateCampaignState={updateCampaignState}
      />

      <SponsoredOrSupportedSection
        language={language}
        isSponsored={isSponsored}
        isSupported={isSupported}
        updateCampaignState={updateCampaignState}
      />

      <Collapse in={isExclusive}>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="create-section-label create-section-label-bottom-space">
              {i18n.t('campaign.square_cover_photo', { locale: language })}
            </label>
            <label className="create-campaign-cover-photo-reminder">
              {i18n.t('square_cover_photo_suggest', { locale: language })}
            </label>

            <ImageUploader
              enableGif={true}
              images={campaignSquareCoverPhoto}
              onImageStateChange={(newState) => {
                dispatch({
                  type: 'createCampaign/setFieldToSession',
                  payload: {
                    squareCoverPhoto: getImageUrl(newState[0]),
                    language,
                  },
                });
                setSquareCoverPhoto(newState);
              }}
              aspect={1 / 1}
              maxImageNum={1}
              language={language}
              photoSectionTitle={'square_photo'}
              uploadImageClicked={() => focusChange('squareCoverPhoto')}
              errorMessage={`${isShowError(
                CampaignErrorHandleField.squarePhoto.name,
                errorFields,
                language,
              )
                ? CampaignErrorHandleField.squarePhoto.message
                : ''
                }`}
            />
          </div>
          <span
            id={`${CampaignErrorHandleField.squarePhoto.name}-${language}`}
          />
        </div>
      </Collapse>

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('other_photos', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        enableGif={true}
        minWidth={900}
        minHeight={600}
        images={campaignOtherPhotos}
        onImageStateChange={(newState) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: {
              detailPhotoOne: getImageUrl(campaignOtherPhotos[0]),
              detailPhotoTwo: getImageUrl(campaignOtherPhotos[1]),
              detailPhotoThree: getImageUrl(campaignOtherPhotos[2]),
              detailPhotoFour: getImageUrl(campaignOtherPhotos[3]),
              language,
            },
          });
          setOtherPhotos(newState);
        }}
        aspect={3 / 2}
        maxImageNum={4}
        language={language}
        photoSectionTitle={'other_photo'}
        uploadImageClicked={() => focusChange('otherPhotos')}
      />
    </>
  );
}

export default KeyVisualSection;
