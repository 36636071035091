import React, { useEffect, useState } from 'react';
import './CustomInputWithMaxLength.scss';
import ErrorFieldMessage from './ErrorFieldMessage';
import { isLongScreen } from '../../utils/ScreenUtil';

function CustomInputWithMaxLength({
  sectionTitle,
  value,
  valueChange,
  placeholder,
  customInputContainerClassName,
  maxLength,
  showCount,
  tips,
  error,
  errorMessage,
  errorId,
}) {
  const [valueInput, setValueInput] = useState();
  const [valueLength, setValueLength] = useState(value?.length || 0);

  useEffect(() => {
    setValueInput(value);
    setValueLength(value?.length || 0);
  }, [value]);

  return (
    <>
      <label
        className={`create-section-label create-section-label-bottom-space`}
      >
        {sectionTitle}
      </label>
      {tips ? <label className="tips-message">{tips}</label> : null}
      <div
        className={`custom-input-with-max-length-with-count ${customInputContainerClassName} ${error ? 'error-field' : ''
          }`}
      >
        <input
          type="text"
          onChange={({ target }) => {
            setValueInput(target.value);
            setValueLength(target.value?.length);
            valueChange(target.value);
          }}
          value={valueInput || ''}
          placeholder={placeholder || ''}
          className={`custom-input-with-max-length-with-count-input ${error ? 'error-field' : ''
            } ${isLongScreen ? '' : 'custom-input-with-max-length-short'}`}
          maxLength={maxLength}
        />
        {showCount && maxLength
          ? <span className="custom-input-with-max-length-count">
            {`${valueLength} / ${maxLength}`}
          </span>
          : null
        }
      </div>
      <ErrorFieldMessage
        id={errorId}
        error={error}
        message={errorMessage}
      />
    </>
  );
}

export default CustomInputWithMaxLength;
