import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getGreetingCardList = (
  afterCursor,
  others,
) => {
  let orderBy = '-pk';
  if (others?.orderBy) {
    orderBy = others?.orderBy;
  };
  const filterName = others?.searchKey || '';

  const query = `{
      greetings(after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains:"${filterName}") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            name
            themeColor
            startDate
            endDate
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGreetingCard = (id) => {
  const query = `{
    greeting(id: "${id}") {
      pk
      id
      name
      themeColor
      startDate
      endDate
      greetingPhoto
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createGreetingCard = (input) => {
  const query = `mutation CreateGreeting($input: CreateGreetingInput!) {
    createGreeting(input: $input) {
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGreetingCard = (input) => {
  const query = `mutation UpdateGreeting($input: UpdateGreetingInput!) {
    updateGreeting(input: $input) {
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteGreetings = (ids) => {
  const query = `mutation DeleteGreetings($input: DeleteGreetingsInput!) {
    deleteGreetings(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
