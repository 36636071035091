import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import { createAction, useCompare } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseEditSection from '../../base/BaseEditSection';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import CustomAddRemoveMutipleSelector from '../../../components/customer/CustomAddRemoveMutipleSelector';
import Loading from '../../../components/base/Loading';
import {
  CheckStatus,
  DELETE_RELATED_SECTIONS,
  SavedStatus,
} from '../../../config/CustomEnums';
import { AdminGroupErrorHandleFields } from './AdminGroupErrorHandleFields';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CreateAdminGroup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const {
    adminGroup,
    checked,
    errorFields,
    saved,
    allPermissions,
  } = useSelector((state) => ({
    adminGroup: state.adminGroup.oneAdminGroup || {},
    checked: state.adminGroup.checked,
    errorFields: state.adminGroup.errorFields,
    saved: state.adminGroup.saved,
    allPermissions: state.admin.allPermissionList, // state.admin.cmsAllPermissions,
  }));

  const [name, setName] = useState(adminGroup.name);
  const [permissions, setPermissions] = useState(
    adminGroup.ownedPermissions || [],
  );
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const vals = { name, permissions };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'adminGroup/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(createAction('adminGroup/updateOrCreate')({}));
    }
  }, [dispatch, checked]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/administrator_groups');
    }
  }, [saved, history]);

  useEffect(() => {
    const id = params.id;
    if (id) {
      dispatch(createAction('adminGroup/getOneAdminGroup')({ id }));
    }

    // dispatch(createAction('admin/getAllPermissions')());
  }, [dispatch, params]);

  useEffect(() => {
    setName(adminGroup.name);
    setPermissions(adminGroup.ownedPermissions || []);
  }, [adminGroup]);

  const sections = [
    <BaseEditSection
      title="General"
      fields={[
        <CustomTitleWithInput
          title={'Name'}
          defaultValue={name}
          setValue={(name) => {
            setName(name);
          }}
          error={{
            id: 'name',
            error: errorFields?.fields?.includes('name'),
            message: errorFields?.messages?.map((item) => {
              if (item.field === 'name') {
                return AdminGroupErrorHandleFields[item.field][item.errorType];
              }
            }),
          }}
        />,
        <CustomAddRemoveMutipleSelector
          title={'Administrator group(s) permission(optional)'}
          data={allPermissions}
          selectedList={adminGroup.ownedPermissions || []}
          setValue={setPermissions}
          sourceReminderText={'All permission(s)'}
          amidReminderText={'Remove permission(s)'}
          loadMoreAction="admin/getAllPermissions"
          filterAction="admin/getAllPermissions"
        />,
      ]}
    />,
  ];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Updated' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={() => {
          dispatch({ type: 'adminGroup/checkValsValid' });
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteAdministratorGroup}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Admin Group',
          content: 'Did you confirm to delete this Admin Group?',
        }}
        title={'Admin Group'}
        data={adminGroup}
        relatedSections={DELETE_RELATED_SECTIONS.ADMIN_GROUP}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `adminGroup/updateState`,
            payload: { checkedList: [adminGroup] },
          });
          dispatch(
            createAction('adminGroup/delete')({
              afterAction: () => {
                history.push('/administrator_groups/');
              },
            }),
          );
        }}
      />
    </>,
  ];

  return (
    <div className="customer">
      <ScrollableComponent
        tempSave={(save) => {}}
        showFinishPop={saved === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Administrator group is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to administrator group list',
              action: () =>
                history.push({
                  pathname: '/administrator_groups',
                }),
            },
          ],
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? adminGroup.name : 'Create administrator group'}
          />
        }
        caution={{
          detail: '',
          title: params.id
            ? `Edit ${adminGroup.name}`
            : 'Create administrator group',
          model: 'adminGroup',
        }}
      />
    </div>
  );
}

export default CreateAdminGroup;
