export const StoreErrorHandleFields = {
  name: {
    required: 'Please provide name',
  },
  displayPriority: {
    required: 'Please provide display priority',
  },
  coverPhoto: {
    required: 'Please provide cover photo',
  },
  subcategories: {
    required: 'Please provide subcategories',
  },
  address: {
    required: 'Please provide address',
  },
  latitude: {
    required: 'Please provide latitude',
  },
  longitude: {
    required: 'Please provide longitude',
  },
  staffCode: {
    // required: 'Please provide staffcode',
    length: 'Staffcode should be 6 digits',
  },
  // brand: {
  //   required: 'Please provide brand',
  // },
  emailAddress: {
    valid: 'please input the valid email',
  },
};
