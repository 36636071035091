import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getCustomerGroups = () => {
  const query = `{
    customerGroups(first: 100) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAllCustomers = (afterCursor, search = {}, moreSearch) => {
  let searchValue = '';
  if (search && Object.keys(search).length > 0) {
    searchValue =
      ', ' +
      Object.keys(search)
        .map((key) => `${key}: ${search[key]}`)
        .join(', ');
  }
  let orderBy = 'membershipId';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const query = `{
    customers(first: 20, after:"${afterCursor}", orderBy:"${orderBy}", ${searchValue}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          ssoUid
          firstName
          lastName
          nickname
          octopusId
          membershipId
          pointAccount {
            id
            pk
            totalPointsEarned
            balance
            totalPointsUsed
            totalPointsExpired
            currentLevel {
              pk
              id
              levelName
            }
            currentLevelRenewDatetime
            currentLevelExpiredInXDays
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomersByPage = (
  afterCursor,
  rank = 'true',
  search,
  groups,
  segments,
  levels,
  age,
  genders,
  startDate,
  endDate,
  moreSearch,
  sort,
) => {
  // firstName_Icontains: "", groups_Name: "", gender_In: "", dateOfBirth_Month: "", mobilePhoneNumberCountryCode_In: "852,853,86", ageBetween:"0,2",signUpAnniversaryBetween: "0,2", level: "Level 2"

  let order = rank ? '-membershipId' : 'membershipId';

  if (sort) {
    order = sort;
  }

  let searches = [];
  if (search) {
    searches.push(`customerName: "${search}"`);
  }
  if (groups) {
    searches.push(`groupsIn: "${groups}"`);
  }
  if (segments) {
    searches.push(`segmentsIn: "${segments}"`);
  }
  if (levels) {
    searches.push(`levelIn: "${levels}"`);
  }
  if (age && age[1] > 0) {
    searches.push(`ageRange: "${age}"`);
  }
  if (genders) {
    searches.push(`genderIn: "${genders}"`);
  }
  // if (startDate && endDate) {
  //   searches.push(`dateOfBirthRange: "${startDate},${endDate}"`);
  // } else if (startDate) {
  //   searches.push(`dateOfBirth: "${startDate}"`);
  // } else if (endDate) {
  //   searches.push(`dateOfBirth: "${endDate}"`);
  // }
  if (startDate) {
    searches.push(`dateOfBirthGte: "${startDate}"`);
  }
  if (endDate) {
    searches.push(`dateOfBirthLte: "${endDate}"`);
  }

  // console.log('@@127: ', searches);
  if (moreSearch) {
    searches = [...searches, ...moreSearch];
  }

  // console.log('@@104: ', searches.join(', '));
  const query = `{
    customers(first: 20, after:"${afterCursor}", orderBy:"${order}"${
    searches.length > 0 ? ',' + searches.join(', ') : ''
  }) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          membershipId
          firstName
          lastName
          nickname
          mobilePhoneNumberCountryCode
          mobilePhoneNumberSubscriberNumber
          emailAddress
          isAssignedAsTestingCustomer
          isForcedInactive
          community {
            pk
            name
          }
          segments {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomers = (ids) => {
  const query = `
    mutation DeleteCustomers($input: DeleteCustomersInput!) {
      deleteCustomers(input: $input) {
        success
        deleteFail
      }
    }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCustomer = (values) => {
  const query = `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      node {
        id
        pk
        firstName
        lastName
        nickname
        octopusId
        ssoUid
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
        emailAddress
        gender {
          label
          value
        }
        dateOfBirth
        address
        company
        signUpChannel
        referrer {
          id
        }
        referredByCampaign {
          id
        }
        isForcedInactive
        preferredMessageLanguage
        creationDate
        lastModifiedDate
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCustomer = (id) => {
  const query = `{
    customer(id: "${id}") {
      id
      pk
      membershipId
      firstName
      lastName
      nickname
      octopusId
      ssoUid
      accountType
      mobilePhoneNumberCountryCode
      mobilePhoneNumberSubscriberNumber
      emailAddress
      joinCommunityDate
      communityHall
      community {
        id
        pk
        name
        communityCode
      }
      isAssignedAsTestingCustomer
      gender {
        label
        value
      }
      dateOfBirth
      address
      company
      signUpChannel
      referrer {
        id
        pk
        firstName
        lastName
      }
      referredByCampaign {
        id
        pk
        name
      }
      isForcedInactive
      preferredMessageLanguage
      creationDate
      lastModifiedDate
      pointAccount {
        id
        pk
        totalPointsEarned
        balance
        totalPointsUsed
        totalPointsExpired
        currentLevel {
          pk
          id
          levelName
        }
        currentLevelRenewDatetime
        currentLevelExpiredInXDays
      }
      totalSpending
      averageOrderValue
      totalNumberOfOrders
      daysFromLastOrder
      hasAgreedDirectMarketing
      groups {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      segments {
        edges {
          node {
            id
            pk
            name
            conditionGroups {
              edges {
                  node {
                      levelCharacteristic {
                        id
                        levels(first: 2) {
                            edges {
                                node {
                                    id
                                    levelName
                                    translations(first: 2) {
                                        edges {
                                            node {
                                                levelName
                                                levelBenefitUrl
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                  }
              }
            }
          }
        }
      }
      coupons(first: 100, isExpired: false, isUsed: false orderBy: "-pk") {
        edges {
          node {
            id
            pk
            template {
              id
              pk
              name
            }
          }
        }
      }


    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerActivityLog = (ssoUid) => {
  // activityTypeIn: "CUSTOMER_POINTS_EARNED,CUSTOMER_POINTS_USED,CUSTOMER_POINTS_EXPIRED,CUSTOMER_LEVEL_CHANGED,CUSTOMER_LEVEL_RETAINED"
  const query = `{
    customerActivityLogs(first: 50, customerSsoUid: "${ssoUid}", orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          activityType
          customerSsoUid
          customerPointAndLevelLogTitle
          logContentId
          logContentType {
            appLabel
            model
          }
          creationDate
        }
      }
    }
  }
  `;

  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCustomer = (values) => {
  const query = `
  mutation ActivateCustomer($input: ActivateCustomerInput!) {
    activateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCustomer = (values) => {
  console.log(values);
  const query = `
  mutation DeactivateCustomer($input: DeactivateCustomerInput!) {
    deactivateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getObjectRelatedCustomers = (search = {}) => {
  const searchValue = search.search
    ? `customerName:"${search.search}", communityIn:"${search.id}"`
    : `communityIn:"${search.id}"`;
  const afterCursor = search.afterCursor || '';
  const query = `{
    customers(first: 20, after:"${afterCursor}", orderBy:"-membershipId", ${searchValue}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          firstName
          lastName
          nickname
          membershipId
          joinCommunityDate
          communityHall
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
