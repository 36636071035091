import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { PublishTagType } from '../../../config/CustomEnums';
const queryString = require('query-string');

function CommunityTarget({
  communityDetail,
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const [showDeleteView, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const deletePks = deleteItems?.map(item => item.pk);

  const unsetCommunityTarget = [
    // {
    //   name: 'Delete',
    //   action: (id) => {
    //     setShowDeletePrompt(true);
    //     setDeleteItems(checkedList);
    //   },
    //   requires: PermissionCodes.changeMission,
    // },
  ];

  useEffect(() => {
    dispatch({ type: 'mission/clearState' });
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringPage = querySearch['page'] || 1;
      const page = parseInt(stringPage);

      dispatch({
        type: 'mission/getCurrentPageMissions',
        payload: {
          ...querySearch,
          page,
          searchKey,
          communityId: id,
        },
      });
    }
  }, [location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={true}
        groupActions={unsetCommunityTarget}
        pageInfo={pageInfo}
        totalCount={totalCount || 0}
        listContent={
          <BaseListContainer
            fields={listFields}
            model={'mission'}
            permissionGroup={PermissionCodes.mission}
            dataList={dataList}
            totalPage={totalPage}
            deleteInfo={{}}
            extraPathname={'/mission'}
            useCustomCommonActions
            customCommonActions={(item) => {
              const actions = [
                {
                  name: 'Edit',
                  action: () => {
                    history.push({
                      pathname: `/communities/${id}/target/${item.pk}/edit/`,
                      state: { fromCommunity: location, communityName: communityDetail?.name?.en }
                    });
                  },
                  requires: PermissionCodes.changeMission,
                },
                // {
                //   name: 'Delete',
                //   action: () => {
                //     setDeleteItems([item]);
                //     setShowDeletePrompt(true);
                //   },
                //   requires: PermissionCodes.changeMission,
                // },
              ];
              return (
                <ActionsDropdownForItem
                  object={item}
                  actions={actions}
                  otherActions={[
                    {
                      name:
                        item.isPublished
                          ? 'Unpublish'
                          : 'Publish',
                      action: () => {
                        dispatch({
                          type: 'mission/publishOrUnpublishMission',
                          payload: {
                            id: item.pk,
                            publish: !item.isPublished,
                            afterAction: () => {
                              history.push(location);
                            },
                          }
                        });
                      },
                      requires: PermissionCodes.changeMission,
                    },
                  ]}
                />
              );
            }}
          />
        }
      />
      <DeletePrompt
        data={deleteItems}
        show={showDeleteView}
        title={'Community Target'}
        relatedName=""
        relatedSections={['Community']}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          dispatch({
            type: 'mission/deleteCommunityTarget',
            payload: {
              deletePks: deletePks,
              afterAction: () => {
                setShowDeletePrompt(false);
                setDeleteItems([]);
                history.push(location);
              },
            },
          });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  communityDetail: state.communityModel.oneObject,
  listFields: state.communityModel.relatedCommunityTarget.listDisplayFields,
  dataList: state.mission.currentPageMissionList,
  pageInfo: state.mission.pageInfo,
  totalPage: state.mission.totalPage,
  totalCount: state.mission.totalCount,
  checkedList: state.mission.checkedList,
})

export default connect(mapStateToProps)(CommunityTarget);
