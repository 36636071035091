import React, { useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';
import './TestAccountPrompt.scss';
import BasePrompt from '../base/prompt/BasePrompt';

function TestAccountPrompt(props) {
  const show = props.show || false;
  const onHide = props.onHide || (() => {});
  const onConfirm = props.onConfirm || (() => {});

  const [selectedAccount, setSelectedAccount] = useState();
  const [errorFields, setErrorFields] = useState([]);

  const handleOnConfirm = () => {
    if (!selectedAccount) {
      setErrorFields(['testAccount']);
    } else {
      onConfirm(selectedAccount);
    }
  };

  const otherBody = () => {
    return (
      <CustomerFilterableDropdown
        filterTestAccount={true}
        showMembershipId={true}
        needLevelFilter={false}
        selectCustomer={(customer) => {
          setSelectedAccount(customer.value);
        }}
        errorName={'testAccount'}
        errorMessage="This field is required."
        errorFields={errorFields}
        title={'Account name'}
        moreSearch={['isAssignedAsTestingCustomer: true ']}
      />
    );
  };

  return (
    <BasePrompt
      show={show}
      closeAction={onHide}
      rightButton={{
        text: 'Send',
        action: handleOnConfirm,
      }}
      leftButton={{
        text: 'Cancel',
        action: onHide,
      }}
      title={'Test Accounts'}
      description={'Are you sure to test the channel with the below accounts?'}
      otherBody={otherBody}
    />
  );
}

export default TestAccountPrompt;
