import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import BrandSelectDropdown from '../campaign/campaignCreation/BrandSelectDropdown';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import addImage from '../../assets/images/addx1.png';
import { PermissionCodes } from '../../config/PermissionCodes'
import BasePrompt from '../base/prompt/BasePrompt';
import { CustomNumberInputWithUnit, CustomNumberMutipleInputWithUnit } from '../earning/CustomBaseComponments';
import { Errors } from '../../models/BarcodeModel'
import ErrorFieldMessage from '../base/ErrorFieldMessage';

function StepTwo({
    errorFields,
    detail
}) {
    const [data, setData] = useState({})
    const history = useHistory()
    const disptch = useDispatch()
    const params = useParams()
    const [checking, onChecking] = useState(false)
    const [turnOnSponsor, setTurnOnSponsor] = useState(!!detail?.sponsorPartner?.id)
    const [createCouponAlert, setCreateCouponALert] = useState(false)

    console.log("data:", data)
    useEffect(() => {
        setData(detail)
        setTurnOnSponsor(detail?.sponsorPartner?.id)
    }, [])


    const extraPointsReward = () => {
        return <>
            <label className="create-section-title">
                Extra barcode rewards
            </label>
            <label className='tips-message'>Fill in either one reward(points or coupon or both). Set 0 = no reward.</label>
            <CustomTitleWithSwitch
                title="Sponsor rewards from partner"
                defaultValue={turnOnSponsor}
                setValue={(isChecked) => {
                    setTurnOnSponsor(isChecked)
                    if (!isChecked) {
                        setData({ ...data, sponsorPartner: null })
                    }
                }}
            />
            {turnOnSponsor ? <BrandSelectDropdown
                defaultValue={{
                    value: data?.sponsorPartner,
                    label: data?.sponsorPartner?.name,
                }}
                onSelectChange={(item) => {
                    setData({ ...data, sponsorPartner: item.value })
                }}
                editable
                error={{}}
                filterOption={{
                    moreSearch: {
                        showInBarcode: true,
                        barcodePartner: true,
                    },
                    isAllActive: true,
                    isAll: true
                }}
            /> : null}
            <CustomTitleLabel title="No of points" />
            <div style={{ display: 'flex' }}>
                <input
                    type="number"
                    onChange={({ target }) => setData({ ...data, points: target.value })}
                    className="custom-number-input-short"
                    value={data.points}
                    min={0}
                    onFocus={() => { }}
                />
                <label className="help-message margin-left-10-percent">
                    {'points'}
                </label>
            </div>
        </>
    }

    const extraCouponReward = () => {
        return <>
            <label className="create-section-title" style={{ marginBottom: "20px" }}>
                Coupon Rewards
            </label>
            <CouponSelectDropdown
                title={'Coupon set'}
                defaultValue={{
                    value: data.linkedCouponSet,
                    label: data.linkedCouponSet?.name,
                }}
                setValue={(item) => {
                    console.log("couponItems:", item)
                    setData({ ...data, linkedCouponSet: item })
                }}
                showRemoveButton
                onRemoveButtonClick={() => {
                    setData({
                        ...data,
                        linkedCouponSet: null,
                        overAllPeriodicLimit: null,
                        overallLimit: null,
                        overAllPeriodicLimitEffectiveNumberOfDays: null,
                        perHeadLimit: null,
                        perHeadPeriodicLimit: null,
                        perHeadPeriodicLimitEffectiveNumberOfDays: null,
                        couponQuantity: null,
                    })
                }}
                addButtonAction={() => setCreateCouponALert(true)}
                addButtonInfo={{
                    requires: PermissionCodes.addCouponTemplate,
                    children: <img src={addImage} />,
                    action: () => setCreateCouponALert(true),
                    customClass: 'earning-rule-add-new-link-button',
                }}
            />
            {data?.linkedCouponSet?.id ? <>
                <CustomTitleLabel title="Quantity" />
                <div style={{ display: 'flex' }}>
                    <input
                        type="number"
                        onChange={({ target }) => {
                            setData({ ...data, couponQuantity: target.value })
                        }}
                        className="custom-number-input-short"
                        value={data.couponQuantity}
                        onFocus={() => { }}
                    />
                    <label className="help-message margin-left-10-percent">
                        coupon(s)
                    </label>
                </div>
                <ErrorFieldMessage
                    error={errorFields.indexOf("couponQuantity") > -1}
                    message={Errors.couponQuantity.message}
                />
                <CustomTitleLabel title="Overall limit (optional)" />
                <label className="tips-message">
                    It should not be greater than {data?.linkedCouponSet?.stock}, the current number of coupon left in stock.
                </label>
                <CustomNumberInputWithUnit
                    defaultValue={data?.overallLimit}
                    setValue={(value) => { setData({ ...data, overallLimit: value }); }}
                    unit="times"
                    errorId={`${Errors.overallLimit.name}`}
                    error={errorFields.indexOf(`overallLimit`) > -1}
                    errorMessage={`${Errors.overallLimit.message}`}
                />
                <CustomTitleLabel title="Periodic limit (optional)" />
                <CustomNumberMutipleInputWithUnit
                    firstDefaultValue={data?.overAllPeriodicLimit}
                    setFirstValue={(value) => { setData({ ...data, overAllPeriodicLimit: value }); }}
                    secondDefaultValue={data?.overAllPeriodicLimitEffectiveNumberOfDays}
                    setSecondValue={(value) => { setData({ ...data, overAllPeriodicLimitEffectiveNumberOfDays: value }); }}
                    per="times per"
                    unit="days"
                />

                <CustomTitleLabel title="Per head limit (optional)" />
                <CustomNumberInputWithUnit
                    defaultValue={data?.perHeadLimit}
                    setValue={(value) => { setData({ ...data, perHeadLimit: value }); }}
                    unit="times"
                />

                <CustomTitleLabel title="Per head periodic limit (optional)" />
                <CustomNumberMutipleInputWithUnit
                    firstDefaultValue={data?.perHeadPeriodicLimit}
                    setFirstValue={(value) => { setData({ ...data, perHeadPeriodicLimit: value }); }}
                    secondDefaultValue={data?.perHeadPeriodicLimitEffectiveNumberOfDays}
                    setSecondValue={(value) => { setData({ ...data, perHeadPeriodicLimitEffectiveNumberOfDays: value }); }}
                    per="times per"
                    unit="days"
                />
            </> : null}
        </>
    }

    return (
        <>
            <ContentSections
                sections={[
                    extraPointsReward(),
                    extraCouponReward()

                ]}
                hidePreview={true}
            />
            <ContinueWithBackButtons
                continueAction={() => {
                    if (checking) {
                        return
                    }
                    onChecking(true)
                    disptch({
                        type: "barcodes/stepChange", payload: {
                            data: data,
                            step: 1,
                            afterAction: () => onChecking(false)
                        },
                    })
                }}
                backAction={() => {
                    disptch({
                        type: "barcodes/stepChange", payload: {
                            data: data,
                            step: 1,
                            isBack: true,
                            afterAction: () => onChecking(false)
                        },
                    })
                }}
            />
            <BasePrompt
                show={createCouponAlert}
                closeAction={() => setCreateCouponALert(false)}
                rightButton={{
                    text: "Go to Create Coupon Set",
                    action: () => {
                        history.push({
                            pathname: '/coupons/create',
                            state: {
                                from: params.id
                                    ? `/barcodes/${params.id}/edit/`
                                    : '/barcodes/create',
                                title: 'Continue to Edit Barcode?',
                                content: 'You can continue to edit the Barcode.',
                            },
                        });
                    },
                }}
                title={"Go to create a Coupon Set?"}
                description={
                    'You will leave the barcode edit process. After you create a coupon set, you can come back to edit a barcode.'
                }
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    detail: state.barcodes.detail,
    errorFields: state.barcodes.errorFields
});

export default connect(mapStateToProps)(StepTwo);
