import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { PermissionCodes } from '../../../config/PermissionCodes';
const queryString = require('query-string');

function CommunityNewsFeeds({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const [showDeleteView, setShowDeletePrompt] = useState(false);

  const unsetNewsFeedCommunity = [
    {
      name: 'Delete',
      action: (id) => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeNewsFeed,
    },
  ];

  useEffect(() => {
    dispatch({ type: 'newsFeedModel/clearData' });
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);

      dispatch({
        type: 'newsFeedModel/getList',
        payload: {
          ...querySearch,
          page,
          reverse: rank,
          search: searchKey,
          communityId: id,
        },
      });
    }
  }, [location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={true}
        groupActions={unsetNewsFeedCommunity}
        pageInfo={pageInfo}
        totalCount={totalCount || 0}
        listContent={
          <BaseListContainer
            fields={listFields}
            model={'newsFeedModel'}
            permissionGroup={PermissionCodes.newsFeed}
            dataList={dataList}
            totalPage={totalPage}
            deleteInfo={{
              data: [],
              title: 'News Feed',
              relatedName: '',
              customizeDelete: (item) => {
                dispatch({
                  type: 'newsFeedModel/deleteNewsFeedsInCommunity',
                  payload: {
                    id: [item.pk],
                    communityId: id,
                    afterAction: () => {
                      history.push(location);
                    },
                  },
                });
              },
              relatedSections: ['Community'],
            }}
            extraPathname={'/news_feeds'}
            actions={['Edit', 'Delete']}
          />
        }
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'News Feed'}
        relatedName=""
        relatedSections={['Community']}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          dispatch({
            type: 'newsFeedModel/deleteNewsFeedsInCommunity',
            payload: {
              communityId: id,
              afterAction: () => {
                setShowDeletePrompt(false);
                history.push(location);
              },
            },
          });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  listFields: state.communityModel.relatedNewsFeedInfo.listDisplayFields,
  dataList: state.newsFeedModel.pagedList,
  pageInfo: state.newsFeedModel.pageInfo,
  totalPage: state.newsFeedModel.totalPage,
  totalCount: state.newsFeedModel.totalCount,
  checkedList: state.newsFeedModel.checkedList,
})

export default connect(mapStateToProps)(CommunityNewsFeeds);
