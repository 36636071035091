import React from 'react';
import { Table, Image } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  LanguageTabTransLation,
  LanguageConfig,
} from '../../config/CustomEnums';

const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

function InformationBarOverviewTableSection(props) {
  const showdown = require('showdown');
  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  const data = props.data || {};

  console.log('@data', data)


  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value?.replace(/<br>\n/g, '<br>')?.replace(/\\,/g, ',')) : '-',
        }}
      ></td>
    );
  };

  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const sectionValues = (valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) => {
          if (language === LanguageConfig.english) {
            return fieldValueText(data[valueKey], index);
          } else {
            return fieldValueText(
              data?.translations?.[language]?.[valueKey],
              index,
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      <PerfectScrollbar>
        <label className="create-section-title">INFORMATION BAR CONTENT</label>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
              {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText('Content')}
              {sectionValues('content')}
            </tr>
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}

export default InformationBarOverviewTableSection;
