import { BaseHelper } from './BaseGraphQLAPIHelper';
//qrCodeScanningTypeQrCodes
//qrCodeScanningTypeRewardType
//qrCodeScanningTypePointsRewardTypePoints
// qrCodeScanningTypeCouponRewardTypeCouponTemplate {
//   id
//   name
// }
// qrCodeScanningTypeCouponRewardTypeQuantity

const EARNINGRULE_DETAIL = `
id
pk
type
name
instructionSectionTitle
instructionSectionContent
detailSectionTitle
detailSectionContent
generalPurchaseTypeMinimumSpending
generalPurchaseTypeMaximumSpending
generalPurchaseTypeLimitedToStores {
  edges {
    node {
      id
    }
  }
}
generalPurchaseTypeEligibleNumberOfDaysSincePurchase
generalPurchaseTypeWillExcludeDeliveryCost
generalPurchaseTypeWillExcludeOtherCharges
generalPurchaseTypeExcludedProducts
generalPurchaseTypeMerchantName
generalPurchaseSubType
merchantMapping
branchMapping
octopusMerchantId
generalPurchaseTypeReceiptNumberPattern
generalPurchaseTypeRewards {
  edges {
    node {
      id
      pk
      skuPatterns
      rewardType
      rewardPoints
      rewardCouponTemplate {
        id
        name
        pk
      }
      rewardCouponQuantity
      carbonSavingQuantity
    }
  }
}
octopusExtraRewards {
  edges {
    node {
      pk
      rewardType
      rewardPoints
      rewardCouponTemplate {
        pk
        name
      }
      rewardCouponQuantity
      carbonSavingQuantity
    }
  }
}
memberReferralTypeBeneficiary
memberReferralTypeLimit
memberReferralTypeRewardType
memberReferralTypePointsRewardTypePoints
memberReferralTypeCouponRewardTypeCouponTemplate {
  id
  name
  pk
}
memberReferralTypeCouponRewardTypeQuantity
newMemberTypeRewardType
newMemberTypePointsRewardTypePoints
newMemberTypeCouponRewardTypeCouponTemplate {
  id
  name
  pk
}
newMemberTypeCouponRewardTypeQuantity
birthdayTypePeriodStartDate
birthdayTypePeriodEndDate
birthdayTypeRewardType
birthdayTypePointsRewardTypePoints
birthdayTypeCouponRewardTypeCouponTemplate {
  id
  name
  pk
}
birthdayTypeCouponRewardTypeQuantity

qrCodeScanningTypeQrCodes
qrCodeScanningTypeOverallLimit
qrCodeScanningTypePerHeadLimit
qrCodeScanningTypePeriodicLimit
qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
qrCodeScanningTypePerHeadPeriodicLimit
qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays

qrCodeScanningTypeRewardType
qrCodeScanningTypePointsRewardTypePoints
qrCodeScanningTypeCouponRewardTypeCouponTemplate {
  id
  pk
  name
}
qrCodeScanningTypeCouponRewardTypeQuantity


gpsCheckInTypeLatitude
gpsCheckInTypeLongitude
gpsCheckInTypeRadiusInMeter
gpsCheckInTypeOverallLimit
gpsCheckInTypePerHeadLimit
gpsCheckInTypePeriodicLimit
gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
gpsCheckInTypePerHeadPeriodicLimit
gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
gpsCheckInTypeRewardType
gpsCheckInTypePointsRewardTypePoints
gpsCheckInTypeCouponRewardTypeCouponTemplate {
  id
  name
  pk
}
gpsCheckInTypeCouponRewardTypeQuantity

photoTakingTypeAllRecyclingStoresToEarnRewards
photoTakingTypeSpecificStoresToEarnRewards {
  edges {
    node {
      id
      pk
      name
    }
  }
}
photoTakingTypeConversionRate {
  edges {
    node {
      id
      pk
      recyclingItem {
        id
        pk
        name
      }
      rewardType
      rewardPoints
      rewardCouponTemplate {
        pk
        id
        name
      }
      rewardCouponQuantity
      carbonSavingQuantity
    }
  }
}
photoTakingTypePerItemQuantityLimit
photoTakingTypePerHeadPerDayTotalQuantityLimit
photoTakingTypeOverallLimit
photoTakingTypePerHeadLimit
photoTakingTypePeriodicLimit
photoTakingTypePeriodicLimitEffectiveNumberOfDays
photoTakingTypePerHeadPeriodicLimit
photoTakingTypePerHeadPeriodicLimitEffectiveNumberOfDays

habitTypeCarbonSavingGoal
habitTypeAllCampaignsToEarnRewards
habitTypeCampaignToEarnRewards {
  id
  pk
  name
}
habitTypeCarbonSavingTimesPerHeadPerDayLimit
habitTypePeriod
habitTypeRewardType
habitTypeCouponRewardTypeCouponTemplate {
  id
  name
  pk
}
habitTypeCouponRewardTypeQuantity
habitTypePointsRewardTypePoints

waterFillingTypeMachineId
waterFillingTypeOverallLimit
waterFillingTypePerHeadLimit
waterFillingTypePeriodicLimit
waterFillingTypePeriodicLimitEffectiveNumberOfDays
waterFillingTypePerHeadPeriodicLimit
waterFillingTypePerHeadPeriodicLimitEffectiveNumberOfDays

waterFillingTypeRewardType
waterFillingTypePointsRewardTypePoints
waterFillingTypeCouponRewardTypeCouponTemplate {
  id
  pk
  name
}
waterFillingTypeCouponRewardTypeQuantity

carbonImproveTypeRewardType
carbonImproveTypePointsRewardTypePoints
carbonImproveTypeCouponRewardTypeCouponTemplate {
  id
  name
  pk
}
carbonImproveTypeCouponRewardTypeQuantity

firstTryCategory
firstTryTypeRewardTypeCommon
firstTryTypePointsRewardTypePointsCommon
firstTryTypeCouponRewardTypeCouponTemplateCommon {
  id
  name
  pk
}
firstTryTypeCouponRewardTypeQuantityCommon
firstTryTypeRewardTypeForRecycling
firstTryTypePointsRewardTypePointsForRecycling
firstTryTypeCouponRewardTypeCouponTemplateForRecycling {
  id
  name
  pk
}
firstTryTypeCouponRewardTypeQuantityForRecycling
firstTryTypeRewardTypeForGreenDining
firstTryTypePointsRewardTypePointsForGreenDining
firstTryTypeCouponRewardTypeCouponTemplateForGreenDining {
  id
  name
  pk
}
firstTryTypeCouponRewardTypeQuantityForGreenDining
firstTryTypeRewardTypeForWaterFilling
firstTryTypePointsRewardTypePointsForWaterFilling
firstTryTypeCouponRewardTypeCouponTemplateForWaterFilling {
  id
  name
  pk
}
firstTryTypeCouponRewardTypeQuantityForWaterFilling


activeTrackingTypeMinimumDistance
activeTrackingTypeMaximumDistance
activeTrackingTypeRewardCarbonForReachMinimumDistance
activeTrackingTypeRewardTypeForReachMinimumDistance
activeTrackingTypePointsRewardTypePointsForReachMinimumDistance
activeTrackingTypeCouponRewardTypeCouponTemplateForReachMinimumDistance {
  id
  name
  pk
}
activeTrackingTypeCouponRewardTypeQuantityForReachMinimumDistance

activeTrackingTypeRewardCarbonForOverMinimumDistance
activeTrackingTypeRewardTypeForOverMinimumDistance
activeTrackingTypePointsRewardTypePointsForOverMinimumDistance
activeTrackingTypeCouponRewardTypeCouponTemplateForOverMinimumDistance {
  id
  name
  pk
}
activeTrackingTypeCouponRewardTypeQuantityForOverMinimumDistance

rewardType
codes
overallLimit
perHeadLimit
periodicLimit
periodicLimitEffectiveNumberOfDays
perHeadPeriodicLimit
perHeadPeriodicLimitEffectiveNumberOfDays
rewardPoints
rewardCouponTemplate {
  id
  name
  pk
}
rewardCouponQuantity

creationDate
lastModifiedDate
rewardCarbon
campaigns {
  edges {
    node {
      id
      pk
      name
    }
  }
}
translations {
  edges {
    node {
      id
      pk
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      language
    }
  }
}
`;

export const getEarningRulesWithType = (
  afterCursor,
  rank,
  type,
  nameContains = '',
  otherSearch,
) => {
  let order = rank ? '-pk' : 'pk';
  if (otherSearch?.sort) {
    order = otherSearch.sort;
  }

  let moreSearch = '';
  if (otherSearch.reward_type) {
    moreSearch += `, rewardTypeIn: "${otherSearch.reward_type}"`;
  }

  const query = `{
    earningRules(first: 20, after: "${afterCursor}", nameIcontains: "${nameContains}", type: "${
    type === 'all' ? '' : type
  }", orderBy: "${order}"${moreSearch}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${EARNINGRULE_DETAIL}
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getEarningRuleMerchantNames = () => {

  const query = `{
    earningRuleMerchantNames{
      merchantNames
    }
  }
  `;
  const variables = {
    input: {},
  };
  
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteEarningRules = (ids) => {
  const query = `mutation DeleteEarningRules($input: DeleteEarningRulesInput!) {
    deleteEarningRules(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      ${EARNINGRULE_DETAIL}
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createEarningRule = (values) => {
  const query = `
  mutation CreateEarningRule($input: CreateEarningRuleInput!) {
    createEarningRule(input: $input) {
      node {
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateEarningRule = (values) => {
  const query = `
  mutation UpdateEarningRule($input: UpdateEarningRuleInput!) {
    updateEarningRule(input: $input) {
      node {
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
