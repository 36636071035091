import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import './CouponTransactionOverview.scss';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import { couponRecordQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';

function CouponTransactionOverview() {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const couponTransactionPK = useParams().id;

  useEffect(() => {
    dispatch(
      createAction('couponTransactions/getSingleCouponTransactionRecord')({
        couponTransactionPK,
      }),
    );
  }, [couponTransactionPK, dispatch]);

  const { record } = useSelector((state) => ({
    record: state.couponTransactions.selectedCouponTransaction,
  }));

  const dataWithType = (couponTransactionType) => {
    let differentItems = [];
    switch (couponTransactionType) {
      case 'Customer earned':
        differentItems = [
          [{ title: 'Earning rule name', field: 'earningRuleName' }],
        ];
        break;
      case 'Customer acquired':
        differentItems = [
          [{ title: 'Points spent', field: 'pointsSpent' }],
          [{ title: 'Campaign name', field: 'campaignName' }],
        ];
        break;
      case 'Admin added':
      case 'Admin removed':
        break;
      case 'Customer use':
        differentItems = [[{ title: 'Used date', field: 'usedDate' }]];
        break;
      case 'Coupon expired':
        if (record.earningRuleName) {
          differentItems = [
            [{ title: 'Earning rule name', field: 'earningRuleName' }],
          ];
        } else if (record.pointsSpent) {
          differentItems = [
            [{ title: 'Points spent', field: 'pointsSpent' }],
            [{ title: 'Campaign name', field: 'campaignName' }],
          ];
        } else if (record.usedDate) {
          differentItems = [[{ title: 'Used date', field: 'usedDate' }]];
        }
        break;
      default:
        break;
    }
    const data = [
      [{ title: 'Name (preferred name)', field: 'name' }],
      [{ title: 'Membership ID', field: 'membershipId' }],
      [{ title: 'Transaction type', field: 'displayType' }],
      ...differentItems,
      [{ title: 'Coupon ID', field: 'couponID' }],
      [{ title: 'Coupon set name', field: 'couponTemplateName' }],
      [{ title: 'Expiry date', field: 'couponExpiryDate' }],
      [{ title: 'Create at', field: 'creationDate' }],
      [{ title: 'Administrator', field: 'administratorName' }],
    ];
    return data;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={record}
                fields={dataWithType(record.displayType)}
              />,
            ]}
            hidePreview={true}
            sectionStyle="coupon-transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view coupon records
          title: 'View Coupon records',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <OnlyBackButton
        backAction={() => {
          history.push({
            pathname: '/coupon_records',
            search: queryString.stringify({
              [couponRecordQueryKey.searchKey]: '',
              [couponRecordQueryKey.page]: 1,
              [couponRecordQueryKey.rank]: true,
              [couponRecordQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

export default CouponTransactionOverview;
