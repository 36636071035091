import React from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { createAction } from '../../../utils';
import { useLocation, useHistory } from 'react-router-dom';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

export default function StoreCategory() {
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [deleteItems, setDeleteItems] = React.useState([]);
  const deletePks = deleteItems.map((item) => item.pk);
  const dispatch = useDispatch();
  const {
    apiDataList,
    totalCount,
    totalPage,
    pageInfo,
    listDisplayFields,
    checkedList,
  } = useSelector((state) => {
    const { storeCategoryList } = state;
    return {
      apiDataList: storeCategoryList.categoryList,
      totalCount: storeCategoryList.totalCount,
      totalPage: storeCategoryList.totalPage,
      pageInfo: storeCategoryList.pageInfo,
      listDisplayFields: storeCategoryList.listDisplayFields,
      checkedList: storeCategoryList.checkedList,
    };
  });
  const location = useLocation();
  const history = useHistory();
  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page || 1;
  const searchKey = searchDict?.search;
  const hasSearchKey = searchKey && searchKey.length > 0;
  const fetchData = () => {
    dispatch(
      createAction('storeCategoryList/getStoreCategoryList')({
        page: currentPage - 1,
        searchKey,
        moreSearch: { ...searchDict },
      }),
    );
  };
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  React.useEffect(() => {
    setDataList(apiDataList);
  }, [apiDataList]);
  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCampaignCategory,
    },
  ];

  const orderChange = (item, order) => {
    if (item.order === order) {
      return;
    }
    dispatch(
      createAction('createStoreCategory/updateCategoryOrder')({
        id: item.pk,
        order: order,
        afterAction: fetchData
      }),
    );
  };

  const tabs = [
    {
      name: 'List Of Store Categories',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'storeCategoryList'}
              permissionGroup={PermissionCodes.campaigncategory}
              deleteInfo={{
                data: [],
                title: 'store category',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.STORE_CATEGORY,
              }}
              sortable
              onSortEnd={(oldIndex, newIndex) => {
                const newOrderObject = dataList[newIndex];
                const movedItem = dataList[oldIndex];
                orderChange(movedItem, newOrderObject.order)
              }}
              onOrderChangedByEdit={(item, newOrder) => {
                orderChange(item, newOrder)
              }}
              hasOtherActions
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Detail',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: PermissionCodes.viewSorecategory,
                  },
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeStorecategory,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.deleteStorecategory,
                  },
                  {
                    name: 'Duplicate',
                    action: () => {
                      dispatch(
                        createAction('createStoreCategory/duplicateCategory')({
                          pk: item.pk,
                          afterAction: fetchData,
                        }),
                      );
                    },
                    requires: PermissionCodes.addStorecategory,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={[
                      {
                        name: 'Move to first',
                        action: () => {
                          orderChange(item, 1)
                        },
                        requires: PermissionCodes.changeStorecategory,
                      },
                      {
                        name: 'Move to last',
                        action: () => {
                          orderChange(item, totalCount)
                        },
                        requires: PermissionCodes.changeStorecategory,
                      },
                    ]}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];
  const buttons = [
    <AuthButton
      title="Create store category"
      action={() => {
        history.push('store_category/create');
      }}
      requires={PermissionCodes.addStorecategory}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'You can create different categories to group your stores',
          title: NavBarNames.storeCategory,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="Store Category"
        relatedSections={DELETE_RELATED_SECTIONS.STORE_CATEGORY}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch(
            createAction('createStoreCategory/deleteCategories')({
              ids: deletePks,
              afterAction: fetchData,
            }),
          );
        }}
      />
    </>
  );
}
