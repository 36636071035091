import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CreateEarningRuleStepOne from './CreateEarningRuleStepOne';
import CreateEarningRuleStepTwo from './CreateEarningRuleStepTwo';
import CreateEarningRuleStepThree from './CreateEarningRuleStepThree';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { SavedStatus } from '../../../config/CustomEnums';
import {
  getCmapaignCreateSucessStepBar,
  CREATE_EARNING_RULE,
} from '../../../components/base/prompt/NextStepConfig';
import { CampaignType } from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { isSpecificEarningRule } from '../../../models/CreateEarningRulesModel';

function CreateEarningRule() {
  const { stepConfig, currentStep, earningRule, savedSucess } = useSelector(
    (state) => {
      return {
        stepConfig: state.createEarningRules.stepConfig,
        currentStep: state.createEarningRules.currentStep,
        earningRule: state.createEarningRules.earningRule,
        savedSucess: state.createEarningRules.saved,
      };
    },
  );

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const earningRuleId = params.id;
    if (earningRuleId) {
      dispatch(
        createAction('createEarningRules/getOneEarningRule')({
          id: earningRuleId,
        }),
      );
    } else {
      dispatch({
        type: 'createEarningRules/loadEarningRuleFromCookie',
      });
    }
    return () => {
      dispatch({ type: 'createEarningRules/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [stepSet, setStepSet] = useState([<Loading />]);

  const caution = () => {
    if (params.id) {
      return {
        detail: '', //Create a campaign to engage your custoemrs. You can create different types of campaign.
        title: 'Edit Earning Rule ',
        model: 'createEarningRules',
      };
    } else {
      return {
        detail: '', //Create a campaign to engage your custoemrs. You can create different types of campaign.
        title: 'Create Earning Rule ',
        model: 'createEarningRules',
      };
    }
  };

  useEffect(() => {
    if ((params.id && earningRule.pk) || !params.id) {
      const steps = [
        <CreateEarningRuleStepTwo />,
        <CreateEarningRuleStepThree />,
      ];
      const isSpecificEarning = isSpecificEarningRule(earningRule?.type);
      if (isSpecificEarning) {
        dispatch({ type: 'createEarningRules/changeStepConfig' });
        dispatch(
          createAction('storeCategoryList/getStoreCategoryList')({
            moreSearch: { idIn: '1' },
          }),
        );
      } else {
        steps.unshift(<CreateEarningRuleStepOne />);
      }
      setStepSet(steps);
    }
  }, [earningRule, params.id]);

  useEffect(() => {
    if (
      // savedSucess === SavedStatus.savedWithFail ||
      history.location.pathname.includes('edit') &&
      savedSucess === SavedStatus.savedWithSuccess
    ) {
      toEarningRuleList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSucess]);

  const toEarningRuleList = () => {
    history.push('/earns');
  };

  return (
    <>
      <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            dispatch({
              type: 'createEarningRules/removeEarningRuleFromCookie',
            });
          }
        }}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={<CustomBreadcrumb />}
        stepsConfig={stepConfig}
        caution={caution()}
        showFinishPop={savedSucess === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: `The earning rule is successfully created. Let's create an earning campaign to activate the earning rule.`,
          steps: {
            stepNames: getCmapaignCreateSucessStepBar(
              CampaignType.earningRulesCampaign,
              true,
            ),
            current: CREATE_EARNING_RULE,
          },
          buttons: [
            {
              text: 'Create Earning campaign',
              action: () => {
                history.push({
                  pathname: '/campaigns/create',
                  search: `type=${CampaignType.earningRulesCampaign}&id=${earningRule?.pk}`,
                });
              },
              requires: PermissionCodes.addCampaign,
            },
            {
              text: 'Maybe Later',
              action: toEarningRuleList,
              requires: '',
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: toEarningRuleList,
          onClose: toEarningRuleList,
        }}
      />
    </>
  );
}

export default CreateEarningRule;
