import { BaseHelper } from './BaseGraphQLAPIHelper';

const FEEDBACK_NODE = `{
  pk
  id
  creationDate
  latitude
  longitude
  customer {
    id
    pk
    firstName
  }
  store {
    pk
    id
    name
  }
  reportType
  images
  missingItems
  openingHours
  locationTag
  reportLocation
  reportLocationInfo
  addInfo
  extraItems
  restaurantName
  subcategory(first: 100, orderBy: "-pk") {
    edges {
      cursor
      node {
        pk
        id
        name
      }
    }
  }
  shopName
  greenShoppingType {
    pk
    name
  }
}`;

export const getFeedbackList = (afterCursor, moreSearch) => {
  const orderBy = moreSearch?.positive ? 'creationDate' : '-creationDate';
  console.log("getFeedbackList:", moreSearch)
  let filter = '';
  if (moreSearch?.activity_type) {
    filter += `, subcategoryIn: "${moreSearch.activity_type}"`;
  }
  if (moreSearch?.search) {
    filter += `, storeNameIcontains:"${moreSearch.search}"`;
  }
  if (moreSearch.report_type) {
    filter += `, reportTypeIn: "${moreSearch.report_type}"`;
  }
  const query = `{
    customerFeedbacks(first: 20, after:"${afterCursor}", orderBy: "${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          pk
          id
          creationDate
          store {
            pk
            id
            name
          }
          reportType
          subcategory(first: 100, orderBy: "-pk") {
            edges {
              cursor
              node {
                pk
                id
                name
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getFeedbackDetail = (id) => {
  const query = `{
    customerFeedback(id: "${id}") ${FEEDBACK_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteFeedbacks = (ids) => {
  const query = `mutation DeleteCustomerFeedbacks($input: DeleteCustomerFeedbacksInput!) {
    deleteCustomerFeedbacks(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
