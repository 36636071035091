import { loading } from './LoadingUtil';
import { convertNumberToCursor, convertCursorToNumber } from '../utils';
import {
  getStoreCategoryList,
  getStoreSubCategoryList,
} from '../services/StoreCategoryAPIHelper';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Store category', fieldName: 'name', orderField: "name" },
    { displayName: 'Display order', fieldName: 'order', orderField: "displayPriority" },
    { displayName: 'Sub-category', fieldName: 'formattedSubcategoryName' },
  ],
  categoryList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  subCategoryList: [],
});

export default {
  namespace: 'storeCategoryList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    assembleCategoryList(state, { payload }) {
      const categoryList = payload.categoryList.map(({ node }, index) => {
        const subcategories = node.subcategories.edges.map(
          (subCategoryEdge) => subCategoryEdge.node,
        );
        return {
          id: node.id,
          pk: node.pk,
          name: node.name,
          order: node.displayPriority,
          subcategories,
          formattedSubcategoryName: subcategories
            .map((subCategory) => subCategory.name)
            .join(', '),
        };
      });
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        categoryList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },
  },
  effects: {
    getStoreCategoryList: [
      function* ({ payload }, { put }) {
        const { searchKey, moreSearch } = payload;
        const page = payload.page !== undefined ? payload.page : 0;
        const pageCursor = page ? convertNumberToCursor(page * 20 - 1) : '';
        const serviceArgs = [getStoreCategoryList, pageCursor, searchKey, moreSearch];

        function* onSuccess(data) {
          const categories = data.storeCategories;
          yield put({
            type: 'assembleCategoryList',
            payload: {
              categoryList: categories.edges,
              totalCount: categories.totalCount,
              pageInfo: categories.pageInfo,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllStoreSubcategoryList: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getStoreSubCategoryList];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              subCategoryList: data.storeSubcategories.edges.map(
                (item) => item.node,
              ),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
