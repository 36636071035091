import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { Button } from 'react-bootstrap';


const AfcCronjobRecordDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { apiStatus, afcCronjobRecord } = useSelector((state) => ({
    afcCronjobRecord: state.afcCronjobRecords.afcCronjobRecord,
    apiStatus: state.afcCronjobRecords.apiStatus
  }));

  console.log('@28', afcCronjobRecord)
  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={afcCronjobRecord}
                fields={[
                  [{ title: 'Source', field: 'source' }],
                  [{ title: 'Type', field: 'type' }],
                  [{ title: 'Start Datetime', field: 'startDatetime' }],
                  [{ title: 'End Datetime', field: 'endDatetime' }],
                  [{ title: 'Status', field: 'status' }],
                  [{ title: 'Fail Log Message', field: 'failLogMessage' }],
                ]}
                // extraDataFields={{ numberOfcustomers: customerTotalCount }}
              />,
            ]}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    const recordId = params.id;
    if (recordId) {
      dispatch(
        createAction('afcCronjobRecords/getOneAfcCronjobRecord')({ id: recordId }),
      );
    }
    return () => {
      dispatch({ type: 'afcCronjobRecords/clearData' });
    };
  }, []);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: afcCronjobRecord?.pk,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={afcCronjobRecord?.pk} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

export default AfcCronjobRecordDetail;
