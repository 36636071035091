import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import { CustomTitleLabel } from './CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { useCompare } from '../../utils';
import CustomRadios from '../base/CustomRadios';
import { GeneralPurchaseSubType, GeneralPurchaseSubTypeOptionsWithoutGeneralType } from '../../models/CreateEarningRulesModel';
import './GeneralPurchaseSection.scss';

function GeneralPurchaseSection({
  onSubmit,
  onSubmitAction = () => { },
  defaultValue = {},
}) {
  const dispatch = useDispatch();
  const [merchantName, setMerchantName] = useState(defaultValue.merchantName);
  const [merchantMapping, setMerchantMapping] = useState(defaultValue.merchantMapping);
  const [branchMapping, setBranchMapping] = useState(defaultValue.branchMapping);
  const [octopusMerchantId, setOctopusMerchantId] = useState(defaultValue.octopusMerchantId);
  const [generalPurchaseSubType, setGeneralPurchaseSubType] = useState(
    defaultValue.generalPurchaseSubType || GeneralPurchaseSubType.GREEN_DINING.value
  );
  // const [receiptNumberPattern, setReceiptNumberPattern] = useState(
  //   defaultValue.receiptNumberPattern,
  // );

  const vals = {
    merchantName,
    merchantMapping,
    branchMapping,
    octopusMerchantId,
    generalPurchaseSubType,
    // receiptNumberPattern,
  };
  const hasValsChanged = useCompare(vals);
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));
  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.merchantName.name}`, errorFields);
    firstError(`${EarningRuleErrorHandleField.merchantMapping.name}`, errorFields);
    firstError(`${EarningRuleErrorHandleField.branchMapping.name}`, errorFields);
    // firstError(
    //   `${EarningRuleErrorHandleField.receiptNumberPattern.name}`,
    //   errorFields,
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">GENERAL PURCHASE</label>

      <CustomTitleLabel title="General Purchase Type" />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <CustomRadios
          onChange={(value) => {
            setGeneralPurchaseSubType(value);
          }}
          default={generalPurchaseSubType}
          options={GeneralPurchaseSubTypeOptionsWithoutGeneralType}
        />
      </div>

      <CustomTitleLabel 
        title="Merchant Name (for displaying in frontend)" 
        className="create-earning-rule-title-with-tips"  
      />
      <label className="tips-message create-earning-rule-tips" >
        For earning rules with the same merchant, it's a MUST for Merchant Name (display in frontend) to be the same
      </label>
      <input
        type="text"
        onChange={({ target }) => setMerchantName(target.value)}
        className="custom-markdown-area-title"
        defaultValue={merchantName}
        onFocus={() => { }}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.merchantName.name}`}
        error={isError(EarningRuleErrorHandleField.merchantName.name)}
        message={`${EarningRuleErrorHandleField.merchantName.message}`}
      />

      <CustomTitleLabel title="Merchant Name (for internal mapping)" />
      <input
        type="text"
        onChange={({ target }) => setMerchantMapping(target.value)}
        className="custom-markdown-area-title"
        defaultValue={merchantMapping}
        onFocus={() => { }}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.merchantMapping.name}`}
        error={isError(EarningRuleErrorHandleField.merchantMapping.name)}
        message={`${EarningRuleErrorHandleField.merchantMapping.message}`}
      />

      <CustomTitleLabel title="Branch" />
      <label className="tips-message">Allow multiple input. For example: branch 1, branch 2, branch 3</label>
      <input
        type="text"
        onChange={({ target }) => setBranchMapping(target.value)}
        className="custom-markdown-area-title"
        defaultValue={branchMapping}
        onFocus={() => { }}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.branchMapping.name}`}
        error={isError(EarningRuleErrorHandleField.branchMapping.name)}
        message={`${EarningRuleErrorHandleField.branchMapping.message}`}
      />

      <CustomTitleLabel 
        title="Octopus Merchant ID" 
        className="create-earning-rule-title-with-tips"    
      />
      <label className="tips-message" style={{ marginBottom: 0 }}>
        Please only fill this in <b>for the General Purchase Type that rewards the highest amount of CW Points</b>.
      </label>
      <label className="tips-message">
        When this is filled, it will trigger Octopus Reward below
      </label>
      <input
        type="text"
        onChange={({ target }) => setOctopusMerchantId(target.value)}
        className="custom-markdown-area-title"
        defaultValue={octopusMerchantId}
        onFocus={() => { }}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.octopusMerchantId.name}`}
        error={isError(EarningRuleErrorHandleField.octopusMerchantId.name)}
        message={`${EarningRuleErrorHandleField.octopusMerchantId.message}`}
      />

      {/* <CustomTitleLabel title="Receipt number pattern" />
      <input
        type="text"
        onChange={({ target }) => setReceiptNumberPattern(target.value)}
        className="custom-markdown-area-title"
        defaultValue={receiptNumberPattern}
        onFocus={() => { }}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.receiptNumberPattern.name}`}
        error={isError('receiptNumberPattern')}
        message={`${EarningRuleErrorHandleField.receiptNumberPattern.message}`}
      /> */}
    </>
  );
}

export default GeneralPurchaseSection;
