import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import { createAction, useCompare } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import {
  SaveAndBackButtons,
  ContinueWithBackButtons,
} from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseEditSection from '../../base/BaseEditSection';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import CustomTitleWithSwitch from '../../../components/base/CustomTitleWithSwitch';
import CustomAddRemoveMutipleSelector from '../../../components/customer/CustomAddRemoveMutipleSelector';
import {
  CheckStatus,
  DELETE_RELATED_SECTIONS,
  SavedStatus,
} from '../../../config/CustomEnums';
import { AdminErrorHandleFields } from './AdminErrorHandleFields';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import { Button } from 'react-bootstrap';
import ChangePasswordPrompt from '../../../components/admin/ChangePasswordPrompt';
import AddNewAdminGroupPrompt from '../../../components/admin/AddNewAdminGroupPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ToastType } from '../../../models/NavBarModel';
import BasePrompt from '../../../components/base/prompt/BasePrompt';

const ChangePasswordCom = ({ changeAction }) => {
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title="Password" />
      <label className="tips-message">
        Raw passwords are not stored, so there is no way to see this user's
        password.
      </label>
      <label className="tips-message">{'*'.repeat(10)}</label>
      <Button className="btn-back-button-common" onClick={changeAction}>
        Change
      </Button>
    </div>
  );
};

function CreateAdmin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const {
    admin,
    checked,
    errorFields,
    saved,
    stepConfig,
    currentStep,
    groupList,
    allPermissions,
  } = useSelector((state) => ({
    admin: state.admin.oneAdmin || {},
    checked: state.admin.checked,
    errorFields: state.admin.errorFields,
    saved: state.admin.saved,
    stepConfig: state.admin.stepConfig,
    currentStep: state.admin.currentStep,
    groupList: state.adminGroup.allList || [],
    allPermissions: state.admin.allPermissionList, //state.admin.cmsAllPermissions,
  }));

  const [username, setUserName] = useState(admin.username);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [firstName, setFirstName] = useState(admin.firstName);
  const [lastName, setLastName] = useState(admin.lastName);
  const [email, setEmail] = useState(admin.email);

  const [isActive, setIsActive] = useState(admin.isActive);
  const [isSuper, setIsSuper] = useState(admin.isSuperuser);

  const [groups, setGroups] = useState(admin.groups);
  const [permissions, setPermissions] = useState(admin.permissions);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showChangePassPrompt, setShowChangePassPrompt] = useState(false);
  const [showAddAdminGroupPrompt, setShowAddAdminGroupPrompt] = useState(false);
  const [showAlertPrompt, setShowAlertPrompt] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: '', content: '' });
  //   const [didMount, setDidMount] = useState(false);
  //   const [stepOneSections, setStepOneSections] = useState([<Loading />]);
  // const [stepTwoSections, setStepTwoSections] = useState([<Loading />]);

  const adminPermission = [
    PermissionCodes.changeAdministrator,
    PermissionCodes.changeAdministratorGroup,
  ];

  const getError = (field) => {
    return {
      id: field,
      error: errorFields?.fields?.includes(field),
      message: errorFields?.messages?.map((item) => {
        if (item.field === field) {
          if (item.errorType === 'required')
            return AdminErrorHandleFields[item.errorType][item.field];
          else
            return AdminErrorHandleFields['others'][item.field][item.errorType];
        }
      }),
    };
  };

  const vals = {
    username,
    password,
    passwordConfirmation,
    firstName,
    lastName,
    email,
    isActive,
    isSuper,
    groups,
    permissions,
  };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'admin/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    setUserName(admin.username);
    setPassword(admin.password);

    setFirstName(admin.firstName);
    setLastName(admin.lastName);
    setEmail(admin.email);

    setIsActive(
      admin.isActive === null || admin.isActive === undefined
        ? true
        : admin.isActive,
    );
    setIsSuper(admin.isSuperuser);

    setPermissions(admin.permissions);
    setGroups(admin.adminGroups);

    setTimeout(() => {
      dispatch({
        type: 'admin/updateState',
        payload: { formChanged: false, isInit: true },
      });
    }, 500);
  }, [dispatch, admin]);
  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      if (currentStep === stepConfig.length - 1) {
        dispatch(createAction('admin/updateOrCreate')({}));
      } else {
        dispatch({ type: 'admin/stepChange', payload: {} });
      }
    }
  }, [dispatch, checked, currentStep, stepConfig]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/administrators');
    }
  }, [saved, history]);

  //   useEffect(() => setDidMount(true), []);

  useEffect(() => {
    if (id) {
      dispatch(createAction('admin/getOneAdmin')({ id }));
    } else {
      dispatch({ type: 'admin/loadDataFromCookieSession' });
    }

    // dispatch(
    //   createAction('adminGroup/getPagedAdminGroups')({
    //     page: 1,
    //     reverse: true,
    //     search: '',
    //   }),
    // );
    // dispatch(createAction('admin/getAllPermissions')());
  }, [dispatch, id]);

  const stepOneSections = [
    <BaseEditSection
      title="General"
      fields={
        id
          ? [
              <CustomTitleWithInput
                title={'Name'}
                defaultValue={username}
                setValue={setUserName}
                error={getError('name')}
              />,
              <ChangePasswordCom
                changeAction={() => setShowChangePassPrompt(true)}
              />,
            ]
          : [
              <CustomTitleWithInput
                title={'Name'}
                defaultValue={username}
                setValue={setUserName}
                error={getError('username')}
              />,

              <CustomTitleWithInput
                title={'Password'}
                defaultValue={password}
                setValue={setPassword}
                error={getError('password')}
                type="password"
                tips="Password at least 8 characters, can't be entirely numeric."
              />,
              <CustomTitleWithInput
                title={'Password confirmation'}
                defaultValue={passwordConfirmation}
                setValue={setPasswordConfirmation}
                error={getError('passwordConfirmation')}
                type="password"
                tips="Enter the same password as before, for verification"
              />,
            ]
      }
    />,
    <BaseEditSection
      title="Personal Information"
      fields={[
        <CustomTitleWithInput
          title={'First name(optional)'}
          defaultValue={firstName}
          setValue={setFirstName}
          error={getError('firstName')}
        />,
        <CustomTitleWithInput
          title={'Last name(optional)'}
          defaultValue={lastName}
          setValue={setLastName}
          error={getError('lastName')}
        />,
        <CustomTitleWithInput
          title={'Email address(optional)'}
          defaultValue={email}
          setValue={setEmail}
          error={getError('email')}
        />,
      ]}
    />,
  ];

  const stepTwoSections = [
    <BaseEditSection
      title="Status"
      fields={[
        <CustomTitleWithSwitch
          title={'Active'}
          defaultValue={isActive}
          setValue={setIsActive}
          error={getError('active')}
        />,
        <CustomTitleWithSwitch
          title={'Superadmin status'}
          defaultValue={isSuper}
          setValue={setIsSuper}
          error={getError('isSuper')}
        />,
      ]}
    />,
    !isSuper ? (
      <BaseEditSection
        title="Permission"
        fields={[
          <CustomAddRemoveMutipleSelector
            title={'Administrator groups(optional)'}
            data={groupList}
            selectedList={groups}
            addButton={{
              title: 'Add Administrator group ',
              action: () => {
                setShowAddAdminGroupPrompt(true);
              },
            }}
            setValue={setGroups}
            sourceReminderText={'All Administrator group(s)'}
            amidReminderText={'Chosen administrator group(s)'}
            requires={PermissionCodes.addAdministratorGroup}
            loadMoreAction="adminGroup/getPagedAdminGroups"
            filterAction="adminGroup/getPagedAdminGroups"
          />,
          <CustomAddRemoveMutipleSelector
            title={'Administrator permission(optional)'}
            data={allPermissions}
            selectedList={permissions}
            setValue={setPermissions}
            sourceReminderText={'All Administrator group(s)'}
            amidReminderText={'Chosen administrator group(s)'}
            loadMoreAction="admin/getAllPermissions"
            filterAction="admin/getAllPermissions"
          />,
        ]}
      />
    ) : null,
  ];

  const stepSet = [
    <>
      <ContentSections sections={stepOneSections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          if (
            !id &&
            password &&
            passwordConfirmation &&
            password !== passwordConfirmation
          ) {
            dispatch({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: ToastType.passwordConfirmation,
                },
              },
            });
          } else {
            dispatch({ type: 'admin/checkValsValid' });
          }
        }}
        backAction={() => history.goBack()}
      />
      <ChangePasswordPrompt
        admin={admin}
        show={showChangePassPrompt}
        onClose={() => setShowChangePassPrompt(false)}
        onConfirm={() => setShowChangePassPrompt(false)}
      />
    </>,
    <>
      <ContentSections sections={stepTwoSections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText={id ? 'Delete' : null}
        saveText={id ? 'Updated' : 'Save'}
        backAction={() => {
          dispatch({ type: 'admin/stepChange', payload: { isBack: true } });
        }}
        saveAction={() => {
          const hasAdminPermissionGroups = groups?.filter(
            (group) =>
              group.permissions?.edges.filter((val) =>
                adminPermission.includes(val.node.codename),
              ).length > 0,
          );

          if (isSuper) {
            setAlertContent({
              title: 'Confirm to give this admin permission',
              content:
                'As this a superadmin, then the admin will gain full system permission by doing so. ',
            });
            setShowAlertPrompt(true);
          } else if (hasAdminPermissionGroups?.length > 0) {
            setAlertContent({
              title: 'Confirm to give this admin permission',
              content: `As this admin belong to ${hasAdminPermissionGroups
                .map((group) => group.name)
                .join(
                  ',',
                )}, then the admin will gain full system permission by doing so. `,
            });
            setShowAlertPrompt(true);
          } else if (
            permissions?.filter((permission) =>
              adminPermission.includes(permission.codename),
            ).length > 0
          ) {
            setAlertContent({
              title: 'Confirm to give this admin permission',
              content: `As "change admin permissions" is set, then the admin will gain full system permission by doing so. `,
            });
            setShowAlertPrompt(true);
          } else {
            dispatch({ type: 'admin/checkValsValid' });
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteAdministrator}
      />
      <AddNewAdminGroupPrompt
        show={showAddAdminGroupPrompt}
        onClose={() => setShowAddAdminGroupPrompt(false)}
        onConfirm={(item) => {
          // createAction('adminGroup/getPagedAdminGroups')({
          //   page: 1,
          //   reverse: true,
          //   search: '',
          // });
          // const newGroups = [...groups, item];
          setGroups(groups ? [...groups, item] : [item]);
          setShowAddAdminGroupPrompt(false);
        }}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Admin',
          content: `Are you sure you want to delete the administrator "${admin.name}"? All of the following related items will be deleted:`,
        }}
        title={'Admin'}
        data={admin}
        relatedSections={DELETE_RELATED_SECTIONS.ADMIN}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `admin/updateState`,
            payload: { checkedList: [admin], formChanged: false },
          });
          dispatch(
            createAction('admin/delete')({
              afterAction: () => {
                history.push('/administrators');
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={showAlertPrompt}
        closeAction={() => setShowAlertPrompt(false)}
        rightButton={{
          text: 'Confirm',
          action: () => {
            dispatch({ type: 'admin/checkValsValid' });
          },
        }}
        title={alertContent.title}
        description={alertContent.content}
      />
    </>,
  ];

  return (
    <div className="customer">
      <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            dispatch({
              type: 'admin/removeAdminFromCookie',
            });
          }
        }}
        showFinishPop={saved === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Administrators is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to administrators list',
              action: () =>
                history.push({
                  pathname: '/administrators',
                }),
            },
          ],
        }}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={
          <CustomBreadcrumb
            name={id ? admin.username : 'Create Administrators'}
          />
        }
        caution={{
          detail: '', //You can create an administrator here and give him the appropriate permissions
          title: `${id ? admin.username : 'Create Administrators'}`,
          model: 'admin',
        }}
        stepsConfig={stepConfig}
      />
    </div>
  );
}

export default CreateAdmin;
