import React, { useEffect } from 'react';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import { useSelector, useDispatch } from 'react-redux';
import BaseEditSection from '../../containers/base/BaseEditSection';
import './TargetCustomerSection.scss';
import { createAction } from '../../utils';

function TargetCustomerSection() {
  const { customerList, selectedCustomerList, levelList } = useSelector(
    (state) => ({
      customerList: state.customerList.customerList,
      selectedCustomerList: state.customerGroup.selectedCustomerGroup.customers,
      levelList: state.levels.levelList,
    }),
  );

  const dispatch = useDispatch();

  const optionList = (list) => {
    if (!list) {
      return [];
    }
    return list.map((item) => {
      return {
        name: item.name,
        value: { ...item, subFilterField: item.level },
      };
    });
  };

  // useEffect(() => {
    // dispatch(createAction('levels/getLevelList')({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="create-customer-group-target">
      <BaseEditSection
        title="TARGET CUSTOMER"
        fields={[
          <CustomAddRemoveMutipleSelector
            isSourceReminderFilterable={true}
            isAmidRreminderFilterable={true}
            sourceReminderEnabelSelectAll={true}
            sourceReminderSubfilterKey={'levelIn'}
            sourceReminderSubFilterItems={optionList(levelList)}
            title={'Customer'}
            data={optionList(customerList)}
            selectedList={optionList(selectedCustomerList)}
            setValue={(options) => {
              dispatch(
                createAction('customerGroup/setFieldToSession')({
                  customers: options.map((item) => item.value),
                }),
              );
              dispatch(
                createAction('customerGroup/updateSelectedCustomerGroupState')({
                  customers: options.map((item) => item.value),
                }),
              );
            }}
            sourceReminderText={''}
            amidReminderText={''}
            loadMoreAction={'customerList/getPagedCustomers'}
            // filterPlaceHolder={'Search by name / membership ID'}
            isCustomerItem={true}
            filterAction={'customerList/getPagedCustomers'}
          />,
        ]}
      />
    </div>
  );
}

export default TargetCustomerSection;
