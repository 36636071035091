import {
  getBrands,
  deleteBrand,
  createBrand,
  getBrand,
  updateBrand,
} from '../services/BrandAPIHelper';
import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  getFileNameFromUrl,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { defaultStep } from './StepBarUtil';
import { LanguageConfig } from '../config/CustomEnums';
import { isValidHttpUrl } from '../utils/CheckValidUtil';

export const BrandActivityType = {
  RECYCLING: {
    name: 'Recycling',
    value: 'RECYCLING',
  },
  GREEN_DINING: {
    name: 'Green Dining',
    value: 'GREEN_DINING',
  },
  PLANT_MILK: {
    name: 'Plant Milk',
    value: 'PLANT_MILK',
  },
  GREEN_SHOPPING: {
    name: 'Green Shopping',
    value: 'GREEN_SHOPPING',
  },
  WATER_FILLING: {
    name: 'Water Filling',
    value: 'WATER_FILLING',
  }
}

export const BrandActivityTypeList = Object.values(BrandActivityType);

export const CreateBrandError = {
  brandName: {
    name: 'brandName',
    message: 'Please provide a name.',
  },
  brandIcon: {
    name: 'brandIcon',
    message: 'Please provide a logo image.',
  },
  brandPromotionPhoto: {
    name: 'brandPromotionPhoto',
    message: 'Please provide a promotion image.',
  },
  brandDisplayPriority: {
    name: 'brandDisplayPriority',
    message: 'Please provide a display priority.',
  },
  brandTag: {
    name: 'brandTag',
    message: 'Please provide correct brand tag.',
  },
  brandPageLink: {
    name: 'brandPageLink',
    message: 'Please provide correct url.',
  }
};

export const sessionDataKey = {
  objectKey: 'createBrand',
  stepEndKey: 'createBrandStepEnd',
  origionalData: 'createBrandOriginalData',
};


const checkFields = (data) => {
  let errorFields = [];
  if (!data.name) {
    errorFields.push(CreateBrandError.brandName.name);
  }
  if (!data.coverPhoto) {
    errorFields.push(CreateBrandError.brandIcon.name);
  }
  if (!data?.promotionPhoto) {
    errorFields.push(CreateBrandError.brandPromotionPhoto.name);
  };
  if (!data?.displayPriority) {
    errorFields.push(CreateBrandError.brandDisplayPriority.name);
  };
  if (data?.brandTag?.length) {
    const invalidBrandTag = data?.brandTag?.filter(item => item?.length > 50);
    if (invalidBrandTag?.length) {
      errorFields.push(CreateBrandError.brandTag.name);
    };
  };
  if (data?.brandPageLink?.length) {
    if (!isValidHttpUrl(data?.brandPageLink)) {
      errorFields.push(CreateBrandError.brandPageLink.name);
    }
  };

  console.log("@@137", data, errorFields)
  return {
    invalid: errorFields.length > 0,
    errorFields: errorFields,
    data,
  };
};

const getInitialState = () => ({
  brandList: [],
  receiptPartnerList: [],
  receiptPartnerListCompleted: false,
  oclPartnerList: [],
  oclPartnerListCompleted: false,
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Display order', fieldName: 'displayPriority', orderField: 'displayPriority' },
    { displayName: 'Related Store', fieldName: 'relatedStoreNames' },
    { displayName: 'Show in barcode', fieldName: 'displayShowInBarcode' },
    { displayName: 'Barcode partner', fieldName: 'displayCodePartner' },
    { displayName: 'Receipt partner', fieldName: 'displayReceiptPartner' },
    { displayName: 'OCL partner', fieldName: 'displayOclPartner' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageBrandList: [],
  selectedBrand: {},
  errorFields: [],
  stepConfig: defaultStep([]),
  currentStep: 0,
});

const getTranslations = (translationList = []) => {
  const translation = {};
  translationList.forEach((item) => {
    let language = item.node.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    }
    if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    translation[language] = {
      id: item.node.pk,
      name: item.node.name,
      brandDescription: item.node.description,
      brandTag: item.node.brandTag?.split(",") || [],
      language,
    };
  });
  return translation;
};

const getStoreTranslations = (translationList) => {
  const translation = {};
  if (!translationList || translationList.length === 0) {
    return {};
  }
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      storeName: item.node.name,
      storeAddress: item.node.address,
      storeDescription: item.node.description,
    };
  });
  return translation;
};

export const parseStore = (storeInfo) => {
  const store = storeInfo.node;
  return {
    storeID: store.id,
    storePK: store.pk,
    storeName: store.name,
    storeAddress: store.address,
    storeDescription: store.description,
    storeTranslations: getStoreTranslations(store.translations?.edges),
  };
};

export const parseBrand = (brand) => {
  let relatedStoreNameList = [];
  if (brand.stores) {
    relatedStoreNameList = brand.stores?.edges?.map((store) => store.node.name);
  }
  const activityType = brand.activityType?.map(item => BrandActivityType?.[item]);

  return {
    id: brand.id,
    pk: brand.pk,
    name: brand.name,
    coverPhoto: brand.icon,
    promotionPhoto: brand.coverPhoto,
    displayPriority: brand.displayPriority,
    brandTag: brand.brandTag?.split(',') || [],
    brandPageLink: brand.brandPageLink || '',
    activityType: activityType || [],
    brandDescription: brand.description,
    brandTranslations: getTranslations(brand.translations?.edges),
    brandStores: brand.stores?.edges.map((store) => parseStore(store)),
    isForcedInactive: brand.isForcedInactive,
    showInBarcode: brand.showInBarcode,
    barcodePartner: brand.barcodePartner,
    displayCodePartner: brand.barcodePartner ? "Yes" : "No",
    receiptPartner: brand.receiptPartner,
    displayReceiptPartner: brand.receiptPartner ? "Yes" : "No",
    oclPartner: brand.oclPartner,
    displayOclPartner: brand.oclPartner ? "Yes" : "No",
    displayShowInBarcode: brand.showInBarcode ? "Yes" : "No",
    relatedStoreNames:
      relatedStoreNameList.length > 0 ? relatedStoreNameList.toString() : '',
  };
};

export const parseBrandInputBody = (brand, isEdit = false) => {
  let brandUrl = [];
  if (brand.coverPhoto.type) {
    brandUrl = brand.coverPhoto.value;
  } else {
    brandUrl = brand.coverPhoto;
  }

  let brandPromotionPhotoUrl = [];
  if (brand.promotionPhoto?.type) {
    brandPromotionPhotoUrl = brand.promotionPhoto?.value;
  } else {
    brandPromotionPhotoUrl = brand.promotionPhoto;
  }

  const coverPhotoName = getFileNameFromUrl(brandUrl);
  const promotionPhotoName = getFileNameFromUrl(brandPromotionPhotoUrl);
  const activityType = brand?.activityType?.length
    ? brand?.activityType?.map(item => item?.value)
    : null;

  const translations = []
  const languages = [LanguageConfig.traditionalChinese, LanguageConfig.simplifiedChinese]
  console.log("brand.brandTranslations:", brand.brandTranslations)
  languages.forEach((language) => {
    const translate = brand.brandTranslations?.[language] || {}
    const brandTag = (translate?.brandTag || []).join(',') || null
    const translateData = {
      brandTag,
      description: translate.brandDescription,
      name: translate.name,
      language: language
    }
    if (translate.id) {
      translateData.id = translate.id
    }
    translations.push(translateData)
  })
  console.log("Brandtranslations:", translations)
  let inputBody = {
    name: brand.name,
    icon: coverPhotoName,
    coverPhoto: promotionPhotoName,
    displayPriority: brand.displayPriority,
    brandTag: brand.brandTag?.filter(item => !!item?.length)?.join(',') || null,
    brandPageLink: brand?.brandPageLink || null,
    activityType,
    description: brand.brandDescription,
    isForcedInactive: brand.isForcedInactive,
    showInBarcode: brand.showInBarcode || false,
    barcodePartner: brand.barcodePartner || false,
    receiptPartner: brand.receiptPartner || false,
    oclPartner: brand.oclPartner || false,
    translations: translations
  };
  if (isEdit) {
    inputBody = {
      ...inputBody,
      id: brand.pk,
    };
  }
  return inputBody;
};

export default {
  namespace: 'brand',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateBrandList(state, { payload }) {
      const { newBrandList, page, receiptPartner, oclPartner } = payload;

      const result = state;
      if (receiptPartner) {
        result.receiptPartnerList = page > 1 ? [...state.receiptPartnerList, ...newBrandList] : newBrandList;
      } else if (oclPartner) {
        result.oclPartnerList = page > 1 ? [...state.oclPartnerList, ...newBrandList] : newBrandList;
      } else {
        result.brandList = page > 1 ? [...state.brandList, ...newBrandList] : newBrandList;
      }
      return result;
    },
    updateSelectedBrand(state, { payload }) {
      let brand = {};
      if ([LanguageConfig.traditionalChinese, LanguageConfig.simplifiedChinese].includes(payload?.language)) {
        const brandTranslations = {
          ...state.selectedBrand.brandTranslations,
          [payload?.language]: {
            ...state.selectedBrand.brandTranslations?.[payload?.language],
            ...payload
          },
        }
        brand = { ...state.selectedBrand, brandTranslations };
      } else {
        brand = { ...state.selectedBrand, ...payload }
      }
      const key = Object.keys(payload)[0];
      if (state.selectedBrand[key] !== payload[key]) {
        saveToSessionStorage(sessionDataKey.objectKey, brand);
      }
      return {
        ...state,
        selectedBrand: brand,
      };
    },
    loadBrandFromCookie(state, { payload }) {
      const brand = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!brand) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, brand);
      saveToSessionStorage(sessionDataKey.objectKey, brand);
      return {
        ...state,
        selectedBrand: brand,
      };
    },

    saveOrRemoveBrandFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    cleanSelectedBrand(state, action) {
      return {
        ...state,
        selectedBrand: {},
        errorFields: [],
      };
    },
    cleanBrandList(state, { payload }) {
      return {
        ...state,
        brandList: [],
        currentPageBrandList: [],
      };
    }
  },
  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldBrand = yield select((state) => state.brand.selectedBrand);
      let brand = {}
      if ([LanguageConfig.traditionalChinese, LanguageConfig.simplifiedChinese].includes(payload?.language)) {
        const brandTranslations = {
          ...oldBrand.brandTranslations,
          [payload?.language]: {
            ...oldBrand.brandTranslations?.[payload?.language],
            ...payload
          },
        }
        brand = { ...oldBrand, brandTranslations };
      } else {
        brand = { ...oldBrand, ...payload };
      }

      saveToSessionStorage(sessionDataKey.objectKey, brand);
    },

    checkFields: [
      function* ({ payload }, { select, put }) {
        const brand = yield select((state) => state.brand.selectedBrand);
        const result = checkFields(brand);
        yield put(
          createAction('updateState')({
            errorFields: result.errorFields,
          }),
        );
        if (!result.invalid) {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageBrands: [
      function* ({ payload }, { put, all }) {
        const { page, searchKey, rank, moreSearch, isAll } = payload || {};
        console.log("getCurrentPageBrands:", payload)
        let afterCursor = '';
        const pageSize = isAll ? 100 : 20;
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * pageSize - 1);
        }

        const serviceArgs = [
          getBrands,
          afterCursor,
          rank,
          searchKey,
          isAll || false,
          moreSearch,
        ];
        function* onSuccess(data) {
          const brandData = data.brands.edges;
          const pageInfo = data.brands.pageInfo;
          const totalCount = data.brands.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const brandList = brandData.map((item) => parseBrand(item.node));
          yield all([
            put({
              type: 'updateState',
              payload: {
                currentPageBrandList: brandList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'updateBrandList',
              payload: {
                newBrandList: brandList,
                page: page,
                receiptPartner: moreSearch?.receiptPartner,
                oclPartner: moreSearch?.oclPartner,
              },
            }),
          ]);

          if (isAll || moreSearch?.receiptPartner || moreSearch?.oclPartner) {
            if (brandList?.length < pageSize) {
              console.log("@@469, all brands loaded")
              let moreSearchPayload = {};
              if (moreSearch?.receiptPartner) {
                moreSearchPayload = { receiptPartnerListCompleted: true }
              } else if (moreSearch?.oclPartner) {
                moreSearchPayload = { oclPartnerListCompleted: true }
              }

              yield put({
                type: 'updateState',
                payload: moreSearchPayload,
              })

            } else {
              yield put({
                type: 'getCurrentPageBrands',
                payload: {
                  ...payload,
                  page: (page || 1) + 1,
                }
              })
            }
          };
        }
        if (isAll) {
          yield apiWithResponseHandle(serviceArgs, onSuccess);
        } else {
          yield loading(serviceArgs, onSuccess);
        }
      },
    ],
    getBrand: [
      function* ({ payload }, { put }) {
        const { brandPK } = payload;
        const brandID = btoa(`BrandNode:${brandPK}`);
        const serviceArgs = [getBrand, brandID];

        function* onSuccess(data) {
          const brandData = data.brand;
          const brand = parseBrand(brandData);
          yield put(
            createAction('updateState')({
              selectedBrand: brand,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.brand.checkedList,
        }));

        let ids = [];
        checkedList.forEach((item) => {
          ids.push(item.pk);
        });

        const serviceArgs = [
          deleteBrand,
          {
            ids: ids,
          },
        ];
        function* onSuccess() {
          const afterActions = payload.afterAction || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createBrand: [
      function* ({ payload }, { select, put }) {
        const brand = yield select((state) => state.brand.selectedBrand);
        const inputBody = parseBrandInputBody(brand);
        const serviceArgs = [createBrand, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({ type: 'updateState', payload: { selectedBrand: {} } });

          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        const brand = { ...payload.data, name: `Copy of ${payload.data.name}` };
        yield put(
          createAction('updateState')({
            selectedBrand: brand,
          }),
        );
        const afterActions = payload.afterAction || (() => { });
        yield put(
          createAction('createBrand')({
            afterActions,
          }),
        );
      },
    ],
    updateBrand: [
      function* ({ payload }, { select }) {
        const brand = yield select((state) => state.brand.selectedBrand);
        const inputBody = parseBrandInputBody(brand, true);
        const serviceArgs = [updateBrand, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateBrandDisplayPriority: [
      function* ({ payload }, { select }) {
        const inputBody = {
          id: payload?.data?.pk,
          displayPriority: payload?.data?.displayPriority
        };
        const serviceArgs = [updateBrand, inputBody];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
