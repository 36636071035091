import { APIStatus, CheckStatus, SavedStatus } from '../config/CustomEnums';
import {
  getAfcDataRecords,
  getOneAfcDataRecord,
  createAfcDataRecord,
  updateAfcDataRecord,
  deleteAfcDataRecord
} from '../services/AfcDataRecordAPIHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  delay,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';

import { getCampignPeriodDateTime } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';

const getInitialState = () => ({
  oneAdminGroup: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    { displayName: 'Membership ID', fieldName: 'membershipId' },
    { displayName: 'Octopus ID', fieldName: 'octopusId' },
    { displayName: 'Station From', fieldName: 'stationFrom' },
    { displayName: 'Station To', fieldName: 'stationTo' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  afcDataRecords: [],
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  checkedList: [],
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  saved: SavedStatus.init,
  formChanged: false,
  apiStatus: APIStatus.none,
  afcDataRecord: {}
});


const parseAfcDataRecord = (item) => {
  return {
    ...item,
    displayTransactionDatetime: getCampignPeriodDateTime(item.transactionDatetime),
    displayCreationDate: getCampignPeriodDateTime(item.creationDate),
    membershipId: item.customer ? item.customer.membershipId : '',
    firstName: item.customer ? item.customer.firstName : '',
    lastName: item.customer ? item.customer.lastName : ''
  };
};


const parseAfcDataRecords = (data) => {
  return data.map((item) => parseAfcDataRecord(item.node));
};

export default {
  namespace: 'afcDataRecords',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getAfcDataRecords: [
      function* ({ payload }, { call, put }) {
        console.log('@5b', payload)
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getAfcDataRecords,
          pageCursor,
          {...payload}
        ];
        function* onSuccess(data) {
          const pageInfo = data?.afcDataRecords?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor1;
          const totalCount = data?.afcDataRecords?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              afcDataRecords: parseAfcDataRecords(data?.afcDataRecords?.edges),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getOneAfcDataRecord: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });
        const id = convertPKToId('AfcDataRecordNode', payload.id);
        const serviceArgs = [getOneAfcDataRecord, id];
        console.log('@119', payload, id)
        function* onSuccess(data) {
          console.log('getOneAfcDataRecord onSuccess :', data);
          yield all([
            put({
              type: 'updateState',
              payload: { apiStatus: APIStatus.none, afcDataRecord: parseAfcDataRecord(data.afcDataRecord) },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneAfcDataRecord onError :', err);
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneAfcDataRecord arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateAfcDataRecord: [
      function* ({ payload }, { put, select }) {
        // const stationFrom = yield select((state) => payload.stationFrom);
        // const stationTo = yield select((state) => payload.stationTo);
        yield put({
          type: 'updateState',
          payload: {
            apiStatus: APIStatus.loading,
          },
        });

        console.log('@166', payload)
        let params = {...payload}
        const actionFunction = payload.formAction == 'edit' ? updateAfcDataRecord : createAfcDataRecord

        delete params['formAction']
        if(payload.formAction != 'edit'){
          delete params['id']
        }

        // console.log('@181', params)

        const serviceArgs = [actionFunction, params];
        function* onSuccess(data) {
          // removeFromSessionStorage(appVersionSessionKey);
          yield put({
            type: 'updateState',
            payload: {
              apiStatus: APIStatus.success,
              formChanged: false,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    deleteAfcDataRecord: [
      function* ({ payload }, { all, put, select }) {
        const serviceArgs = [deleteAfcDataRecord, payload.pk];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                formChanged: false,
              },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          yield put({
            type: 'updateState',
            payload: {
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};


export const AfcDataRecordErrorHandleFields = {
  required: {
    octopusId: 'Please provide Octopus number',
    stationFrom: 'Please provide station from',
    stationTo: 'Please provide station to',
    transactionDate: 'Please provide a transaction date.'
  },
};