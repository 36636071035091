import React, { useState, useEffect } from 'react';
import BasePrompt from './BasePrompt';
import './DownloadImagePrompt.scss';
import ImageCheckOn from '../../../assets/images/imageCheckOn.png';
import ImageCheckOff from '../../../assets/images/imageCheckOff.png';
import { saveImage } from '../../../utils';

const DownloadImagePrompt = ({ show, images, closeAction }) => {
  const [selectedIndex, setSelectedIndex] = useState([]);
  console.log(selectedIndex);
  useEffect(() => {
    setSelectedIndex([]);
  }, [show]);
  return (
    <BasePrompt
      title="Please select the image to download"
      show={show}
      closeAction={closeAction}
      leftButton={{
        text: 'Cancel',
        action: closeAction,
      }}
      rightButton={{
        text: 'Download',
        action: () => {
          images.forEach((image, index) => {
            if (selectedIndex.includes(index)) {
              saveImage(image);
            }
          });
          if (closeAction) {
            closeAction();
          }
        },
      }}
      otherBody={() => (
        <div className="download-preview-container">
          {images?.map((image, index) => {
            const selected = selectedIndex.includes(index);
            return (
              <div
                className={`download-preview-image-container ${
                  index === 0 ? 'first-img' : ''
                }`}
                onClick={() => {
                  if (selected) {
                    setSelectedIndex(
                      selectedIndex.filter((item) => item !== index),
                    );
                  } else {
                    setSelectedIndex([...selectedIndex, index]);
                  }
                }}
              >
                <img src={image} alt="" className="download-preview-img" />
                <img
                  src={selected ? ImageCheckOn : ImageCheckOff}
                  alt=""
                  className="preview-img-checkbox"
                />
              </div>
            );
          })}
        </div>
      )}
    />
  );
};
export default DownloadImagePrompt;
