import React, { useState, useEffect } from 'react';
import './SaveDiscardToast.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'react-bootstrap';
import { ToastType } from '../../models/NavBarModel';
import { TokenErrors } from '../../config/CustomEnums';
function SaveDiscardToast() {
  const { saveDiscardToastShowing } = useSelector((state) => ({
    saveDiscardToastShowing: state.navBars.saveDiscardToastShowing,
  }));
  const [show, setShow] = useState(
    saveDiscardToastShowing.value &&
      saveDiscardToastShowing.type !== TokenErrors.Expiry && saveDiscardToastShowing.type !== TokenErrors.NoPermission
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setShow(
      saveDiscardToastShowing.value &&
        saveDiscardToastShowing.type !== TokenErrors.Expiry && saveDiscardToastShowing.type !== TokenErrors.NoPermission
    );
    console.log('@@14: saveDiscardToastShowing.value');
  }, [saveDiscardToastShowing.type, saveDiscardToastShowing.value]);

  const onToastHide = () => {
    setShow(false);
    dispatch({
      type: 'navBars/updateState',
      payload: {
        saveDiscardToastShowing: {
          value: false,
          type: saveDiscardToastShowing.type,
        },
      },
    });
    setTimeout(() => {
      dispatch({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: { value: false, type: ToastType.none },
        },
      });
    }, 1200);
  };

  const normalDelayType = [
    ToastType.none,
    ToastType.save,
    ToastType.discard,
    ToastType.couponGenerate,
  ];

  const isNormalDelay = () => {
    if (normalDelayType.includes(saveDiscardToastShowing.type)) {
      return 1200;
    };
    return 8000;
  }

  return (
    <div className="save-success-toast-container">
      <Toast
        className="save-toast-main-content"
        onClose={onToastHide}
        show={show}
        delay={isNormalDelay()}
        autohide
      >
        <Toast.Body className="save-toast-body">
          <label className="save-toast-text">
            {saveDiscardToastShowing.type}
          </label>
          <label onClick={onToastHide} className="save-toast-button">
            Close
          </label>
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default SaveDiscardToast;
