import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import RecordTableCard from '../../../components/customer/RecordTableCard';
import RecordTimelineCard from '../../../components/customer/RecordTimelineCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CustomerOverviewDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const id = params.id;

  const {
    customer,
    activityLogs,
    couponTransactions,
    transactions,
    avaliableCampaigns,
  } = useSelector((state) => ({
    customer: state.customerList.customer,
    activityLogs: state.customerList.activityLogs,
    couponTransactions: state.couponTransactions.couponTransactionList || [],
    transactions: state.transactions.currentPageTransactionList || [],
    avaliableCampaigns: state.campaignList.avaliableCampaignNamesList,
  }));
  const tabs = [
    {
      name: 'Information',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={'Membership Information'}
                data={customer}
                fields={[
                  [{ title: 'Membership ID', field: 'membershipId' }],
                  [{ title: 'Account Type', field: 'displayAccountType' }],
                  [{ title: 'Join Channel', field: 'signUpChannel' }],
                  [
                    { title: 'Current Level', field: 'level' },
                    { title: 'Level renewal date', field: 'levelRenewalDate' },
                    {
                      title: 'Level expired in(days)',
                      field: 'levelExpiredIndays',
                    },
                  ],
                  [{ title: 'Segment', field: 'segment' }],
                  [{ title: 'Customer group', field: 'group' }],
                  [{ title: 'Referral source', field: 'referralSource' }],
                  [{ title: 'Create at', field: 'formateCreated' }],
                  [{ title: 'Customer Lifetime Value', field: 'lifetime' }],
                  [{ title: 'Average Order Value', field: 'averageOrder' }],
                  [{ title: 'Total number of orders', field: 'totalOrders' }],
                  [{ title: 'Days from last order', field: 'lastOrderDays' }],
                  [
                    {
                      title: 'Opt out from direct marketing',
                      field: 'optOutFromDirectMarketing',
                    },
                    { title: 'Legal agreement', field: 'legalAgreement' },
                    {
                      title: 'Data processing agreement',
                      field: 'dataProcessingAgreement',
                    },
                  ],
                  [
                    { title: 'Points balance', field: 'pointsBalance' },
                    {
                      title: 'Total lifetime points used',
                      field: 'totalLifetimePointsUsed',
                    },
                    {
                      title: 'Total lifetime points expired',
                      field: 'totalLifetimePointsExpired',
                    },
                  ],
                  [{ title: 'TPE', field: 'tpe' }],
                  [{ title: 'Level Privilege', field: 'levelPrivilege' }],
                  [{ title: 'Active Status', field: 'status' }],
                  [{ title: 'Available campaign', field: 'availableCampaign' }],
                  [{ title: 'Coupons', field: 'coupons' }],
                ]}
                extraDataFields={{ availableCampaign: avaliableCampaigns }}
              />,
              <MembershipInfoCard
                title={'Personal Information'}
                data={customer}
                fields={[
                  [{ title: 'First name', field: 'firstName' }],
                  [{ title: 'Last name', field: 'lastName' }],
                  [{ title: 'Chinese First name', field: 'chineseFirstName' }],
                  [{ title: 'Chinese Last name', field: 'chineseLastName' }],
                  [{ title: 'Preferred name (optional)', field: 'nickname' }],
                  [{ title: 'Gender (optional)', field: 'displayGender' }],
                  [
                    {
                      title: 'Preferred message language',
                      field: 'preferredMessageLanguageDisplay',
                    },
                  ],
                  [{ title: 'Assign to test customer', field: 'assignToTest' }],
                  [{ title: 'Octopus Id (optional)', field: 'octopusId' }],
                  [{ title: 'Community (optional)', field: 'communityName' }],
                  [{ title: 'Community joined time', field: 'joinCommunityDate' }],
                  [{ title: 'Hall', field: 'communityHall' }],

                ]}
              />,
              <MembershipInfoCard
                title={'Contact Information'}
                data={customer}
                fields={[
                  [{ title: 'Mobile number', field: 'mobileNumber' }],
                  [{ title: 'Email', field: 'email' }],
                  [
                    {
                      title: 'Social media (optional)',
                      field: 'socialMedia',
                    },
                  ],
                  [{ title: 'Address (optional)', field: 'address' }],
                ]}
              />,
            ]}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
    {
      name: 'Record',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <RecordTableCard
                title={'Transaction'}
                data={transactions?.slice(0, 10)}
                // data={[
                //   {
                //     pk: 1,
                //     createdDate: '12 Aug 2020 (Wed)',
                //     eventType: 'Void',
                //     transactionDate: 'Void\r\n12 Aug 2020 (Wed)',
                //     brand: 'Zara',
                //     store: 'AAA',
                //     transactionValue: 100,
                //   },
                // ]}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: 'Event create date', name: 'createdDate' },
                  {
                    displayName: 'Transaction type',
                    name: 'transactionDisplayType',
                  },
                  { displayName: 'Event type', name: 'eventType' },
                  { displayName: 'Brand Name', name: 'brand' },
                  { displayName: 'Store name', name: 'displayStoreName' },
                  {
                    displayName: 'Transaction value($)',
                    name: 'transactionValue',
                  },
                ]}
                viewAllLink={`/transactions?search=${
                  customer.nickname ? customer.nickname : customer.owner
                }`}
              />,
              <RecordTableCard
                title={'Coupons'}
                data={couponTransactions.slice(0, 10)}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: 'Event create date', name: 'createdDate' },
                  { displayName: 'Transaction type', name: 'displayType' },
                  { displayName: 'Event type', name: 'eventType' },
                  { displayName: 'Expired time', name: 'couponExpiryDate' },
                ]}
                viewAllLink={`/coupon_records?search=${
                  customer.nickname ? customer.nickname : customer.owner
                }`}
                tableWidth={694}
              />,
              <RecordTimelineCard
                title={'points and level'}
                data={activityLogs}
                // data={[
                //   {
                //     text: 'Level downgrade : level 4 to 3',
                //     updateTime: '12 Aug 2020(Wed) 12:30:12',
                //   },
                //   {
                //     text: 'Level downgrade : level 4 to 3',
                //     updateTime: '12 Aug 2020(Wed) 12:30:12',
                //   },
                // ]}
              />,
            ]}
            hidePreview={true}
            sectionStyle="detail-record-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'customerList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCustomer}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
  }, [dispatch]);

  useEffect(() => {
    dispatch(createAction('customerList/getOneCustomer')({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      createAction('campaignList/getCampaignList')({
        ssoUid: customer.ssoUid,
        isAll: true,
      }),
    );
    dispatch(
      createAction('customerList/getCustomerActivityLog')({
        ssoUid: customer.ssoUid,
      }),
    );
    dispatch(
      createAction('couponTransactions/getCouponTransactions')({
        searchKey: customer.nickname ? customer.nickname : customer.owner,
        ssoUid: customer.ssoUid,
      }),
    );
    dispatch(
      createAction('transactions/getCurrentPageTransactions')({
        searchKey: customer.nickname ? customer.nickname : customer.owner,
        rank: true,
        ssoUid: customer.ssoUid,
      }),
    );
  }, [dispatch, customer]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: customer.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={customer.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
          //   let search = '';
          //   if (key !== 'Overview') {
          //     // search = queryString.stringify({
          //     //   [URLFilterKeys.type]: CampaignType.allTypes,
          //     //   [URLFilterKeys.page]: 1,
          //     //   [URLFilterKeys.searchKey]: '',
          //     //   [URLFilterKeys.rank]: true,
          //     // });
          //   }
          //   history.push({ pathname: '/customer', hash: key, search: search });
        }}
      />
    </div>
  );
}

export default CustomerOverviewDetail;
