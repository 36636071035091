import {
  getCampaignList,
  getCampaignCategoryList,
} from '../services/CampaignAPIHelper';
import { getTranslationForCampaign, sessionDataKey } from './CreateCampaignModel';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import {
  checkCampaignIsExpired,
  getCampignListDisplayTime,
  getCampignPeriodDateTime,
} from '../utils/TimeFormatUtil';
import { loading, apiWithResponseHandle } from './LoadingUtil';
import { MessageChannel, PublishTagType } from '../config/CustomEnums';
import { CampaignType } from '../config/CustomEnums';

export const FIRST_TIME_ENTER_CAMPAIGN = 'FIRST_TIME_ENTER_CAMPAIGN';

const SHOW_RESUME_POP = 'SHOW_RESUME_POP';

const getCampaignMessageChannel = (channels) => {
  if (!channels || channels.length === 0) {
    return '-';
  }
  const newChannels = channels.map((channel) => {
    const channelLowercase = channel.toLowerCase();
    if (channelLowercase.includes('email')) {
      return MessageChannel.email;
    }
    if (channelLowercase.includes('push')) {
      return MessageChannel.push;
    }
    if (channelLowercase.includes('sms')) {
      return MessageChannel.sms;
    }
    if (channelLowercase.includes('web')) {
      return MessageChannel.web;
    }
    return channel;
  });
  const channelString = newChannels.toString();
  return channelString || '-';
};

const getFormatedStatus = (campaign) => {
  if (!campaign.isPublished) {
    return PublishTagType.unPublished;
  }

  const isExpired = checkCampaignIsExpired(campaign.endDate);
  if (!isExpired) {
    return PublishTagType.published;
  }
  return PublishTagType.expired;
};

const getInitialState = () => ({
  listFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Message Channel', fieldName: 'displayMessageChannels' },
    { displayName: 'Brand', fieldName: 'brand', orderField: 'brand' },
    { displayName: 'Display Priority', fieldName: 'couponDisplayPriority', orderField: 'couponDisplayPriority' },
    {
      displayName: 'Target customers',
      fieldName: 'numberOfCustomersVisible',
      orderField: 'targetCustomers',
    },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
    },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ],
  campaignList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  categories: [],
  segments: [],
  avaliableCampaignNamesList: [],
  allCampaignList: [],
  resume: {
    showResumeButton: false,
    showResumePop: false,
  },
});

export const getTypeDisplay = (type) => {
  if (type === CampaignType.couponsCampaign) {
    return 'Coupon';
  }
  if (type === CampaignType.earningRulesCampaign) {
    return 'Earning Rule';
  }
  return 'General';
};

const getTypeDisplayWithExclusive = (type, isExclusive) => {
  return `${type}${isExclusive ? '(Exclusive)' : ''}`
}

const parseCampaignList = (list) => {
  return list.map((campaign) => {
    const node = campaign.node;
    const typeDisplay = getTypeDisplay(node.type);
    const translations = getTranslationForCampaign(node, true);

    return {
      id: node.id,
      pk: node.pk,
      name: node.name,
      displayEndDate: node.displayEndDate,
      displayStartDate: node.displayStartDate,
      couponDisplayPriority: node.couponDisplayPriority,
      endDate: node.endDate,
      isPublished: node.isPublished,
      startDate: node.startDate,
      status: node.status,
      type: node.type,
      typeDisplay,
      typeDisplayWithExclusive: getTypeDisplayWithExclusive(typeDisplay, node.isExclusive),
      brands: node.brands,
      brand: node.brands?.edges?.map((item) => item.node.name).join(', '),
      numberOfCustomersVisible: node.numberOfCustomersVisible,
      lastModifiedDate: node.lastModifiedDate,
      displayLastModifiedDate: getCampignPeriodDateTime(node.lastModifiedDate),
      publicationDate: node.publicationDate,
      messageChannels: node.messageChannels,
      displayMessageChannels: getCampaignMessageChannel(node.messageChannels),
      displayActivePeriod: getCampignListDisplayTime(
        node.startDate,
        node.endDate,
      ),
      order: node.displayPriority,
      coverPhoto: node.coverPhoto,
      displayVisiblePeriod: getCampignListDisplayTime(
        node.displayStartDate,
        node.displayEndDate,
      ),
      displayStatus: getFormatedStatus(node),
      earningRule: node.earningCampaignTypeEarningRule,
      translations,
    };
  });
};

export default {
  namespace: 'campaignList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    assembleCampaignList(state, { payload }) {
      const campaignList = parseCampaignList(payload.list);
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        campaignList: campaignList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
        avaliableCampaignNamesList: campaignList.map((item) => item.name),
      };
    },

    assembleAllCampaignList(state, { payload }) {
      const campaignList = parseCampaignList(payload.list);

      return {
        ...state,
        allCampaignList:
          payload.page > 1
            ? [...state.allCampaignList, ...campaignList]
            : campaignList,
      };
    },

    assembleSegments(state, { payload }) {
      const segments = payload.list.map((segment) => {
        const node = segment.node;
        return {
          id: node.id,
          pk: node.pk,
          name: node.name,
        };
      });
      return { ...state, segments: segments };
    },

    assembleCategory(state, { payload }) {
      const categories = payload.list.map((category) => {
        const node = category.node;
        return {
          id: node.id,
          pk: node.pk,
          name: node.name,
        };
      });
      return { ...state, categories: categories };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    *campaignResume({ payload }, { select, put }) {
      const campaign = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!campaign?.campaignType) {
        yield put({
          type: 'updateState',
          payload: {
            resume: { showResumeButton: false, showResumePop: false },
          },
        });
        saveToSessionStorage(SHOW_RESUME_POP, null);
        return;
      }
      const showResumePop = getObjectFromSessionStorage(SHOW_RESUME_POP);
      const firstTimeEnterCampaign = getObjectFromSessionStorage(
        FIRST_TIME_ENTER_CAMPAIGN,
      );
      if (!firstTimeEnterCampaign) {
        saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN, true);
      }
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: true,
            showResumePop:
              showResumePop !== false && firstTimeEnterCampaign === false,
          },
        },
      });
    },

    *resumeAction({ payload }, { select, put }) {
      const hideResumePop = payload.hideResumePop;
      saveToSessionStorage(SHOW_RESUME_POP, !hideResumePop);
      const showResumeButton = yield select(
        (state) => state.campaignList.resume.showResumeButton,
      );
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: showResumeButton,
            showResumePop: false,
          },
        },
      });
    },

    getCampaignList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const reverse = payload.reverse;
        const type = payload.type || '';
        const searchKey = payload.searchKey;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const ssoUid = payload.ssoUid || '';

        const serviceArgs = [
          getCampaignList,
          pageCursor,
          type === CampaignType.allTypes ? '' : type,
          reverse,
          searchKey || '',
          ssoUid,
          payload.isSelectorLoad,
          { ...payload },
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          if (payload.isSelectorLoad) {
            yield put({
              type: 'assembleAllCampaignList',
              payload: {
                list: campaigns.edges,
                page,
              },
            });
          } else {
            yield put({
              type: 'assembleCampaignList',
              payload: {
                list: campaigns.edges,
                totalCount: campaigns.totalCount,
                pageInfo: campaigns.pageInfo,
              },
            });
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCamapigncategories: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getCampaignCategoryList];
        function* onSuccess(data) {
          yield put({
            type: 'assembleCategory',
            payload: { list: data.campaignCategories.edges },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        console.log('duplicate campaign', payload);
        yield put({
          type: 'createCampaign/duplicateCampaign',
          payload: {
            campaignId: payload.data.pk,
            afterAction: payload.afterAction,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { put }) {
        yield put({ type: 'createCampaign/deleteCampaigns', payload });
      },
      { type: 'takeLatest' },
    ],
  },
};
