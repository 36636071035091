import React, { Component } from 'react';
import './CouponKeyVisual.scss';
import ImageUploader from '../../base/ImageUploader';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import {
  isShowError,
  firstError,
} from '../../campaign/campaignCreation/CreateCampaignHandleError';
import { CouponErrorFields } from './CouponHandleError';
import i18n from '../../../I18n';
import { LanguageConfig } from '../../../config/CustomEnums';

@connect(({ createCoupon }) => ({
  translations: createCoupon.couponTemplate.translations,
  errorFields: createCoupon.errorFields,
}))
class CouponKeyVisual extends Component {
  state = {
    coverPhoto: '',
    otherPhotos: [],
  };

  componentDidUpdate(prevProps) {
    const oldErrors = JSON.stringify(prevProps.errorFields);
    const currentErrors = JSON.stringify(this.props.errorFields);
    if (currentErrors !== oldErrors) {
      firstError(
        `${CouponErrorFields.coverPhotoEmpty.name}-${this.props.language}`,
        this.props.errorFields,
        this.props.language,
      );
    }
  }

  render() {
    const coverPhotoError = isShowError(
      CouponErrorFields.coverPhotoEmpty.name,
      this.props.errorFields,
      this.props.language,
    );
    const defaultCoverPhoto = [
      this.props.translations[this.props.language]?.coverPhoto,
    ].filter((item) => item);
    return (
      <div>
        <div className="first-section-title">
          {i18n.t('key_visual', { locale: this.props.language })}
        </div>
        <div className="second-section">
          <div className="second-section-title">
            {i18n.t('cover_photo', { locale: this.props.language })}
          </div>
          <div className="second-section_description">
            {i18n.t('cover_photo_suggest', { locale: this.props.language })}
          </div>
          <span
            id={`${CouponErrorFields.coverPhotoEmpty.name}-${this.props.language}`}
          />
          <ImageUploader
            minWidth={900}
            minHieght={600}
            images={defaultCoverPhoto}
            onImageStateChange={(newState) => {
              this.props.dispatch({
                type: 'createCoupon/setFieldToSession',
                payload: {
                  coverPhoto: newState?.[0],
                  language: this.props.language,
                },
              });

              this.setState({ coverPhoto: newState?.[0] });
              if (
                this.props.language === LanguageConfig.english &&
                this.props.errorFields.includes(
                  CouponErrorFields.coverPhotoEmpty.name,
                )
              ) {
                let currentErrorFields = this.props.errorFields;
                const index = this.props.errorFields.indexOf(
                  CouponErrorFields.coverPhotoEmpty.name,
                );
                currentErrorFields.splice(index, 1);
                this.props.dispatch(
                  createAction('createCoupon/updateState')({
                    errorFields: currentErrorFields,
                  }),
                );
              }
              this.props.dispatch(
                createAction('createCoupon/updateTranslation')({
                  language: this.props.language,
                  changedStates: { coverPhoto: newState?.[0] },
                }),
              );
            }}
            aspect={3 / 2}
            maxImageNum={1}
            language={this.props.language}
            photoSectionTitle={'coupon_cover_photo'}
            uploadImageClicked={() => this.props.inputChange('coverPhoto')}
            errorMessage={
              coverPhotoError ? CouponErrorFields.coverPhotoEmpty.message : ''
            }
          />
        </div>
        <div className="second-section">
          <div className="second-section-title">
            {i18n.t('other_photos', { locale: this.props.language })}
          </div>
          <div className="second-section_description">
            {i18n.t('cover_photo_suggest', { locale: this.props.language })}
          </div>
          <ImageUploader
            minWidth={900}
            minHieght={600}
            images={
              this.props.translations[this.props.language]?.otherPhoto || []
            }
            onImageStateChange={(newState) => {
              this.props.dispatch({
                type: 'createCoupon/setFieldToSession',
                payload: {
                  otherPhoto: newState,
                  language: this.props.language,
                },
              });
              this.setState({ otherPhotos: newState });
              this.props.dispatch(
                createAction('createCoupon/updateTranslation')({
                  language: this.props.language,
                  changedStates: { otherPhoto: newState },
                }),
              );
            }}
            aspect={3 / 2}
            maxImageNum={4}
            language={this.props.language}
            photoSectionTitle={'coupon_other_photo'}
            uploadImageClicked={() => this.props.inputChange('otherPhotos')}
            errorMessage=""
          />
        </div>
      </div>
    );
  }
}

export default CouponKeyVisual;
