import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import ProfileSection from './ProfileSection';
import ContentSection from './ContentSection';
import { SaveAndBackButtons } from '../base/BottomStepComponent';
import Loading from '../base/Loading';
import { PermissionCodes } from '../../config/PermissionCodes';
import { APIStatus, PublishTagType } from '../../config/CustomEnums';
import NextStepPrompt from '../base/prompt/NextStepPrompt';

const CreateRewardCodeStepTwo = ({
  rewardCodeDetail,
  createRewardCodeAPIStatus,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const isPublished = rewardCodeDetail.status === PublishTagType.published;
  const [showPrompt, setShowPrompt] = useState(false);

  const sections = [
    <ProfileSection />,
    <ContentSection />
  ];

  useEffect(() => {
    if (createRewardCodeAPIStatus === APIStatus.success && !rewardCodeDetail?.pk) {
      setShowPrompt(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRewardCodeAPIStatus]);

  const backToList = () => {
    if (rewardCodeDetail?.pk) {
      history.push('/reward_code')
    }
  };

  return (
    <>
      {createRewardCodeAPIStatus !== APIStatus.calling ? (
        <ContentSections sections={sections} hidePreview={true} />
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <SaveAndBackButtons
        saveTempText={id ? 'Update' : "Save"}
        saveText={`${id ? 'Update' : 'Save'} and ${isPublished ? 'un' : ''}publish`}
        backAction={() =>
          dispatch({
            type: 'rewardCode/stepChange',
            payload: {
              step: 1,
              isBack: true,
            },
          })
        }
        saveTempAction={() => {
          dispatch({
            type: 'rewardCode/createOrUpdateRewardCode',
            payload: {
              id: rewardCodeDetail?.pk,
              hasPublishOrUnpublishAction: false,
              afterAction: backToList,
            }
          });
        }}
        saveAction={() => {
          dispatch({
            type: 'rewardCode/createOrUpdateRewardCode',
            payload: {
              id: rewardCodeDetail?.pk,
              hasPublishOrUnpublishAction: true,
              afterAction: backToList,
            }
          });
        }}
        saveRequires={PermissionCodes.changeRewardCode}
      />

      <NextStepPrompt
        show={showPrompt}
        title="Successfully Saved!"
        description="Registration code successfully created."
        steps={null}
        buttons={[
          {
            text: 'Back to list',
            action: () => {
              history.push('/reward_code')
            },
          },
        ]}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
  createRewardCodeAPIStatus: state.rewardCode.createRewardCodeAPIStatus,
});

export default connect(mapStateToProps)(CreateRewardCodeStepTwo);
