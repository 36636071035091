import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { isShowError } from "../../utils";
import CustomInputWithTitle from "../customer/CustomInputWithTitle";
import { CustomTitleWithDropDown } from "../customer/CustomTitleWithDropDown";
import CustomTitleWithSwitch from "../base/CustomTitleWithSwitch";
import ImageUploader from "../base/ImageUploader";
import { WhatToDoNextKeyVisualSections, WhatToDoNextKey } from "../../config/CustomEnums";
import i18n from "../../I18n";
import { IMAGE_TYPES } from "../../models/UploadFilesModel";

const getImageUrl = (data) => {
  if (!data) {
    return "";
  }
  if (data.type !== IMAGE_TYPES.TYPE_URL) {
    return "";
  }
  return data.value;
};

function WhatToDoNextKeyVisualCard({
  language,
}) {
  const {
    translations,
    customImageSmallDevice1,
    customImageSmallDevice2,
    customImageSmallDevice3,
    customImageSmallDevice4,
    customImageSmallDevice5,
    customImageSmallDevice6,
    customImageLargeDevice1,
    customImageLargeDevice2,
    customImageLargeDevice3,
    customImageLargeDevice4,
    customImageLargeDevice5,
    customImageLargeDevice6,
    whatToDoNextKey,
  } = useSelector((state) => ({
    translations: state.whatToDoNextList.whatToDoNext.translations,
    customImageSmallDevice1: state.whatToDoNextList.whatToDoNext.translations[language].customImageSmallDevice1,
    customImageSmallDevice2: state.whatToDoNextList.whatToDoNext.translations[language].customImageSmallDevice2,
    customImageSmallDevice3: state.whatToDoNextList.whatToDoNext.translations[language].customImageSmallDevice3,
    customImageSmallDevice4: state.whatToDoNextList.whatToDoNext.translations[language].customImageSmallDevice4,
    customImageSmallDevice5: state.whatToDoNextList.whatToDoNext.translations[language].customImageSmallDevice5,
    customImageSmallDevice6: state.whatToDoNextList.whatToDoNext.translations[language].customImageSmallDevice6,
    customImageLargeDevice1: state.whatToDoNextList.whatToDoNext.translations[language].customImageLargeDevice1,
    customImageLargeDevice2: state.whatToDoNextList.whatToDoNext.translations[language].customImageLargeDevice2,
    customImageLargeDevice3: state.whatToDoNextList.whatToDoNext.translations[language].customImageLargeDevice3,
    customImageLargeDevice4: state.whatToDoNextList.whatToDoNext.translations[language].customImageLargeDevice4,
    customImageLargeDevice5: state.whatToDoNextList.whatToDoNext.translations[language].customImageLargeDevice5,
    customImageLargeDevice6: state.whatToDoNextList.whatToDoNext.translations[language].customImageLargeDevice6,
    whatToDoNextKey: state.whatToDoNextList.whatToDoNext.key,
  }));

  const dispatch = useDispatch();

  const [activeSection, setActiveSection] = useState();

  const [smallDeviceImageList, setSmallDeviceImageList] = useState([])
  const [largeDeviceImageList, setLargeDeviceImageList] = useState([])
  const isGettingStarted = whatToDoNextKey == WhatToDoNextKey.GETTING_STARTED;
  const maxImageNum = isGettingStarted ? 6 : 1;

  useEffect(() => {
    const images = [
      customImageSmallDevice1,
      customImageSmallDevice2,
      customImageSmallDevice3,
      customImageSmallDevice4,
      customImageSmallDevice5,
      customImageSmallDevice6,
    ].filter(item => item)
    setSmallDeviceImageList(images)
  }, [
    customImageSmallDevice1,
    customImageSmallDevice2,
    customImageSmallDevice3,
    customImageSmallDevice4,
    customImageSmallDevice5,
    customImageSmallDevice6
  ])

  useEffect(() => {
    const images = [
      customImageLargeDevice1,
      customImageLargeDevice2,
      customImageLargeDevice3,
      customImageLargeDevice4,
      customImageLargeDevice5,
      customImageLargeDevice6,
    ].filter(item => item)
    setLargeDeviceImageList(images)
  }, [
    customImageLargeDevice1,
    customImageLargeDevice2,
    customImageLargeDevice3,
    customImageLargeDevice4,
    customImageLargeDevice5,
    customImageLargeDevice6
  ])

  const smallMinWidth = 750;
  const smallMinHeight = isGettingStarted ? 1380 : 1624;
  const largeMinWidth = isGettingStarted ? 860 : 750;
  const largeMinHeight = isGettingStarted ? 1582 : 1624;

  const maxImageSize = 12

  return (
    <>
      <div className="first-section-title">{i18n.t('what_to_do_next_key_visual', { locale: language })}</div>
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t("what_to_do_next_custom_image_small", { locale: language })}*
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t(`what_to_do_next_custom_image_small_remark_${whatToDoNextKey.toLowerCase()}`, { locale: language })}
      </label>
      <ImageUploader
        enableGif={true}
        maxImageSize={maxImageSize}
        minWidth={smallMinWidth}
        minHeight={smallMinHeight}
        images={smallDeviceImageList}
        maxImageNum={maxImageNum}
        onImageStateChange={(newState) => {
          setSmallDeviceImageList(newState)

          const images = []

          newState.forEach(item => images.push(getImageUrl(item)))

          dispatch({
            type: 'whatToDoNextList/updateKeyVisual',
            payload: {
              language: language,
              smallDeviceImageList: images
            },
          });
        }}
        language={language}
        photoSectionTitle={"what_to_do_next_custom_image_small"}
        uploadImageClicked={(inputArea) => setActiveSection(inputArea)}
      />
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t("what_to_do_next_custom_image_large", { locale: language })}*
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t(`what_to_do_next_custom_image_large_remark_${whatToDoNextKey.toLowerCase()}`, { locale: language })}
      </label>
      <ImageUploader
        enableGif={true}
        maxImageSize={maxImageSize}
        minWidth={largeMinWidth}
        minHeight={largeMinHeight}
        images={largeDeviceImageList}
        maxImageNum={maxImageNum}
        onImageStateChange={(newState) => {
          setLargeDeviceImageList(newState)

          const images = []

          newState.forEach(item => images.push(getImageUrl(item)))

          dispatch({
            type: 'whatToDoNextList/updateKeyVisual',
            payload: {
              language: language,
              largeDeviceImageList: images
            },
          });
        }}
        // aspect={3 / 2}
        language={language}
        photoSectionTitle={"what_to_do_next_custom_image_large_device"}
        uploadImageClicked={(inputArea) => setActiveSection(inputArea)}
      />
    </>
  );
}



export default WhatToDoNextKeyVisualCard;

