import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { rewritePermission, checkHasPermission } from '../../utils';

function AuthButton({
  title,
  action,
  requires,
  customClass,
  children,
  hidden,
  ...others
}) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  const hasPermission = () => {
    if (!requires) {
      return true;
    }
    let newRequires = requires;
    if (!Array.isArray(requires)) {
      newRequires = [requires];
    }

    return (
      newRequires.filter((val) => checkHasPermission(user, val)).length > 0
    );
  };

  return (
    <>
      {hasPermission() && !hidden ? (
        children ? (
          <button
            className={`btn-further btn-primary ${
              customClass ? customClass : ''
            }`}
            onClick={action}
            {...others}
          >
            {children}
          </button>
        ) : title ? (
          <Button
            className={`${
              customClass ? customClass : 'btn-further btn-primary'
            }`}
            onClick={action}
            {...others}
          >
            {title}
          </Button>
        ) : null
      ) : null}
    </>
  );
}

export default AuthButton;
