import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import './AfcAccountBindingRecordList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import DeletePrompt from '../../../components/base/DeletePrompt';
import Filter from './Filter';


function AfcAccountBindingRecordList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    tableFields,
    dataList,
    pageInfo,
    totalCount,
    totalPage,
    currentPage,
  } = useSelector((state) => ({
    tableFields: state.afcAccountBindingRecords.listDisplayFields,
    dataList: state.afcAccountBindingRecords.afcAccountBindingRecords,
    pageInfo: state.afcAccountBindingRecords.pageInfo,
    totalCount: state.afcAccountBindingRecords.totalCount,
    totalPage: state.afcAccountBindingRecords.totalPage,
    currentPage: state.afcAccountBindingRecords.currentPage,
  }));

  const [deleteItem, setDeleteItem] = useState(null)
  const [showDeletePrompt, setShowDeletePrompt] = useState(false)

  const fetchData = () => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringPage = searchDict['page'] || 1;
    const page = parseInt(stringPage);

    dispatch(
      createAction('afcAccountBindingRecords/getAfcAccountBindingRecords')({
        ...searchDict,
        // page: currentPage,
        searchKey,
      }),
    );
  };

  useEffect(() => {
    fetchData()
  }, [dispatch, location, queryString]);


  const tableTabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          groupActions={[]}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={tableFields}
              model={'AfcAccountBindingRecord'}
              permissionGroup={PermissionCodes.transaction}
              dataList={dataList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'View details',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
              customClassName="afc-cron-job-record-table"
            />
          }
        />
      ),
    },
  ];

  return (
    <>
    <CustomListComponent
      caution={{
        detail:
          'All AFC account binding records are listed here.',
        title: 'AFC account binding records',
      }}
      buttons={[]}
      breadcrumb={<CustomBreadcrumb />}
      tabs={tableTabs}
      hideTab={true}
    />

      </>
  );
}

export default AfcAccountBindingRecordList;
