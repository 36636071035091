import { useDispatch, useSelector, dispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import ContentSections from '../../../components/base/ContentSections';
import { OnlySaveButton } from '../../../components/base/BottomStepComponent';
import { createAction, getError, useCompare } from '../../../utils';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import { CustomNumberInputWithUnit, CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';

function ProgressiveProfilingEdit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const isPart1 =
    location.pathname?.toLowerCase()?.indexOf('part1_progressive_profiling') >
    -1;

  const [errorFields, setErrorFields] = useState({ fields: [], messages: [] });
  const { lifetimePoints1, lifetimePoints2, apiStatus } = useSelector(
    (state) => ({
      lifetimePoints1: state.progressiveProfiling.part1LifetimePoints,
      lifetimePoints2: state.progressiveProfiling.part2LifetimePoints,
      apiStatus: state.progressiveProfiling.apiStatus,
    }),
  );

  const [part1LifetimePoints, setPart1LifetimePoints] = useState(lifetimePoints1);
  const [part2LifetimePoints, setPart2LifetimePoints] = useState(lifetimePoints2);

  const defaultValue = isPart1 ? part1LifetimePoints : part2LifetimePoints;
  const setValue = (value) => {
    isPart1 ? setPart1LifetimePoints(value) : setPart2LifetimePoints(value);
  };

  useEffect(() => {
    if (lifetimePoints1 !== part1LifetimePoints) {
      setPart1LifetimePoints(lifetimePoints1);
    }
    if (lifetimePoints2 !== part2LifetimePoints) {
      setPart2LifetimePoints(lifetimePoints2);
    }
  }, [lifetimePoints1, lifetimePoints2]);

  useEffect(() => {
    if (apiStatus === APIStatus.success) {
      history.push(location.pathname);
    }
  }, [apiStatus]);

  useEffect(() => {
    dispatch(
      createAction(
        'progressiveProfiling/getProgressiveProfilingLifetimePoints',
      )({}),
    );
    return () => {
      dispatch(createAction('progressiveProfiling/clearData')({}));
    };
  }, [dispatch]);

  const sections = apiStatus !== APIStatus.calling ? [
    <div>
      <div>
        <label className="create-section-title">Activate Progressive Profiling</label>
        <label className="help-message">Progressive Profiling will be triggered when a user has reached the following lifetime CW Points:</label>
      </div>
      <CustomTitleLabel title="Reached Lifetime CW Points" />
      <CustomNumberInputWithUnit
        unit="CW Points"
        setValue={setValue}
        defaultValue={defaultValue}
      />
      </div>,
  ] : [<Loading />];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />

      <OnlySaveButton
        saveText={'Update'}
        saveAction={() => {
          dispatch(
            createAction(
              'progressiveProfiling/updateProgressiveProfilingLifetimePoints',
            )({
              part1LifetimePoints,
              part2LifetimePoints,
            }),
          );
        }}
      />
    </>,
  ];

  return (
    <ScrollableComponent
      content={stepSet}
      currentStep={0}
      breadcrumb={
        <CustomBreadcrumb
          name={`Part ${isPart1 ? 1 : 2} Progressive Profiling`}
        />
      }
      caution={{
        detail: '',
        title: `Part ${isPart1 ? 1 : 2} Progressive Profiling`,
        model: 'progressiveProfiling',
      }}
    />
  );
}

export default ProgressiveProfilingEdit;
