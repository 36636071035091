import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getAfcAccountBindingRecords = (afterCursor, rank, search, moreSearch) => {
  // let order = rank ? '-pk' : 'pk';
  // let order = '-pk';
  // if (moreSearch?.sort) {
  //  order = moreSearch.sort;
  // }

  let searchString = `, orderBy: "${moreSearch.rank ? '-pk' : 'pk'}"`;
 
  if (moreSearch.searchKey) {
    searchString += `, searchKey: "${moreSearch.searchKey}"`;
  }

  if (moreSearch.status) {
    searchString += `, statusIn: "${moreSearch.status}"`;
  }

  if (moreSearch.date) {
    searchString += `, creationDateEqual: "${moreSearch.date}"`;
  }

  // const searchKey = search || '';

  const query = `
    {
        afcAccountBindingRecords(first: 20, after: "${afterCursor}",  ${searchString}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id

            externalAccountType
            externalAccountNumber
            status
            creationDate

            customer {
              membershipId
              firstName
              lastName
            }
          }
        }
      }
    }
    `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

const AFC_DATA_RECORD_NODE = `{
    id
    pk

    externalAccountType
    externalAccountNumber
    status
    creationDate

    customer {
      membershipId
      firstName
      lastName
    }
}`;

export const getOneAfcAccountBindingRecord = (id) => {
    const query = `{
      afcAccountBindingRecord(id: "${id}") ${AFC_DATA_RECORD_NODE}
    }`;
    const variables = {};
    return BaseHelper.callGraphQLAPI({
      query,
      variables,
    });
};