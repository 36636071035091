import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { createAction } from '../../../utils';
import { useLocation, useHistory } from 'react-router-dom';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';

export default function FeedbackList() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [sortDisabled, setSortDisabled] = useState(true);
  const {
    pagedFeedbackRecordList,
    totalCount,
    totalPage,
    pageInfo,
    listDisplayFields,
    checkedList,
  } = useSelector((state) => {
    const { storeFeedback } = state;
    return {
      pagedFeedbackRecordList: storeFeedback.pagedFeedbackRecordList,
      totalCount: storeFeedback.totalCount,
      totalPage: storeFeedback.totalPage,
      pageInfo: storeFeedback.pageInfo,
      listDisplayFields: storeFeedback.listDisplayFields,
      checkedList: storeFeedback.checkedList,
    };
  });

  const fetchData = () => {
    const searchDict = queryString.parse(location.search);
    const currentPage = searchDict?.page || 1;
    const searchKey = searchDict?.search;
    const hasSearchKey = searchKey && searchKey.length > 0;
    setSortDisabled(hasSearchKey);
    dispatch(
      createAction('storeFeedback/getFeedbackList')({
        page: currentPage - 1,
        moreSearch: { ...searchDict, searchKey },
      }),
    );
  };

  useEffect(() => {
    fetchData();
    dispatch(createAction('storeCategoryList/getStoreCategoryList')({}));
    return () => {
      dispatch(createAction('storeFeedback/resetFeedbacks')());
    };
  }, [location]);

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteFeedbackRecords,
    },
  ];

  const tabs = [
    {
      name: 'List Of Feedback',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={pagedFeedbackRecordList || []}
              totalPage={totalPage}
              model={'storeFeedback'}
              permissionGroup={PermissionCodes.feedbackList}
              deleteInfo={{
                data: [],
                title: 'store feedback',
                relatedName: '',
                onComfirm: {},
                relatedSections: '',
              }}
              sortDisabled={sortDisabled}
              rankDisabled
              hasOtherActions={false}
              actions={['Detail', 'Delete']}
            />
          }
        />
      ),
    },
  ];
  const buttons = [];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'You can view store feedbacks here',
          title: 'Store Feedbacks',
        }}
        defaultActiveKey={''}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title="Store Feedbacks"
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('storeFeedback/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      />
    </>
  );
}
