import React, { useState, useEffect } from 'react';
import i18n from '../../../I18n.js';
import { useSelector, useDispatch } from 'react-redux';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import { useCompare } from '../../../utils';

function InstructionSection(props) {
  const language = props.language;
  const focusChange = props.focusChange;
  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});

  const dispatch = useDispatch();
  let instructionTitle, instructionContent;
  const defaultValue = props.defaultValue || {};
  const valueChangedAction = props.valueChangedAction || '';

  if (defaultValue) {
    instructionTitle = defaultValue['instructionSectionTitle'] || '';
    instructionContent = defaultValue['instructionSectionContent'] || '';
  }

  const [instructionSectionTitle, setInstructionSectionTitle] = useState(
    instructionTitle,
  );

  const [instructionSectionContent, setInstructionSectionContent] = useState(
    instructionContent,
  );

  const vals = { instructionSectionTitle, instructionSectionContent };
  const hasValChanged = useCompare(vals);
  // console.log('@@138: ', props.defaultValue);
  useEffect(() => {
    if (hasValChanged) {
      // console.log(
      //   '@@36: ',
      //   instructionSectionContent,
      //   vals.instructionSectionContent,
      // );
      dispatch({
        type: valueChangedAction,
        payload: { vals, language },
      });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        instructionSectionTitle,
        instructionSectionContent,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  return (
    <CustomMarkDownArea
      areaTitle={i18n.t('instruction', { locale: language })}
      title={{
        label: i18n.t('instruction_section_title', { locale: language }),
        value: instructionSectionTitle,
        valueChange: (value) => {
          setInstructionSectionTitle(value);
        },
        tips: { ...props.titleTips },
        focus: () => focusChange('instructionTitle'),
      }}
      content={{
        label: i18n.t('instruction_section_content', { locale: language }),
        value: instructionSectionContent,
        valueChange: (value) => {
          setInstructionSectionContent(value);
        },
        tips: { ...props.contentTips },
        focus: () => focusChange('instructionContent'),
      }}
    />
  );
}

export default InstructionSection;
