import React, { useState, useEffect } from 'react';
import BasePrompt from '../../base/prompt/BasePrompt';
import CreateCategoryPrompt from './CreateCategoryPrompt';
import { CampaignType, CategoryType } from '../../../config/CustomEnums';
import './PropertiesGeneralSection.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomRadios from '../../base/CustomRadios';
import CustomAddRemoveMutipleSelector from '../../customer/CustomAddRemoveMutipleSelector';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import { Row } from 'react-bootstrap';
import { GENERAL_TYPE } from '../../../models/CreateCampaignModel';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ContinueCreate } from '../CampaignUrlConfig';

const options = (source) => {
  if (!source) {
    return [];
  }
  return source.map((item) => {
    return {
      name: item.name,
      value: item,
    };
  });
};

const getRadioOptions = () => [
  {
    label: GENERAL_TYPE.customerGroup,
    value: GENERAL_TYPE.customerGroup,
  },
  {
    label: GENERAL_TYPE.segment,
    value: GENERAL_TYPE.segment,
  },
  // {
  //   label: GENERAL_TYPE.level,
  //   value: GENERAL_TYPE.level,
  // },
];

function PropertiesGeneralSection(props) {
  const [showAddCustomerGroup, setShowAddCustomerGroup] = useState(false);
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [showAddSegment, setShowAddSegment] = useState(false);
  const {
    birthdayStartDate,
    birthdayEndDate,
    categories,
    customerGroup,
    campaignType,
    campaignCategories,
    groups,
    isExclusive,
    errorFields,
    segments,
    campaignSegments,
    storeGeneralType,
  } = useSelector((state) => ({
    campaignCategories: state.createCampaign.campaign.categories,
    campaignSegments: state.createCampaign.campaign.segments,
    customerGroup: state.createCampaign.campaign.customerGroup,
    campaignType: state.createCampaign.campaign.campaignType,
    categories: state.campaignCategoryList.categoryList,
    groups: state.customerGroup.groupList,
    isExclusive: state.createCampaign.campaign.isExclusive,
    errorFields: state.createCampaign.errorFields,
    segments: state.segments.segmentList,
    birthdayStartDate:
      state.createCampaign.campaign.linkedEarningRules
        ?.birthdayTypePeriodStartDate,
    birthdayEndDate:
      state.createCampaign.campaign.linkedEarningRules
        ?.birthdayTypePeriodEndDate,
    storeGeneralType: state.createCampaign.campaign.generalType,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const [selectedCategory, setSelectCategories] = useState(campaignCategories);
  const [selectedSegments, setSelectSegments] = useState(campaignSegments);
  const getGroup = () => {
    if (!isExclusive) {
      return [];
    }
    return customerGroup || [];
  };
  const [generalType, setGeneralType] = useState(storeGeneralType);
  const [selectedCustomerGroup, setSelectCustomerGroup] = useState(getGroup());

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        categories: selectedCategory,
        customerGroup: selectedCustomerGroup,
        segments: selectedSegments,
        generalType: generalType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${CampaignErrorHandleField.customerGroup.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  const isError = isShowError(
    CampaignErrorHandleField.customerGroup.name,
    errorFields,
  );

  const segmentConfig = {
    title: 'Target segments',
    data: options(segments),
    selectedList: selectedSegments,
    setValue: (option) => {
      dispatch({
        type: 'createCampaign/setFieldToSession',
        payload: { segments: option },
      });
      setSelectSegments(option);
    },
    sourceReminderText: 'All item(s)',
    amidReminderText: 'Select item(s)',
    addButton: {
      title: 'Add Segments',
      action: () => {
        setShowAddSegment(true);
      },
    },
    requires: PermissionCodes.addSegment,
    error: {
      error: isError,
      message: CampaignErrorHandleField.segment.message,
    },
    selectAllTitle: 'all segments',
    loadMoreAction: 'segments/getSegmentsList',
    defaultFilter:
      birthdayStartDate && birthdayEndDate
        ? {
            dateOfBirthRange: `${birthdayStartDate},${birthdayEndDate}`,
          }
        : {},
  };

  const customerGroupConfig = {
    title: 'Target customer groups',
    data: options(groups),
    selectedList: selectedCustomerGroup,
    setValue: (option) => {
      dispatch({
        type: 'createCampaign/setFieldToSession',
        payload: { customerGroup: option },
      });
      console.log('customerGroup:', option);
      setSelectCustomerGroup(option);
    },
    sourceReminderText: 'All item(s)',
    amidReminderText: 'Select item(s)',
    addButton: {
      title: 'Add Customer Group',
      action: () => {
        setShowAddCustomerGroup(true);
      },
    },
    requires: PermissionCodes.addCustomerGroup,
    error: {
      error: isError,
      message: CampaignErrorHandleField.customerGroup.message,
    },
    selectAllTitle: 'all customer groups',
    loadMoreAction: 'customerGroup/getCurrentPageList',
  };

  const campaignCategoryConfig = {
    title: 'Campaign category (optional)',
    data: options(categories),
    selectedList: selectedCategory,
    setValue: (option) => {
      dispatch({
        type: 'createCampaign/setFieldToSession',
        payload: { categories: option },
      });
      setSelectCategories(option);
    },
    sourceReminderText: 'Select categories',
    addButton: {
      title: 'Add Category',
      action: () => {
        setShowCreateCategory(true);
      },
    },
    requires: PermissionCodes.addCampaignCategory,
    selectAllTitle: 'all campaign categories',
    loadMoreAction: 'campaignCategoryList/getCampaignCategoryList',
    defaultFilter: {
      categoryType:
        campaignType === CampaignType.couponsCampaign
          ? CategoryType.REWARD_CAMPAIGN
          : CategoryType.EARNING_CAMPAIGN,
    },
  };

  const getSelectorWithConfig = (config) => {
    return (
      <CustomAddRemoveMutipleSelector
        title={config.title}
        data={config.data}
        selectedList={config.selectedList}
        setValue={config.setValue}
        sourceReminderText={config.sourceReminderText}
        amidReminderText={config.amidReminderText}
        sourceReminderEnabelSelectAll={true}
        addButton={config.addButton}
        error={isError ? config.error : {}}
        requires={config.requires}
        selectAllTitle={config.selectAllTitle}
        loadMoreAction={config.loadMoreAction}
        defaultFilter={config.defaultFilter}
      />
    );
  };

  return (
    <>
      {campaignType === CampaignType.couponsCampaign ? (
        <label className="create-section-title">General</label>
      ) : null}
      <span id={`${CampaignErrorHandleField.customerGroup.name}`} />
      {isExclusive ? (
        <>
          <div
            className={`${
              campaignType === CampaignType.couponsCampaign
                ? ''
                : 'category-select-title-top'
            }`}
          >
            <label className="create-section-label create-section-label-bottom-space">
              Target customer
            </label>
            <Row className="featured-exclusive-row">
              <CustomRadios
                onChange={(value) => {
                  setGeneralType(value);
                }}
                default={generalType}
                options={getRadioOptions()}
              />
            </Row>
          </div>
          {generalType === GENERAL_TYPE.customerGroup
            ? getSelectorWithConfig(customerGroupConfig)
            : null}
          {generalType === GENERAL_TYPE.segment
            ? getSelectorWithConfig(segmentConfig)
            : null}
        </>
      ) : null}
      {getSelectorWithConfig(campaignCategoryConfig)}

      <BasePrompt
        show={showAddCustomerGroup}
        closeAction={() => setShowAddCustomerGroup(false)}
        rightButton={{
          text: 'create customer group',
          action: () =>
            history.push({
              pathname: '/customer_group/create',
              state: { from: history.location, ...ContinueCreate },
            }),
        }}
        title={'Go to create customer group?'}
        description={'Go to create customer group'}
      />

      <BasePrompt
        show={showAddSegment}
        closeAction={() => setShowAddSegment(false)}
        rightButton={{
          text: 'create segments',
          action: () =>
            history.push({
              pathname: '/segments/create',
              state: { from: history.location, ...ContinueCreate },
            }),
        }}
        title={'Go to create segment?'}
        description={'Go to create segment'}
      />
      <CreateCategoryPrompt
        show={showCreateCategory}
        onClose={() => setShowCreateCategory(false)}
      />
    </>
  );
}

export default PropertiesGeneralSection;
