import React from 'react';
import { Image, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './ContentSection.scss';
import {
  LanguageConfig,
  LanguageTabTransLation,
} from '../../../config/CustomEnums';
const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

const detailPhotos = [
  'detailPhotoOne',
  'detailPhotoTwo',
  'detailPhotoThree',
  'detailPhotoFour',
];

function ContentSection(props) {
  const { translations } = useSelector((state) => ({
    translations: state.createCampaign.campaign.translations,
  }));
  const showdown = require('showdown');
  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value) : '-',
        }}
      ></td>
    );
  };

  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const sectionValues = (valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) =>
          fieldValueText(translations[language][valueKey], index),
        )}
      </>
    );
  };

  const coverImage = () => {
    return (
      <>
        {fieldsLanguages.map((language, index) => (
          <td key={`coverImage-${language}-${index}`}>
            {translations[language].coverPhoto ? (
              <Image
                className="content-section-rectangle-image"
                src={translations[language].coverPhoto}
              />
            ) : (
              '-'
            )}
          </td>
        ))}
      </>
    );
  };

  const squareCoverImage = () => {
    return (
      <>
        {fieldsLanguages.map((language, index) => (
          <td key={`squareCoverImage-${language}-${index}`}>
            {translations[language].squareCoverPhoto ? (
              <Image
                className="content-section-square-image"
                src={translations[language].squareCoverPhoto}
              />
            ) : (
              '-'
            )}
          </td>
        ))}
      </>
    );
  };

  const getDetailPhotosWithOneLanguage = (language) => {
    const mapResult = detailPhotos.map((detailPhoto) =>
      translations[language][detailPhoto] ? (
        <Image
          key={`detail-photo-${language}-${translations[language][detailPhoto]}`}
          className="content-section-rectangle-image content-section-detail-photo"
          src={translations[language][detailPhoto]}
        />
      ) : null,
    );
    const deleteNullItem = mapResult.filter((result) => result);
    return deleteNullItem.length === 0 ? '-' : deleteNullItem;
  };

  const images = () => {
    return (
      <>
        {fieldsLanguages.map((language, index) => (
          <td key={`fieldsLanguages-${language}-${index}`}>
            <div className="content-section-detail-photo-container">
              {getDetailPhotosWithOneLanguage(language)}
            </div>
          </td>
        ))}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">CONTENT</label>
      <PerfectScrollbar>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
              {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText('Title')}
              {sectionValues('generalName')}
            </tr>
            <tr>
              {fieldNameText('Short description')}
              {sectionValues('shortDescription')}
            </tr>
            <tr>
              {fieldNameText('Cover Image')}
              {coverImage()}
            </tr>

            <tr>
              {fieldNameText('Square Cover Image')}
              {squareCoverImage()}
            </tr>

            <tr>
              {fieldNameText('Image')}
              {images()}
            </tr>

            <tr>
              {fieldNameText('Instruction section title')}
              {sectionValues('instructionSectionTitle')}
            </tr>

            <tr>
              {fieldNameText('Instruction section content')}
              {sectionValues('instructionSectionContent')}
            </tr>

            <tr>
              {fieldNameText('Detail section title')}
              {sectionValues('detailSectionTitle')}
            </tr>

            <tr>
              {fieldNameText('Detail section content')}
              {sectionValues('detailSectionContent')}
            </tr>
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}

export default ContentSection;
