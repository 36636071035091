import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { sessionDataKey } from '../../../models/BarcodeModel';
import StepOne from '../../../components/barcode/StepOne';
import StepTwo from '../../../components/barcode/StepTwo';
import StepThree from '../../../components/barcode/StepThree';
import Loading from '../../../components/base/Loading'
import { APIStatus } from '../../../config/CustomEnums';

function CreateBarcode({
  stepConfig,
  currentStep,
  status
}) {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'barcodes/getObj',
        payload: {
          id: params.id,
        }
      });
    } else {
      dispatch({ type: 'barcodes/loadObjectFromCookie' });
    }

    return () => {
      dispatch({ type: 'barcodes/clearState' });
    };
  }, []);

  const stepContent = status === APIStatus.calling ? [<Loading customClass={'common-full-height'}/>] : [
    <StepOne />,
    <StepTwo />,
    <StepThree />,
  ];

  return (
    <ScrollableComponent
      tempSave={(save) => {
        dispatch({
          type: 'mission/saveOrRemoveMissionFromCookie',
          payload: save,
        });
      }}
      sessionDataKey={sessionDataKey}
      content={stepContent}
      currentStep={currentStep}
      stepsConfig={stepConfig}
      breadcrumb={
        <CustomBreadcrumb
          name={
            params.id
              ? `Edit Barcode`
              : 'Create New Barcode'
          }
        />
      }
      caution={{
        detail: '',
        title: params.id
          ? `Edit Barcode`
          : 'Create New Barcode'
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  stepConfig: state.barcodes.stepConfig,
  currentStep: state.barcodes.currentStep,
  status: state.barcodes.status
});

export default connect(mapStateToProps)(CreateBarcode);
