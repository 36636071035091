import React from 'react';
import './ContentSections.scss';
import { Image, Row, Col } from 'react-bootstrap';
import LanguageTabs from './LanguageTab';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { useMainContentWidth, getScrollbarWidth } from '../../utils/ScreenUtil';
import { detectIsSafari } from '../../utils';
function ContentSections(props) {
  const mainContentWidth = useMainContentWidth();
  const preViewWidth = mainContentWidth - 80 - 245 - getScrollbarWidth();
  const sections = props.sections;
  const inputWithImageConfig = props.inputWithImageConfig;
  const activeSection = props.activeSection;
  const defaultImage = props.defaultImage;
  const languageTabContent = props.languageTabContent;
  const hidePreview = props.hidePreview || false;
  const language = props.language || LanguageConfig.english;
  const fieldsError = props.fieldsError;
  const errorTab = props.errorTab || null;
  const sectionStyle = props.sectionStyle || '';
  console.log("enContainer:", languageTabContent)
  const getFormatedSections = (unFormatedSections, sectionWithLanguageTab) => {
    console.log("unFormatedSections:", unFormatedSections)
    return unFormatedSections?.map((section, index) =>
      section ? (
        <div
          key={`${index}-create-step-section`}
          className={`create-step-section-item ${sectionWithLanguageTab && index === 0
            ? 'create-step-section-item-first-section'
            : ''
            } ${sectionStyle}`}
        >
          {section}
        </div>
      ) : null,
    );
  }

  const sectionContent = languageTabContent ? (
    <div className="create-section-container">
      <LanguageTabs
        fieldsError={fieldsError}
        errorTab={errorTab}
        enContainer={getFormatedSections(languageTabContent.enContainer, true)}
        hantContainer={getFormatedSections(
          languageTabContent.hantContainer,
          true,
        )}
        hansContainer={getFormatedSections(
          languageTabContent.hansContainer,
          true,
        )}
      />
    </div>
  ) : (
    getFormatedSections(sections)
  );

  const sectionWithPreview = () => {
    return (
      <>
        <Col className="create-section-container">{sectionContent}</Col>
        <Col
          className="create-preview-col-section"
          style={{ marginLeft: preViewWidth <= 200 ? 15 : 30 }}
        >
          <div
            className={`create-preview-section ${detectIsSafari() ? 'create-preview-section-safari' : ''
              }`}
          >
            <label className="create-preview-title create-section-title">
              {i18n.t('sample_layout', { locale: language })}
            </label>
            <Image
              src={
                inputWithImageConfig[activeSection]
                  ? inputWithImageConfig[activeSection]
                  : defaultImage
              }
              className="create-preview-img"
            />
          </div>
        </Col>
      </>
    );
  };

  return (
    <Row className="create-section-and-preview-container">
      {hidePreview ? (
        <div className="content-no-preview">{sectionContent}</div>
      ) : (
        sectionWithPreview()
      )}
    </Row>
  );
}

export default ContentSections;
