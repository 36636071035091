import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);

  const searchCreateDate = parsedSearch['transaction_date'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [transactionDate, setTransactionDate] = useState(searchCreateDate);
  const [status, setStatus] = useState(searchStatus)

  const content = [

    {
      title: 'Transaction date',
      value: transactionDate,
      setValue: setTransactionDate,
      componment: FilterTypeEnum.date,
    },
    {
      title: 'Status',
      data: [
        { name: 'Success', pk: 'SUCCESS' },
        { name: 'Fail', pk: 'FAIL' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoice,
    },
    
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
