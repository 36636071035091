import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import { SaveAndBackButtons } from '../base/BottomStepComponent';
import { PermissionCodes } from '../../config/PermissionCodes';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { BarcodeProductTypeList, BarcodeValidateStatus, BarcodeVolumeConversion, LanguageConfig, ImageDomain } from '../../config/CustomEnums';
import { productTypes } from '../../models/BarcodeModel';
import PreviewImage from '../base/prompt/PreviewImage';
import CustomSwitchButton from '../base/CustomSwitchButton';
import ImageUploader from '../base/ImageUploader';
import CustomInputWithTitle from '../customer/CustomInputWithTitle';
import FilterableDropdown from '../base/FilterableDropdown';
import EditNewBarcodeStepOnePrompt from './EditNewBarcodeStepOnePrompt';
import './EditNewBarcodeStepOne.scss';
import { firstError, isShowError } from '../../utils';
import { NewBarcodeError } from '../../models/BarcodeValidateModel';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomRadios from '../base/CustomRadios';

const promptEvent = {
  approve: "approve",
  reject: "reject",
  rejectWithMessage: "reject with message",
}

const barcodeVolumeConversionList = Object.values(BarcodeVolumeConversion).map(item => ({
  label: item.name,
  value: item.value,
}));

const brandDefaultFilter = {
  moreSearch: {
    showInBarcode: true,
  },
}

const CorrectSelector = ({
  correct,
  setCorrect,
  hide,
}) => {
  if (hide) {
    return null;
  };
  return (
    <>
      <CustomTitleLabel title="Incorrect?" className='barcode-incorrect-selector-title' />
      <CustomSwitchButton
        disabled={false}
        defaultChecked={!correct}
        onChange={(value) => {
          setCorrect(!value);
        }}
      />
    </>
  )
};

const TitleWithLabel = ({ title, mapped }) => {
  const labelClass = `${mapped ? "barcode-title-label-mapped" : "barcode-title-label-not-mapped"} barcode-title-label`
  return <div>
    <CustomTitleLabel title={title} />
    <label className={labelClass}>
      {mapped ? 'Mapped with GS1HK' : "Not mapped with GS1HK"}
    </label>
  </div>
}

const radioOptions = productTypes.map(item => {
  return {
    label: item.name, value: item.pk, iconName: item.iconName
  }
})

const ErrorField = ({ type, errorFields }) => {
  return (
    <ErrorFieldMessage
      error={isShowError(
        NewBarcodeError?.[type]?.name,
        errorFields,
        LanguageConfig.english,
      )}
      id={`${NewBarcodeError?.[type]?.name}-first`}
      message={NewBarcodeError?.[type]?.message}
    />
  )
}


const EditNewBarcodeStepOneV2 = ({
  detail,
  existedBarcodeList,
  brandList,
  errorFields,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [barcode, setBarcode] = useState(detail.linkedBarcode);
  const disabledChange = detail.status != BarcodeValidateStatus.WAITING_FOR_APPROVAL.name;
  const disabledIncorrect = disabledChange || barcode?.id;
  const [image, setImage] = useState(detail.productImage);
  const [enlargeImageUrl, setEnlargeImageUrl] = useState(null);
  const [productTypeCorrect, setProductTypeCorrect] = useState(false);
  const [productType, setProductType] = useState(detail.productType);
  const [productNameEnCorrect, setProductNameEnCorrect] = useState(detail.productNameMapped);
  const [productNameEn, setProductNameEn] = useState(detail.productName);
  const [productNameZhCorrect, setProductNameZhCorrect] = useState(detail.translations?.[LanguageConfig.traditionalChinese]?.productNameMapped);
  const [productNameZh, setProductNameZh] = useState(detail.translations?.[LanguageConfig.traditionalChinese]?.productName);
  const [productNameZhMapped] = useState(detail.translations?.[LanguageConfig.traditionalChinese]?.productNameMapped);
  const [productNameMapped] = useState(detail.productNameMapped);
  const [brandMapped] = useState(detail.brandMapped);
  const [volumeMapped] = useState(detail.volumeMapped);
  const [recyclableImageType, setRecyclableImageType] = useState(radioOptions.find(item => item.value === detail.productType?.id)?.value)
  const [linkedBrandCorrect, setLinkedBrandCorrect] = useState(detail.brandMapped);
  const [linkedBrand, setLinkedBrand] = useState(detail.linkedBrand);
  const [volumeCorrect, setVolumeCorrect] = useState(detail.volumeMapped);
  const [volumeQuantity, setVolumeQuantity] = useState(detail.volumeQuantity);
  const [volumeConversion, setVolumeConversion] = useState(detail.volumeConversion);
  console.log("recyclableImageType:", recyclableImageType)

  const [event, setEvent] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [rejectReason, setRejectReason] = useState({ value: null, label: null });

  useEffect(() => {
    dispatch({ type: 'barcodeValidate/getExistedBarcodeList' });
    dispatch({ type: 'brand/getCurrentPageBrands', payload: brandDefaultFilter });
  }, [])

  useEffect(() => {
    [
      NewBarcodeError.productType.name,
      `${NewBarcodeError.productNameEn.name}-first`,
      `${NewBarcodeError.productNameZh.name}-first`,
      `${NewBarcodeError.linkedBrand.name}-first`,
      NewBarcodeError.volumeQuantity.name,
      NewBarcodeError.volumeConversion.name,
    ].forEach(item => {
      firstError(
        item,
        errorFields,
        LanguageConfig.english,
      )
    });
  }, [errorFields]);

  const approveOrRejectAfterAction = () => {
    if (detail?.nextNewBarcodePk) {
      dispatch({ type: 'barcodeValidate/clearState' });
      history.push(`/barcode_validate/${detail?.nextNewBarcodePk}/`);
    } else {
      history.push('/barcode_validate');
    };
  };

  const approveNewBarcode = () => {
    const iconName = radioOptions.find(item => item.value === recyclableImageType)?.iconName
    dispatch({
      type: 'barcodeValidate/checkNewBarcode',
      payload: {
        detail: {
          id: detail.id,
          barcode: barcode?.id,
          productImage: image || `${ImageDomain}${iconName}`,
          productType: productTypeCorrect ? detail.productType?.id : productType?.id,
          productNameEn: productNameEnCorrect ? detail.productName : productNameEn,
          productNameZh: productNameZhCorrect ? detail.translations?.[LanguageConfig.traditionalChinese]?.productName : productNameZh,
          linkedBrand: linkedBrandCorrect ? detail.linkedBrand?.pk : linkedBrand?.pk,
          volumeQuantity: volumeCorrect ? detail.volumeQuantity : volumeQuantity,
          volumeConversion: volumeCorrect ? detail.volumeConversion?.value : volumeConversion.value,
        },
        afterAction: approveOrRejectAfterAction,
      }
    })
  }

  const rejectNewBarcode = () => {
    dispatch({
      type: 'barcodeValidate/rejectNewBarcode',
      payload: {
        id: detail.id,
        rejectReason: rejectReason?.value?.id,
        afterAction: approveOrRejectAfterAction,
      }
    })
  }

  const promptOnConfirm = () => {
    console.log("@@130", event)
    if (event == promptEvent.approve) {
      approveNewBarcode();
    } else {
      rejectNewBarcode()
    };
  }

  const prodImageSection = (
    <>
      <CustomTitleLabel title="Product Image (Optional)" />
      <div className="tips-message">
        Suggested Image Size: 600px * 600px (Less than 2MB, support JPG, PNG and GIF only)
      </div>
      <ImageUploader
        enableGif={true}
        minWidth={600}
        minHeight={600}
        images={image ? [image] : []}
        onImageStateChange={(newState) => {
          setImage(newState?.[0])
        }}
        aspect={1 / 1}
        maxImageNum={1}
        language={LanguageConfig.english}
        uploadImageClicked={() => { }}
        errorMessage={`${isShowError(
          NewBarcodeError.productImage.name,
          errorFields,
          LanguageConfig.english,
        )
          ? NewBarcodeError.productImage.message
          : ''
          }`}
      />
      <div>
        <CustomTitleLabel title="Recyclable placeholder icon" />
        <div style={{ display: 'flex' }}>
          <CustomRadios
            onChange={setRecyclableImageType}
            default={recyclableImageType}
            options={radioOptions}
          />
        </div>
      </div>
      <div className='barcode-validate-separate-line' />
    </>)

  const recyclableTypeSection = (<>
    <CustomTitleLabel title="Recyclable Type" />
    <label className="second-section-content">{detail.productTypeName}</label>
    <CorrectSelector correct={productTypeCorrect} setCorrect={setProductTypeCorrect} hide={disabledIncorrect} />
    {
      productTypeCorrect || disabledIncorrect
        ? null
        : <CustomTitleWithDropDown
          isImageField={false}
          showRemoveButton={false}
          needFilter={false}
          title='Recyclable Type'
          titleStyle='barcode-correct-title'
          source={BarcodeProductTypeList}
          defaultValue={{
            label: productType?.name,
            value: productType
          }}
          setValue={(value) => {
            setProductType(value.value);
          }}
          errors={errorFields}
          errorName={NewBarcodeError.productType.name}
          errorMessage={NewBarcodeError.productType.message}
        />
    }
    <div className='barcode-validate-separate-line' />
  </>)

  const prodNameEnSection = (<>
    <TitleWithLabel title="Product name (English)" mapped={productNameMapped} />

    <label className="second-section-content">{detail.productName || '-'}</label>
    <ErrorField
      type={'productNameEn'}
      errorFields={errorFields}
    />
    <CorrectSelector correct={productNameEnCorrect} setCorrect={setProductNameEnCorrect} hide={disabledIncorrect} />
    {
      productNameEnCorrect || disabledIncorrect
        ? null
        : <CustomInputWithTitle
          title='Product name (English)'
          titleStyle='barcode-correct-title'
          defaultValue={productNameEn}
          placeholder="Did not provide. Admin to input this field."
          setValue={(value) => {
            setProductNameEn(value);
          }}
          error={{
            id: NewBarcodeError.productNameEn.name,
            error: isShowError(
              NewBarcodeError.productNameEn.name,
              errorFields,
              LanguageConfig.english,
            ),
            message: NewBarcodeError.productNameEn.message,
          }}
        />
    }
    <div className='barcode-validate-separate-line' />
  </>
  )

  const prodNameTcSection = (<>
    <TitleWithLabel title="Product name (Chinese)" mapped={productNameZhMapped} />
    <label className="second-section-content">{detail.translations?.[LanguageConfig.traditionalChinese]?.productName || '-'}</label>
    <ErrorField
      type={'productNameZh'}
      errorFields={errorFields}
    />
    <CorrectSelector correct={productNameZhCorrect} setCorrect={setProductNameZhCorrect} hide={disabledIncorrect} />
    {
      productNameZhCorrect || disabledIncorrect
        ? null
        : <CustomInputWithTitle
          title='Product name (Chinese)'
          titleStyle='barcode-correct-title'
          placeholder="Did not provide. Admin to input this field."
          defaultValue={productNameZh}
          setValue={setProductNameZh}
          error={{
            id: NewBarcodeError.productNameZh.name,
            error: isShowError(
              NewBarcodeError.productNameZh.name,
              errorFields,
              LanguageConfig.english,
            ),
            message: NewBarcodeError.productNameZh.message,
          }}
        />
    }
    <div className='barcode-validate-separate-line' />
  </>
  )


  const linkedBrandSection = (<>
    <TitleWithLabel title="Link to brand" mapped={brandMapped} />
    <label className="second-section-content">{detail.linkedBrand?.pk ? detail.linkedBrand?.name : 'Others'}</label>
    {
      detail.linkedBrand?.pk
        ? null
        : <label className="second-section-content">{detail.brand}</label>
    }
    <ErrorField
      type={'linkedBrand'}
      errorFields={errorFields}
    />
    <CorrectSelector correct={linkedBrandCorrect} setCorrect={setLinkedBrandCorrect} hide={disabledIncorrect} />
    {
      linkedBrandCorrect || disabledIncorrect
        ? null
        : <CustomTitleWithDropDown
          isImageField={false}
          showRemoveButton={false}
          needFilter={true}
          title='Link to brand'
          titleStyle='barcode-correct-title'
          source={brandList}
          defaultValue={{
            label: linkedBrand?.name,
            value: linkedBrand
          }}
          setValue={(value) => {
            setLinkedBrand(value.value);
          }}
          loadMoreAction={'brand/getCurrentPageBrands'}
          filterAction={'brand/getCurrentPageBrands'}
          defaultFilter={brandDefaultFilter}
          addButtonInfo={{
            title: 'Create Now',
            action: () => {
              history.push({
                pathname: '/brands/create',
                state: {
                  from: history.location,
                  title: `Continue to validate new barcode`,
                  content: `You can continue to validate new barcode.`,
                },
              })
            },
            customClass: 'btn-back-button-common',
          }}
          errors={errorFields}
          errorName={NewBarcodeError.linkedBrand.name}
          errorMessage={NewBarcodeError.linkedBrand.message}
        />
    }
    <div className='barcode-validate-separate-line' />
  </>
  )

  const volumesSection = (<>
    <TitleWithLabel title="Volume" mapped={volumeMapped} />
    <label className="second-section-content">{detail.volumeEntire}</label>
    <CorrectSelector correct={volumeCorrect} setCorrect={setVolumeCorrect} hide={disabledIncorrect} />
    {
      volumeCorrect || disabledIncorrect
        ? null
        : <>
          <CustomTitleLabel title="Volume" className='barcode-correct-title' />
          <div>
            <div className='barcode-validate-volume-quantity'>
              <input
                type={'number'}
                onChange={({ target }) => {
                  setVolumeQuantity(target.value)
                }}
                value={volumeQuantity}
                className={`custom-markdown-area-title  ${isShowError(
                  NewBarcodeError.volumeQuantity.name,
                  errorFields,
                  LanguageConfig.english,
                ) ? 'error-field' : ''
                  }`}
              />
              <ErrorFieldMessage
                error={isShowError(
                  NewBarcodeError.volumeQuantity.name,
                  errorFields,
                  LanguageConfig.english,
                )}
                id={NewBarcodeError.volumeQuantity.name}
                message={NewBarcodeError.volumeQuantity.message}
              />
            </div>
            <div className='barcode-validate-dropdown'>
              <FilterableDropdown
                isImageField={false}
                selectChange={setVolumeConversion}
                isPromptDropdown={true}
                options={barcodeVolumeConversionList}
                filterableItemClassName={''}
                defaultValue={volumeConversion}
                needFilter={false}
                customClass={`${isShowError(
                  NewBarcodeError.volumeConversion.name,
                  errorFields,
                  LanguageConfig.english,
                ) ? 'error-field' : ''}`}
                loadMoreAction={() => { }}
                filterAction={() => { }}
              />
              <ErrorFieldMessage
                error={isShowError(
                  NewBarcodeError.volumeConversion.name,
                  errorFields,
                  LanguageConfig.english,
                )}
                id={NewBarcodeError.volumeConversion.name}
                message={NewBarcodeError.volumeConversion.message}
              />
            </div>
          </div>
        </>
    }
  </>
  )

  const sections = [
    <>
      <div className="first-section-title">GENERAL</div>
      <CustomTitleLabel title="Barcodes" />
      <label className="second-section-content">{detail.code}</label>

      <CustomTitleWithDropDown
        disabled={disabledChange}
        isImageField={false}
        showRemoveButton={true}
        onRemoveButtonClick={() => {
          setBarcode(null);
        }}
        needFilter={true}
        title={'LINK TO EXISTING RELATED PRODUCT'}
        titleTips={'To approve the barcode, you could either select from an existing product in "LINK TO EXISTING RELATED PRODUCT" section or provide new information in "PRODUCT INFORMATION" section.\nIf any existing product is selected, the information filled in "PRODUCT INFORMATION" section will be ignored.'}
        source={existedBarcodeList}
        defaultValue={{
          label: barcode?.name,
          value: barcode
        }}
        setValue={(value) => {
          setBarcode(value.value);
        }}
        loadMoreAction={'barcodeValidate/getExistedBarcodeList'}
        filterAction={'barcodeValidate/getExistedBarcodeList'}
        defaultFilter={{}}
      />

      <div className='barcode-validate-info-container'>
        <CustomTitleLabel title="PRODUCT INFORMATION" />
        {prodImageSection}
        {recyclableTypeSection}
        {prodNameEnSection}
        {prodNameTcSection}
        {linkedBrandSection}
        {volumesSection}
      </div >
      <PreviewImage
        show={!!enlargeImageUrl}
        onClose={() => {
          setEnlargeImageUrl(null);
        }}
        imageUrl={enlargeImageUrl}
        extraClass={{ width: 'auto', maxWidth: '100%', height: 315 * 1.7 }}
      />
    </>
  ];

  return (
    <>
      <ContentSections
        hidePreview={true}
        sections={sections}
      />
      <SaveAndBackButtons
        backAction={() => {
          history.goBack();
        }}
        secondSaveTempText={disabledChange ? "" : "Reject with message"}
        secondSaveTempAction={() => {
          setEvent(promptEvent.rejectWithMessage);
          setShowPrompt(true);
        }}
        saveTempText={disabledChange ? "" : "Reject"}
        saveTempAction={() => {
          setEvent(promptEvent.reject);
          setShowPrompt(true);
        }}
        saveText={disabledChange ? "" : "Approve"}
        saveAction={() => {
          setEvent(promptEvent.approve);
          setShowPrompt(true);
        }}
        saveRequires={PermissionCodes.changeSubmittednewbarcode}
      />
      <EditNewBarcodeStepOnePrompt
        event={event}
        show={showPrompt}
        onCancel={() => {
          setShowPrompt(false)
        }}
        onConfirm={promptOnConfirm}
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  detail: state.barcodeValidate.newBarcodeDetail,
  existedBarcodeList: state.barcodeValidate.existedBarcodeList,
  brandList: state.brand.brandList,
  errorFields: state.barcodeValidate.errorFields,
});

export default connect(mapStateToProps)(EditNewBarcodeStepOneV2);
