export const NavBarNames = {
  customer: 'Customer',
  segments: 'Segment',
  customerGroup: 'Customer group',
  levels: 'Levels',
  records: 'Records',
  campaigns: 'Campaigns',
  campaignCategory: 'Campaign categories',
  mission: 'Mission',
  message: 'Messages',
  earnRules: 'Earning rules',
  coupons: 'Coupons',
  rewards: 'Rewards',
  brands: 'Brands',
  stores: 'Stores',
  storeCategory: 'Store Categories',
  translations: 'Translations',
  transactions: 'Transactions',
  pointRecords: 'Point records',
  couponRecords: 'Coupon records',
  webview: 'Webview content',
  appversion: 'App version',
  guideImage: 'Guide image',
  afcSetting: 'AFC Settings',
  admin: 'Administrators',
  adminGroup: 'Administrators group',
  dashboard: 'Dashboard',
  banners: 'Banners',
  productCategory: 'Product categories',
  featuredCampaign: 'Featured campaigns',
  whatsNewCampaign: 'What\'s New',
  greetingCard: 'Greeting card',
  greetingSection: 'Greeting section',
  featuredProduct: 'Featured products',
  feedbackList: 'Store feedback',
  afcDataRecords: 'AFC data records',
  afcCronJobRecords: 'AFC cron job records',
  afcAccountLogs: 'AFC account logs',
  community: "Community",
  newsFeed: "News Feed",
  splashAd: "Splash Ad",
  rewardCode: "Registration Code",
  part1ProgressiveProfiling: "Part 1 Progressive Profiling",
  part2ProgressiveProfiling: "Part 2 Progressive Profiling",
  barcodeListing: "Barcode Listing",
  validateNewBarcode: "Validate New Barcode",
  whatToDoNext: "What to do next"
};
