import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isShowError, useCompare, readFile, fileUpload } from '../../../utils';
import i18n from '../../../I18n';
import { ErrorHandleFields } from './ErrorHandleFields';
import CustomEditor from '../../../components/base/CustomEditor';

const DetailSection = ({ language, translations, errorFields }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState(
    translations?.[language]?.description || '',
  );

  const isError = (field) => {
    return isShowError(field, errorFields, language);
  };

  const vals = {
    description,
  };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({
        type: 'newsFeedModel/updateTranslations',
        payload: { description, language },
      });
    }
  });

  return (
    <>
      <label className="create-section-title">
        {i18n.t('news_feed_detail', { locale: language })}
      </label>
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('news_feed_content', { locale: language })}
      </label>
      <CustomEditor
        initialValue={description}
        onValueChange={(value) => {
          console.log('@@40', value);
          setDescription(value);
        }}
        error={isError(ErrorHandleFields.description.name)}
        errorMessage={ErrorHandleFields.description.message}
        toolBars={[
          'heading',
          'bold',
          'italic',
          'quote',
          'divider',
          'ul',
          'ol',
          'link',
          'htmlInline',
          'redo',
          'undo',
          'image',
        ]}
        hooks={{
          addImageBlobHook: async (file, applyFunction) => {
            if (file?.size / 1024 / 1024 > 2) {
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: 'Image dimensions could not be bigger than 2MB.',
                  },
                },
              });
              return '';
            }
            const imageData = await readFile(file);
            fileUpload({
              dispatch: dispatch,
              imageData: imageData,
              imageType: file.type,
              afterAction: applyFunction,
            });
          },
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  translations: state.newsFeedModel.oneObject.translations || {},
  errorFields: state.newsFeedModel.errorFields,
});

export default connect(mapStateToProps)(DetailSection);
