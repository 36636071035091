import React, { Component } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CouponTemplateOverview from '../../../components/coupon/couponList/CouponTemplateOverview';
import SingleCouponList from '../../../components/coupon/couponList/SingleCouponList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { couponSetDetailBreads } from '../../../config/CustomBreadConfig';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { createAction, getHashKeyString } from '../../../utils';
import { connect } from 'react-redux';
import GenerateCouponView from '../../../components/coupon/couponCreation/GenerateCouponView';
import {
  CouponURLFragments,
  CouponURLQueryKeys,
} from '../../../components/coupon/CouponUrlConfig';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
const queryString = require('query-string');
@connect(({ couponList, users }) => ({
  ...couponList,
  isSuperuser: users.isSuperuser,
  userPermissions: users.userPermissions,
}))
class CouponSetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
    };
    this.couponSetPK = props.match.params.id;
    this.props.dispatch(
      createAction('couponList/getCouponSet')({
        couponSetPK: props.match.params.id,
        notSaveToSession: true,
      }),
    );
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'couponList/clearData' });
  }

  render() {
    const tabs = [
      {
        name: CouponURLFragments.overview,
        content: <CouponTemplateOverview couponSet={this.props.couponSet} />,
      },
      this.props.isSuperuser ||
      this.props.userPermissions.filter(
        (val) => val.split('_')[1] === PermissionCodes.coupon,
      ).length > 0
        ? {
            name: CouponURLFragments.list,
            content: <SingleCouponList couponSet={this.props.couponSet} />,
          }
        : {},
    ];

    const buttons = [
      <AuthButton
        title="Generate Coupons"
        customClass="btn-back-button-common btn-download"
        action={() => {
          this.setState({ modalShow: true });
          this.props.dispatch(
            createAction('createCoupon/updateCouponTemplate')({
              generateCoupon: true,
              notSaveToSession: true,
            }),
          );
        }}
        requires={PermissionCodes.addCoupon}
      />,
      <AuthButton
        title="Edit"
        action={() => {
          this.props.dispatch(
            createAction('createCoupon/updateCouponTemplateWithSetData')(
              this.props.couponSet,
            ),
          );
          this.props.history.push({
            pathname: `/coupons/${this.couponSetPK}/edit`,
            state: {
              isFromEdit: true,
              couponSetID: this.props.couponSet.id,
            },
          });
        }}
        requires={PermissionCodes.changeCouponTemplate}
      />,
    ];
    const defaultActiveKey =
      getHashKeyString(this.props.location.hash) || CouponURLFragments.overview;
    return (
      <>
        <CustomListComponent
          breadcrumb={
            <CustomBreadcrumb
              breadcrumbs={couponSetDetailBreads(this.props.couponSet.name)}
            />
          }
          defaultActiveKey={defaultActiveKey}
          onTabChange={(key) => {
            let search = '';
            if (key !== CouponURLFragments.overview) {
              search = queryString.stringify({
                [CouponURLQueryKeys.page]: 1,
                [CouponURLQueryKeys.searchKey]: '',
                [CouponURLQueryKeys.rank]: true,
              });
            }
            this.props.history.push({
              pathname: `/coupons/${this.props.match.params.id}`,
              hash: key,
              search: search,
            });
          }}
          buttons={buttons}
          tabs={tabs}
          title={this.props.couponSet?.name}
        />
        <GenerateCouponView
          toSingleCouponList={true}
          couponFormat={this.props.couponSet?.formats}
          couponTemplate={this.props.couponSet?.pk}
        />
      </>
    );
  }
}

export default withRouter(CouponSetDetail);
