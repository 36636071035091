import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomListComponent from '../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import Loading from '../../components/base/Loading';
import EditNewBarcodeStepOneV2 from '../../components/barcodeValidate/EditNewBarcodeStepOneV2';

function EditNewBarcode({
  newBarcodeDetail,
}) {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch({
      type: 'barcodeValidate/getNewBarcode',
      payload: { id: params?.id }
    });

    return () => {
      dispatch({ type: 'barcodeValidate/clearState' });
    };
  }, [params?.id]);

  const tabs = [
    {
      name: '',
      content:
        newBarcodeDetail.id
          ? <EditNewBarcodeStepOneV2 />
          : <Loading />
    }
  ];

  return (
    <CustomListComponent
      caution={{
        detail: '',
        title: 'New barcode details',
      }}
      breadcrumb={<CustomBreadcrumb name="New barcode details" />}
      hideTab={true}
      tabs={tabs}
    />
  );
}

const mapStateToProps = (state) => ({
  newBarcodeDetail: state.barcodeValidate.newBarcodeDetail,
})

export default connect(mapStateToProps)(EditNewBarcode);
