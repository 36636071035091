import { apiWithResponseHandle } from './LoadingUtil';
import { APIStatus } from '../config/CustomEnums';
import { createGreetingCard, deleteGreetings, getGreetingCard, getGreetingCardList, updateGreetingCard } from '../services/GreetingCardAPIHelper';
import { convertCursorToNumber, convertNumberToCursor, convertPKToId, delay, getFileNameFromUrl, getObjectFromSessionStorage, removeFromSessionStorage, saveToSessionStorage } from '../utils';
import { getCampignListDisplayTime } from '../utils/TimeFormatUtil';

export const greetingCardThemeColor = {
  'Forest': { name: 'Forest(#02432C)', value: 'Forest' },
  'DarkRed': { name: 'Dark red(#9B1900)', value: 'DarkRed' },
  'DarkCyan': { name: 'Dark cyan(#076980)', value: 'DarkCyan' },
};

export const greetingCardThemeColorList = Object.values(greetingCardThemeColor);

export const CreateGreetingCardError = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a general name.'
  },
  themeColor: {
    name: 'themeColor',
    message: 'Please select a theme color.',
  },
  greetingPhoto: {
    name: 'greetingPhoto',
    message: 'Please provide a greeting photo',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a greeting card cannot be on or before the start date and time.',
  },
};

export const sessionDataKey = {
  objectKey: 'createGreetingCard',
  stepEndKey: 'createGreetingCardStepEnd',
  origionalData: 'createGreetingCardOriginalData',
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Color', fieldName: 'themeColorDisplay' },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
  ],
  greetingCard: {},
  stepConfig: [],
  greetingCardList: [],
  totalCount: null,
  totalPage: null,
  pageInfo: {},
  checkedList: [],
  errorFields: [],
  loadingStatus: APIStatus.none,
  createStatus: APIStatus.none,
  formChanged: false,
});

const parseGreetingCardDetail = (data = {}) => {
  const themeColor = {
    label: greetingCardThemeColor?.[data.themeColor].name,
    value: greetingCardThemeColor?.[data.themeColor]
  };

  let displayActivePeriod = getCampignListDisplayTime(
    data.startDate,
    data.endDate,
  );
  if (!data.endDate) {
    displayActivePeriod = 'All time active';
  };

  return ({
    id: data.id,
    pk: data.pk,
    name: data.name,
    themeColor,
    themeColorDisplay: greetingCardThemeColor?.[data.themeColor].name,
    greetingPhoto: data.greetingPhoto,
    activeStartDate: data.startDate,
    activeEndDate: data.endDate,
    displayActivePeriod,
  });
};

const parseGreetingCardList = (list) => {
  return list.map(item => parseGreetingCardDetail(item?.node));
};

const checkFields = (data) => {
  const {
    name,
    themeColor,
    greetingPhoto,
    activeStartDate,
    activeEndDate,
  } = data;

  let errorFields = [];
  if (!name) {
    errorFields.push(CreateGreetingCardError.generalName.name);
  };
  if (!themeColor) {
    errorFields.push(CreateGreetingCardError.themeColor.name);
  };
  if (!greetingPhoto) {
    errorFields.push(CreateGreetingCardError.greetingPhoto.name);
  };
  console.log("@@107", data);
  if (activeStartDate?.getTime() >= activeEndDate?.getTime()) {
    errorFields.push(CreateGreetingCardError.activeEndDate.name);
  };

  console.log("@@137", data, errorFields)
  return {
    invalid: errorFields.length > 0,
    errorFields: errorFields,
    data,
  };
};

const getDataForCreateGreetingCard = (data) => {
  console.log("@@121", data)
  let result = {
    name: data?.name,
    themeColor: data?.themeColor?.value?.value,
    greetingPhoto: getFileNameFromUrl(data?.greetingPhoto) || null,
    startDate: data?.activeStartDate,
    endDate: data?.activeEndDate,
  }

  return result;
};

export default {
  namespace: 'greetingCard',
  state: { ...getInitialState() },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
    updateGreetingCardDetail(state, { payload }) {
      const newGreetingCard = {
        ...state.greetingCard,
        ...payload,
      };

      saveToSessionStorage(sessionDataKey.objectKey, newGreetingCard);
      return {
        ...state,
        greetingCard: newGreetingCard,
      };
    },
    assembleGreetingCard(state, { payload }) {
      const greetingCardData = parseGreetingCardDetail(payload.greetingCard);
      saveToSessionStorage(sessionDataKey.origionalData, greetingCardData);
      saveToSessionStorage(sessionDataKey.objectKey, greetingCardData);
      return {
        ...state,
        greetingCard: greetingCardData,
      };
    },
    assembleGreetingCardList(state, { payload }) {
      const greetingCardList = parseGreetingCardList(payload.list);
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        greetingCardList: greetingCardList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },
    loadGreetingCardFromCookie(state, { payload }) {
      const greetingCard = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!greetingCard) {
        return {
          ...state,
          loadingStatus: APIStatus.success,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, greetingCard);
      saveToSessionStorage(sessionDataKey.objectKey, greetingCard);
      return {
        ...state,
        greetingCard: greetingCard,
        loadingStatus: APIStatus.success,
      };
    },
    saveOrRemoveGreetingCardFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
  },
  effects: {
    getList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';

        const serviceArgs = [
          getGreetingCardList,
          pageCursor,
          { ...payload },
        ];

        function* onSuccess(data) {
          console.log("@@55", data)
          const greetingCards = data?.greetings;
          yield put({
            type: 'assembleGreetingCardList',
            payload: {
              list: greetingCards?.edges,
              totalCount: greetingCards?.totalCount,
              pageInfo: greetingCards?.pageInfo,
            },
          })
        };
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getDetail: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { loadingStatus: APIStatus.calling },
        });
        const id = convertPKToId('GreetingNode', payload.id);
        const serviceArgs = [getGreetingCard, id];
        function* onSuccess(data) {
          console.log('@@116, greeting card detail', data);
          yield all([
            put({
              type: 'assembleGreetingCard',
              payload: { greetingCard: data?.greeting },
            }),
            put({
              type: 'updateState',
              payload: { loadingStatus: APIStatus.success },
            }),
          ]);
        }
        function* onError(err) {
          console.log('greeting card onError :', err);
          yield put({
            type: 'updateState',
            payload: { loadingStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('greeting card arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { loadingStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    checkFields: [
      function* ({ payload }, { select, put }) {
        const greetingCard = yield select((state) => state.greetingCard.greetingCard);
        const result = checkFields(greetingCard);
        yield put({
          type: 'updateState',
          payload: {
            errorFields: result.errorFields,
          }
        });
        if (!result.invalid) {
          yield put({
            type: 'createOrUpdateGreetingCard',
            payload: { id: payload?.id }
          });
        }
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateGreetingCard: [
      function* ({ payload }, { call, select, put, all }) {
        const originalGreetingCard = yield select(
          (state) => state.greetingCard.greetingCard,
        );
        const createGreetingCardDate = getDataForCreateGreetingCard(originalGreetingCard);
        if (originalGreetingCard?.pk && payload?.id) {
          createGreetingCardDate.id = originalGreetingCard?.pk;
        };

        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        const serviceArgs = [createGreetingCardDate?.id ? updateGreetingCard : createGreetingCard, createGreetingCardDate];
        function* onSuccess(data) {
          const greetingCard = data?.[createGreetingCardDate?.id ? 'updateGreeting' : 'createGreeting']?.node;
          console.log("@@312", greetingCard)
          yield put({
            type: 'updateState',
            payload: {
              createStatus: !greetingCard ? APIStatus.failed : APIStatus.success,
            },
          });
          yield put({ type: 'saveOrRemoveGreetingCardFromCookie', payload: false });
        };

        function* failed(data) {
          yield put({ type: 'updateState', payload: { createStatus: APIStatus.failed } });
        };

        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { call, select, put }) {
        const deleteItems = yield select(state => state.greetingCard.checkedList);
        const deletePks = deleteItems?.map(item => item.pk);
        const selectedIds = payload?.deletePks || deletePks;
        console.log("@@354", selectedIds)
        const afterAction = payload.afterAction || (() => { });

        const serviceArgs = [deleteGreetings, selectedIds];
        yield apiWithResponseHandle(serviceArgs);

        yield delay(1000);
        afterAction();
      },
      { type: 'takeLatest' },
    ],
  },
};
