import React, { useEffect, useState } from 'react';
import './BrandList.scss';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
function BrandList() {
  const queryString = require('query-string');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const {
    brandList,
    displayFields,
    totalPage,
    totalCount,
    pageInfo,
    checkedList,
  } = useSelector((state) => ({
    brandList: state.brand.currentPageBrandList,
    displayFields: state.brand.listDisplayFields,
    totalPage: state.brand.totalPage,
    totalCount: state.brand.totalCount,
    pageInfo: state.brand.pageInfo,
    checkedList: state.brand.checkedList,
  }));

  const closeDeletePop = () => {
    setShowDeletePrompt(false);
  };

  const confirmDelete = () => {
    setShowDeletePrompt(false);
    dispatch(
      createAction('brand/delete')({
        afterAction: () => {
          history.push({
            pathname: location.pathname,
            search: queryString.stringify({
              ...queryString.parse(location.search),
              page: 1,
            }),
          });
        },
      }),
    );
  };

  useEffect(() => {
    dispatch({ type: 'brand/cleanBrandList' });

    const searchDict = queryString.parse(location.search);
    const stringRank = searchDict['rank'] || 'true';
    const rank = stringRank === 'true';
    dispatch(
      createAction('brand/getCurrentPageBrands')({
        searchKey: searchDict['search'],
        rank: rank,
        page: searchDict['page'] || 1,
        moreSearch: { ...searchDict },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Brand"
      action={() => {
        history.push('/brands/create');
      }}
      requires={PermissionCodes.addBrand}
    />,
  ];
  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteBrand,
    },
  ];

  const displayPriorityChange = (item, displayPriority) => {
    if (item.displayPriority === displayPriority) {
      return;
    };
    dispatch(
      createAction('brand/updateBrandDisplayPriority')({
        data: {
          pk: item.pk,
          displayPriority: displayPriority,
        },
        afterAction: () => history.push(location),
      }),
    );
  };

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'brand'}
              permissionGroup={PermissionCodes.brand}
              dataList={brandList}
              totalPage={totalPage}
              sortable
              sortDisabled={false}
              onSortEnd={(oldIndex, newIndex) => {
                const newOrderObject = brandList[newIndex];
                const movedItem = brandList[oldIndex];
                displayPriorityChange(movedItem, newOrderObject.displayPriority)
              }}
              onOrderChangedByEdit={(item, newDisplayPriority) => {
                displayPriorityChange(item, newDisplayPriority)
              }}
              actions={['Edit', 'Delete', 'Duplicate']}
              deleteInfo={{
                data: [],
                title: 'brand',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.BRAND,
              }}
            />
          }
        />
      ),
    },
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Create various brands and select them in a campaign.',
          title: 'Brands',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />

      <DeletePrompt
        data={checkedList}
        title={'Brand'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.BRAND}
        show={showDeletePrompt}
        onClose={closeDeletePop}
        onConfirm={confirmDelete}
      />
    </>
  );
}

export default BrandList;
