import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';

function GreetingSectionList({
  pageInfo,
  totalPage,
  totalCount,
  listFields,
  dataList,
  checkedList,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const deletePks = deleteItems?.map(item => item.pk);

  useEffect(() => {
    return function () {
      dispatch({ type: 'greetingSection/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const querySearch = queryString.parse(history.location.search);
    dispatch({
      type: 'greetingSection/getList',
      payload: {
        ...querySearch,
        searchKey: querySearch.search,
      }
    });
  }, [dispatch, history.location]);

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeGreetingSection,
    },
  ];

  const tabs = [
    {
      name: 'greetingSection',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'greetingSection'}
              permissionGroup={PermissionCodes.greetingSection}
              deleteInfo={{
                data: [],
                title: 'Greeting section',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              specificActions={[
                {
                  fields: ['activeEndDate'],
                  conditions: [null],
                  actions: ['Detail'],
                },
              ]}
              actions={['Detail', 'Edit', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  const getButtons = () => {
    return [
      <AuthButton
        title={'Create Greeting Section'}
        className={'btn-further'}
        action={() => {
          dispatch({ type: 'greetingSection/updateState', payload: { greetingSection: {} } });
          history.push({
            pathname: '/greeting_section/create',
          });
        }}
        requires={PermissionCodes.changeGreetingSection}
      />
    ]
  }

  const buttons = getButtons();

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'These are greeting section settings.',
          title: NavBarNames.greetingSection,
        }}
        defaultActiveKey={tabs[0].name}
        tabs={tabs}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        buttons={buttons}
      />
      <DeletePrompt
        data={checkedList}
        title={'campaign'}
        relatedName={''}
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch({
            type: 'greetingSection/delete',
            payload: {
              deletePks,
              afterAction: () => {
                history.push(history.location);
              },
            }
          });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  pageInfo: state.greetingSection.pageInfo,
  totalPage: state.greetingSection.totalPage,
  totalCount: state.greetingSection.totalCount,
  listFields: state.greetingSection.listDisplayFields,
  dataList: state.greetingSection.greetingSectionList,
  checkedList: state.greetingSection.checkedList,
});

export default connect(mapStateToProps)(GreetingSectionList);
