import React from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './ProfileSection.scss';
import CheckOnIcon from '../../../assets/images/check_on.svg';
import { CampaignType, EarningRuleType, LanguageConfig } from '../../../config/CustomEnums';
import { GENERAL_TYPE, TARGET_USERS } from '../../../models/CreateCampaignModel';
import { getReadablePeriod, getCampaignPeriodDateTimeWithA } from '../../../utils/TimeFormatUtil';
function ProfileSection() {
  const {
    campaignType,
    linkedCoupon,
    linkedEarningRules,
    categories,
    customerGroup,
    activeStartDate,
    activeEndDate,
    visibleEndDate,
    visibleStartDate,
    isAlwaysVisiblePeriod,
    isAlwaysActivePeriod,
    linkedBrands,
    overallLimit,
    perHeadLimit,
    requiredPoints,
    discount,
    discountRequiredPoints,
    discountStartDate,
    discountEndDate,
    discountTargetedUser,
    discountTargetedCustomerGroups,
    discountTargetedCustomerSegments,
    displayOverLimit,
    segments,
    generalType,
    location,
  } = useSelector((state) => ({
    campaignType: state.createCampaign.campaign.campaignType,
    linkedCoupon: state.createCampaign.campaign.linkedCoupon,
    linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
    categories: state.createCampaign.campaign.categories,
    customerGroup: state.createCampaign.campaign.customerGroup,
    activeStartDate: state.createCampaign.campaign.activeStartDate,
    activeEndDate: state.createCampaign.campaign.activeEndDate,
    visibleStartDate: state.createCampaign.campaign.visibleStartDate,
    visibleEndDate: state.createCampaign.campaign.visibleEndDate,
    isAlwaysVisiblePeriod: state.createCampaign.campaign.isAlwaysVisiblePeriod,
    isAlwaysActivePeriod: state.createCampaign.campaign.isAlwaysActivePeriod,
    linkedBrands: state.createCampaign.campaign.linkedBrands,
    overallLimit: state.createCampaign.campaign.overallLimit,
    perHeadLimit: state.createCampaign.campaign.perHeadLimit,
    requiredPoints: state.createCampaign.campaign.requiredPoints,
    discount: state.createCampaign.campaign.discount,
    discountRequiredPoints: state.createCampaign.campaign.discountRequiredPoints,
    discountStartDate: state.createCampaign.campaign.discountStartDate,
    discountEndDate: state.createCampaign.campaign.discountEndDate,
    discountTargetedUser: state.createCampaign.campaign.discountTargetedUser,
    discountTargetedCustomerGroups: state.createCampaign.campaign.discountTargetedCustomerGroups,
    discountTargetedCustomerSegments: state.createCampaign.campaign.discountTargetedCustomerSegments,
    displayOverLimit: state.createCampaign.campaign.displayOverLimit,
    segments: state.createCampaign.campaign.segments,
    generalType: state.createCampaign.campaign.generalType,
    location: state.createCampaign.campaign.location,
  }));

  const getListStrings = (list) => {
    if (list?.length === 0) {
      return null;
    }
    return list?.map((item) => item.name).toString();
  };

  const profileField = (field, value, showShortDescription = false) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        <label className="create-section-field-input profile-section-label-bottom">
          {value || '-'}
        </label>
        {showShortDescription ? (
          <div className="display-over-all-limit-container">
            <Image src={CheckOnIcon} className="display-over-all-limit-icon" />
            <label className="display-over-all-limit">
              Display the overall limit in the campaign.
            </label>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">PROFILES</label>
      {profileField('Campaign type', campaignType)}
      {campaignType === CampaignType.couponsCampaign
        ? profileField('Link to coupon set', linkedCoupon?.name)
        : profileField('Link to earning rule set', linkedEarningRules?.name)}
      {
        linkedEarningRules?.type === EarningRuleType.qrCodeRecyclingExtraRewards
          ?
          <>
            {profileField('Building name - EN (Compulsory)', location?.name)}
            {profileField('Building name - TC (Compulsory)', location?.translations?.[LanguageConfig.traditionalChinese]?.name)}
            {profileField('Longitude', location?.longitude)}
            {profileField('Latitude', location?.latitude)}
            {profileField('Radius', location?.radius)}
          </>
          : null
      }
      {profileField('Campaign category', getListStrings(categories))}
      {profileField(
        'Target customer groups',
        getListStrings(
          generalType === GENERAL_TYPE.customerGroup ? customerGroup : [],
        ),
      )}
      {profileField(
        'Segments',
        getListStrings(generalType === GENERAL_TYPE.segment ? segments : []),
      )}
      {profileField(
        'Active period',
        getReadablePeriod(activeStartDate, activeEndDate, isAlwaysActivePeriod),
      )}
      {profileField(
        'Visible period',
        getReadablePeriod(
          visibleStartDate,
          visibleEndDate,
          isAlwaysVisiblePeriod,
        ),
      )}
      {profileField(
        'Related brand',
        linkedBrands?.map((item) => item.name).join(', '),
      )}
      {campaignType === CampaignType.couponsCampaign ? (
        <>
          {profileField(
            'Overall limit',
            overallLimit === null ? '-' : `${overallLimit} number of coupons`,
            displayOverLimit,
          )}
          {profileField(
            'Per head limit',
            perHeadLimit === null ? '-' : `${perHeadLimit} number of coupons`,
          )}
          {profileField('Required points', `${requiredPoints} points`)}
          {profileField('Discount', discount == true ? 'Available' : '-')}
          {discount == true &&
            <>
              {profileField('Required points (Discounted)', `${discountRequiredPoints} points`)}
              {profileField('Discount start date', getCampaignPeriodDateTimeWithA(discountStartDate))}
              {profileField('Discount end date', getCampaignPeriodDateTimeWithA(discountEndDate))}
              {profileField('Target all users', discountTargetedUser == TARGET_USERS.all ? TARGET_USERS.all : '-')}
              {profileField('Target customer group', getListStrings(discountTargetedCustomerGroups))}
              {profileField('Target customer segment', getListStrings(discountTargetedCustomerSegments))}
            </>
          }
        </>
      ) : null}
    </>
  );
}

export default ProfileSection;
