import {
  getStores,
  getStoresOfBrand,
  createOrUpdateStore,
  deleteStores,
  getOneStore,
  getPagedStores,
  getDistricts,
} from '../services/StoreAPIHelper';
import {
  createAction,
  convertNumberToCursor,
  convertPKToId,
  convertCursorToNumber,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeUndefinedFieldFromDict,
  removeFromSessionStorage,
  getFileNameFromUrl,
  delay,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  SavedStatus,
  CheckStatus,
  LanguageConfig,
  LanguageUpperConfig,
} from '../config/CustomEnums';
import { StoreErrorHandleFields } from '../containers/merchants/stores/StoreErrorHandleFields';
import sessionStorage from 'redux-persist/es/storage/session';

const storeSessionKey = 'tempStore';

const getTranslations = (translationList) => {
  const translation = {};
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      ...item.node,
      storeName: item.node.name,
    };
  });
  return translation;
};

const parseStore = (store) => {
  if (!store) {
    return {};
  }
  return {
    ...store,
    storeID: store.id,
    storePK: store.pk,
    storeName: store.name,
    storePhoto: store.photo,
    storeAddress: store.address,
    storeTranslations: getTranslations(store.translations?.edges || []),
    coverPhoto: store.photo,
    otherPhoto: [store.photo2, store.photo3].filter((item) => item),
    phoneNumber: store.phoneNumberCountryCode
      ? `${store.phoneNumberCountryCode}-${store.phoneNumberSubscriberNumber}`
      : '-',
    displayPhoneNumner: store.phoneNumberCountryCode
      ? `+${store.phoneNumberCountryCode}${store.phoneNumberSubscriberNumber}`
      : '-',
    displaySubcategory: store.subcategories?.edges
      .map((item) => item.node.name)
      .join(','),
    selectedSubcategories: store.subcategories?.edges.map((item) => item.node),
    // displaySubcategory:
    //   'Western, Chinese, German, French, Hunan, Cantonese, Northeastern, Jiangsu and Zhejiang cuisine, Sichuan cuisine...',
    order: store.displayPriority,
    displayDistrict: store.district?.name,
    displayBrand: store.brand?.name,
    isReverseVendingMachineDisplay: store.isReverseVendingMachine
      ? 'Yes'
      : 'No',
    isReverseVendingMachine: store.isReverseVendingMachine,
    district: store.district
      ? { label: store.district.name, value: store.district }
      : null,
  };
};

const getInitState = () => {
  return {
    storeList: [],
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Name', fieldName: 'name', orderField: 'name' },
      { displayName: 'Cover photo', fieldName: 'coverPhoto' },
      {
        displayName: 'Display order',
        fieldName: 'order',
        orderField: 'displayPriority',
      },
      { displayName: 'Phone number', fieldName: 'phoneNumber' },
      { displayName: 'Email', fieldName: 'emailAddress' },
      { displayName: 'Subcategory', fieldName: 'displaySubcategory', lines: 5 },
      {
        displayName: 'Reverse Vending Machine',
        fieldName: 'isReverseVendingMachineDisplay',
      },
    ],
    pagedStoreList: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    totalPage: 0,
    totalCount: 0,
    currentPage: 0,
    checkedList: [],
    oneStore: {},
    checked: CheckStatus.initOrNotChecked,
    errorFields: {},
    saved: SavedStatus.init,
    formChanged: false,
    districtList: [],
    filteredDistrictList: [],
  };
};

export default {
  namespace: 'storeModel',
  state: getInitState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    loadDataFromCookie(state, { payload }) {
      const sessionStore = getObjectFromSessionStorage(storeSessionKey) || {};
      let tempStore = payload?.data ? payload?.data : {};

      tempStore.name = {
        [LanguageConfig.english]: tempStore.name || null,
        [LanguageConfig.traditionalChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.traditionalChinese]?.name
          : null,
        [LanguageConfig.simplifiedChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.simplifiedChinese]?.name
          : null,
      };

      tempStore.address = {
        [LanguageConfig.english]: tempStore.address || null,
        [LanguageConfig.traditionalChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.traditionalChinese]
              ?.address
          : null,
        [LanguageConfig.simplifiedChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.simplifiedChinese]
              ?.address
          : null,
      };
      tempStore.description = {
        [LanguageConfig.english]: tempStore.description || null,
        [LanguageConfig.traditionalChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.traditionalChinese]
              ?.description
          : null,
        [LanguageConfig.simplifiedChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.simplifiedChinese]
              ?.description
          : null,
      };

      let data = { ...tempStore };
      if (!payload?.noCookie) {
        data = { ...tempStore, ...removeUndefinedFieldFromDict(sessionStore) };
      }

      return {
        ...state,
        oneStore: data,
      };
    },
    changeVals(state, { payload }) {
      const sessionStore = getObjectFromSessionStorage(storeSessionKey) || {};
      console.log('@@122vals changed: ', payload.vals);
      let data = {};
      if (payload.vals) {
        data = payload.vals;
      }
      // console.log('@@180-1: ', sessionStore);
      // console.log('@@180-2: ', data);
      // console.log('@@180-3:', {
      //   ...sessionStore,
      //   ...removeUndefinedFieldFromDict(data),
      //   name: {
      //     ...sessionStore.name,
      //     ...removeUndefinedFieldFromDict(data.name),
      //   },
      //   address: {
      //     ...sessionStore.address,
      //     ...removeUndefinedFieldFromDict(data.address),
      //   },
      //   description: {
      //     ...sessionStore.description,
      //     ...removeUndefinedFieldFromDict(data.description),
      //   },
      // });
      let changedData = {
        ...sessionStore,
        ...removeUndefinedFieldFromDict(data),
      };

      const changedName = removeUndefinedFieldFromDict(data.name);
      if (Object.keys(changedName).length > 0) {
        changedData = {
          ...changedData,
          name: {
            ...sessionStore.name,
            ...changedName,
          },
        };
      }

      const changedAddress = removeUndefinedFieldFromDict(data.address);
      if (Object.keys(changedAddress).length > 0) {
        changedData = {
          ...changedData,
          address: {
            ...sessionStore.address,
            ...changedAddress,
          },
        };
      }

      const changedDescription = removeUndefinedFieldFromDict(data.description);
      if (Object.keys(changedDescription).length > 0) {
        changedData = {
          ...changedData,
          description: {
            ...sessionStore.description,
            ...changedDescription,
          },
        };
      }

      saveToSessionStorage(storeSessionKey, changedData);
      return {
        ...state,
        formChanged: true,
      };
    },
    checkValsValid(state, { payload }) {
      const sessionStore = getObjectFromSessionStorage(storeSessionKey) || {};

      const store = {
        ...state.oneStore,
        ...removeUndefinedFieldFromDict(sessionStore),
      };

      let errorFields = { fields: [], messages: [] };
      let checked = CheckStatus.initOrNotChecked;

      Object.keys(StoreErrorHandleFields).map((field) => {
        const emailValid =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (['name', 'address', 'description'].includes(field)) {
          if (!store[field].en) {
            errorFields.fields.push(field);
            errorFields.messages.push({ field, errorType: 'required' });
          }
        } else if (
          !['emailAddress', 'staffCode'].includes(field) &&
          (!store[field] || store[field].length <= 0)
        ) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'required' });
        } else if (
          field === 'staffCode' &&
          store[field] &&
          store[field].length !== 6
        ) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'length' });
        } else if (
          field === 'emailAddress' &&
          store[field]?.length > 0 &&
          !emailValid.test(store[field])
        ) {
          // console.log('@@266: ', store[field]?.length);
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'valid' });
        }
      });

      if (!payload.coverPhoto) {
        errorFields.fields.push('coverPhoto');
        errorFields.messages.push({
          field: 'coverPhoto',
          errorType: 'required',
        });
      }

      if (errorFields.fields.length > 0) {
        checked = CheckStatus.checkedWithFail;
      } else {
        checked = CheckStatus.checkedWithSuccess;
      }

      return {
        ...state,
        checked,
        errorFields,
      };
    },
    removeFromCookie(state, { payload }) {
      removeFromSessionStorage(storeSessionKey);
      return { ...state, formChanged: false };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },
  effects: {
    *getAllStores({ payload }, { call, put, select }) {
      const serviceArgs = [getStores];

      function* onSuccess(data) {
        const storeInfo = data?.stores;
        if (storeInfo) {
          const storeList = storeInfo.edges;
          yield put(
            createAction('updateState')({
              storeList: storeList.map((item) => parseStore(item.node)),
            }),
          );
        }
      }

      yield apiWithResponseHandle(serviceArgs, onSuccess);
    },

    getPagedStoreList: [
      function* ({ payload }, { put, select }) {
        const page = payload.page || 1;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';

        const serviceArgs = [getPagedStores, pageCursor, payload];
        function* onSuccess(data) {
          const pageInfo = data?.stores?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.stores?.totalCount;

          const storeList = data?.stores?.edges.map((item) =>
            parseStore(item.node),
          );
          if (payload.isSelectorLoad) {
            const stateStoreList = yield select(
              (state) => state.storeModel.storeList,
            );

            yield put({
              type: 'updateState',
              payload: {
                storeList:
                  page > 1 ? [...stateStoreList, ...storeList] : storeList,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                pagedStoreList: storeList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo.endCursor) + 1,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            });
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedSegments } = yield select((state) => ({
          checkedSegments: state.storeModel.checkedList,
        }));

        let pks = [];
        checkedSegments.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteStores, pks];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          yield all([
            put({
              type: 'storeModel/updateState',
              payload: { checkedList: [] },
            }),
          ]);

          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getOneStore: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [
          getOneStore,
          convertPKToId('StoreNode', payload.id),
        ];
        function* onSuccess(data) {
          yield put({
            type: 'loadDataFromCookie',
            payload: {
              data: parseStore(data.store),
              noCookie: true,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        let data = payload.data;
        let source = {};
        const getPhoto = (index) => {
          return source.otherPhoto[index]
            ? getFileNameFromUrl(
                source.otherPhoto[index].value || source.otherPhoto[index],
              )
            : null;
        };
        if (!data) {
          const serverStore = yield select(
            (state) => state.storeModel.oneStore,
          );
          const sessionStore =
            getObjectFromSessionStorage(storeSessionKey) || {};
          source = {
            ...serverStore,
            ...removeUndefinedFieldFromDict(sessionStore),
          };
          data = {
            name: source.name.en,
            displayPriority: source.displayPriority,
            photo: getFileNameFromUrl(
              source.coverPhoto.value || source.coverPhoto,
            ),
            photo2: getPhoto(0),
            photo3: getPhoto(1),
            address: source.address.en,
            phoneNumberCountryCode:
              source.phoneNumberCountryCode?.value ||
              source.phoneNumberCountryCode,
            phoneNumberSubscriberNumber: source.phoneNumberSubscriberNumber,
            latitude:
              source.latitude && source.longitude ? source.latitude : null,
            longitude:
              source.latitude && source.longitude ? source.longitude : null,
            emailAddress: source.email || source.emailAddress,
            description: source.description.en,
            staffCode: source.staffCode,
            subcategories: source.subcategories.map((item) => item.pk),
            district: source.district?.pk || source.district?.value.pk,
            brand: source.brand?.pk || source.brand?.value.pk,
            isAvailable: source.isAvaliable,
            isPublic: source.isPublic,
            hideInMap: source.hideInMap,
            isReverseVendingMachine: source.isReverseVendingMachine,
            securityKey: source.securityKey,
            translations:
              source.storeTranslations &&
              Object.keys(source.storeTranslations).length > 0
                ? Object.keys(source.storeTranslations).map((lang) => ({
                    language: source.storeTranslations[lang].language,
                    name: source.name[source.storeTranslations[lang].language],
                    address:
                      source.address[source.storeTranslations[lang].language],
                    description:
                      source.description[
                        source.storeTranslations[lang].language
                      ],
                    id: source.storeTranslations[lang].pk,
                  }))
                : [
                    LanguageConfig.traditionalChinese,
                    LanguageConfig.simplifiedChinese,
                  ].map((lang) => ({
                    language: lang,
                    name: source.name[lang],
                    address: source.address[lang],
                    description: source.description[lang],
                  })),
            // translations: [
            //   LanguageConfig.traditionalChinese,
            //   LanguageConfig.simplifiedChinese,
            // ].map((lang) => ({
            //   language: lang,
            //   name: source.name[lang],
            //   address: source.address[lang],
            //   description: source.description[lang],
            // })),
          };

          if (source.pk) {
            data.id = source.pk;
          }

          if (serverStore.brand) {
            delete data.brand;
          }
        }

        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [createOrUpdateStore, data];
        console.log("@@551", serviceArgs)
        function* onSuccess(data) {
          console.log("@@553", data)
          if (
            ('createStore' in data && data.createStore.errors) ||
            ('updateStore' in data && data.updateStore.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                saved: SavedStatus.savedWithFail,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                formChanged: false,
                saved: SavedStatus.savedWithSuccess,
                checked: CheckStatus.initOrNotChecked,
              },
            });

            removeFromSessionStorage(storeSessionKey);
          }

          afterAction();
        }

        function* onFail(data) {
          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
              saved: SavedStatus.savedWithFail,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail, onFail);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { call, select, put }) {
        const otherPhotos = payload.data.otherPhoto || [];
        const photo2 = otherPhotos[0];
        const photo3 = otherPhotos[1];
        const data = {
          name: `Copy of ${payload.data.name}`,
          displayPriority: payload.data.displayPriority + 1,
          photo: getFileNameFromUrl(payload.data.coverPhoto),
          photo2: photo2 ? getFileNameFromUrl(photo2) : null,
          photo3: photo3 ? getFileNameFromUrl(photo3) : null,
          address: payload.data.address,
          phoneNumberCountryCode: payload.data.phoneNumberCountryCode,
          phoneNumberSubscriberNumber: payload.data.phoneNumberSubscriberNumber,
          latitude:
            payload.data.latitude && payload.data.longitude
              ? payload.data.latitude
              : null,
          longitude:
            payload.data.latitude && payload.data.longitude
              ? payload.data.longitude
              : null,
          emailAddress: payload.data.emailAddress,
          description: payload.data.description,
          securityKey: payload.data.securityKey,
          staffCode: payload.data.staffCode,
          subcategories: payload.data.subcategories.edges.map(
            (item) => item.node.pk,
          ),
          district: payload.data.district?.pk,
          brand: payload.data.brand?.pk,
        };
        yield put(
          createAction('createOrUpdate')({
            data,
            storeTranslations: payload.data.translations?.edges?.map(
              (item) => item.node,
            ),
            afterAction: payload.afterAction,
          }),
        );
      },
      { type: 'takeLatest' },
    ],
    getDistrictList: [
      function* ({ payload }, { select, put }) {
        const { search } = payload;
        const districtList = yield select(
          (state) => state.storeModel.districtList,
        );
        if (search && districtList) {
          return yield put({
            type: 'updateState',
            payload: {
              filteredDistrictList: districtList.filter(
                (val) =>
                  val.name
                    .toLocaleLowerCase()
                    .indexOf(search?.toLocaleLowerCase()) >= 0,
              ),
            },
          });
        }

        const serviceArgs = [getDistricts];
        function* onSuccess(data) {
          const result = data?.districts?.edges.map((item) => item.node);
          yield put({
            type: 'updateState',
            payload: {
              districtList: result,
              filteredDistrictList: result,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
