import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignErrorHandleField } from './CreateCampaignHandleError';
import PeriodSection from './PeriodSection';
import { compareDate } from '../../../utils/TimeFormatUtil';
import {
  campaignLimitDate,
  campaignVisibleClue,
  disableSwitchToAllPeriod,
} from './CreateCampaignUtil';
function VisiblePeriodSection(props) {
  const {
    earningRuleType,
    isAlwaysVisiblePeriod,
    visibleStartDate,
    visibleEndDate,
    errorFields,
    birthdayStartDate,
    birthdayEndDate,
  } = useSelector((state) => ({
    earningRuleType: state.createCampaign.campaign.linkedEarningRules?.type,
    isAlwaysVisiblePeriod: state.createCampaign.campaign.isAlwaysVisiblePeriod,
    visibleStartDate: state.createCampaign.campaign.visibleStartDate,
    visibleEndDate: state.createCampaign.campaign.visibleEndDate,
    errorFields: state.createCampaign.errorFields,
    birthdayStartDate:
      state.createCampaign.campaign.linkedEarningRules
        ?.birthdayTypePeriodStartDate,
    birthdayEndDate:
      state.createCampaign.campaign.linkedEarningRules
        ?.birthdayTypePeriodEndDate,
  }));
  const onSubmitAction = props.onSubmitAction || (() => {});
  const dispatch = useDispatch();
  const onSubmit = props.onSubmit;
  const defaultActive = () => {
    if (birthdayStartDate && birthdayEndDate) {
      return false;
    }
    return isAlwaysVisiblePeriod;
  };
  const [alwaysActive, setAlwaysActive] = useState(defaultActive());
  const [startDate, setStartDate] = useState(
    compareDate(visibleStartDate, birthdayStartDate, false),
  );
  const [endDate, setEndDate] = useState(
    compareDate(visibleEndDate, birthdayEndDate, true),
  );
  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        isAlwaysVisiblePeriod: alwaysActive,
        visibleStartDate: startDate,
        visibleEndDate: endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  const sessionSave = (payload) => {
    dispatch({
      type: 'createCampaign/setFieldToSession',
      payload: payload,
    });
  };

  const activePeriodSwitch = (isChecked) => {
    sessionSave({ isAlwaysVisiblePeriod: isChecked });
    setAlwaysActive(isChecked);
  };

  const startDateChange = (date) => {
    sessionSave({ visibleStartDate: date });
    setStartDate(date);
  };

  const endDateChange = (date) => {
    sessionSave({ visibleEndDate: date });
    setEndDate(date);
  };

  return (
    <PeriodSection
      disabled={disableSwitchToAllPeriod(
        earningRuleType,
        birthdayStartDate,
        birthdayEndDate,
      )}
      title="Visible Period"
      titleDescription={campaignVisibleClue(earningRuleType)}
      switchButtonTitle="It is an all-time visible campaign"
      isAlwaysPeriod={alwaysActive}
      switchOnChange={activePeriodSwitch}
      startDate={startDate}
      startDateChange={startDateChange}
      endDate={endDate}
      endDateChange={endDateChange}
      maxDate={campaignLimitDate(earningRuleType, birthdayStartDate)}
      minDate={campaignLimitDate(earningRuleType, birthdayEndDate)}
      errorFields={errorFields}
      fieldName={CampaignErrorHandleField.visibleEndDate.name}
      fieldErrorMessage={CampaignErrorHandleField.visibleEndDate.message}
    />
  );
}

export default VisiblePeriodSection;
