export const enCompare = (firstItem = "", secondItem = "") => {
    return firstItem?.toLowerCase() > secondItem?.toLowerCase() ? 1 : -1
  }
  
  export const zhCompare = (firstItem = "", secondItem = "") => {
    return firstItem?.localeCompare(secondItem, 'zh-CN');
  }
  
  export const defaultOrder = (firstItem = "", secondItem = "") => {
    const firstItemLength = firstItem?.length || 0;
    const secondItemLength = secondItem?.length || 0;
    const minLength = Math.min(firstItemLength, secondItemLength);
    const reg = /[0-9a-zA-Z]/;
    for (let i = 0; i < minLength; i++) {
      const firstCharacter = firstItem[i];
      const secondCharacter = secondItem[i];
      if (firstCharacter === secondCharacter) {
        continue
      } else if (reg.test(firstCharacter) || reg.test(secondCharacter)) {
        return enCompare(firstCharacter, secondCharacter)
      } else {
        return zhCompare(firstCharacter, secondCharacter)
      }
    }
    return firstItemLength > secondItemLength ? 1 : -1
  }
  