import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import BasePrompt from '../base/prompt/BasePrompt';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
  CustomNumberInputWithUnit,
} from './CustomBaseComponments';
import { Image } from 'react-bootstrap';
import addImage from '../../assets/images/addx1.png';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import {
  CampaignType,
  EarningRuleType,
  EarningRuleRewardType,
} from '../../config/CustomEnums';
import { getCampaignTypeSelectPromptConfig } from '../campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { useCompare } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomSwitchButton from '../base/CustomSwitchButton';

const RADIO_OPTIONS = [
  { label: 'Coupons', value: EarningRuleRewardType.coupons },
  { label: 'Points', value: EarningRuleRewardType.points },
];
const PROMOT_CONFIG = getCampaignTypeSelectPromptConfig(
  CampaignType.earningRulesCampaign,
);

const BaseStepRewardSection = ({
  title,
  description,
  keyPrefix = 'reachMinimum', // reachMinimum, overMinimum
  defaultValue = {},
  setShowCreateNewLinkPrompt,
  onSubmit,
  onSubmitAction,
}) => {
  const dispatch = useDispatch();
  const showExtra = keyPrefix === 'overMinimum';
  const [rewardType, setRewardType] = useState(
    defaultValue.rewards?.[keyPrefix]?.[`${keyPrefix}RewardType`] ||
      EarningRuleRewardType.coupons,
  );
  const [selectedCoupons, setSelectedCoupons] = useState(
    defaultValue.rewards?.[keyPrefix]?.[`${keyPrefix}Coupons`],
  );
  const [quantity, setQuantity] = useState(
    defaultValue.rewards?.[keyPrefix]?.[`${keyPrefix}Quantity`],
  );
  const [carbonReward, setCarbonReward] = useState(
    defaultValue.rewards?.[keyPrefix]?.[`${keyPrefix}Carbon`],
  );
  // const [hasCarbonReward, setHasCarbonReward] = useState(
  //   defaultValue[`${keyPrefix}Carbon`] > 0,
  // );
  const vals = {
    [`${keyPrefix}RewardType`]: rewardType,
    [`${keyPrefix}Coupons`]: selectedCoupons,
    [`${keyPrefix}Quantity`]: quantity,
    [`${keyPrefix}Carbon`]: carbonReward,
  };
  const hasValchanged = useCompare(vals);
  useEffect(() => {
    if (hasValchanged) {
      dispatch({
        type: 'createEarningRules/changeVals',
        payload: { vals, groupKey: keyPrefix },
      });
    }
  });
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));
  const isError = (name) => {
    let names = Array.isArray(name) ? name : [name];
    let errors = [];
    names.forEach((field) => {
      const error = isShowError(
        EarningRuleErrorHandleField[field].name,
        errorFields,
      );
      if (error) {
        errors.push(error);
      }
    });

    return errors[0];
  };
  useEffect(() => {
    firstError(
      `${EarningRuleErrorHandleField[`${keyPrefix}Coupons`].name}`,
      errorFields,
    );
    firstError(
      `${EarningRuleErrorHandleField[`${keyPrefix}Quantity`].name}`,
      errorFields,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);
  return (
    <div
      key={`create-step-section-step-${keyPrefix}`}
      className={'create-step-section-item'}
    >
      <label className="create-section-title">{title}</label>
      <label className="tips-message">{description}</label>
      <CustomTitleLabel title="Reward type" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={(value) => {
            setRewardType(value);
          }}
          default={rewardType}
          options={RADIO_OPTIONS}
        />
      </div>

      {rewardType === EarningRuleRewardType.coupons ? (
        <>
          <CouponSelectDropdown
            title={'Coupon set'}
            defaultValue={{
              value: selectedCoupons,
              label: selectedCoupons?.name,
            }}
            setValue={setSelectedCoupons}
            addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            isPrompt
            errors={errorFields}
            errorName={EarningRuleErrorHandleField[`${keyPrefix}Coupons`].name}
            errorMessage={EarningRuleErrorHandleField[`${keyPrefix}Coupons`].message}
            addButtonInfo={{
              requires: PermissionCodes.addCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
          />
        </>
      ) : null}
      <CustomTitleLabel title="Quantity" />
      {showExtra && (
        <label className="tips-message">
          Eg. When customer step distance over the minimum, in addition to the
          fixed rewards, user can also can earn 1 coupons for every 1km. 1 km =
          1{' '}
          {rewardType === EarningRuleRewardType.coupons ? 'coupons' : 'points'}
        </label>
      )}
      <div style={{ display: 'flex' }}>
        {showExtra && (
          <label className="help-message margin-right-10-percent">1 km =</label>
        )}
        <input
          type="number"
          onChange={({ target }) => {
            const { value } = target;
            setQuantity(value === '' ? null : value);
          }}
          className="custom-number-input-short"
          value={quantity}
        />
        <label className="help-message margin-left-10-percent">
          {rewardType === EarningRuleRewardType.points
            ? 'points'
            : 'coupons per amount unit'}
        </label>
      </div>
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField[`${keyPrefix}Quantity`].name}`}
        error={isError(`${keyPrefix}Quantity`)}
        message={`${
          EarningRuleErrorHandleField[`${keyPrefix}Quantity`].message
        }`}
      />
      {/* <label className="create-section-label create-section-label-bottom-space">
        Carbon saving
      </label>
      <CustomSwitchButton
        defaultChecked={hasCarbonReward}
        onChange={setHasCarbonReward}
      />
      {hasCarbonReward ? (
        <> */}
      <label className="create-section-label create-section-label-bottom-space">
        Carbon saving quantity (optional)
      </label>
      {showExtra && (
        <label className="tips-message">
          Eg. When customer step distance over the minimum, , in addition to the
          fixed rewards, user can also earn 1 carbon saving for every 1km. 1 km
          = 1 carbon saving
        </label>
      )}
      <div style={{ display: 'flex' }}>
        {showExtra && (
          <label className="help-message margin-right-10-percent">1 km =</label>
        )}
        <input
          type="number"
          onChange={({ target }) => setCarbonReward(target.value)}
          className="custom-number-input-short"
          value={carbonReward}
          onFocus={() => {}}
        />
        <label className="help-message margin-left-10-percent">
          {showExtra ? 'carbon saving' : 'carbon saving per amount unit'}
        </label>
      </div>
      {/* </>
      ) : null} */}
    </div>
  );
};

function StepRewardSection(props) {
  const defaultValue = props.defaultValue || {};
  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const history = useHistory();
  const params = useParams();

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});

  return (
    <Row
      className="create-section-and-preview-container"
      style={{ marginTop: -60 }}
    >
      <div className="content-no-preview">
        <BaseStepRewardSection
          title="Reward for reach the minimum distance"
          description="When customer step distance reach the minimum distance then will get this reward."
          keyPrefix="reachMinimum"
          defaultValue={defaultValue}
          onSubmit={onSubmit}
          onSubmitAction={onSubmitAction}
          setShowCreateNewLinkPrompt={setShowCreateNewLinkPrompt}
        />
        <BaseStepRewardSection
          title="Reward for over the minimum distance"
          description="When customer step distance over the minimum,  in addition to the fixed rewards, user can also earn other extra reward."
          keyPrefix="overMinimum"
          defaultValue={defaultValue}
          onSubmit={onSubmit}
          onSubmitAction={onSubmitAction}
          setShowCreateNewLinkPrompt={setShowCreateNewLinkPrompt}
        />

        <BasePrompt
          show={showCreateNewLinkPrompt}
          closeAction={() => setShowCreateNewLinkPrompt(false)}
          rightButton={{
            text: PROMOT_CONFIG.button,
            action: () => {
              history.push({
                pathname: '/coupons/create',
                state: {
                  from: params.id
                    ? `/earns/${params.id}/edit/`
                    : '/earns/create',
                  title: 'Continue to Create Earning Rule',
                  content: 'You can continue to create the earning rule.',
                },
              });
            },
          }}
          title={PROMOT_CONFIG.title}
          description={
            'You will leave the campaign creation process. After you create a coupon set, you can come back to create a earning rule.'
          }
        />
      </div>
    </Row>
  );
}

export default StepRewardSection;
