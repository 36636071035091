import React, { useEffect, useState } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useDispatch, connect } from 'react-redux';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { sessionDataKey } from '../../../models/GreetingCardModel';
import CreateGreetingCardStepOne from '../../../components/greetingCard/CreateGreetingCardStepOne';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';

function CreateGreetingCard({
  greetingCardDetailName,
  createStatus,
  loadingStatus,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [completeProcess, setShowCompleteProcess] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'greetingCard/getDetail',
        payload: {
          id: params.id,
        }
      });
    } else {
      dispatch({
        type: 'greetingCard/loadGreetingCardFromCookie',
      });
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'greetingCard/clearData' });
    };
  }, [dispatch]);

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      if (params?.id) {
        gotoGreetingCardList();
        return;
      };
      setShowCompleteProcess(true);
    };
  }, [createStatus])

  const stepContent = [
    loadingStatus === APIStatus.success
      ? <CreateGreetingCardStepOne />
      : <Loading />
  ];

  const gotoGreetingCardList = () => {
    history.push({
      pathname: '/greeting_card',
    });
  };

  return (
    <>
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({
            type: 'greetingCard/saveOrRemoveGreetingCardFromCookie',
            payload: save,
          });
        }}
        sessionDataKey={sessionDataKey}
        stepsConfig={[]}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? greetingCardDetailName : ''} />
        }
        caution={{
          detail: '',
          title: params.id ? 'Edit ' + greetingCardDetailName : 'Create Greeting Card',
        }}
        showFinishPop={completeProcess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Greeting card is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to greeting card list',
              action: gotoGreetingCardList,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: gotoGreetingCardList,
          onClose: gotoGreetingCardList,
        }}
      />

      <SaveAndBackButtons
        backAction={gotoGreetingCardList}
        saveText={params.id ? 'Update' : 'Save'}
        saveAction={() => {
          dispatch({
            type: 'greetingCard/checkFields',
            payload: { id: params.id },
          })
        }}
      />
    </>
  );
}


const mapStateToProps = (state) => ({
  greetingCardDetailName: state.greetingCard.greetingCard.name,
  createStatus: state.greetingCard.createStatus,
  loadingStatus: state.greetingCard.loadingStatus,
});

export default connect(mapStateToProps)(CreateGreetingCard);

