import { useRef, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { ImageDomain, LanguageConfig } from '../config/CustomEnums';
import i18n from '../I18n';
import { IMAGE_TYPES, ERROR_TYPE } from '../models/UploadFilesModel';
import URI from 'urijs';
import { v4 as uuidv4 } from 'uuid';

export const deleteMessage = (items, related) =>
  `${
    items.length > 1
      ? 'These ' +
        items.slice(0, items.length - 1).join(', ') +
        ' and ' +
        items.slice(items.length - 1) +
        ' are'
      : 'This ' + items.join(', ') + ' is'
  } associated with some ${
    related.length > 1
      ? related.slice(0, related.length - 1).join(', ') +
        ' and ' +
        related.slice(related.length - 1)
      : related.join(', ')
  }, once deleted, it will affect other content. Are you sure to delete?`;

export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));
export const createNumberArray = (numberSize) =>
  Array.from(Array(numberSize).keys());

export const anchorElementWithId = (id) => {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  window.scrollTo(0, element.offsetTop - 124);
};

export const getFileNameFromUrl = (url) => {
  if(!url) {
    return url
  }
  const uri = new URI(url);
  return uri?.filename();
};

export const detectIsSafari = () => {
  return window.navigator.userAgent?.indexOf('Safari') !== -1;
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(id);
    }, 100);
  }
};

export const getImageUrl = (data) => {
  if (!data) {
    return '';
  }
  if (data.type !== IMAGE_TYPES.TYPE_URL) {
    return '';
  }
  return data.value;
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};

export const addDomainToImage = (imageName) => {
  if (!imageName) {
    return imageName;
  }
  if (imageName.indexOf('http') > -1) {
    return imageName;
  }

  return `${ImageDomain}${imageName}`;
};

export const gotoOutWeb = (link, callback) => {
  if (typeof callback === 'function') {
    callback();
  }
  window.open(link, '_blank');
};

export const getHashKeyString = (hash) => {
  if (!hash) {
    return '';
  }
  const hashKey = hash.slice(1, hash.length);
  return decodeURI(hashKey);
};

export const convertNumberToCursor = (index) => {
  const numberString = `arrayconnection:${index}`;
  return btoa(numberString);
};
export const convertPKToId = (nodeName, PK) => {
  if (!PK) {
    return PK;
  }
  const idString = `${nodeName}:${PK}`;
  return btoa(idString);
};

export const convertCursorToNumber = (base64) => {
  const decodedString = atob(base64);
  const numberString = decodedString.substring(
    decodedString.lastIndexOf(':') + 1,
  );
  return parseInt(numberString);
};

export const saveToSessionStorage = (key, object) => {
  window.sessionStorage.setItem(key, JSON.stringify(object));
};

export const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const getObjectFromSessionStorage = (key) => {
  const allcookies = sessionStorage.getItem(key);
  if (allcookies) {
    return JSON.parse(allcookies);
  }
  return allcookies;
};

export const addToSessionStorage = (key, object) => {
  console.log('save cookies:', object);

  const originalCookie = getObjectFromSessionStorage(key);
  const newCookie = { ...originalCookie, ...object };
  window.sessionStorage.setItem(key, JSON.stringify(newCookie));
};

export const createAction = (type) => (payload) => {
  return { type, payload };
};

export const removeElementFromArray = (array, value) => {
  return array.filter(function (ele) {
    return ele !== value;
  });
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useCompare = (val: any) => {
  const prevVal = usePrevious(val);
  return prevVal && JSON.stringify(prevVal) !== JSON.stringify(val);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const getError = (field, errorFields, errorHandle) => {
  return {
    id: field,
    error: errorFields?.fields?.includes(field),
    message: errorFields?.messages?.map((item) => {
      if (item.field === field) {
        if (item.errorType === 'required')
          return errorHandle[item.errorType][item.field];
        else return errorHandle['others'][item.field][item.errorType];
      }
    }),
  };
};

export const enLocaleNumberFormatter = (num) => {
  if (!num) {
    return 0;
  }
  const stringNumber = num.toString();
  const commaIndex = stringNumber.indexOf('.');
  if (commaIndex === -1) {
    return i18n.toNumber(stringNumber, { precision: 0 });
  }
  const stringNeedFormatting = stringNumber.substring(0, commaIndex);
  const stringEnd = stringNumber.substring(commaIndex);
  const stringFormated = i18n.toNumber(stringNeedFormatting, { precision: 0 });
  return `${stringFormated}${stringEnd}`;
};

export const formatNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeUndefinedFieldFromDict = (data) => {
  let validData = {};
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== null) {
        validData[key] = data[key];
      }
    });
  }

  return validData;
};

export const rewritePermission = (requires) => {
  if (typeof requires !== 'string') {
    return requires;
  }
  return requires?.indexOf('delete') === 0 || requires?.indexOf('add') === 0
    ? `change_${requires.split('_')[1]}`
    : requires;
};

export const checkHasPermission = (user, requires = []) => {
  if (user?.isSuperuser) return true;

  const newRequires = rewritePermission(requires);
  if (user?.userPermissions.includes(newRequires) || !requires) return true;

  return false;
};

export const ArrayStringData = (toSplitIntData) => {
  return toSplitIntData
    .split(',')
    .filter(function (ele) {
      return ele !== '';
    })
    .map((el) => {
      let number = Number(el);
      return number === 0 ? number : number || el;
    });
};

export const emptyStringToNull = (input) => (input === '' ? null : input);

export const saveImage = (url) => {
  fetch(url, { cache: 'no-cache' })
    .then((res) => res.blob())
    .then((blob) => {
      saveAs(blob, getFileNameFromUrl(url));
    });
};

export const getObjectTranslations = (object = {}) => {
  const translationEdge = object?.translations?.edges || [];
  const translation = {};
  translationEdge.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      ...item.node,
    };
  });
  return translation;
};

export const getFormatedField = (fieldName, object) => {
  const translations = object.translations || {};
  const formatedField = {};
  Object.values(LanguageConfig).forEach((language) => {
    const sessionData = object[fieldName]?.[language];
    const translationData =
      language === LanguageConfig.english &&
      typeof object[fieldName] !== 'object'
        ? object[fieldName]
        : translations[language]?.[fieldName];
    formatedField[language] = sessionData || translationData;
  });
  return formatedField;
};

export const fileUpload = async ({
  dispatch,
  imageData,
  imageType,
  afterAction,
}) => {
  const uuid = uuidv4();
  dispatch({
    type: 'uploadFiles/uploadCroppedImage',
    payload: {
      croppedImage: {
        imageDataUrl: imageData,
        imageType: imageType,
        imageName: uuid,
      },
      afterAction: afterAction,
    },
  });
};

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
