import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { sessionDataKey } from '../../../models/SplashAdModel';
import CreateSplashAdStepOne from '../../../components/splashAd/CreateSplashAdStepOne';
import Loading from '../../../components/base/Loading';

const CreateSplashAd = ({
  splashAdDetail,
  splashAdCreated,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'splashAd/getSplashAd',
        payload: {
          id: params.id,
          afterAction: () => {
            setLoading(false);
          }
        }
      });
    } else {
      dispatch({
        type: 'splashAd/loadSplashAdFromCookie',
        payload: {
          afterAction: () => {
            setLoading(false);
          }
        }
      });
    }

    return () => {
      dispatch({ type: 'splashAd/clearState' });
    };
  }, []);

  const stepContent = loading ? [<Loading />] : [<CreateSplashAdStepOne />];


  return (
    <ScrollableComponent
      tempSave={(save) => {
        dispatch({
          type: 'splashAd/saveOrRemoveSplashAdFromCookie',
          payload: save,
        });
      }}
      sessionDataKey={sessionDataKey}
      showFinishPop={splashAdCreated}
      nextStepConfig={{
        title: 'Successfully Created!',
        description: 'Splash ad successfully created.',
        buttons: [
          {
            text: 'Back to splash ad list',
            action: () => {
              history.push({
                pathname: '/splash_ad',
              });
            },
          },
        ],
      }}
      content={stepContent}
      currentStep={0}
      breadcrumb={
        <CustomBreadcrumb
          name={
            params.id
              ? splashAdDetail.name || 'Edit Splash Ad'
              : 'Create Splash Ad'
          }
        />
      }
      caution={{
        detail: '',
        title: params.id ? "Edit Splash Ad" : "Create Splash Ad"
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  splashAdDetail: state.splashAd.splashAdDetail,
  splashAdCreated: state.splashAd.splashAdCreated,
});

export default connect(mapStateToProps)(CreateSplashAd);
