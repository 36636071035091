import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../campaign/campaignCreation/CreateCampaignStepOne.scss';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../base/ItemTypeSet';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import { getScrollbarWidth, useMainContentWidth } from '../../utils/ScreenUtil';
import { MissionTypesConfig } from './CreateMissionConfig';

const CreateMissionStepOne =({
  missionType,
}) =>  {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const dispatch = useDispatch();
  const params = useParams();

  const sections = [
    <>
      <div>
        <label className="step-one-title">Mission Type</label>
        <div
          className="step-type-area campaign-step-one-bottom"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {MissionTypesConfig.map((typeItem) => (
            <ItemTypeSet
              key={`${typeItem.id}-${typeItem.description}`}
              item={typeItem}
              selected={missionType}
              disabled={!!params?.id}
              onClick={(id) => {
                dispatch({
                  type: 'mission/updateMissionDetail',
                  payload: { missionType: id, icon: null},
                });
              }}
            />
          ))}
        </div>
      </div>
    </>,
  ];

  const continueAction = () =>
    dispatch({ type: 'mission/stepChange', payload: { step: 0 } });

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={continueAction}
        disabledContinue={!missionType}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  missionType: state.mission.missionDetail.missionType,
});

export default connect(mapStateToProps)(CreateMissionStepOne);
