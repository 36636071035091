import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomerFilterableDropdown from './CustomerFilterableDropdown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import StoreSelectDropdown from './StoreSelectDropdown';
import BarcodeSelectDropdown from './BarcodeSelectDropdown'
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import OfflineEventTypeSelectDropdown from './OfflineEventTypeSelectDropdown';
import StaffNameSelectDropdown from './StaffNameSelectDropdown';
import CustomEditor from '../base/CustomEditor';
import { createAction } from '../../utils';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateTransactionError, TRANSACTION_RELATED_TYPE } from '../../models/CreateTransactionModel';
import { EarningRuleType, LanguageConfig } from '../../config/CustomEnums';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { useLocation } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import i18n from '../../I18n';
import FilterableDropdown from '../base/FilterableDropdown';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import AddCustomerGroupSegmentPrompt, { NewPromptType } from '../base/prompt/AddCustomerGroupSegmentPrompt';
import { GeneralPurchaseSubType, GeneralPurchaseSubTypeOptions } from '../../models/CreateEarningRulesModel';
import CustomRadios from '../base/CustomRadios';
import './TransactionGeneralSection.scss';

const CustomerLeastTips = "Select at least one customer / target customer group / segment";

function TransactionGeneralSection({
  language,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    customerGroupList,
    segmentList,
    customerGroup,
    customerSegment,
    transactionType,
    selectedCustomer,
    selectMerchantName,
    selectedStore,
    transactionDate,
    oclDate,
    offlineEventType,
    offlineDisplayEventType,
    relatedTransactionType,
    generalPurchaseSubType,
    smallHeading,
    staffName,
    posInvoiceId,
    invoiceId,
    remarks,
    errorFields,
    selectedBarcode
  } = useSelector((state) => ({
    customerGroupList: state.customerGroup.groupList,
    segmentList: state.segments.segmentList,
    customerGroup: state.createTransaction.transaction.customerGroup,
    customerSegment: state.createTransaction.transaction.customerSegment,
    transactionType: state.createTransaction.transaction.transactionDisplayType,
    selectedCustomer: state.createTransaction.transaction.customer,
    selectMerchantName: state.createTransaction.transaction.merchantName,
    selectedStore: state.createTransaction.transaction.store,
    selectedBarcode: state.createTransaction.transaction.barcode,
    transactionDate: state.createTransaction.transaction.transactionDate,
    oclDate: state.createTransaction.transaction.oclDate,
    offlineEventType: state.createTransaction.transaction.offlineEventType,
    offlineDisplayEventType:
      state.createTransaction.transaction.displayOfflineEventType,
    relatedTransactionType: state.createTransaction.transaction.relatedTransactionType,
    generalPurchaseSubType: state.createTransaction.transaction.generalPurchaseSubType,
    smallHeading: state.createTransaction.transaction.translations?.[language]?.smallHeading,
    staffName: state.createTransaction.transaction.staffName,
    posInvoiceId: state.createTransaction.transaction.posInvoiceId,
    invoiceId: state.createTransaction.transaction.invoiceId,
    remarks: state.createTransaction.transaction.remarks,
    errorFields: state.createTransaction.errorFields,
  }));

  const isAdminCreate = transactionType == TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE || false;

  useEffect(() => {
    firstError(
      CreateTransactionError.transactionDate.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.offlineEventType.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.posInvoiceId.name,
      errorFields,
      LanguageConfig.english,
    );
    // firstError(
    //   CreateTransactionError.invoiceId.name,
    //   errorFields,
    //   LanguageConfig.english,
    // );
    firstError(
      CreateTransactionError.offlineEventType.name,
      errorFields,
      LanguageConfig.english,
    );
    // firstError(
    //   CreateTransactionError.staffName.name,
    //   errorFields,
    //   LanguageConfig.english,
    // );
    firstError(
      CreateTransactionError.relatedTransactionType.name,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };

  const invoiceIdError = () => {
    let isInvoiceIDError = false;
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
      isInvoiceIDError = isError(CreateTransactionError.posInvoiceId.name);
    } else if (
      transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    ) {
      isInvoiceIDError = isError(CreateTransactionError.invoiceId.name);
    }
    return isInvoiceIDError;
  };

  const [date, setDate] = useState(
    transactionDate ? new Date(transactionDate) : new Date(),
  );
  const [promptType, setPromptType] = useState(NewPromptType.none);

  useEffect(() => {
    if (transactionDate) {
      setDate(new Date(transactionDate));
    }
  }, [transactionDate]);

  const smallHeadingSection = () => {
    return (
      <>
        <CustomTitleLabel title={i18n.t('transaction_small_heading', { locale: language })} />
        <div>
          <input
            type="text"
            className='text-input-field'
            onChange={(e) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  smallHeading: e.target.value,
                  language: language,
                })
              );
            }}
            value={smallHeading || ''}
            maxLength={60}
          />
        </div >
        <label className='tips-message' style={{ marginTop: '8px' }}>{i18n.t('transaction_small_heading_limit', { locale: language })}</label>
      </>
    );
  };

  if (language !== LanguageConfig.english) {
    return (
      smallHeadingSection()
    )
  };

  const relatedTransactionTypeOptions = Object.keys(TRANSACTION_RELATED_TYPE).map(key => (TRANSACTION_RELATED_TYPE[key]));

  return (
    <>
      <div className="first-section-title">GENERAL</div>
      <CustomerFilterableDropdown
        title={'Customer'}
        titleTips={isAdminCreate ? CustomerLeastTips : ''}
        showMembershipId={true}
        errorName={isAdminCreate ? CreateTransactionError.customerLeast.name : CreateTransactionError.customer.name}
        errorMessage={isAdminCreate ? CreateTransactionError.customerLeast.message : CreateTransactionError.customer.message}
        errorFields={errorFields}
        defaultSelectedCustomer={{
          label: selectedCustomer.owner,
          value: selectedCustomer,
        }}
        needLevelFilter={true}
        selectCustomer={(customer) => {
          dispatch(
            createAction('createTransaction/updateTransactionState')({
              customer: customer.value,
            }),
          );
        }}
      />
      {isAdminCreate
        ? <>
          <CustomAddRemoveMutipleSelector
            title={'Target customer groups'}
            titleTips={CustomerLeastTips}
            data={customerGroupList?.map((item) => {
              return {
                name: item.name,
                value: item,
              };
            })}
            selectedList={customerGroup?.map((item) => {
              return { name: item.name, value: item };
            })}
            setValue={(options) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  customerGroup: options.map((item) => item.value),
                }),
              );
            }}
            sourceReminderEnabelSelectAll={true}
            addButton={{
              title: 'Add Customer Group',
              action: () => setPromptType(NewPromptType.customerGroup),
            }}
            requires={PermissionCodes.addCustomerGroup}
            selectAllTitle={'All customer groups'}
            loadMoreAction={'customerGroup/getCurrentPageList'}
            error={{
              id: "customerLeast",
              error: isError(CreateTransactionError.customerLeast.name),
              message: CreateTransactionError.customerLeast.message,
            }}
          />
          <CustomAddRemoveMutipleSelector
            title={'Target segments'}
            titleTips={CustomerLeastTips}
            data={segmentList?.map((item) => {
              return {
                name: item.name,
                value: item,
              };
            })}
            selectedList={customerSegment?.map((item) => {
              return {
                name: item.name,
                value: item,
              };
            })}
            setValue={(options) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  customerSegment: options.map((item) => item.value),
                }),
              );
            }}
            sourceReminderEnabelSelectAll={true}
            addButton={{
              title: 'Add Segments',
              action: () => setPromptType(NewPromptType.segments),
            }}
            requires={PermissionCodes.addSegment}
            selectAllTitle={'All segments'}
            loadMoreAction={'segments/getSegmentsList'}
            error={{
              id: "customerLeast",
              error: isError(CreateTransactionError.customerLeast.name),
              message: CreateTransactionError.customerLeast.message,
            }}
          />
        </>
        : null
      }
      <div>
        <CustomTitleLabel
          title={
            transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
              ? "Payment date"
              : "Transaction date"
          }
        />
        <CustomDateTimeSelect
          defaultTime={date ? new Date(date) : new Date()}
          error={isShowError(
            CreateTransactionError.transactionDate.name,
            errorFields,
            LanguageConfig.english,
          )}
          onTimeChange={(date) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                transactionDate: date,
              }),
            );
          }}
        />
        <ErrorFieldMessage
          id={CreateTransactionError.transactionDate.name}
          error={isError(CreateTransactionError.transactionDate.name)}
          message={CreateTransactionError.transactionDate.message}
        />
      </div>
      {
        transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
          ? <>
            <CustomTitleLabel
              className='transaction-ocl-date-title'
              title="Payment date (to check OCL record)"
            />
            <div className='second-section_description transaction-ocl-date-description'>
                  If this field is filled with correct format, system will refer to this field for Payment Date.<br/>
                  Please input the required date format: 2023-05-10 15:22:00 </div>
            <input
              type="text"
              className={`text-input-field ${isError(CreateTransactionError.oclDate.name)
                ? 'error-field'
                : ''
                }`}
              placeholder='Input date format (e.g 2023-05-10 15:22:00)'
              onChange={(e) => {
                dispatch(
                  createAction('createTransaction/updateTransactionState')({
                    oclDate: e.target.value,
                  }),
                );
              }}
              value={oclDate || ''}
            />

            <ErrorFieldMessage
              id={CreateTransactionError.oclDate.name}
              error={isError(CreateTransactionError.oclDate.name)}
              message={CreateTransactionError.oclDate.message}
            />
          </>
          : null
      }
      {isAdminCreate
        ? <>
          <div style={{ display: 'grid', width: '100%' }}>
            <CustomTitleLabel title='Related transaction type' />
            <label className='tips-message'>
              This selection affects the title of the record display in My Green Journey.
              <br />
              If select “Others”, show reward type as title.
            </label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <FilterableDropdown
                selectChange={(value) => {
                  dispatch(
                    createAction('createTransaction/updateTransactionState')({
                      relatedTransactionType: value,
                    }),
                  );
                }}
                isPromptDropdown={true}
                options={relatedTransactionTypeOptions}
                defaultValue={relatedTransactionType}
                needFilter={false}
                customClass={`custom-title-dropdown ${isError(CreateTransactionError.relatedTransactionType.name) ? 'error-field' : ''
                  }`}
                onToggle={() => { }}
                loadMoreAction={() => { }}
                filterAction={() => { }}
              />
            </div>
            <ErrorFieldMessage
              id={CreateTransactionError.relatedTransactionType.name}
              error={isError(CreateTransactionError.relatedTransactionType.name)}
              message={CreateTransactionError.relatedTransactionType.message}
            />
          </div>
          {relatedTransactionType?.value === TRANSACTION_RELATED_TYPE.GREEN_DINING.value
            ? <>
              <CustomTitleLabel title="General Purchase Type" />
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <CustomRadios
                  onChange={(value) => {
                    dispatch(
                      createAction('createTransaction/updateTransactionState')({
                        generalPurchaseSubType: value,
                      }),
                    );
                  }}
                  default={generalPurchaseSubType || GeneralPurchaseSubType.GREEN_DINING.value}
                  options={GeneralPurchaseSubTypeOptions}
                />
              </div>
            </>
            : null
          }
          {smallHeadingSection()}
        </>
        : null
      }
      {transactionType !== TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING ? null : (
        <>
          <StoreSelectDropdown
            selectedStore={selectedStore}
            selectStore={(store) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  store: store,
                }),
              );
            }}
            hideAddButton={false}
            addButtonRequires={PermissionCodes.addStore}
          />

          <BarcodeSelectDropdown
            selectedBarcode={selectedBarcode}
            selectBarcode={(barcode) => {
              dispatch({ type: 'createTransaction/updateTransactionState', payload: { barcode: barcode } })
            }}
          />
        </>
      )}
      {/* <div>
        <CustomTitleLabel
          title={
            transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
              ? 'Pos invoice id'
              : 'Invoice id (optional)'
          }
        />
        <div>
          <input
            type="text"
            className={`text-input-field ${
              invoiceIdError() ? 'error-field' : ''
            }`}
            onChange={(e) => {
              if (
                transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
              ) {
                dispatch(
                  createAction('createTransaction/updateTransactionState')({
                    posInvoiceId: e.target.value,
                  }),
                );
              } else if (
                transactionType ===
                TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
              ) {
                dispatch(
                  createAction('createTransaction/updateTransactionState')({
                    invoiceId: e.target.value,
                  }),
                );
              }
            }}
            value={
              transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
                ? posInvoiceId
                : invoiceId
            }
          />
        </div>
        <div>
          <ErrorFieldMessage
            id={
              transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
                ? CreateTransactionError.posInvoiceId.name
                : CreateTransactionError.invoiceId.name
            }
            error={invoiceIdError()}
            message={
              transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
                ? CreateTransactionError.posInvoiceId.message
                : CreateTransactionError.invoiceId.message
            }
          />
        </div>
      </div> */}
      {/* {transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM &&
      location.pathname.includes('create') ? null : (
        <div>
          <OfflineEventTypeSelectDropdown
            transactionType={transactionType}
            defaultEventType={offlineEventType}
            defaultDisplayEventType={offlineDisplayEventType}
            selectEventType={(item) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  offlineEventType: item.value,
                  displayOfflineEventType: item.label,
                }),
              );
            }}
          />
          <ErrorFieldMessage
            id={CreateTransactionError.offlineEventType.name}
            error={isError(CreateTransactionError.offlineEventType.name)}
            message={CreateTransactionError.offlineEventType.message}
          />
        </div>
      )} */}
      <div>
        {/* <StaffNameSelectDropdown
          selectedStaff={staffName}
          selectStaff={(data) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                staffName: data,
              }),
            );
          }}
        /> */}
        <CustomTitleLabel title="Staff name (optional)" />
        <div>
          <input
            type="text"
            className={`text-input-field ${isError(CreateTransactionError.staffName.name)
              ? 'error-field'
              : ''
              }`}
            onChange={(e) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  staffName: e.target.value,
                }),
              );
            }}
            value={staffName || ''}
          />
        </div>

        <ErrorFieldMessage
          id={CreateTransactionError.staffName.name}
          error={isError(CreateTransactionError.staffName.name)}
          message={CreateTransactionError.staffName.message}
        />
      </div>
      <div>
        <CustomTitleLabel title="Remarks (optional)" />
        <CustomEditor
          initialValue={remarks}
          onValueChange={(data) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                remarks: data,
              }),
            );
          }}
        />
      </div>
      <AddCustomerGroupSegmentPrompt
        createItem="transaction"
        promptType={promptType}
        setPromptType={setPromptType}
      />
    </>
  );
}

export default TransactionGeneralSection;
