import React, { useEffect, useState } from 'react';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { APIStatus, MessageChannel } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import { MessageChannelConfig, MessageType, InformationBarMessageType } from './CreateMessageConfig';
import MessageChannelCard from './MessageChannelCard';
import CampaignSelectDropdown from './CampaignSelectDropdown';
import { CheckboxItem } from '../base/BaseFilter';
import {
  CustomTitleWithRadio,
  CustomTitleLabel,
} from '../earning/CustomBaseComponments';
import { message } from 'antd';

function CreateMessageStepOne() {
  const dispatch = useDispatch();

  const {
    status,
    channels,
    relatedCampaign,
    messageType,
    latestWelcomeMessageId,
  } = useSelector((state) => ({
    status: state.createMessage.createStatus,
    channels: state.createMessage.message.channels,
    relatedCampaign: state.createMessage.message.relatedCampaign,
    messageType: state.createMessage.message.messageType,
    latestWelcomeMessageId: state.messageList.latestWelcomeMessageId,
  }));

  console.log('@messageType', messageType)

  const messageChannelArea = () => {
    return (
      <div className="step-type-area message-channel-area">
        {MessageChannelConfig.map((channel) => (
          <MessageChannelCard
            key={channel.channel}
            channelConfig={channel}
            selectedChannels={channels}
            select={(channel) => {
              let messageType = MessageType.generalMessage

              let selectedChannels = channels;

              const index = selectedChannels.indexOf(channel);
              if (index === -1) {
                selectedChannels.push(channel);

                if (channel === MessageChannel.informationBar) {
                  selectedChannels = [MessageChannel.informationBar]

                  messageType = InformationBarMessageType.maintenance

                } else if (
                  channel === MessageChannel.push &&
                  selectedChannels.indexOf(MessageChannel.inbox) === -1
                ) {
                  selectedChannels.push(MessageChannel.inbox);
                }

                if ((channel === MessageChannel.push ||
                  channel === MessageChannel.inbox) &&
                  channels.includes(MessageChannel.informationBar)) {
                  const idx = selectedChannels.indexOf(MessageChannel.informationBar)
                  selectedChannels.splice(idx, 1)

                  messageType = MessageType.generalMessage
                }
              } else {
                selectedChannels.splice(index, 1);
              }

              dispatch(
                createAction('createMessage/updateMessageState')({
                  messageType: messageType,
                  channels: selectedChannels,
                  displayChannels: selectedChannels.toString(),
                }),
              );
            }}
          />
        ))}
      </div>
    );
  };

  const relatedCampaigns = () => {
    return (
      <CampaignSelectDropdown
        title={'Related campaign (optional)'}
        isPrompt
        defaultValue={{ label: relatedCampaign?.name, value: relatedCampaign }}
        setValue={(value) => {
          dispatch(
            createAction('createMessage/updateMessageState')({
              relatedCampaign: value,
              relatedCampaignName: value.name,
            }),
          );
        }}
      />
    );
  };

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Message channel</label>
          {messageChannelArea()}
          {
            channels.includes(MessageChannel.informationBar) ?
              <CustomTitleWithRadio
                title={'Message Type'}
                options={[
                  { label: InformationBarMessageType.maintenance, value: InformationBarMessageType.maintenance },
                  { label: InformationBarMessageType.others, value: InformationBarMessageType.others },
                ]}
                defaultValue={messageType}
                setValue={(value) => {
                  let messageType = 'Information Bar - Maintenance'

                  if (value === InformationBarMessageType.others) {
                    messageType = 'Information Bar - Others'
                  }

                  dispatch(
                    createAction('createMessage/updateMessageState')({
                      messageType: value,
                      displayMessageType: messageType
                    }),
                  );
                }}
              /> :

              <CustomTitleWithRadio
                title={'Message Type'}
                options={[
                  { label: 'General Message', value: MessageType.generalMessage },
                  { label: 'Welcome Message', value: MessageType.welcomeMessage },
                ]}
                defaultValue={messageType}
                setValue={(value) => {
                  dispatch(
                    createAction('createMessage/updateMessageState')({
                      messageType: value,
                      displayMessageType:
                        value === MessageType.welcomeMessage
                          ? 'Welcome message'
                          : 'General Message',
                    }),
                  );
                }}

                tips={
                  messageType === MessageType.welcomeMessage &&
                    latestWelcomeMessageId >= 0
                    ? 'Specific message to new sign up member, only can have one, if you already have one welcome message and check the second as a welcome message the second one will replace the previous one'
                    : ''
                }
              />
          }
          {/* <CustomTitleLabel title="Message Type" />
          <label className="tips-message">
            Specific message to new sign up member, only can have one, if you
            already have one welcome message and check the second as a welcome
            message the second one will replace the previous one
          </label>
          <CheckboxItem
            title={'Welcome message'}
            defaultValue={messageType === MessageType.welcomeMessage}
            onChange={(event) => {
              let mType = MessageType.generalMessage;
              if (event.target.checked) {
                mType = MessageType.welcomeMessage;
              }

              dispatch(
                createAction('createMessage/updateMessageState')({
                  messageType: mType,
                }),
              );
            }}
          /> */}

          {messageType === MessageType.welcomeMessage ||
            messageType === InformationBarMessageType.maintenance ||
            messageType === InformationBarMessageType.others
            ? null
            : relatedCampaigns()}
        </div>
      )}
    </>,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        disabledContinue={channels.length === 0 || messageType === null}
        continueAction={() => {
          dispatch(
            createAction('createMessage/stepChange')({
              step: 0,
            }),
          );
        }}
      />
    </>
  );
}

export default CreateMessageStepOne;
