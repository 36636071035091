import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateGreetingSectionError } from '../../models/GreetingSectionModel';
import { isShowError } from '../../utils';
import CustomRadios from '../base/CustomRadios';
import { Row } from 'react-bootstrap';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { MissionCardStyleType } from '../../config/CustomEnums';

const MissionCardStyleOptions = [
    {
        label: 'Yellow',
        value: MissionCardStyleType.YELLOW,
    },
    {
        label: 'Green',
        value: MissionCardStyleType.GREEN,
    },
    {
        label: 'Sky',
        value: MissionCardStyleType.SKY,
    },
];


function MissionCardStyleSection({
    missionCardStyle,
    errorFields,
}) {
    const dispatch = useDispatch();

    const updateGreetingSectionDetail = (payload) => {
        dispatch({
            type: 'greetingSection/updateGreetingSectionDetail',
            payload,
        });
    }

    return (
        <>
            <div className="first-section-title">Mission card style</div>
            <div>
                <label className="create-section-label create-section-label-bottom-space">
                    Mission card style
                </label>
                <Row className="featured-exclusive-row">
                    <CustomRadios
                        disabled={false}
                        onChange={(value) => {
                            updateGreetingSectionDetail({ missionCardStyle: value })
                        }}
                        default={missionCardStyle}
                        options={MissionCardStyleOptions}
                    />
                </Row>
                <ErrorFieldMessage
                    id={CreateGreetingSectionError.backgroundImageStyle.name}
                    error={isShowError(
                        CreateGreetingSectionError.backgroundImageStyle.name,
                        errorFields,
                    )}
                    message={CreateGreetingSectionError.backgroundImageStyle.message}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    missionCardStyle: state.greetingSection.greetingSection.missionCardStyle,
    errorFields: state.greetingSection.errorFields,
});

export default connect(mapStateToProps)(MissionCardStyleSection);
