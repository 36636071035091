import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import RewardCodeOverview from './RewardCodeOverview';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { getHashKeyString } from '../../../utils';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ImportResource } from '../../../models/DownloadImportModel';
import { RewardCodeType } from '../../../models/RewardCodeModel';
import RewardSingleCode from './RewardSingleCode';

const queryString = require('query-string');

export const URLQueryKeys = {
  searchKey: 'searchKey',
  rank: 'rank',
  page: 'page',
};

export const URLFragments = {
  overview: 'Overview',
  singleCode: 'Code generated',
};

const RewardCodeDetail = ({
  rewardCodeDetail,
  importedFile,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [buttons, setButtons] = useState([]);
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    dispatch({
      type: 'rewardCode/getRewardCode',
      payload: {
        id,
        afterAction: () => {
          dispatch({
            type: 'rewardSingleCode/getCurrentPageRewardSingleCodes',
            payload: {
              inRewardCodeSet: id,
            }
          })
        }
      },
    });
    return () => {
      dispatch({ type: 'rewardCode/clearState' });
    };
  }, []);

  useEffect(() => {
    if (importedFile) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.rewardSingleCode,
          file: importedFile,
          related: `${id}`,
        }
      });
    }
  }, [dispatch, importedFile]);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    dispatch({
      type: 'uploadFiles/uploadFile',
      payload: {
        file: fileUploaded,
        source: event,
      }
    });
    event.target.value = null;
  };

  const tabs = [
    {
      name: URLFragments.overview,
      content: <RewardCodeOverview />,
    },
    {
      name: URLFragments.singleCode,
      content: <RewardSingleCode />,
    }
  ];

  const defaultActiveKey =
    getHashKeyString(history.location.hash) || URLFragments.overview;


  const getRewardCodeButtons = () => {
    const rewardCodeButtons = [
      rewardCodeDetail?.pk && rewardCodeDetail.codeType === RewardCodeType.UNIQUE_CODE.value
        ? <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Import CSV"
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />
        : <></>,
      <AuthButton
        title="Edit"
        action={() => {
          history.push({
            pathname: `/reward_code/${id}/edit`,
          });
        }}
        requires={PermissionCodes.changeRewardCode}
      />,
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept=".csv"
      />,
    ]

    return rewardCodeButtons;
  };

  useEffect(() => {
    setButtons(getRewardCodeButtons())
  }, [rewardCodeDetail.pk])


  return (
    <CustomListComponent
      breadcrumb={<CustomBreadcrumb name={rewardCodeDetail.name} />}
      defaultActiveKey={defaultActiveKey}
      onTabChange={(key) => {
        let search = '';
        if (key !== URLFragments.overview) {
          search = queryString.stringify({
            [URLQueryKeys.page]: 1,
            [URLQueryKeys.searchKey]: '',
            [URLQueryKeys.rank]: true,
          });
        }
        history.push({
          pathname: `/reward_code/${id}/`,
          hash: key,
          search: search,
        });
      }}
      buttons={buttons}
      tabs={tabs}
      title={rewardCodeDetail.name}
    />
  );
}

const mapStateToProps = (state) => ({
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
  importedFile: state.uploadFiles.importedFile,
});

export default connect(mapStateToProps)(RewardCodeDetail);
