import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateGreetingCardError, greetingCardThemeColorList } from '../../models/GreetingCardModel';
import { isShowError } from '../../utils';
import CustomInputWithTitle from '../customer/CustomInputWithTitle';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function GeneralSection({
  greetingCardName,
  themeColor,
  errorFields,
}) {
  const dispatch = useDispatch();

  const updateGreetingCardDetail = (payload) => {
    dispatch({
      type: 'greetingCard/updateGreetingCardDetail',
      payload,
    });
  }

  return (
    <>
      <div className="first-section-title">GENERAL</div>
      <CustomInputWithTitle
        title={'Name'}
        defaultValue={greetingCardName}
        setValue={(value) => {
          updateGreetingCardDetail({ name: value })
        }}
        error={{
          id: 'generalName',
          error: isShowError(CreateGreetingCardError.generalName.name, errorFields),
          message: CreateGreetingCardError.generalName.message
        }}
      />
      <CustomTitleWithDropDown
        isImageField={false}
        needFilter={false}
        title={'Theme color'}
        source={greetingCardThemeColorList}
        defaultValue={themeColor}
        setValue={(value) => {
          updateGreetingCardDetail({ themeColor: value })
        }}
        loadMoreAction={() => { }}
        filterAction={() => { }}
        errors={errorFields}
        errorName={CreateGreetingCardError.themeColor.name}
        errorMessage={CreateGreetingCardError.themeColor.message}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  greetingCardName: state.greetingCard.greetingCard.name,
  themeColor: state.greetingCard.greetingCard.themeColor,
  errorFields: state.greetingCard.errorFields,
});

export default connect(mapStateToProps)(GeneralSection);
