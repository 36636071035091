import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getPagedAdminGroups = (afterCursor, rank, search, moreSearch) => {
  let order = rank ? '-pk' : 'pk';
  if (moreSearch?.sort) {
    order = moreSearch.sort;
  }
  const searchKey = search || '';

  const query = `
  {
    administratorGroups(first: 20, after: "${afterCursor}", orderBy: "${order}", nameIcontains: "${searchKey}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          administrators {
            edges {
              node {
                username
              }
            }
          }
          permissions {
            edges {
              node {
                id
                pk
                name
                codename
              }
            }
          }
        }
      }
    }
  }
  `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateAdminGroup = (pk) => {
  const query = ``;
  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteAdminGroup = (pks) => {
  const query = `
  mutation DeleteAdministratorGroups($input: DeleteAdministratorGroupsInput!) {
    deleteAdministratorGroups(input: $input) {
     success
   }
 }

 `;

  const variables = {
    input: {
      ids: pks,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneAdminGroup = (id) => {
  const query = `
  {
    administratorGroup(id: "${id}") {
      id
      pk
      name
      administrators {
        edges {
          node {
            username
          }
        }
      }
      permissions {
        edges {
          node {
            pk
            name
            codename
          }
        }
      }
    }
  }
  `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateOrCreateAdminGroup = (value) => {
  let query = `
  mutation CreateAdministratorGroup($input: CreateAdministratorGroupInput!) {
    createAdministratorGroup(input: $input) {
      node {
        id
        pk
        name
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  if (value.id) {
    query = `
    mutation UpdateAdministratorGroup($input: UpdateAdministratorGroupInput!) {
      updateAdministratorGroup(input: $input) {
        node {
          id
          pk
          name
        }
        errors {
          field
          messages
        }
      }
    }

  `;
  }

  const variables = {
    input: value,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
