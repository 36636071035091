import React, { useEffect, useState } from 'react';
import './MessageList.scss';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import { useSelector, useDispatch } from 'react-redux';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import {
  DELETE_RELATED_SECTIONS,
  MessageTag,
} from '../../../config/CustomEnums';
import { MessageType } from '../../../components/message/CreateMessageConfig';

function MessageList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const {
    pageInfo,
    totalCount,
    displayFields,
    messageList,
    totalPage,
    checkedList,
  } = useSelector((state) => ({
    pageInfo: state.messageList.pageInfo,
    totalCount: state.messageList.totalCount,
    displayFields: state.messageList.listDisplayFields,
    messageList: state.messageList.currentPageMessageList,
    totalPage: state.messageList.totalPage,
    checkedList: state.messageList.checkedList,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('messageList/getCurrentPageMessages')({
        ...searchDict,
        page,
        rank,
        searchKey,
      }),
    );
  }, [dispatch, location, queryString]);

  const buttons = [
    <AuthButton
      title="Create Message"
      action={() => {
        history.push('/messages/create');
      }}
      requires={PermissionCodes.addMessage}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteMessage,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'messageList'}
              specificActions={[
                {
                  fields: ['status'],
                  conditions: [MessageTag.sent],
                  actions: ['Detail', 'Duplicate', 'Delete'],
                },
                {
                  fields: ['isLatestWelcomeMessage'],
                  conditions: [true],
                  actions: ['Edit', 'Detail', 'Delete'],
                },
                {
                  fields: ['isLatestWelcomeMessage'],
                  conditions: [false],
                  actions: ['Detail', 'Delete'],
                },
              ]}
              permissionGroup={PermissionCodes.message}
              dataList={messageList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'message',
                relatedName: 'channels',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.MESSAGE,
              }}
              actions={['Edit', 'Detail', 'Duplicate', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Create a message to get in touch with  your customers. You can send a message through different channels.',
          title: 'Messages',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        relatedSections={DELETE_RELATED_SECTIONS.MESSAGE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('messageList/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Message'}
        relatedName={'channels'}
      />
    </>
  );
}

export default MessageList;
