import React, { useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import {
  RewardCodeCategory,
  RewardCodeType,
  RewardCodeRewardType,
} from '../../../models/RewardCodeModel';
import { ArrayStringData } from '../../../utils';


function Filter({ 
  brandList = [],
  backAction = () => { },
}) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchRewardType = parsedSearch['reward_type'] || '';
  const searchExpired = parsedSearch['expired'] || '';
  const searchStatus = parsedSearch['status'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchCodeType = parsedSearch['code_type'] || '';
  const searchActivePeriod = parsedSearch['active_period'] || '';

  const [rewardType, setRewardType] = useState(searchRewardType);
  const [expired, setExpired] = useState(searchExpired);
  const [status, setStatus] = useState(searchStatus);
  const [brand, setBrand] = useState(ArrayStringData(searchBrand));
  const [codeType, setCodeType] = useState(searchCodeType);
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);

  const content = [
    {
      title: 'Reward type',
      data: [
        { pk: RewardCodeRewardType.POINTS.value, name: RewardCodeRewardType.POINTS.label },
        { pk: RewardCodeRewardType.COUPONS.value, name: RewardCodeRewardType.COUPONS.label },
      ],
      value: rewardType,
      setValue: setRewardType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Expired',
      data: [
        { name: 'Unexpired', pk: 'false' },
        { name: 'Expired', pk: 'true' },
      ],
      value: expired,
      setValue: setExpired,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Brand',
      data: brandList,
      value: brand,
      setValue: setBrand,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Code type',
      data: [
        { pk: RewardCodeType.GENERIC_CODE.value, name: RewardCodeType.GENERIC_CODE.label },
        { pk: RewardCodeType.UNIQUE_CODE.value, name: RewardCodeType.UNIQUE_CODE.label },
      ],
      value: codeType,
      setValue: setCodeType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

const mapStateToProps = (state) => ({
  brandList: state.brand.brandList,
});

export default connect(mapStateToProps)(Filter);
