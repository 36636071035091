import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleWithDropDown } from '../../../components/customer/CustomTitleWithDropDown';

function CommunityFilterableDropdown(props) {
  const dispatch = useDispatch();
  const selectedObject = props.selectedObject || (() => {});
  const defaultSelectedObject = props.defaultSelectedObject;
  const title = props.title;
  const titleTips = props.titleTips || '';
  const { communityList } = useSelector((state) => ({
    communityList: state.communityModel.communityList,
  }));
  useEffect(() => {
    dispatch({
      type: 'communityModel/getList',
      payload: {},
    });
  }, []);

  return (
    <>
      <CustomTitleWithDropDown
        title={title}
        titleTips={titleTips}
        source={communityList}
        defaultValue={defaultSelectedObject}
        setValue={selectedObject}
        loadMoreAction={'communityModel/getList'}
        filterAction={'communityModel/getList'}
        showRemoveButton={!!props.onRemoveButtonClick}
        onRemoveButtonClick={props.onRemoveButtonClick}
      />
    </>
  );
}

export default CommunityFilterableDropdown;
