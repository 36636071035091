import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from 'moment';
import { CustomTitleLabel } from './CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import { useCompare } from '../../utils';
import { TimeFormater } from '../../utils/TimeFormatUtil';

const { RangePicker } = DatePicker;

function BirthdaySection(props) {
  const dateFormat = TimeFormater.yearMonth;

  const defaultValue = props.defaultValue || {};

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));

  const [birthStart, setStartDate] = useState(defaultValue.birthStart);
  const [birthEnd, setEndDate] = useState(defaultValue.birthEnd);
  const vals = { birthStart, birthEnd };
  const hasValsChanged = useCompare(vals);
  const dispatch = useDispatch();

  const onChange = (dates, dateStrings) => {
    const [start, end] = dates;
    setStartDate(start?.format(dateFormat));
    setEndDate(end?.format(dateFormat));
  };

  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.birthday.name}`, errorFields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">BIRTHDAY</label>
      <CustomTitleLabel title="Birthday Period" />

      <RangePicker
        picker="month"
        defaultValue={[
          birthStart ? moment(birthStart, dateFormat) : null,
          birthEnd ? moment(birthEnd, dateFormat) : null,
        ]}
        defaultPickerValue={[
          birthStart ? moment(birthStart, dateFormat) : null,
          birthEnd ? moment(birthEnd, dateFormat) : null,
        ]}
        className="date-picker"
        format={dateFormat}
        separator={'-'}
        onChange={onChange}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.birthday.name}`}
        error={isError('birthday')}
        message={`${EarningRuleErrorHandleField.birthday.message}`}
      />
    </>
  );
}

export default BirthdaySection;
