import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import MessageOverviewTableSection from '../../../components/message/MessageOverviewTableSection';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { APIStatus, MessageTag } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import WhatToDoNextGeneralOverviewCard from '../../../components/whatToDoNext/WhatToDoNextGeneralOverviewCard';
import WhatToDoNextKeyVisualOverviewCard from '../../../components/whatToDoNext/WhatToDoNextKeyVisualOverviewCard';
import { WhatToDoNextKeyVisualSections } from '../../../config/CustomEnums'
import { URLQueryKeys } from './CreateWhatToDoNext';

function WhatToDoNextOverview() {
  const queryString = require('query-string');
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { status, whatToDoNextKey } = useSelector((state) => ({
    status: state.createMessage.createStatus,
    whatToDoNextKey: state.whatToDoNextList.whatToDoNext.key,
  }));

  const isKeyVisualVisible = WhatToDoNextKeyVisualSections.includes(whatToDoNextKey)

  useEffect(() => {
    return () => {
      dispatch(createAction('whatToDoNextList/clearData')());
    };
  }, [dispatch]);

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              sections={[
                <WhatToDoNextGeneralOverviewCard />,
                isKeyVisualVisible && <WhatToDoNextKeyVisualOverviewCard />,
              ]}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => history.push('edit/')}
      requires={PermissionCodes.changeMessage}
    />
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: 'What to do next',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
      <OnlyBackButton
        backAction={() => {
          dispatch(createAction('whatToDoNextList/clearData')());
          history.push({
            pathname: '/what_to_do_next',
            search: queryString.stringify({
              [URLQueryKeys.page]: 1,
              [URLQueryKeys.rank]: true,
              [URLQueryKeys.sort]: 'displayPriority',
            }),
          });
        }}
      />
    </>
  );
}

export default WhatToDoNextOverview;
