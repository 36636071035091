import React from 'react';
import './ErrorFieldMessage.scss';

function ErrorFieldMessage(props) {
  return props.error ? (
    <label id={props.id} className={`error-field-message-style ${props.extra}`}>
      {props.message}
    </label>
  ) : null;
}

export default ErrorFieldMessage;
