import React, { useEffect, useState } from 'react';
import ContentSections from '../base/ContentSections';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import { useSelector, useDispatch } from 'react-redux';
import MessageOverviewTableSection from './MessageOverviewTableSection';
import {
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import { createAction } from '../../utils';
import TestAccountPrompt from './TestAccountPrompt';
import CreateMessageSuccessPrompt from './CreateMessageSuccessPrompt';
import { useLocation } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import ListButtonsGroup from '../base/ListButtonsGroup';
import AuthButton from '../base/AuthButton';
import { MessageType } from './CreateMessageConfig';

function CreateMessageStepFour() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { message, latestWelcomeMessageId } = useSelector((state) => ({
    message: state.createMessage.message,
    latestWelcomeMessageId: state.messageList.latestWelcomeMessageId,
  }));

  const [showTestPrompt, setShowTestPrompt] = useState(false);
  const [showSentPrompt, setShowSentPrompt] = useState(false);
  const [isTestSend, setIsTestSend] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [showTimeoutPrompt, setShowTimeoutPrompt] = useState(false);
  const [showWelcomePrompt, setShowWelcomePrompt] = useState(false);

  const sections = [
    <MembershipInfoCard
      title={'PROPERTIES'}
      data={message}
      fields={[
        [{ title: 'Message channel', field: 'displayChannels' }],
        [{ title: 'Message type', field: 'displayMessageType' }],
        [{ title: 'Related campaign', field: 'relatedCampaignName' }],
        [{ title: 'Target customers', field: 'displayTargetCustomer' }],
        [{ title: 'Target segments', field: 'displayTargetedSegments' }],
        [{ title: 'Delivery Time', field: 'deliveryDate' }],
      ]}
    />,
    <MessageOverviewTableSection data={message} />,
  ];

  const saveButtonAction = (isSaveOnly) => {
    if (
      message.messageType !== MessageType.welcomeMessage &&
      new Date(message.scheduledDate) < new Date()
    ) {
      setShowTimeoutPrompt(true);
      return;
    }
    setIsDraft(isSaveOnly);
    if (location.pathname.includes('edit')) {
      dispatch(
        createAction('createMessage/updateMessage')({
          isDraft: isSaveOnly,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(true);
          },
        }),
      );
    } else {
      dispatch(
        createAction('createMessage/createMessage')({
          isDraft: isSaveOnly,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(isSaveOnly);
          },
        }),
      );
    }
  };

  const getButtonList = () => {
    return message?.messageType === MessageType.welcomeMessage
      ? {
          extraButtons: [
            <SaveTempButton
              text="Test Channel"
              action={() => {
                setShowTestPrompt(true);
              }}
            />,
          ],
          extraPopContent: [
            {
              content: 'Test Channel',
              action: () => {
                setShowTestPrompt(true);
              },
              requires: PermissionCodes.testMessage,
            },
          ],
          primaryButton: (
            <SaveButton
              text="Save Only"
              action={() => {
                if (latestWelcomeMessageId >= 0) {
                  setShowWelcomePrompt(true);
                } else {
                  saveButtonAction(true);
                }
              }}
            />
          ),
          primaryPopContent: {
            requires: PermissionCodes.addAdministratorGroup,
            action: () => {
              if (latestWelcomeMessageId >= 0) {
                setShowWelcomePrompt(true);
              } else {
                saveButtonAction(true);
              }
            },
            content: 'Save Only',
          },
        }
      : {
          extraButtons: [
            <SaveTempButton
              text="Test Channel"
              action={() => {
                setShowTestPrompt(true);
              }}
            />,
            <SaveTempButton
              text="Save Only"
              action={() => saveButtonAction(true)}
            />,
          ],
          extraPopContent: [
            {
              content: 'Test Channel',
              action: () => {
                setShowTestPrompt(true);
              },
              requires: PermissionCodes.testMessage,
            },
            {
              content: 'Save Only',
              action: () => saveButtonAction(true),
            },
          ],
          primaryButton: (
            <SaveButton
              text="Schedule"
              action={() => saveButtonAction(false)}
            />
          ),
          primaryPopContent: {
            requires: PermissionCodes.addAdministratorGroup,
            action: () => saveButtonAction(false),
            content: 'Schedule',
          },
        };
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <BackAndMultipleButtons
        backAction={() => {
          dispatch(
            createAction('createMessage/stepChange')({
              step: message?.messageType === MessageType.welcomeMessage ? 2 : 3,
              isBack: true,
            }),
          );
        }}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            {...getButtonList()}
          />,
        ]}
      />
      <TestAccountPrompt
        show={showTestPrompt}
        onHide={() => setShowTestPrompt(false)}
        onConfirm={(selectedAccount) => {
          setShowTestPrompt(false);
          dispatch(
            createAction('createMessage/testMessageSend')({
              isEdit: location.pathname.includes('edit'),
              customer: selectedAccount,
              afterActions: () => {
                setIsTestSend(true);
                setShowSentPrompt(true);
              },
            }),
          );
        }}
      />
      <CreateMessageSuccessPrompt
        isTest={isTestSend}
        isDraft={isDraft}
        show={showSentPrompt}
        onClose={() => setShowSentPrompt(false)}
      />

      <BasePrompt
        show={showTimeoutPrompt}
        closeAction={() => setShowTimeoutPrompt(false)}
        rightButton={{
          text: 'Reschedule',
          action: () => {
            setShowTimeoutPrompt(false);
            dispatch(
              createAction('createMessage/updateState')({
                currentStep: 2,
              }),
            );
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowTimeoutPrompt(false),
        }}
        title={'Schedule timeout'}
        description={
          'Your schecule time is expired, please update delivery time.'
        }
      />

      <BasePrompt
        show={showWelcomePrompt}
        closeAction={() => setShowWelcomePrompt(false)}
        rightButton={{
          text: 'Replace',
          action: () => {
            setShowWelcomePrompt(false);
            saveButtonAction(true);
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowWelcomePrompt(false);
            dispatch({
              type: 'createMessage/updateMessageState',
              payload: { messageType: MessageType.generalMessage },
            });
            dispatch({
              type: 'createMessage/updateState',
              payload: { currentStep: 0 },
            });
          },
        }}
        title={'Warning'}
        description={
          'You have created a welcome message, if you are sure to check this message as a welcome message, it will be replaced your previous welcome message.'
        }
      />
    </>
  );
}

export default CreateMessageStepFour;
