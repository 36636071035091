import {
  LanguageConfig,
  CampaignType,
  APIStatus,
  EarningRuleType,
  CategoryType,
  CampaignHomeSection,
} from '../config/CustomEnums';
import {
  createCampaign,
  publishCampaign,
  getCampaign,
  unPublishCampaign,
  updateCampaign,
  deleteCampaigns,
  getOneEarningRule,
  getOneCoupon,
} from '../services/CampaignAPIHelper';
import { CampaignErrorHandleField } from '../components/campaign/campaignCreation/CreateCampaignHandleError';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  createAction,
  delay,
  addDomainToImage,
  convertPKToId,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';
import {
  CREATE_CAMPAIGN_CATEGORY_FAILD,
  CREATE_CAMPAIGN_CATEGORY_SUCCESS,
} from './CreateCampaignCategoryModel';
import { getTypeDisplay } from './CampaignListModel';
import { isValidHttpUrl } from '../utils/CheckValidUtil';
import { isWithinInterval, isBefore } from 'date-fns';

export const sessionDataKey = {
  objectKey: 'createCampaign',
  stepEndKey: 'createCampaignStepEnd',
  origionalData: 'createCampaignOriginalData',
};

export const GENERAL_TYPE = {
  customerGroup: 'Customer group',
  segment: 'Segment',
  // level: 'Level',
};

export const TARGET_USERS = {
  all: 'All users',
  withTarget: 'Target customer groups or segments, or both',
};

const stepNames = ['Type', 'Content', 'Properties', 'Preview'];

function checkStepTwoFields(data, translations, isBack) {
  const generalName = data[LanguageConfig.english].generalName;
  const coverPhoto = data[LanguageConfig.english].coverPhoto;
  const isExclusive = data.isExclusive;
  const square = data[LanguageConfig.english].squareCoverPhoto;
  const shortDescription = data[LanguageConfig.english].shortDescription;
  const brand = data[LanguageConfig.english].brand;
  const enTag = data[LanguageConfig.english].campaignTag;
  const tcTag = data[LanguageConfig.traditionalChinese].campaignTag;
  const scTag = data[LanguageConfig.simplifiedChinese].campaignTag;
  const adLink = data[LanguageConfig.english].adLink;
  const location = data[LanguageConfig.english].location;
  const errorFields = [];
  if (!generalName) {
    errorFields.push(CampaignErrorHandleField.generalName.name);
  }
  if (!shortDescription) {
    errorFields.push(CampaignErrorHandleField.shortDescription.name);
  }
  if (location) {
    if (!location.name) {
      errorFields.push(CampaignErrorHandleField.locationName.name);
    }
    const latLong = ['longitude', 'latitude']
    latLong.forEach((fieldName) => {
      if (!location[fieldName]) {
        errorFields.push(CampaignErrorHandleField?.[`location${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`].name);
      } else {
        const errorFieldName = `location${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`
        if (location[fieldName]?.split('.')[0]?.length > 3) {
          errorFields.push(
            CampaignErrorHandleField[`${errorFieldName}LimitBeforeDot`].name,
          );
        } else if (location[fieldName]?.split('.')[1]?.length > 6) {
          errorFields.push(
            CampaignErrorHandleField[`${errorFieldName}LimitAfterDot`].name,
          );
        }
      }
    })
    if (!location.radius) {
      errorFields.push(CampaignErrorHandleField.locationRadius.name);
    }
  };
  const invalidBrand = brand?.length > 2;
  if (invalidBrand && data.campaignType === CampaignType.couponsCampaign) {
    errorFields.push(CampaignErrorHandleField.relatedBrand.name);
  };
  const invalidEnTag = enTag?.filter((item) => item?.length > 50)?.length;
  const invalidTcTag = tcTag?.filter((item) => item?.length > 26)?.length;
  const invalidScTag = scTag?.filter((item) => item?.length > 26)?.length;
  console.log('brand:', brand);
  if (
    enTag?.length > 4 ||
    tcTag?.length > 4 ||
    scTag?.length > 4 ||
    invalidEnTag ||
    invalidTcTag ||
    invalidScTag
  ) {
    errorFields.push(CampaignErrorHandleField.campaignTag.name);
  }
  if (!coverPhoto) {
    errorFields.push(CampaignErrorHandleField.coverPhoto.name);
  }
  if (isExclusive && !square) {
    errorFields.push(CampaignErrorHandleField.squarePhoto.name);
  }

  if (adLink?.length) {
    if (!isValidHttpUrl(adLink)) {
      errorFields.push(CampaignErrorHandleField.adLink.name);
    }
  };

  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data: {
      linkedBrands: brand,
      location: {
        ...location,
        translations: {
          [LanguageConfig.traditionalChinese]: {
            ...data[LanguageConfig.traditionalChinese]?.location,
          },
          [LanguageConfig.simplifiedChinese]: {
            ...data[LanguageConfig.simplifiedChinese]?.location,
          }
        }
      },
      translations: {
        [LanguageConfig.english]: {
          ...translations[LanguageConfig.english],
          ...data[LanguageConfig.english],
        },
        [LanguageConfig.traditionalChinese]: {
          ...translations[LanguageConfig.traditionalChinese],
          ...data[LanguageConfig.traditionalChinese],
        },
        [LanguageConfig.simplifiedChinese]: {
          ...translations[LanguageConfig.simplifiedChinese],
          ...data[LanguageConfig.simplifiedChinese],
        },
      },
    },
  };
}

function checkStepThreeFields(data, isBack, isExclusive, isMonthlyOrWeekly) {
  const {
    activeStartDate,
    activeEndDate,
    visibleStartDate,
    visibleEndDate,
    campaignType,
    requiredPoints,
    discount,
    discountRequiredPoints,
    discountStartDate,
    discountEndDate,
    discountTargetedUser,
    discountTargetedCustomerGroups,
    discountTargetedCustomerSegments,
    isAlwaysActivePeriod,
    isAlwaysVisiblePeriod,
    customerGroup,
    segments,
    generalType,
    couponDisplayPriority,
  } = data;

  const errorFields = [];
  if (isExclusive) {
    if (
      generalType === GENERAL_TYPE.customerGroup &&
      customerGroup?.length === 0
    ) {
      errorFields.push(CampaignErrorHandleField.customerGroup.name);
    }
    if (generalType === GENERAL_TYPE.segment && segments?.length === 0) {
      errorFields.push(CampaignErrorHandleField.customerGroup.name);
    }
  }

  if (
    !isAlwaysActivePeriod &&
    activeStartDate.getTime() >= activeEndDate.getTime()
  ) {
    console.log('@@123');
    errorFields.push(CampaignErrorHandleField.activeEndDate.name);
  }
  if (
    !isAlwaysVisiblePeriod &&
    visibleStartDate.getTime() >= visibleEndDate.getTime()
  ) {
    console.log('@@130');
    errorFields.push(CampaignErrorHandleField.visibleEndDate.name);
  }

  if (
    campaignType === CampaignType.couponsCampaign &&
    requiredPoints !== 0
  ) {
    if (!requiredPoints) {
      errorFields.push(CampaignErrorHandleField.requiredPoints.name);
    }
    else if (!Number.isInteger(Number(requiredPoints)) || Number(requiredPoints) < 0) {
      errorFields.push(CampaignErrorHandleField.requiredPointsWholeNumber.name);
    }
  }
  if (
    campaignType === CampaignType.couponsCampaign &&
    (!couponDisplayPriority || couponDisplayPriority < 1)
  ) {
    errorFields.push(CampaignErrorHandleField.couponDisplayPriority.name);
  }
  //  discount validation
  if (discount) {
    if (discountRequiredPoints !== 0) {
      if (!discountRequiredPoints) {
        errorFields.push(CampaignErrorHandleField.requiredPointsDiscountedRequired.name);
      }
      if (!Number.isInteger(Number(discountRequiredPoints)) || Number(discountRequiredPoints) < 0) {
        errorFields.push(CampaignErrorHandleField.requiredPointsDiscountedWholeNumber.name);
      } else if (Number(discountRequiredPoints) >= Number(requiredPoints)) {
        errorFields.push(CampaignErrorHandleField.requiredPointsDiscountedAmount.name);
      }
    }
    if (!isBefore(discountStartDate, discountEndDate)) {
      errorFields.push(CampaignErrorHandleField.discountStartEndDate.name)
    }
    if (!isAlwaysActivePeriod && isBefore(activeStartDate, activeEndDate) && (!isWithinInterval(discountStartDate, { start: activeStartDate, end: activeEndDate }) || !isWithinInterval(discountEndDate, { start: activeStartDate, end: activeEndDate }))) {
      errorFields.push(CampaignErrorHandleField.discountStartEndDateWithin.name)
    }
    if (!discountTargetedUser) {
      errorFields.push(CampaignErrorHandleField.discountTargetedUser.name);
    }
    if (discountTargetedUser == TARGET_USERS.withTarget && (discountTargetedCustomerGroups === null || discountTargetedCustomerGroups.length == 0) && (discountTargetedCustomerSegments === null || discountTargetedCustomerSegments.length == 0)) {
      errorFields.push(CampaignErrorHandleField.discountTargetedUserGroup.name);
    }
  }

  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data: { ...data },
  };
}

function getTranlationDataForCreateCampaign(campaign, language, update) {
  let languageField = {};
  const translation = campaign?.translations?.[language] || {};
  if (language !== LanguageConfig.english) {
    languageField = {
      language: language,
    };
    if (translation.pk && update) {
      languageField['id'] = translation.pk;
    }
  }

  const result = {
    ...languageField,
    shortDescription: translation.shortDescription,
    name: translation.generalName,
    campaignTag:
      translation.campaignTag?.filter((item) => !!item?.length)?.join(',') ||
      null,
    instructionSectionTitle: translation.instructionSectionTitle,
    instructionSectionContent: translation.instructionSectionContent,
    detailSectionTitle: translation.detailSectionTitle,
    detailSectionContent: translation.detailSectionContent,
    coverPhoto: getFileNameFromUrl(translation.coverPhoto) || null,
  };
  delete result['location']
  if (translation.squareCoverPhoto) {
    result['squareCoverPhoto'] = getFileNameFromUrl(
      translation.squareCoverPhoto,
    );
  }

  if (translation.detailPhotoOne) {
    result['detailPhoto1'] = getFileNameFromUrl(translation.detailPhotoOne);
  }
  if (translation.detailPhotoTwo) {
    result['detailPhoto2'] = getFileNameFromUrl(translation.detailPhotoTwo);
  }
  if (translation.detailPhotoThree) {
    result['detailPhoto3'] = getFileNameFromUrl(translation.detailPhotoThree);
  }
  if (translation.detailPhotoFour) {
    result['detailPhoto4'] = getFileNameFromUrl(translation.detailPhotoFour);
  }
  return result;
}

function getDataForCreateCampaign(campaign, update) {
  const categories = campaign.categories?.map((category) => category.value.pk);
  let targetGroup = [];
  let segments = [];
  const generalType = campaign.generalType;
  if (campaign.isExclusive && generalType) {
    const groupLength = campaign.customerGroup?.length;
    const segmentsLength = campaign.segments?.length;
    if (generalType === GENERAL_TYPE.customerGroup && groupLength) {
      targetGroup = campaign.customerGroup?.map((group) => group.value.pk);
    } else if (generalType === GENERAL_TYPE.segment && segmentsLength) {
      segments = campaign.segments?.map((segment) => segment.value.pk);
    }
  }
  let earningRule = {};
  let coupon = {};
  let generalMessage = {};
  let location = null;
  if (campaign.campaignType === CampaignType.couponsCampaign) {
    coupon = {
      couponCampaignTypeCouponTemplate: campaign.linkedCoupon.pk,
      couponCampaignTypeShouldShowCouponStock: campaign.displayOverLimit,
      couponCampaignTypeOverallLimit: campaign.overallLimit,
      couponCampaignTypePerHeadLimit: campaign.perHeadLimit,
      couponCampaignTypeRequiredPoints: campaign.requiredPoints,
      discount: campaign.discount,
      isDiscountForAllUser: campaign.isDiscountForAllUser,
      discountRequiredPoints: campaign.discountRequiredPoints,
      discountStartDate: campaign.discountStartDate,
      discountEndDate: campaign.discountEndDate,
      discountTargetedCustomerGroups: campaign.discountTargetedCustomerGroups.map(targetGroup => targetGroup.value.pk),
      discountTargetedCustomerSegments: campaign.discountTargetedCustomerSegments.map(segment => segment.value.pk),
    };
  }
  if (campaign.campaignType === CampaignType.earningRulesCampaign) {
    earningRule = {
      earningCampaignTypeEarningRule: campaign.linkedEarningRules.pk,
    };
  }
  if (campaign.campaignType === CampaignType.generalMessageCampaign) {
    generalMessage = {
      homeSection: campaign.homeSection === CampaignHomeSection.redeemSection.value
        ? CampaignHomeSection.redeemSection.value
        : CampaignHomeSection.earnSection.value
    }
  }
  if (campaign.linkedEarningRules.type === EarningRuleType.qrCodeRecyclingExtraRewards) {
    location = campaign.location
    location.translations = []

    const zh = [LanguageConfig.traditionalChinese, LanguageConfig.simplifiedChinese];
    zh.forEach((lang) => {
      const zhLocation = campaign?.translations?.[lang]?.location
      if (zhLocation && zhLocation.name) {
        let zhLocationTranslation = { language: lang, name: zhLocation.name };
        if (update) {
          zhLocationTranslation['id'] = zhLocation.pk
        }
        location.translations?.push(zhLocationTranslation)
      }
    })
  }
  const formattedTranslation = getTranlationDataForCreateCampaign(
    campaign,
    LanguageConfig.english,
    update,
  );

  const traditionalChinese = getTranlationDataForCreateCampaign(
    campaign,
    LanguageConfig.traditionalChinese,
    update,
  );
  const simplifiedChinese = getTranlationDataForCreateCampaign(
    campaign,
    LanguageConfig.simplifiedChinese,
    update,
  );

  const result = {
    type: campaign.campaignType,
    categories: categories,
    targetedCustomerGroups: targetGroup,
    targetedSegments: segments,
    displayInDiscover: campaign.displayInDiscover || false,
    displayInHome: campaign.displayInHome || false,
    isFeatured: campaign.isFeatured || false,
    isExclusive: campaign.isExclusive || false,
    isSponsored: campaign.isSponsored || false,
    isSupported: campaign.isSupported || false,
    linkButtonType: campaign.linkButtonType || null,
    adLink: campaign.adLink || null,
    brand: campaign.linkedBrand?.pk,
    brands: campaign.linkedBrands?.map((item) => item.pk) || [],
    // stores: campaign.linkedStores?.map((item) => item.pk),
    translations: [traditionalChinese, simplifiedChinese],
    displayPriority: campaign.displayPriority,
    ...formattedTranslation,
    ...coupon,
    ...earningRule,
    ...generalMessage,
  };
  if (location) {
    result['location'] = location;
  }
  const now = new Date();
  if (!campaign.isAlwaysActivePeriod) {
    result['endDate'] = campaign.activeEndDate;
    result['startDate'] = campaign.activeStartDate;
  } else {
    result['endDate'] = null;
    result['startDate'] =
      update && campaign.activeStartDate < now ? campaign.activeStartDate : now;
  }
  if (!campaign.isAlwaysVisiblePeriod) {
    result['displayEndDate'] = campaign.visibleEndDate;
    result['displayStartDate'] = campaign.visibleStartDate;
  } else {
    result['displayEndDate'] = null;
    result['displayStartDate'] =
      update && campaign.visibleStartDate < now
        ? campaign.visibleStartDate
        : now;
  }
  return result;
}

function getBrandSectionForCampaign(brandSection) {
  if (!brandSection) {
    return { brandSection: {}, linkedBrand: {} };
  }
  // console.log('@@247: ', brandSection);
  return {
    brandSection: {
      id: brandSection.id,
      pk: brandSection.pk,
    },
    linkedBrand: {
      id: brandSection.id,
      pk: brandSection.pk,
      name: brandSection.name,
    },
  };
}

function getCouponAndEarningRuleForCampaign(couponSection) {
  if (!couponSection) {
    return {};
  }
  return {
    ...couponSection,
    id: couponSection.id,
    pk: couponSection.pk,
    name: couponSection.name,
    type: couponSection.type,
  };

}
function getLocationForCampaign(location) {
  if (!location) {
    return null;
  }
  return {
    name: location.name,
    latitude: location.latitude.toString(),
    longitude: location.longitude.toString(),
    radius: location.radius.toString(),
  };
}

function getCategoriesAndTargetGroupForCampaign(section) {
  if (!section.edges.length) {
    return [];
  }
  const targetGroup = section.edges.map((edge) => {
    const target = edge.node;
    return {
      name: target.name,
      value: { ...target },
    };
  });
  return targetGroup;
}

function assembleLocationTranslation(node, language) {
  if (!node) {
    return {};
  }
  if (language == LanguageConfig.english) {
    return {
      name: node.name,
      latitude: node.latitude,
      longitude: node.longitude,
      radius: node.radius,
    }
  }

  const translationEdge = node.translations?.edges?.find((edge) => edge?.node?.language == language)
  if (!translationEdge) {
    return {}
  }
  return {
    id: translationEdge.node?.id,
    pk: translationEdge.node?.pk,
    name: translationEdge.node?.name
  }
}

function translationAssemble(node, isCampaign) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;
  const data = {
    instructionSectionTitle: node.instructionSectionTitle,
    instructionSectionContent: node.instructionSectionContent,
    detailSectionTitle: node.detailSectionTitle,
    detailSectionContent: node.detailSectionContent,
    campaignTag: node.campaignTag?.split(',') || [],
    coverPhoto: addDomainToImage(node.coverPhoto),
    detailPhotoOne: addDomainToImage(node.detailPhoto1),
    detailPhotoTwo: addDomainToImage(node.detailPhoto2),
    detailPhotoThree: addDomainToImage(node.detailPhoto3),
    detailPhotoFour: addDomainToImage(node.detailPhoto4),
  };
  if (isCampaign) {
    data.generalName = node.name;
    data.id = node.id;
    data.pk = node.pk;
    data.shortDescription = node.shortDescription;
    data.squareCoverPhoto = addDomainToImage(node.squareCoverPhoto);
  }
  return {
    [language]: data,
  };
}

export function getTranslationForCampaign(campaign, isCampaign) {
  const enTranslation = translationAssemble(campaign, isCampaign);
  const chineseTranslation = campaign?.translations?.edges.map((item) => {
    return translationAssemble(item.node, isCampaign);
  });
  const reducedTranslation = chineseTranslation?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});

  if (campaign?.location?.name) {
    enTranslation[LanguageConfig.english].location = assembleLocationTranslation(campaign.location, LanguageConfig.english)
    if (campaign?.location?.translations?.edges?.length > 0) {
      const zhHantLocation = assembleLocationTranslation(campaign.location, LanguageConfig.traditionalChinese)
      if (!reducedTranslation[LanguageConfig.traditionalChinese]) {
        reducedTranslation[LanguageConfig.traditionalChinese] = { location: zhHantLocation }
      } else {
        reducedTranslation[LanguageConfig.traditionalChinese].location = zhHantLocation
      }
    }
  }


  return {
    ...enTranslation,
    ...reducedTranslation,
  };
}

function getMillisecondsFromDate(date) {
  if (!date) {
    return '';
  }
  const momentTime = new Date(date);
  return momentTime;
}

function assembleCampaign(campaignData) {
  if (!campaignData) {
    return {};
  }
  campaignData.brandSection = campaignData.brand;

  const linkedCoupon = getCouponAndEarningRuleForCampaign(
    campaignData.couponCampaignTypeCouponTemplate,
  );
  const linkedEarningRules = getCouponAndEarningRuleForCampaign(
    campaignData.earningCampaignTypeEarningRule,
  );
  const categories = getCategoriesAndTargetGroupForCampaign(
    campaignData.categories,
  );
  const targetGroup = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedCustomerGroups,
  );
  const segments = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedSegments,
  );
  const translations = getTranslationForCampaign(campaignData, true);
  const brandSection = getBrandSectionForCampaign(campaignData.brandSection);
  const startTime = getMillisecondsFromDate(campaignData.startDate);
  const endTime = getMillisecondsFromDate(campaignData.endDate);
  const visibleStartTime = getMillisecondsFromDate(
    campaignData.displayStartDate,
  );
  const visibleEndTime = getMillisecondsFromDate(campaignData.displayEndDate);
  const publicationTime = getMillisecondsFromDate(campaignData.publicationDate);
  let generalType = GENERAL_TYPE.customerGroup;
  if (segments && segments.length > 0) {
    generalType = GENERAL_TYPE.segment;
  }
  const displayInDiscover =
    campaignData.type === CampaignType.couponsCampaign
      ? false
      : !campaignData.displayInHome;
  const discountTargetedCustomerGroups = getCategoriesAndTargetGroupForCampaign(campaignData.discountTargetedCustomerGroups)
  const discountTargetedCustomerSegments = getCategoriesAndTargetGroupForCampaign(campaignData.discountTargetedCustomerSegments)
  const location = getLocationForCampaign(campaignData.location)

  return {
    id: campaignData.id,
    pk: campaignData.pk,
    campaignType: campaignData.type,
    displayInDiscover: displayInDiscover || false,
    displayInHome: campaignData.displayInHome || false,
    homeSection: campaignData.homeSection,
    isExclusive: campaignData.isExclusive || false,
    isFeatured: campaignData.isFeatured || false,
    isSponsored: campaignData.isSponsored || false,
    isSupported: campaignData.isSupported || false,
    linkButtonType: campaignData.linkButtonType || null,
    adLink: campaignData.adLink || null,
    isAlwaysActivePeriod: !endTime,
    activeStartDate: startTime,
    activeEndDate: endTime || startTime,
    isAlwaysVisiblePeriod: !visibleEndTime,
    visibleStartDate: visibleStartTime,
    visibleEndDate: visibleEndTime || visibleStartTime,
    publicationDate: publicationTime,
    overallLimit: campaignData.couponCampaignTypeOverallLimit,
    perHeadLimit: campaignData.couponCampaignTypePerHeadLimit,
    requiredPoints: campaignData.couponCampaignTypeRequiredPoints,
    discount: campaignData.discount,
    discountRequiredPoints: campaignData.discountRequiredPoints,
    discountStartDate: campaignData.discountStartDate,
    discountEndDate: campaignData.discountEndDate,
    discountTargetedCustomerGroups: discountTargetedCustomerGroups,
    discountTargetedCustomerSegments: discountTargetedCustomerSegments,
    displayOverLimit: campaignData.couponCampaignTypeShouldShowCouponStock,
    isPublished: campaignData.isPublished,
    linkedBrand: brandSection.linkedBrand,
    brandSection: brandSection,
    linkedBrands: campaignData.brands?.edges?.map((item) => item.node),
    linkedBrandsName: campaignData.brands?.edges
      ?.map((item) => item.node?.name)
      ?.join(', '),
    linkedStores: campaignData.stores?.edges?.map((item) => item.node),
    linkedEarningRules,
    linkedCoupon,
    categories,
    displayPriority: campaignData.displayPriority,
    couponDisplayPriority: campaignData.couponDisplayPriority,
    customerGroup: targetGroup || [],
    translations,
    generalType,
    segments: segments || [],
    typeDisplay: getTypeDisplay(campaignData.type),
    order: campaignData.displayPriority,
    isMonthlyOrWeekly:
      campaignData.type === CampaignType.earningRulesCampaign &&
      (linkedEarningRules.habitTypePeriod ===
        EarningRuleType.monthlyAchievement ||
        linkedEarningRules.habitTypePeriod ===
        EarningRuleType.weeklyAchievement),
    location,
  };
}

function getTranlation() {
  return {
    generalName: '',
    shortDescription: '',
    coverPhoto: '',
    squareCoverPhoto: '',
    detailPhotoOne: '',
    detailPhotoTwo: '',
    detailPhotoThree: '',
    detailPhotoFour: '',
    instructionSectionTitle: '',
    instructionSectionContent: '',
    detailSectionTitle: '',
    detailSectionContent: '',
  };
}

const cmapaignInit = () => ({
  id: null,
  pk: null,
  displayInDiscover: false,
  displayInHome: false,
  isExclusive: false,
  isFeatured: false,
  campaignType: '',
  isAlwaysActivePeriod: false,
  activeStartDate: null,
  activeEndDate: null,
  isAlwaysVisiblePeriod: false,
  visibleStartDate: null,
  visibleEndDate: null,
  overallLimit: null,
  perHeadLimit: null,
  requiredPoints: 0,
  discount: false,
  discountRequiredPoints: 0,
  discountStartDate: null,
  discountEndDate: null,
  discountTargetedUser: null,
  discountTargetedCustomerGroups: [],
  discountTargetedCustomerSegments: [],
  displayOverLimit: false,
  linkedCoupon: null,
  linkedEarningRules: null,
  linkedBrand: null,
  order: null,
  typeDisplay: null,
  categories: [],
  customerGroup: [],
  segments: [],
  generalType: GENERAL_TYPE.customerGroup,
  couponDisplayPriority: null,
  translations: {
    [LanguageConfig.english]: getTranlation(),
    [LanguageConfig.traditionalChinese]: getTranlation(),
    [LanguageConfig.simplifiedChinese]: getTranlation(),
  },
});

const getInitialState = () => ({
  campaign: cmapaignInit(),
  errorFields: [],
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  languageTag: LanguageConfig.english,
  createStatus: APIStatus.none,
  campaignPublish: APIStatus.none,
  promptShowed: false,
});

function saveDataToSessions(oldCampaign) {
  const localCampaign = getObjectFromSessionStorage(sessionDataKey.objectKey);

  const localEn = localCampaign?.translations?.[LanguageConfig.english] || {};
  const localTraditional =
    localCampaign?.translations?.[LanguageConfig.traditionalChinese] || {};
  const localSimplified =
    localCampaign?.translations?.[LanguageConfig.simplifiedChinese] || {};

  const finalCampaign = {
    ...localCampaign,
    ...oldCampaign,
    translations: {
      [LanguageConfig.english]: {
        ...localEn,
        ...oldCampaign.translations[LanguageConfig.english],
      },
      [LanguageConfig.traditionalChinese]: {
        ...localTraditional,
        ...oldCampaign.translations[LanguageConfig.traditionalChinese],
      },
      [LanguageConfig.simplifiedChinese]: {
        ...localSimplified,
        ...oldCampaign.translations[LanguageConfig.simplifiedChinese],
      },
    },
  };
  saveToSessionStorage(sessionDataKey.objectKey, finalCampaign);
}

export default {
  namespace: 'createCampaign',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateCampaign(state, { payload }) {
      const campaign = { ...state.campaign, ...payload };

      // campaign.isMonthlyOrWeekly =
      //   campaign.campaignType === CampaignType.earningRulesCampaign &&
      //   [
      //     EarningRuleType.monthlyAchievement,
      //     EarningRuleType.weeklyAchievement,
      //   ].includes(campaign.linkedEarningRules?.type);
      // if (campaign.isMonthlyOrWeekly) {
      //   const today = new Date();
      //   campaign.isAlwaysActivePeriod = true;
      //   campaign.isAlwaysVisiblePeriod = false;
      //   campaign.visibleEndDate = new Date(today.setDate(today.getDate() - 1));
      //   campaign.visibleStartDate = new Date(
      //     today.setDate(today.getDate() - 1),
      //   );
      // }

      saveToSessionStorage(sessionDataKey.objectKey, campaign);
      saveDataToSessions(campaign);
      return { ...state, campaign: campaign };
    },

    assembleCampaign(state, { payload }) {
      const campaignData = assembleCampaign(payload.campaign);
      const linkedCoupon = campaignData.linkedCoupon || {};
      const linkedEarningRules = campaignData.linkedEarningRules || {};
      const promptShowed =
        Object.keys(linkedCoupon).length > 0 ||
        Object.keys(linkedEarningRules).length > 0;
      const campaign = {
        ...state.campaign,
        ...campaignData,
        translations: {
          ...state.campaign.translations,
          ...campaignData.translations,
        },
      };
      saveToSessionStorage(sessionDataKey.origionalData, campaign);
      saveToSessionStorage(sessionDataKey.objectKey, campaign);
      saveDataToSessions(campaign);
      return {
        ...state,
        promptShowed,
        campaign: campaign,
      };
    },

    loadCampaignFromCookie(state, { payload }) {
      const campaign = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!campaign) {
        return {
          ...state,
        };
      }
      const linkedCoupon = state.linkedCoupon || {};
      const linkedEarningRules = state.linkedEarningRules || {};
      const promptShowed =
        Object.keys(linkedCoupon).length > 0 ||
        Object.keys(linkedEarningRules).length > 0;
      // campaign.isMonthlyOrWeekly =
      //   campaign.campaignType === CampaignType.earningRulesCampaign &&
      //   [
      //     EarningRuleType.monthlyAchievement,
      //     EarningRuleType.weeklyAchievement,
      //   ].includes(campaign.linkedEarningRules.type);
      // if (campaign.isMonthlyOrWeekly) {
      //   const today = new Date();
      //   campaign.isAlwaysActivePeriod = true;
      //   campaign.isAlwaysVisiblePeriod = false;
      //   campaign.visibleEndDate = new Date(today.setDate(today.getDate() - 1));
      //   campaign.visibleStartDate = new Date(
      //     today.setDate(today.getDate() - 1),
      //   );
      // }
      saveToSessionStorage(sessionDataKey.origionalData, campaign);
      saveToSessionStorage(sessionDataKey.objectKey, campaign);
      saveDataToSessions(campaign);
      return {
        ...state,
        promptShowed: promptShowed,
        campaign: campaign,
      };
    },

    saveOrRemoveCampaignFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      const data = payload.data;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {} };
      if (step === 1) {
        data['isExclusive'] = state.campaign.isExclusive;
        result = checkStepTwoFields(data, state.campaign.translations, isBack);
      }
      if (step === 2) {
        data['campaignType'] = state.campaign.campaignType;
        result = checkStepThreeFields(
          data,
          isBack,
          state.campaign.isExclusive,
          state.campaign.isMonthlyOrWeekly,
        );
      }
      const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );

      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }

      saveDataToSessions({
        ...state.campaign,
        ...result.data,
      });
      return {
        ...state,
        currentStep: step,
        stepConfig,
        errorFields: errorFields,
        createStatus: APIStatus.none,
        campaign: {
          ...state.campaign,
          ...result.data,
        },
      };
    },

    linkedUpdate(state, { payload }) {
      const translations = getTranslationForCampaign(payload.data);
      const campaign = {
        ...state.campaign,
        translations: {
          [LanguageConfig.english]: {
            ...(state.campaign.translations[LanguageConfig.english] || {}),
            ...(translations[LanguageConfig.english] || {}),
          },
          [LanguageConfig.simplifiedChinese]: {
            ...(state.campaign.translations[LanguageConfig.simplifiedChinese] ||
              {}),
            ...(translations[LanguageConfig.simplifiedChinese] || {}),
          },
          [LanguageConfig.traditionalChinese]: {
            ...(state.campaign.translations[
              LanguageConfig.traditionalChinese
            ] || {}),
            ...(translations[LanguageConfig.traditionalChinese] || {}),
          },
        },
      };
      saveToSessionStorage(sessionDataKey.objectKey, campaign);
      saveDataToSessions(campaign);
      return {
        ...state,
        campaign: campaign,
      };
    },

    clearData(state, { payload }) {
      if (payload?.deleteSession) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      removeFromSessionStorage(CampaignType.couponsCampaign);
      removeFromSessionStorage(CampaignType.earningRulesCampaign);
      removeFromSessionStorage(CampaignType.generalMessageCampaign);
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldCampaign = yield select(
        (state) => state.createCampaign.campaign,
      );
      const language = payload.language;
      let campaign = {};
      if (language) {
        delete payload.language;
        campaign = {
          ...oldCampaign,
          translations: {
            ...oldCampaign.translations,
            [language]: {
              ...oldCampaign.translations[language],
              ...payload,
            },
          },
        };
      } else {
        campaign = { ...oldCampaign, ...payload };
      }

      saveToSessionStorage(sessionDataKey.objectKey, campaign);
      saveDataToSessions(campaign);
    },

    *typeChange({ payload }, { select, put }) {
      const stateCampaign = yield select(
        (state) => state.createCampaign.campaign,
      );
      const campaignType = payload.campaignType;
      yield put({
        type: 'updateCampaign',
        payload: {
          campaignType,
          linkedEarningRules: {
            ...stateCampaign?.linkedEarningRules,
            type: '',
          },
        },
      });
      const linkedCoupon = stateCampaign.linkedCoupon;
      const linkedEarningRules = stateCampaign.linkedEarningRules;
      const couponId = linkedCoupon?.pk;
      const earningRuleId = linkedEarningRules?.pk;
      let name = '';
      let actionName = '';
      if (campaignType === CampaignType.couponsCampaign && couponId) {
        name = linkedCoupon?.name;
        actionName = 'getAndLinkCouponDetail';
      }
      if (campaignType === CampaignType.earningRulesCampaign && earningRuleId) {
        name = linkedEarningRules?.name;
        actionName = 'getAndLinkEarningRuleDetail';
      }
      if (actionName) {
        yield put({
          type: actionName,
          payload: { name, couponId, earningRuleId },
        });
      }
    },

    publishOrUnpublishCamapaign: [
      function* ({ payload }, { call, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        let { campaignPk, isPublished } = yield select((state) => ({
          campaignPk: state.createCampaign.campaign.pk,
          isPublished: state.createCampaign.campaign.isPublished,
        }));

        if (campaignPk) {
          yield put.resolve({
            type: 'updateCampaignFields',
            payload,
          });
        } else {
          yield put.resolve({
            type: 'createOneCampaign',
            payload,
          });
        }
        campaignPk = yield select((state) => state.createCampaign.campaign.pk);
        const apiError = yield select((state) => state.createCampaign.apiError);
        if (apiError) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          return;
        }
        isPublished
          ? yield put({
            type: 'unPublishCampaign',
            payload: { ...payload, campaignPk },
          })
          : yield put({
            type: 'doPublish',
            payload: { ...payload, campaignPk },
          });
      },
      { type: 'takeLatest' },
    ],

    doPublish: [
      function* ({ payload }, { select, put }) {
        const afterAction = payload.afterAction || (() => { });
        const campaignPk = payload.campaignPk;
        const serviceArgs = [
          publishCampaign,
          {
            id: campaignPk,
          },
        ];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.success,
              campaignPublish: APIStatus.success,
            },
          });
          afterAction();
        }

        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              campaignPublish: APIStatus.failed,
            },
          });
          afterAction();
        }

        function* onArgumentsError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              campaignPublish: APIStatus.failed,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
    ],

    unPublishCampaign: [
      function* ({ payload }, { call, select, put }) {
        const afterAction = payload.afterAction || (() => { });
        const campaignPk = payload.campaignPk;
        const serviceArgs = [
          unPublishCampaign,
          {
            id: campaignPk,
          },
        ];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success },
          });
          afterAction();
        }
        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          afterAction();
        }

        function* onArgumentsError(response) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          afterAction();
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],

    createNewOrUpdateExistedCampign: [
      function* ({ payload }, { call, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const campaignPk = yield select(
          (state) => state.createCampaign.campaign.pk,
        );
        if (campaignPk || payload?.pk) {
          yield put.resolve({ type: 'updateCampaignFields', payload });
        } else {
          yield put.resolve({ type: 'createOneCampaign', payload });
        }

        const { newCampaignPk, apiError } = yield select((state) => ({
          newCampaignPk: state.createCampaign.campaign.pk,
          apiError: state.createCampaign.apiError,
        }));

        yield put({
          type: 'updateState',
          payload: {
            createStatus:
              !newCampaignPk || apiError ? APIStatus.failed : APIStatus.success,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    createOneCampaign: [
      function* ({ payload }, { call, select, put, all }) {
        const originalCampaign = yield select(
          (state) => state.createCampaign.campaign,
        );
        const createCampaignData = getDataForCreateCampaign(
          originalCampaign,
          false,
        );

        const serviceArgs = [createCampaign, createCampaignData];
        function* onSuccess(data) {
          const campaign = data.createCampaign.node;
          if (!campaign) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }
          yield put({
            type: 'updateState',
            payload: { apiError: false },
          });
          yield put({
            type: 'updateCampaign',
            payload: { id: campaign.id, pk: campaign.pk },
          });
          yield put({ type: 'saveOrRemoveCampaignFromCookie', payload: false });
        }

        function* failed(data) {
          console.log('createOneCampaign failed:', data);
          yield put({ type: 'updateState', payload: { apiError: true } });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    createCamapigncategory: [
      function* ({ payload }, { call, select, put, race, take }) {
        const { enCategory, simpleCategory, traditionalCategory, priority } =
          payload;
        const campaignType = yield select(
          (state) => state.createCampaign.campaign.campaignType,
        );
        const categoryType =
          campaignType === CampaignType.couponsCampaign
            ? CategoryType.REWARD_CAMPAIGN
            : CategoryType.EARNING_CAMPAIGN;
        yield put(
          createAction('createCampaignCategory/createCampaignCategory')({
            enCategory,
            simpleCategory,
            traditionalCategory,
            priority,
            isForcedInactive: false,
            categoryType,
          }),
        );
        const [success, failed] = yield race([
          take(CREATE_CAMPAIGN_CATEGORY_SUCCESS),
          take(CREATE_CAMPAIGN_CATEGORY_FAILD),
        ]);
        if (success) {
          yield put(
            createAction('campaignCategoryList/getCampaignCategoryList')({
              categoryType,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    getOneCampaign: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('CampaignNode', payload.id);
        const serviceArgs = [getCampaign, id];
        function* onSuccess(data) {
          console.log('getOneCampaign onSuccess :', data);
          yield all([
            put({
              type: 'assembleCampaign',
              payload: { campaign: data.campaign },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.success },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneCampaign onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneCampaign arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    deleteCampaigns: [
      function* ({ payload }, { call, select, put }) {
        const selectedCampaignIds = yield select(
          (state) => state.campaignList.checkedList,
        );
        const deleteCampaignIds = selectedCampaignIds?.map((item) => item.pk);
        const afterAction = payload.afterAction || (() => { });

        const serviceArgs = [deleteCampaigns, deleteCampaignIds];
        yield apiWithResponseHandle(serviceArgs);

        yield delay(1000);
        afterAction();
      },
      { type: 'takeLatest' },
    ],

    duplicateCampaign: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'clearData' });
        const campaignId = payload.campaignId;
        const afterAction = payload.afterAction || (() => { });
        yield put.resolve({
          type: 'getOneCampaign',
          payload: { id: campaignId },
        });
        const translations = yield select(
          (state) => state.createCampaign.campaign.translations,
        );
        const englishName = translations[LanguageConfig.english].generalName;
        translations[
          LanguageConfig.english
        ].generalName = `Copy of ${englishName}`;
        yield put.resolve({
          type: 'updateCampaign',
          payload: { translations },
        });
        yield put.resolve({ type: 'createOneCampaign' });
        afterAction();
      },
      { type: 'takeLatest' },
    ],

    updateCampaignFields: [
      function* ({ payload }, { all, select, put }) {
        const originalCampaign = yield select(
          (state) => state.createCampaign.campaign,
        );

        const campaignData = getDataForCreateCampaign(originalCampaign, true);
        console.log('update')
        campaignData.id = originalCampaign.pk;

        const serviceArgs = [updateCampaign, campaignData];
        function* onSuccess(data) {
          yield put({ type: 'saveOrRemoveCampaignFromCookie', payload: false });
          yield put({ type: 'updateState', payload: { apiError: false } });
        }
        function* failed(data) {
          yield put({ type: 'updateState', payload: { apiError: true } });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    updateCouponCampaignDisplayPriority: [
      function* ({ payload }, { all, select, put }) {
        const serviceArgs = [updateCampaign, payload];
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess(data) {
          yield afterAction({ success: true });
        }
        function* failed(data) {
          yield afterAction({ success: false });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkCouponDetail: [
      function* ({ payload }, { call, select, put }) {
        if (!payload.couponId) {
          return;
        }
        const couponId = convertPKToId('CouponTemplateNode', payload.couponId);
        const name = payload.name;
        const linkedCoupon = yield select(
          (state) => state.createCampaign.campaign.linkedCoupon,
        ) || {};
        yield put({
          type: 'updateCampaign',
          payload: { linkedCoupon: { ...linkedCoupon, name: name } },
        });
        const serviceArgs = [getOneCoupon, couponId];
        function* onSuccess(data) {
          yield put({
            type: 'updateCampaign',
            payload: {
              linkedCoupon: { ...linkedCoupon, ...data.couponTemplate },
            },
          });
          yield put({
            type: 'linkedUpdate',
            payload: { data: data.couponTemplate, ...payload },
          });
          if (payload.afterAction) {
            payload.afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkEarningRuleDetail: [
      function* ({ payload }, { call, select, put }) {
        if (!payload.earningRuleId) {
          return;
        }
        const earningRuleId = convertPKToId(
          'EarningRuleNode',
          payload.earningRuleId,
        );
        const name = payload.name;
        const linkedEarningRules = yield select(
          (state) => state.createCampaign.campaign.linkedEarningRules,
        ) || {};
        yield put({
          type: 'updateCampaign',
          payload: {
            linkedEarningRules: { ...linkedEarningRules, name: name },
          },
        });
        const serviceArgs = [getOneEarningRule, earningRuleId];
        function* onSuccess(data) {
          yield put({
            type: 'updateCampaign',
            payload: {
              linkedEarningRules: {
                ...linkedEarningRules,
                ...data.earningRule,
              },
            },
          });
          yield put({
            type: 'linkedUpdate',
            payload: { data: data.earningRule, ...payload },
          });
          if (payload.afterAction) {
            payload.afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
