import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux"
import { Row } from 'react-bootstrap';
import i18n from '../../../I18n.js';
import CustomRadios from '../../base/CustomRadios.js';
import { CampaignErrorHandleField, isShowError } from './CreateCampaignHandleError.js';
import { LanguageConfig } from '../../../config/CustomEnums.js';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments.js';
import ErrorFieldMessage from '../../base/ErrorFieldMessage.js';

const LinkButtonType = {
  none: null,
  DIRECTLY: 'DIRECTLY',
  DETAIL_PAGE: 'DETAIL_PAGE',
}

const getRadioOptions = (language) => [
  {
    label: i18n.t('campaign.none', { locale: language }),
    value: LinkButtonType.none,
  },
  {
    label: i18n.t('campaign.directly', { locale: language }),
    value: LinkButtonType.DIRECTLY,
  },
  {
    label: i18n.t('campaign.detail_page', { locale: language }),
    value: LinkButtonType.DETAIL_PAGE
  },
];


const AdLinkSection = ({
  language,
  onSubmit,
  onSubmitAction,
  linkButtonType,
  adLink,
  errorFields,
}) => {
  const dispatch = useDispatch();
  const [link, setLink] = useState(adLink);
  const updateCampaignState = (payload) => {
    dispatch({
      type: 'createCampaign/updateCampaign',
      payload: payload,
    });
  };
  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        adLink: link
      });
    }
  }, [onSubmit]);

  const radioOptions = getRadioOptions(language);

  const getRadioText = () => {
    let label = linkButtonType || LinkButtonType.none;

    return radioOptions.filter((item) => item.value === label)[0].label;
  };

  return (
    <>
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('campaign.ad_link_type', { locale: language })}
      </label>
      <Row className="featured-exclusive-row">
        {language !== LanguageConfig.english ? (
          getRadioText()
        ) : (
          <CustomRadios
            disabled={language !== LanguageConfig.english}
            onChange={(value) => {
              console.log("@@63", value)
              updateCampaignState({
                linkButtonType: value
              })
            }}
            default={linkButtonType || null}
            options={radioOptions}
          />
        )}
      </Row>
      <CustomTitleLabel title= {i18n.t('campaign.campaign_ad_link', { locale: language })} />
      {language !== LanguageConfig.english
        ? adLink
        : <>
          <textarea
            onChange={({ target }) => {
              console.log("@@79", target.value)
              setLink(target.value);
              updateCampaignState({
                adLink: target.value,
              })
            }}
            className="custom-textarea-input-box custom-textarea-input-font"
            value={adLink}
          />
          <ErrorFieldMessage
            id={CampaignErrorHandleField.adLink.name}
            error={isShowError(CampaignErrorHandleField.adLink.name, errorFields)}
            message={CampaignErrorHandleField.adLink.message}
          />
        </>

      }
    </>
  )
};

const mapStateToProps = (state) => ({
  linkButtonType: state.createCampaign.campaign.linkButtonType,
  adLink: state.createCampaign.campaign.adLink,
  errorFields: state.createCampaign.errorFields,
});

export default connect(mapStateToProps)(AdLinkSection);
