import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomSwitchButton from '../base/CustomSwitchButton';
import BasePrompt from '../base/prompt/BasePrompt';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
  CustomTitleWithRadio,
  CustomNumberInputWithTips,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { createAction, useCompare } from '../../utils';
import { useHistory } from 'react-router-dom';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import { CampaignType, EarningRuleType } from '../../config/CustomEnums';
import CustomTitleWithInput from '../base/CustomTitleWithInput';

function PhotoTakingSection(props) {
  const dispatch = useDispatch();
  const defaultValue = props.defaultValue || {};

  const [isAllRecyclingStores, setIsAllRecyclingStores] = useState(
    defaultValue.isAllRecyclingStores,
  );
  const [photoTakingStores, setPhotoTakingStores] = useState(
    defaultValue.photoTakingStores,
  );
  const [perItemAmountLimit, setPerItemAmountLimit] = useState(
    defaultValue.perItemAmountLimit,
  );
  const [perHeadTotalAmountLimit, setPerHeadTotalAmountLimit] = useState(
    defaultValue.perHeadTotalAmountLimit,
  );

  const [overallLimit, setOverallLimit] = useState(defaultValue.overallLimit);
  const [periodicLimit, setPeriodicLimit] = useState(
    defaultValue.periodicLimit,
  );
  const [periodicLimitDays, setPeriodicLimitDays] = useState(
    defaultValue.periodicLimitDays,
  );
  const [perHeadLimit, setPerHeadLimit] = useState(defaultValue.perHeadLimit);
  const [perHeadPeriodicLimit, setPerHeadPeriodicLimit] = useState(
    defaultValue.perHeadPeriodicLimit,
  );
  const [perHeadPeriodicLimitDays, setPerHeadPeriodicLimitDays] = useState(
    defaultValue.perHeadPeriodicLimitDays,
  );

  const vals = {
    isAllRecyclingStores,
    photoTakingStores,
    perItemAmountLimit,
    perHeadTotalAmountLimit,

    overallLimit,
    periodicLimit,
    periodicLimitDays,
    perHeadLimit,
    perHeadPeriodicLimit,
    perHeadPeriodicLimitDays,
  };
  const hasValsChanged = useCompare(vals);
  const history = useHistory();

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields, pagedStoreList } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
    pagedStoreList: state.storeModel.storeList,
  }));
  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    if (defaultValue.isAllRecyclingStores === undefined) {
      setIsAllRecyclingStores(true);
    }
    if (
      defaultValue.perItemAmountLimit === null ||
      defaultValue.perItemAmountLimit === undefined
    ) {
      setPerItemAmountLimit(5);
    }
    if (
      defaultValue.perHeadTotalAmountLimit === null ||
      defaultValue.perHeadTotalAmountLimit === undefined
    ) {
      setPerHeadTotalAmountLimit(15);
    }

    dispatch(
      createAction('storeModel/getPagedStoreList')({
        isAllRecyling: true,
        isSelectorLoad: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    // firstError(`${EarningRuleErrorHandleField.monthlyGoal.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">Photo Taking</label>

      <CustomTitleWithRadio
        title="Available location"
        setValue={setIsAllRecyclingStores}
        defaultValue={isAllRecyclingStores}
        options={[
          { label: 'All recycling locations', value: true },
          { label: 'Specific locations', value: false },
        ]}
      />
      {!isAllRecyclingStores ? (
        <CustomAddRemoveMutipleSelector
          title={''}
          data={pagedStoreList}
          selectedList={photoTakingStores}
          setValue={(value) => {
            setPhotoTakingStores(value);
          }}
          sourceReminderText={'All recycling location(s)'}
          amidReminderText={'Selected recycling location(s)'}
          onFocusFunc={() => {}}
          loadMoreAction={'storeModel/getPagedStoreList'}
          filterAction={'storeModel/getPagedStoreList'}
          defaultFilter={{ isAllRecyling: true }}
          error={{
            id: EarningRuleErrorHandleField.photoTakingStores.name,
            error: isError('photoTakingStores'),
            message: EarningRuleErrorHandleField.photoTakingStores.message,
          }}
          // addButton={{
          //   action: () => setShowAddBrand(true),
          //   title: 'Create Now',
          //   requires: PermissionCodes.addBrand,
          //   customClass: 'general-section-add-new-brand btn-add-button-common',
          // }}
        />
      ) : null}

      <CustomNumberInputWithTips
        title={'Per items amount limit'}
        setValue={setPerItemAmountLimit}
        defaultValue={perItemAmountLimit}
        errorId={'perItemAmountLimit'}
        error={isError('perItemAmountLimit')}
        errorMessage={EarningRuleErrorHandleField.perItemAmountLimit.message}
        tips={
          'If select common or material item > the limit , then will required admin to approval'
        }
      />
      <CustomNumberInputWithTips
        title={'Per head total amount limit'}
        setValue={setPerHeadTotalAmountLimit}
        defaultValue={perHeadTotalAmountLimit}
        errorId={'perHeadTotalAmountLimit'}
        error={isError('perHeadTotalAmountLimit')}
        errorMessage={
          EarningRuleErrorHandleField.perHeadTotalAmountLimit.message
        }
        tips={
          'If per head  recycling items total amount > the limit , then will prohibit users from increasing the number.'
        }
      />

      <CustomTitleLabel title="Overall limit (optional)" />
      <label className="tips-message">
        Maximum number of times to photo taking per campaign.
      </label>
      <CustomNumberInputWithUnit
        unit="times"
        setValue={setOverallLimit}
        defaultValue={overallLimit}
      />

      <CustomTitleLabel title="Periodic limit (optional)" />
      <label className="tips-message">
        Maximum number of times to photo taking per period.
      </label>
      <CustomNumberMutipleInputWithUnit
        per="times per"
        unit="days"
        setFirstValue={setPeriodicLimit}
        firstDefaultValue={periodicLimit}
        setSecondValue={setPeriodicLimitDays}
        secondDefaultValue={periodicLimitDays}
      />

      <CustomTitleLabel title="Per head limit (optional)" />
      <label className="tips-message">
        Maximum number of times to photo taking per customer.
      </label>
      <CustomNumberInputWithUnit
        unit="times"
        setValue={setPerHeadLimit}
        defaultValue={perHeadLimit}
      />

      <CustomTitleLabel title="Per head periodic limit (optional)" />
      <label className="tips-message">
        Maximum number of times to photo taking per customer per period.
      </label>
      <CustomNumberMutipleInputWithUnit
        per="times per"
        unit="days"
        setFirstValue={setPerHeadPeriodicLimit}
        firstDefaultValue={perHeadPeriodicLimit}
        setSecondValue={setPerHeadPeriodicLimitDays}
        secondDefaultValue={perHeadPeriodicLimitDays}
      />
    </>
  );
}

export default PhotoTakingSection;
