import {
  getRewardCodes,
  getRewardCode,
  createRewardCode,
  updateRewardCode,
  deleteRewardCodes,
  publishRewardCode,
  unPublishRewardCode,
} from '../services/RewardCodeApiHelper'
import {
  convertNumberToCursor,
  convertCursorToNumber,
  removeFromSessionStorage,
  saveToSessionStorage,
  getObjectFromSessionStorage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  getCampignPeriodDateTime,
  getCampignListDisplayTime,
  getDisplayDate
} from '../utils/TimeFormatUtil';
import {
  LanguageConfig,
  APIStatus,
  PublishTagType,
} from '../config/CustomEnums';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { getTranslationForCampaign } from './CreateCampaignModel';
import { checkCampaignIsExpired } from '../utils/TimeFormatUtil';

export const RewardCodeCategory = {
  "REGISTRATION_CODE": {
    value: "REGISTRATION_CODE",
    label: 'Registration code',
  },
  "REDEMPTION_CODE": {
    value: "REDEMPTION_CODE",
    label: 'Redemption code',
  },
};

export const RewardCodeCategoryList = Object.values(RewardCodeCategory);

export const RewardCodeType = {
  "UNIQUE_CODE": {
    label: "Unique code",
    value: "UNIQUE_CODE"
  },
  "GENERIC_CODE": {
    label: "Generic code",
    value: "GENERIC_CODE"
  }
};

export const RewardCodeTypeList = Object.values(RewardCodeType);

export const RewardCodeRewardType = {
  "COUPONS": {
    label: "coupons",
    value: "COUPONS"
  },
  "POINTS": {
    label: "points",
    value: "POINTS"
  },
};

export const RewardCodeRewardTypeList = Object.values(RewardCodeRewardType);

export const sessionDataKey = {
  objectKey: 'createRewardCode',
  stepEndKey: 'createRewardCodeStepEnd',
  origionalData: 'createRewardCodeOriginalData',
};

export const CreateRewardCodeError = {
  name: {
    name: 'name',
    message: 'Please provide a name'
  },
  codeType: {
    name: 'codeType',
    message: 'Please provide a code type',
  },
  code: {
    name: 'code',
    message: 'Please provide a registration code',
  },
  totalNumber: {
    name: 'totalNumber',
    message: 'Please provide a total number',
  },
  couponSet: {
    name: 'couponSet',
    message: 'Please provide a coupon set',
  },
  couponQuantity: {
    name: 'couponQuantity',
    message: 'Please provide a valid number',
  },
  points: {
    name: 'points',
    message: 'Please provide a valid number',
  },
  perHeadLimit: {
    name: 'perHeadLimit',
    message: 'Please provide a valid number',
  },
  endDate: {
    name: 'endDate',
    message: 'The end date & time cannot be on or before the start date & time.',
  },
};

const stepNames = ['Content', 'Preview'];

const getInitialState = () => ({
  rewardCodeList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name' },
    { displayName: 'Used Codes/ Total Codes', fieldName: 'usedCodesWithTotalCodes' },
    { displayName: 'Related Brand', fieldName: 'brandName' },
    { displayName: 'Code Type', fieldName: 'displayCodeType' },
    { displayName: 'Reward Type', fieldName: 'displayRewardType' },
    { displayName: 'Active Period', fieldName: 'displayActivePeriod' },
    { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  currentPageRewardCodeList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  rewardCodeDetail: {
    category: RewardCodeCategory.REGISTRATION_CODE.value,
    codeType: RewardCodeType.UNIQUE_CODE.value,
    rewardType: RewardCodeRewardType.COUPONS.value,
    translations: {
      [LanguageConfig.english]: {},
      [LanguageConfig.simplifiedChinese]: {},
      [LanguageConfig.traditionalChinese]: {},
    },
  },
  errorFields: [],
  errorTab: LanguageConfig.english,
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  rewardCodeAPIStatus: APIStatus.none,
  createRewardCodeAPIStatus: APIStatus.none,
});

const parseBrand = (data) => {
  if (!data) {
    return {}
  }
  const translationsEdges = data.translations?.edges;
  let translations = {};
  if (translationsEdges?.length > 0) {
    translationsEdges.forEach((item) => {
      const node = item.node;
      translations[node.language] = {
        id: node.pk,
        language: node.language,
        name: node.name,
      };
    });
  };
  translations[LanguageConfig.english] = {
    language: LanguageConfig.english,
    name: data.name,
  }

  return {
    ...data,
    brandTranslations: translations,
  }
}

const parseRewardCode = (data) => {
  const category = data.codeSetCategory ? RewardCodeCategory?.[data.codeSetCategory] : RewardCodeCategory.REGISTRATION_CODE;
  const codeType = data.codeSetType ? RewardCodeType?.[data.codeSetType] : RewardCodeType.UNIQUE_CODE;
  const rewardType = data.rewardType ? RewardCodeRewardType?.[data.rewardType] : RewardCodeRewardType.COUPONS;

  const translationsEdges = data.translations?.edges;
  let translations = {};
  if (translationsEdges?.length > 0) {
    translationsEdges.forEach((item) => {
      const node = item.node;
      translations[node.language] = {
        id: node.pk,
        language: node.language,
        name: node.name,
      };
    });
  };
  translations[LanguageConfig.english] = {
    language: LanguageConfig.english,
    name: data.name,
  }

  let status = PublishTagType.unPublished;
  if (data.isPublished) {
    status = PublishTagType.published;
  };
  if (data.isExpired) {
    status = PublishTagType.expired;
  };

  return {
    pk: data.pk,
    name: data.name,
    category: category?.value,
    codeType: codeType?.value,
    displayCodeType: codeType?.label,
    linkedBrand: parseBrand(data.linkedBrand),
    brandName: data.linkedBrand?.name,
    code: data.code,
    totalNumberOfSingleCodes: data.totalNumberOfSingleCodes,
    usedCodes: data.usedCodes,
    totalCodes: data.totalCodes,
    usedCodesWithTotalCodes: `${data.usedCodes || 0}/${data.totalCodes || 0}`,
    rewardType: rewardType?.value,
    displayRewardType: rewardType?.label,
    linkedCouponSet: data.linkedCouponSet,
    couponName: data.linkedCouponSet ? `${data.linkedCouponSet?.name} (ID: ${data.linkedCouponSet?.pk})` : '',
    couponQuantity: data.couponQuantity,
    points: data.points,
    perHeadLimit: data.perHeadLimit,
    isAlwaysActivePeriod: !data.endDate,
    activeStartDate: data.startDate,
    activeEndDate: data.endDate,
    displayActivePeriod: getCampignListDisplayTime(
      data.startDate,
      data.endDate,
    ),
    translations,
    creationDate: data.creationDate,
    overviewCreationDate: getCampignPeriodDateTime(data.creationDate),
    lastModifiedDate: data.lastModifiedDate,
    displayLastModifiedDate: getDisplayDate(data.lastModifiedDate),
    overviewLastModifiedDate: getCampignPeriodDateTime(data.lastModifiedDate),
    isPublished: data.isPublished,
    status,
  };
};

const assembleSingleTranslation = (data = {}, language) => {
  let idField = {};
  if (data?.id) {
    idField = { id: data.id };
  };
  return {
    ...idField,
    language,
    name: data.name || '',
  };
};

const assembleTranslations = (translations = {}) => {
  const traditionalChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.traditionalChinese],
    LanguageConfig.traditionalChinese,
  );
  const simplifiedChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.simplifiedChinese],
    LanguageConfig.simplifiedChinese,
  );
  return [
    traditionalChinese,
    simplifiedChinese,
  ]
};

const assembleRewardCode = (detail = {}) => {
  const now = new Date();

  let startDate = null;
  let endDate = null;
  if (detail.isAlwaysActivePeriod) {
    startDate = detail.activeStartDate < now ? detail.activeStartDate : now;
  } else {
    startDate = detail.activeStartDate;
    endDate = detail.activeEndDate;
  };

  const translations = assembleTranslations(detail.translations);

  return {
    name: detail.translations?.[LanguageConfig.english]?.name,
    codeSetCategory: detail.category,
    codeSetType: detail.codeType,
    linkedBrand: detail.linkedBrand?.pk || null,
    code: detail.codeType === RewardCodeType.GENERIC_CODE.value ? detail.code : null,
    totalNumberOfSingleCodes: detail.codeType === RewardCodeType.GENERIC_CODE.value? detail.totalNumberOfSingleCodes : null,
    rewardType: detail.rewardType,
    linkedCouponSet: detail.rewardType === RewardCodeRewardType.COUPONS.value ? detail.linkedCouponSet?.pk : null,
    couponQuantity: detail.rewardType === RewardCodeRewardType.COUPONS.value ? detail.couponQuantity || null : null,
    points: detail.rewardType === RewardCodeRewardType.POINTS.value ? detail.points || null : null,
    perHeadLimit: detail.codeType === RewardCodeType.UNIQUE_CODE.value ? detail.perHeadLimit || null : null,
    startDate,
    endDate,
    translations,
  };
};

const checkStepOneFields = (data) => {
  console.log("@@checkStepOneFields", data)
  let errorFields = [];
  let errorTab = LanguageConfig.english;
  if (!data?.translations?.[LanguageConfig.english]?.name) {
    errorFields.push(CreateRewardCodeError.name.name);
  };

  if (!data?.codeType) {
    errorFields.push(CreateRewardCodeError.codeType.name);
  };
  if (data?.codeType === RewardCodeType.GENERIC_CODE.value && !data?.code) {
    errorFields.push(CreateRewardCodeError.code.name);
  };
  if (data?.codeType === RewardCodeType.GENERIC_CODE.value && data?.totalNumberOfSingleCodes < 0) {
    errorFields.push(CreateRewardCodeError.totalNumber.name);
  };
  if (data?.rewardType === RewardCodeRewardType.COUPONS.value && !data?.linkedCouponSet) {
    errorFields.push(CreateRewardCodeError.couponSet.name);
  };
  if (data?.rewardType === RewardCodeRewardType.COUPONS.value && (!data?.couponQuantity || data?.couponQuantity <= 0)) {
    errorFields.push(CreateRewardCodeError.couponQuantity.name);
  };
  if (data?.rewardType === RewardCodeRewardType.POINTS.value && (!data?.points || data?.points <= 0)) {
    errorFields.push(CreateRewardCodeError.points.name);
  };
  if (data?.codeType === RewardCodeType.UNIQUE_CODE.value && data?.perHeadLimit < 0) {
    errorFields.push(CreateRewardCodeError.perHeadLimit.name);
  };

  const startDate = new Date(data?.activeStartDate);
  const endDate = new Date(data?.activeEndDate);
  if (
    !data?.isAlwaysActivePeriod &&
    startDate.getTime() >= endDate.getTime()
  ) {
    errorFields.push(CreateRewardCodeError.endDate.name);
  }
  return {
    data,
    invalid: errorFields.length > 0,
    errorFields: errorFields,
    errorTab,
  };
};


export default {
  namespace: 'rewardCode',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateDetail(state, { payload }) {
      const detail = {
        ...state.rewardCodeDetail,
        ...payload,
      };
      console.log("@@updateRewardCodeDetail", detail)

      saveToSessionStorage(sessionDataKey.objectKey, detail);
      return {
        ...state,
        rewardCodeDetail: detail,
      }
    },
    updateDetailTranslations(state, { payload }) {
      const detail = {
        ...state.rewardCodeDetail,
        translations: {
          ...state.rewardCodeDetail.translations,
          [payload.language]: {
            ...state.rewardCodeDetail.translations[payload.language],
            ...payload,
          }
        }
      };
      console.log("@@updateRewardCodeDetailTranslations", detail)

      saveToSessionStorage(sessionDataKey.objectKey, detail);
      return {
        ...state,
        rewardCodeDetail: detail
      }
    },
    clearState(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
    saveOrRemoveRewardCodeFromCookie(state, { payload }) {
      console.log("@@saveOrRemoveRewardCodeFromCookie", payload)
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    stepChange(state, { payload }) {
      const rewardCodeDetail = state.rewardCodeDetail;
      const isBack = payload.isBack;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {}, errorTab: LanguageConfig.english };

      if (step === 0) {
        result = checkStepOneFields(rewardCodeDetail);
        console.log("@@352 check step one", rewardCodeDetail, result)
      }

      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );

      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }

      return {
        ...state,
        currentStep: step,
        stepConfig: stepConfig,
        errorFields: result.errorFields,
        errorTab: result.errorTab,
      };
    },
  },
  effects: {
    loadRewardCodeFromCookie: [
      function* ({ payload }, { put, select }) {
        const rewardCode = getObjectFromSessionStorage(sessionDataKey.objectKey);
        console.log("@@350 rewardCode", rewardCode);

        if (rewardCode) {
          yield put({
            type: 'updateState',
            payload: {
              rewardCodeDetail: rewardCode,
            }
          })
        }
        if (payload?.afterAction) {
          payload?.afterAction();
        };
      }
    ],

    getCurrentPageRewardCodes: [
      function* ({ payload }, { put }) {
        const { page, searchKey, sort } = payload;

        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getRewardCodes,
          {
            afterCursor,
            order: sort,
            search: searchKey,
            others: payload,
          }
        ];
        function* onSuccess(data) {
          const pageInfo = data.rewardCodeSets.pageInfo;
          const totalCount = data.rewardCodeSets.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const rewardCodeData = data.rewardCodeSets.edges;
          const rewardCodeList = rewardCodeData.map((item) =>
            parseRewardCode(item.node),
          );
          yield put({
            type: 'updateState',
            payload: {
              currentPageRewardCodeList: rewardCodeList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              totalCount,
              currentLastCursor,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getRewardCode: [
      function* ({ payload }, { put, select }) {
        const { id } = payload;
        const rewardCodeID = btoa(`RewardCodeSetNode:${id}`);
        const serviceArgs = [getRewardCode, rewardCodeID];

        yield put({
          type: 'updateState',
          payload: {
            rewardCodeAPIStatus: APIStatus.calling,
          },
        });

        function* onSuccess(data) {
          const rewardCodeData = data.rewardCodeSet || {};
          const rewardCode = parseRewardCode(rewardCodeData);

          yield put({
            type: 'updateState',
            payload: {
              rewardCodeDetail: rewardCode,
              rewardCodeAPIStatus: APIStatus.success,
            },
          });

          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteRewardCode: [
      function* ({ payload }, { put, select }) {
        const serviceArgs = [deleteRewardCodes, payload?.deletePks];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateRewardCode: [
      function* ({ payload }, { put, select }) {
        const id = payload?.id || null;
        const { rewardCodeDetail } = yield select((state) => ({
          rewardCodeDetail: state.rewardCode.rewardCodeDetail,
        }));
        let newRewardCodeDetail = assembleRewardCode(rewardCodeDetail);
        if (id) {
          newRewardCodeDetail.id = id;
        };

        const serviceArgs = [id ? updateRewardCode : createRewardCode, newRewardCodeDetail];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(successData) {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({
            type: 'updateState',
            payload: {
              createRewardCodeAPIStatus: APIStatus.success,
            },
          });
          if (payload.hasPublishOrUnpublishAction && successData) {
            const rewardCodeNode = successData?.createRewardcodeset?.node || successData?.updateRewardcodeset?.node;
            yield put.resolve({
              type: 'publishOrUnpublishRewardCode',
              payload: {
                id: rewardCodeNode?.pk,
                publish: !rewardCodeNode?.isPublished,
              },
            });
          };
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }

        function* onFailed() {
          yield put({
            type: 'updateState',
            payload: {
              createRewardCodeAPIStatus: APIStatus.failed,
            },
          });
        }

        yield put({
          type: 'updateState',
          payload: {
            createRewardCodeAPIStatus: APIStatus.calling,
          },
        });
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
    ],
    publishOrUnpublishRewardCode: [
      function* ({ payload }, { put }) {
        const serviceArgs = [payload?.publish ? publishRewardCode : unPublishRewardCode, { id: payload.id }];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
