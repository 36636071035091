import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import SingleCodeFilter from './SingleCodeFilter';
const queryString = require('query-string');

const RewardSingleCode = ({
  listDisplayFields,
  pageInfo,
  totalCount,
  totalPage,
  checkedList,
  rewardSingleCodeList,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const params = useParams();
  const id = params.id;
  console.log("@@27", id)

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const deletePks = deleteItems?.map(item => item.pk);

  const searchQuery = queryString.parse(location.search);
  let currentPage = parseInt(searchQuery?.page);
  if (isNaN(currentPage)) {
    currentPage = 1;
  }

  const searchKey = searchQuery?.search;
  const fetchData = () => {
    console.log("@@41", id)
    dispatch({
      type: 'rewardSingleCode/getCurrentPageRewardSingleCodes',
      payload: {
        ...searchQuery,
        page: currentPage,
        searchKey,
        inRewardCodeSet: id,
      },
    });
  };

  const groupActions = [
    {
      name: 'Delete',
      action: (id) => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeRewardCode,
    },
  ];

  useEffect(() => {
    fetchData()
  }, [location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={true}
        groupActions={groupActions}
        pageInfo={pageInfo}
        totalCount={totalCount || 0}
        searchPlaceholder={'Search by code, name, member id'}
        filter={{ hasFilter: true, componment: SingleCodeFilter }}
        listContent={
          <BaseListContainer
            fields={listDisplayFields}
            model={'rewardSingleCode'}
            permissionGroup={PermissionCodes.rewardCode}
            dataList={rewardSingleCodeList}
            totalPage={totalPage}
            deleteInfo={{
              data: [],
              title: 'Reward single code',
              relatedName: '',
              relatedSections: [],
            }}
            actions={['Delete']}
            useCustomCommonActions
            customCommonActions={(item) => {
              const actions = [
                {
                  name: 'Delete',
                  action: () => {
                    setDeleteItems([item]);
                    setShowDeletePrompt(true);
                  },
                  requires: PermissionCodes.changeRewardCode,
                },
              ];
              return (
                <ActionsDropdownForItem
                  object={item}
                  actions={actions}
                  otherActions={[]}
                />
              );
            }}
          />
        }
      />

      <DeletePrompt
        data={deleteItems}
        show={showDeletePrompt}
        title={'Reward single code'}
        relatedName=""
        relatedSections={[]}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch({
            type: 'rewardSingleCode/deleteRewardSingleCode',
            payload: {
              deletePks,
              afterAction: fetchData,
            },
          });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  listDisplayFields: state.rewardSingleCode.listDisplayFields,
  pageInfo: state.rewardSingleCode.pageInfo,
  totalCount: state.rewardSingleCode.totalCount,
  totalPage: state.rewardSingleCode.totalPage,
  checkedList: state.rewardSingleCode.checkedList,
  rewardSingleCodeList: state.rewardSingleCode.currentPageRewardSingleCodeList,
});

export default connect(mapStateToProps)(RewardSingleCode);
