import React, { useState, useEffect } from 'react';
import './CreateCampaignStepFour.scss';
import { useDispatch, useSelector } from 'react-redux';
import ContentSections from '../../base/ContentSections';
import ProfileSection from './ProfileSection';
import ContentSection from './ContentSection';
import PublishCampaignPrompt from './PublishCampaignPrompt';
import { SaveAndBackButtons } from '../../base/BottomStepComponent';
import { useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../base/Loading';
import { PermissionCodes } from '../../../config/PermissionCodes';
const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
};

function CreateCampaignStepFour() {
  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);
  const dispatch = useDispatch();
  const history = useHistory();
  const sections = [<ProfileSection />, <ContentSection />];
  const { createStatus, isPublished } = useSelector((state) => ({
    createStatus: state.createCampaign.createStatus,
    isPublished: state.createCampaign.campaign.isPublished,
  }));

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      setShowPublishPrompt(
        actionType === ActionType.save ||
          history.location.pathname.includes('edit'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <ContentSections sections={sections} hidePreview={true} />
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <SaveAndBackButtons
        saveTempText="Save"
        saveText={isPublished ? 'UnPublished' : 'Publish'}
        backAction={() =>
          dispatch({
            type: 'createCampaign/stepChange',
            payload: {
              step: 3,
              isBack: true,
            },
          })
        }
        saveAction={() => {
          setActionType(ActionType.publish);
          dispatch(
            createAction('createCampaign/publishOrUnpublishCamapaign')(),
          );
        }}
        saveTempAction={() => {
          setActionType(ActionType.save);
          dispatch(
            createAction('createCampaign/createNewOrUpdateExistedCampign')(),
          );
        }}
        saveRequires={PermissionCodes.changeCampaign}
      />

      <PublishCampaignPrompt
        isSave={actionType === ActionType.save}
        isPublish={!isPublished}
        show={showPublishPrompt}
      />
    </>
  );
}

export default CreateCampaignStepFour;
