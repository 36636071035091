import { MessageChannel } from '../../config/CustomEnums';
import InboxImage from '../../assets/images/message/inbox.svg';
import PushImage from '../../assets/images/message/push.svg';
import InformationBar from '../../assets/images/message/informationBar.svg'

export const MessageChannelConfig = [
  {
    channel: MessageChannel.inbox,
    image: InboxImage,
    description: 'In-app messages to explain new content and promotions.',
    suggest: 'Suggest length: 1,000 character',
  },
  {
    channel: MessageChannel.push,
    image: PushImage,
    description: 'encourage customers to open the app.',
    suggest: 'suggest length: 235 character',
  },
  {
    channel: MessageChannel.informationBar,
    image: InformationBar,
    description: 'To show latest news',
    suggest: 'suggest length: 1,000 character',
  },
];

export const MessageType = {
  generalMessage: 'GENERAL_MESSAGE',
  welcomeMessage: 'WELCOME_MESSAGE',
};

export const InformationBarMessageType = {
  maintenance: 'Maintenance',
  others: 'Others'
}