import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { getImageUrl, isShowError } from '../../utils';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import i18n from '../../I18n';
import {
  CreateRewardCodeError,
  RewardCodeCategory,
  RewardCodeCategoryList,
  RewardCodeType,
  RewardCodeTypeList,
} from '../../models/RewardCodeModel';
import { LanguageConfig } from '../../config/CustomEnums';
import CustomRadios from '../base/CustomRadios';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomInputWithMaxLength from '../base/CustomInputWithMaxLength';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { firstError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import BasePrompt from '../base/prompt/BasePrompt';


const GeneralSection = ({
  language,
  brandList,
  rewardCodeDetail,
  errorFields,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCreateNewBrandPrompt, setShowCreateNewBrandPrompt] = useState(false);

  const {
    pk,
    category,
    linkedBrand,
    codeType,
    code,
    totalNumberOfSingleCodes,
    translations = {},
  } = rewardCodeDetail || {};

  const rewardCodeTitle = translations?.[language]?.name;
  const readableCodeType = RewardCodeTypeList.filter(item => item.value === codeType)?.[0]?.label;


  const isError = (field) => {
    return isShowError(field, errorFields, language);
  };

  useEffect(() => {
    if (language === LanguageConfig.english) {
      dispatch({
        type: 'brand/getCurrentPageBrands',
        payload: {},
      });
    }
  }, []);

  useEffect(() => {
    [
      CreateRewardCodeError.name.name,
      CreateRewardCodeError.codeType.name,
      CreateRewardCodeError.code.name,
      CreateRewardCodeError.totalNumber.name,
    ].forEach(item => {
      firstError(item, errorFields);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);


  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>

      <CustomInputWithMaxLength
        sectionTitle={i18n.t('name', { locale: language })}
        value={rewardCodeTitle}
        valueChange={(value) => {
          dispatch({
            type: 'rewardCode/updateDetailTranslations',
            payload: { name: value, language },
          });
        }}
        showCount
        maxLength={language === 'en' ? 60 : 30}
        errorId={CreateRewardCodeError.name.name}
        error={isError(CreateRewardCodeError.name.name)}
        errorMessage={CreateRewardCodeError.name.message}
      />

      {
        language === LanguageConfig.english
          ? <>
            <CustomTitleWithDropDown
              disabled={false}
              isImageField={false}
              showRemoveButton={true}
              onRemoveButtonClick={() => {
                dispatch({
                  type: 'rewardCode/updateDetail',
                  payload: { linkedBrand: null },
                });
              }}
              needFilter={true}
              title={'Related brand (optional)'}
              titleTips={''}
              source={brandList}
              defaultValue={{
                label: linkedBrand?.name,
                value: linkedBrand
              }}
              setValue={(value) => {
                dispatch({
                  type: 'rewardCode/updateDetail',
                  payload: { linkedBrand: value.value },
                });
              }}
              loadMoreAction={'brand/getCurrentPageBrands'}
              filterAction={'brand/getCurrentPageBrands'}
              defaultFilter={{}}
              addButtonInfo={{
                title: 'Create Now',
                action: () => {
                  setShowCreateNewBrandPrompt(true);
                },
                customClass: 'btn-back-button-common',
              }}
            />

            <label className="create-section-label create-section-label-bottom-space">Code type</label>
            {
              pk
                ? <label className="custom-record-normal-font custom-timeline-time-color">
                  {readableCodeType}
                </label>
                : <>
                  <Row className="featured-exclusive-row">
                    <CustomRadios
                      onChange={(value) => {
                        console.log("@@reward code change code type", value)
                        dispatch({
                          type: 'rewardCode/updateDetail',
                          payload: { codeType: value },
                        });
                      }}
                      default={codeType}
                      options={RewardCodeTypeList}
                    />
                  </Row>
                  <ErrorFieldMessage
                    id={CreateRewardCodeError.codeType.name}
                    error={isError(CreateRewardCodeError.codeType.name)}
                    message={CreateRewardCodeError.codeType.message}
                  />
                </>
            }
            {
              codeType === RewardCodeType.GENERIC_CODE.value
                ? <>
                  {
                    pk
                      ? <>
                        <CustomTitleLabel title={'Input registration code'} />
                        <label className="custom-record-normal-font custom-timeline-time-color">
                          {code}
                        </label>
                      </>
                      : <CustomTitleWithInput
                        title='Input registration code'
                        defaultValue={code}
                        setValue={(value) => {
                          dispatch({
                            type: 'rewardCode/updateDetail',
                            payload: { code: value },
                          });
                        }}
                        error={
                          isError(CreateRewardCodeError.code.name)
                            ? {
                              id: CreateRewardCodeError.code.name,
                              error: true,
                              message: CreateRewardCodeError.code.message,
                            }
                            : {}
                        }
                      />
                  }
                  <CustomTitleWithInput
                    type='number'
                    title='Total number of codes'
                    tips='Reminder: When editing, ensure the number in this field is greater than or equal to the previous saved number'
                    defaultValue={totalNumberOfSingleCodes}
                    setValue={(value) => {
                      dispatch({
                        type: 'rewardCode/updateDetail',
                        payload: { totalNumberOfSingleCodes: value },
                      });
                    }}
                    error={
                      isError(CreateRewardCodeError.totalNumber.name)
                        ? {
                          id: CreateRewardCodeError.totalNumber.name,
                          error: true,
                          message: CreateRewardCodeError.totalNumber.message,
                        }
                        : {}
                    }
                  />
                </>
                : null
            }
          </>
          : null
      }
      <BasePrompt
        show={showCreateNewBrandPrompt}
        closeAction={() => setShowCreateNewBrandPrompt(false)}
        rightButton={{
          text: 'Go to create a brand',
          action: () => {
            history.push({
              pathname: '/brands/create',
              state: {
                from: history.location,
                title: `Continue to ${pk ? 'edit' : 'create'} registration code`,
                content: `You can continue to ${pk ? 'edit' : 'create'} registration code.`,
              },
            })
          },
        }}
        title={'Go to create a brand'}
        description={
          'You will leave the registration code creation process. After you create a brand, you can come back to create a registration code.'
        }
      />
    </>
  )
}


const mapStateToProps = (state) => ({
  brandList: state.brand.brandList,
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
  errorFields: state.rewardCode.errorFields,
});

export default connect(mapStateToProps)(GeneralSection);
