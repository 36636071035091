import { useDispatch, useSelector, dispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import BaseEditSection from '../../base/BaseEditSection';
import { createAction, getError, useCompare } from '../../../utils';
// import { AfcSettingsErrorHandleFields } from '../../../models/AfcSettingsModel';
import { APIStatus, AfcDataStatus } from '../../../config/CustomEnums';
import { Row } from 'react-bootstrap';
import CustomRadios from '../../../components/base/CustomRadios';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import CustomDateTimeSelect from '../../../components/base/CustomDateTimeSelect';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';
import userEvent from '@testing-library/user-event';
import CustomerFilterableDropdown from '../../../components/transactions/CustomerFilterableDropdown';
import Loading from '../../../components/base/Loading';




function EditAfcSettings() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorFields, setErrorFields] = useState({ fields: [], messages: [] });
  const apiStatus = useSelector((state) => state.afcSettings.apiStatus);  
  const { afcSettings={} } = useSelector((state) => ({
    afcSettings: state.afcSettings.afcSettings || {},
  }));


  useEffect(() => {
    return () => {
      dispatch(createAction('afcSettings/clearData')({}));
    };
  }, []);

  const [level1Points, setLevel1Points] = useState('');
  const [level1CarbonSavings, setLevel1CarbonSavings] = useState('');
  const [level2Points, setLevel2Points] = useState('');
  const [level2CarbonSavings, setLevel2CarbonSavings] = useState('');
  const [level3Points, setLevel3Points] = useState('');
  const [level3CarbonSavings, setLevel3CarbonSavings] = useState('');
  const [level4Points, setLevel4Points] = useState('');
  const [level4CarbonSavings, setLevel4CarbonSavings] = useState('');
  const [level5Points, setLevel5Points] = useState('');
  const [level5CarbonSavings, setLevel5CarbonSavings] = useState('');
  const [level6ExtraCarbonSavings, setLevel6ExtraCarbonSavings] = useState('');
  const [afcCampaignId, setAfcCampaignId] = useState('');
  const [errorReportingEmailList, setErrorReportingEmailList] = useState('')

  useEffect(() => {
    if (apiStatus === APIStatus.success) {
      history.push('/afc_settings');
    }
  }, [apiStatus]);


  useEffect(() => {
    console.log('@53', afcSettings)

    setLevel1CarbonSavings(afcSettings.level1CarbonSavings)
    setLevel1Points(afcSettings.level1Points)
    setLevel2CarbonSavings(afcSettings.level2CarbonSavings)
    setLevel2Points(afcSettings.level2Points)
    setLevel3CarbonSavings(afcSettings.level3CarbonSavings)
    setLevel3Points(afcSettings.level3Points)
    setLevel4CarbonSavings(afcSettings.level4CarbonSavings)
    setLevel4Points(afcSettings.level4Points)
    setLevel5CarbonSavings(afcSettings.level5CarbonSavings)
    setLevel5Points(afcSettings.level5Points)

    setLevel6ExtraCarbonSavings(afcSettings.level6ExtraCarbonSavings)

    setAfcCampaignId(afcSettings.afcCampaignId)
    setErrorReportingEmailList(afcSettings.errorReportingEmailList)


    // console.log('@69a', afcSettings.transactionDatetime, transactionDatetime)

    // load data from api
    
    dispatch(
        createAction('afcSettings/getAfcSettings')({  }),
    );
        

  },  [dispatch, afcSettings.id]);

  
  const  sections = [
    <BaseEditSection
      title="General"
      fields={[
        <CustomTitleWithInput
            title={'Level 1 Carbon Savings'}
            defaultValue={level1CarbonSavings}
            setValue={(value) => {
            let data = value;
            data = data.replace(/[^0-9.]/g, '');
            setLevel1CarbonSavings(data);
            }}
        />,
        <CustomTitleWithInput
            title={'Level 1 Points'}
            defaultValue={level1Points}
            setValue={(value) => {
                let data = value;
                data = data.replace(/[^0-9.]/g, '');
                setLevel1Points(data);
            }}
        />,

        <CustomTitleWithInput
            title={'Level 2 Carbon Savings'}
            defaultValue={level2CarbonSavings}
            setValue={(value) => {
            let data = value;
            data = data.replace(/[^0-9.]/g, '');
            setLevel2CarbonSavings(data);
            }}
        />,
        <CustomTitleWithInput
            title={'Level 2 Points'}
            defaultValue={level2Points}
            setValue={(value) => {
                let data = value;
                data = data.replace(/[^0-9.]/g, '');
                setLevel2Points(data);
            }}
        />,

        <CustomTitleWithInput
            title={'Level 3 Carbon Savings'}
            defaultValue={level3CarbonSavings}
            setValue={(value) => {
            let data = value;
            data = data.replace(/[^0-9.]/g, '');
            setLevel3CarbonSavings(data);
            }}
        />,
        <CustomTitleWithInput
            title={'Level 3 Points'}
            defaultValue={level3Points}
            setValue={(value) => {
                let data = value;
                data = data.replace(/[^0-9.]/g, '');
                setLevel3Points(data);
            }}
        />,

        <CustomTitleWithInput
            title={'Level 4 Carbon Savings'}
            defaultValue={level4CarbonSavings}
            setValue={(value) => {
            let data = value;
            data = data.replace(/[^0-9.]/g, '');
            setLevel4CarbonSavings(data);
            }}
        />,
        <CustomTitleWithInput
            title={'Level 4 Points'}
            defaultValue={level4Points}
            setValue={(value) => {
                let data = value;
                data = data.replace(/[^0-9.]/g, '');
                setLevel4Points(data);
            }}
        />,

        <CustomTitleWithInput
            title={'Level 5 Carbon Savings'}
            defaultValue={level5CarbonSavings}
            setValue={(value) => {
            let data = value;
            data = data.replace(/[^0-9.]/g, '');
            setLevel5CarbonSavings(data);
            }}
        />,
        <CustomTitleWithInput
            title={'Level 5 Points'}
            defaultValue={level5Points}
            setValue={(value) => {
                let data = value;
                data = data.replace(/[^0-9.]/g, '');
                setLevel5Points(data);
            }}
        />,


        <CustomTitleWithInput
            title={'Extra Carbon Savings After Level 5'}
            defaultValue={level6ExtraCarbonSavings}
            setValue={(value) => {
                let data = value;
                data = data.replace(/[^0-9.]/g, '');
                setLevel6ExtraCarbonSavings(data);
            }}
        />,

        <CustomTitleWithInput
            title={'AFC Campaign ID'}
            defaultValue={afcCampaignId}
            setValue={(value) => {
                let data = value;
                data = data.replace(/[^0-9.]/g, '');
                setAfcCampaignId(data);
            }}
        />,

        <CustomTitleWithInput
          title={'Error Reporting Email List'}
          defaultValue={errorReportingEmailList}
          setValue={(value) => {
              let data = value;
              setErrorReportingEmailList(data);
          }}
        />,
      ]}
    />,
  ];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />
      
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={(event) => {
          dispatch(
            createAction('afcSettings/updateAfcSettings')({
              level1CarbonSavings, level1Points,
              level2CarbonSavings, level2Points,
              level3CarbonSavings, level3Points,
              level4CarbonSavings, level4Points,
              level5CarbonSavings, level5Points,
              level6ExtraCarbonSavings,
              afcCampaignId,
              errorReportingEmailList
            }),
          );

        }}
        backAction={() => history.goBack()}
      />
    </>,
  ];


  return (
    <ScrollableComponent
      content={stepSet}
      currentStep={0}
      breadcrumb={<CustomBreadcrumb name={'Edit AFC Settings'} />}
      caution={{
        detail: '',
        title: 'Edit AFC Settings',
        model: 'afcSettings',
      }}
    />
  );
}

export default EditAfcSettings;
