import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EarningRuleType } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';

import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import RewardSection from '../../../components/earning/RewardSection';
import GeneralPurchaseSection from '../../../components/earning/GeneralPurchaseSection';
import GPSCheckInSection from '../../../components/earning/GPSCheckInSection';
import ReferralSection from '../../../components/earning/ReferralSection';
import BirthdaySection from '../../../components/earning/BirthdaySection';
import QRCodeScanningSection from '../../../components/earning/QRCodeScanningSection';
import { createAction, getObjectFromSessionStorage } from '../../../utils';
import HabitSection from '../../../components/earning/HabitSection';
import RewardCarbonSection from '../../../components/earning/RewardCarbonSection';
import RewardSKUSection from '../../../components/earning/RewardSKUSection';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import PhotoTakingSection from '../../../components/earning/PhotoTakingSection';
import { isSpecificEarningRule } from '../../../models/CreateEarningRulesModel';
import StepPropertiesSection from '../../../components/earning/StepPropertiesSection';
import StepRewardSection from '../../../components/earning/StepRewardSection';
import OctopusExtraRewardSection from '../../../components/earning/OctopusExtraRewardSection';
import RewardExtraPointSection from '../../../components/earning/RewardExtraPointSection';
const dataArray = [];

function CreateEarningruleStepThree(props) {
  let tempEarningRule = getObjectFromSessionStorage('tempEarningRule');
  const earningRuleType = tempEarningRule?.type;

  const { checked } = useSelector((state) => ({
    checked: state.createEarningRules.checked,
  }));
  const isSpecificEarning = isSpecificEarningRule(earningRuleType);
  const dispatch = useDispatch();
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const [showPrompt, setShowPrompt] = useState(false);

  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (checked > 0) {
      if (!tempEarningRule?.pk) {
        dispatch(
          createAction('createEarningRules/createOrUpdateEarningRule')({
            isBack: onCheck.isBack,
            step: isSpecificEarning ? 1 : 2,
            isSave: true,
          }),
        );
      } else {
        setShowPrompt(true);
      }
    }

    if (onCheck.value) {
      let formatedData = { earningRuleType, type: earningRuleType };
      dataArray.forEach((item) => {
        formatedData = {
          ...formatedData,
          ...item,
        };
      });

      dispatch({
        type: 'createEarningRules/stepChange',
        payload: {
          data: formatedData,
          isBack: onCheck.isBack,
          step: isSpecificEarning ? 1 : 2,
          isSave: true,
        },
      });
    }

    stopCheck();
  }, [dispatch, onCheck.isBack, onCheck.value, earningRuleType, checked]);

  const submitAction = (data) => {
    dataArray.push(data);
  };

  const earningRuleProperties = () => {
    let properties;
    switch (earningRuleType) {
      case EarningRuleType.generalPurchase:
        properties = (
          <GeneralPurchaseSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.gpsCheckIn:
        properties = (
          <GPSCheckInSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.memberReferral:
        properties = (
          <ReferralSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.birthday:
        properties = (
          <BirthdaySection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.qrCodeScanning:
      case EarningRuleType.waterFilling:
      case EarningRuleType.activationCode:
      case EarningRuleType.qrCodeRecyclingExtraRewards:
        properties = (
          <QRCodeScanningSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.habit:
        properties = (
          <HabitSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.photoTaking:
        properties = (
          <PhotoTakingSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.activeTracking:
        properties = (
          <StepPropertiesSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      default:
        properties = null;
        break;
    }
    return properties;
  };

  const getEarningRuleRewardSection = () => {
    let reward;
    switch (earningRuleType) {
      case EarningRuleType.generalPurchase:
        reward = (
          <RewardSKUSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.photoTaking:
      case EarningRuleType.firstTry:
        reward = (
          <RewardCarbonSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.qrCodeRecyclingExtraRewards:
        reward = (
          <RewardExtraPointSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      default:
        reward = (
          <RewardSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
    }
    return reward;
  };

  const getEarningRuleExtraRewardSection = () => {
    if (earningRuleType == EarningRuleType.generalPurchase) {
      return <OctopusExtraRewardSection
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(data);
        }}
        defaultValue={tempEarningRule}
      />
    };
    return null;
  }

  // const earningRulePropertie = earningRuleProperties();
  const sections =
    earningRuleType === EarningRuleType.activeTracking
      ? [earningRuleProperties()]
      : [earningRuleProperties(), getEarningRuleRewardSection(), getEarningRuleExtraRewardSection()];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      {earningRuleType === EarningRuleType.activeTracking ? (
        <StepRewardSection
          onSubmit={onCheck.value}
          onSubmitAction={(data) => {
            submitAction(data);
          }}
          defaultValue={tempEarningRule}
        />
      ) : null}

      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={() => {
          isChecking({ isBack: false });
        }}
        backAction={() => {
          isChecking({ isBack: true });
        }}
      />
      <BasePrompt
        show={showPrompt}
        closeAction={() => setShowPrompt(false)}
        leftButton={{
          text: 'Continue updated',
          action: () => {
            dispatch(
              createAction('createEarningRules/createOrUpdateEarningRule')({
                isBack: onCheck.isBack,
                step: isSpecificEarning ? 1 : 2,
                isSave: true,
              }),
            );
            setShowPrompt(false);
          },
        }}
        rightButton={{
          text: 'Cancel',
          action: () => {
            setShowPrompt(false);
          },
        }}
        title={'Confirm to update the earning rule?'}
        description={
          'If changed, it may cause an error in the associated campaign. If there are new requirements, it is best to re-establish a earning rule and create a new campaign.'
        }
      />
    </>
  );
}

export default CreateEarningruleStepThree;
