import { BaseHelper } from './BaseGraphQLAPIHelper';
import { BarcodeType } from '../config/CustomEnums'

const node = `
id
pk
isPublished
productType {
    id
    pk
    name
}
productName
productImage
volumeQuantity
volumeConversion
linkedBrand {
    id
    pk
    name
}
sponsorPartner {
    id
    pk
    name
}
points
overallLimit
perHeadLimit
overAllPeriodicLimit
overAllPeriodicLimitEffectiveNumberOfDays
perHeadPeriodicLimit
perHeadPeriodicLimitEffectiveNumberOfDays
couponQuantity
codes
linkedCouponSet {
    id
    pk
    name
    stock

}
translations {
  edges{
    node{
      id
      pk
      language
      productName
    }
  }
}
`;

export const getList = ({
  afterCursor = '',
  order,
  search = '',
  others = {},
}) => {
  const orderBy = '-pk';
  let userFilter = '';
  if (others?.type === BarcodeType.partner || others?.type === BarcodeType.nonPartner) {
    userFilter += `, isPartner: ${others?.type === BarcodeType.partner ? 'true' : 'false'}`;
  }
  if (others?.product_type) {
    userFilter += `,productTypeIn: "${others?.product_type}"`;
  }
  if (others?.brand) {
    userFilter += `,brandIn: "${others?.brand}"`;
  }
  if (others?.status) {
    userFilter += `, isPublished: ${others?.status}`;
  }

  const query = `
  {
    barcodes(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains: "${search}"${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          isPublished
          productType {
              id
              pk
              name
          }
          productName
          productImage
          couponQuantity
          volumeQuantity
          volumeConversion
          linkedBrand {
              id
              pk
              name
          }
          points
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getObject = (id) => {
  const query = `
  {
    barcode(id: "${id}") {
      ${node}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createObject = (inputBody) => {
  const query = `
  mutation CreateBarcode($input: CreateBarcodeInput!) {
    createBarcode(input: $input) {
      node {
        ${node}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateObject = (inputBody) => {
  const query = `
  mutation UpdateBarcode($input: UpdateBarcodeInput!) {
    updateBarcode(input: $input) {
      node {
        ${node}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteObjects = (ids) => {
  const query = `
  mutation DeleteBarcodes($input: DeleteBarcodesInput!) {
    deleteBarcodes(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishObject = (input) => {
  const query = `mutation PublishBarcode($input: PublishBarcodeInput!) {
    publishBarcode(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishObject = (input) => {
  const query = `mutation UnPublishBarcode($input: UnPublishBarcodeInput!) {
    unPublishBarcode(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const checkBarcode = (input) => {
  const query = `mutation CheckCode($input: CheckCodeInput!) {
    checkCode(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

const newBarcodeNode = `
  id
  pk
  nextNewBarcodePk
  barcode {
    pk
    productName
  }
  linkedBrand {
    pk
    name
  }
  brandName
  productType {
    pk
    name
  }
  productImage
  productName
  productNameMapped
  volumeMapped
  brandMapped
  code
  volumeQuantity
  volumeConversion
  barcodeStatus
  creationDate
  lastModifiedDate
  translations {
    edges{
      node{
        pk
        language
        productName
        productNameMapped
      }
    }
  }
`;


export const getNewBarcodes = ({
  afterCursor = '',
  order,
  search = '',
  others = {},
}) => {
  let orderBy = '-pk';
  if (order) {
    orderBy = order;
  };
  let userFilter = '';
  if (others?.type) {
    userFilter += ``;
  }
  if (others?.product_type) {
    userFilter += `,productTypeIn: "${others?.product_type}"`;
  }
  if (others?.brand) {
    userFilter += `,brandIn: "${others?.brand}"`;
  }

  if (others?.type === BarcodeType.partner || others?.type === BarcodeType.nonPartner) {
    userFilter += `, isPartner: ${others?.type === BarcodeType.partner ? 'true' : 'false'}`;
  }

  if (others?.barcodeStatus) {
    userFilter += `, barcodeStatusIn: "${others?.barcodeStatus}"`;
  }

  const query = `
  {
    newbarcodes(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains: "${search}"${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${newBarcodeNode}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getNewBarcode = (id) => {
  const query = `
  {
    newbarcode(id: "${id}") {
      ${newBarcodeNode}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getExistedBarcodeList = ({
  afterCursor,
  search,
}) => {
  const userFilter = search ? `, nameIcontains: "${search}"` : ''

  const query = `
  {
    barcodes(first: 20, after:"${afterCursor}", orderBy: "-pk"${userFilter}) {
      edges {
        cursor
        node {
          pk
          productName
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};


export const approveNewBarcode = (input) => {
  const query = `
  mutation ApproveNewBarcode($input: ApproveNewBarcodeInput!) {
    approveNewBarcode(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `
  const variables = {
    input,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectNewBarcode = (input) => {
  const query = `
  mutation RejectNewBarcode($input: RejectNewBarcodeInput!) {
    rejectNewBarcode(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `
  const variables = {
    input,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteNewBarcode = (ids) => {
  const query = `
  mutation DeleteNewBarcodes($input: DeleteNewBarcodesInput!) {
    deleteNewBarcodes(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
