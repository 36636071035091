import {
  createObject,
  deleteObjects,
  getObject,
  getList,
  publishObject,
  unPublishObject,
  updateObject,
  checkBarcode
} from '../services/BarcodeApiHelper';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  removeFromSessionStorage,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  getFileNameFromUrl,
  getImageUrl
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  APIStatus,
  LanguageConfig,
  PublishTagType,
} from '../config/CustomEnums';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { getTranlationsData } from '../utils/TranslationUtil'

export const sessionDataKey = {
  objectKey: 'createBarcode',
  stepEndKey: 'createBarcodeStepEnd',
  origionalData: 'createBarcodeOriginalData',
};

export const productTypes = [
  { name: 'Aluminium Cans', pk: 9, iconName: "barcode_aluminium_cans.jpg" },
  { name: 'Beverage Carton', pk: 7, iconName: "barcode_beverage_carton.jpg"  },
  { name: 'Beverage Plastic Bottles', pk: 4, iconName: "barcode_beverage_palstic_bottles.jpg"  },
  { name: 'Glass Bottles', pk: 10, iconName: "barcode_glass_bottles.jpg"  },
  { name: 'Personal Care Plastic Bottles', pk: 5, iconName: "barcode_personal_care_plastic_bottles.jpg"  },
]

export const productVolume = [
  { name: 'mL', value: "ML" },
  { name: 'L', value: "L" },
  { name: 'g', value: "G" },
  { name: 'kg', value: "KG" },
  { name: "pcs", "value": "PCS" },
]

export const Errors = {
  productName: {
    name: 'productName',
    message: 'Please provide a product name'
  },
  productChName: {
    name: 'productChName',
    message: 'Please provide a product transalate name'
  },
  productType: {
    name: 'productType',
    message: 'Please provide a Recyclable Type'
  },
  linkedBrand: {
    name: 'linkedBrand',
    message: 'Please provide a linked brand'
  },
  codes: {
    name: 'codes',
    message: 'Please provide a valid code'
  },
  codesDuplicated: {
    name: 'codesDuplicated',
    message: 'this code is duplicated with existed codes'
  },
  volume: {
    name: 'volume',
    message: 'Please provide a valid volume info',
  },
  productImage: {
    name: 'productImage',
    message: 'Please provide a product image',
  },

  couponQuantity: {
    name: 'couponQuantity',
    message: 'Please provide a coupon Quantity'
  },
  overallLimit: {
    name: 'overallLimit',
    message: 'Over all Limit can not be greater than coupon stock'
  },
};

const stepNames = ['Content', 'Properties', 'Preview'];

const getInitialState = () => ({
  list: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    {
      displayName: 'Brand',
      fieldName: 'brandName',
    },
    {
      displayName: 'Recyclable Type',
      fieldName: 'displayProductTypeName',
    },
    {
      displayName: 'Photo',
      fieldName: 'productImage',
    },
    {
      displayName: 'Product Name',
      fieldName: 'productName',
    },
    {
      displayName: 'Rewards (Extra Pts)',
      fieldName: 'displayPoints',
    },
    {
      displayName: 'Rewards (coupon)',
      fieldName: 'couponQuantity',
    },
    {
      displayName: 'Vlumne',
      fieldName: 'displayVolume',
    },
    {
      displayName: 'Status',
      fieldName: 'status',
    },
  ],
  currentPageList: [],
  allList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  detail: {
    translations: {
      [LanguageConfig.english]: {},
      [LanguageConfig.simplifiedChinese]: {},
      [LanguageConfig.traditionalChinese]: {},
    },
  },
  errorFields: [],
  errorTab: LanguageConfig.english,
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  created: false,
  status: APIStatus.none
});


const parseObject = (data = {}) => {
  const translations = getTranlationsData(data)
  const status = data.isPublished ? PublishTagType.published : PublishTagType.unPublished
  return {
    ...data,
    status: status,
    displayVolume: `${data.volumeQuantity} ${data.volumeConversion}`,
    translations,
    displayProductTypeName: data.productType.name,
    brandName: data.linkedBrand?.name,
    displayPoints: data.points ? `${data.points}CWP` : '-',
    name: data.productName.toUpperCase()
  };
};

const getObjectInput = (detail = {}) => {
  console.log("getObjectInput:", detail)
  const data = {
    productType: detail.productType?.pk,
    linkedCouponSet: detail.linkedCouponSet?.pk || null,
    productName: detail.productName?.toUpperCase(),
    productImage: getFileNameFromUrl(detail.productImage),
    volumeQuantity: detail.volumeQuantity,
    volumeConversion: detail.volumeConversion,
    linkedBrand: detail.linkedBrand?.pk || null,
    sponsorPartner: detail.sponsorPartner?.pk || null,
    points: detail.points || null,
    overallLimit: detail.overallLimit || null,
    perHeadLimit: detail.perHeadLimit || null,
    overAllPeriodicLimit: detail.overAllPeriodicLimit || null,
    overAllPeriodicLimitEffectiveNumberOfDays: detail.overAllPeriodicLimitEffectiveNumberOfDays || null,
    perHeadPeriodicLimit: detail.perHeadPeriodicLimit || null,
    perHeadPeriodicLimitEffectiveNumberOfDays: detail.perHeadPeriodicLimitEffectiveNumberOfDays || null,
    couponQuantity: detail.couponQuantity || null,
    codes: detail.codes
  }
  if (detail.pk) {
    data.id = detail.pk
  }
  const changedTranslation = detail?.translations?.[LanguageConfig.traditionalChinese] || {}
  const translation = {
    language: LanguageConfig.traditionalChinese,
    productName: changedTranslation.productName
  }
  if (changedTranslation.pk) {
    translation.id = changedTranslation.pk
  }
  data.translations = [translation]
  return data
};
const checkStepOne = (data) => {
  console.log("typeof(data.volumeQuantity):", typeof (data.volumeQuantity))
  const errorFields = [];
  if (!data.productType?.pk) {
    errorFields.push(Errors.productType.name);
  }
  if (!data.productName) {
    errorFields.push(Errors.productName.name);
  }
  if (!data?.translations?.[LanguageConfig.traditionalChinese]?.productName) {
    errorFields.push(Errors.productChName.name);
  }
  if (!data.linkedBrand?.pk) {
    errorFields.push(Errors.linkedBrand.name);
  }
  if (!data.codes?.length) {
    errorFields.push(Errors.codes.name);
  }
  if (!data.volumeQuantity || !data.volumeConversion) {
    errorFields.push(Errors.volume.name);
  }
  if (data.volumeConversion && ["G", "ML", "PCS"].includes(data.volumeConversion)) {
    if (parseInt(data.volumeQuantity) != data.volumeQuantity) {
      errorFields.push(Errors.volume.name);
    }
  }
  if (!data.productImage) {
    errorFields.push(Errors.productImage.name);
  }
  if (typeof (data.productImage) === "object") {
    data.productImage = getImageUrl(data.productImage)
  }
  data.displayVolume = `${data.volumeQuantity} ${data.volumeConversion}`
  data.displayCodes = data.codes?.join(",")
  return {
    data,
    invalid: errorFields.length > 0,
    errorFields: errorFields,
  };
};

const checkStepTwo = (data, isBack) => {
  console.log("checkStepTwo:", data)
  if (isBack) {
    return {
      data,
      invalid: false,
      errorFields: []
    };
  }
  const errorFields = [];
  if (data?.linkedCouponSet?.pk) {
    if (!data?.couponQuantity) {
      errorFields.push(Errors.couponQuantity.name);
    }
    if (data.overallLimit && data.overallLimit > data?.linkedCouponSet.stock) {
      errorFields.push(Errors.overallLimit.name);
    }
  }
  return {
    data,
    invalid: errorFields.length > 0,
    errorFields: errorFields,
  };
};


export default {
  namespace: 'barcodes',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    clearState(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      const data = payload.data || state.detail;
      console.log("stepChange:", data)
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {}, };
      if (step === 0) {
        result = checkStepOne(data);
      }

      if (step === 1) {
        result = checkStepTwo(data, isBack);
      }
      const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );
      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }
      if (payload.afterAction) {
        payload.afterAction()
      }
      console.log("stepChange:", errorFields)
      return {
        ...state,
        detail: data,
        currentStep: step,
        stepConfig: stepConfig,
        errorFields: errorFields,
      };
    },
    saveOrRemoveMissionFromCookie(state, { payload }) {
      console.log("@@saveOrRemoveMissionFromCookie", payload)
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

  },
  effects: {
    loadObjectFromCookie: [
      function* ({ payload }, { put, select }) {
        const obj = getObjectFromSessionStorage(sessionDataKey.objectKey);
        console.log("barcodeLoadObject", obj);
        if (obj) {
          yield put({
            type: 'updateState',
            payload: {
              detail: obj,
            }
          })
        }
      }
    ],

    getCurrentPageList: [
      function* ({ payload }, { put, select }) {
        const { page, searchKey, sort } = payload;
        const existList = yield select(state => state.barcodes.allList)
        console.log("getBarcodeList:", payload)
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getList,
          {
            afterCursor,
            order: sort,
            search: searchKey,
            others: payload,
          }
        ];
        function* onSuccess(data) {
          const pageInfo = data.barcodes.pageInfo;
          const totalCount = data.barcodes.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const edges = data.barcodes.edges;
          const list = edges.map((item) =>
            parseObject(item.node),
          );
          const dataList = {}
          if (payload?.isSelectorLoad) {
            dataList.allList = payload.page > 1
              ? [...existList, ...list]
              : list
          } else {
            dataList.currentPageList = list
          }

          yield put(
            {
              type: "updateState", payload: {
                ...dataList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                },
                totalCount,
                currentLastCursor,
                totalPage: Math.ceil(totalCount / 20),
              }
            }
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getObj: [
      function* ({ payload }, { put, select }) {
        const { id } = payload;
        const btoaID = btoa(`BarcodeNode:${id}`);
        const serviceArgs = [getObject, btoaID];
        yield put({ type: "updateState", payload: { status: APIStatus.calling } })
        function* onSuccess(data) {
          const node = data.barcode;
          if (!node) {
            return
          }
          const obj = parseObject(node);
          yield put({
            type: "updateState", payload: {
              detail: obj,
              status: APIStatus.success
            }
          });

          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        function* onFailed(err) {
          yield put({ type: "updateState", payload: { status: APIStatus.failed } })
        }
        yield loading(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { put, select }) {
        const serviceArgs = [deleteObjects, payload?.deletePKs];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdateObj: [
      function* ({ payload }, { put, select }) {
        const id = payload?.id || null;
        const { detail } = yield select((state) => ({
          detail: state.barcodes.detail,
        }));
        const formattedObj = getObjectInput(detail);
        const serviceArgs = [id ? updateObject : createObject, formattedObj];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess(successData) {
          removeFromSessionStorage(sessionDataKey.objectKey);
          const barcodePk = successData?.createBarcode?.node?.pk || successData?.updateBarcode?.node?.pk;
          yield afterAction(true, barcodePk);
        }
        function* failed() {
          yield afterAction(false);
        }
        yield loading(serviceArgs, onSuccess, failed, failed)
      },
    ],
    publishOrUnpublishObj: [
      function* ({ payload }, { put }) {
        const serviceArgs = [payload?.publish ? publishObject : unPublishObject, { id: payload.id }];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    checkCode: [
      function* ({ payload }, { put }) {
        const serviceArgs = [checkBarcode, { code: payload.code }];
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess(data) {
          console.log("checkCode:", data)
          yield afterAction(data?.checkCode?.success);
        }
        function* onFailed() {
          yield afterAction(false);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
