import { APIStatus, LanguageConfig, CategoryType } from '../config/CustomEnums';
import { createCampaignCategory } from '../services/CampaignAPIHelper';
import {
  updateCampaignCategory,
  deleteCampaignCategories,
  getCampaignCategoryList,
} from '../services/CampaignCategoryAPIHelper';
import {
  createAction,
  convertPKToId,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { getCampaignCategory } from '../services/CampaignCategoryAPIHelper';
import { apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  category: {
    name: null,
    order: null,
    isForcedInactive: false,
    categoryType: CategoryType.BOTH,
    translations: [],
  },
  errorFields: [],
  formChanged: false,
  saved: -1,
  createStatus: APIStatus.none,
});

export const CAMPAIGN_CATEGORY_SESSION_KEY = 'CAMPAIGN_CATEGORY_SESSION_KEY';

export const CREATE_CAMPAIGN_CATEGORY_SUCCESS =
  'CREATE_CAMPAIGN_CATEGORY_SUCCESS';
export const CREATE_CAMPAIGN_CATEGORY_FAILD = 'CREATE_CAMPAIGN_CATEGORY_FAILED';

export const CAMPAIGN_CATEGORY_ORDER_LAST = 'CAMPAIGN_CATEGORY_ORDER_LAST';

const validateForm = (data) => {
  const { name, order, isForceInactive } = data;
  const errorFields = [];
  if (name === '' || name === null || name === undefined) {
    errorFields.push({
      field: 'name',
      message: 'Please provide a name',
    });
  }
  const displayPriority = parseInt(order);
  if (isNaN(displayPriority)) {
    errorFields.push({
      field: 'order',
      message: 'Minimum value is 1',
    });
  }
  return errorFields;
};

const assembleCampaignCategory = (category) => {
  const translations = [];
  const apiTranslations = category.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    }
    if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    translations.push({
      name: item.node.name,
      id: item.node.pk,
      language,
    });
  });
  return {
    name: category.name,
    order: category.displayPriority,
    isForcedInactive: category.isForcedInactive,
    categoryType: category.categoryType || CategoryType.BOTH,
    translations,
  };
};

export default {
  namespace: 'createCampaignCategory',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    reset(state, { payload }) {
      return getInitialState();
    },
    assembleCampaignCategory(state, { payload }) {
      const { category } = payload;
      return {
        ...state,
        category: assembleCampaignCategory(category),
      };
    },
    loadCampaignCategoryFromSessionStorage(state, { payload }) {
      try {
        const category = getObjectFromSessionStorage(
          CAMPAIGN_CATEGORY_SESSION_KEY,
        );
        if (category) {
          return {
            ...state,
            category: {
              ...category,
            },
          };
        }
      } catch (error) {
        console.log(error);
      }
      return {
        ...state,
      };
    },
    formHasChanged(state, { payload }) {
      return {
        ...state,
        formChanged: true,
      };
    },
  },
  effects: {
    getCategory: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('CampaignCategoryNode', payload.id);
        const serviceArgs = [getCampaignCategory, id];
        function* onSuccess(data) {
          console.log('getOneCampaign onSuccess :', data);
          yield all([
            put({
              type: 'assembleCampaignCategory',
              payload: { category: data.campaignCategory },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.none },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneCampaignCategory onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneCampaignCategory arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    checkAndSave: [
      function* ({ payload }, { call, select, put, take, race }) {
        const {
          name,
          translations,
          order,
          isForcedInactive,
          categoryType,
        } = payload;
        const errorFields = validateForm(payload);
        if (errorFields && errorFields.length > 0) {
          yield put({ type: 'updateState', payload: { errorFields } });
          return;
        }
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        yield put(
          createAction('createCampaignCategory')({
            enCategory: name,
            simpleCategory: translations.filter(
              (t) => t.language === LanguageConfig.simplifiedChinese,
            )[0]?.name,
            traditionalCategory: translations.filter(
              (t) => t.language === LanguageConfig.traditionalChinese,
            )[0]?.name,
            priority: order,
            isForcedInactive,
            categoryType: categoryType,
          }),
        );
        const [success, failed] = yield race([
          take(CREATE_CAMPAIGN_CATEGORY_SUCCESS),
          take(CREATE_CAMPAIGN_CATEGORY_FAILD),
        ]);
        if (success) {
          yield put({
            type: 'updateState',
            payload: { formChanged: false, createStatus: APIStatus.success },
          });
          removeFromSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY);
          console.log(success);
        } else {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          console.log(failed);
        }
      },
      { type: 'takeLatest' },
    ],
    checkAndUpdate: [
      function* ({ payload }, { call, select, put, all }) {
        const {
          name,
          translations,
          order,
          isForcedInactive,
          categoryID,
          categoryType,
        } = payload;
        if (categoryID === null || categoryID === undefined) {
          return;
        }
        const errorFields = validateForm(payload);
        if (errorFields && errorFields.length > 0) {
          yield put({ type: 'updateState', payload: { errorFields } });
          return;
        }
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });

        const category = yield select(
          (state) => state.createCampaignCategory.category,
        );
        console.log('@@250: ', category);

        const SCName = translations.filter(
          (t) => t.language === LanguageConfig.simplifiedChinese,
        )[0]?.name;
        const TCName = translations.filter(
          (t) => t.language === LanguageConfig.traditionalChinese,
        )[0]?.name;

        const input = {
          name,
          displayPriority: parseInt(order),
          isForcedInactive,
          id: categoryID,
          categoryType: categoryType,
          translations: category.translations
            ? category.translations.map((item) => ({
                ...item,
                name:
                  item.language === LanguageConfig.simplifiedChinese
                    ? SCName
                    : TCName,
              }))
            : [
                {
                  language: LanguageConfig.simplifiedChinese,
                  name: SCName,
                },
                {
                  language: LanguageConfig.traditionalChinese,
                  name: TCName,
                },
              ],
        };
        console.log('@@274: ', input);

        const serviceArgs = [updateCampaignCategory, input];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { formChanged: false, createStatus: APIStatus.success },
          });
          removeFromSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY);
        }
        function* onFailed(response) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    createCampaignCategory: [
      function* ({ payload }, { call, put, all }) {
        const {
          enCategory,
          simpleCategory,
          traditionalCategory,
          priority,
          isForcedInactive,
          categoryType,
        } = payload;
        console.log('categoryType:', categoryType);
        const input = {
          name: enCategory,
          displayPriority: parseInt(priority),
          isForcedInactive,
          categoryType: categoryType,
          translations: [
            {
              language: LanguageConfig.simplifiedChinese,
              name: simpleCategory,
            },
            {
              language: LanguageConfig.traditionalChinese,
              name: traditionalCategory,
            },
          ],
        };
        const serviceArgs = [createCampaignCategory, input];

        function* onSuccess(data) {
          yield put({ type: CREATE_CAMPAIGN_CATEGORY_SUCCESS });
        }
        function* onFailed(response) {
          yield put({ type: CREATE_CAMPAIGN_CATEGORY_FAILD });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCategoryActiveStatus: [
      function* ({ payload }, { call, put, all }) {
        const { id, isForcedInactive, afterAction } = payload;
        const input = {
          id,
          isForcedInactive,
        };
        const serviceArgs = [updateCampaignCategory, input];

        yield apiWithResponseHandle(serviceArgs);

        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    updateCategoryOrder: [
      function* ({ payload }, { call, put, select }) {
        const { id, order, afterAction } = payload;
        const input = {
          id,
        };
        let getCampaignCategoryListError = false;
        if (order === CAMPAIGN_CATEGORY_ORDER_LAST) {
          const serviceArgs = [getCampaignCategoryList, '', ''];

          function* onSuccess(data) {
            input.displayPriority = data?.campaignCategories?.totalCount;
          }
  
          function* onFailed(response) {
            getCampaignCategoryListError = ture;
          }

          yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
          if (getCampaignCategoryListError) {
            return;
          };
        } else {
          input.displayPriority = order;
        }
        const updateServiceArgs = [updateCampaignCategory, input];

        yield apiWithResponseHandle(updateServiceArgs);

        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    deleteCategories: [
      function* ({ payload }, { call, put, select }) {
        const { ids, afterAction } = payload;
        const input = {
          ids,
        };
        const serviceArgs = [deleteCampaignCategories, input];

        yield apiWithResponseHandle(serviceArgs);

        if (afterAction) {
          afterAction();
        } else {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success },
          });
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    duplicateCategory: [
      function* ({ payload }, { call, put, race, take }) {
        const { pk, afterAction } = payload;
        const id = convertPKToId('CampaignCategoryNode', pk);

        const serviceArgs = [getCampaignCategory, id];

        function* onSuccess(data) {
          const category = assembleCampaignCategory(
            data?.campaignCategory,
          );
  
          yield put(
            createAction('createCampaignCategory')({
              enCategory: `copy of ${category.name}`,
              simpleCategory:
                category.translations[LanguageConfig.simplifiedChinese]?.name,
              traditionalCategory:
                category.translations[LanguageConfig.traditionalChinese]?.name,
              priority: category.order + 1,
              isForcedInactive: category.isForcedInactive,
            }),
          );
          if (afterAction) {
            afterAction();
          }
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      {
        type: 'takeEvery',
      },
    ],
  },
};
