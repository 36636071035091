import React from 'react';
import { connect, useDispatch } from 'react-redux';
import CustomAddRemoveMutipleSelector from '../../../components/customer/CustomAddRemoveMutipleSelector';
import { defaultOrder } from '../../../utils/OrderUtils';

function GuideImageGeneralSection({
  guideDetail,
  receiptPartnerList,
  oclPartnerList,
}) {
  const dispatch = useDispatch();

  const {
    receiptPartner = [],
    oclPartner = [],
  } = guideDetail;

  const orderSelectedListFunction = (previousItem, currentItem) => {
    return defaultOrder(previousItem?.name, currentItem?.name)
  };

  const orderedReceiptPartnerList = receiptPartnerList.sort(orderSelectedListFunction);
  const orderedOclPartnerList = oclPartnerList.sort(orderSelectedListFunction);

  return (
    <>
      <label className="create-section-title">GENERAL</label>
      <label className="create-section-label guide-image-title-partners">PARTNERS</label>

      <CustomAddRemoveMutipleSelector
        title='OCL Merchants list'
        data={orderedOclPartnerList}
        selectedList={oclPartner}
        orderSelectedListFunction={orderSelectedListFunction}
        setValue={(value) => {
          dispatch({
            type: 'guideImage/updateGuide',
            payload: {
              oclPartner: value,
            }
          })
        }}
        isSourceReminderLocalFilterable={true}
        isAmidRreminderLocalFilterable={true}
        sourceReminderText='Select OCL Merchant'
        amidReminderText={`Please select OCL Merchant (${oclPartner?.length || 0})`}
        error={{}}
      />

      <CustomAddRemoveMutipleSelector
        title='Receipt Merchants list '
        data={orderedReceiptPartnerList}
        selectedList={receiptPartner}
        orderSelectedListFunction={orderSelectedListFunction}
        setValue={(value) => {
          console.log("@@61", value)
          dispatch({
            type: 'guideImage/updateGuide',
            payload: {
              receiptPartner: value,
            }
          })
        }}
        isSourceReminderLocalFilterable={true}
        isAmidRreminderLocalFilterable={true}
        sourceReminderText='Select Receipt Merchant'
        amidReminderText={`Please select Receipt Merchant (${receiptPartner?.length || 0})`}
        error={{}}
      />

    </>
  );
}

const mapStateToProps = (state) => ({
  guideDetail: state.guideImage.guideDetail,
  receiptPartnerList: state.brand.receiptPartnerList,
  oclPartnerList: state.brand.oclPartnerList,
});

export default connect(mapStateToProps)(GuideImageGeneralSection);
