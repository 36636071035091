import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from '../earning/CustomBaseComponments';
import CustomMultipleSelect from '../base/CustomMultipleSelect';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import CustomRadios from '../base/CustomRadios';
import { useCompare, isNumber, createAction } from '../../utils';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import BranchLine from '../../assets/images/branchLinex3.png';

const ViewCampaignTypeEnum = {
  viewTimes: 'VIEW_TIMES',
  stayTimes: 'STAY_TIME',
};

const getCampaignType = (groupName) => {
  if (groupName === 'couponCampaignCharacteristic') {
    return 'COUPON_CAMPAIGN';
  };
  if (groupName === 'earningCampaignCharacteristic') {
    return 'EARNING_CAMPAIGN';
  };
  return '';
};

function CampaignBehavioralSection(props) {
  const title = props.title;
  const groupName = props.groupName || 'viewCampaignCharacteristic';
  const conditionIndex = props.conditionIndex;

  const defaultValue = props.defaultValue || {};
  // const campaignList = props.campaignList || [];
  // const campaignCategoryList = props.campaignCategoryList || [];

  const onReset = props.isReset;
  const changeReset = props.changeReset || (() => {});
  const isCreate = props.isCreate;

  const [viewType, setViewType] = useState(defaultValue.viewType);
  const [viewValue, setViewValue] = useState(defaultValue.viewValue);

  const [viewedTimeType, setViewedTimeType] = useState(
    defaultValue.viewedTimeType || ViewCampaignTypeEnum.viewTimes,
  );

  const [viewTimes, setViewTimes] = useState(defaultValue.viewTimes);

  const { campaignList, campaignCategoryList } = useSelector((state) => ({
    campaignList: state.campaignList.allCampaignList,
    campaignCategoryList: state.campaignCategoryList.categoryList,
  }));

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
      },
    }));
  };

  const dispatch = useDispatch();
  const vals = { viewType, viewValue, viewedTimeType, viewTimes };
  const hasValsChanged = useCompare(vals);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({
        type: 'segments/changeVals',
        payload: {
          vals,
          groupName,
          conditionIndex,
        },
      });
    }
  });

  useEffect(() => {
    dispatch(
      createAction('campaignList/getCampaignList')({
        isSelectorLoad: true,
        type: getCampaignType(props.groupName),
      }),
    );
    dispatch(
      createAction('campaignCategoryList/getCampaignCategoryList')({
        isSelectorLoad: true,
      }),
    );
  }, []);

  return (
    <div>
      <CustomTitleLabel title={title} />
      <div className="d-flex flex-column">
        <div style={{ display: 'flex' }}>
          <DropDownWithDefaultSelect
            actions={dropDownOptions(
              ['Campaign', 'Campaign category'],
              (value) => {
                setViewType(value);
                setViewValue(null);
              },
            )}
            defaultValue={viewType}
          />
          <label className="content-campaign-related-to margin-right-10-percent">
            related to
          </label>
          <CustomTitleWithDropDown
            setValue={setViewValue}
            source={
              viewType === 'Campaign category'
                ? campaignCategoryList
                : props.groupName === 'couponCampaignCharacteristic'
                ? campaignList.filter((val) => val.type === 'COUPON_CAMPAIGN')
                : props.groupName === 'earningCampaignCharacteristic'
                ? campaignList.filter((val) => val.type === 'EARNING_CAMPAIGN')
                : campaignList
            }
            defaultValue={viewValue}
            loadMoreAction={
              viewType === 'Campaign'
                ? 'campaignList/getCampaignList'
                : 'campaignCategoryList/getCampaignCategoryList'
            }
            filterAction={
              viewType === 'Campaign'
                ? 'campaignList/getCampaignList'
                : 'campaignCategoryList/getCampaignCategoryList'
            }
            defaultFilter={{type: getCampaignType(props.groupName)}}
          />

          {/* <DropDownWithDefaultSelect
            actions={dropDownOptions(
              ['1 day', '7 days', '1 month', '3 months', '6 months', '1 year'],
              setPeriodDays,
            )}
            defaultValue={periodDays}
          /> */}
        </div>
        <div className="display-flex-area">
          <Image src={BranchLine} className="content-campaign-branchLine" />
          {groupName === 'viewCampaignCharacteristic' ? (
            <div className="d-flex flex-column">
              <div className="display-flex-area create-section-label create-section-label-bottom-space ">
                <CustomRadios
                  labelClass="font-weight"
                  onChange={(value) => {
                    setViewedTimeType(value);
                  }}
                  default={viewedTimeType}
                  options={[
                    {
                      label: 'Number of times viewing the campaign',
                      value: ViewCampaignTypeEnum.viewTimes,
                    },
                    {
                      label: 'Period of time staying in the campaign',
                      value: ViewCampaignTypeEnum.stayTimes,
                    },
                  ]}
                />
              </div>
              <InputWithMutipleDropDown
                conditionIndex={conditionIndex}
                groupName={groupName}
                hasNumber
                rangeOptions={
                  viewedTimeType === ViewCampaignTypeEnum.viewTimes
                    ? null
                    : ['Mins', 'H']
                }
                hasPeriodDropdown={
                  viewedTimeType === ViewCampaignTypeEnum.viewTimes
                }
                defaultNumber={defaultValue.number}
                defaultRange={defaultValue.periodRange}
                defaultPeriod={defaultValue.periodDays}
              />
            </div>
          ) : (
            <>
              <CustomNumberInputWithUnit
                title={'Number of times participating the campaign'}
                unit={'times'}
                setValue={setViewTimes}
                defaultValue={viewTimes}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CampaignBehavioralSection;
