import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import addImage from '../../assets/images/addx1.png';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { useCompare } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import { CreateMissionError } from '../../models/MissionModel';
import BasePrompt from '../base/prompt/BasePrompt';
import './RewardSection.scss';
import { LanguageConfig, MissionType } from '../../config/CustomEnums';

function RewardSection({
  onSubmit = false,
  onSubmitAction = () => {},
  points,
  couponTemplate,
  couponQuantity,
  missionType,
  translations = {},
  errorFields,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const fromCommunity = history.location?.state?.fromCommunity || {};
  const fromCommunityPathName = fromCommunity?.pathname || null;
  const communityId = parseInt(fromCommunity?.pathname?.split('/')?.[2] || '') || null;

  const isHabitual = missionType === MissionType.habitual;
  const enHabitualLevels = translations?.[LanguageConfig.english]?.habitualLevels || [];

  const [missionPoints, setMissionPoints] = useState(points);
  const [missionCouponTemplate, setMissionCouponTemplate] = useState(couponTemplate);
  const [missionCouponQuantity, setMissionCouponQuantity] = useState(couponQuantity);
  const [habitualLevels, setHabitualLevels] = useState(enHabitualLevels);
  const [showCreateNewCouponPrompt, setShowCreateNewCouponPrompt] = useState(false);

  const updateHabitualLevels = (field, value, index) => {
    const tempArray = [].concat(habitualLevels);
    tempArray[index] = {
      ...habitualLevels[index],
      [field]: value,
    };

    setHabitualLevels(tempArray);
  };

  const vals = isHabitual
    ? {
        habitualLevels,
        isHabitual,
        language: LanguageConfig.english,
      }
    : {
        points: missionPoints,
        couponTemplate: missionCouponTemplate,
        couponQuantity: missionCouponQuantity,
      };
  const hasValchanged = useCompare(vals);

  useEffect(() => {
    if (hasValchanged) {
      dispatch({
        type: `mission/${isHabitual ? 'updateMissionDetailTranslations' : 'updateMissionDetail'}`,
        payload: vals,
      });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${CreateMissionError.rewards.name}`, errorFields);
  }, [errorFields]);

  const isError = (field) => {
    return isShowError(field, errorFields);
  };

  const getRewardDetailSection = ({
    title = '',
    hasBorderTop = false,
    missionPoints,
    missionCouponQuantity,
    missionCouponTemplate,
    setMissionPoints,
    setMissionCouponQuantity,
    setMissionCouponTemplate,
    missionRewardsErrorName = '',
    missionPointsErrorName,
    missionCouponQuantityErrorName,
  }) => (
    <div className={`d-flex flex-column reward-detail-container ${hasBorderTop ? '' : 'no-top-border'}`}>
      {title ? <CustomTitleLabel title={title} /> : null}
      {isError(missionRewardsErrorName) ? (
        <label
          className={`tips-message mission-rewards-error`}
          id={missionRewardsErrorName}
        >
          {'Fill in either one reward(points or coupon or both)'}
        </label>
      ) : null}

      <CustomTitleLabel title="No of points" />
      <div style={{ display: 'flex' }}>
        <input
          type="number"
          onChange={({ target }) => setMissionPoints(target.value)}
          className="custom-number-input-short"
          value={missionPoints}
          min={0}
          onFocus={() => {}}
        />
        <label className="help-message margin-left-10-percent">
          {'points'}
        </label>
      </div>

      <ErrorFieldMessage
        id={missionPointsErrorName}
        error={isError(missionPointsErrorName)}
        message={CreateMissionError.points.message}
      />

      <CustomTitleLabel title="Quantity" />
      <div style={{ display: 'flex' }}>
        <input
          type="number"
          onChange={({ target }) => setMissionCouponQuantity(target.value)}
          className="custom-number-input-short"
          value={missionCouponQuantity}
          min={0}
          onFocus={() => {}}
        />
        <label className="help-message margin-left-10-percent">
          {'coupons per number'}
        </label>
      </div>

      <ErrorFieldMessage
        id={missionCouponQuantityErrorName}
        error={isError(missionCouponQuantityErrorName)}
        message={CreateMissionError.couponQuantity.message}
      />

      <CouponSelectDropdown
        title={'Coupon set'}
        defaultValue={{
          value: missionCouponTemplate,
          label: missionCouponTemplate?.name,
        }}
        setValue={setMissionCouponTemplate}
        addButtonAction={() => setShowCreateNewCouponPrompt(true)}
        isPrompt
        showRemoveButton={true}
        onRemoveButtonClick={() => setMissionCouponTemplate(null)}
        errors={errorFields}
        errorName={null}
        addButtonInfo={{
          requires: PermissionCodes.addCouponTemplate,
          children: <Image src={addImage} />,
          action: () => setShowCreateNewCouponPrompt(true),
          customClass: 'earning-rule-add-new-link-button',
        }}
      />

      <BasePrompt
        show={showCreateNewCouponPrompt}
        closeAction={() => setShowCreateNewCouponPrompt(false)}
        rightButton={{
          text: 'Go to create coupon set',
          action: () => {
            history.push({
              pathname: '/coupons/create',
              state: {
                from: fromCommunityPathName
                ? (params.id ? `/communities/${communityId}/target/${params.id}/edit/` : `/communities/${communityId}/target/create`)
                : (params.id ? `/mission/${params.id}/edit/` : '/mission/create'),
                title: `Continue to Create ${fromCommunityPathName ? "community target" : "mission"}`,
                content: `You can continue to create the ${fromCommunityPathName ? "community target" : "mission"}.`,
              },
            });
          },
        }}
        title={'Go to create a coupont set?'}
        description={
          'You will leave the mission creation process. After you create a coupon set, you can come back.'
        }
      />
    </div>
  );

  return (
    <>
      <label className="create-section-title">Reward</label>
      <label
        className={`tips-message ${
          isError(CreateMissionError.rewards.name)
            ? 'mission-rewards-error'
            : ''
        }`}
        id={CreateMissionError.rewards.name}
      >
        {'Fill in either one reward(points or coupon or both)'}
      </label>

      {isHabitual
        ? habitualLevels.map((item, index) =>
            getRewardDetailSection({
              title: `Level ${item.levelGrade} reward`,
              hasBorderTop: index !== 0,
              missionPoints: item.numberOfPoints,
              missionCouponQuantity: item.couponQuantity,
              missionCouponTemplate: item.couponTemplate,
              setMissionPoints: (value) => {
                updateHabitualLevels('numberOfPoints', value, index);
              },
              setMissionCouponQuantity: (value) => {
                updateHabitualLevels('couponQuantity', value, index);
              },
              setMissionCouponTemplate: (value) => {
                updateHabitualLevels('couponTemplate', value, index);
              },
              missionRewardsErrorName: `level${item.levelGrade}Rewards`,
              missionPointsErrorName: `level${item.levelGrade}NumberOfPoints`,
              missionCouponQuantityErrorName: `level${item.levelGrade}CouponQuantity`,
            })
          )
        : getRewardDetailSection({
            missionPoints,
            missionCouponQuantity,
            missionCouponTemplate,
            setMissionPoints,
            setMissionCouponQuantity,
            setMissionCouponTemplate,
            missionPointsErrorName: CreateMissionError.points.name,
            missionCouponQuantityErrorName: CreateMissionError.couponQuantity.name,
          })}
    </>
  );
}

const mapStateToProps = (state) => ({
  points: state.mission.missionDetail.points,
  couponTemplate: state.mission.missionDetail.couponTemplate,
  couponQuantity: state.mission.missionDetail.couponQuantity,
  missionType: state.mission.missionDetail.missionType,
  translations: state.mission.missionDetail.translations,
  errorFields: state.mission.errorFields,
});

export default connect(mapStateToProps)(RewardSection);
