import {
  getCouponTransactions,
  getCouponTransaction,
} from '../services/TransactionRecordsAPIHelper';
import { parseExpiredDate } from './CouponListModel';
import {
  createAction,
  convertCursorToNumber,
  capitalizeFirstLetter,
} from '../utils';
import { loading } from './LoadingUtil';
import { formatDate, getDisplayDate } from '../utils/TimeFormatUtil';

const getInitialState = () => ({
  couponTransactionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    {
      displayName: 'Name (preferred name)',
      fieldName: 'name',
      linked: false,
      orderField: 'customerFirstName',
    },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    { displayName: 'Record Type', fieldName: 'displayType' },
    { displayName: 'Coupon ID', fieldName: 'couponID', orderField: 'coupon' },
    {
      displayName: 'Coupon Set Name',
      fieldName: 'couponTemplateName',
      orderField: 'couponTemplateName',
    },
    {
      displayName: 'Expiry Date',
      fieldName: 'couponExpiryDate',
    },
    {
      displayName: 'Create at',
      fieldName: 'creationDate',
      orderField: 'creationDate',
    },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageCouponTransactionList: [],
  selectedCouponTransaction: {},
});

const parseCouponTransactionType = (type) => {
  let displayType = '';
  switch (type) {
    case 'EARN':
      displayType = 'Customer earned';
      break;
    case 'BUY':
      displayType = 'Customer acquired';
      break;
    case 'GRANT':
      displayType = 'Admin added';
      break;
    case 'RECLAIM':
      displayType = 'Admin removed';
      break;
    case 'USE':
      displayType = 'Customer use';
      break;
    case 'EXPIRED':
      displayType = 'Coupon expired';
      break;
    default:
      break;
  }
  return displayType;
};

const getEventType = (transactionType) => {
  let eventType = '';
  switch (transactionType) {
    case 'EARN':
    case 'BUY':
    case 'GRANT':
      eventType = 'Coupon in';
      break;
    case 'RECLAIM':
    case 'USE':
    case 'EXPIRED':
      eventType = 'Coupon out';
      break;
    default:
      break;
  }
  return eventType;
};

const parseCouponTransaction = (item) => {
  return {
    pk: item.pk,
    id: item.id,
    membershipId: item.customer?.membershipId,
    ssoUid: item.customer?.ssoUid,
    originType: item.type,
    displayType: parseCouponTransactionType(item.type),
    pointsSpent: item.pointsSpent,
    creationDate: getDisplayDate(item.creationDate),
    usedDate: formatDate(item.usedAt, 'DD MMM yyyy (ddd), HH:mm a'),
    name: item.customer
      ? item.customer?.nickname
        ? `${item.customer?.firstName} ${item.customer?.lastName} (${item.customer?.nickname})`
        : `${item.customer?.firstName} ${item.customer?.lastName}`
      : null,
    campaignName: item.campaign?.name,
    earningRuleName: item.campaign?.earningCampaignTypeEarningRule?.name,
    couponID: item.coupon?.pk,
    couponTemplateName: item.coupon?.template?.name,
    couponTemplateNameWithSerialNumber: `${capitalizeFirstLetter(
      item.type.toString().toLowerCase(),
    )} ${item.coupon?.template?.name}(${item.coupon?.serialNumber})`,
    couponExpiryDate: item.coupon?.template
      ? parseExpiredDate(item.coupon?.template)
      : null,
    administratorName: item.administrator?.username,
    createdDate: formatDate(item.creationDate),
    eventType: getEventType(item.type),
  };
};

export default {
  namespace: 'couponTransactions',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    getCouponTransactions: [
      function* ({ payload }, { call, put }) {
        // const { afterCursor } = payload;
        const serviceArgs = [
          getCouponTransactions,
          null,
          { ...payload, rank: true },
        ];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          yield put(
            createAction('updateState')({
              couponTransactionList: couponTransactionList,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageCouponTransactions: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        console.log('@@158: ', afterCursor);
        const serviceArgs = [getCouponTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const pageInfo = data.couponTransactions.pageInfo;
          const totalCount = data.couponTransactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPageCouponTransactionList: couponTransactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSingleCouponTransactionRecord: [
      function* ({ payload }, { put }) {
        const { couponTransactionPK } = payload;
        const transactionID = btoa(
          `CouponTransactionNode:${couponTransactionPK}`,
        );
        const serviceArgs = [getCouponTransaction, transactionID];
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              selectedCouponTransaction: parseCouponTransaction(
                data.couponTransaction,
              ),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
