import { BaseHelper } from './BaseGraphQLAPIHelper';

const rewardCodeNode = `
  id
  pk
  name
  creationDate
  lastModifiedDate
  linkedBrand {
      pk
      name
      translations {
        edges{
          node{
            pk
            language
            name
          }
        }
      }
  }
  codeSetType
  codeSetCategory
  code
  totalNumberOfSingleCodes
  usedCodes
  totalCodes
  rewardType
  linkedCouponSet {
      pk
      name
  }
  couponQuantity
  points
  perHeadLimit
  startDate
  endDate
  isExpired
  isPublished
  publishedDate
  translations {
      edges{
          node{
              pk
              language
              name
          }
      }
  }
`;

export const getRewardCodes = ({
  afterCursor = '',
  order,
  search = '',
  others = {},
}) => {
  console.log("@@47 getRewardCodes", others)
  let orderBy = '-pk';
  if (order) {
    orderBy = order;
  };
  let userFilter = '';
  // TODO
  // if (others?.category) {
  //   userFilter += `, codeSetCategory: "${others?.category}"`;
  // }
  userFilter += `, codeSetCategory: "REGISTRATION_CODE"`

  if (others?.code_type) {
    userFilter += `, codeSetType: "${others?.code_type}"`;
  }
  if (others?.reward_type) {
    userFilter += `, rewardType: "${others?.reward_type}"`;
  }
  if (others?.brand) {
    userFilter += `, brandIn: "${others?.brand}"`;
  }
  if (others?.status) {
    userFilter += `, isPublished: ${others?.status}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others?.expired}`;
  }
  if (others?.active_period) {
    const activePeriod = others?.active_period?.split(',');
    userFilter += `, startDateGte: "${activePeriod[0]}", endDateLte: "${activePeriod[1]}"`;
  }

  const query = `
  {
    rewardCodeSets(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains: "${search}"${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          creationDate
          lastModifiedDate
          name
          linkedBrand {
            pk
            name
          }
          codeSetType
          codeSetCategory
          usedCodes
          totalCodes
          rewardType
          startDate
          endDate
          isPublished
          isExpired
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getRewardCode = (id) => {
  const query = `
  {
    rewardCodeSet(id: "${id}") {
      ${rewardCodeNode}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createRewardCode = (inputBody) => {
  const query = `
  mutation CreateRewardCodeSet($input: CreateRewardCodeSetInput!) {
    createRewardcodeset(input: $input) {
      node {
        ${rewardCodeNode}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateRewardCode = (inputBody) => {
  const query = `
  mutation UpdateRewardCodeSet($input: UpdateRewardCodeSetInput!) {
    updateRewardcodeset(input: $input) {
      node {
        ${rewardCodeNode}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteRewardCodes = (ids) => {
  const query = `
  mutation DeleteRewardCodeSets($input: DeleteRewardCodeSetsInput!) {
    deleteRewardcodesets(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishRewardCode = (input) => {
  const query = `mutation PublishRewardCodeSet($input: PublishRewardCodeSetInput!) {
    publishRewardcodeset(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishRewardCode = (input) => {
  const query = `mutation UnpublishRewardCodeSet($input: UnpublishRewardCodeSetInput!) {
    unpublishRewardcodeset(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getRewardSingleCodes = ({
  afterCursor = '',
  order,
  search = '',
  others = {},
}) => {
  console.log("@@214 getRewardSingleCodes", others)
  let orderBy = '-pk';
  if (order) {
    orderBy = order;
  };
  let userFilter = '';
  if (others?.inRewardCodeSet) {
    userFilter += `, inRewardCodeSet: "${others?.inRewardCodeSet}"`;
  }
  if (others?.status) {
    userFilter += `, isActive: ${others?.status}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others?.expired}`;
  }
  if (others?.used) {
    userFilter += `, isUsed: ${others?.used}`;
  }

  const query = `
  {
    rewardSingleCodes(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains: "${search}"${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          creationDate
          lastModifiedDate
          code
          usedAt
          customer {
              pk
              firstName
              lastName
          }
          isActive
          isExpired
          isUsed
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteRewardSingleCodes = (ids) => {
  const query = `
  mutation DeleteRewardSingleCodes($input: DeleteRewardSingleCodesInput!) {
    deleteRewardsinglecodes(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
