import React, { useState, useEffect } from 'react';
import './CreateCategoryPrompt.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
const CategoryList = ['English', '简体（可选）', '繁體（可選）'];

function CreateCategoryPrompt(props) {
  const defaultShow = props.show;
  const onClose = props.onClose || (() => {});
  const [show, setShow] = useState(false);
  const [enCategory, setEnCategory] = useState();
  const [simpleCategory, setSimpleCategory] = useState();
  const [traditionalCategory, setTraditionalCategory] = useState();
  const [priority, setPriority] = useState();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => ({
    categories: state.campaignCategoryList.categoryList,
  }));
  const handleClose = () => {
    setShow(false);
    onClose(true);
  };
  const startNewSection = () => {
    dispatch(
      createAction('createCampaign/createCamapigncategory')({
        enCategory,
        simpleCategory,
        traditionalCategory,
        priority,
      }),
    );
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const setNewCategory = (value, type) => {
    if (type === CategoryList[0]) {
      setEnCategory(value);
      return;
    }
    if (type === CategoryList[1]) {
      setSimpleCategory(value);
      return;
    }
    setTraditionalCategory(value);
  };

  if (!show && defaultShow) {
    setShow(true);
  }

  const translation = (category, index) => {
    return (
      <div
        key={`${category}-${index}`}
        className="create-category-prompt-input-item"
      >
        <label className="create-section-label create-section-label-bottom-space">
          {category}
        </label>
        <input
          className="create-category-prompt-input-text"
          type="text"
          onChange={({ target }) => setNewCategory(target.value, category)}
        />
      </div>
    );
  };

  const getInputArea = () => {
    return (
      <>
        <div className="create-category-prompt-input">
          {CategoryList.map((category, index) => translation(category, index))}
        </div>
        <div
          key={`category-priority`}
          className="create-category-prompt-input-item"
        >
          <label className="create-section-label create-section-label-bottom-space">
            Display order
          </label>
          <input
            type="number"
            min="1"
            className="create-category-prompt-input-text"
            onChange={({ target }) => setPriority(target.value)}
          />
          <label className="grey-caution-of-priority">
            Minimum value is 1. The smaller value, the higher priority.
          </label>
        </div>
      </>
    );
  };
  return (
    <BasePrompt
      show={show}
      closeAction={handleClose}
      rightButton={{
        text: 'Save',
        action: startNewSection,
      }}
      title={'Create New Campaign Category'}
      description={
        'Please enter a new campaign category with its translation below:'
      }
      otherBody={getInputArea}
    />
  );
}

export default CreateCategoryPrompt;
