import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateGreetingSectionError } from '../../models/GreetingSectionModel';
import { isShowError } from '../../utils';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

function ActivePeriodSection({
  activeStartDate,
  activeEndDate,
  errorFields,
}) {
  const dispatch = useDispatch();

  const updateGreetingSectionDetail = (payload) => {
    dispatch({
      type: 'greetingSection/updateGreetingSectionDetail',
      payload,
    });
  };

  return (
    <>
      <label className="create-section-title">ACTIVE PERIOD</label>
      <label className="section-short-description">Theme available time.</label>
      <label className="create-section-label create-section-label-bottom-space">
        Start date
      </label>
      <CustomDateTimeSelect
        disabled={false}
        defaultTime={activeStartDate}
        maxDate={null}
        onTimeChange={(value) => {
          updateGreetingSectionDetail({ activeStartDate: value })
        }}
        filterDate={(date) => {
          return true;
        }}
        hideTime={false}
      />
      <label className="create-section-label create-section-label-bottom-space">
        End date
      </label>
      <CustomDateTimeSelect
        error={isShowError(CreateGreetingSectionError.activeEndDate.name, errorFields)}
        minDate={null}
        defaultTime={activeEndDate}
        disabled={false}
        onTimeChange={(value) => {
          updateGreetingSectionDetail({ activeEndDate: value })
        }}
        filterDate={(date) => {
          return true;
        }}
        hideTime={false}
      />
      <ErrorFieldMessage
        id={CreateGreetingSectionError.activeEndDate.name}
        error={isShowError(CreateGreetingSectionError.activeEndDate.name, errorFields)}
        message={CreateGreetingSectionError.activeEndDate.message}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  activeStartDate: state.greetingSection.greetingSection.activeStartDate,
  activeEndDate: state.greetingSection.greetingSection.activeEndDate,
  errorFields: state.greetingSection.errorFields,
});

export default connect(mapStateToProps)(ActivePeriodSection);
