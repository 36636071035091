import moment from 'moment';
import { AmOrPm } from '../config/CustomEnums';

export const TimeFormater = {
  dayMonthWeek: 'DD MMM (ddd)',
  dayMonthYearWeek: 'D MMM YYYY (ddd)',
  dayMonthYearWeekTime: 'DD MMM YYYY (ddd),HH:mm:ss',
  dayMonthYearWeekTimeWithComma: 'D/M/YYYY(ddd),HH:mm:ss',
  dayMonthYearWeekTimeA: 'DD MMM YYYY (ddd),HH:mm a',
  dayMonthYear: 'DD MMM YYYY',
  yearMonthDay: 'YYYY-MM-DD',
  week: 'ddd',
  time: 'HH:mm:ss',
  dayMonthYearWeekTimePeriod: 'DD MMM YYYY (ddd), HH:mm a',
  yearMonthDayTimePeriod: 'YYYY-MM-DD HH:mm a',
  yearMonthDayTime: 'YYYY-MM-DD HH:mm',
  timePeriod: 'HH:mm a',
  yearMonth: 'YYYY-MM',
};

export const getCampignPeriodDate = (date) => {
  const momentFormat = moment(date);
  const formatted = momentFormat.format('D MMM YYYY (ddd)');
  return formatted;
};

export const getCampignPeriodDateTime = (date) => {
  const momentFormat = moment(date);
  const formatted = momentFormat.format('D/M/YYYY(ddd),HH:mm:ss');
  return formatted;
};

export const getCampaignPeriodDateTimeWithA = (date) => {
  const momentFormat = moment(date);
  const formatted = momentFormat.format('D MMMM YYYY (ddd), hh:mm a');
  return formatted;
};

export const getCampignPeriodFormatedTime = (date) => {
  const momentFormat = moment(date);
  const resultHour = momentFormat.format('hh');
  const minutes = momentFormat.format('mm');
  const resultMinute = Math.floor(minutes / 5) * 5;
  const twentyHourFormat = momentFormat.format('HH');
  const isPm = parseFloat(twentyHourFormat) >= 12;

  return {
    defaultHour: resultHour,
    defaultMinute: resultMinute,
    defaultAmOrPm: isPm ? AmOrPm.pm : AmOrPm.am,
  };
};

export const getCurrentDay = () => {
  const date = new Date();
  date.setHours(0, 0, 0);
  return date;
};

export const getCampignListDisplayTime = (startDate, endDate) => {
  const momentNow = moment();
  const startTime = moment(startDate);
  const currentYear = momentNow.year();
  const currentTime = momentNow.valueOf();
  if (!endDate) {
    if (currentYear === startTime.year()) {
      return `From ${startTime.format('DD MMM (ddd)')}`;
    }
    return `From ${startTime.format('DD MMM yyyy')}`;
  }
  const endTime = moment(endDate);
  if (currentTime < startTime) {
    if (currentYear === startTime.year()) {
      return `From ${startTime.format('DD MMM (ddd)')}`;
    }
    return `From ${startTime.format('DD MMM yyyy')}`;
  }
  if (currentYear === endTime.year()) {
    return `Until ${endTime.format('DD MMM (ddd)')}`;
  }
  return `Until ${endTime.format('DD MMM yyyy')}`;
};

export const checkCampaignIsExpired = (endDate) => {
  const momentNow = moment();
  const endTime = moment(endDate);
  return momentNow.valueOf() > endTime.valueOf();
};

export const getCouponExpiryDate = (datetime) => {
  const momentFormat = moment(datetime);
  momentFormat.set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 });
  return momentFormat;
};

export const getDisplayDate = (inputDate) => {
  const momentNow = moment();
  const inputTime = moment(inputDate);
  const currentYear = momentNow.year();
  // const currentTime = momentNow.valueOf();
  if (currentYear === inputTime.year()) {
    return `${inputTime.format('DD MMM (ddd)')}`;
  }
  return `${inputTime.format('DD MMM yyyy')}`;
};

export const formatDate = (inputDate, formate = 'DD MMM yyyy (ddd)') => {
  if (!inputDate) {
    return '';
  }
  const inputTime = moment(inputDate);
  const weekIndex = formate.indexOf(TimeFormater.week);
  if (weekIndex >= 0 && formate.length > weekIndex + 4) {
    const time = inputTime.format(formate.split(`(${TimeFormater.week}),`)[1]);
    return `${inputTime.format(TimeFormater.dayMonthYearWeek)}, ${time}`;
  }

  if (formate === TimeFormater.dayMonthYearWeekTimeA) {
    const time = inputTime.format('HH:mm');
    const amOrPm = inputTime.format('a');
    return `${inputTime.format(
      TimeFormater.dayMonthYearWeek,
    )}, ${time} ${amOrPm}`;
  }

  return `${inputTime.format(formate)}`;
};

export const compareDate = (date1, date2, isAfter) => {
  // console.log('compareDate:', date1, date2, isAfter);
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  const firstDate = moment(date1);
  const secondDate = moment(date2);
  if (isAfter) {
    return firstDate.isAfter(secondDate) ? date1 : date2;
  }
  return firstDate.isBefore(secondDate) ? date1 : date2;
};

export const getReadablePeriod = (startDate, endDate, isAlways) => {
  if (!startDate) {
    return '-';
  }
  if (!endDate || isAlways) {
    return 'Always';
  }
  const startDateFormat = getCampignPeriodDateTime(startDate);
  const endDateFormat = getCampignPeriodDateTime(endDate);
  return `${startDateFormat} - ${endDateFormat}`;
};
