import React, { useEffect, useState } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import BrandDetailContent from '../../../components/brand/CreateBrandContent';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../../utils';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { sessionDataKey } from '../../../models/BrandModel';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

function CreateBrand(props) {
  const queryString = require('query-string');
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [completeProcess, setShowCompleteProcess] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const { stepConfig, selectedBrand } = useSelector((state) => ({
    stepConfig: state.brand.stepConfig,
    selectedBrand: state.brand.selectedBrand,
  }));

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('brand/getBrand')({
          brandPK: params.id,
        }),
      );
    } else {
      dispatch(createAction('brand/loadBrandFromCookie')());
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('brand/cleanSelectedBrand')());
    };
  }, [dispatch]);

  const stepContent = [<BrandDetailContent />];

  const gotoBrandList = () => {
    history.push({
      pathname: '/brands',
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };
  return (
    <>
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({
            type: 'brand/saveOrRemoveBrandFromCookie',
            payload: save,
          });
        }}
        sessionDataKey={sessionDataKey}
        stepsConfig={stepConfig}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? selectedBrand.name : ''} />
        }
        caution={{
          detail: '', //Create various brands and select them in a campaign.
          title: params.id ? 'Edit ' + selectedBrand.name : 'Create Brand',
        }}
        showFinishPop={completeProcess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Brand is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to brand list',
              action: gotoBrandList,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: gotoBrandList,
          onClose: gotoBrandList,
        }}
      />

      <SaveAndBackButtons
        backAction={gotoBrandList}
        saveTempText={params.id ? 'Delete Brand' : null}
        saveTempAction={() => {
          dispatch(
            createAction('brand/updateState')({
              checkedList: [selectedBrand],
            }),
          );
          setShowDeletePrompt(true);
        }}
        saveText={params.id ? 'Updated' : 'Save'}
        saveAction={() => {
          dispatch(
            createAction('brand/checkFields')({
              afterActions: () => {
                if (params.id) {
                  dispatch(
                    createAction('brand/updateBrand')({
                      afterActions: gotoBrandList,
                    }),
                  );
                } else {
                  dispatch(
                    createAction('brand/createBrand')({
                      afterActions: () => setShowCompleteProcess(true),
                    }),
                  );
                }
              },
            }),
          );
        }}
      />

      <DeletePrompt
        title={'Brand'}
        data={selectedBrand}
        relatedSections={DELETE_RELATED_SECTIONS.BRAND}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('brand/delete')({
              afterAction: () => history.push('/brands'),
            }),
          );
        }}
      />
    </>
  );
}

export default CreateBrand;
