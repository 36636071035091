import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import { firstError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { useCompare } from '../../utils';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import './RewardSKUSection.scss';
import RewardSection from './RewardSection';

const defaultContent = (defaultValue) => {
  if (defaultValue?.octopusExtraRewards?.length) {
    const rewardContents = defaultValue.octopusExtraRewards?.map((reward, index) => {
      return {
        rewardName: `Reward ${index + 1}`,
        ...reward,
      };
    });
    return rewardContents;
  }
  return [];
};

const OctopusExtraRewardSection = ({
  errorFields,
  onSubmit,
  onSubmitAction = () => { },
  defaultValue,
}) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState(defaultContent(defaultValue));
  const vals = {
    octopusExtraRewards: content,
  };
  const hasValsChanged = useCompare(vals);
  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      console.log('useEffectvals:', vals);
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.octopusExtraRewards.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">OCTOPUS REWARD</label>
      {content.map((item, index) => {
        return (
          <>
            <div className="reward-sku-title">
              <label className="create-section-title-without-upper">
                {item.rewardName}
              </label>
              <Image
                onClick={() => {
                  const afterRemoveContent = content.filter(
                    (val) => val.rewardName !== item.rewardName,
                  );
                  const newContent = afterRemoveContent?.map((val, index) => ({
                    ...val,
                    rewardName: `Reward ${index + 1} `,
                  }));
                  setContent(newContent);
                  dispatch({
                    type: 'createEarningRules/removeVals',
                    payload: { extraRewardsGroupKey: item.pk },
                  });
                }}
                src={DeleteIcon}
                className="content-delete-button reward-sku-delete-button"
              />
            </div>

            <div
              className={`reward-content-area ${errorFields.includes(EarningRuleErrorHandleField.octopusExtraRewards.name)
                ? 'error-field'
                : ''
                }`}
            >
              <div className="display-flex-area reward-content">
                <div className="reward-content">
                  <RewardSection
                    onSubmit={onSubmit}
                    onSubmitAction={(data) => {
                      onSubmitAction(data);
                    }}
                    defaultValue={item}
                    hideTitle
                    showCouponRemoveButton
                    // hideCarbonInput
                    groupKey={`octopusReward${index + 1}`}
                    rewardChange={(reward, group) => {
                      const newContent = content?.map((data, index) => {
                        if (parseInt(group?.replace("octopusReward", "")) === index + 1) {
                          return {
                            ...data,
                            ...reward,
                          };
                        }
                        return data;
                      });
                      setContent(newContent);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        );
      })}

      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.octopusExtraRewards.name}`}
        error={errorFields.includes(EarningRuleErrorHandleField.octopusExtraRewards.name)}
        message={EarningRuleErrorHandleField.octopusExtraRewards.message}
      />

      <button
        onClick={() => {
          setContent([
            ...content,
            {
              rewardName: `Reward ${content?.length + 1 || 1} `,
              rewardType: 'COUPON',
              coupons: null,
              carbon: null,
              quantity: null,
            },
          ]);
        }}
        className="btn-back-button-common content-add-button dropdown-toggle btn btn-none"
      >
        Add reward
      </button>
    </>
  );
}

const mapStateToProps = (state) => ({
  errorFields: state.createEarningRules.errorFields,
});

export default connect(mapStateToProps)(OctopusExtraRewardSection);
