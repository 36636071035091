import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import { LanguageConfig } from '../../config/CustomEnums';
import InformationBarMessageMaintenanceGeneralSection from './InformationBarMessageMaintenanceGeneralSection';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../base/BottomStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { useParams } from 'react-router-dom';

function CreateInformationBarMessageStepTwo() {
  let dataArray = [];
  const dispatch = useDispatch();
  const params = useParams();

  const { errorFields } = useSelector((state) => ({
    errorFields: state.createMessage.errorFields,
  }));

  // const [onSubmit, setOnSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (!onCheck.value) {
      setError(errorFields.length > 0);
    } else {
      let formatedData = {};
      console.log('dataArray')
      console.log(dataArray)

      dataArray.forEach((item, index) => {
        const informationBar = item.informationBar
        const language = item.language;

        if (language === LanguageConfig.english) {
          formatedData = {
            ...formatedData,
            ...item,
          };
        } else {
          formatedData = {
            informationBar: {
              ...formatedData.informationBar,
              translations: {
                ...formatedData.informationBar.translations,
                [language]: {
                  ...informationBar
                }
              }
            },
          }
        }
      })

      delete formatedData.informationBar.translations['zh-Hant'].messageId

      console.log('formatedData')
      console.log(formatedData)
      dispatch(
        createAction('createMessage/stepChange')({
          data: formatedData,
          isBack: onCheck.isBack,
          step: 1,
        }),
      );
      setError(false);
      stopCheck();
    }
  }, [onCheck.value, onCheck.isBack, errorFields.length, dataArray, dispatch]);

  const getTabs = (language) => {
    return [
      <InformationBarMessageMaintenanceGeneralSection
        language={language}
        onSubmit={onCheck.value}
        onSubmitData={(data) => {
          dataArray.push(data);
        }}
      />,
    ];
  };

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
  };

  return (
    <>
      <ContentSections
        fieldsError={error}
        languageTabContent={languageTabConfig}
        hidePreview={true}
      />
      {params.id ? (
        <OnlyContinueButton
          continueAction={() => {
            isChecking({ isBack: false });
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backAction={() => {
            isChecking({ isBack: true });
          }}
          continueAction={() => {
            isChecking({ isBack: false });
          }}
        />
      )}
    </>
  );
}

export default CreateInformationBarMessageStepTwo;
