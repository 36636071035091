import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import ContentSections from '../base/ContentSections';
import { SaveAndBackButtons } from '../base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes'
import { SectionWithNonTranslation, SectionWithTranslation, InfomationTypes } from '../base/DetailInfomationSections'
import BasePrompt from '../base/prompt/BasePrompt';
import './StepThree.scss';

function RewardSection({ data }) {
  const mapping = [
    {
      title: "Extra Barcode rewards",
      items:
        [
          {
            name: "Sponsored by",
            property: "sponsorPartner",
          },
          {
            name: "Points",
            property: "points",
          }
        ]
    },
    {
      title: "Coupon rewards",
      items:
        [
          {
            name: "Coupon Set",
            property: "linkedCouponSet"
          },
          {
            name: "Quantity",
            property: "couponQuantity"
          },
        ]
    },
    {
      title: "",
      items: [{
        name: "Overall Limit",
        property: "overallLimit"
      },
      {
        name: "Perhead Limit",
        property: "perHeadLimit"
      },
      {
        name: "Overall Periodic Limit",
        property: "overAllPeriodicLimitEffectiveNumberOfDays",
        union: ' / ',
        propertySuffix: "overAllPeriodicLimit",
        unionSuffix: " day(s)"
      },
      {
        name: "Perhead Periodic Limit",
        property: "perHeadPeriodicLimitEffectiveNumberOfDays",
        union: ' / ',
        propertySuffix: "perHeadPeriodicLimit",
        unionSuffix: " day(s)"
      },]
    }

  ]
  return <SectionWithNonTranslation mapping={mapping} data={data} />
}

function ContentSection({ data }) {
  const mapping = [
    {
      name: "Recyclable Type", property: "productType"
    },
    {
      name: "Product name", property: "productName"
    },
    {
      name: "Link to brand", property: "linkedBrand"
    },
    {
      name: "Barcodes", property: "displayCodes"
    },
    {
      name: "Volume", property: "displayVolume"
    },
    {
      name: "Product image", property: "productImage", type: InfomationTypes.image
    },
  ]
  return <SectionWithTranslation mapping={mapping} data={data} title={"Content"} />
}


const PublishPrompt = ({
  pk,
  isPublish,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cancelAction = () => {
    setShow(false);
  }

  const confirmAction = () => {
    dispatch({
      type: 'barcodes/createOrUpdateObj',
      payload: {
        id: pk,
        afterAction: (success, barcodePk) => {
          if (success) {
            dispatch({
              type: 'barcodes/publishOrUnpublishObj',
              payload: {
                publish: isPublish,
                id: barcodePk,
                afterAction: () => {
                  history.push("/barcodes")
                }
              }
            })
          }
        }
      },
    })
  }

  return (
    <BasePrompt
      show={show}
      closeAction={cancelAction}
      leftButton={{
        text: 'Cancel',
        action: cancelAction
      }}
      rightButton={{
        text: 'Confirm',
        action: confirmAction
      }}
      title={`Confirm to ${isPublish ? 'publish' : 'unPublish'} barcode record?`}
      description={`Confirm to ${isPublish ? 'publish' : 'unPublish'} barcode record below?`}
      modalClassName='barcode-publish-prompt'
    />
  )
}



function StepThree({ detail }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams()
  const [processing, setProcessing] = useState(false)
  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const sections = [<RewardSection data={detail} />, <ContentSection data={detail} />];
  const isPublished = detail?.isPublished || false;

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText="Save"
        saveText={isPublished ? 'UnPublished' : 'Publish'}
        backAction={() =>
          dispatch({
            type: 'barcodes/stepChange',
            payload: {
              step: 2,
              isBack: true,
            },
          })
        }
        saveTempAction={() => {
          if (processing) {
            return;
          }
          setProcessing(true)
          dispatch({
            type: 'barcodes/createOrUpdateObj',
            payload: {
              id: params.id,
              afterAction: (success) => {
                setProcessing(false)
                if (success) {
                  history.push("/barcodes")
                }
              }
            },
          })
        }}
        saveAction={() => {
          setShowPublishPrompt(true);
        }}
        tempRequires={PermissionCodes.changeBarcode}
        saveRequires={PermissionCodes.changeBarcode}
      />
      <PublishPrompt
        pk={detail.pk}
        isPublish={!isPublished}
        show={showPublishPrompt}
        setShow={setShowPublishPrompt}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  detail: state.barcodes.detail,
});

export default connect(mapStateToProps)(StepThree);