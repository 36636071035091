import React, { useEffect, useState } from 'react';
import ContentSections from '../base/ContentSections';
import { connect, useDispatch } from 'react-redux';
import { SaveAndBackButtons } from '../base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import ProfileSection from './ProfileSection';
import ContentSection from './ContentSection';
import NextStepPrompt from '../base/prompt/NextStepPrompt';

const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
};

function CreateMissionStepFour({
  isPublished,
  missionCreated,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [showPrompt, setShowPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);

  const fromCommunity = history.location?.state?.fromCommunity || {};
  const fromCommunityPathName = fromCommunity?.pathname || null;
  const id = params?.id;

  useEffect(() => {
    if (missionCreated) {
      setShowPrompt(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionCreated]);

  const getTitle = () => {
    if (actionType === ActionType.save) {
      return 'Successfully Saved!';
    }
    if (!isPublished) {
      return 'Successfully Published!';
    }
    return 'Successfully UnPublished!';
  };

  const getDescription = () => {
    if (actionType === ActionType.save) {
      return `Mission is successfully saved. 
      You can publish by edit the status.`;
    }
    if (!isPublished) {
      return `Mission is successfully published.`;
    }
    return `Mission is successfully unpublished. 
    You can publish again by edit the status.`;
  };

  const sections = [
    <ProfileSection />,
    <ContentSection />
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText="Save"
        saveText={isPublished ? 'UnPublished' : 'Publish'}
        backAction={() =>
          dispatch({
            type: 'mission/stepChange',
            payload: {
              step: 3,
              isBack: true,
              fromCommunity: !!fromCommunityPathName,
            },
          })
        }
        saveAction={() => {
          setActionType(ActionType.publish);
          dispatch(
            createAction('mission/createOrUpdateMission')({
              id,
              hasPublishOrUnpublishAction: true,
            }),
          );
        }}
        saveTempAction={() => {
          setActionType(ActionType.save);
          dispatch(
            createAction('mission/createOrUpdateMission')({
              id,
              hasPublishOrUnpublishAction: false,
            }),
          );
        }}
        saveRequires={PermissionCodes.changeMission}
      />

      <NextStepPrompt
        show={showPrompt}
        title={getTitle()}
        description={getDescription()}
        steps={null}
        buttons={[
          {
            text: 'Back to list',
            action: () => {
              console.log("@@108", history.location?.state)
              if (fromCommunityPathName) {
                history.push({
                  pathname: fromCommunityPathName,
                  search: fromCommunity?.search,
                  hash: fromCommunity?.hash,
                });
                return;
              }
              history.push({
                pathname: '/mission',
                hash: null,
              })
            },
            requires: PermissionCodes.changeMission,
          },
        ]}
      />

    </>
  );
}

const mapStateToProps = (state) => ({
  isPublished: state.mission.missionDetail.isPublished,
  missionCreated: state.mission.missionCreated,
});

export default connect(mapStateToProps)(CreateMissionStepFour);
