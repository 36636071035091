import React from 'react';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import './CustomEditor.scss';
import { isLongScreen } from '../../utils/ScreenUtil';
const toolBars = [
  'heading',
  'bold',
  'italic',
  'quote',
  'divider',
  'ul',
  'ol',
  'link',
  'htmlInline',
  'redo',
  'undo',
];

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.initialValue = props.initialValue;
    // this.resetValueAction = props.resetValueAction || (() => {});
    this.onValueChange = props.onValueChange || (() => { });
    this.errorMessage = props.errorMessage;
    this.onFocus = props.onFocus || (() => { });
    this.toolBars = props.toolBars || toolBars;
    this.state = {
      editorValue: props.initialValue,
      editorChangeValue: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initialValue && this.props.initialValue && !this.state.editorChangeValue) {
      this.editorRef.current.getInstance().setMarkdown(this.props.initialValue);
    }
    // if (!prevProps?.resetValue && this.props?.resetValue) {
    //   this.editorRef.current.getInstance().setMarkdown(this.props.initialValue);
    //   this.resetValueAction();
    // }
  }

  render() {
    return (
      <>
        <div
          className={`customer-editor-container ${this.props.error ? 'editor-container-error' : ''
            } ${isLongScreen ? '' : 'customer-editor-container-short '}`}
          onFocus={() => this.onFocus()}
        >
          <Editor
            events={{
              change: () => {
                const markdown = this.editorRef.current
                  .getInstance()
                  .getMarkdown();
                // console.log('@@122-56: ', this.state.editorValue, markdown);
                if (
                  markdown !== this.state.editorValue &&
                  (markdown || this.state.editorValue)
                ) {
                  if (!this.state.editorChangeValue) {
                    this.setState({ editorChangeValue: true });
                  };
                  this.onValueChange(markdown);
                  this.setState({ editorValue: markdown });
                }
              },
            }}
            initialValue={this.initialValue}
            previewStyle="vertical"
            height="270px"
            useCommandShortcut={true}
            initialEditType="wysiwyg"
            hideModeSwitch={true}
            hooks={this.props.hooks || {}}
            toolbarItems={this.toolBars}
            onPaste={(e) => {
              const markdown = this.editorRef.current
                .getInstance()
                .getMarkdown();
              const clipboardData = e.data?.clipboardData;
              const isCarbonWalletData = clipboardData?.getData('carbonWallet text/html');
              const htmlData = clipboardData?.getData('text/html');
              const plainText = clipboardData?.getData('text/plain');
              const editedData = plainText?.replace(/\n/g, '');
              console.log("@@86, onPaste, isCarbonWalletData:", isCarbonWalletData)
              console.log("@@87, editedData:", editedData)
              console.log("@@88, onPaste, text/html", htmlData)

              // message should pureCopy
              if (!isCarbonWalletData) {
                e.data?.preventDefault();
                document.execCommand('insertText', false, editedData);
              } else if (!markdown) {
                e.data?.preventDefault();
                this.editorRef.current.getInstance().setHtml(htmlData);
                const currentMarkdown = this.editorRef.current.getInstance().getMarkdown();
                console.log("@@102", currentMarkdown)
                this.onValueChange(currentMarkdown);
                this.setState({ editorChangeValue: true, editorValue: currentMarkdown });
              }
            }}
            onCopy={(e) => {
              console.log("@@96, onCopy");
              e.data?.clipboardData?.setData('carbonWallet text/html', "True");
            }}
            ref={this.editorRef}
            customHTMLSanitizer={(html) => {
              return `${html
                .replace(/<\/?span[^>]*>/gm, '')
                .replace(/<\/?label[^>]*>/gm, '')}`;
            }}
          />
        </div>
        {this.props.error ? (
          <label className="editor-container-error-message">
            {this.errorMessage}
          </label>
        ) : null}
      </>
    );
  }
}

export default CustomEditor;
