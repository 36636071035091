// home breadcrumb
export const homeBreads = () => {
  return [{ name: 'Home', link: '/' }];
};

//campaign breadcrumb
export const campaignListBreads = (isWhatsNewList) => {
  const homeBreadList = homeBreads();
  if (isWhatsNewList) {
    homeBreadList.push({ name: 'What\'s New', link: '/what_is_new' });
  } else {
    homeBreadList.push({ name: 'Campaigns', link: '/campaigns' });
  }

  return homeBreadList;
};

export const createCampaignBreads = () => {
  const camapignListBreadList = campaignListBreads();
  camapignListBreadList.push({
    name: 'Create Campaign',
    link: '/campaigns/create',
  });
  return camapignListBreadList;
};

export const editCampaignBreads = (isWhatsNewList) => {
  const camapignListBreadList = campaignListBreads(isWhatsNewList);
  camapignListBreadList.push({
    name: 'Edit Campaign',
  });
  return camapignListBreadList;
};

// coupon breadcrumb

export const couponSetBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Coupon Sets',
    link: '/coupons',
  });
  return homeBreadList;
};

export const createCouponBreads = (id) => {
  const couponSetBreadList = couponSetBreads();
  couponSetBreadList.push({
    name: `${id ? 'Edit' : 'Create'} Coupon Set`,
    link: '/coupons/create',
  });
  return couponSetBreadList;
};

export const couponSetDetailBreads = (couponSetName, isBack = false) => {
  const couponSetListBreadList = couponSetBreads();
  couponSetListBreadList.push({
    name: couponSetName,
    link: '/coupons/couponSet',
    isBack: isBack,
  });
  return couponSetListBreadList;
};

export const singleCouponBreads = (couponSetName, singleCouponSerialNum) => {
  const couponSetDetailBreadList = couponSetDetailBreads(couponSetName, true);
  couponSetDetailBreadList.push({
    name: singleCouponSerialNum,
    link: '/coupons/couponSet/singleCoupon',
  });
  return couponSetDetailBreadList;
};


// community breadcrumb
export const communityBreads = (isBack = false) => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Community',
    link: '/communities',
    isBack: isBack,
  });
  return homeBreadList;
};

export const communityDetailBreads = (name, isBack = false) => {
  const communityListBreadList = communityBreads();
  communityListBreadList.push({
    name: name,
    link: '/communities/community',
    isBack: isBack,
  });
  return communityListBreadList;
};

export const communityTargetFromListBreads = (name) => {
  const communityTargetBreadList = communityBreads(true);
  communityTargetBreadList.push({
    name: name,
    link: '/communities/communityTarget',
  });
  return communityTargetBreadList;
};

export const communityTargetBreads = (communityName, targetName) => {
  const communityTargetBreadList = communityDetailBreads(communityName, true);
  communityTargetBreadList.push({
    name: targetName,
    link: '/communities/community/communityTarget',
  });
  return communityTargetBreadList;
};
