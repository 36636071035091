import React from 'react';
import { connect } from 'react-redux';
import { TRANSACTION_EVENT_TYPE, } from './TransactionList';
import TransactionRecentScrollButton from '../../../assets/images/transaction/transactionRecentScrollButton.svg'

function CardHeader({ item }) {
    let headerColor = { displayEventType: TRANSACTION_EVENT_TYPE.TYPE_WAITING, background: '#E6F2FF', color: '#6AB3FF' }
    if (item.offlineEventType === 'APPROVED') {
        headerColor = { displayEventType: TRANSACTION_EVENT_TYPE.TYPE_APPROVED, background: '#DFFEED', color: '#02432C' }
    } else if (item.offlineEventType === 'REJECTED') {
        headerColor = { displayEventType: TRANSACTION_EVENT_TYPE.TYPE_REJECTED, background: '#FFE6E1', color: '#FF6B4A' }
    } else if (item.offlineEventType === 'RECALLED') {
        headerColor = { displayEventType: TRANSACTION_EVENT_TYPE.TYPE_RECALLED, background: '#FFE6E1', color: '#FF6B4A' }
    }

    return <div className='recent-transaction-card-header' style={{ background: headerColor.background }}>
        <div>
            <label>
                ID: {item.pk}
            </label>
            <label>{item.transactionDetailDisplayDate}</label>
        </div>
        <label style={{ color: headerColor.color }}>
            {headerColor.displayEventType}
        </label>
    </div>
}

function CardRecyclableItem({ recycleItem }) {
    return <label className='recent-transaction-item-recyclable'>
        {recycleItem?.node?.name}({recycleItem?.node?.quantity})
    </label>
}

function CardBody({ item }) {

    return <div className="recent-transaction-item-body">
        <div>
            <label>Recyclable</label>
            {item.photoTakingItems?.edges?.map(photoItem => <CardRecyclableItem recycleItem={photoItem} />)}
        </div>
        <img src={item.receiptImage} />
    </div>
}

function CardBottom({ item }) {
    return <div className='recent-transaction-card-bottom'>
        <label>Total Amount</label>
        <label>{item?.totalRewardAmount}</label>
    </div>
}

function TransactionCard({ item }) {
    console.log("TransactionCard:", item)
    return <div className='recent-transaction-item' >
        <CardHeader item={item} />
        <CardBody item={item} />
        <CardBottom item={item} />
    </div>

}

function RecentTransactions({ recentTransctions }) {
    const items = recentTransctions
    console.log("recentTransactionsitem:", recentTransctions)
    if (!items || items.length < 1) {
        return <></>
    }
    const scrollButton = (isScrollLeft) => {
        const scrollArea = document.getElementById("recent-transaction-scroll")
        console.log("isscrollLeft:", isScrollLeft, scrollArea)
        const changedPosition = isScrollLeft ? 388 : -388
        const containerLeft = scrollArea?.scrollLeft
        scrollArea.scrollTo({ left: containerLeft + changedPosition, behavior: 'smooth' },)
    }
    return (
        <div className="create-section-and-preview-container row recent-transaction-container">
            <div onScroll={() => {

            }}>
                <label>RECENT TRANSACTION FROM THE SAME USER</label>
                <div id="recent-transaction-scroll" className='recent-transaction-items'>
                    {items?.map(item => <TransactionCard item={item} />)}
                </div>
                <img id="recent-transaction-scroll-to-right" style={items.length < 4 ? {display: "none"} : {}} src={TransactionRecentScrollButton} onClick={() => {
                    scrollButton()
                }} />
                <img id='recent-transaction-scroll-to-left' style={items.length < 4 ? {display: "none"} : {}} src={TransactionRecentScrollButton} onClick={() => {
                    scrollButton(true)
                }} />

            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    recentTransctions: state.transactions.recentTransctions,
});


export default connect(mapStateToProps)(RecentTransactions);
