import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getCouponTemplateList = (afterCursor, search) => {
  let order = '-pk';
  if (search.rank === false || search.rank === 'false') {
    order = 'pk';
  }

  let searchString = `, orderBy: "${order}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.all) {
    searchString += `,`;
  } else {
    searchString += `, first: 20,`;
  }
  if (search.out_of_stock) {
    searchString += `, outOfStock: ${search.out_of_stock}`;
  }
  if (search.status) {
    searchString += `, isActive: ${search.status}`;
  }
  if (search.expired) {
    searchString += `, isExpired: ${search.expired}`;
  }
  if (search.expired_date) {
    switch (search.expired_date) {
      case 'absolute':
        if (search.absolute) {
          const expiredDateRange = search.absolute.split(',');
          searchString += `, absoluteGte: "${expiredDateRange[0]}", absoluteLte: "${expiredDateRange[1]}"`;
        }
        break;
      case 'relativeDay':
        if (search.relativeDay) {
          searchString += `, relativeDay: ${search.relativeDay}`;
        }
        break;
      default:
        searchString += `, allTime: true`;
        break;
    }
  }

  if (search.search || search.searchKey || search.filterName) {
    searchString += `, nameIcontains: "${
      search.search || search.searchKey || search.filterName
    }"`;
  }

  const query = `{
    couponTemplates(after: "${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          shouldUseInStore
          useStaffCode
          isForcedInactive
          instructionSectionTitle
          instructionSectionContent
          detailSectionTitle
          detailSectionContent
          coverPhoto
          detailPhoto1
          detailPhoto2
          detailPhoto3
          detailPhoto4
          lastModifiedDate
          creationDate
          linkedCampaignsName
          absoluteEffectiveDate
          absoluteExpiryDate
          status
          stock
          totalNubmerOfGeneratedCoupons
          validPeriodType
          numberOfDaysToExpireAfterAcquisition
          formats
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponSet = (couponSetID) => {
  const query = `{
    couponTemplate(id: "${couponSetID}") {
      id
      pk
      name
      lastModifiedDate
      creationDate
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      validPeriodType
      absoluteEffectiveDate
      absoluteExpiryDate
      numberOfDaysToExpireAfterAcquisition
      isForcedInactive
      translations {
        edges {
          node {
            language
            name
            id
            pk
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
          }
        }
      }
      brand {
        id
        name
        pk
        translations {
          edges {
            node {
              language
              name
              description
              id
            }
          }
        }
        stores {
          edges {
            node {
              id
              pk
              name
              address
              description
              translations {
                edges {
                  node {
                    language
                    name
                    address
                    description
                  }
                }
              }
            }
          }
        }
      }
      applicableStores {
        edges {
          node {
            id
            pk
            name
            address
            description
            translations {
              edges {
                node {
                  language
                  name
                  address
                  description
                }
              }
            }
          }
        }
      }
      shouldUseInStore
      useStaffCode
      # applicableStores
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      # computed fields
      totalNubmerOfGeneratedCoupons
      stock
      linkedCampaignsName
      status
      formats
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createCouponTemplate = (couponTemplateInfo) => {
  const query = `mutation CreateCouponTemplate($input: CreateCouponTemplateInput!) {
      createCouponTemplate(input: $input) {
        node {
          id
          pk
          name
          coverPhoto
        }
        errors {
          field
          messages
        }
      }
    }`;
  const variables = {
    input: {
      name: 'campaign',
      ...couponTemplateInfo,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const generateCoupon = (payload) => {
  const query = `mutation GenerateCoupons($input: GenerateCouponsInput!) {
      generateCoupons(input: $input) {
        success
      }
    }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSingleCouponListWithTemplate = (
  templateID,
  afterCursor = null,
  rank = null,
  filterKey = null,
) => {
  let order = '-pk';
  if (rank === false || rank === 'false') {
    order = 'pk';
  }
  const query = `{
    couponTemplate(id: "${templateID}") {
      coupons(first: 20, orderBy: "${order}", after: "${afterCursor}", serialNumberIcontains: "${
    filterKey || ''
  }") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            pk
            serialNumber
            creationDate
            lastModifiedDate
            dateOfPurchase
            dateOfEarning
            dateOfGrant
            isUsed
            isExpired
            isActive
            isForcedInactive
            effectiveDate
            expiryDate
            owner {
              id
              pk
              firstName
              lastName
            }
            store {
              id
              pk
              name
              staffCode
            }
            image
          }
        }
      }
    }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllSingleCouponWithTemplate = (templateID) => {
  const query = `{
    couponTemplate(id: "${templateID}") {
      coupons {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            dateOfGrant
            serialNumber
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSingleCoupon = (id) => {
  const query = `
  {
    coupon(id: "${id}") {
      id
      pk
      template {
        name
        formats
      }
      isForcedInactive
      store {
        name
      }
      owner {
        firstName
        lastName
      }
      dateOfPurchase
      dateOfEarning
      dateOfGrant
      serialNumber
      isUsed
      isExpired
      isActive
      effectiveDate
      expiryDate
      image
      creationDate
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCoupons = (ids) => {
  const query = `
  mutation DeactivateCoupons($input: DeactivateCouponsInput!) {
    deactivateCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSingleCoupon = (couponPK) => {
  const query = `
  mutation DeleteCoupon($input: DeleteCouponInput!) {
    deleteCoupon(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSingleCoupons = (ids) => {
  const query = `
  mutation DeleteCoupons($input: DeleteCouponsInput!) {
    deleteCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveSingleCoupon = (couponPK) => {
  const query = `
  mutation DeactivateCoupon($input: DeactivateCouponInput!) {
    deactivateCoupon(input: $input) {
      node {
        id
        template {
          name
        }
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeSingleCoupon = (couponPK) => {
  const query = `
  mutation ActivateCoupon($input: ActivateCouponInput!) {
    activateCoupon(input: $input) {
      node {
        id
        template {
          name
        }
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCoupons = (ids) => {
  const query = `
  mutation ActivateCoupons($input: ActivateCouponsInput!) {
    activateCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const grantCoupon = (couponPK, customerPK) => {
  const query = `mutation GrantCoupon($input: GrantCouponInput!) {
    grantCoupon(input: $input) {
      node {
        id
        pk
        isForcedInactive
        owner {
          firstName
          lastName
        }
        dateOfPurchase
        dateOfEarning
        dateOfGrant
        serialNumber
        isUsed
        isExpired
        isActive
        effectiveDate
        expiryDate
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
      customer: customerPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const reclaimCoupon = (id) => {
  const query = `
  mutation ReclaimCoupon($input: ReclaimCouponInput!) {
    reclaimCoupon(input: $input) {
      node {
        id
        pk
        template {
          name
        }
        isForcedInactive
        store {
          name
        }
        owner {
          firstName
          lastName
        }
        dateOfPurchase
        dateOfEarning
        dateOfGrant
        serialNumber
        isUsed
        isExpired
        isActive
        effectiveDate
        expiryDate
      }
    }
  }`;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCouponSet = (couponSetPK) => {
  const query = `
  mutation DeleteCouponTemplate($input: DeleteCouponTemplateInput!) {
    deleteCouponTemplate(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: couponSetPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCouponSets = (ids) => {
  const query = `
  mutation DeleteCouponTemplates($input: DeleteCouponTemplatesInput!) {
    deleteCouponTemplates(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCouponSetActiveStatus = (
  couponSetPK,
  isForcedInactive = false,
) => {
  const query = `
  mutation UpdateCouponTemplate($input: UpdateCouponTemplateInput!) {
    updateCouponTemplate(input: $input) {
      node {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      id: couponSetPK,
      isForcedInactive: isForcedInactive,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCouponSet = (couponSet) => {
  const query = `
  mutation UpdateCouponTemplate($input: UpdateCouponTemplateInput!) {
    updateCouponTemplate(input: $input) {
      node {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...couponSet,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllCouponSet = (ssoUid) => {
  const query = `
  {
    coupons(customerSsoUid: "${ssoUid}", customerName: "", isExpired: false, isUsed: false orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          template {
            name
          }
          isForcedInactive
          store {
            name
            staffCode
          }
          owner {
            id
            pk
            firstName
            lastName
          }
          dateOfPurchase
          dateOfEarning
          dateOfGrant
          serialNumber
          isUsed
          isExpired
          isActive
          effectiveDate
          expiryDate
        }
      }
    }
  }

  `;

  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
