import { APIStatus, CheckStatus, LanguageConfig, SavedStatus } from '../config/CustomEnums';
import {
  getGuideImages,
  getGuideImage,
  updateGuideImage,
} from '../services/GuideImageApiHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  delay,
  getFileNameFromUrl,
  getObjectTranslations,
} from '../utils';
import { getCampignPeriodDateTime } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';

export const GuideImageError = {
  firstTitle: {
    name: 'firstTitle',
    message: 'guide_title_error'
  },
  firstImage: {
    name: 'firstImage',
    message: 'guide_image_error'
  },
  secondTitle: {
    name: 'secondTitle',
    message: 'guide_title_error'
  },
  secondImage: {
    name: 'secondImage',
    message: 'guide_image_error'
  },
}


const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Type', fieldName: 'name' },
    { displayName: 'Create at', fieldName: 'creationDate' },
    { displayName: 'Last Modified date', fieldName: 'lastModifiedDate' },
  ],
  guideImageList: [],
  guideDetail: {},
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  checkedList: [],
  checked: CheckStatus.initOrNotChecked,
  errorFields: [],
  errorTab: LanguageConfig.english,
  saved: SavedStatus.init,
  formChanged: false,
  detailApiStatus: APIStatus.none,
  saveApiStatus: APIStatus.none,
});

const parseBrand = (edges, isReceipt, isOcl) => {
  if (!edges) {
    return [];
  };
  const brands = edges?.filter(item => {
    const node = item.node || {};
    return (isReceipt && node.receiptPartner) || (isOcl && node.oclPartner);
  })?.map(item => {
    const node = item.node || {};
    return {
      pk: node.pk,
      name: node.name,
    };
  });
  return brands;
};

const parseGuide = (item) => {
  const typeTitle = guideTypeStrings[item.type] || '-';
  const translations = getObjectTranslations(item);

  translations[LanguageConfig.english] = {
    language: LanguageConfig.english,
    firstTitle: item.firstTitle,
    firstImage: item.firstImage,
    secondTitle: item.secondTitle,
    secondImage: item.secondImage,
  };

  return {
    id: item.pk,
    pk: item.pk,
    name: typeTitle,
    typeTitle: typeTitle,
    receiptPartner: parseBrand(item.receiptPartner?.edges, true, false),
    oclPartner: parseBrand(item.oclPartner?.edges, false, true),
    creationDate: getCampignPeriodDateTime(item.creationDate),
    lastModifiedDate: getCampignPeriodDateTime(item.lastModifiedDate),
    translations,
  };
};

const parseGuideList = (data) => {
  return data.map((item) => parseGuide(item.node));
};

const parseGuideImageUrl = (filePath) => {
  let url = null;
  if (filePath && filePath?.type) {
    url = getFileNameFromUrl(filePath.value);
  } else {
    url = getFileNameFromUrl(filePath);
  }
  return url;
};

const assembleSingleTranslation = (data = {}, language) => {
  let idField = {};
  if (data?.pk) {
    idField = { id: data.pk };
  };
  return {
    ...idField,
    language,
    firstTitle: data?.firstTitle || "",
    firstImage: parseGuideImageUrl(data?.firstImage) || null,
    secondTitle: data?.secondTitle || "",
    secondImage: parseGuideImageUrl(data?.secondImage) || null,
  };
};

const assembleTranslations = (translations = {}) => {
  const traditionalChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.traditionalChinese],
    LanguageConfig.traditionalChinese,
  );
  const simplifiedChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.simplifiedChinese],
    LanguageConfig.simplifiedChinese,
  );
  return [
    traditionalChinese,
    simplifiedChinese,
  ]
};

const assembleGuide = (data) => {
  console.log("@@124", data)

  const receiptPartner = data?.receiptPartner?.map(item => item.pk) || [];
  const oclPartner = data?.oclPartner?.map(item => item.pk) || [];
  const translations = assembleTranslations(data?.translations);
  const enTranslations = assembleSingleTranslation(data?.translations?.[LanguageConfig.english], LanguageConfig.english);

  return {
    id: data.id,
    firstTitle: enTranslations.firstTitle,
    firstImage: enTranslations.firstImage,
    secondTitle: enTranslations.secondTitle,
    secondImage: enTranslations.secondImage,
    receiptPartner,
    oclPartner,
    translations,
  }
};

export const guideTypeStrings = {
  PHOTO_TAKING: 'Photo Taking',
  GREEN_DINING: 'Green dining',
  PLANT_MILK: 'Plant Milk',
  GREEN_SHOPPING: 'Green Shopping',
  BARCODE: 'Barcode',
};

export default {
  namespace: 'guideImage',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    updateGuide(state, { payload }) {
      const guideDetail = {
        ...state.guideDetail,
        ...payload,
      };

      return {
        ...state,
        guideDetail,
      }
    },

    updateGuideTranslations(state, { payload }) {
      const guideDetail = {
        ...state.guideDetail,
        translations: {
          ...state.guideDetail.translations,
          [payload.language]: {
            ...state.guideDetail.translations[payload.language],
            ...payload,
          }
        }
      };
      return {
        ...state,
        guideDetail,
      }
    },

    checkValid(state, { payload }) {
      const guideDetail = state.guideDetail || {};
      const translations = guideDetail?.translations || {};
      let errorFields = [];
      let errorTab = LanguageConfig.english;
      [LanguageConfig.english, LanguageConfig.traditionalChinese].forEach(language => {
        if (!translations?.[language]?.firstTitle?.length) {
          errorFields.push(`${GuideImageError.firstTitle.name}-${language}`);
          errorTab = language;
        };
        if (!translations?.[language]?.firstImage) {
          errorFields.push(`${GuideImageError.firstImage.name}-${language}`);
          errorTab = language;
        };
        if (!translations?.[language]?.secondTitle?.length) {
          errorFields.push(`${GuideImageError.secondTitle.name}-${language}`);
          errorTab = language;
        };
        if (!translations?.[language]?.secondImage) {
          errorFields.push(`${GuideImageError.secondImage.name}-${language}`);
          errorTab = language;
        };
      })
      console.log("@@210", errorFields, errorTab)
      return {
        ...state,
        errorFields,
        errorTab,
      }
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getGuideImages: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getGuideImages, pageCursor, payload.reverse];
        function* onSuccess(data) {
          const pageInfo = data?.guides?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor1;
          const totalCount = data?.guides?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              guideImageList: parseGuideList(data?.guides?.edges),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getGuideImage: [
      function* ({ payload }, { call, put }) {
        const id = convertPKToId('GuideNode', payload.id);
        const serviceArgs = [getGuideImage, id];
        function* onSuccess(data) {
          const guide = parseGuide(data.guide);
          yield put({
            type: 'updateState',
            payload: {
              detailApiStatus: APIStatus.success,
              guideDetail: guide,
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateGuideImage: [
      function* ({ payload }, { call, put, select }) {
        yield put({ type: 'checkValid' });

        const { guideDetail, errorFields } = yield select(
          (state) => ({
            guideDetail: state.guideImage.guideDetail,
            errorFields: state.guideImage.errorFields,
          })
        );

        if (errorFields?.length > 0) {
          return;
        };

        const input = assembleGuide(guideDetail);

        const serviceArgs = [updateGuideImage, input];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              saveApiStatus: APIStatus.success,
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
