import React, { useEffect, useState } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch, connect } from "react-redux";
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { sessionDataKey } from '../../../models/WhatToDoNextListModel';
import { createAction } from "../../../utils";
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import CreateWhatToDoNextCard from '../../../components/whatToDoNext/CreateWhatToDoNextCard';
import NextStepPrompt from '../../../components/base/prompt/NextStepPrompt';

export const URLQueryKeys = {
  sort: 'sort',
  rank: 'rank',
  page: 'page',
};


function CreateWhatToDoNext({
  updateStatus
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [showPrompt, setShowPrompt] = useState(false);
  const queryString = require('query-string');

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('whatToDoNextList/getWhatToDoNext')({
          id: params.id,
        }),
      );
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'whatToDoNextList/clearData' });
    };
  }, [dispatch]);


  useEffect(() => {
    if (updateStatus === APIStatus.success) {
      setShowPrompt(true);
    };
  }, [updateStatus])


  const stepContent = [
    <CreateWhatToDoNextCard />
  ];

  const gotoWhatToDoCardList = () => {
    history.push({
      pathname: '/what_to_do_next',
      search: queryString.stringify({
        [URLQueryKeys.page]: 1,
        [URLQueryKeys.rank]: true,
        [URLQueryKeys.sort]: 'displayPriority',
      }),
    });
  };


  return (
    <>
      <ScrollableComponent
        sessionDataKey={sessionDataKey}
        stepsConfig={[]}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name='Edit' />
        }
        caution={{
          detail: '',
          title: 'Edit - What to do next'
        }}
        // showFinishPop={completeProcess}
        // nextStepConfig={{
        //   title: 'Successfully published!',
        //   description: 'What to do next content is successfully published.',
        //   steps: null,
        //   buttons: [
        //     {
        //       text: 'Back to what to do next list',
        //       action: gotoWhatToDoCardList,
        //     },
        //   ],
        // }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: gotoWhatToDoCardList,
          onClose: gotoWhatToDoCardList,
        }}
      />

      <SaveAndBackButtons
        backAction={gotoWhatToDoCardList}
        saveText='Update'
        saveAction={() => {
          dispatch(
            createAction('whatToDoNextList/updateOneWhatToDoNext')({}),
          );
        }}
      />


      <NextStepPrompt
        show={showPrompt}
        title={'Successfully published!'}
        description={'What to do next content is successfully published.'}
        steps={null}
        buttons={[
          {
            text: 'Back to what to do next list',
            action: gotoWhatToDoCardList,
          },
        ]}
      />

    </>
  );
}


const mapStateToProps = (state) => ({
  updateStatus: state.whatToDoNextList.updateStatus,
});

export default connect(mapStateToProps)(CreateWhatToDoNext);

