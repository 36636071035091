import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';

const queryString = require('query-string');

function CommunityList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { listFields, dataList, pageInfo, totalPage, totalCount, checkedList } =
    useSelector((state) => ({
      listFields: state.communityModel.listDisplayFields,
      dataList: state.communityModel.pagedList,
      pageInfo: state.communityModel.pageInfo,
      totalPage: state.communityModel.totalPage,
      totalCount: state.communityModel.totalCount,
      checkedList: state.communityModel.checkedList,
    }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const createObjectAction = () => {
    history.push('/communities/create');
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          title="Create Community Target"
          action={() => {
            console.log("@@45", location)
            history.push({
              pathname: '/communities/0/target/create',
              state: { fromCommunity: location }
            });
          }}
          requires={PermissionCodes.changeMission}
          customClass={'btn-further btn-margin-right-16'}
        />,
        <AuthButton
          title="Create Community"
          action={createObjectAction}
          requires={PermissionCodes.changeCommunity}
          customClass={'btn-further'}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.changeCommunity,
          action: createObjectAction,
          content: 'Create Community',
        },
      ]}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeCommunity,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'communityModel'}
              permissionGroup={PermissionCodes.community}
              deleteInfo={{
                data: [],
                title: 'community',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.COMMUNITY,
              }}
              rankDisabled
              actions={['Edit', 'Detail', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: 'communityModel/clearData' });
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);

      dispatch({
        type: 'communityModel/getList',
        payload: {
          ...querySearch,
          page,
          reverse: rank,
          search: searchKey,
        },
      });
    }
  }, [location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'After you created community, customers can join the community and get defined rewards.',
          title: NavBarNames.community,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
      />
      <DeletePrompt
        data={checkedList}
        title={'communities'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.COMMUNITY}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'communityModel/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            },
          });
        }}
      />
    </>
  );
}

export default CommunityList;
