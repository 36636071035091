import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomMultipleSelect from '../base/CustomMultipleSelect';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { useCompare } from '../../utils';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import { withResizeDetector } from 'react-resize-detector';

function DropdownToMutipleSelector(props) {
  const width = props.width;
  const title = props.title;
  const groupName = props.groupName;
  const conditionIndex = props.conditionIndex;

  const dropDown = props.dropDown;
  const mutipleSelector = props.mutipleSelector;
  const mutipleSelecteOptions = mutipleSelector.options;

  const defaultValue = dropDown.defaultValue || 'Please select';
  const defaultSelected = mutipleSelector.defaultValue || [];

  const dropDownName = props.dropDownName || `${title}-lookup`;
  const selectorName = props.dropDownName || `${title}-selector`;

  const isReset = props.isReset || false;
  const changeReset = props.changeReset || (() => {});
  const isCreate = props.isCreate;

  //   const onMutipleSelectorChange = props.onMutipleSelectorChange || (() => {});

  const [lookup, setLookup] = useState(
    dropDown.defaultValue || 'Please select',
  );

  const [showSelector, setShowSelector] = useState(
    lookup.toLowerCase() === 'in',
  );
  const [selected, setSelected] = useState(defaultSelected);

  const lookups =
    title === 'Level' ? ['In'] : ['In', 'Is empty', 'Is not empty'];
  const dropDownOptions = lookups.map((lookup) => ({
    name: lookup,
    action: () => {
      setLookup(lookup);
      setShowSelector(lookup.toLowerCase() === 'in');
    },
  }));

  const dispatch = useDispatch();
  const vals = { lookup, selected };
  const hasValsChanged = useCompare(vals);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({
        type: 'segments/changeVals',
        payload: {
          vals,
          groupName,
          conditionIndex,
        },
      });
    }
  });

  useEffect(() => {
    if (isReset) {
      if (isCreate) {
        // setLookup(
        //   groupName === 'genderCharacteristic'
        //     ? 'Is not empty'
        //     : 'Please select',
        // );
        setLookup('Please select');
        setShowSelector(false);
        setSelected([]);
      } else {
        setLookup(dropDown.defaultValue || 'Please select');
        setShowSelector(dropDown.defaultValue?.toLowerCase() === 'in');
        setSelected(mutipleSelector.defaultValue || []);
      }

      changeReset(false);
    }
  }, [isReset, isCreate, dropDown, mutipleSelector, changeReset]);

  // useEffect(() => {
  //   setLookup(dropDown.defaultValue || 'Please select');
  //   setShowSelector(lookup.toLowerCase() === 'in');
  // }, [dropDown, lookup]);

  // const reset = () => {
  //   setLookup(defaultValue);
  //   setShowSelector(lookup.toLowerCase() === 'in');
  //   setSelected(defaultSelected);
  // };

  return (
    <div style={{ width: '100%' }}>
      <CustomTitleLabel title={title} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginBottom: 15 }}>
          <DropDownWithDefaultSelect
            name={dropDownName}
            actions={dropDownOptions}
            defaultValue={lookup}
          />
        </div>

        {showSelector ? (
          // <CustomMultipleSelect
          //   name={selectorName}
          //   defaultSelected={defaultSelected}
          //   options={mutipleSelecteOptions}
          //   hideTitle={true}
          //   onSelectChange={(options) => {
          //     setSelected(options);
          //   }}
          //   hideAddButton={true}
          //   isReset={isReset}
          // />
          <div style={width < 972 ? { width: '100%' } : { flex: 1 }}>
            <CustomAddRemoveMutipleSelector
              data={mutipleSelecteOptions}
              selectedList={defaultSelected || []}
              setValue={setSelected}
              sourceReminderText={'Please select item(s)'}
              amidReminderText={'Please select item(s)'}
              isReset={isReset}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default withResizeDetector(DropdownToMutipleSelector);
