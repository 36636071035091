import React from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  LanguageConfig,
  LanguageTabTransLation,
} from '../../config/CustomEnums';

const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];


const ContentSection = ({
  rewardCodeDetail,
}) => {
  const showdown = require('showdown');
  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);

  const {
    linkedBrand,
    translations = {},
  } = rewardCodeDetail || {};

  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value) : '-',
        }}
      ></td>
    );
  };

  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const sectionValues = (valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) =>
          fieldValueText(translations[language]?.[valueKey], index),
        )}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">CONTENT</label>
      <PerfectScrollbar>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
              {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText('Name')}
              {sectionValues('name')}
            </tr>
            <tr>
              {fieldNameText('Related Brand')}
              {fieldValueText(linkedBrand?.name, 0)}
              {fieldValueText(linkedBrand?.brandTranslations?.[LanguageConfig.traditionalChinese]?.name, 1)}
              {fieldValueText(linkedBrand?.brandTranslations?.[LanguageConfig.simplifiedChinese]?.name, 2)}
            </tr>
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
});

export default connect(mapStateToProps)(ContentSection);