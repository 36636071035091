import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import { APIStatus, BarcodeVolumeConversion, LanguageConfig } from '../../config/CustomEnums';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BrandSelectDropdown from '../campaign/campaignCreation/BrandSelectDropdown';
import { productTypes, productVolume, Errors } from '../../models/BarcodeModel'
import CustomInputWithMaxLength from '../base/CustomInputWithMaxLength';
import './Barcode.scss'
import tick from '../../assets/images/barcodeTick.svg'
import CloseButton from '../../assets/images/coupon/close.svg'
import ImageUploader from '../base/ImageUploader';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

function AddBarcode({ onAdd, errorFields, codes }) {
  const dispatch = useDispatch()
  const [validated, setValidated] = useState(true)
  const [duplicated, setDuplicated] = useState(false)
  const [code, onCodeChagne] = useState()
  console.log("errorFields:", errorFields)
  const onValide = (code) => {
    if (code?.length != 13) {
      setValidated(false)
      return
    }
    const codeList = code.split("").map(item => parseInt(item))
    const unSupportedCode = codeList.some(item => item === "NaN")
    if (unSupportedCode) {
      setValidated(false)
      return
    }
    let sumEven = 0
    let sumOdd = 0
    codeList.forEach((item, index) => {
      console.log("item, index", item, index)
      if ((index + 1) % 2 == 0) {
        sumEven += item
      } else if (index < codeList.length - 1) {
        sumOdd += item
      }
    })
    console.log("sumEven, sumOdd :", sumEven, sumOdd)
    const remainder = (sumEven * 3 + sumOdd) % 10
    let checkDigit = remainder
    if (remainder != 0) {
      checkDigit = 10 - remainder
    }
    console.log("checkDigit:", remainder, checkDigit, codeList[codeList.length - 1])
    if (checkDigit != codeList[codeList.length - 1]) {
      setValidated(false)
      return
    }
    if (codes?.includes(code)) {
      setValidated(true)
      setDuplicated(true)
      return
    }
    dispatch({
      type: "barcodes/checkCode", payload: {
        code: code, afterAction: (result) => {
          setValidated(true)
          setDuplicated(!result)
          if (result) {
            onAdd(code)
            onCodeChagne("")
          }
        }
      }
    })

  }
  return (<><label
    className={`create-section-label create-section-label-bottom-space`}
  >
    Input Barcodes
  </label>
    <label className="tips-message">Support ean 13 barcodes</label>
    <div>
      <div
        className={`custom-input-with-max-length-with-count btn-margin-right-16 ${!validated || duplicated || errorFields.indexOf("codes") > -1 ? "error-field" : ''}`}
      >
        <input
          type="text"
          value={code}
          onChange={({ target }) => {
            onCodeChagne(target?.value || "")
          }}
          className={`custom-input-with-max-length-with-count-input`}
          maxLength={13}
        />
        {/* {validated && code ? <img className="barcode-tick-icon" src={tick} /> : null} */}
      </div>
      <button
        onClick={() => {
          onValide(code)
        }}
        className='btn-add-button-common'>+ Add Barcode</button>
    </div>
    <ErrorFieldMessage
      error={!validated || duplicated || errorFields.indexOf("codes") > -1}
      message={!validated ? Errors.codes.message : Errors.codesDuplicated.message}
    />
  </>)
}

function StepOne({
  errorFields,
  status,
  detail
}) {
  const [data, setData] = useState(detail || {})
  const history = useHistory()
  const disptch = useDispatch()
  const [checking, onChecking] = useState(false)
  console.log("data:", data)
  useEffect(() => {
    if (status === APIStatus.success) {
      console.log("data StepOne:", status)
      setData(detail)
    }
  }, [status])

  const codes = data?.codes || []
  const productTypeSection = () => {
    console.log("productTypes:", productTypes, data?.productType)
    return <CustomTitleWithDropDown
      title={"Recyclable Type"}
      needFilter={false}
      source={productTypes}
      defaultValue={{ value: data?.productType, label: data?.productType?.name }}
      setValue={(item) => {
        setData({ ...data, productType: item.value })
      }}
      errors={errorFields}
      errorName={"productType"}
    />
  }

  const productEnNameSection = () => {
    return <CustomInputWithMaxLength
      sectionTitle={"Product Name (English)"}
      value={data.productName}
      valueChange={(value) => {
        setData({ ...data, productName: value })
      }}
      showCount
      error={errorFields.indexOf("productName") > -1}
      errorMessage={Errors.productName.message}
    />
  }
  const productChNameSection = () => {
    return <CustomInputWithMaxLength
      sectionTitle={"Product Name (Chinese)"}
      value={data?.translations?.[LanguageConfig.traditionalChinese]?.productName}
      valueChange={(value) => {
        setData({ ...data, translations: { [LanguageConfig.traditionalChinese]: { ...(data?.translations?.[LanguageConfig.traditionalChinese] || {}), productName: value } } })
      }}
      showCount
      error={errorFields.indexOf("productChName") > -1}
      errorMessage={Errors.productChName.message}
    />
  }

  const brandSection = () => {
    return (<BrandSelectDropdown
      title={"Link to Brand"}
      defaultValue={{
        value: data?.linkedBrand,
        label: data?.linkedBrand?.name,
      }}
      onSelectChange={(item) => {
        setData({ ...data, linkedBrand: item.value })
      }}
      editable
      error={{ errors: errorFields, errorName: "linkedBrand" }}
      filterOption={{
        moreSearch: {
          showInBarcode: true,
        },
        isAllActive: true,
        isAll: true
      }}
    />)
  }

  const codesSection = () => {
    return <>
      <AddBarcode
        errorFields={errorFields}
        codes={data.codes}
        onAdd={(code) => {
          setData({ ...data, codes: [...(data.codes || []), code] })
        }} />

      <label
        className={`create-section-label create-section-label-bottom-space`}
      >
        Barcodes
      </label>
      <div style={{ display: 'flex', flexWrap: "wrap" }}>
        {codes.map(code => {
          return <div className='barcode-code'>
            <label>{code}</label>
            <img src={CloseButton} alt="delete" onClick={() => {
              const newCodes = codes.filter(item => item !== code)
              setData({ ...data, codes: newCodes })
            }} />
          </div>
        })}
      </div>

    </>
  }

  const volumnSection = () => {
    return <>
      <label
        className={`create-section-label create-section-label-bottom-space`}
      >
        Volume
      </label>
      <div className={"barcode-volume"}>
        <input
          type="number"
          step="0.001"
          value={data?.volumeQuantity}
          onChange={({ target }) => {
            const pattern = /^\d+(?:\.\d{1,3})?$/
            const valid = pattern.test(target.value)
            if (!valid) {
              return
            }
            setData({ ...data, volumeQuantity: target.value })
          }}
          min={0}
          className={`coupon-campaign-section-input `} />
        <CustomTitleWithDropDown
          source={productVolume}
          defaultValue={{ value: data?.volumeConversion, label: BarcodeVolumeConversion?.[data?.volumeConversion]?.name }}
          setValue={(item) => {
            console.log("volumeConversion:", item)
            setData({ ...data, volumeConversion: item.value?.value })
          }}
          needFilter={false}
          errors={errorFields}
          errorName={"volumeConversion"}
        />
      </div>
      <ErrorFieldMessage
        error={errorFields.indexOf("volume") > -1}
        message={Errors.volume.message}
      />
    </>
  }

  const productImageSection = () => {
    return <>
      <label className="create-section-label create-section-label-bottom-space">
        Product Image
      </label>
      <label className="create-campaign-cover-photo-reminder">
        Suggested Image Size: 600px * 600px (Less than 2MB, support JPG, PNG and GIF only)
      </label>
      <ImageUploader
        images={data.productImage ? [data.productImage] : []}
        aspect={1 / 1}
        maxImageNum={1}
        language={LanguageConfig.english}
        onImageStateChange={(imageData) => {
          setData({ ...data, productImage: imageData?.[0] })
        }}
        uploadImageClicked={() => { }}
        errorMessage={
          errorFields.indexOf("productImage") > -1
            ? Errors.productImage.message
            : ''
        }
      />
    </>
  }


  const generalSections = () => {
    return <>
      <label className="create-section-title">
        General
      </label>
      {productTypeSection()}
      {productEnNameSection()}
      {productChNameSection()}
      {brandSection()}
      {codesSection()}
      {volumnSection()}
      {productImageSection()}
    </>
  }

  return (
    <>
      <ContentSections
        sections={[
          generalSections(),
        ]}
        hidePreview={true}
      />
      <ContinueWithBackButtons
        continueAction={() => {
          if (checking) {
            return
          }
          onChecking(true)
          disptch({
            type: "barcodes/stepChange", payload: {
              data: data,
              step: 0,
              afterAction: () => onChecking(false)
            },
          })
        }}
        backAction={() => {
          disptch({ type: "barcodes/clearState" })
          history.push({ pathname: "/barcodes" })
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  errorFields: state.barcodes.errorFields,
  status: state.barcodes.status,
  detail: state.barcodes.detail
});

export default connect(mapStateToProps)(StepOne);
