import React from 'react';
import './Loading.scss';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';

function Loading({ customClass }) {
  return (
    <div className={`loading-area ${customClass || ''}`}>
      <Image src={LoadingIcon} className="loading-icon"></Image>
    </div>
  );
}

export default Loading;
