import React, { Component } from 'react';
import './CouponInstructionSection.scss';
import { connect } from 'react-redux';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import i18n from '../../../I18n';
import { createAction } from '../../../utils';

@connect(({ createCoupon }) => ({
  couponTranslation: createCoupon.couponTemplate.translations,
}))
class CouponInstructionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructionTitle:
        props.couponTranslation[props.language]?.instructionSectionTitle,
      instructionContent:
        props.couponTranslation[props.language]?.instructionSectionContent,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.couponTranslation !== this.props.couponTranslation) {
      this.setState({
        instructionTitle: this.props.couponTranslation[this.props.language]
          ?.instructionSectionTitle,
        instructionContent: this.props.couponTranslation[this.props.language]
          ?.instructionSectionContent,
      });
    }

    if (this.props.onSubmit) {
      this.props.onSubmitAction({
        instructionSectionTitle: this.state.instructionTitle,
        instructionSectionContent: this.state.instructionContent,
      });
    }
  }

  formChanged = () => {
    this.props.dispatch({
      type: 'createCoupon/updateState',
      payload: { formChanged: true },
    });
  };

  render() {
    return (
      <CustomMarkDownArea
        areaTitle={i18n.t('instruction', { locale: this.props.language })}
        title={{
          label: i18n.t('instruction_section_title', {
            locale: this.props.language,
          }),
          value: this.state.instructionTitle,
          valueChange: (value) => {
            this.formChanged();
            this.setState({
              instructionTitle: value,
            });
          },
          focus: () => this.props.inputChange('instructionTitle'),
        }}
        content={{
          label: i18n.t('instruction_section_content', {
            locale: this.props.language,
          }),
          value: this.state.instructionContent,
          valueChange: (value) => {
            this.formChanged();
            this.setState({
              instructionContent: value,
            });
          },
          focus: () => this.props.inputChange('instructionContent'),
        }}
      />
    );
  }
}

export default CouponInstructionSection;
