import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import {
  SavedStatus,
  LanguageConfig,
  DELETE_RELATED_SECTIONS,
  CheckStatus,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Loading from '../../../components/base/Loading';
import GeneralSection from './GeneralSection';
import KeyVisualSection from './KeyVisualSection';
import DetailSection from './DetailSection';


function NewsFeedChange({
  object,
  checked,
  saved,
  objectLoading,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    dispatch({ type: 'newsFeedModel/getObject', payload: { id } });
    return () => {
      dispatch({ type: 'newsFeedModel/clearData' });
    };
  }, []);

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch({
        type: 'newsFeedModel/createOrUpdate',
        payload: {
          afterAction: id ? () => history.push('/news_feeds') : () => { },
        },
      });
    }
  }, [dispatch, checked]);


  const getLanguageTab = (language) => ([
    <GeneralSection language={language} />,
    <KeyVisualSection language={language} />,
    <DetailSection language={language} />,
  ])


  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  const stepSet = [
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete News Feed' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={() => {
          dispatch({
            type: 'newsFeedModel/checkValsValid',
            payload: {},
          });
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.changeNewsFeed}
      />
      <DeletePrompt
        message={{
          title: 'Delete this news feed',
          content: 'Did you confirm to delete this news feed?',
        }}
        title={'News feed'}
        data={object}
        relatedSections={DELETE_RELATED_SECTIONS.NEWS_FEED}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'newsFeedModel/delete',
            payload: {
              id: object.pk,
              afterAction: () => {
                history.push('/news_feeds');
              },
            },
          });
        }}
      />
    </>,
  ];

  const getButtons = () => {
    return [
      {
        text: 'Back to news feed list',
        action: () => {
          const fromCommunity = history.location?.state?.fromCommunity || {};
          if (fromCommunity?.pathname) {
            history.push({ 
              pathname: fromCommunity?.pathname,
              search: fromCommunity?.search,
              hash: fromCommunity?.hash,
            });
            return;
          }
          history.push({
            pathname: '/news_feeds',
          });
        },
      },
    ];
  };


  if (objectLoading) {
    return <ContentSections sections={[<Loading />]} hidePreview />;
  };
  return (
    <div className="customer">
      <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            dispatch({
              type: 'newsFeedModel/removeFromCookie',
            });
          }
        }}
        showFinishPop={saved === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'News feed successfully created.',
          buttons: getButtons(),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={id ? object.name : ''}
          />
        }
        caution={{
          detail: '',
          title: id
            ? object.name
            : 'Create News Feed',
          model: id ? null : 'newsFeedModel',
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  object: state.newsFeedModel.oneObject || {},
  checked: state.newsFeedModel.checked,
  saved: state.newsFeedModel.saved,
  objectLoading: state.newsFeedModel.objectLoading,
});

export default connect(mapStateToProps)(NewsFeedChange);
