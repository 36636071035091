import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image, Dropdown } from 'react-bootstrap';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import BasePrompt from '../base/prompt/BasePrompt';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
  CustomNumberInputWithUnit,
} from './CustomBaseComponments';
import addImage from '../../assets/images/addx1.png';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import {
  CampaignType,
  EarningRuleType,
  EarningRuleRewardType,
} from '../../config/CustomEnums';
import { getCampaignTypeSelectPromptConfig } from '../campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { createAction, useCompare } from '../../utils';
import { useHistory } from 'react-router-dom';
import RewardSection from './RewardSection';
import CustomContentFilterDropDown from '../base/CustomContentFilterDropDown';
import DeleteIcon from '../../assets/images/deleteIcon.png';

function CarbonConversionRate({ category, carbons, setCarbons }) {
  return (
    <div>
      <label style={{ width: '100px' }}>{category.name}</label>
      <input
        onChange={({ target }) => {
          setCarbons({
            ...carbons,
            [category.pk]: { ...carbons[category.pk], carbon: target.value },
          });
        }}
        defaultValue={carbons[category.pk] ? carbons[category.pk].carbon : null}
      ></input>
      carbon
      <input
        onChange={({ target }) => {
          setCarbons({
            ...carbons,
            [category.pk]: { ...carbons[category.pk], point: target.value },
          });
        }}
        value={carbons[category.pk] ? carbons[category.pk].point : null}
      ></input>
      points
    </div>
  );
}

function RewardCarbonSection(props) {
  const defaultValue = props.defaultValue || {};

  //   const materialCommonItems = [
  //     { pk: 4, name: 'Glass bottle' },
  //     { pk: 5, name: 'Paper' },
  //   ];

  const { categoryList } = useSelector((state) => ({
    categoryList: state.storeCategoryList.categoryList,
  }));

  const [quantity, setQuantity] = useState(defaultValue.quantity);
  const [carbons, setCarbons] = useState(defaultValue.carbons || {});

  const [content, setContent] = useState([]);
  // const [isShowDropdown, setIsShowDropdown] = useState(false);

  //   const [showCoupons, setShowCoupons] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const vals = {
    carbons,
    quantity,
  };
  const hasValchanged = useCompare(vals);
  useEffect(() => {
    if (hasValchanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.coupons.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  useEffect(() => {
    const rewardsSubcategories = defaultValue.rewards
      ? Object.keys(defaultValue.rewards)
      : [];

    const initContent = [];

    for (let i = 0; i < categoryList.length; i++) {
      const category = categoryList[i];

      for (let f = 0; f < category.subcategories.length; f++) {
        const subcategory = category.subcategories[f];
        console.log('@@120: ', rewardsSubcategories, subcategory.pk);
        if (rewardsSubcategories.includes(subcategory.pk.toString())) {
          initContent.push({
            title: subcategory.name,
            data: subcategory,
            componment: (
              <RewardSection
                onSubmit={onSubmit}
                onSubmitAction={(data) => {
                  onSubmitAction(data);
                }}
                defaultValue={
                  defaultValue.rewards
                    ? defaultValue.rewards[subcategory.pk]
                    : {}
                }
                hideTitle
                groupKey={subcategory.pk}
              />
            ),
          });
        }
      }
    }

    setContent([...content, ...initContent]);
  }, []);

  useEffect(() => {
    console.log('@@157: ', content);
  }, [content]);

  const getData = () => {
    let data = {};
    for (let i = 0; i < categoryList.length; i++) {
      const category = categoryList[i];
      data[category.name] = category.subcategories?.filter(
        (item) => content.filter((val) => val.data.pk === item.pk).length <= 0,
      );
    }

    return data;
  };

  return (
    <>
      <label className="create-section-title">REWARD</label>

      {content.map((item, index) => {
        return (
          <>
            <label className="create-section-title-without-upper">
              Item {index + 1}: {item.title}
            </label>
            <div
              className={`reward-content-area ${
                errorFields.includes('rewards') ? 'error-field' : ''
              }`}
            >
              <div className="display-flex-area">
                <div className="reward-content">
                  <RewardSection
                    onSubmit={onSubmit}
                    onSubmitAction={(data) => {
                      onSubmitAction(data);
                    }}
                    defaultValue={
                      defaultValue.rewards
                        ? defaultValue.rewards[item.data.pk]
                        : {}
                    }
                    hideTitle
                    groupKey={item.data.pk}
                  />
                </div>
                <button
                  className="reset-button content-delete-button-area"
                  onClick={() => {
                    const afterRemoveContent = content.filter(
                      (val) => val.title !== item.title,
                    );
                    setContent(afterRemoveContent);

                    dispatch({
                      type: 'createEarningRules/removeVals',
                      payload: { rewardsGroupKey: item.data.pk },
                    });
                  }}
                >
                  <Image src={DeleteIcon} className="content-delete-button" />
                </button>
              </div>
            </div>
          </>
        );
      })}

      <ErrorFieldMessage
        id={'rewards'}
        error={errorFields.includes('rewards')}
        message={EarningRuleErrorHandleField['rewards'].message}
      />

      <CustomContentFilterDropDown
        title="Add requirement"
        data={getData()}
        content={content}
        addContentAction={(section) => {
          setContent([
            ...content,
            {
              title: section.name,
              data: section,
            },
          ]);
        }}
      />

      {/* <Dropdown
        onToggle={(isOpen) => {
          setIsShowDropdown(isOpen);
        }}
      >
        <Dropdown.Toggle
          className="btn-back-button-common content-add-button"
          variant="none"
          id={`dropdown`}
        >
          + Add requirement
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ zIndex: 2 }}>
          <CustomContentFilterDropDown
            data={getData()}
            show={isShowDropdown}
            addContent={(section) => {
              setContent([
                ...content,
                {
                  title: section.name,
                  data: section,
                },
              ]);
            }}
            settedParams={content?.map((item) => item.title)}
          />
        </Dropdown.Menu>
      </Dropdown> */}
    </>
  );
}

export default RewardCarbonSection;
