import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import { sessionDataKey } from '../../../models/MissionModel';
import CreateMissionStepOne from '../../../components/mission/CreateMissionStepOne';
import CreateMissionStepTwo from '../../../components/mission/CreateMissionStepTwo';
import CreateMissionStepThree from '../../../components/mission/CreateMissionStepThree';
import CreateMissionStepFour from '../../../components/mission/CreateMissionStepFour';
import { communityTargetBreads, communityTargetFromListBreads } from '../../../config/CustomBreadConfig';

function CreateMission({
  stepConfig,
  communityTargetStepConfig,
  currentStep,
  missionDetail,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const fromCommunity = history.location?.state?.fromCommunity || {};
  const fromCommunityPathName = fromCommunity?.pathname || null;
  const communityName = history.location?.state?.communityName || "Community Name";
  const communityId = parseInt(fromCommunityPathName?.split('/')?.[2] || '') || null;

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'mission/getMission',
        payload: {
          id: params.id,
        }
      });
    } else {
      dispatch({
        type: 'mission/loadMissionFromCookie',
        payload: { fromCommunity: !!fromCommunityPathName, communityId: communityId }
      });
    }

    return () => {
      dispatch({ type: 'mission/clearState' });
    };
  }, []);

  const stepContent = [
    <CreateMissionStepOne />,
    <CreateMissionStepTwo />,
    <CreateMissionStepThree />,
    <CreateMissionStepFour />,
  ];

  const communityTargetStepContent = [
    <CreateMissionStepTwo />,
    <CreateMissionStepThree />,
    <CreateMissionStepFour />,
  ];

  const search = queryString.parse(history.location.search);

  return (
    <ScrollableComponent
      tempSave={(save) => {
        dispatch({
          type: 'mission/saveOrRemoveMissionFromCookie',
          payload: save,
        });
      }}
      sessionDataKey={sessionDataKey}
      content={fromCommunityPathName ? communityTargetStepContent : stepContent}
      currentStep={currentStep}
      stepsConfig={fromCommunityPathName ? communityTargetStepConfig : stepConfig}
      breadcrumb={
        fromCommunityPathName
          ? <CustomBreadcrumb
            breadcrumbs={
              params?.communityId == 0
                ? communityTargetFromListBreads('Create Community Target')
                : communityTargetBreads(
                  communityName,
                  params.id
                    ? missionDetail.name || 'Edit Community Target'
                    : 'Create Community Target'
                )}
          />
          : <CustomBreadcrumb
            name={
              params.id
                ? missionDetail.name || 'Edit Mission'
                : 'Create Mission'
            }
          />
      }
      caution={{
        detail: '',
        title: fromCommunityPathName
          ? (params.id ? "Edit Community Target" : "Create Community Target")
          : (params.id ? "Edit Mission" : "Create Mission")
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  stepConfig: state.mission.stepConfig,
  communityTargetStepConfig: state.mission.communityTargetStepConfig,
  currentStep: state.mission.currentStep,
  missionDetail: state.mission.missionDetail,
});

export default connect(mapStateToProps)(CreateMission);
