import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import { createAction, useCompare } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import CustomAddRemoveMutipleSelector from '../../../components/customer/CustomAddRemoveMutipleSelector';
import {
  CheckStatus,
  SavedStatus,
  LanguageConfig,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  getPreviewLayoutImages,
  defaultPreviewImage,
} from '../../../components/store/StorePreviewConfig';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import ImageUploader from '../../../components/base/ImageUploader';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from '../../../components/customer/CustomTitleWithDropDown';
import CustomEditor from '../../../components/base/CustomEditor';
import i18n from '../../../I18n';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import BrandSelectDropdown from '../../../components/campaign/campaignCreation/BrandSelectDropdown';
import {
  getStoreCreateSuccessStepBar,
  CREATE_STORE,
} from '../../../components/base/prompt/NextStepConfig';
import CustomTitleWithSwitch from '../../../components/base/CustomTitleWithSwitch';
import CustomRadios from '../../../components/base/CustomRadios';

const getError = (name, errorFields, errorHandle) => {
  return {
    id: name,
    error: errorFields?.fields?.includes(name),
    message: errorFields?.messages?.map((item) => {
      if (item.field === name) {
        return errorHandle[item.field][item.errorType];
      }
    }),
  };
};

const getErrorMessage = (name, errorFields, errorHandle) => {
  const error = errorFields?.messages?.filter(
    (item) => item.field === name,
  )?.[0];

  return error ? errorHandle[error.field][error.errorType] : '';
};

function CreateStore() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    store,
    checked,
    errorFields,
    saved,
    districtList,
    subCategoryList,
    formChanged,
  } = useSelector((state) => ({
    store: state.storeModel.oneStore || {},
    checked: state.storeModel.checked,
    errorFields: state.storeModel.errorFields,
    saved: state.storeModel.saved,
    districtList: state.storeModel.filteredDistrictList,
    subCategoryList: state.storeCategoryList.subCategoryList,
    formChanged: state.storeModel.formChanged,
  }));

  const inputWithImageConfig = getPreviewLayoutImages();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [activeSection, setActiveSection] = useState();
  const [showAlertPrompt, setShowAlertPrompt] = useState({
    title: 'brand',
    show: false,
    pathname: 'brand/create',
  });
  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(createAction('storeModel/createOrUpdate')({}));
    }
  }, [dispatch, checked]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/stores');
    }
  }, [history, saved]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'storeModel/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = params.id;
  useEffect(() => {
    if (id) {
      dispatch(createAction('storeModel/getOneStore')({ id }));
    } else {
      dispatch({ type: 'storeModel/loadDataFromCookie' });
    }

    dispatch(createAction('storeModel/getDistrictList')({}));
    dispatch(createAction('storeCategoryList/getAllStoreSubcategoryList')({}));
  }, [dispatch, id]);

  const [name, setName] = useState({
    [LanguageConfig.english]: null,
    [LanguageConfig.simplifiedChinese]: null,
    [LanguageConfig.traditionalChinese]: null,
  });
  const [displayPriority, setDisplayPriority] = useState();
  const [coverPhoto, setCoverPhoto] = useState();
  const [otherPhoto, setOtherPhoto] = useState([]);
  const [district, setDistrict] = useState();
  const [address, setAddress] = useState({
    [LanguageConfig.english]: null,
    [LanguageConfig.simplifiedChinese]: null,
    [LanguageConfig.traditionalChinese]: null,
  });
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState();
  const [phoneNumberSubscriberNumber, setPhoneNumberSubscriberNumber] =
    useState();
  const [emailAddress, setEmailAddress] = useState();
  const [securityKey, setSecurityKey] = useState();
  const [description, setDescription] = useState({
    [LanguageConfig.english]: null,
    [LanguageConfig.simplifiedChinese]: null,
    [LanguageConfig.traditionalChinese]: null,
  });
  const [brand, setBrand] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [staffCode, setStaffCode] = useState();

  const [isAvaliable, setIsAvaliable] = useState(false);
  const [isReverseVendingMachine, setIsReverseVendingMachine] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [hideInMap, setHideInMap] = useState(false);
  const [subcategories, setSubcategories] = useState();

  const vals = {
    name,
    displayPriority,
    coverPhoto,
    otherPhoto,
    district,
    address,
    phoneNumberCountryCode,
    phoneNumberSubscriberNumber,
    emailAddress,
    description,
    brand,
    subcategories,
    latitude,
    longitude,
    staffCode,
    isAvaliable,
    isReverseVendingMachine,
    isPublic,
    hideInMap,
    securityKey
  };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'storeModel/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    console.log('store.selectedSubcategories:', store.selectedSubcategories);
    setName(store.name);
    setDisplayPriority(store.displayPriority);
    setCoverPhoto(store.photo);
    setOtherPhoto([store.photo2, store.photo3].filter((item) => item));
    setDistrict(store.district);
    setAddress(store.address);
    setPhoneNumberCountryCode(
      store.phoneNumberCountryCode
        ? typeof store.phoneNumberCountryCode === 'string'
          ? {
              label: store.phoneNumberCountryCode,
              value: store.phoneNumberCountryCode,
            }
          : store.phoneNumberCountryCode
        : null,
    );
    setPhoneNumberSubscriberNumber(store.phoneNumberSubscriberNumber);
    setEmailAddress(store.emailAddress);
    setDescription(store.description);
    setBrand(store.brand);
    setSubcategories(store.selectedSubcategories);
    setLatitude(store.latitude);
    setLongitude(store.longitude);
    setStaffCode(store.staffCode);

    setIsAvaliable(store.isAvailable);
    setIsReverseVendingMachine(store.isReverseVendingMachine);
    setIsPublic(store.isPublic);
    setHideInMap(store.hideInMap);
    setSecurityKey(store.securityKey);

    setTimeout(() => {
      dispatch({
        type: 'storeModel/updateState',
        payload: { formChanged: false },
      });
    }, 500);
  }, [dispatch, store]);

  const getLanguageTab = (language) => {
    // if (createStatus === APIStatus.calling) {
    //   return [<Loading />];
    // }
    // console.log('@@329: ', brand?.label);
    return [
      [
        <div style={{ marginTop: '-30px' }}>
          <CustomTitleWithInput
            title={i18n.t('name', { locale: language })}
            defaultValue={name ? name[language] : null}
            setValue={(value) =>
              setName({
                ...name,
                [language]: value,
              })
            }
            focusChange={() => {
              setActiveSection('name');
            }}
            error={getError('name', errorFields, StoreErrorHandleFields)}
          />
          {language === 'en' ? (
            <>
              <CustomTitleWithInput
                title={'Display order'}
                defaultValue={displayPriority}
                setValue={setDisplayPriority}
                type={'number'}
                error={getError(
                  'displayPriority',
                  errorFields,
                  StoreErrorHandleFields,
                )}
              />
              <label className="create-message-suggest">
                {`Minimum value is 1. The smaller value, the higher priority.`}
              </label>

              <div style={{ display: 'grid' }}>
                <CustomTitleLabel title={'Cover photo'} />
                <label className="create-message-suggest">
                  {i18n.t('cover_photo_suggest')}
                </label>
                <ImageUploader
                  minWidth={900}
                  minHieght={600}
                  images={coverPhoto ? [coverPhoto] : []}
                  onImageStateChange={(newState) => {
                    setCoverPhoto(newState[0]);
                  }}
                  aspect={4 / 3}
                  maxImageNum={1}
                  language={language}
                  uploadImageClicked={() => {
                    setActiveSection('coverPhoto');
                  }}
                  errorMessage={getErrorMessage(
                    'coverPhoto',
                    errorFields,
                    StoreErrorHandleFields,
                  )}
                />
              </div>

              <div style={{ display: 'grid' }}>
                <CustomTitleLabel title={'Other photo'} />
                <label className="create-message-suggest">
                  {i18n.t('cover_photo_suggest')}
                </label>
                <ImageUploader
                  minWidth={900}
                  minHieght={600}
                  images={otherPhoto.length > 0 ? otherPhoto : []}
                  onImageStateChange={(newState) => {
                    setOtherPhoto(newState);
                  }}
                  aspect={4 / 3}
                  maxImageNum={2}
                  language={language}
                  uploadImageClicked={() => {
                    setActiveSection('otherPhoto');
                  }}
                  errorMessage={getErrorMessage(
                    'otherPhoto',
                    errorFields,
                    StoreErrorHandleFields,
                  )}
                />
              </div>

              <CustomTitleWithDropDown
                title="District(optional)"
                source={districtList}
                defaultValue={district}
                setValue={setDistrict}
                onFocus={() => setActiveSection('district')}
                filterAction={'storeModel/getDistrictList'}
              />
            </>
          ) : null}

          <CustomTitleWithInput
            title={i18n.t('address', { locale: language })}
            defaultValue={address ? address[language] : null}
            setValue={(value) =>
              setAddress({
                ...address,
                [language]: value,
              })
            }
            focusChange={() => {
              setActiveSection('address');
            }}
            error={getError('address', errorFields, StoreErrorHandleFields)}
          />
          {language === 'en' ? (
            <>
              <CustomTitleWithDropDownAndInput
                title="Mobile number(optional)"
                source={[
                  // { name: '856', pk: '856' },
                  { name: '852', pk: '852' },
                  // { name: '86', pk: '86' },
                ]}
                defaultValue={phoneNumberCountryCode}
                defaultInputValue={phoneNumberSubscriberNumber}
                needFilter={false}
                customClass="dropdown-short"
                inputCustomClass="dropdown-input-long"
                setValue={setPhoneNumberCountryCode}
                setInputValue={setPhoneNumberSubscriberNumber}
                onFocus={() => {
                  setActiveSection('mobileNumber');
                }}
                inputOnFocusChange={() => setActiveSection('mobileNumber')}
              />

              <CustomTitleWithInput
                title={'Email(optional)'}
                defaultValue={emailAddress}
                setValue={setEmailAddress}
                focusChange={() => {
                  setActiveSection('email');
                }}
                error={getError(
                  'emailAddress',
                  errorFields,
                  StoreErrorHandleFields,
                )}
              />
              <CustomTitleWithInput
                title={'Security Key(optional)'}
                defaultValue={securityKey}
                setValue={setSecurityKey}
                focusChange={() => {}}
              />
            </>
          ) : null}

          <>
            <CustomTitleLabel
              title={i18n.t('description', { locale: language })}
            />
            <CustomEditor
              initialValue={description ? description[language] : null}
              onValueChange={(data) => {
                setDescription({ ...description, [language]: data });
              }}
              onFocus={() => {
                setActiveSection('description');
              }}
            />
          </>
          {language === 'en' ? (
            <>
              {params.id && store.brand ? null : (
                <>
                  <BrandSelectDropdown
                    title={'Related brand (optional)'}
                    defaultValue={{ label: brand?.name, value: brand }}
                    onSelectChange={setBrand}
                    addButton={{
                      title: 'Create brand',
                      action: () => {
                        setShowAlertPrompt({
                          title: 'brand',
                          show: true,
                          pathname: '/brands/create/',
                        });
                      },
                      requires: PermissionCodes.addBrand,
                      customClass: 'btn-add-button-common',
                    }}
                    editable
                    onFocus={() => setActiveSection('brand')}
                    error={{ errors: errorFields?.fields, errorName: 'brand' }}
                  />
                  <label className="tips-message">
                    Once set, cannot be changed.
                  </label>
                </>
              )}
              {/* <div>
                <CustomTitleLabel title="Activity type" />
                <div style={{ display: 'flex' }}>
                  <CustomRadios
                    onChange={(value) => {
                      setActivityType(value);
                      subcategoryChange({
                        activity: value,
                        subcategoriesData: relatedSubcategories,
                      });
                    }}
                    default={activityType}
                    options={radioOptions}
                  />
                </div>
              </div> */}

              <CustomAddRemoveMutipleSelector
                title={'Subcategories'}
                data={subCategoryList}
                selectedList={subcategories}
                // selectAllTitle={'All subcategories'}
                // sourceReminderEnabelSelectAll
                addButton={{
                  title: 'Add Subcategory ',
                  action: () => {
                    setShowAlertPrompt({
                      title: 'subcategory',
                      show: true,
                      pathname: '/store_category/create',
                    });
                  },
                }}
                setValue={setSubcategories}
                sourceReminderText={'All item(s)'}
                amidReminderText={'Selected subcategories'}
                error={getError(
                  'subcategories',
                  errorFields,
                  StoreErrorHandleFields,
                )}
                onFocusFunc={() => {
                  setActiveSection('subCategory');
                }}
                // loadMoreAction={'storeCategoryList/getAllStoreSubcategoryList'}
                groupKey="category"
              />

              <CustomTitleWithInput
                title={'Latitude'}
                defaultValue={latitude}
                setValue={setLatitude}
                type="number"
                error={getError(
                  'latitude',
                  errorFields,
                  StoreErrorHandleFields,
                )}
              />

              <CustomTitleWithInput
                title={'Longitude'}
                defaultValue={longitude}
                setValue={setLongitude}
                type="number"
                error={getError(
                  'longitude',
                  errorFields,
                  StoreErrorHandleFields,
                )}
              />

              <CustomTitleWithInput
                title={'Staff code'}
                defaultValue={staffCode}
                setValue={setStaffCode}
                type="number"
                error={getError(
                  'staffCode',
                  errorFields,
                  StoreErrorHandleFields,
                )}
              />
              <CustomTitleWithSwitch
                title="Available"
                defaultValue={isAvaliable === false ? false : true}
                setValue={setIsAvaliable}
              />
              <CustomTitleWithSwitch
                title="Reverse Vending Machine"
                defaultValue={isReverseVendingMachine}
                setValue={setIsReverseVendingMachine}
              />

              <CustomTitleLabel title="For public or private use" />
              <div style={{ display: 'flex' }}>
                <CustomRadios
                  onChange={(value) => {
                    setIsPublic(value);
                  }}
                  default={isPublic === false ? false : true}
                  options={[
                    { label: 'For public', value: true },
                    { label: 'Private use', value: false },
                  ]}
                />
              </div>

              <CustomTitleWithSwitch
                title="Hide in map"
                defaultValue={hideInMap}
                setValue={setHideInMap}
              />
            </>
          ) : null}
        </div>,
      ],
    ];
  };

  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  const stepSet = [
    <>
      <ContentSections
        languageTabContent={languageTabConfig}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultPreviewImage()}
        activeSection={activeSection}
      />

      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Updated' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={() => {
          dispatch({
            type: 'storeModel/checkValsValid',
            payload: { coverPhoto: coverPhoto },
          });
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteStore}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Store',
          content: 'Did you confirm to delete this Store?',
        }}
        title={'Store'}
        data={store}
        relatedSections={DELETE_RELATED_SECTIONS.STORE}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `storeModel/updateState`,
            payload: { checkedList: [store] },
          });
          dispatch(
            createAction('storeModel/delete')({
              afterAction: () => {
                history.push('/stores/');
              },
            }),
          );
        }}
      />
    </>,
  ];

  const getButtons = () => {
    if (history.location.search) {
      return [
        {
          text: 'Back to store category list',
          action: () => {
            history.push({
              pathname: '/store_category',
            });
          },
        },
        {
          text: 'Maybe Later',
          action: () => history.push('/stores'),
        },
      ];
    }
    return [
      {
        text: 'Back to store list',
        action: () => {
          history.push({
            pathname: '/stores',
          });
        },
      },
    ];
  };

  return (
    <div className="customer">
      <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            dispatch({
              type: 'storeModel/removeFromCookie',
            });
          }
        }}
        showFinishPop={saved === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Store is successfully created.',
          steps: history.location.search
            ? {
                stepNames: getStoreCreateSuccessStepBar(
                  history.location.search,
                ),
                current: CREATE_STORE,
              }
            : null,
          buttons: getButtons(),
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/stores'),
          onClose: () => history.push('/stores'),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? store.storeName : ''} />
        }
        caution={{
          detail: '',
          title: params.id ? store.storeName : 'Create Store',
          model: 'storeModel',
        }}
      />

      <BasePrompt
        show={showAlertPrompt.show}
        closeAction={() =>
          setShowAlertPrompt({ ...showAlertPrompt, show: false })
        }
        rightButton={{
          text: `Go to create ${showAlertPrompt.title}`,
          action: () => {
            history.push({
              pathname: showAlertPrompt.pathname,
              state: {
                from: history.location,
                title: 'Continue to create store?',
                content: 'You can continue to create the store.',
              },
            });
          },
        }}
        title={`Go to create a ${showAlertPrompt.title}?`}
        description={`You will leave the store creation process. After you create a new ${showAlertPrompt.title}, you can back to this page.`}
      />
    </div>
  );
}

export default CreateStore;
