import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './GeneralSection.scss';
import BrandSelectDropdown from './BrandSelectDropdown';
import {
  CampaignType,
  EarningRuleType,
  LanguageConfig,
} from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import AuthButton from '../../base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useHistory } from 'react-router-dom';
import BasePrompt from '../../base/prompt/BasePrompt';
import { ContinueCreate } from '../CampaignUrlConfig';
import CustomAddRemoveMutipleSelector from '../../customer/CustomAddRemoveMutipleSelector';
import { createAction } from '../../../utils';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import CustomTagSection from '../../base/CustomTagSection';
import LocationSection from './LocationSection';

function GeneralSection(props) {
  const focusChange = props.focusChange;
  const language = props.language;
  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => { });

  const {
    generalName,
    shortDescription,
    campaignTag,
    location,
    linkedEarningRules,
    errorFields,
    brandList,
    // storeList,
    campaign,
  } = useSelector((state) => ({
    campaign: state.createCampaign.campaign,
    generalName:
      state.createCampaign.campaign.translations[language].generalName,
    shortDescription:
      state.createCampaign.campaign.translations[language].shortDescription,
    campaignTag: state.createCampaign.campaign.translations[language].campaignTag,
    linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
    location: state.createCampaign.campaign.location,
    errorFields: state.createCampaign.errorFields,
    brandList: state.brand.brandList,
    // storeList: state.storeModel.storeList,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const [campaignGeneralName, setCampaignGeneralName] = useState(generalName);
  const [campaignShortDescription, setCampaignShortDescription] = useState(
    shortDescription,
  );
  const [campaignLinkedBrands, setCampaignLinkedBrands] = useState(
    campaign.linkedBrands,
  );
  const [campaignStateTag, setCampaignStateTag] = useState(campaignTag);
  // const [campaignLinkedStores, setCampaignLinkedStores] = useState(
  //   campaign.linkedStores,
  // );

  const [showAddBrand, setShowAddBrand] = useState(false);
  const isError = (name) => {
    return isShowError(
      CampaignErrorHandleField[name].name,
      errorFields,
      language,
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     createAction('storeModel/getPagedStoreList')({
  //       brands: campaignLinkedBrands?.map((item) => item.pk).join(','),
  //       isSelectorLoad: true,
  //     }),
  //   );
  // }, [campaign.linkedBrands]);

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        shortDescription: campaignShortDescription,
        generalName: campaignGeneralName,
        brand: campaignLinkedBrands,
        campaignTag: campaignStateTag,
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(
      `${CampaignErrorHandleField.generalName.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      `${CampaignErrorHandleField.shortDescription.name}-${language}`,
      errorFields,
      language,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);
  return (
    <>
      <CustomMarkDownArea
        areaTitle={i18n.t('general', { locale: language })}
        title={{
          label: i18n.t('name', { locale: language }),
          value: campaignGeneralName,
          valueChange: (value) => {
            dispatch({
              type: 'createCampaign/setFieldToSession',
              payload: { generalName: value, language },
            });
            setCampaignGeneralName(value);
          },
          focus: () => focusChange('name'),
          error: isError('generalName'),
          errorId: `${CampaignErrorHandleField.generalName.name}-${language}`,
          errorMessage: `${CampaignErrorHandleField.generalName.message}`,
          showCount: true,
          maxLength: 120,
        }}
        content={{
          label: i18n.t('short_description', { locale: language }),
          value: campaignShortDescription,
          valueChange: (value) => {
            dispatch({
              type: 'createCampaign/setFieldToSession',
              payload: { shortDescription: value, language },
            });
            setCampaignShortDescription(value);
          },
          focus: () => focusChange('shortDescription'),
          error: isError('shortDescription'),
          errorId: `${CampaignErrorHandleField.shortDescription.name}-${language}`,
          errorMessage: CampaignErrorHandleField.shortDescription.message,
          // showCount: true,
          // maxLength: 200,
        }}
      />
      {
        linkedEarningRules?.type === EarningRuleType.qrCodeRecyclingExtraRewards ?
          <LocationSection
            language={language}
            onSubmit={onSubmit}
            onSubmitAction={onSubmitAction}
          />
          : null
      }
      {language === LanguageConfig.english ? (
        <>
          <CustomAddRemoveMutipleSelector
            title={i18n.t('related_brand_optional', { locale: language })}
            titleTips={campaign.campaignType === CampaignType.couponsCampaign ? "Max. select 2 brands" : ""}
            data={brandList}
            selectedList={campaign.linkedBrands}
            setValue={(value) => {
              // dispatch(
              //   createAction('storeModel/getPagedStoreList')({
              //     brands: value.map((item) => item.pk).join(','),
              //   }),
              // );

              setCampaignLinkedBrands(value);
              // setCampaignLinkedStores([]);
            }}
            sourceReminderText={'All brand(s)'}
            amidReminderText={'Selected brand(s)'}
            onFocusFunc={() => focusChange('relatedBrand')}
            // addButton={{
            //   action: () => setShowAddBrand(true),
            //   title: 'Create Now',
            //   requires: PermissionCodes.addBrand,
            //   customClass: 'general-section-add-new-brand btn-add-button-common',
            // }}
            error={{
              id: `${CampaignErrorHandleField.relatedBrand.name}-${language}`,
              error: isError(CampaignErrorHandleField.relatedBrand.name),
              message: CampaignErrorHandleField.relatedBrand.message,
            }}
          />
          {/* &&
          campaignLinkedBrands &&
          campaignLinkedBrands?.length > 0 &&
          [
            EarningRuleType.qrCodeScanning,
            EarningRuleType.gpsCheckIn,
            // EarningRuleType.generalPurchase,
            EarningRuleType.habit,
            EarningRuleType.photoTaking,
            EarningRuleType.waterFilling,
          ].includes(campaign.linkedEarningRules?.type)  */}

          {/* {campaign.campaignType === CampaignType.earningRulesCampaign ? (
            <CustomAddRemoveMutipleSelector
              title={i18n.t('applicable_stores', { locale: language })}
              data={storeList}
              selectedList={campaignLinkedStores}
              setValue={(value) => {
                setCampaignLinkedStores(value);
              }}
              sourceReminderText={'All store(s)'}
              amidReminderText={'Selected store(s)'}
              onFocusFunc={() => {}}
              // disabled={
              //   !campaignLinkedBrands || campaignLinkedBrands?.length <= 0
              // }
              loadMoreAction={'storeModel/getPagedStoreList'}
              // defaultFilter={{
              //   brands: campaignLinkedBrands?.map((item) => item.pk).join(','),
              // }}
              // addButton={{
              //   action: () => setShowAddBrand(true),
              //   title: 'Create Now',
              //   requires: PermissionCodes.addBrand,
              //   customClass: 'general-section-add-new-brand btn-add-button-common',
              // }}
            />
          ) : null} */}

          <BasePrompt
            show={showAddBrand}
            closeAction={() => setShowAddBrand(false)}
            rightButton={{
              text: 'create brand',
              action: () =>
                history.push({
                  pathname: '/brands/create',
                  state: { from: history.location, ...ContinueCreate },
                }),
            }}
            title={'Go to create brand?'}
            description={`You will leave the campaign creation process. After you create a new brand, you can back to this page.`}
          />
        </>
      ) : (
        <>
          <div className="brand-select-dropdown-container">
            <CustomTitleLabel
              title={i18n.t('related_brand_optional', { locale: language })}
            />
            <label>
              {campaign.linkedBrands?.map((val) => val.name).join(', ') || '-'}
            </label>
          </div>
          {/* <div className="brand-select-dropdown-container">
            <CustomTitleLabel
              title={i18n.t('applicable_stores', { locale: language })}
            />
            <label>
              {campaign.linkedStores?.map((val) => val.name).join(', ') || '-'}
            </label>
          </div> */}
        </>
      )}
      <CustomTagSection
        tagTitle={i18n.t('input_tag', { locale: language })}
        tagDescription={i18n.t('input_tag_description', { locale: language })}
        stateTag={campaignStateTag}
        setStateTag={setCampaignStateTag}
        tagSecondTitle={i18n.t('campaign_tag', { locale: language })}
        errorId={`${CampaignErrorHandleField.campaignTag.name}-${language}`}
        isError={isError(CampaignErrorHandleField.campaignTag.name)}
        errorMessage={CampaignErrorHandleField.campaignTag.message}
      />
    </>
  );
}

export default GeneralSection;
