import React, { useState } from 'react';
import BaseActionModal from '../coupon/couponList/BaseActionModalView';
import { capitalizeFirstLetter } from '../../utils';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { NewBarcodeRejectReasons } from '../../config/CustomEnums';

const EditNewBarcodeStepOnePrompt = ({
  event,
  show,
  onCancel,
  onConfirm,
  rejectReason = { value: null, label: null },
  setRejectReason = (() => { }),
}) => {
  const [reasons, setReasons] = useState(NewBarcodeRejectReasons);

  const clearRejectReason = () => {
    setRejectReason({ value: null, label: null });
  };

  const handleOnHide = () => {
    onCancel();
    setReasons(NewBarcodeRejectReasons);
    clearRejectReason();
  };

  const localFilterAction = (search = '') => {
    setReasons(NewBarcodeRejectReasons.filter(item => item.name.toLowerCase().includes(search.toLowerCase())));
  };

  const getActionPromptDetailItems = () => {
    return (
      <div>
        {event === "reject with message"
          ? <CustomTitleWithDropDown
            title={"Reason to reject"}
            defaultValue={rejectReason}
            setValue={setRejectReason}
            source={reasons}
            localFilterAction={localFilterAction}
            showRemoveButton={true}
            onRemoveButtonClick={clearRejectReason}
            extraTitleClass='reject-reason-dropdown'
          />
          : null
        }
      </div>
    );
  };

  return (
    <BaseActionModal
      modalClassName={'barcode-validate-modal'}
      showModal={show}
      onHide={handleOnHide}
      modalTitle={"Confirm to proceed?"}
      modalGeneralContent={"This operation cannot be undone."}
      modalDetailContent={getActionPromptDetailItems()}
      actionName={"Confirm"}
      action={onConfirm}
    />
  );
}

export default EditNewBarcodeStepOnePrompt;
