import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image, Dropdown } from 'react-bootstrap';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import { createAction } from '../../utils';
import { useLocation } from 'react-router-dom';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import ImageUploader from '../base/ImageUploader';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomContentFilterDropDown from '../base/CustomContentFilterDropDown';
import { CreateTransactionError } from '../../models/CreateTransactionModel';

export function UploadImageCom({
  defaultValue,
  errorFields,
  title,
  fieldName = 'Recycling photo',
  field = 'recylingImage',
  hint = 'Suggested Image Size: 2700 px * 1800 px At least : 900 px * 600 px(Less than 2MB, support JPG, PNG and GIF only)',
  minWidth = 900,
  minHeight = 600,
}) {
  const dispatch = useDispatch();

  const [image, setImage] = useState((defaultValue || {})[field]);
  return (
    <>
      {title ? <label className="create-section-title">{title}</label> : null}

      <div style={{ display: 'grid' }}>
        <CustomTitleLabel title={fieldName} />
        <label className="create-message-suggest">{hint}</label>
        <ImageUploader
          secert={true}
          minWidth={minWidth}
          minHeight={minHeight}
          images={image ? [image] : []}
          onImageStateChange={(newState) => {
            setImage(newState[0]);
            dispatch({
              type: 'createTransaction/updateTransactionState',
              payload: { [field]: newState?.[0] },
            });
          }}
          uploadImageClicked={() => { }}
          aspect={4 / 3}
          maxImageNum={1}
          language={'en'}
          errorMessage={
            errorFields.includes(field)
              ? CreateTransactionError[field].message
              : ''
          }
        />
      </div>
    </>
  );
}

function PhotoTakingItemCom({ defaultValue, errorFields }) {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(defaultValue?.amount);
  useEffect(() => {
    setAmount(defaultValue?.amount);
  }, [defaultValue]);

  const getErrorMessage = () => {
    if (errorFields.includes('recylingItemAmount' + defaultValue?.pk)) {
      return CreateTransactionError['recylingItemAmount'].message;
    }

    if (errorFields.includes('recylingItemAmountMore' + defaultValue?.pk)) {
      return CreateTransactionError['recylingItemAmountMore'].message;
    }
  };

  return (
    <>
      <CustomTitleWithInput
        title={'Amount'}
        type="number"
        defaultValue={amount}
        setValue={(value) => {
          setAmount(value);
          dispatch({
            type: 'createTransaction/changeRecylingRecords',
            payload: {
              amount: value,
              item: defaultValue,
            },
          });
        }}
        error={{
          id: defaultValue?.pk,
          error:
            errorFields.includes('recylingItemAmount' + defaultValue?.pk) ||
            errorFields.includes('recylingItemAmountMore' + defaultValue?.pk),
          message: getErrorMessage(),
        }}
      />
    </>
  );
}

export function PhotoTakingSetItemsCom({ defaultValue, store, errorFields, barcode }) {
  const dispatch = useDispatch();

  const [content, setContent] = useState([]);
  const { selectedStoreCampaign } = useSelector((state) => ({
    selectedStoreCampaign: state.campaignList.allCampaignList
      ? state.campaignList.allCampaignList[0]
      : null,
  }));

  const getDropDownData = () => {
    let data = {};

    for (let i = 0; i < store?.subcategories?.edges.length; i++) {
      const node = store?.subcategories?.edges[i].node;
      const category = node.category;
      if (barcode && barcode.productType.pk != node.pk) {
        continue;
      }
      if (
        [2, 3].includes(category.pk) &&
        content.filter((item) => item.data.pk === node.pk).length <= 0
      ) {
        if (Object.keys(data || []).includes(category.name)) {
          data[category.name] = [...data[category.name], node];
        } else {
          data[category.name] = [node];
        }
      }
    }

    return data;
  };

  useEffect(() => {
    const recylingItems = defaultValue.recylingItems || {};

    const initContent = [];
    for (let i = 0; i < Object.keys(recylingItems).length; i++) {
      const itemKey = Object.keys(recylingItems)[i];
      if (recylingItems[itemKey].name) {
        initContent.push({
          title: recylingItems[itemKey].name,
          data: recylingItems[itemKey],
        });
      }
    }

    setContent(initContent);
  }, []);

  return (
    <>
      <label className="create-section-title">Recycling items</label>
      {content.map((item, index) => {
        return (
          <>
            <label className="create-section-title-without-upper">
              Item {index + 1}: {item.title}
            </label>
            <div
              className={`reward-content-area ${errorFields?.includes('rewards') ? 'error-field' : ''
                }`}
            >
              <div className="display-flex-area">
                <div className="reward-content">
                  <PhotoTakingItemCom
                    defaultValue={
                      defaultValue.recylingItems &&
                        defaultValue.recylingItems[item.data.pk]
                        ? defaultValue.recylingItems[item.data.pk]
                        : item.data
                    }
                    errorFields={errorFields}
                  />
                </div>
                <button
                  className="reset-button content-delete-button-area"
                  onClick={() => {
                    const afterRemoveContent = content.filter(
                      (val) => val.title !== item.title,
                    );
                    setContent(afterRemoveContent);

                    dispatch({
                      type: 'createTransaction/changeRecylingRecords',
                      payload: {
                        item: item.data,
                        deleted: true,
                      },
                    });
                  }}
                >
                  <Image src={DeleteIcon} className="content-delete-button" />
                </button>
              </div>
            </div>
          </>
        );
      })}

      <ErrorFieldMessage
        id={'recylingItemsLess'}
        error={errorFields?.includes('recylingItemsLess')}
        message={CreateTransactionError['recylingItemsLess'].message}
      />
      <ErrorFieldMessage
        id={'recylingItemsMore'}
        error={errorFields?.includes('recylingItemsMore')}
        message={CreateTransactionError['recylingItemsMore'].message}
      />

      <ErrorFieldMessage
        id={'recylingItemsLess'}
        error={errorFields?.includes('recylingTotalAmountMiss')}
        message={CreateTransactionError['recylingTotalAmountMiss'].message}
      />
      <ErrorFieldMessage
        id={'recylingItemsMore'}
        error={errorFields?.includes('recylingTotalAmountLimit')}
        message={CreateTransactionError[
          'recylingTotalAmountLimit'
        ].message.replace(
          'xxx',
          selectedStoreCampaign?.earningRule
            ?.photoTakingTypePerHeadPerDayTotalQuantityLimit,
        )}
      />

      <CustomContentFilterDropDown
        title="Add items"
        data={getDropDownData()}
        content={content}
        addContentAction={(section) => {
          setContent([
            ...content,
            {
              title: section.name,
              data: section,
            },
          ]);
        }}
      />
    </>
  );
}

function PhotoTakingContent(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  // const defaultValue = props.defaultValue;

  const { transaction, errorFields } = useSelector((state) => ({
    transaction: state.createTransaction.transaction,
    errorFields: state.createTransaction.errorFields,
  }));

  const getSections = () => {
    return [
      <UploadImageCom defaultValue={transaction} errorFields={errorFields} />,
      <PhotoTakingSetItemsCom
        defaultValue={transaction}
        store={transaction.store}
        errorFields={errorFields}
      />,
    ];
  };

  return (
    <>
      <ContentSections sections={getSections()} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        backAction={() =>
          dispatch(
            createAction('createTransaction/stepChange')({
              isBack: true,
              step: 2,
            }),
          )
        }
        saveText={'Save'}
        saveAction={() => {
          dispatch(
            createAction('createTransaction/checkStepThree')({
              isBack: false,
              afterActions: () => {
                if (location.pathname.includes('edit')) {
                  dispatch(
                    createAction('createTransaction/updateTransaction')(),
                  );
                } else {
                  dispatch(
                    createAction('createTransaction/createTransaction')(),
                  );
                }
              },
            }),
          );
        }}
      />
    </>
  );
}

export default PhotoTakingContent;
