import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import './AfcDataRecordList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import DeletePrompt from '../../../components/base/DeletePrompt';


function AfcDataRecordList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    tableFields,
    dataList,
    pageInfo,
    totalCount,
    totalPage,
    currentPage,
  } = useSelector((state) => ({
    tableFields: state.afcDataRecords.listDisplayFields,
    dataList: state.afcDataRecords.afcDataRecords,
    pageInfo: state.afcDataRecords.pageInfo,
    totalCount: state.afcDataRecords.totalCount,
    totalPage: state.afcDataRecords.totalPage,
    currentPage: state.afcDataRecords.currentPage,
  }));

  const [deleteItem, setDeleteItem] = useState(null)
  const [showDeletePrompt, setShowDeletePrompt] = useState(false)

  const fetchData = () => {
    const searchDict = queryString.parse(location.search);
    const stringRank = searchDict['rank'] || 'true';
    const rank = stringRank === 'true';

    const searchKey = searchDict['search'];
    const stringPage = searchDict['page'] || 1;
    const page = parseInt(stringPage);

    dispatch(
      createAction('afcDataRecords/getAfcDataRecords')({
        ...searchDict,
        // page: currentPage,
        searchKey,
        rank
      }),
    );
  };

  useEffect(() => {
    fetchData()
  }, [dispatch, location, queryString]);

  const buttons = [
    <AuthButton
      title={'Create AFC Data Record'}
      className="btn-further"
      action={() => {
        // dispatch({ type: 'earningRuleList/clearData' });
        history.push({
          pathname: '/afc_data_record/create/',
        });
      }}
      requires={PermissionCodes.viewTransaction}
    ></AuthButton>,
  ];


  const tableTabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          groupActions={[]}
          filter={{ hasFilter: true, componment: Filter }}

          listContent={
            <BaseListContainer
              fields={tableFields}
              model={'afcDataRecord'}
              permissionGroup={PermissionCodes.transaction}
              dataList={dataList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}

              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'View details',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                  },
                  {
                    name: 'Edit',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/edit`);
                    },
                  },
                  {
                    name: 'Delete',
                    action: (item) => {
                      setDeleteItem(item)
                      setShowDeletePrompt(true)
                    }
                  }
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
              customClassName="afc-data-record-table"
            />
          }
        />
      ),
    },
  ];

  return (
    <>
    <CustomListComponent
      caution={{
        detail:
          'All AFC data records are listed here, you may update them before they are collected by customer.',
        title: 'AFC Data records',
      }}
      buttons={buttons}
      breadcrumb={<CustomBreadcrumb />}
      tabs={tableTabs}
      hideTab={true}
    />

    <DeletePrompt
        data={deleteItem}
        title="AFC Data Record"
        relatedSections={DELETE_RELATED_SECTIONS.AFC_DATA_RECORD}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('afcDataRecords/deleteAfcDataRecord')({
              id: deleteItem.id,
              pk: deleteItem.pk,
              afterAction: fetchData,
            }),
          );
          setDeleteItem(null);
        }}
      />
      </>
  );
}

export default AfcDataRecordList;
