import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import MessageOverviewTableSection from '../../../components/message/MessageOverviewTableSection';
import InformationBarOverviewTableSection from '../../../components/message/InformationBarOverviewTableSection'
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { APIStatus, MessageTag } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';

function MessageOverview() {
  const queryString = require('query-string');
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    selectedMessage,
    defaultInformationBar,
    status
  } = useSelector((state) => ({
    selectedMessage: state.messageList.message,
    defaultInformationBar: state.messageList.message.informationBar,
    status: state.createMessage.createStatus,
  }));

  useEffect(() => {
    dispatch(
      createAction('messageList/getMessage')({
        messagePK: params.id,
      }),
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('createMessage/clearData')());
      dispatch(createAction('messageList/clearData')());
    };


  }, [dispatch]);

  const [defaultFields, setDefaultFields] = useState([])

  useEffect(() => {
    const baseFields = [
      [{ title: 'Message channel', field: 'displayChannels' }],
      [{ title: 'Message type', field: 'displayMessageType' }],
      [
        {
          title: 'Name',
          field: 'name',
        }
      ],
      [
        {
          title: 'Target customers',
          field: 'displayTargetCustomer',
        },
      ],
      [
        {
          title: 'Target segments',
          field: 'displayTargetedSegments',
        },
      ],
      [
        {
          title: 'Start Time',
          field: 'displayScheduledStartDate'
        }
      ],
    ]
    if (selectedMessage.channels?.includes('Information Bar')) {
      const newFields = [
        ...baseFields,

        [
          {
            title: 'End Time',
            field: 'displayScheduledEndDate'
          }
        ],
      ]
      setDefaultFields(newFields)
    } else {
      const newFields = [
        ...baseFields,
        [
          {
            title: 'Delivery Time',
            field: 'deliveryDate'
          }
        ],
      ]
      setDefaultFields(newFields)
    }
  }, [selectedMessage])

  const sections =
    selectedMessage.channels?.includes('Information Bar') ?
      [
        <MembershipInfoCard
          title={'PROPERTIES'}
          data={selectedMessage}
          fields={defaultFields}
          extraDataFields={defaultInformationBar}
        />,
        <InformationBarOverviewTableSection data={defaultInformationBar} />,
        < MessageOverviewTableSection data={selectedMessage} />,
      ] :
      [
        <MembershipInfoCard
          title={'PROPERTIES'}
          data={selectedMessage}
          fields={defaultFields}
          extraDataFields={defaultInformationBar}
        />,
        < MessageOverviewTableSection data={selectedMessage} />,
      ]

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              sections={sections}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    selectedMessage.status === MessageTag.sent ||
      selectedMessage.isLatestWelcomeMessage === false ? null : (
      <AuthButton
        title="Edit"
        action={() => history.push('edit/')}
        requires={PermissionCodes.changeMessage}
      />
    ),
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: 'View Message',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
      <OnlyBackButton
        backAction={() => {
          dispatch(createAction('messageList/clearMessageState')());
          history.push({
            pathname: '/messages',
            search: queryString.stringify({
              page: 1,
              rank: true,
              search: '',
            }),
          });
        }}
      />
    </>
  );
}

export default MessageOverview;
