import React from 'react';
import CustomRadios from '../base/CustomRadios';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from '../earning/CustomBaseComponments';
import { Image } from 'react-bootstrap';
import addImage from '../../assets/images/addx1.png';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { isShowError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { EarningRuleRewardType } from '../../config/CustomEnums';
import { PermissionCodes } from '../../config/PermissionCodes';

const radioOptions = [
  { label: 'Coupons', value: EarningRuleRewardType.coupons },
  { label: 'Points', value: EarningRuleRewardType.points },
];

const optionalSet = [
  { label: 'None', value: null },
  { label: 'Coupons', value: EarningRuleRewardType.coupons },
  { label: 'Points', value: EarningRuleRewardType.points },
];

function RewardSectionCommon(props) {
  const initialValue = props.initialValue || {};
  const { rewardType, couponTemplate } = initialValue;
  const points = initialValue.points || '';
  const couponQuantity = initialValue.couponQuantity || '';
  const carbon = initialValue.carbon || '';
  const hideTitle = props.hideTitle || false;

  const typeChange = props.typeChange || (() => {});
  const carbonChange = props.carbonChange || (() => {});
  const couponChange = props.couponChange || (() => {});
  const pointsChange = props.pointsChange || (() => {});
  const couponQuantityChange = props.couponQuantityChange || (() => {});

  const errorFields = props.errorFields || [];
  const handleFields = props.handleFields || {};
  const customizeTitle = props.customizeTitle || 'Reward';

  const isError = (name) => {
    const names = Array.isArray(name) ? name : [name];
    const errors = [];
    names.forEach((field) => {
      const error = isShowError(handleFields[field]?.name, errorFields);
      if (error) {
        errors.push(error);
      }
    });

    return errors[0];
  };

  const rewardQuality =
    rewardType === EarningRuleRewardType.points
      ? 'points'
      : 'coupons per number';

  return (
    <>
      {hideTitle ? null : (
        <label className="create-section-title">{customizeTitle}</label>
      )}
      <CustomTitleLabel title="Reward type" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={typeChange}
          default={rewardType}
          options={radioOptions}
        />
      </div>

      {rewardType === EarningRuleRewardType.coupons ? (
        <>
          <CouponSelectDropdown
            title={'Coupon set'}
            defaultValue={{
              value: couponTemplate,
              label: couponTemplate?.name,
            }}
            setValue={couponChange}
            addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            isPrompt
            errors={errorFields}
            errorName={handleFields?.['coupons']?.name}
            addButtonInfo={{
              requires: PermissionCodes.addCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
          />
        </>
      ) : null}
      <CustomTitleLabel title="Quantity" />
      <div style={{ display: 'flex' }}>
        <input
          type="number"
          onChange={({ target }) => {
            if (rewardType === EarningRuleRewardType.coupons) {
              couponQuantityChange(target.value);
              return;
            }
            pointsChange(target.value);
          }}
          className="custom-number-input-short"
          value={
            rewardType === EarningRuleRewardType.coupons
              ? couponQuantity
              : points
          }
          onFocus={() => {}}
        />
        <label className="help-message margin-left-10-percent">
          {rewardQuality}
        </label>
      </div>
      <ErrorFieldMessage
        id={`${handleFields?.['quantity']?.name}`}
        error={isError('quantity')}
        message={`${handleFields?.['quantity']?.message}`}
      />
      <CustomNumberInputWithUnit
        title={'Carbon'}
        unit={'g'}
        defaultValue={carbon}
        setValue={carbonChange}
      />
    </>
  );
}

export default RewardSectionCommon;
