
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { Image, Table } from 'react-bootstrap';
import {
    LanguageConfig,
    LanguageTabTransLation,
} from '../../config/CustomEnums';

export const InfomationTypes = {
    "object": "object",
    "image": "image",
}

const showdown = require('showdown');
const converter = new showdown.Converter();

function Title({ title }) {
    return <label className="create-section-title">{title}</label>
}

function NonTranslationItem({ name, value }) {
    const itemValue = typeof (value) === "object" ? value?.name : value
    return (
        <div className='d-flex flex-column' style={{ width: '278px' }}>
            <label className="create-section-label create-section-label-bottom-space">
                {name}
            </label>
            <label className="create-section-field-input profile-section-label-bottom">
                {itemValue || '-'}
            </label>
        </div>
    )
}

function NonTranslationRow({ row = [], data }) {
    return (
        <div className='d-flex flex-row'>
            {row.map(item => {
                let value = data[item.property]
                if(item.union && data[item.propertySuffix] && data[item.property]) {
                    value = data[item.propertySuffix] + item.union + data[item.property] + item.unionSuffix
                }
                return <NonTranslationItem name={item.name} value={value} />
            })}
        </div>
    )
}

// mapping : [{ title: "", items: [{ name: "", property: "" }] }]
// TODO: add type in mapping [{ title: "", items: [{ name: "", property: "", type: "" }] }]
export function SectionWithNonTranslation({ data, mapping = [] }) {
    return (
        <>
            {mapping.map(item => {
                return <>
                    <Title title={item.title} />
                    <NonTranslationRow row={item.items} data={data} />
                    <div style={{marginBottom: "50px"}}/>
                </>
            })
            }
        </>
    );
}

function ColumnName({ name }) {
    return <td className="create-section-label content-section-field-clumn">
        {name}
    </td>
}



function TranslationTitle() {
    return <tr>
        <ColumnName />
        {[LanguageTabTransLation.english, LanguageTabTransLation.traditionalChinese].map(language => <th className="create-section-label content-section-value-clumn">
            {language}
        </th>)}
    </tr>
}

function RowItem({ data, mapItem }) {
    const translations = data.translations || {}
    const enValue = translations[LanguageConfig.english]?.[mapItem.property] || data[mapItem.property]
    const chValue = translations[LanguageConfig.traditionalChinese]?.[mapItem.property]
    const itemValue = typeof (enValue) === "object" ? enValue?.name : enValue
    return <tr>
        <ColumnName name={mapItem.name} />
        {[itemValue, chValue].map((value, index) => <RowValue value={value} index={index} type={mapItem.type} />
        )}
    </tr>

}

const RowValue = ({ value, index, type }) => {
    if (value && type === InfomationTypes.image) {
        return (<td
            key={`fieldValueText-${value}-${index}`}
            className="create-section-field-input"
        >
            <img
                className="content-section-rectangle-image"
                src={value}
            />
        </td>)

    }
    return (
        <td
            key={`fieldValueText-${value}-${index}`}
            className="create-section-field-input"
            dangerouslySetInnerHTML={{
                __html: value ? converter.makeHtml(value) : '-',
            }}
        ></td>
    );
};


// mapping : [{name: "", property: ""}]
// TODO: add type in mapping [{name: "", property: "", type: ""}]
export function SectionWithTranslation({ title, data, mapping = [] }) {
    const items = mapping.map(item => <RowItem data={data} mapItem={item} />)
    return <>
        <Title title={title} />
        <PerfectScrollbar>
            <Table bordered className="content-section-table-container">
                <thead>
                    <TranslationTitle />
                </thead>
                <tbody>
                    {items}
                </tbody>
            </Table>
        </PerfectScrollbar>
    </>
}