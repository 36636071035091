import { APIStatus, CheckStatus, SavedStatus } from '../config/CustomEnums';
import {
  getAfcSettings, updateAfcSettings,
} from '../services/AfcSettingsApiHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  delay,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';

import { getCampignPeriodDateTime } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';

const getInitialState = () => ({
  oneAdminGroup: {},
  
  afcSettings: {},
  apiStatus: APIStatus.none,

  errorFields: {},
  saved: SavedStatus.init,
});


const parseAfcDataRecord = (item) => {
  return {
    ...item,
    creationDate: getCampignPeriodDateTime(item.creationDate),
    membershipId: item.customer ? item.customer.membershipId : '',
    firstName: item.customer ? item.customer.firstName : '',
    lastName: item.customer ? item.customer.lastName : ''
  };
};


const parseAfcDataRecords = (data) => {
  return data.map((item) => parseAfcDataRecord(item.node));
};

export default {
  namespace: 'afcSettings',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getAfcSettings: [
        function* ({ payload }, { call, select, put, all }) {
            yield put({
              type: 'updateState',
              payload: { apiStatus: APIStatus.calling },
            });
            const id = convertPKToId('AfcSettingsRecordNode', "1");

            const serviceArgs = [getAfcSettings, id];
            console.log('@119', payload)
            function* onSuccess(data) {
              console.log('getAfcSettings onSuccess :', data);
              yield all([
                put({
                  type: 'updateState',
                  payload: { apiStatus: APIStatus.none, afcSettings: data.afcSettingsRecord },
                }),
              ]);
            }
            function* onError(err) {
              console.log('getAfcSettings onError :', err);
              yield put({
                type: 'updateState',
                payload: { apiStatus: APIStatus.failed },
              });
            }
            function* onArgumentsError(err) {
              console.log('getAfcSettings arguments error :', err);
              yield put({
                type: 'updateState',
                payload: { apiStatus: APIStatus.failed },
              });
            }
            yield apiWithResponseHandle(
              serviceArgs,
              onSuccess,
              onError,
              onArgumentsError,
            );
        },
        { type: 'takeLatest' },
    ],

    updateAfcSettings: [
        function* ({ payload }, { put, select }) {
            // const stationFrom = yield select((state) => payload.stationFrom);
            // const stationTo = yield select((state) => payload.stationTo);
            yield put({
              type: 'updateState',
              payload: {
                apiStatus: APIStatus.loading,
              },
            });
    
            console.log('@166', payload)
            let params = {...payload}
            params.id = "1";

    
            const serviceArgs = [updateAfcSettings, params];

            function* onSuccess(data) {
              // removeFromSessionStorage(appVersionSessionKey);
              yield put({
                type: 'updateState',
                payload: {
                  apiStatus: APIStatus.success,
                  formChanged: false,
                },
              });
            }
            yield loading(serviceArgs, onSuccess);
        },
        
        { type: 'takeLatest' },
    ],

  },
};

