import React, { useEffect, useState } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateMessageStepOne from '../../../components/message/CreateMessageStepOne';
import CreateMessageStepTwo from '../../../components/message/CreateMessageStepTwo';
import CreateMessageStepThree from '../../../components/message/CreateMessageStepThree';
import CreateMessageStepFour from '../../../components/message/CreateMessageStepFour';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { useParams, useHistory } from 'react-router-dom';
import { sessionDataKey } from '../../../models/CreateMessageModel';
import queryString from 'query-string';
import {
  getCmapaignCreateSucessStepBar,
  NOTIFY_YOUR_CUSTOMER,
} from '../../../components/base/prompt/NextStepConfig';
import { URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import { CampaignType } from '../../../config/CustomEnums';
import { TAB_LIST_OF_COUPON_SETS } from '../coupon/CouponList';
import { LanguageConfig } from '../../../config/CustomEnums';
import { InformationBarMessageType, MessageType } from '../../../components/message/CreateMessageConfig';
import CreateInformationBarMessageStepTwo from '../../../components/message/CreateInformationBarMessageStepTwo';
import CreateInformationBarOtherMessageStepTwo from '../../../components/message/CreateInformationBarOtherMessageStepTwo'
import CreateInformationBarMessageStepThree from '../../../components/message/CreateInformationBarMessageStepThree'
import CreateInformationBarOtherMessageStepFour from '../../../components/message/CreateInformationBarOtherMessageStepFour'
import CreateInformationBarMaintenanceMessageStepFour from '../../../components/message/CreateInformationBarMaintenanceMessageStepFour'

function CreateMessage() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const {
    stepConfig,
    currentStep,
    scheduledMessage,
    messagePK,
    campaign,
  } = useSelector((state) => ({
    stepConfig: state.createMessage.stepConfig,
    currentStep: state.createMessage.currentStep,
    scheduledMessage: state.createMessage.scheduledMessage,
    messagePK: state.createMessage.message.pk,
    campaign: state.createCampaign.campaign,
  }));

  const { message } = useSelector((state) => ({
    message: state.createMessage.message,
  }));

  const getStepContent = () => {
    if (message?.messageType === MessageType.welcomeMessage) {
      return [
        <CreateMessageStepOne />,
        <CreateMessageStepTwo />,
        <CreateMessageStepFour />
      ]
    } else if (message?.messageType === InformationBarMessageType.maintenance) {
      return [
        <CreateMessageStepOne />,
        <CreateInformationBarMessageStepTwo />,
        <CreateInformationBarMessageStepThree />,
        <CreateInformationBarMaintenanceMessageStepFour />
      ]
    } else if (message?.messageType === InformationBarMessageType.others) {
      return [
        <CreateMessageStepOne />,
        <CreateInformationBarOtherMessageStepTwo />,
        <CreateInformationBarMessageStepThree />,
        <CreateInformationBarOtherMessageStepFour />
      ]
    } else {
      return [
        <CreateMessageStepOne />,
        <CreateMessageStepTwo />,
        <CreateMessageStepThree />,
        <CreateMessageStepFour />,
      ]
    }
  };

  const [stepContent, setStepContent] = useState(getStepContent());

  const search = queryString.parse(history.location.search);
  useEffect(() => {
    if (search) {
      const campaignId = search.campaign;
      dispatch(
        createAction('createCampaign/getOneCampaign')({ id: campaignId }),
      );
    }
    if (params.id) {
      dispatch(
        createAction('messageList/getMessage')({
          messagePK: params.id,
          afterAction: () => {
            dispatch(createAction('createMessage/stepChange')({ step: 0 }));
          },
        }),
      );
    } else {
      dispatch(createAction('createMessage/loadMessageFromCookie')());
    }

    dispatch(
      createAction('messageList/getCurrentPageMessages')({
        message_type: MessageType.welcomeMessage,
      }),
    );

    return () => {
      dispatch(createAction('createMessage/clearData')());
      dispatch(createAction('campaignList/clearData')());
      dispatch(
        createAction('createCampaign/clearData')({
          deleteSession: search.campaign,
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search && campaign?.id) {
      const relatedCampaign = campaign.translations[LanguageConfig.english];
      relatedCampaign.id = campaign.id;
      relatedCampaign.pk = campaign.pk;
      relatedCampaign.name = relatedCampaign.generalName;
      dispatch(
        createAction('createMessage/updateMessageState')({
          relatedCampaign: relatedCampaign,
          relatedCampaignName: relatedCampaign.name,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    setStepContent(getStepContent());
  }, [message.messageType]);

  const getButtonsWithType = () => {
    const backGoMessageList = {
      text: 'Go to message section',
      action: () => history.push('/messages'),
      requires: '',
    };
    const buttons = [backGoMessageList];
    if (!search?.type && !search?.campaign) {
      buttons.push({
        text: 'View details',
        action: () => history.push(`/messages/${messagePK}/`),
      });
      return buttons;
    }
    let text = 'Back to campaign listing page';
    let locationParm = {
      pathname: '/campaigns',
      hash: URLFragments.list,
    };

    if (search?.type && search.id) {
      switch (search.type) {
        case CampaignType.couponsCampaign:
          text = 'Back to coupon set listing page';
          locationParm = {
            pathname: '/coupons',
            hash: TAB_LIST_OF_COUPON_SETS,
          };
          break;
        case CampaignType.earningRulesCampaign:
          text = 'Back to earning rule listing page';
          locationParm = {
            pathname: '/earns',
          };
          break;
        default:
          break;
      }
    }
    buttons.unshift({ text: text, action: () => history.push(locationParm) });
    return buttons;
  };

  return (
    <ScrollableComponent
      tempSave={(save) => {
        dispatch({
          type: 'createMessage/saveOrRemoveMessageFromCookie',
          payload: save,
        });
      }}
      showFinishPop={scheduledMessage}
      nextStepConfig={{
        title: 'Message Created!',
        description:
          'Campaign and message are ready. Come back later to check the campaign result.',
        steps: {
          stepNames:
            !search?.type || campaign?.pk + '' !== search.campaign + ''
              ? null
              : getCmapaignCreateSucessStepBar(search.type, search?.id),
          current: NOTIFY_YOUR_CUSTOMER,
        },
        buttons: getButtonsWithType(),
      }}
      sessionDataKey={sessionDataKey}
      content={stepContent}
      currentStep={currentStep}
      stepsConfig={stepConfig}
      breadcrumb={
        <CustomBreadcrumb
          name={
            params.id
              ? message.name && message.name !== ''
                ? message.name
                : 'Edit Message'
              : null
          }
        />
      }
      caution={{
        detail: '', //Create a message to get in touch with  your customers. You can send a message through different channels.
        title: `${params.id
          ? message.name && message.name !== ''
            ? message.name
            : 'Edit Message'
          : 'Create Message'
          }`,
      }}
    />
  );
}

export default CreateMessage;
