import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ScrollableComponent.scss';
import CustomSteps from './CustomSteps';
import CustomTips from './CustomTips';
import { isLongScreen } from '../../utils/ScreenUtil';
import BasePrompt from '../base/prompt/BasePrompt';
import Loading from './Loading';
import { ToastType } from '../../models/NavBarModel';
import { useHistory } from 'react-router-dom';
import { getObjectFromSessionStorage, saveToSessionStorage } from '../../utils';
import NextStepPrompt from '../../components/base/prompt/NextStepPrompt';
import ContinueCreatePrompt from '../../components/base/ContinueCreatePrompt';

function ScrollableComponent(props) {
  const {
    breadcrumb,
    caution,
    content,
    bottom,
    stepsConfig,
    currentStep,
    continueCreateConfig = {},
    nextStepConfig = {},
    showFinishPop,
  } = props;
  const tempSave = props.tempSave || (() => {});
  const sessionDataKey = props.sessionDataKey || {};
  const [nextLocation, setNextLocation] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [locationBlock, setLocationBlock] = useState({ unblock: () => {} });
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasFormChanged } = useSelector((state) => {
    return {
      hasFormChanged: caution.model
        ? state[caution.model]['formChanged']
        : false,
    };
  });
  const formChangedRef = useRef(hasFormChanged);
  const componentRef = useRef();

  const getCaution = () => {
    return (
      <div className="caution-area">
        <CustomTips detail={caution.detail}>
          <label className="caution-text">{caution.title}</label>
        </CustomTips>
      </div>
    );
  };

  const getFunctionNameFromAction = (action) => {
    switch (action) {
      case 'POP':
        return 'goBack';
      case 'PUSH':
        return 'push';
      case 'REPLACE':
        return 'replace';
      default:
        return 'push';
    }
  };

  const navigateToNextLocation = () => {
    const action = nextLocation?.action;
    const location = nextLocation?.nextLocation || { pathname: '/' };
    const functionName = getFunctionNameFromAction(action);
    locationBlock.unblock();
    if (functionName === 'goBack') {
      setLocationBlock({ unblock: () => {} });
      history.goBack();
    } else {
      setLocationBlock({ unblock: () => {} });
      history[functionName](location);
    }
  };

  const onConfirm = () => {
    setShowPopup(false);
    locationBlock.unblock();
    navigateToNextLocation();
  };

  const onCancel = () => {
    setShowPopup(false);
  };

  const block = (nextLocation, action) => {
    console.log("@@94", componentRef.current)
    if (!componentRef.current) {
      return true;
    }
    let result = hasFormChanged;
    if (!caution.model) {
      const sessionObject = getObjectFromSessionStorage(
        sessionDataKey.objectKey,
      );
      const sessionOrigional = getObjectFromSessionStorage(
        sessionDataKey.origionalData,
      );
      const origional = JSON.stringify(sessionOrigional).replace(/null/g, '');
      const object = JSON.stringify(sessionObject).replace(/null/g, '');
      const stepEnd = getObjectFromSessionStorage(sessionDataKey.stepEndKey);
      result = origional !== object && !stepEnd;
      console.log('@@104: ', origional !== object, !stepEnd);
    }

    if (!result && history.location.pathname.indexOf('edit') !== -1) {
      tempSave(false);
    }

    setShowPopup(result);
    setNextLocation({ nextLocation, action });
    return !result;
  };

  useEffect(() => {
    formChangedRef.current = hasFormChanged;
    setLocationBlock({ unblock: history.block(block) });
    saveToSessionStorage(sessionDataKey.stepEndKey, false);
    saveToSessionStorage(sessionDataKey.origionalData, null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFormChanged]);

  useEffect(() => {
    return () => {
      locationBlock.unblock();
      componentRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const refreshOrClose = () => {
    let result = formChangedRef.current;
    if (!caution.model) {
      const sessionObject = getObjectFromSessionStorage(
        sessionDataKey.objectKey,
      );
      const sessionOrigional = getObjectFromSessionStorage(
        sessionDataKey.origionalData,
      );
      const origional = JSON.stringify(sessionOrigional).replace(/null/g, '');
      const object = JSON.stringify(sessionObject).replace(/null/g, '');
      const stepEnd = getObjectFromSessionStorage(sessionDataKey.stepEndKey);
      result = origional !== object && !stepEnd;
    }
    if (result) {
      return `You have unsaved changes!`;
    }
    return;
  };
  useEffect(() => {
    window.onbeforeunload = refreshOrClose;
    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unSavePrompt = () => {
    return (
      <BasePrompt
        show={showPopup}
        closeAction={onCancel}
        rightButton={{
          text: 'Save',
          action: () => {
            tempSave(true);
            setOnSaving(true);
            setTimeout(() => {
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.save,
                  },
                },
              });
              onConfirm();
            }, 1500);
          },
        }}
        leftButton={{
          text: 'Discard changes',
          action: () => {
            tempSave(false);
            dispatch({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: ToastType.discard,
                },
              },
            });
            onConfirm();
          },
        }}
        title={'You have unsaved changes'}
        description={'Save the changes before leaving?'}
      />
    );
  };

  const finishCreateFlowPop = () => {
    return (
      <NextStepPrompt
        show={
          showFinishPop &&
          !history.location?.state?.from &&
          !history.location.pathname.includes('edit')
        }
        {...nextStepConfig}
      />
    );
  };

  const continueCreatePop = () => {
    return (
      <ContinueCreatePrompt
        show={
          showFinishPop &&
          history.location?.state?.from &&
          !history.location.pathname.includes('edit')
        }
        {...continueCreateConfig}
      />
    );
  };

  return (
    <>
      <div className="container-common scroll-container-common-header" ref={componentRef}>
        <div
          className={`${
            isLongScreen
              ? 'scroll-container-long-screen'
              : 'scroll-container-short-screen'
          }`}
        >
          {breadcrumb}
          {getCaution()}
        </div>
      </div>
      <div
        className={`scroll-container-common ${
          isLongScreen
            ? 'scroll-container-long-screen'
            : 'scroll-container-short-screen'
        }`}
      >
        {stepsConfig ? <CustomSteps stepsConfig={stepsConfig} /> : null}
        {onSaving ? (
          <div className="on-saving-area-active">
            <Loading />
          </div>
        ) : (
          content[currentStep]
        )}
        {bottom}
        {unSavePrompt()}
        {finishCreateFlowPop()}
        {continueCreatePop()}
      </div>
    </>
  );
}

export default ScrollableComponent;
