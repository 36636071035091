import {
    LanguageConfig,
} from '../config/CustomEnums';
export const getTranlationsData = (data) => {
    const translationsEdges = data?.translations?.edges;
    let translations = {};
    if (translationsEdges?.length > 0) {
        translationsEdges.forEach((item) => {
            const node = item.node;
            translations[node.language] = {
                ...node,
            };
        });
    };
    translations[LanguageConfig.english] = {
        language: LanguageConfig.english,
        ...data,
    }
    return translations;
}