import React, { useEffect, useState, useRef } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import { ImportResource } from '../../../models/DownloadImportModel';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';

export default function CustomerGroup() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState();
  const hiddenFileInput = useRef(null);
  const {
    pageInfo,
    totalCount,
    totalPage,
    displayFields,
    groupList,
    checkedList,
    importedFile,
  } = useSelector((state) => ({
    pageInfo: state.customerGroup.pageInfo,
    totalCount: state.customerGroup.totalCount,
    totalPage: state.customerGroup.totalPage,
    displayFields: state.customerGroup.listDisplayFields,
    groupList: state.customerGroup.currentPageGroupList,
    checkedList: state.customerGroup.checkedList,
    importedFile: state.uploadFiles.importedFile,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const search = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('customerGroup/getCurrentPageList')({
        search,
        rank,
        page,
        moreSearch: { ...searchDict },
      }),
    );
  }, [dispatch, location, queryString]);

  useEffect(() => {
    console.log('uploadedFileName:', uploadedFileName);
    const underlineIndex = uploadedFileName?.lastIndexOf('_');
    const dotIndex = uploadedFileName?.lastIndexOf('.');
    let fileNameEndIndex = underlineIndex;
    if (underlineIndex <= 0) {
      fileNameEndIndex = dotIndex;
    }
    const fileName = uploadedFileName?.slice(0, fileNameEndIndex);
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.customerGroup,
          file: importedFile,
          related: fileName,
        }),
      );
    }
  }, [dispatch, importedFile, uploadedFileName]);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
    console.log('@@71');
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadedFileName(fileUploaded.name);
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    event.target.value = null;
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          title="Create Customer Group"
          customClass={'btn-further btn-download'}
          action={() => history.push('/customer_group/create')}
          requires={PermissionCodes.addCustomerGroup}
        />,
        <AuthButton
          title={'Import .csv'}
          customClass="btn-back-button-common"
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addCustomerGroup,
          action: () => history.push('/customer_group/create'),
          content: 'Create Customer Group',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Import .csv',
        },
      ]}
    />,

    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => setShowDeletePrompt(true),
      requires: PermissionCodes.deleteCustomerGroup,
    },
  ];
  const tabs = [
    {
      name: 'List of customer groups',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'customerGroup'}
              permissionGroup={PermissionCodes.customergroup}
              dataList={groupList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: 'customer group',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER_GROUP,
              }}
              actions={['Edit', 'Delete', 'Duplicate']}
            />
          }
        />
      ),
    },
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Create various customer groups and target them with different experiences.',
          title: 'Customer groups',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={false}
        tabs={tabs}
      />
      <DeletePrompt
        title={'customer group'}
        data={checkedList}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER_GROUP}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('customerGroup/delete')({
              afterAction: () => {
                history.push('/customer_group');
              },
            }),
          );
        }}
      />
    </>
  );
}
