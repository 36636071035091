import React from 'react';
import CustomerList from '../containers/customers/customer/CustomerList';
import LevelList from '../containers/customers/level/LevelList';
import TransactionList from '../containers/record/records/TransactionList';
import SegmentList from '../containers/customers/segments/SegmentList';
import CampaignList from '../containers/engagement/campaign/CampaignList';
import CouponList from '../containers/engagement/coupon/CouponList';
import EarningRuleList from '../containers/engagement/earningRule/EarningRuleList';
import MessageList from '../containers/engagement/message/MessageList';
import RewardList from '../containers/engagement/rewards/RewardList';
import BrandList from '../containers/merchants/brands/BrandList';
import StoreList from '../containers/merchants/stores/StoreList';
import Dashboard from '../containers/dashboard/Dashboard';
import { NavBarNames } from './NavBarNameList';
import { PermissionCodes } from './PermissionCodes';
import TranslationList from '../containers/merchants/translations/TranslationList';
import { URLFragments } from '../components/campaign/CampaignUrlConfig';
import { CouponURLQueryKeys } from '../components/coupon/CouponUrlConfig';
import CustomerGroup from '../containers/customers/customerGroup/CustomerGroup';
import CampaignCategory from '../containers/engagement/campaignCategory/CampaignCategory';
import StoreCategory from '../containers/merchants/storeCategory/StoreCategory';
import FeedbackList from '../containers/merchants/storeFeedback/FeedbackList';
import WebviewContent from '../containers/others/WebviewContent';
import CouponTransaction from '../containers/record/couponRecords/CouponTransaction';
import AfcDataRecordList from '../containers/record/afcDataRecords/AfcDataRecordList';
import PointTransactionList from '../containers/record/pointRecords/PointTransactionList';
import AdminList from '../containers/admin/admins/AdminList';
import AdminGroupList from '../containers/admin/adminGroups/AdminGroupList';
import BannerList from '../containers/homeManage/banners/BannerList';
import FeaturedCampaignList from '../containers/homeManage/featuredCampaigns/FeaturedCampaignList';
import CommunityList from '../containers/merchants/community/CommunityList'

import {
  pointRecordQueryKey,
  couponRecordQueryKey,
  transactionQueryKey,
} from '../components/pointRecord/RecordsUrlConfig';
import AppVersionList from '../containers/others/AppVersion/AppVersionList';
import GuideImageList from '../containers/others/Guide/GuideImageList';
import AfcSettingDetail from '../containers/others/afcSettings/AfcSettingDetail'
import AfcCronjobRecordList from '../containers/record/afcCronjobRecords/AfcCronjobRecordList';
import AfcAccountBindingRecordList from '../containers/record/afcAccountBindingRecords/AfcAccountBindingRecordList';
import MissionList from '../containers/engagement/mission/MissionList';
import WhatsNewCampaignList from '../containers/homeManage/whatsNewCampaigns/WhatsNewCampaignList';
import GreetingSectionList from '../containers/homeManage/greetingSection/GreetingSectionList';
import NewsFeedList from '../containers/engagement/newsFeed/NewsFeedList';
import ProgressiveProfilingEdit from '../containers/userFeedback/progressiveProfiling/ProgressiveProfilingEdit';
import BarcodeList from '../containers/engagement/barcode/BarcodeList';
import { BarcodeType } from '../config/CustomEnums';
import ValidateNewBarcodeList from '../containers/barcode/ValidateNewBarcodesList';
import SplashAdList from '../containers/engagement/splashAd/SplashAdList';
import RewardCodeList from '../containers/engagement/rewardCode/RewardCodeList';
import WhatToDoNextList from '../containers/homeManage/whatToDoNext/WhatToDoNextList'

const queryString = require('query-string');
const dashboard = {
  path: '/',
  name: 'Dashboard',
  location: {
    pathname: '/',
  },
  exact: true,
  main: () => <Dashboard />,
};

const admin = {
  name: 'administrators',
  list: [
    {
      path: '/administrator_groups',
      location: {
        pathname: '/administrator_groups',
      },
      exact: true,
      name: NavBarNames.adminGroup,
      codename: PermissionCodes.administratorgroup,
      main: () => <AdminGroupList />,
    },
    {
      path: '/administrators',
      location: {
        pathname: '/administrators',
      },
      exact: true,
      name: NavBarNames.admin,
      codename: PermissionCodes.administrator,
      main: () => <AdminList />,
    },
  ],
};

const customers = {
  name: 'user',
  list: [
    {
      path: '/customers',
      location: {
        pathname: '/customers',
      },
      exact: true,
      name: NavBarNames.customer,
      codename: PermissionCodes.customer,
      main: () => <CustomerList />,
    },
    {
      path: '/segments',
      location: {
        pathname: '/segments',
      },
      exact: true,
      name: NavBarNames.segments,
      codename: PermissionCodes.segment,
      main: () => <SegmentList />,
    },
    {
      path: '/customer_group',
      location: {
        pathname: '/customer_group',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.customerGroup,
      codename: PermissionCodes.customergroup,
      main: () => <CustomerGroup />,
    },
  ],
};

const engagement = {
  name: 'Engagement',
  list: [
    {
      path: '/campaigns',
      location: {
        pathname: '/campaigns',
        hash: URLFragments.overview,
      },
      exact: true,
      name: NavBarNames.campaigns,
      codename: PermissionCodes.campaign,
      main: () => <CampaignList />,
    },
    {
      path: '/campaign_category',
      location: {
        pathname: '/campaign_category',
      },
      exact: true,
      name: NavBarNames.campaignCategory,
      codename: PermissionCodes.campaigncategory,
      main: () => <CampaignCategory />,
    },
    {
      path: '/mission',
      location: {
        pathname: '/mission',
      },
      exact: true,
      name: NavBarNames.mission,
      codename: PermissionCodes.mission,
      main: () => <MissionList />,
    },
    {
      path: '/messages',
      location: {
        pathname: '/messages',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.message,
      codename: PermissionCodes.message,
      main: () => <MessageList />,
    },
    {
      path: '/earns',
      location: {
        pathname: '/earns',
      },
      exact: true,
      name: NavBarNames.earnRules,
      codename: PermissionCodes.earningrule,
      main: () => <EarningRuleList />,
    },
    {
      path: '/coupons',
      location: {
        pathname: '/coupons',
      },
      exact: true,
      name: NavBarNames.coupons,
      codename: PermissionCodes.coupontemplate,
      main: () => <CouponList />,
    },
    {
      path: '/news_feeds',
      location: {
        pathname: '/news_feeds',
      },
      exact: true,
      name: NavBarNames.newsFeed,
      codename: PermissionCodes.newsFeed,
      main: () => <NewsFeedList />,
    },
    {
      path: '/communities',
      location: {
        pathname: '/communities',
      },
      exact: true,
      name: NavBarNames.community,
      codename: PermissionCodes.community,
      main: () => <CommunityList />,
    },
    {
      path: '/splash_ad',
      location: {
        pathname: '/splash_ad',
      },
      exact: true,
      name: NavBarNames.splashAd,
      codename: PermissionCodes.splashAd,
      main: () => <SplashAdList />,
    },
    {
      path: '/reward_code',
      location: {
        pathname: '/reward_code',
      },
      exact: true,
      name: NavBarNames.rewardCode,
      codename: PermissionCodes.rewardCode,
      main: () => <RewardCodeList />,
    },
  ],
};

const barcode = {
  name: 'Barcode',
  list: [
    {
      path: '/barcodes',
      location: {
        pathname: '/barcodes',
      },
      exact: true,
      name: NavBarNames.barcodeListing,
      codename: PermissionCodes.barcode,
      main: () => <BarcodeList />,
    },
    {
      path: '/barcode_validate',
      location: {
        pathname: '/barcode_validate',
      },
      exact: true,
      name: NavBarNames.validateNewBarcode,
      codename: PermissionCodes.submittednewbarcode,
      main: () => <ValidateNewBarcodeList />,
    },
  ],
};
const merchants = {
  name: 'Merchants',
  list: [
    {
      path: '/brands',
      location: {
        pathname: '/brands',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.brands,
      codename: PermissionCodes.brand,
      main: () => <BrandList />,
    },
    {
      path: '/stores',
      location: {
        pathname: '/stores',
      },
      exact: true,
      name: NavBarNames.stores,
      codename: PermissionCodes.store,
      main: () => <StoreList />,
    },
    {
      path: '/store_category',
      location: {
        pathname: '/store_category',
      },
      exact: true,
      name: NavBarNames.storeCategory,
      codename: PermissionCodes.storecategory,
      main: () => <StoreCategory />,
    },
    {
      path: '/store_feedback',
      location: {
        pathname: '/store_feedback',
      },
      exact: true,
      name: NavBarNames.feedbackList,
      codename: PermissionCodes.feedbackList,
      main: () => <FeedbackList />,
    },
  ],
};

const userFeedback = {
  name: 'User Feedback',
  list: [
    {
      path: '/part1_progressive_profiling',
      location: {
        pathname: '/part1_progressive_profiling',
      },
      exact: true,
      name: NavBarNames.part1ProgressiveProfiling,
      codename: PermissionCodes.part1ProgressiveProfiling,
      main: () => <ProgressiveProfilingEdit />,
    },
    {
      path: '/part2_progressive_profiling',
      location: {
        pathname: '/part2_progressive_profiling',
      },
      exact: true,
      name: NavBarNames.part2ProgressiveProfiling,
      codename: PermissionCodes.part2ProgressiveProfiling,
      main: () => <ProgressiveProfilingEdit />,
    },
  ]
}

const others = {
  name: 'Others',
  list: [
    {
      path: '/webview',
      // location: {
      //   pathname: '/webview',
      // },
      exact: true,
      name: NavBarNames.webview,
      main: () => <WebviewContent />,
    },
    {
      path: '/app_version',
      location: {
        pathname: '/app_version',
      },
      exact: true,
      name: NavBarNames.appversion,
      codename: PermissionCodes.appVersion,
      main: () => <AppVersionList />,
    },
    {
      path: '/guide_image',
      location: {
        pathname: '/guide_image',
      },
      exact: true,
      name: NavBarNames.guideImage,
      codename: PermissionCodes.guideImage,
      main: () => <GuideImageList />,
    },
    {
      path: '/afc_settings',
      location: {
        pathname: '/afc_settings',
      },
      exact: true,
      name: NavBarNames.afcSetting,
      codename: PermissionCodes.viewTransaction,
      main: () => <AfcSettingDetail />,
    }
  ],
};

const records = {
  name: 'Record',
  list: [
    {
      path: '/transactions',
      location: {
        pathname: '/transactions',
        search: queryString.stringify({
          [transactionQueryKey.searchKey]: '',
          [transactionQueryKey.page]: 1,
          [transactionQueryKey.rank]: true,
          [transactionQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.transactions,
      codename: PermissionCodes.transaction,
      main: () => <TransactionList />,
    },
    {
      path: '/point_records',
      location: {
        pathname: '/point_records',
        search: queryString.stringify({
          [pointRecordQueryKey.searchKey]: '',
          [pointRecordQueryKey.page]: 1,
          [pointRecordQueryKey.rank]: true,
          [pointRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.pointRecords,
      codename: PermissionCodes.pointtransaction,
      main: () => <PointTransactionList />,
    },
    {
      path: '/coupon_records',
      location: {
        pathname: '/coupon_records',
        search: queryString.stringify({
          [couponRecordQueryKey.searchKey]: '',
          [couponRecordQueryKey.page]: 1,
          [couponRecordQueryKey.rank]: true,
          [couponRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.couponRecords,
      codename: PermissionCodes.coupontransaction,
      main: () => <CouponTransaction />,
    },
    /************  new pages by walty ****************/
    {
      path: '/afc_data_record',
      location: {
        pathname: '/afc_data_record',
        search: queryString.stringify({
          [couponRecordQueryKey.searchKey]: '',
          [couponRecordQueryKey.page]: 1,
          [couponRecordQueryKey.rank]: true,
          [couponRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.afcDataRecords,
      codename: PermissionCodes.coupontransaction,  // TODO: update later
      main: () => <AfcDataRecordList />,
    },
    {
      path: '/afc_cronjob_record',
      location: {
        pathname: '/afc_cronjob_record',
        search: queryString.stringify({
          [couponRecordQueryKey.searchKey]: '',
          [couponRecordQueryKey.page]: 1,
          [couponRecordQueryKey.rank]: true,
          [couponRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.afcCronJobRecords,
      codename: PermissionCodes.coupontransaction,
      main: () => <AfcCronjobRecordList />,
    },
    {
      path: '/afc_account_binding_record',
      location: {
        pathname: '/afc_account_binding_record',
        search: queryString.stringify({
          [couponRecordQueryKey.searchKey]: '',
          [couponRecordQueryKey.page]: 1,
          [couponRecordQueryKey.rank]: true,
          [couponRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.afcAccountLogs,
      codename: PermissionCodes.coupontransaction,
      main: () => <AfcAccountBindingRecordList />,
    },

  ],
};

const homemanager = {
  name: 'Manage Home page',
  list: [
    // {
    //   path: '/banners',
    //   location: {
    //     pathname: '/banners',
    //   },
    //   exact: true,
    //   name: NavBarNames.banners,
    //   codename: PermissionCodes.banners,
    //   main: () => <BannerList />,
    // },
    {
      path: '/discover_campaigns',
      location: {
        pathname: '/discover_campaigns',
      },
      exact: true,
      name: NavBarNames.featuredCampaign,
      codename: PermissionCodes.featuredCampaign,
      main: () => <FeaturedCampaignList />,
    },
    {
      path: '/what_is_new',
      location: {
        pathname: '/what_is_new',
      },
      exact: true,
      name: NavBarNames.whatsNewCampaign,
      codename: PermissionCodes.whatsNewCampaign,
      main: () => <WhatsNewCampaignList />,
    },
    {
      path: '/greeting_section',
      location: {
        pathname: '/greeting_section',
      },
      exact: true,
      name: NavBarNames.greetingSection,
      codename: PermissionCodes.greetingSection, // TODO
      main: () => <GreetingSectionList />,
    },
    {
      path: '/what_to_do_next',
      location: {
        pathname: '/what_to_do_next',
        search: queryString.stringify({
          page: 1,
          rank: true,
          sort: 'displayPriority'
        })
      },
      exact: true,
      name: NavBarNames.whatToDoNext,
      codename: PermissionCodes.whatToDoNext,
      main: () => <WhatToDoNextList />,
    },
  ],
};

export const tabList = [
  dashboard,
  customers,
  engagement,
  barcode,
  merchants,
  userFeedback,
  others,
  records,
  admin,
  homemanager,
];
