import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import { CustomNumberInputWithUnit } from './CustomBaseComponments';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import { isShowError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { firstError, useCompare } from '../../utils';

function StepPropertiesSection(props) {
  const dispatch = useDispatch();
  const defaultValue = props.defaultValue || {};

  const [minDistance, setMinDistance] = useState(defaultValue.minDistance);
  const [maxDistance, setMaxDistance] = useState(defaultValue.maxDistance);

  const vals = {
    minDistance,
    maxDistance,
  };
  const hasValsChanged = useCompare(vals);

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));
  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };
  useEffect(() => {
    firstError(
      `${EarningRuleErrorHandleField.wrongDistance.name}`,
      errorFields,
    );
    firstError(`${EarningRuleErrorHandleField.maxDistance.name}`, errorFields);
    firstError(`${EarningRuleErrorHandleField.minDistance.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);
  const minDistanceIntValue = parseInt(minDistance);
  const maxDistanceIntValue = parseInt(maxDistance);
  const distanceIsInValid =
    !isNaN(minDistanceIntValue) &&
    !isNaN(maxDistanceIntValue) &&
    maxDistanceIntValue < minDistanceIntValue;
  console.log(distanceIsInValid);
  return (
    <>
      <label className="create-section-title">ACTIVE TRACKING</label>
      <CustomNumberInputWithUnit
        title="Minimum distance"
        tips="Once over the minimum distance then will get reward."
        setValue={setMinDistance}
        unit="km"
        defaultValue={minDistance}
        errorId={`${EarningRuleErrorHandleField.minDistance.name}`}
        error={isError(EarningRuleErrorHandleField.minDistance.name)}
        errorMessage={`${EarningRuleErrorHandleField.minDistance.message}`}
      />
      <CustomNumberInputWithUnit
        title="Maximum distance"
        tips="The portion exceeding the maximum value will not be included in the calculation of the reward range"
        setValue={setMaxDistance}
        unit="km"
        defaultValue={maxDistance}
        errorId={
          distanceIsInValid
            ? EarningRuleErrorHandleField.wrongDistance.name
            : EarningRuleErrorHandleField.maxDistance.name
        }
        error={
          distanceIsInValid ||
          isError(EarningRuleErrorHandleField.maxDistance.name)
        }
        errorMessage={
          distanceIsInValid
            ? EarningRuleErrorHandleField.wrongDistance.message
            : EarningRuleErrorHandleField.maxDistance.message
        }
      />
    </>
  );
}

export default StepPropertiesSection;
