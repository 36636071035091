import React from 'react';
import { Table, Image } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  LanguageTabTransLation,
  LanguageConfig,
  MessageChannel,
} from '../../config/CustomEnums';
import { InformationBarMessageType } from './CreateMessageConfig';

const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

function MessageOverviewTableSection(props) {
  const showdown = require('showdown');
  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  const data = props.data || {};
  console.log('@data1', data)

  const isInformationBar = data?.displayChannels?.includes(MessageChannel.informationBar);
  const isMaintenance = data?.messageType === InformationBarMessageType.maintenance;

  const getSectionTitle = () => {
    const displayChannels = data.displayChannels
    if (isInformationBar) {
      return `INBOX MESSAGE CONTENT`
    }

    return data.displayChannels?.toUpperCase();

  };

  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value?.replace(/<br>\n/g, '<br>')?.replace(/\\,/g, ',')) : '-',
        }}
      ></td>
    );
  };

  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const sectionValues = (valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) => {
          if (language === LanguageConfig.english) {
            if (valueKey.includes('.')) {
              return fieldValueText(data[valueKey.split('.')[0]][valueKey.split('.')[1]], index);
            }
            return fieldValueText(data[valueKey], index);
          } else {
            if (valueKey.includes('.')) {
              return fieldValueText(data.translations?.[language]?.[valueKey.split('.')[0]]?.[valueKey.split('.')[1]], index);
            }
            return fieldValueText(
              data.translations?.[language]?.[valueKey],
              index,
            );
          }
        })}
      </>
    );
  };

  const coverImage = () => {
    return (
      <>
        {fieldsLanguages.map((language, index) => {
          let image = '';
          if (language === LanguageConfig.english) {
            image = data.photo;
          } else {
            image = data.translations?.[language]?.photo;
          }
          if (Array.isArray(image)) {
            const imageList = image.map((item) => {
              if (item.type) {
                return item.value;
              }
              return image;
            });
            image = imageList[0];
          }
          return (
            <td key={`coverImage-${language}-${index}`}>
              {image && image !== '' ? (
                <Image
                  className="content-section-rectangle-image"
                  src={image}
                />
              ) : (
                '-'
              )}
            </td>
          );
        })}
      </>
    );
  };

  return (
    <>
      <PerfectScrollbar>
        <label className="create-section-title">{getSectionTitle()}</label>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
              {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText(`Title${isInformationBar ? '' : ' (optional)'}`)}
              {sectionValues('name')}
            </tr>
            <tr>
              {fieldNameText('Content')}
              {sectionValues('content')}
            </tr>
            <tr>
              {fieldNameText('Image (optional)')}
              {coverImage()}
            </tr>
            {!isMaintenance &&
              <tr>
                {fieldNameText('Url (optional)')}
                {sectionValues('url')}
              </tr>
            }
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}

export default MessageOverviewTableSection;
