import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateSplashAdError } from '../../models/SplashAdModel';
import PeriodSection from '../campaign/campaignCreation/PeriodSection';

const VisiblePeriodSection =({
  isAlwaysVisiblePeriod,
  visibleStartDate,
  visibleEndDate,
  errorFields,
}) => {
  const dispatch = useDispatch();

  return (
    <PeriodSection
      disabled={false}
      title="Visible Period"
      maxDate={null}
      minDate={null}
      titleDescription={'Display this splash ad on app.'}
      switchButtonTitle="It is an all-time visible splash ad"
      isAlwaysPeriod={isAlwaysVisiblePeriod}
      switchOnChange={(value) => {
        dispatch({
          type: 'splashAd/updateSplashAdDetail',
          payload: { isAlwaysVisiblePeriod: value},
        });
      }}
      startDate={visibleStartDate}
      startDateChange={(value) => {
        dispatch({
          type: 'splashAd/updateSplashAdDetail',
          payload: { visibleStartDate: value},
        });
      }}
      filterStartDate={(date) => {
        return true;
      }}
      endDate={visibleEndDate}
      endDateChange={(value) => {
        dispatch({
          type: 'splashAd/updateSplashAdDetail',
          payload: { visibleEndDate: value},
        });
      }}
      filterEndDate={(date) => {
        return true;
      }}
      hideTime={false}
      errorFields={errorFields}
      fieldName={CreateSplashAdError.visibleEndDate.name}
      fieldErrorMessage={CreateSplashAdError.visibleEndDate.message}
    />
  );
}


const mapStateToProps = (state) => ({
  isAlwaysVisiblePeriod: state.splashAd.splashAdDetail.isAlwaysVisiblePeriod,
  visibleStartDate: state.splashAd.splashAdDetail.visibleStartDate,
  visibleEndDate: state.splashAd.splashAdDetail.visibleEndDate,
  errorFields: state.splashAd.errorFields,
});

export default connect(mapStateToProps)(VisiblePeriodSection);
