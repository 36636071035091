import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { createAction } from '../../utils';

function MessageSelectDropdown(props) {
  const title = props.title || null;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => { });
  const { currentPageMessageList } = useSelector((state) => ({
    currentPageMessageList: state.messageList.currentPageMessageList,
  }));

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      createAction('messageList/getCurrentPageMessages')({
        // isSelectorLoad: true,
        // isExpired: false,
        // isPublished: true,
        // ...(props.defaultFilter || {}),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      setValue={onSelectChange}
      source={currentPageMessageList}
      sourceShowId={props.sourceShowId || false}
      showMultiLine={props.showMultiLine || false}
      defaultValue={defaultValue}
      defaultFilter={props.defaultFilter || {}}
      loadMoreAction={'messageList/getCurrentPageMessages'}
      filterAction={'messageList/getCurrentPageMessages'}
      showRemoveButton={props.showRemoveButton}
      onRemoveButtonClick={props.onRemoveButtonClick}
      errors={props.errors}
      errorName={props.errorName}
      errorMessage={props.errorMessage}
      extraTitleClass={'no-bottom-space'}
    />
  );
}

export default MessageSelectDropdown;
