import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import i18n from '../../I18n';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import {
  CreateMissionError,
  MissionEarningIcon,
  NumberOfLevelsList,
} from '../../models/MissionModel';
import './MissionGeneralSection.scss';
import CustomInputWithTitle from '../customer/CustomInputWithTitle';
import { LanguageConfig } from '../../config/CustomEnums';
import { CustomNumberInputWithUnit } from '../earning/CustomBaseComponments';
import { isShowError } from '../../utils';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

function SetLevelsSection({
  errorFields,
  setLevels,
  habitualLevels,
  language,
  isRecycling,
  missionDetail
}) {
  const dispatch = useDispatch();
  const isPublished = missionDetail?.isPublished;
  const setLevelsValue = setLevels?.value?.value;

  const updateHabitualLevels = (field, value, index) => {
    const tempArray = [].concat(habitualLevels);
    tempArray[index] = {
      ...habitualLevels[index],
      [field]: value,
    };

    dispatch({
      type: 'mission/updateMissionDetailTranslations',
      payload: {
        habitualLevels: tempArray,
        language,
      },
    });
  };

  const getLevelError = (levelGrade, language) => {
    if (language === LanguageConfig.english) {
      if (isShowError(`level${levelGrade}Name`, errorFields)) {
        return {
          id: `level${levelGrade}Name`,
          error: isShowError(`level${levelGrade}Name`, errorFields),
          message: `Please provide vaild Level ${levelGrade} name`,
        };
      }

      if (isShowError(`level${levelGrade}JoinedTimes`, errorFields)) {
        return {
          id: `level${levelGrade}JoinedTimes`,
          error: isShowError(`level${levelGrade}JoinedTimes`, errorFields),
          message: `Please provide vaild Level ${levelGrade} Joined times`,
        };
      }

      if (isShowError(`level${levelGrade}RecyclingItems`, errorFields)) {
        return {
          id: `level${levelGrade}RecyclingItems`,
          error: isShowError(`level${levelGrade}RecyclingItems`, errorFields),
          message: `Please provide vaild Level ${levelGrade} Recycling items`,
        };
      }
    }
    return {};
  };

  const levelsSection = (habitualLevels, language) => {
    return habitualLevels.map((item, index) => {
      console.log("item:", item)
      const error = getLevelError(item.levelGrade, language);
      return (
        <div className="grid-container">
          <CustomInputWithTitle
            title={i18n.t('mission_level_name', { locale: language, levelGrade: item.levelGrade })}
            defaultValue={item.levelName}
            setValue={(value) => {
              updateHabitualLevels('levelName', value, index);
            }}
            error={error}
            errorid
          />
          {language === LanguageConfig.english ? (
            <>
              <div style={{ opacity: isPublished || isRecycling ? 0.5 : 1 }}
              >
                <CustomNumberInputWithUnit
                  title={'Joined times'}
                  unit={'times'}
                  defaultValue={item.joinedTimes}
                  disabled={isRecycling || isPublished}
                  setValue={(value) => {
                    updateHabitualLevels('joinedTimes', value, index);
                  }}
                />
              </div >
              {isRecycling ? (
                <div style={{ opacity: isPublished ? 0.5 : 1 }}>
                  <CustomNumberInputWithUnit
                    title={'Recycling items'}
                    unit={'items'}
                    disabled={isPublished}
                    defaultValue={item.recyclingItems}
                    setValue={(value) => {
                      updateHabitualLevels('recyclingItems', value, index);
                    }}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      );
    });
  };

  useEffect(() => {
    const setLevelsValue = setLevels?.value?.value;
    const length = habitualLevels.length;
    const tempArray = [].concat(habitualLevels);
    if (setLevelsValue > length) {
      const newValue = [...Array(setLevelsValue - length)].map((_, index) => {
        return {
          levelGrade: length + index + 1,
          levelName: '',
          joinedTimes: '',
          recyclingItems: '',
          numberOfPoints: '',
          couponQuantity: '',
          couponTemplate: '',
        };
      });
      tempArray.push(...newValue);
    } else if (setLevelsValue < length) {
      [...Array(length - setLevelsValue)].map((_, index) => {
        tempArray.splice(-1, 1);
      });
    }
    dispatch({
      type: 'mission/updateMissionDetailTranslations',
      payload: { habitualLevels: tempArray, language },
    });
  }, [setLevels]);

  useEffect(() => {
    if (language === LanguageConfig.english && habitualLevels.length) {
      let tempArray = [].concat(habitualLevels);
      if (isRecycling) {
        tempArray = habitualLevels.map((item, index) => {
          return {
            ...item,
            joinedTimes: '',
          };
        });
      } else {
        tempArray = habitualLevels.map((item, index) => {
          return {
            ...item,
            recyclingItems: '',
          };
        });
      }

      dispatch({
        type: 'mission/updateMissionDetailTranslations',
        payload: {
          habitualLevels: tempArray,
          language,
        },
      });
    }
  }, [isRecycling]);

  const levelGradeSelect = () => {
    if (language !== LanguageConfig.english) {
      return null;
    }
    if (missionDetail?.pk) {
      return <>
        <CustomTitleLabel className={"no-bottom-space"} title={"Set levels"} />
        <label className="banners-detail-text">{habitualLevels?.length}</label>
      </>
    }
    return <>
      <CustomTitleWithDropDown
        needFilter={false}
        title={'Set levels'}
        source={NumberOfLevelsList}
        defaultValue={setLevels}
        loadMoreAction={() => { }}
        filterAction={() => { }}
        setValue={(value) => {
          dispatch({
            type: 'mission/updateMissionDetail',
            payload: { setLevels: value },
          });
        }}
        errors={errorFields}
        errorName={CreateMissionError.setLevels.name}
        errorMessage={CreateMissionError.setLevels.message}
        extraTitleClass={'no-bottom-space'}
      />
      <label className="tips-message">Maximum 5 levels</label>
    </>
  }

  return (
    <>
      {language === LanguageConfig.english ? levelGradeSelect() : null}
      {setLevelsValue ? levelsSection(habitualLevels, language) : null}
    </>
  );
}

export default SetLevelsSection;
