import React from 'react';
import { useHistory } from 'react-router-dom';
import BasePrompt from './BasePrompt';

export const NewPromptType = {
  segments: 'segments',
  customerGroup: 'customer groups',
  none: 'none',
};

function AddCustomerGroupSegmentPrompt(props) {
  const history = useHistory();
  const createItem = props.createItem || '';
  const createItemUpperCase = createItem.charAt(0).toUpperCase() + createItem.slice(1);
  const promptType = props.promptType || NewPromptType.none;
  const setPromptType = props.setPromptType || (() => {});

  const getPromptContent = () => {
    let title = 'segment';
    let button = 'Segment';
    let pathname = '/segments/create/';
    if (promptType === NewPromptType.customerGroup) {
      title = 'customer group';
      button = 'Customer Group';
      pathname = '/customer_group/create';
    }
    return {
      title: `Go to create ${title} ?`,
      description: `You will leave ${createItem} creation process.`,
      button: `Go to Create ${button}`,
      action: () => {
        history.push({
          pathname: pathname,
          state: {
            from: history.location,
            title: `Continue to Create ${createItemUpperCase}`,
            content: `You can continue to create the ${createItemUpperCase}.`,
          },
        });
      },
    };
  };


  return (
    <BasePrompt
      show={ promptType !== NewPromptType.none}
      closeAction={() => setPromptType(NewPromptType.none)}
      rightButton={{
        text: getPromptContent().button,
        action: getPromptContent().action,
      }}
      title={getPromptContent().title}
      description={getPromptContent().description}
    />
  );
}

export default AddCustomerGroupSegmentPrompt;
