import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getAfcCronjobRecords = (afterCursor, rank, search, moreSearch) => {
  // let order = rank ? '-pk' : 'pk';
  let order = '-pk';
  if (moreSearch?.sort) {
    order = moreSearch.sort;
  }
  const searchKey = search || '';

  const query = `
    {
        afcCronjobRecords(first: 20, after: "${afterCursor}", orderBy: "${order}") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
		id
		pk
		source
		type
		startDatetime
		endDatetime	    
		status

          }
        }
      }
    }
    `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

const AFC_DATA_RECORD_NODE = `{
    id
    pk

    source
    type
    startDatetime
    endDatetime

    status

    failLogMessage
}`;

export const getOneAfcCronjobRecord = (id) => {
    const query = `{
      afcCronjobRecord(id: "${id}") ${AFC_DATA_RECORD_NODE}
    }`;
    const variables = {};
    return BaseHelper.callGraphQLAPI({
      query,
      variables,
    });
};