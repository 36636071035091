import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { addDomainToImage } from '../../utils';
import AlertInfoPrompt from '../base/prompt/AlertInfoPrompt';
import PreviewImage from '../base/prompt/PreviewImage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { GreenDiningSkuItems } from '../transactions/MTRCreateTransactionStepThree';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

const showdown = require('showdown');
const converter = new showdown.Converter();
converter.setOption('simpleLineBreaks', true);

function MembershipInfoCard({ title, data, fields, extraDataFields }) {
  const dispatch = useDispatch();
  const tableRef = useRef();

  const [showPrompt, setShowPrompt] = useState(false);
  const [promptInfo, setPromptInfo] = useState({});
  const [tableWidth, setTableWidth] = useState(800);
  const [enlargeImageUrl, setEnlargeImageUrl] = useState(null);

  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, []);

  const getDisplayValue = (field) => {
    let value = data[field];
    if (Array.isArray(value)) {
      value = value.slice(0, 10).join('\r\n');
    }

    if (field === 'mobileNumber') {
      value = value?.replace('\r\n', ' ');
    }

    if (field === 'remarks') {
      return (
        <label
          className={"create-section-field-input"}
          dangerouslySetInnerHTML={{
            __html: value ? converter.makeHtml(value) : '-'
          }}
        >
        </label>
      )
    };


    if (extraDataFields && field.includes('.')) {
      value = extraDataFields[field.split('.')[0]][field.split('.')[1]];
    }

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
      if (Array.isArray(value)) {
        value = value.slice(0, 10).join('\r\n');
      }
    }

    return value || '-';
  };

  const needShowMore = (field) => {
    let value = data[field];
    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }
    return Array.isArray(value) && value.length > 10;
  };

  const showMore = (field) => {
    let title, subTitle, content;
    if (field === 'coupons') {
      title = 'Coupon';
      subTitle = 'The following coupon belogn to this customer';
    }

    if (field === 'availableCampaign') {
      title = 'Campaign';
      subTitle = 'The user can see the following activities';
    }

    if (field === 'displayPermissions') {
      title = 'Administrator permission';
      subTitle = 'The user has the following permissions';
    }

    let value = data[field];

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }

    content = (
      <ul>
        {value.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );

    setPromptInfo({ title, subTitle, content });
    setShowPrompt(true);
  };
  // console.log('@@56: ', tableRef.current);
  return (
    <>
      <label className="create-section-title">{title}</label>

      <table ref={tableRef}>
        {fields.map((row) => {
          return (
            <tr className='membership-info-card-tr'>
              {row.map((column) => {
                if (column.title === '') {
                  return null;
                }
                if (column.title === 'displayGeneralPurchaseSubType') {
                  return <CustomTitleLabel title={data['displayGeneralPurchaseSubType']} className={'transaction-general-purchase-detail-sub-type-title'} />
                }
                if (column.title === 'SKU') {
                  return (
                    <td
                      style={{
                        display: 'inline-grid',
                        width: `${tableWidth / row.length}px`,
                      }}
                    >
                      <GreenDiningSkuItems skuItems={data.skuItems} isSeparateTitle={column.isSeparateTitle} />
                    </td>
                  );
                }
                if (column.isSeparateTitle) {
                  return (
                    <>
                      <div className='transaction-general-purchase-detail-separete-line' />
                      <CustomTitleLabel title={column.title} className={'transaction-general-purchase-detail-separete-title'} />
                    </>
                  )
                }
                if (column.title.includes('Duplicated')) {
                  return (
                    <td
                      style={{
                        display: 'inline-grid',
                        width: `${tableWidth / row.length}px`,
                      }}
                    >
                      <CustomTitleLabel title={column.title} />
                      <label className="custom-record-normal-font custom-timeline-time-color list-new-line ">
                        <button disabled
                          class={getDisplayValue(column.field) === 'Yes'
                            ? "duplicate-ocl-yes-button"
                            : "duplicate-ocl-no-button"}>
                          {getDisplayValue(column.field)}
                        </button>
                      </label>
                    </td>
                  );
                }

                if (column.editable) {
                  return (
                    <td
                      style={{
                        display: 'inline-grid',
                        width: `${tableWidth / row.length}px`,
                      }}
                    >
                      <CustomTitleLabel title={`${column.title}(edit)`} />
                      {
                        column.description
                          ? <div className="second-section_description membership-info-editable-field-description" >
                            {column.description}
                          </div>
                          : null
                      }
                      <input
                        type="text"
                        className={`text-input-field ${column.editError
                          ? 'error-field'
                          : ''
                          }`}
                        placeholder={column.placeholder || ''}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (column.inputOnChange) {
                            value = column.inputOnChange(value)
                          }
                          dispatch({ type: column.editAction, payload: { value } })
                        }}
                        value={column.editValue}
                      />

                      <ErrorFieldMessage
                        id={column.field}
                        error={column.editError}
                        message={column.editError}
                      />
                    </td>
                  )
                }

                return (
                  <td
                    style={{
                      display: 'inline-grid',
                      width: `${tableWidth / row.length}px`,
                    }}
                  >
                    <CustomTitleLabel title={column.title} className={column.titleClassName ?? ''} />
                    {column.title === 'Brand' ? (
                      <label className="tips-message">
                        Once set, cannot be changed.
                      </label>
                    ) : null}
                    <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
                      {column.title.includes('image') &&
                        getDisplayValue(column.field) !== '-' ? ( //TODO: need change
                        Array.isArray(data[column.field]) ?
                          data[column.field].map((item) => {
                            return (
                              <img
                                alt="img"
                                src={addDomainToImage(item)}
                                style={{ maxWidth: 191, maxHeight: 191, marginRight: '30px' }}
                                onClick={() => {
                                  setEnlargeImageUrl(
                                    addDomainToImage(item),
                                  );
                                }}
                              />
                            )
                          })
                          :
                          <img
                            alt="img"
                            src={addDomainToImage(getDisplayValue(column.field))}
                            style={{ maxWidth: 191, maxHeight: 191 }}
                            onClick={() => {
                              setEnlargeImageUrl(
                                addDomainToImage(getDisplayValue(column.field)),
                              );
                            }}
                          />
                      ) : (
                        getDisplayValue(column.field)
                      )}
                    </label>
                    {needShowMore(column.field) ? (
                      <button
                        type="button"
                        className="btn-link reset-button show-more-button"
                        onClick={() => {
                          showMore(column.field, data[column.field]);
                        }}
                      >
                        show more
                      </button>
                    ) : null}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
      <AlertInfoPrompt
        show={showPrompt}
        onCancel={() => setShowPrompt(false)}
        onClose={() => setShowPrompt(false)}
        onConfirm={() => setShowPrompt(false)}
        title={promptInfo.title}
        subTitle={promptInfo.subTitle}
        content={promptInfo.content}
      />
      <PreviewImage
        show={!!enlargeImageUrl}
        onClose={() => {
          setEnlargeImageUrl(null);
        }}
        imageUrl={enlargeImageUrl}
        extraClass={{ width: 'auto', maxWidth: '100%', height: 315 * 1.7 }}
      />
    </>
  );
}

export default MembershipInfoCard;
