import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import {
  LanguageConfig,
  APIStatus,
  BANNER_CONTENT_TYPE,
} from '../../../config/CustomEnums';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';
import i18n from '../../../I18n';
import Loading from '../../../components/base/Loading';
import { createAction, isShowError, firstError } from '../../../utils';
import CustomRadios from '../../../components/base/CustomRadios';
import { Row } from 'react-bootstrap';
import CampaignSelectDropdown from '../../../components/message/CampaignSelectDropdown';
import ProductSelectDropdown from '../../../components/banners/ProductSelectDropdown';
import ImageUploader from '../../../components/base/ImageUploader';
import { BannerErrorFields } from '../../../models/HomeBannersModel';
import { getReadablePeriod } from '../../../utils/TimeFormatUtil';
import { listItem } from '../../../components/banners/DisplayItems';
import DisplayOrder from '../../../components/banners/DisplayOrder';
import './CreateBanner.scss';

export const ContentType = {
  Campaign: 'Campaign',
  Product: 'Product',
};

const getTitle = (id) => {
  if (id) {
    return {
      detail: '', //'Edit a banner to promote your campaign or product.',
      title: 'Edit Banner',
      model: 'bannerList',
    };
  }
  return {
    detail: '', //'Create a banner to promote your campaign or product.',
    title: 'Create Banner',
    model: 'bannerList',
  };
};

const getRadioOptions = () => [
  {
    label: ContentType.Campaign,
    value: BANNER_CONTENT_TYPE.CAMPAIGN,
  },
  {
    label: ContentType.Product,
    value: BANNER_CONTENT_TYPE.PRODUCT,
  },
];

const CreateBanner = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { errorFields, banner, createStatus, loadingStatus } = useSelector(
    (state) => ({
      errorFields: state.bannerList.errorFields,
      banner: state.bannerList.banner,
      createStatus: state.bannerList.createStatus,
      loadingStatus: state.bannerList.loadingStatus,
    }),
  );
  const [bannerType, setBannerType] = useState(BANNER_CONTENT_TYPE.CAMPAIGN);
  const [coverPhoto, setCoverPhoto] = useState([]);
  const [traditionalCoverPhoto, setTraditionalCoverPhoto] = useState([]);
  const [simplifiedCoverPhoto, setSimpleCoverPhoto] = useState([]);
  const [order, setOrder] = useState();
  const setNewTranslationForImage = (image, language) => {
    if (language === LanguageConfig.traditionalChinese) {
      setTraditionalCoverPhoto(image);
      return;
    }
    if (language === LanguageConfig.simplifiedChinese) {
      setSimpleCoverPhoto(image);
      return;
    }
    setCoverPhoto(image);
  };
  const getImage = (language) => {
    if (language === LanguageConfig.english) {
      return coverPhoto || [];
    }
    if (language === LanguageConfig.traditionalChinese) {
      return traditionalCoverPhoto || [];
    }
    return simplifiedCoverPhoto || [];
  };

  useEffect(() => {
    dispatch(createAction('bannerList/getProductList')());
    const bannerId = params.id;
    if (bannerId) {
      dispatch(createAction('bannerList/getOneBanner')({ id: bannerId }));
    } else {
      dispatch({
        type: 'bannerList/loadFromSessionStorage',
      });
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'bannerList/clearData' });
      dispatch({ type: 'campaignList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNewImageArray = (image) => {
    if (!image) {
      return [];
    }
    return [{ type: 'TYPE_URL', value: image }];
  };

  useEffect(() => {
    const traditionalTranslation = banner.translations?.find(
      (translation) =>
        translation.language === LanguageConfig.traditionalChinese,
    )?.coverPhoto;
    const simpleTranslation = banner.translations?.find(
      (translation) =>
        translation.language === LanguageConfig.simplifiedChinese,
    )?.coverPhoto;
    setNewTranslationForImage(
      getNewImageArray(banner.coverPhoto),
      LanguageConfig.english,
    );
    setNewTranslationForImage(
      getNewImageArray(traditionalTranslation),
      LanguageConfig.traditionalChinese,
    );
    setNewTranslationForImage(
      getNewImageArray(simpleTranslation),
      LanguageConfig.simplifiedChinese,
    );
    setOrder(banner.order);
    setBannerType(banner.contentType || BANNER_CONTENT_TYPE.CAMPAIGN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner.coverPhoto, banner.order, banner.contentType]);
  useEffect(() => {
    if (createStatus === APIStatus.success && params.id) {
      history.push('/banners');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    firstError(`${errorFields[0]}-${LanguageConfig.english}`, errorFields);
  }, [errorFields]);

  const formChanged = () => {
    dispatch({
      type: 'bannerList/updateState',
      payload: { formChanged: true },
    });
  };

  const error = (language, field) => {
    return (
      <ErrorFieldMessage
        id={`${field.name}-${language}`}
        error={isShowError(field, errorFields, language)}
        message={field.message}
      />
    );
  };

  const campaignSelectDropdown = (language) => {
    if (language !== LanguageConfig.english) {
      return listItem('Related campaign', banner.campaign?.name);
    }
    return (
      <CampaignSelectDropdown
        title={'Related campaign'}
        isPrompt
        defaultValue={{ label: banner.campaign?.name, value: banner.campaign }}
        setValue={(value) => {
          formChanged();
          dispatch(
            createAction('bannerList/updateBanner')({
              campaign: value,
            }),
          );
        }}
      />
    );
  };

  const productSelectDropdown = (language) => {
    if (language !== LanguageConfig.english) {
      return listItem('Related product', banner.campaign?.name);
    }
    return (
      <ProductSelectDropdown
        title={'Related Porduct'}
        isPrompt
        defaultValue={{ label: banner.product?.name, value: banner.product }}
        setValue={(value) => {
          formChanged();
          dispatch(
            createAction('bannerList/updateBanner')({
              product: value,
            }),
          );
        }}
      />
    );
  };

  const campaignSection = (language) => {
    return (
      <>
        {campaignSelectDropdown(language)}
        {listItem('Campaign name', banner.campaign?.name)}
        {listItem('Campaign ID', banner.campaign?.pk)}
        {listItem(
          'Campaign’s active period',
          getReadablePeriod(
            banner.campaign?.startDate,
            banner.campaign?.endDate,
          ),
        )}
        {listItem(
          'Campaign’s visible period',
          getReadablePeriod(
            banner.campaign?.displayStartDate,
            banner.campaign?.displayEndDate,
          ),
        )}
      </>
    );
  };

  const productSection = (language) => {
    return (
      <>
        {productSelectDropdown(language)}
        {listItem('Product name', banner.product?.name)}
        {listItem('Product ID', banner.product?.pk)}
        {listItem('Quantity', banner.product?.quantity)}
      </>
    );
  };

  const bannerImage = (language) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          Banner image
        </label>
        <label className="create-campaign-cover-photo-reminder">
          Suggested Image Size: 900 px * 600 px (Less than 2MB, support JPG, PNG
          and GIF only.)
        </label>
        <ImageUploader
          minWidth={900}
          minHieght={600}
          images={getImage(language)}
          onImageStateChange={(newState) => {
            formChanged();
            setNewTranslationForImage(newState, language);
          }}
          uploadImageClicked={() => {}}
          aspect={3 / 2}
          maxImageNum={1}
          language={language}
        />
        {error(language, BannerErrorFields.bannerImage)}
      </>
    );
  };

  const bannerTypeSelector = (language) => {
    if (language !== LanguageConfig.english) {
      return listItem(
        'Type',
        bannerType === BANNER_CONTENT_TYPE.PRODUCT
          ? ContentType.Product
          : ContentType.Campaign,
        'type-margin-top',
      );
    }
    return (
      <>
        <Row className="featured-exclusive-row">
          <CustomRadios
            onChange={(value) => {
              formChanged();
              setBannerType(value);
            }}
            default={bannerType}
            options={getRadioOptions()}
          />
        </Row>
        {error(language, BannerErrorFields.contentType)}
      </>
    );
  };

  const getLanguageTab = (language) => {
    if (
      createStatus === APIStatus.calling ||
      loadingStatus === APIStatus.calling
    ) {
      return [<Loading />];
    }
    return [
      <>
        {bannerTypeSelector(language)}
        {bannerType === BANNER_CONTENT_TYPE.CAMPAIGN
          ? campaignSection(language)
          : productSection(language)}
        {bannerImage(language)}
        <DisplayOrder
          order={order}
          onChange={(item) => {
            formChanged();
            setOrder(item);
          }}
          errors={errorFields}
          field={BannerErrorFields.order}
          language={language}
        />
      </>,
    ];
  };

  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  const save = (active) => {
    const simple = {
      coverPhoto: simplifiedCoverPhoto[0]?.value,
      language: LanguageConfig.simplifiedChinese,
    };
    const traditional = {
      coverPhoto: traditionalCoverPhoto[0]?.value,
      language: LanguageConfig.traditionalChinese,
    };
    const translations = [simple, traditional].map((item) => {
      const trans = banner.translations?.find(
        (translation) => translation.language === item.language,
      );
      if (trans) {
        item.id = trans.pk;
      }
      return item;
    });
    dispatch({
      type: 'bannerList/createOrUpdateBanner',
      payload: {
        checkStep: true,
        isCreate: !banner.id,
        data: {
          ...banner,
          coverPhoto: coverPhoto[0]?.value,
          contentType: bannerType,
          order,
          contentReferenceId:
            bannerType === BANNER_CONTENT_TYPE.CAMPAIGN
              ? banner.campaign?.pk
              : banner.product?.pk,
          active: active,
          translations: translations,
        },
      },
    });
  };

  const renderForm = () => (
    <>
      <ContentSections
        fieldsError={errorFields && errorFields.length > 0}
        languageTabContent={languageTabConfig}
        hidePreview
      />

      <SaveAndBackButtons
        saveTempText={'Save only'}
        saveText={`Save and ${banner.active ? 'UnPublish' : 'Publish'}`}
        saveTempAction={() => save(banner.active)}
        saveAction={() => save(!banner.active)}
      />
    </>
  );

  return (
    <div className="create-banner">
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({ type: 'bannerList/changeSessionStorage', payload: save });
        }}
        showFinishPop={createStatus === APIStatus.success && !params.id}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Banner is successfully created.',
          steps: {
            stepNames: [],
            current: null,
          },
          buttons: [
            {
              text: 'Back to Banner list',
              action: () => history.push('/banners'),
              requires: '',
            },
          ],
        }}
        content={[renderForm()]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={`${params.id ? 'Edit' : 'Create'} Banner`} />
        }
        caution={getTitle(params.id)}
      />
    </div>
  );
};

export default CreateBanner;
