import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import {
  CustomTitleLabel,
  CustomNumberInput,
  CustomNumberInputWithUnit,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { useCompare } from '../../utils';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

function GPSCheckInSection(props) {
  const dispatch = useDispatch();
  const defaultValue = props.defaultValue || {};
  const [latitude, setLatitude] = useState(defaultValue.latitude);
  const [longitude, setLongitude] = useState(defaultValue.longitude);
  const [radius, setRadius] = useState(defaultValue.radius);
  const [overallLimit, setOverallLimit] = useState(defaultValue.overallLimit);
  const [periodicLimit, setPeriodicLimit] = useState(
    defaultValue.periodicLimit,
  );
  const [periodicLimitDays, setPeriodicLimitDays] = useState(
    defaultValue.periodicLimitDays,
  );
  const [perHeadLimit, setPerHeadLimit] = useState(defaultValue.perHeadLimit);
  const [perHeadPeriodicLimit, setPerHeadPeriodicLimit] = useState(
    defaultValue.perHeadPeriodicLimit,
  );
  const [perHeadPeriodicLimitDays, setPerHeadPeriodicLimitDays] = useState(
    defaultValue.perHeadPeriodicLimitDays,
  );

  const vals = {
    latitude,
    longitude,
    radius,
    overallLimit,
    periodicLimit,
    periodicLimitDays,
    perHeadLimit,
    perHeadPeriodicLimit,
    perHeadPeriodicLimitDays,
  };
  const hasValsChanged = useCompare(vals);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));
  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };
  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.latitude.name}`, errorFields);
    firstError(`${EarningRuleErrorHandleField.longitude.name}`, errorFields);
    firstError(`${EarningRuleErrorHandleField.radius.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">GPS CHECK-IN</label>

      <CustomNumberInput
        title="Latitude"
        setValue={setLatitude}
        defaultValue={latitude}
        errorId={`${EarningRuleErrorHandleField.latitude.name}`}
        error={isError('latitude')}
        errorMessage={`${EarningRuleErrorHandleField.latitude.message}`}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.latitudeLimit.name}`}
        error={isError('latitudeLimit')}
        message={`${EarningRuleErrorHandleField.latitudeLimit.message}`}
      />

      <CustomNumberInput
        title="Longitude"
        setValue={setLongitude}
        defaultValue={longitude}
        errorId={`${EarningRuleErrorHandleField.longitude.name}`}
        error={isError('longitude')}
        errorMessage={`${EarningRuleErrorHandleField.longitude.message}`}
      />
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.longitudeLimit.name}`}
        error={isError('longitudeLimit')}
        message={`${EarningRuleErrorHandleField.longitudeLimit.message}`}
      />

      <CustomNumberInputWithUnit
        title="Radius"
        setValue={setRadius}
        unit="meters"
        defaultValue={radius}
        errorId={`${EarningRuleErrorHandleField.radius.name}`}
        error={isError('radius')}
        errorMessage={`${EarningRuleErrorHandleField.radius.message}`}
      />

      <CustomTitleLabel title="Overall limit (optional)" />
      <label className="tips-message">
        Maximum number of times to do GPS check-in per campaign.
      </label>
      <CustomNumberInputWithUnit
        defaultValue={overallLimit}
        setValue={setOverallLimit}
        unit="times"
      />

      <CustomTitleLabel title="Periodic limit (optional)" />
      <label className="tips-message">
        Maximum number of times to do GPS check-in per period.
      </label>
      <CustomNumberMutipleInputWithUnit
        firstDefaultValue={periodicLimit}
        setFirstValue={setPeriodicLimit}
        secondDefaultValue={periodicLimitDays}
        setSecondValue={setPeriodicLimitDays}
        per="times per"
        unit="days"
      />

      <CustomTitleLabel title="Per head limit (optional)" />
      <label className="tips-message">
        Maximum number of times to do GPS check-in per customer.
      </label>
      <CustomNumberInputWithUnit
        defaultValue={perHeadLimit}
        setValue={setPerHeadLimit}
        unit="times"
      />

      <CustomTitleLabel title="Per head periodic limit (optional)" />
      <label className="tips-message">
        Maximum number of times to do GPS check-in per customer per period.
      </label>
      <CustomNumberMutipleInputWithUnit
        firstDefaultValue={perHeadPeriodicLimit}
        setFirstValue={setPerHeadPeriodicLimit}
        secondDefaultValue={perHeadPeriodicLimitDays}
        setSecondValue={setPerHeadPeriodicLimitDays}
        per="times per"
        unit="days"
      />
    </>
  );
}

export default GPSCheckInSection;
