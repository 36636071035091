import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import CustomInputWithMaxLength from '../../base/CustomInputWithMaxLength';
import i18n from '../../../I18n';
import { CustomNumberInput, CustomNumberInputWithUnit } from '../../earning/CustomBaseComponments';
import '../../base/CustomInputWithMaxLength.scss'
import { CampaignErrorHandleField, firstError, isShowError, isShowErrorGroup } from './CreateCampaignHandleError';
import ErrorFieldMessageGroup from '../../base/ErrorFieldMessageGroup';

function LocationSection(props) {
    const { language, onSubmit, onSubmitAction } = props;

    const {
        location,
        locationId,
        locationPk,
        errorFields,
        locationName,
    } = useSelector((state) => ({
        location: state.createCampaign.campaign.location,
        locationId: state.createCampaign.campaign.translations[language]?.location?.id,
        locationPk: state.createCampaign.campaign.translations[language]?.location?.pk,
        locationName: state.createCampaign.campaign.translations[language]?.location?.name,
        errorFields: state.createCampaign.errorFields,
    }));

    const fields = useMemo(() => ['locationName', 'locationLongitude', 'locationLongitudeLimitBeforeDot', 'locationLongitudeLimitAfterDot', 'locationLatitude', 'locationLatitudeLimitBeforeDot', 'locationLatitudeLimitAfterDot', 'locationRadius'], [])
    const [name, setName] = useState(locationName ?? '');
    const [longitude, setLongitude] = useState(location?.longitude);
    const [latitude, setLatitude] = useState(location?.latitude);
    const [radius, setRadius] = useState(location?.radius);

    useEffect(() => {
        if (onSubmit && onSubmitAction) {
            let location = {
                name,
                latitude,
                longitude,
                radius
            };
            if (locationId) {
                location['id'] = locationId
                location['pk'] = locationPk
            }
            onSubmitAction({ location });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSubmit]);

    const errorAttribute = (name) => {
        return {
            errorId: name,
            error: isShowError(
                CampaignErrorHandleField[name].name,
                errorFields,
                language,
            ),
            errorMessage: CampaignErrorHandleField?.[name].message
        };
    }

    useEffect(() => {
        fields.map((fieldName) => firstError(
            `${CampaignErrorHandleField?.[fieldName].name}-${language}`,
            errorFields,
            language,
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorFields]);

    return (
        <>
            <CustomInputWithMaxLength
                sectionTitle={i18n.t("campaign.location_name", { locale: language })}
                value={name}
                valueChange={(value) => setName(value)}
                type="text"
                {...errorAttribute('locationName')}
                tips={language === 'en' ? i18n.t("campaign.location_name_tips", { locale: language }) : null}
            />
            {language === 'en' ?
                <>
                    <CustomNumberInput
                        title={i18n.t("campaign.location_longitude", { locale: language })}
                        setValue={setLongitude}
                        defaultValue={longitude}
                        customInputClassName={`custom-input-with-max-length-with-count`}
                        error={isShowErrorGroup(
                            [CampaignErrorHandleField.locationLongitude, CampaignErrorHandleField.locationLongitudeLimitBeforeDot, CampaignErrorHandleField.locationLongitudeLimitAfterDot],
                            errorFields,
                        )}
                    />
                    <ErrorFieldMessageGroup errorFields={errorFields} isShowError={CampaignErrorHandleField.isShowError}
                        errors={[CampaignErrorHandleField.locationLongitude, CampaignErrorHandleField.locationLongitudeLimitBeforeDot, CampaignErrorHandleField.locationLongitudeLimitAfterDot]} />
                    <CustomNumberInput
                        title={i18n.t("campaign.location_latitude", { locale: language })}
                        setValue={setLatitude}
                        defaultValue={latitude}
                        customInputClassName={`custom-input-with-max-length-with-count`}
                        error={isShowErrorGroup(
                            [CampaignErrorHandleField.locationLatitude, CampaignErrorHandleField.locationLatitudeLimitBeforeDot, CampaignErrorHandleField.locationLatitudeLimitAfterDot],
                            errorFields,
                        )}
                    />
                    <ErrorFieldMessageGroup errorFields={errorFields} isShowError={CampaignErrorHandleField.isShowError}
                        errors={[CampaignErrorHandleField.locationLatitude, CampaignErrorHandleField.locationLatitudeLimitBeforeDot, CampaignErrorHandleField.locationLatitudeLimitAfterDot]} />
                    <CustomNumberInputWithUnit
                        title={i18n.t("campaign.location_radius", { locale: language })}
                        setValue={setRadius}
                        defaultValue={radius}
                        unit="m"
                        {...errorAttribute('locationRadius')}
                    />
                </>
                : null
            }
        </>
    );
}

export default LocationSection;
