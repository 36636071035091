import React, { useEffect, useState } from 'react';
import './WhatToDoNextList.scss';
import queryString from 'query-string';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import { useSelector, useDispatch } from 'react-redux';
import BaseListContainer from '../../base/BaseListContainer';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import {
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import { createAction } from '../../../utils';

function WhatToDoNextList() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    whatToDoNextList,
    pageInfo,
    totalCount,
    displayFields,
    totalPage,
  } = useSelector((state) => ({
    whatToDoNextList: state.whatToDoNextList.whatToDoNextList,
    pageInfo: state.whatToDoNextList.pageInfo,
    totalCount: state.whatToDoNextList.totalCount,
    displayFields: state.whatToDoNextList.listDisplayFields,
    totalPage: state.whatToDoNextList.totalPage,
  }));


  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    console.log('@searchQuery', searchQuery)
    const sort = searchQuery['sort'];
    const stringRank = searchQuery['rank'] || 'true';
    const stringPage = searchQuery['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('whatToDoNextList/getWhatToDoNextList')({
        ...searchQuery,
        page,
        rank,
        sort,
      }),
    );
  }, [dispatch, location, queryString]);

  const updateWhatToDoNextDisplayPriority = (item, order) => {
    if (item.displayPriority === order) {
      return
    }

    dispatch({
      type: 'whatToDoNextList/updateWhatToDoNextDisplayPriority',
      payload: {
        id: item.pk,
        displayPriority: order,
        afterAction: (success) => history.push(location)
      }
    })
  }

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'whatToDoNextList'}
              extraPathname={'what_to_do_next'}
              permissionGroup={PermissionCodes.whatToDoNext}
              dataList={whatToDoNextList}
              totalPage={totalPage}
              sortable
              onSortEnd={(oldIndex, newIndex) => {
                console.log('@sort', oldIndex)
                // const newOrderObject = dataList[newIndex];
                // const movedItem = dataList[oldIndex];
                // orderChange(movedItem, newOrderObject.order);
              }}
              onOrderChangedByEdit={(item, newOrder) => {
                console.log('@item', item)
                console.log('@order', newOrder)

                updateWhatToDoNextDisplayPriority(item, newOrder)
              }}
              deleteInfo={{
                data: [],
                title: 'message',
                relatedName: 'channels',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.MESSAGE,
              }}
              actions={['Edit', 'Detail']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Create a message to get in touch with  your customers. You can send a message through different channels.',
          title: 'What to do next',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
    </>
  );
}

export default WhatToDoNextList;
