import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
    FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { useSelector } from 'react-redux';
import { productTypes } from '../../../models/BarcodeModel'
import { BarcodeType } from '../../../config/CustomEnums';

function Filter({ backAction = () => { } }) {
    const location = useLocation();
    const brandList = useSelector((state) => state.brand.brandList);
    const parsedSearch = queryString.parse(location.search);
    const searchType = parsedSearch['type'] || '';
    const searchBrands = parsedSearch['brand'] || '';
    const searchProducts = parsedSearch['product_type'] || '';
    const searchStatus = parsedSearch['status'] || '';

    const [type, setType] = useState(searchType);
    const [brands, setBrands] = useState(ArrayStringData(searchBrands));
    const [selectedTypes, setProductTypes] = useState(ArrayStringData(searchProducts));
    const [selectedStatus, setSelectedStatus] = useState(searchStatus);

    const content = [
        {
            title: 'Type',
            data: [
              { pk: BarcodeType.partner, name: 'Partner Barcode' },
              { pk: BarcodeType.nonPartner, name: 'Non-Partner Barcode' },
            ],
            value: type,
            setValue: setType,
            componment: FilterTypeEnum.singleChoice,
            className: 'fit-content-height-area',
          },
        {
            title: 'Product Type',
            data: productTypes,
            value: selectedTypes,
            setValue: setProductTypes,
            componment: FilterTypeEnum.choice,
        },
        {
            title: 'Brand',
            data: brandList,
            value: brands,
            setValue: setBrands,
            componment: FilterTypeEnum.choice,
        },
        {
            title: 'Status',
            data: [
              { name: 'Unpublished', pk: 'false' },
              { name: 'Published', pk: 'true' },
            ],
            value: selectedStatus,
            setValue: setSelectedStatus,
            componment: FilterTypeEnum.singleChoiceSegment,
          },
    ];

    return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
