import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignErrorHandleField } from './CreateCampaignHandleError';
import PeriodSection from './PeriodSection';
import { compareDate, getCurrentDay } from '../../../utils/TimeFormatUtil';
import {
  campaignLimitDate,
  campaignAvaliableClue,
  disableSwitchToAllPeriod,
} from './CreateCampaignUtil';
import { EarningRuleType } from '../../../config/CustomEnums';

function ActivePeriodSection(props) {
  const {
    birthdayEndDate,
    isAlwaysActivePeriod,
    activeStartDate,
    activeEndDate,
    errorFields,
    birthdayStartDate,
    earningRuleType,
    linkedEarningRules,
  } = useSelector((state) => ({
    birthdayStartDate:
      state.createCampaign.campaign.linkedEarningRules
        ?.birthdayTypePeriodStartDate,
    isAlwaysActivePeriod: state.createCampaign.campaign.isAlwaysActivePeriod,
    activeStartDate: state.createCampaign.campaign.activeStartDate,
    activeEndDate: state.createCampaign.campaign.activeEndDate,
    errorFields: state.createCampaign.errorFields,
    birthdayEndDate:
      state.createCampaign.campaign.linkedEarningRules
        ?.birthdayTypePeriodEndDate,
    earningRuleType: state.createCampaign.campaign.linkedEarningRules?.type,
    linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  }));
  const dispatch = useDispatch();
  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const defaultActive = () => {
    if (birthdayStartDate && birthdayEndDate) {
      return false;
    }
    return isAlwaysActivePeriod;
  };
  const [alwaysActive, setAlwaysActive] = useState(defaultActive());
  const [startDate, setStartDate] = useState(
    compareDate(activeStartDate, birthdayStartDate, false),
  );
  const [endDate, setEndDate] = useState(
    compareDate(activeEndDate, birthdayEndDate, true),
  );

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        isAlwaysActivePeriod: alwaysActive,
        activeStartDate: startDate,
        activeEndDate: endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  const activePeriodSwitch = (isChecked) => {
    dispatch({
      type: 'createCampaign/setFieldToSession',
      payload: { isAlwaysActivePeriod: isChecked },
    });
    setAlwaysActive(isChecked);
  };

  const startDateChange = (date) => {
    dispatch({
      type: 'createCampaign/setFieldToSession',
      payload: { activeStartDate: date },
    });

    setStartDate(date);
  };

  const endDateChange = (date) => {
    dispatch({
      type: 'createCampaign/setFieldToSession',
      payload: { activeEndDate: date },
    });
    setEndDate(date);
  };

  const getStartDate = () => {
    const startDateString =
      typeof startDate === 'string' ? startDate : startDate?.toDateString();
    if (
      (!startDate || startDateString === new Date().toDateString()) &&
      earningRuleType === EarningRuleType.habit
    ) {
      const now = new Date();

      if (
        linkedEarningRules.habitTypePeriod === EarningRuleType.weeklyAchievement
      ) {
        if (now.getDay() !== 0) {
          const newDate = now.getDate() + 7 - now.getDay();
          now.setDate(newDate);
        }
      }

      if (
        linkedEarningRules.habitTypePeriod ===
        EarningRuleType.monthlyAchievement
      ) {
        if (now.getDate() !== 1) {
          now.setMonth(now.getMonth() + 1);
          now.setDate(1);
        }
      }
      now.setHours(0, 0, 0, 0);

      return now;
    }

    return startDate;
  };

  const getEndDate = () => {
    const endDateString =
      typeof endDate === 'string' ? endDate : endDate?.toDateString();

    if (
      (!endDate || endDateString === new Date().toDateString()) &&
      earningRuleType === EarningRuleType.habit
    ) {
      const now = new Date();
      if (
        linkedEarningRules.habitTypePeriod === EarningRuleType.weeklyAchievement
      ) {
        now.setDate(now.getDate() + 6 - now.getDay() + 7);
      }

      if (
        linkedEarningRules.habitTypePeriod ===
        EarningRuleType.monthlyAchievement
      ) {
        now.setMonth(now.getMonth() + 2);
        now.setDate(0);
      }
      now.setHours(23, 59, 59, 999);
      // endDateChange(now);
      return now;
    }
    return endDate;
  };

  return (
    <PeriodSection
      disabled={disableSwitchToAllPeriod(
        earningRuleType,
        birthdayStartDate,
        birthdayEndDate,
      )}
      title="Active Period"
      endDateChange={endDateChange}
      maxDate={campaignLimitDate(earningRuleType, birthdayStartDate)}
      minDate={campaignLimitDate(earningRuleType, birthdayEndDate)}
      titleDescription={campaignAvaliableClue(earningRuleType)}
      switchButtonTitle="It is an all-time active campaign"
      isAlwaysPeriod={alwaysActive}
      switchOnChange={activePeriodSwitch}
      startDate={getStartDate()}
      startDateChange={startDateChange}
      filterStartDate={(date) => {
        if (earningRuleType === EarningRuleType.habit) {
          if (
            linkedEarningRules.habitTypePeriod ===
            EarningRuleType.weeklyAchievement
          )
            return date.getDay() === 0;
          if (
            linkedEarningRules.habitTypePeriod ===
            EarningRuleType.monthlyAchievement
          )
            return date.getDate() === 1;
        }
        return true;
      }}
      endDate={getEndDate()}
      filterEndDate={(date) => {
        if (earningRuleType === EarningRuleType.habit) {
          if (
            linkedEarningRules.habitTypePeriod ===
            EarningRuleType.weeklyAchievement
          )
            return date.getDay() === 6;
          if (
            linkedEarningRules.habitTypePeriod ===
            EarningRuleType.monthlyAchievement
          ) {
            date.setDate(date.getDate() + 1);
            return date.getDate() === 1;
          }
        }
        return true;
      }}
      hideTime={earningRuleType === EarningRuleType.habit}
      errorFields={errorFields}
      fieldName={CampaignErrorHandleField.activeEndDate.name}
      fieldErrorMessage={CampaignErrorHandleField.activeEndDate.message}
    />
  );
}

export default ActivePeriodSection;
