import React from 'react';
import CreateCampaign from '../containers/engagement/campaign/CreateCampaign';
import CreateCoupon from '../containers/engagement/coupon/CreateCoupon';
import Dashboard from '../containers/dashboard/Dashboard';
import CampaignItemOverview from '../containers/engagement/campaign/CampaignItemOverview';
import CouponSetDetail from '../containers/engagement/coupon/CouponSetDetail';
import SingleCouponDetail from '../containers/engagement/coupon/SingleCouponDetail';
import CreateEarningRule from '../containers/engagement/earningRule/CreateEarningRule';
import CreateSegment from '../containers/customers/segments/CreateSegment';
import SegmentDetail from '../containers/customers/segments/SegmentDetail';
import CustomerOverviewDetail from '../containers/customers/customer/CustomerOverviewDetail';
import CustomerEditForm from '../containers/customers/customer/CustomerEditForm';
import PointTransactionList from '../containers/record/pointRecords/PointTransactionList';
import CreatePointRecord from '../containers/record/pointRecords/CreatePointRecord';
import PointTransactionOverview from '../containers/record/pointRecords/PointTransactionOverview';
import CouponTransaction from '../containers/record/couponRecords/CouponTransaction';
import CouponTransactionOverview from '../containers/record/couponRecords/CouponTransactionOverview';
import TransactionOverView from '../containers/record/records/TransactionOverview';
import CreateTransaction from '../containers/record/records/CreateTransaction';
import CreateAdminGroup from '../containers/admin/adminGroups/CreateAdminGroup';
import CreateAdmin from '../containers/admin/admins/CreateAdmin';
import CreateBrand from '../containers/merchants/brands/CreateBrand';
import CreateMessage from '../containers/engagement/message/CreateMessage';
import MessageOverview from '../containers/engagement/message/MessageOverview';
import AdminDetail from '../containers/admin/admins/AdminDetail';
import CampaignCategoryDetail from '../containers/engagement/campaignCategory/CampaignCategoryDetail';
import CreateCampaignCategory from '../containers/engagement/campaignCategory/CreateCampaignCategory';
import StoreDetail from '../containers/merchants/stores/StoreDetail';
import CreateStore from '../containers/merchants/stores/CreateStore';
import StoreCategoryDetail from '../containers/merchants/storeCategory/StoreCategoryDetail';
import CreateStoreCategory from '../containers/merchants/storeCategory/CreateStoreCategory';
import CreateCustomerGroup from '../containers/customers/customerGroup/CreateCustomerGroup';
import { PermissionCodes } from './PermissionCodes';
import CreateBanner from '../containers/homeManage/banners/CreateBanner';
import FeaturedCampaignDetail from '../containers/homeManage/featuredCampaigns/FeaturedCampaignDetail';
import FeedbackDetail from '../containers/merchants/storeFeedback/FeedbackDetail';
import AppVersionList from '../containers/others/AppVersion/AppVersionList';
import CreateAppVersion from '../containers/others/AppVersion/CreateAppVersion';

import GuideImageList from '../containers/others/Guide/GuideImageList';
import GuideImageEdit from '../containers/others/Guide/GuideImageEdit';

// import CreateAfcDataRecord from '../containers/others/AppVersion/CreateAfcDataRecord';
import AfcDataRecordDetail from '../containers/record/afcDataRecords/AfcDataRecordDetail';
import CreateOrUpdateAfcDataRecord from '../containers/record/afcDataRecords/CreateOrUpdateAfcDataRecord';
import AfcCronjobRecordDetail from '../containers/record/afcCronjobRecords/AfcCronjobRecordDetail';
import AfcAccountBindingRecordDetail from '../containers/record/afcAccountBindingRecords/AfcAccountBindingRecordDetail';
import EditAfcSettings from '../containers/others/afcSettings/AfcSettingEdit';
import CreateMission from '../containers/engagement/mission/CreateMission';
import MissionDetail from '../containers/engagement/mission/MissionDetail';
import CreateGreetingCard from '../containers/homeManage/greetingCard/CreateGreetingCard';
import GreetingCardDetail from '../containers/homeManage/greetingCard/GreetingCardDetail';
import CreateGreetingSection from '../containers/homeManage/greetingSection/CreateGreetingSection';
import GreetingSectionDetail from '../containers/homeManage/greetingSection/GreetingSectionDetail';
// import AfcDataRecordEdit from '../containers/others/Guide/AfcDataRecordEdit';
import CommunityDetail from '../containers/merchants/community/CommunityDetail';
import CommunityChange from '../containers/merchants/community/CommunityChange';
import NewsFeedChange from '../containers/engagement/newsFeed/NewsFeedChange';
import CreateBarcode from '../containers/engagement/barcode/CreateBarcode';
import EditNewBarcode from '../containers/barcode/EditNewBarcode';
import CreateSplashAd from '../containers/engagement/splashAd/CreateSplashAd';
import RewardCodeDetail from '../containers/engagement/rewardCode/RewardCodeDetail';
import CreateRewardCode from '../containers/engagement/rewardCode/CreateRewardCode';

import CreateWhatToDoNext from '../containers/homeManage/whatToDoNext/CreateWhatToDoNext'
import WhatToDoNextOverview from '../containers/homeManage/whatToDoNext/WhatToDoNextOverview'

export const pageRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    main: () => <Dashboard />,
  },
  {
    path: ['/campaigns/create', '/campaigns/:id/edit', '/what_is_new/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign />,
  },
  {
    path: ['/campaigns/:id/overview', '/what_is_new/:id/overview'],
    exact: true,
    name: 'Overview',
    main: () => <CampaignItemOverview />,
  },
  {
    path: ['/coupons/create', '/coupons/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCouponTemplate,
    main: () => <CreateCoupon />,
  },
  {
    path: ['/coupons/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTemplate,
    main: () => <CouponSetDetail />,
  },
  {
    path: [
      '/coupons/couponSet/:couponSetId/singleCoupon/:singleCouponId',
      '/coupons/:id/:singleCouponId/edit',
    ],
    exact: true,
    codename: PermissionCodes.viewCoupon,
    main: () => <SingleCouponDetail />,
  },
  {
    name: 'Create Earning Rule',
    path: '/earns/create',
    exact: true,
    codename: PermissionCodes.addEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Edit Earning Rule',
    path: ['/earns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Create Segment',
    path: ['/segments/create/'],
    exact: true,
    codename: PermissionCodes.addSegment,
    main: () => <CreateSegment />,
  },
  {
    path: ['/segments/:id/'],
    exact: true,
    codename: PermissionCodes.viewSegment,
    main: () => <SegmentDetail />,
  },
  {
    path: ['/segments/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeSegment,
    main: () => <CreateSegment />,
  },

  {
    path: ['/customers/:id/'],
    exact: true,
    codename: PermissionCodes.viewCustomer,
    main: () => <CustomerOverviewDetail />,
  },
  {
    path: ['/customers/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomer,
    main: () => <CustomerEditForm />,
  },
  {
    name: 'Update Point record',
    path: ['/point_records/create', '/point_records/:id/edit'],
    exact: true,
    codename: PermissionCodes.changePointtransaction,
    main: () => <CreatePointRecord />,
  },
  {
    name: 'View Point record',
    path: ['/point_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewPointtransaction,
    main: () => <PointTransactionOverview />,
  },
  {
    name: 'View Coupon records',
    path: ['/coupon_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTransaction,
    main: () => <CouponTransactionOverview />,
  },
  {
    name: 'Create Transaction',
    path: ['/transactions/create'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'Edit Transaction',
    path: ['/transactions/:id/edit'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'View transaction',
    path: ['/transactions/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <TransactionOverView />,
  },
  {
    name: 'Create Administrators group',
    path: ['/administrator_groups/create'],
    exact: true,
    codename: PermissionCodes.addAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    path: ['/administrator_groups/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    name: 'Create Administrators',
    path: ['/administrators/create'],
    exact: true,
    codename: PermissionCodes.addAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    path: ['/administrators/:id/'],
    exact: true,
    codename: PermissionCodes.viewAdministrator,
    main: () => <AdminDetail />,
  },

  {
    path: ['/administrators/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    name: 'Brand',
    path: ['/brands/create'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    path: ['/brands/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    name: 'Create Message',
    path: ['/messages/create'],
    exact: true,
    codename: PermissionCodes.addMessage,
    main: () => <CreateMessage />,
  },
  {
    path: ['/messages/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeMessage,
    main: () => <CreateMessage />,
  },
  {
    name: 'View Message',
    path: ['/messages/:id/'],
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <MessageOverview />,
  },
  {
    name: 'Create campaign category',
    path: ['/campaign_category/create'],
    exact: true,
    codename: PermissionCodes.addCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewCampaignCategory,
    main: () => <CampaignCategoryDetail />,
  },
  {
    name: 'Create mission',
    path: ['/mission/create'],
    exact: true,
    codename: PermissionCodes.changeMission,
    main: () => <CreateMission />,
  },
  {
    path: ['/mission/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeMission,
    main: () => <CreateMission />,
  },
  {
    path: ['/mission/:id/'],
    exact: true,
    codename: PermissionCodes.viewMission,
    main: () => <MissionDetail />,
  },

  {
    path: ['/barcode_validate/:id/'],
    exact: true,
    codename: PermissionCodes.changeSubmittednewbarcode,
    main: () => <EditNewBarcode />,
  },

  {
    name: 'Create store',
    path: ['/stores/create'],
    exact: true,
    codename: PermissionCodes.addStore,
    main: () => <CreateStore />,
  },
  {
    path: ['/stores/:id/'],
    exact: true,
    codename: PermissionCodes.viewStore,
    main: () => <StoreDetail />,
  },
  {
    path: ['/stores/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStore,
    main: () => <CreateStore />,
  },
  {
    name: 'Create store category',
    path: ['/store_category/create'],
    exact: true,
    codename: PermissionCodes.addStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    path: ['/store_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewSorecategory,
    main: () => <StoreCategoryDetail />,
  },
  {
    path: ['/store_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    name: 'Create Customer Group',
    path: ['/customer_group/create'],
    exact: true,
    codename: PermissionCodes.addCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    path: ['/customer_group/:id/', '/customer_group/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    path: ['/banners/create', '/banners/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/discover_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <FeaturedCampaignDetail />,
  },
  {
    name: 'Create Greeting Card',
    path: ['/greeting_card/create'],
    exact: true,
    codename: PermissionCodes.greetingCard,
    main: () => <CreateGreetingCard />,
  },
  {
    path: ['/greeting_card/:id/edit/'],
    exact: true,
    codename: PermissionCodes.greetingCard,
    main: () => <CreateGreetingCard />,
  },
  {
    path: ['/greeting_card/:id/'],
    exact: true,
    codename: PermissionCodes.greetingCard,
    main: () => <GreetingCardDetail />,
  },
  {
    name: 'Create Greeting Section',
    path: ['/greeting_section/create'],
    exact: true,
    codename: PermissionCodes.greetingSection,
    main: () => <CreateGreetingSection />,
  },
  {
    path: ['/greeting_section/:id/edit/'],
    exact: true,
    codename: PermissionCodes.greetingSection,
    main: () => <CreateGreetingSection />,
  },
  {
    path: ['/greeting_section/:id/'],
    exact: true,
    codename: PermissionCodes.greetingSection,
    main: () => <GreetingSectionDetail />,
  },
  {
    path: ['/store_feedback/:id/'],
    exact: true,
    name: 'Overview',
    main: () => <FeedbackDetail />,
  },
  {
    path: ['/app_version/'],
    exact: true,
    codename: PermissionCodes.viewAppVersion,
    main: () => <AppVersionList />,
  },
  {
    path: ['/app_version/create'],
    exact: true,
    codename: PermissionCodes.changeAppVersion,
    main: () => <CreateAppVersion />,
  },
  {
    path: ['/afc_data_record/create'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <CreateOrUpdateAfcDataRecord />,
  },
  {
    path: ['/afc_data_record/:id/edit'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <CreateOrUpdateAfcDataRecord />,
  },
  {
    path: ['/afc_data_record/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <AfcDataRecordDetail />,
  },
  {
    path: ['/afc_cronjob_record/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <AfcCronjobRecordDetail />,
  },
  {
    path: ['/afc_account_binding_record/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <AfcAccountBindingRecordDetail />,
  },

  {
    path: ['/guide_image/'],
    exact: true,
    codename: PermissionCodes.viewGuidemodel,
    main: () => <GuideImageList />,
  },
  {
    path: ['/guide_image/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeGuidemodel,
    main: () => <GuideImageEdit />,
  },
  {
    path: ['/afc_settings/edit'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <EditAfcSettings />,
  },
  {
    path: ['/communities/create', '/communities/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCommunity,
    main: () => <CommunityChange />,
  },
  {
    path: ['/communities/:id/'],
    exact: true,
    codename: PermissionCodes.viewCommunity,
    main: () => <CommunityDetail />,
  },
  {
    path: ['/communities/:communityId/target/create', '/communities/:communityId/target/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeMission,
    main: () => <CreateMission />,
  },
  {
    path: ['/news_feeds/create', '/news_feeds/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeNewsFeed,
    main: () => <NewsFeedChange />,
  },
  {
    path: ['/barcodes/create', '/barcodes/:id/edit/', '/barcodes/:id/'],
    exact: true,
    codename: PermissionCodes.changeBarcode,
    main: () => <CreateBarcode />,
  },
  {
    path: ['/splash_ad/create', '/splash_ad/:id/edit/', '/splash_ad/:id/'],
    exact: true,
    codename: PermissionCodes.changeSplashAd,
    main: () => <CreateSplashAd />,
  },
  {
    path: ['/reward_code/create', '/reward_code/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeRewardCode,
    main: () => <CreateRewardCode />,
  },
  {
    path: ['/reward_code/:id/'],
    exact: true,
    codename: PermissionCodes.viewRewardCode,
    main: () => <RewardCodeDetail />,
  },
  {
    path: ['/what_to_do_next/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeWhatToDoNext,
    main: () => <CreateWhatToDoNext />,
  },
  {
    name: 'Preview',
    path: ['/what_to_do_next/:id/'],
    exact: true,
    codename: PermissionCodes.viewWhatToDoNext,
    main: () => <WhatToDoNextOverview />,
  },
];
