import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, connect } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useLocation, useHistory } from 'react-router-dom';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import {
  PublishTagType,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import Filter from './Filter';

const RewardCodeList = ({
  rewardCodeList,
  listDisplayFields,
  checkedList,
  pageInfo,
  totalCount,
  totalPage,
}) => {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const deletePks = deleteItems?.map(item => item.pk);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);
  let currentPage = parseInt(searchQuery?.page);
  if (isNaN(currentPage)) {
    currentPage = 1;
  }

  const searchKey = searchQuery?.search;
  const fetchData = () => {
    dispatch({
      type: 'rewardCode/getCurrentPageRewardCodes',
      payload: {
        ...searchQuery,
        page: currentPage,
        searchKey,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: 'brand/getCurrentPageBrands',
      payload: { isAll: true }
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [location]);

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeRewardCode,
    },
  ];

  const tabs = [
    {
      name: 'List Of Registration Code',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={rewardCodeList}
              totalPage={totalPage}
              currentPage={currentPage}
              model={'rewardCode'}
              permissionGroup={PermissionCodes.rewardCode}
              deleteInfo={{
                data: [],
                title: 'Registration code',
                relatedName: '',
                onComfirm: {},
                relatedSections: null,
              }}
              actions={['Detail', 'Edit', 'Delete']}
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`reward_code/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeRewardCode,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.changeRewardCode,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={[
                      {
                        name:
                          item.status === PublishTagType.published
                            ? 'Unpublish'
                            : 'Publish',
                        action: () => {
                          dispatch({
                            type: 'rewardCode/publishOrUnpublishRewardCode',
                            payload: {
                              id: item.pk,
                              publish: item.status !== PublishTagType.published,
                              afterAction: fetchData,
                            },
                          });
                        },
                        requires: PermissionCodes.changeRewardCode,
                      },
                    ]}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];
  const buttons = [
    <AuthButton
      title="Create Registration Code"
      action={() => {
        history.push('reward_code/create');
      }}
      requires={PermissionCodes.changeRewardCode}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: "",
          title: NavBarNames.rewardCode,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="Registration Code"
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch({
            type: 'rewardCode/deleteRewardCode',
            payload: {
              deletePks,
              afterAction: fetchData,
            },
          });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  listDisplayFields: state.rewardCode.listDisplayFields,
  pageInfo: state.rewardCode.pageInfo,
  totalCount: state.rewardCode.totalCount,
  totalPage: state.rewardCode.totalPage,
  rewardCodeList: state.rewardCode.currentPageRewardCodeList,
  checkedList: state.rewardCode.checkedList,
});

export default connect(mapStateToProps)(RewardCodeList);
