import { BaseHelper } from './BaseGraphQLAPIHelper';

const CONDITION_GROUPS = `
conditionGroups(orderBy: "displayOrder") {
  edges {
      node {
          pk
          id
          displayOrder
          genderCharacteristic {
              id
              pk
              lookup
              genders(first: 10) {
                  edges {
                      node {
                          id
                          pk
                          name
                      }
                  }
              }
              displayOrder
          }
          monthOfBirthCharacteristic {
              id
              pk
              lookup
              months(first: 10) {
                  edges {
                      node {
                          id
                          pk
                          name
                      }
                  }
              }
              displayOrder
          }
          ageCharacteristic {
              id
              pk
              lookup
              age
              minimumAge
              maximumAge
              displayOrder
          }
          signUpAnniversaryCharacteristic {
              id
              pk
              lookup
              numberOfYears
              minimumNumberOfYears
              maximumNumberOfYears
              displayOrder
          }
          countryCodeCharacteristic {
              id
              pk
              lookup
              countryCodes(first: 10) {
                  edges {
                      node {
                          id
                          pk
                          name
                      }
                  }
              }
              displayOrder
          }
          hasRecentPurchaseCharacteristic {
              id
              pk
              inXNumberOfDays
              displayOrder
          }
          numberOfTransactionsCharacteristic {
              id
              pk
              period
              quantity
              displayOrder
          }
          totalSpendingCharacteristic {
              id
              pk
              lookup
              dollars
              minimumDollars
              maximumDollars
              displayOrder
          }
          averageTransactionValueCharacteristic {
              id
              pk
              lookup
              dollars
              minimumDollars
              maximumDollars
              displayOrder
          }
          levelCharacteristic {
              id
              pk
              levels(first: 10) {
                  edges {
                      node {
                          id
                          pk
                          levelName
                          translations(first: 10) {
                              edges {
                                  node {
                                      levelName
                                      levelBenefitUrl
                                  }
                              }
                          }
                      }
                  }
              }
              displayOrder
          }
          signUpMemberCharacteristic {
              pk
              period
              displayOrder
          }
          signUpReferralCharacteristic {
              pk
              invited
              displayOrder
          }
          lastLoginCharacteristic {
              pk
              period
              displayOrder
          }
          loginTimeCharacteristic {
              pk
              times
              displayOrder
          }
          productCharacteristic {
              pk
              products
              displayOrder
          }
          earningCampaignCharacteristic {
            pk
            behavioralType
            catalogType
            campaign {
                pk
                name
            }
            campaignCategory {
                pk
                name
            }
            numberOfParticipateTimes
            viewCampaignType
            period
            numberOfViewCampaignTimes
            stayPeriodType
            stayPeriod
            displayOrder
          }
          couponCampaignCharacteristic {
              pk
              behavioralType
              catalogType
              campaign {
                  pk
                  name
              }
              campaignCategory {
                  pk
                  name
              }
              numberOfParticipateTimes
              viewCampaignType
              period
              numberOfViewCampaignTimes
              stayPeriodType
              stayPeriod
              displayOrder
          }
          viewCampaignCharacteristic {
              pk
              behavioralType
              catalogType
              campaign {
                  pk
                  name
              }
              campaignCategory {
                  pk
                  name
              }
              numberOfParticipateTimes
              viewCampaignType
              period
              numberOfViewCampaignTimes
              stayPeriodType
              stayPeriod
              displayOrder
          }
          behavioralCampaignCharacteristics {
              edges {
                  node {
                      pk
                      behavioralType
                      catalogType
                      campaign {
                          pk
                          name
                      }
                      campaignCategory {
                          pk
                          name
                      }
                      numberOfParticipateTimes
                      viewCampaignType
                      period
                      numberOfViewCampaignTimes
                      stayPeriodType
                      stayPeriod
                      displayOrder
                  }
              }
          }
      }
  }
}
`;

const SEGMENT_CONTENT = `
customers {
  totalCount
}
${CONDITION_GROUPS}
`;

export const getSegmentList = (afterCursor = '', search) => {
  let order = '-pk';
  if (search.moreSearch?.sort && typeof search.moreSearch?.sort !== "function") {
    order = search.moreSearch?.sort;
  }
  //name: "${search}",
  //   orderBy: "${order}",

  const query = `
{
  segments(${search.type ? 'first: 100,' : 'first: 20,'
    } after: "${afterCursor}",nameIcontains: "${search.search || ''
    }", orderBy: "${order}", dateOfBirthRange: "${search.dateOfBirthRange || ''
    }") {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        pk
        name
        ${search.isSelectorLoad ? '' : CONDITION_GROUPS}
      }
    }
  }
}

  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneSegment = (id) => {
  //   orderBy: "${order}",
  const query = `
    {
      segment(id: "${id}") {
        id
        pk
        name
        relatedCustomersCount
        ${CONDITION_GROUPS}
      }
    }
    `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateSegment = (value) => {
  const createUpdateHeader = value.id ? 'Update' : 'Create';

  const query = `
  mutation ${createUpdateHeader}Segment($input: ${createUpdateHeader}SegmentInput!) {
    ${createUpdateHeader.toLowerCase()}Segment(input: $input) {
      node {
        pk
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: value,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateSegment = (value) => {
  const query = `
  mutation UpdateSegment($input: UpdateSegmentInput!) {
    updateSegment(input: $input) {
      node {
        id
        pk
        name
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: value,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSegments = (ids) => {
  const query = `mutation DeleteSegments($input: DeleteSegmentsInput!) {
    deleteSegments(input: $input) {
      success
    }
  }`;

  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
