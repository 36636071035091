export const CouponErrorFields = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a coupon name.',
  },
  coverPhotoEmpty: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo.',
  },
  endDatetime: {
    name: 'couponTemplateEndDatetime',
    message:
      'The end date & time of a coupon cannot be on or before the start date and time.',
  },
  relativeDay: {
    name: 'couponTemplateRelativeDay',
    message: 'Please provide required days.',
  },
  couponStock: {
    name: 'couponStockField',
    message: 'This field is required',
  },
};
