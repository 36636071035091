import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateGreetingCardError } from '../../models/GreetingCardModel';
import { isShowError } from '../../utils';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

function ActivePeriodSection({
  activeStartDate,
  activeEndDate,
  errorFields,
}) {
  const dispatch = useDispatch();

  const updateGreetingCarddetail = (payload) => {
    dispatch({
      type: 'greetingCard/updateGreetingCardDetail',
      payload,
    });
  };

  return (
    <>
      <label className="create-section-title">ACTIVE PERIOD</label>
      <label className="section-short-description">Theme available time.</label>
      <label className="create-section-label create-section-label-bottom-space">
        Start date
      </label>
      <CustomDateTimeSelect
        disabled={false}
        defaultTime={activeStartDate}
        maxDate={null}
        onTimeChange={(value) => {
          updateGreetingCarddetail({ activeStartDate: value })
        }}
        filterDate={(date) => {
          return true;
        }}
        hideTime={false}
      />
      <label className="create-section-label create-section-label-bottom-space">
        End date
      </label>
      <CustomDateTimeSelect
        error={isShowError(CreateGreetingCardError.activeEndDate.name, errorFields)}
        minDate={null}
        defaultTime={activeEndDate}
        disabled={false}
        onTimeChange={(value) => {
          updateGreetingCarddetail({ activeEndDate: value })
        }}
        filterDate={(date) => {
          return true;
        }}
        hideTime={false}
      />
      <ErrorFieldMessage
        id={CreateGreetingCardError.activeEndDate.name}
        error={isShowError(CreateGreetingCardError.activeEndDate.name, errorFields)}
        message={CreateGreetingCardError.activeEndDate.message}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  activeStartDate: state.greetingCard.greetingCard.activeStartDate,
  activeEndDate: state.greetingCard.greetingCard.activeEndDate,
  errorFields: state.greetingCard.errorFields,
});

export default connect(mapStateToProps)(ActivePeriodSection);
