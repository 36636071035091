import React, { Component } from 'react';
import './CreateCouponStepTwo.scss';
import CouponDateTimeSelector from '../../../components/coupon/couponCreation/CouponDateTimeSelector';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createAction } from '../../../utils';
import { CouponErrorFields } from '../../../components/coupon/couponCreation/CouponHandleError';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import moment from 'moment';

@connect(({ createCoupon }) => ({
  couponTemplate: createCoupon.couponTemplate,
  createCouponTemplateStatus: createCoupon.createCouponTemplateStatus,
}))
class CreateCouponStepTwo extends Component {
  constructor(props) {
    super(props);
    const from = props.location.state?.from;
    this.state = {
      saveButtonClicked: false,
      from: from,
      showPrompt: false,
      fromEdit: props.location.pathname.includes('edit'),
    };
  }

  checkCouponDateValid = () => {
    const startDate = moment(this.props.couponTemplate.startDateTime).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 })
    const endDate = moment(this.props.couponTemplate.endDateTime).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 })
    console.log("startDate:", startDate)
    console.log("endDate:", endDate)
    if (
      this.props.couponTemplate.isAbsolute &&
      startDate.isAfter(endDate)
    ) {
      this.props.dispatch(
        createAction('createCoupon/updateState')({
          errorFields: [CouponErrorFields.endDatetime.name],
        }),
      );
      return false;
    } else if (
      this.props.couponTemplate.isRelative &&
      !this.props.couponTemplate.numberOfDaysToExpireAfterAcquisition
    ) {
      this.props.dispatch(
        createAction('createCoupon/updateState')({
          errorFields: [CouponErrorFields.relativeDay.name],
        }),
      );
      return false;
    }
    return true;
  };

  componentDidUpdate() {
    if (
      this.props.createCouponTemplateStatus === APIStatus.success &&
      this.state.saveButtonClicked
    ) {
      this.props.history.push('/coupons');
    }
  }

  render() {
    return this.props.createCouponTemplateStatus === APIStatus.calling ? (
      <Loading />
    ) : (
      <div className="create-coupon-step-two-container">
        <CouponDateTimeSelector />
        <SaveAndBackButtons
          saveTempText="Save Only"
          saveText="Save and Generate Coupon"
          saveTempAction={() => {
            if (this.checkCouponDateValid()) {
              if (this.state.fromEdit) {
                this.props.dispatch(
                  createAction('createCoupon/updateCouponTemplateInput')({
                    isGenerater: false,
                  }),
                );
              } else {
                this.props.dispatch(
                  createAction('createCoupon/createCouponTemplate')({}),
                );
              }
              this.setState({ saveButtonClicked: true });
            }
          }}
          saveAction={() => {
            if (this.checkCouponDateValid()) {
              if (this.state.fromEdit) {
                this.props.dispatch(
                  createAction('createCoupon/updateCouponTemplateInput')({
                    isGenerater: true,
                  }),
                );
              } else {
                this.props.dispatch(
                  createAction('createCoupon/createCouponTemplate')({
                    isGenerater: true,
                  }),
                );
              }
            }
          }}
          backAction={() => {
            this.props.dispatch(
              createAction('createCoupon/stepChange')({
                data: this.props.couponTemplate,
                step: 1,
                isBack: true,
              }),
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(CreateCouponStepTwo);
