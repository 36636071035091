import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getGuideImages = () => {
  const query = `
    {
        guides(first: 10, orderBy: "-pk") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
          pk
          type
          creationDate
          lastModifiedDate
          }
        }
      }
    }
    `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getGuideImage = (id) => {
  const query = `
  {
    guide(id: "${id}") {
      pk
      type
      receiptPartner {
        edges{
          node{
            pk
            name
            receiptPartner
          }
        }
      }
      oclPartner{
        edges{
          node{
            pk
            name
            oclPartner
          }
        }
      }
      firstTitle
      firstImage
      secondTitle
      secondImage
      creationDate
      lastModifiedDate
      translations {
        edges{
          node{
            pk
            language
            firstTitle
            firstImage
            secondTitle
            secondImage
          }
        }
      }
    }
  }
    `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGuideImage = (values) => {
  const query = `
  mutation UpdateGuide($input: UpdateGuideInput!) {
    updateGuide(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
    `;
    const variables = {
      input: values,
    };
  

  return BaseHelper.callGraphQLAPI({ query, variables });
};
