import React, { Component } from 'react';
import CustomRadios from '../../base/CustomRadios';
import { Row } from 'react-bootstrap';
import './CouponDateTimeSelector.scss';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import { VALID_PERIOD_TYPE } from '../../../models/CreateCouponModel';
import { isShowError } from '../../campaign/campaignCreation/CreateCampaignHandleError';
import { CouponErrorFields } from './CouponHandleError';
import { LanguageConfig } from '../../../config/CustomEnums';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';

@connect(({ createCoupon }) => ({
  couponTemplate: createCoupon.couponTemplate,
  errorFields: createCoupon.errorFields,
}))
class CouponDateTimeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDatetimeLabel:
        props.couponTemplate.validPeriodType || VALID_PERIOD_TYPE.ALL_TIME,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.couponTemplate.validPeriodType !==
      this.props.couponTemplate.validPeriodType
    ) {
      this.setState({
        selectedDatetimeLabel: this.props.couponTemplate.validPeriodType,
      });
    }
  }

  couponDatetimeOptions = [
    { label: 'All the time', value: VALID_PERIOD_TYPE.ALL_TIME },
    { label: 'In a period', value: VALID_PERIOD_TYPE.ABSOLUTE },
    {
      label: 'Numbers of day since acquiring',
      value: VALID_PERIOD_TYPE.RELATIVE,
    },
  ];

  daySinceNowView = () => {
    const inputFieldError = isShowError(
      CouponErrorFields.relativeDay.name,
      this.props.errorFields,
      LanguageConfig.english,
    );
    return (
      <div>
        <label className="create-section-label">Required days</label>
        <div className="d-flex">
          <input
            defaultValue={
              this.props.couponTemplate.numberOfDaysToExpireAfterAcquisition ||
              ''
            }
            type="text"
            className={`day-input-fields ${
              inputFieldError ? 'error-field' : ''
            }`}
            onChange={(e) => {
              this.props.dispatch(
                createAction('createCoupon/updateCouponTemplate')({
                  numberOfDaysToExpireAfterAcquisition: e.target.value,
                }),
              );
            }}
          />
          <label className="days-label">Days</label>
        </div>
        <ErrorFieldMessage
          id={CouponErrorFields.relativeDay.name}
          error={inputFieldError}
          message={CouponErrorFields.relativeDay.message}
        />
      </div>
    );
  };

  periodView = () => {
    let absoluteEffectiveDate = this.props.couponTemplate.absoluteEffectiveDate;
    if (this.props.startDateTime) {
      absoluteEffectiveDate = this.props.couponTemplate.startDateTime;
    }
    let absoluteExpiryDate = this.props.couponTemplate.absoluteExpiryDate;
    if (this.props.couponTemplate.endDateTime) {
      absoluteExpiryDate = this.props.couponTemplate.endDateTime;
    }
    return (
      <div>
        <div className="second-section">
          <label className="create-section-label">Start date</label>
          <CustomDateTimeSelect
            defaultTime={absoluteEffectiveDate}
            onTimeChange={(datetime) => {
              this.props.dispatch(
                createAction('createCoupon/updateCouponTemplate')({
                  startDateTime: datetime,
                }),
              );
            }}
          />
        </div>
        <div className="second-section">
          <label className="create-section-label">End date</label>
          <CustomDateTimeSelect
            defaultTime={absoluteExpiryDate}
            error={isShowError(
              CouponErrorFields.endDatetime.name,
              this.props.errorFields,
              LanguageConfig.english,
            )}
            onTimeChange={(datetime) => {
              this.props.dispatch(
                createAction('createCoupon/updateCouponTemplate')({
                  endDateTime: datetime,
                }),
              );
            }}
            hideTime={true}
          />
          <ErrorFieldMessage
            id={CouponErrorFields.endDatetime.name}
            error={isShowError(
              CouponErrorFields.endDatetime.name,
              this.props.errorFields,
            )}
            message={CouponErrorFields.endDatetime.message}
          />
        </div>
      </div>
    );
  };

  radiosChanged = (value) => {
    switch (value) {
      case VALID_PERIOD_TYPE.ALL_TIME:
        this.props.dispatch(
          createAction('createCoupon/updateCouponTemplate')({
            isAllTime: true,
            isAbsolute: false,
            isRelative: false,
          }),
        );
        break;
      case VALID_PERIOD_TYPE.ABSOLUTE:
        this.props.dispatch(
          createAction('createCoupon/updateCouponTemplate')({
            isAbsolute: true,
            isAllTime: false,
            isRelative: false,
          }),
        );
        break;
      case VALID_PERIOD_TYPE.RELATIVE:
        this.props.dispatch(
          createAction('createCoupon/updateCouponTemplate')({
            isAllTime: false,
            isAbsolute: false,
            isRelative: true,
          }),
        );
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        <label className="create-section-label">Valid period</label>
        <Row className="featured-exclusive-row">
          <CustomRadios
            disable={false}
            options={this.couponDatetimeOptions}
            default={this.state.selectedDatetimeLabel}
            onChange={(value) => {
              this.setState({ selectedDatetimeLabel: value });
              this.props.dispatch(
                createAction('createCoupon/updateCouponTemplate')({
                  validPeriodType: value,
                }),
              );
              this.radiosChanged(value);
            }}
          />
        </Row>
        <div
          className="second-section"
          hidden={
            this.state.selectedDatetimeLabel === VALID_PERIOD_TYPE.RELATIVE
              ? false
              : true
          }
        >
          {this.daySinceNowView()}
        </div>
        <div
          hidden={
            this.state.selectedDatetimeLabel === VALID_PERIOD_TYPE.ABSOLUTE
              ? false
              : true
          }
        >
          {this.periodView()}
        </div>
        <div className="error-field-message-style"></div>
      </div>
    );
  }
}

export default CouponDateTimeSelector;
