import { loading, apiWithResponseHandle } from './LoadingUtil';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  getFileNameFromUrl,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
  convertPKToId,
} from '../utils';
import {
  getHomeBanners,
  updateHomeBanners,
  createHomeBanners,
  getOneHomeBanner,
  deleteHomeBanners,
} from '../services/HomeManageApiHelper';
import {
  BANNER_CONTENT_TYPE,
  PublishTagType,
  APIStatus,
} from '../config/CustomEnums';

export const BANNER_SESSION_KEY = 'BANNER_SESSION_KEY';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILED = 'CREATE_BANNER_FAILED';
export const BANNER_ORDER_LAST = 'BANNER_ORDER_LAST';

export const BannerErrorFields = {
  bannerImage: {
    name: 'bannerImage',
    message: 'Please provide a banner image.',
  },
  order: {
    name: 'order',
    message: 'Please provide a display order.',
  },
  contentType: {
    name: 'contentType',
    message: 'Please provide a content type.',
  },
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Related Campaign/ Product', fieldName: 'name' },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Type', fieldName: 'contentTypeDisplay' },
    {
      displayName: 'Display Order',
      fieldName: 'order',
      orderField: 'displayPriority',
    },
    { displayName: ' Status', fieldName: 'status' },
  ],
  bannerList: [],
  banner: {},
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  errorFields: [],
  createStatus: APIStatus.none,
  formChanged: false,
  loadingStatus: APIStatus.none,
});

const getContentTypeToDisplay = (contentType) => {
  if (contentType === BANNER_CONTENT_TYPE.CAMPAIGN) {
    return 'Campaign';
  }
  if (contentType === BANNER_CONTENT_TYPE.PRODUCT) {
    return 'Product';
  }
  return '';
};

const getBannerName = (banner) => {
  if (banner.contentType === BANNER_CONTENT_TYPE.CAMPAIGN) {
    return banner?.campaign?.name;
  }
  return banner?.product?.name;
};

const checkFields = (data) => {
  const errorFields = [];
  if (!data.contentReferenceId) {
    errorFields.push(BannerErrorFields.contentType);
  }
  if (!data.coverPhoto) {
    errorFields.push(BannerErrorFields.bannerImage);
  }
  if (!data.order) {
    errorFields.push(BannerErrorFields.order);
  }
  return errorFields;
};

const getBannerForCreateOrUpdate = (banner, isCreate) => {
  const input = {};

  if (banner.pk && !isCreate) {
    input.id = banner.pk;
  }
  if (banner.coverPhoto) {
    input.coverPhoto = getFileNameFromUrl(banner.coverPhoto);
  }
  if (banner.description) {
    input.description = banner.description;
  }
  if (banner.order) {
    input.displayPriority = banner.order;
  }
  if (banner.active !== null || banner.active !== undefined) {
    input.active = banner.active || false;
  }
  if (banner.contentType) {
    input.contentType = banner.contentType;
  }
  if (banner.contentReferenceId) {
    input.contentReferenceId = banner.contentReferenceId;
  }
  if (banner.translations) {
    input.translations = banner.translations?.map((item) => {
      const trans = { language: item.language };
      if (item.coverPhoto) {
        trans.coverPhoto = getFileNameFromUrl(item.coverPhoto);
      }
      if (!isCreate && item.id) {
        trans.id = item.id;
      }
      return trans;
    });
  }

  return input;
};

const getProductFromReferenceId = (id, productList) => {
  const product = productList?.find((item) => item.pk === id);
  return product;
};

const assembleBanner = (node, productList) => {
  const product = {
    id: node.id,
    pk: node.pk,
    coverPhoto: node.coverPhoto,
    description: node.description,
    order: node.displayPriority,
    contentTypeDisplay: getContentTypeToDisplay(node.contentType),
    contentType: node.contentType,
    contentReferenceId: node.contentReferenceId,
    active: node.active,
    campaign: node.campaign,
    product: getProductFromReferenceId(node.contentReferenceId, productList),
    status: node.active ? PublishTagType.published : PublishTagType.unPublished,
    isPublished: node.active,
    translations: node.translations?.edges?.map((item) => item.node),
  };
  product.name = getBannerName(product);
  return product;
};

const assembleProduct = (node) => {
  if (!node) {
    return {};
  }
  return {
    pk: node.id,
    name: node.title,
    quantity: node.totalInventory,
    image: node.images.edges ? node.images.edges[0].node : '',
  };
};

export default {
  namespace: 'bannerList',
  state: { ...getInitialState(), productList: [] },
  reducers: {
    updateBanner(state, { payload }) {
      return {
        ...state,
        banner: {
          ...state.banner,
          ...payload,
        },
      };
    },

    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    assembleBanner(state, { payload }) {
      const banner = assembleBanner(payload.banner, state.productList);
      return {
        ...state,
        loadingStatus: APIStatus.success,
        banner: banner,
      };
    },

    assembleBannerList(state, { payload }) {
      const bannerList = payload.bannerList.map(({ node }) =>
        assembleBanner(node, state.productList),
      );
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        bannerList: bannerList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },

    assembleProductList(state, { payload }) {
      const productList = payload.productList.map(({ node }) =>
        assembleProduct(node),
      );
      return {
        ...state,
        productList: productList,
      };
    },

    loadFromSessionStorage(state, { payload }) {
      const banner =
        getObjectFromSessionStorage(BANNER_SESSION_KEY) || state.banner;
      return {
        ...state,
        banner,
      };
    },

    changeSessionStorage(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(BANNER_SESSION_KEY);
      } else {
        saveToSessionStorage(BANNER_SESSION_KEY, state.banner);
      }
      return {
        ...state,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getPageBannerList: [
      function* ({ payload }, { put }) {
        const { search } = payload;
        const searchPage = payload.page;
        const contentTypeIn =
          payload.type === BANNER_CONTENT_TYPE.ALL_TYPE ? '' : payload.type;
        const active = payload.active;
        let page = searchPage;
        if (typeof searchPage === 'string') {
          page = parseInt(searchPage) || 1;
        }
        const pageCursor = convertNumberToCursor((page - 1) * 20 - 1);
        const serviceArgs = [
          getHomeBanners,
          pageCursor,
          search,
          contentTypeIn,
          active,
          payload.sort,
        ];
        function* onSuccess(data) {
          const banners = data.homepageBanners;
          // const products = yield select((state) =>
          //   state.productList.filter((item) => item.name.indexOf(search) >= 0),
          // );

          yield put({
            type: 'assembleBannerList',
            payload: {
              bannerList: banners.edges,
              totalCount: banners.totalCount,
              pageInfo: banners.pageInfo,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateBanner: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const afterAction = payload.afterAction || (() => {});
        const isCreate = payload.isCreate;
        const input = getBannerForCreateOrUpdate(payload?.data || {}, isCreate);
        const checkStep = payload.checkStep;
        if (checkStep) {
          const errorFields = checkFields(payload.data);
          yield put({
            type: 'updateState',
            payload: { errorFields, createStatus: APIStatus.failed },
          });
          if (errorFields.length > 0) {
            return;
          }
        }
        console.log('@@321: ', input);
        const service = isCreate ? createHomeBanners : updateHomeBanners;
        const serviceArgs = [service, input];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success, formChanged: false },
          });
          yield afterAction();
          removeFromSessionStorage(BANNER_SESSION_KEY);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    duplicate: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'createOrUpdateBanner',
          payload: {
            isCreate: true,
            data: payload.data,
            afterAction: payload.afterAction,
          },
        });
      },
    ],

    delete: [
      function* ({ payload }, { put, select }) {
        const afterAction = payload.afterAction || (() => {});
        const checkedList = yield select(
          (state) => state.bannerList.checkedList,
        );
        const ids = checkedList.map((item) => item.pk);
        const serviceArgs = [deleteHomeBanners, ids];
        function* onSuccess(data) {
          yield afterAction();
        }
        yield yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
    ],

    getOneBanner: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: { loadingStatus: APIStatus.calling },
        });
        const afterAction = payload.afterAction || (() => {});
        const bannerId = convertPKToId('HomepageBannerNode', payload.id);
        const serviceArgs = [getOneHomeBanner, bannerId];
        function* onSuccess(data) {
          yield put({
            type: 'assembleBanner',
            payload: { banner: data.homepageBanner },
          });

          yield afterAction();
        }
        yield yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
    ],

    getProductList: [
      function* ({ payload }, { put, call }) {
      },
    ],
  },
};
