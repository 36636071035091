import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CommunityUniversityIdList } from '../../../config/CustomEnums';
const queryString = require('query-string');

function CommunityCustomers() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const [showDeleteView, setShowDeletePrompt] = useState(false);
  const { listFields, listFieldsWithHall, dataList, pageInfo, totalPage, totalCount, checkedList } =
    useSelector((state) => ({
      listFields: state.communityModel.relatedCustomerInfo.listDisplayFields,
      listFieldsWithHall: state.communityModel.relatedCustomerInfo.listDisplayFieldsWithHall,
      dataList: state.customerList.pagedCustomerList,
      pageInfo: state.customerList.pageInfo,
      totalPage: state.customerList.totalPage,
      totalCount: state.customerList.totalCount,
      checkedList: state.customerList.checkedList,
    }));

  const getUrlParams = () => {
    const searchDict = queryString.parse(history.location.search);
    const search = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    return {
      rank,
      search,
      page,
    };
  };

  const unsetCustomerCommunity = [
    {
      name: 'Delete',
      action: (id) => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeCustomer,
    },
  ];

  useEffect(() => {
    const urlParams = getUrlParams();
    dispatch({
      type: 'customerList/getObjectRelatedCustomers',
      payload: { id, page: urlParams.page, search: urlParams.search },
    });
  }, [history.location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={true}
        groupActions={unsetCustomerCommunity}
        pageInfo={pageInfo}
        totalCount={totalCount || 0}
        listContent={
          <BaseListContainer
            fields={CommunityUniversityIdList.includes(parseInt(id) || 0) ? listFieldsWithHall : listFields}
            model={'customerList'}
            permissionGroup={PermissionCodes.customer}
            dataList={dataList}
            totalPage={totalPage}
            deleteInfo={{
              data: [],
              title: 'Customer',
              relatedName: '',
              customizeDelete: (item) => {
                dispatch({
                  type: 'communityModel/unsetCustomerCommunity',
                  payload: {
                    id: item.pk,
                    communityId: id,
                    afterAction: () => {
                      history.push(location);
                    },
                  },
                });
                console.log('operatedItem:', item);
              },
              relatedSections: ['Community'],
            }}
            actions={['Delete']}
          />
        }
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Customer'}
        relatedName=""
        relatedSections={['Community']}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          dispatch({
            type: 'communityModel/unsetCustomerCommunity',
            payload: {
              communityId: id,
              afterAction: () => {
                setShowDeletePrompt(false);
                history.push(location);
              },
            },
          });
        }}
      />
    </>
  );
}

export default CommunityCustomers;
