import {
  getWhatToDoNextList,
  getWhatToDoNext,
  updateWhatToDoNext
} from '../services/WhatToDoNextApiHelper'

import {
  convertCursorToNumber,
  convertNumberToCursor,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeFromSessionStorage,
  convertPKToId,
  addDomainToImage,
  getFileNameFromUrl
} from '../utils';

import {
  checkCampaignIsExpired,
  getCampignPeriodDateTime
} from '../utils/TimeFormatUtil';

import { loading, apiWithResponseHandle } from './LoadingUtil';

import {
  LanguageConfig,
  APIStatus,
  WhatToDoNextKeyVisualSections
} from '../config/CustomEnums';

export const sessionDataKey = {
  objectKey: 'createWhatToDoNext',
  originalData: 'createWhatToDoNextOriginalData',
};

function translationAssemble(node) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;
  const data = {
    name: node.name,
    pk: node.pk,
    customImageSmallDevice1: addDomainToImage(node.customImageSmallDevice1),
    customImageSmallDevice2: addDomainToImage(node.customImageSmallDevice2),
    customImageSmallDevice3: addDomainToImage(node.customImageSmallDevice3),
    customImageSmallDevice4: addDomainToImage(node.customImageSmallDevice4),
    customImageSmallDevice5: addDomainToImage(node.customImageSmallDevice5),
    customImageSmallDevice6: addDomainToImage(node.customImageSmallDevice6),
    customImageLargeDevice1: addDomainToImage(node.customImageLargeDevice1),
    customImageLargeDevice2: addDomainToImage(node.customImageLargeDevice2),
    customImageLargeDevice3: addDomainToImage(node.customImageLargeDevice3),
    customImageLargeDevice4: addDomainToImage(node.customImageLargeDevice4),
    customImageLargeDevice5: addDomainToImage(node.customImageLargeDevice5),
    customImageLargeDevice6: addDomainToImage(node.customImageLargeDevice6),
  };
  return {
    [language]: data,
  };
}

export function getTranslationForWhatToDoNext(data) {
  const enTranslation = translationAssemble(data);
  const chineseTranslation = data?.translations?.edges.map((item) => {
    return translationAssemble(item.node);
  });
  const reducedTranslation = chineseTranslation?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});
  return {
    ...enTranslation,
    ...reducedTranslation,
  };
}

const saveDataToSessions = (oldWhatToDoNext) => {
  console.log('@old', oldWhatToDoNext)
  const localWhatToDoNext = getObjectFromSessionStorage(sessionDataKey.objectKey);

  const localEn = localWhatToDoNext?.translations?.[LanguageConfig.english] || {};
  const localTraditional =
    localWhatToDoNext?.translations?.[LanguageConfig.traditionalChinese] || {};
  const localSimplified =
    localWhatToDoNext?.translations?.[LanguageConfig.simplifiedChinese] || {};

  const finalWhatToDoNext = {
    ...localWhatToDoNext,
    ...oldWhatToDoNext,
    translations: {
      [LanguageConfig.english]: {
        ...localEn,
        ...oldWhatToDoNext.translations[LanguageConfig.english],
      },
      [LanguageConfig.traditionalChinese]: {
        ...localTraditional,
        ...oldWhatToDoNext.translations[LanguageConfig.traditionalChinese],
      },
      [LanguageConfig.simplifiedChinese]: {
        ...localSimplified,
        ...oldWhatToDoNext.translations[LanguageConfig.simplifiedChinese],
      },
    },
  };
  saveToSessionStorage(sessionDataKey.objectKey, finalWhatToDoNext);
}


const displayInGuestViewMapping = (node) => {
  return node.displayInGuestView ? 'Yes' : 'No'
}

const whatToDoNextDataMapping = (data) => {
  if (!data) {
    return {}
  }
  const translations = getTranslationForWhatToDoNext(data);

  return {
    id: data.id,
    pk: data.pk,
    key: data.key,
    order: data.displayPriority,
    displayPriority: data.displayPriority,
    coverPhoto: addDomainToImage(data.coverPhoto),
    creationDate: getCampignPeriodDateTime(data.creationDate),
    lastModifiedDate: getCampignPeriodDateTime(data.lastModifiedDate),
    displayInGuestView: data.displayInGuestView,
    translations: translations,
  }
}

const parseWhatToDoNextList = (list) => {
  return list.map((whatToDoNext) => {
    const node = whatToDoNext.node;
    // const translations = getTranslationForWhatToDoNext(node, true);

    return {
      id: node.id,
      pk: node.pk,
      name: node.name,
      order: node.displayPriority,
      coverPhoto: addDomainToImage(node.coverPhoto),
      displayInGuestView: displayInGuestViewMapping(node),
      displayLastModifiedDate: getCampignPeriodDateTime(node.lastModifiedDate),
      // customImageSmallDevice1: node.customImageSmallDevice1,
      // customImageSmallDevice2,
      // customImageSmallDevice3,
      // customImageSmallDevice4,
      // customImageSmallDevice5,
      // customImageSmallDevice6,
      // customImageLargeDevice1,
      // customImageLargeDevice2,
      // customImageLargeDevice3,
      // customImageLargeDevice4,
      // customImageLargeDevice5,
      // customImageLargeDevice6,
      // translations,
    };
  });
};
const assembleUpdateWhatToDoNextData = (whatToDoNext) => {
  const languageField = {
    translations:
      [
        {
          language: 'zh-Hant',
          name: whatToDoNext.translations['zh-Hant'].name
        }
      ]
  }

  let updateWhatToDoNextInput = {
    id: whatToDoNext.pk,
    displayPriority: whatToDoNext.displayPriority,
    displayInGuestView: whatToDoNext.displayInGuestView,
    key: whatToDoNext.key
  }

  if (WhatToDoNextKeyVisualSections.includes(whatToDoNext.key)) {
    // TODO: customImageLargeDevice
    updateWhatToDoNextInput = {
      ...updateWhatToDoNextInput,
      ...languageField,
      translationsToDelete: [
        whatToDoNext.translations['zh-Hant'].pk
      ]
    }

    if (whatToDoNext.translations['zh-Hant'].customImageSmallDevice1) {
      languageField.translations[0].customImageSmallDevice1 = getFileName(whatToDoNext.translations['zh-Hant'].customImageSmallDevice1)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageSmallDevice2) {
      languageField.translations[0].customImageSmallDevice2 = getFileName(whatToDoNext.translations['zh-Hant'].customImageSmallDevice2)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageSmallDevice3) {
      languageField.translations[0].customImageSmallDevice3 = getFileName(whatToDoNext.translations['zh-Hant'].customImageSmallDevice3)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageSmallDevice4) {
      languageField.translations[0].customImageSmallDevice4 = getFileName(whatToDoNext.translations['zh-Hant'].customImageSmallDevice4)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageSmallDevice5) {
      languageField.translations[0].customImageSmallDevice5 = getFileName(whatToDoNext.translations['zh-Hant'].customImageSmallDevice5)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageSmallDevice6) {
      languageField.translations[0].customImageSmallDevice6 = getFileName(whatToDoNext.translations['zh-Hant'].customImageSmallDevice6)
    }

    if (whatToDoNext.translations['zh-Hant'].customImageLargeDevice1) {
      languageField.translations[0].customImageLargeDevice1 = getFileName(whatToDoNext.translations['zh-Hant'].customImageLargeDevice1)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageLargeDevice2) {
      languageField.translations[0].customImageLargeDevice2 = getFileName(whatToDoNext.translations['zh-Hant'].customImageLargeDevice2)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageLargeDevice3) {
      languageField.translations[0].customImageLargeDevice3 = getFileName(whatToDoNext.translations['zh-Hant'].customImageLargeDevice3)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageLargeDevice4) {
      languageField.translations[0].customImageLargeDevice4 = getFileName(whatToDoNext.translations['zh-Hant'].customImageLargeDevice4)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageLargeDevice5) {
      languageField.translations[0].customImageLargeDevice5 = getFileName(whatToDoNext.translations['zh-Hant'].customImageLargeDevice5)
    }
    if (whatToDoNext.translations['zh-Hant'].customImageLargeDevice6) {
      languageField.translations[0].customImageLargeDevice6 = getFileName(whatToDoNext.translations['zh-Hant'].customImageLargeDevice6)
    }

    if (whatToDoNext.translations.en.customImageSmallDevice1) {
      updateWhatToDoNextInput['customImageSmallDevice1'] = getFileName(whatToDoNext.translations.en.customImageSmallDevice1)
    }
    if (whatToDoNext.translations.en.customImageSmallDevice2) {
      updateWhatToDoNextInput['customImageSmallDevice2'] = getFileName(whatToDoNext.translations.en.customImageSmallDevice2)
    }
    if (whatToDoNext.translations.en.customImageSmallDevice3) {
      updateWhatToDoNextInput['customImageSmallDevice3'] = getFileName(whatToDoNext.translations.en.customImageSmallDevice3)
    }
    if (whatToDoNext.translations.en.customImageSmallDevice4) {
      updateWhatToDoNextInput['customImageSmallDevice4'] = getFileName(whatToDoNext.translations.en.customImageSmallDevice4)
    }
    if (whatToDoNext.translations.en.customImageSmallDevice5) {
      updateWhatToDoNextInput['customImageSmallDevice5'] = getFileName(whatToDoNext.translations.en.customImageSmallDevice5)
    }
    if (whatToDoNext.translations.en.customImageSmallDevice6) {
      updateWhatToDoNextInput['customImageSmallDevice6'] = getFileName(whatToDoNext.translations.en.customImageSmallDevice6)
    }

    if (whatToDoNext.translations.en.customImageLargeDevice1) {
      updateWhatToDoNextInput['customImageLargeDevice1'] = getFileName(whatToDoNext.translations.en.customImageLargeDevice1)
    }
    if (whatToDoNext.translations.en.customImageLargeDevice2) {
      updateWhatToDoNextInput['customImageLargeDevice2'] = getFileName(whatToDoNext.translations.en.customImageLargeDevice2)
    }
    if (whatToDoNext.translations.en.customImageLargeDevice3) {
      updateWhatToDoNextInput['customImageLargeDevice3'] = getFileName(whatToDoNext.translations.en.customImageLargeDevice3)
    }
    if (whatToDoNext.translations.en.customImageLargeDevice4) {
      updateWhatToDoNextInput['customImageLargeDevice4'] = getFileName(whatToDoNext.translations.en.customImageLargeDevice4)
    }
    if (whatToDoNext.translations.en.customImageLargeDevice5) {
      updateWhatToDoNextInput['customImageLargeDevice5'] = getFileName(whatToDoNext.translations.en.customImageLargeDevice5)
    }
    if (whatToDoNext.translations.en.customImageLargeDevice6) {
      updateWhatToDoNextInput['customImageLargeDevice6'] = getFileName(whatToDoNext.translations.en.customImageLargeDevice6)
    }
  }

  return updateWhatToDoNextInput;

  function getFileName(image) {
    return image && getFileNameFromUrl(image);
  }
}

function getTranslation() {
  return {
    pk: '',
    name: '',
    customImageSmallDevice1: '',
    customImageSmallDevice2: '',
    customImageSmallDevice3: '',
    customImageSmallDevice4: '',
    customImageSmallDevice5: '',
    customImageSmallDevice6: '',
    customImageLargeDevice1: '',
    customImageLargeDevice2: '',
    customImageLargeDevice3: '',
    customImageLargeDevice4: '',
    customImageLargeDevice5: '',
    customImageLargeDevice6: ''
  };
}

const whatToDoNextInit = () => ({
  id: null,
  pk: null,
  coverPhoto: '',
  displayInGuestView: '',
  order: '',
  translations: {
    [LanguageConfig.english]: getTranslation(),
    [LanguageConfig.traditionalChinese]: getTranslation(),
    [LanguageConfig.simplifiedChinese]: getTranslation(),
  },
});

const getInitialState = () => ({
  whatToDoNext: whatToDoNextInit(),
  whatToDoNextList: [],
  createStatus: APIStatus.none,
  updateStatus: APIStatus.none,
  allWhatToDoNextList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Guest view', fieldName: 'displayInGuestView' },
    {
      displayName: 'Last Modified Date',
      fieldName: 'displayLastModifiedDate',
      orderField: 'lastModifiedDate',
    },
    {
      displayName: 'Display Priority',
      fieldName: 'order',
      orderField: 'displayPriority',
    },
  ],
  currentPageWhatToDoNextList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
});

export default {
  namespace: 'whatToDoNextList',
  state: { ...getInitialState() },
  reducers: {
    updateKeyVisual(state, { payload }) {

      const language = payload.language
      const newTranslations = state.whatToDoNext.translations

      // newTranslations[language].pk = payload.pk || ''

      if (payload.smallDeviceImageList) {
        newTranslations[language].customImageSmallDevice1 = payload.smallDeviceImageList[0] || ''
        newTranslations[language].customImageSmallDevice2 = payload.smallDeviceImageList[1] || ''
        newTranslations[language].customImageSmallDevice3 = payload.smallDeviceImageList[2] || ''
        newTranslations[language].customImageSmallDevice4 = payload.smallDeviceImageList[3] || ''
        newTranslations[language].customImageSmallDevice5 = payload.smallDeviceImageList[4] || ''
        newTranslations[language].customImageSmallDevice6 = payload.smallDeviceImageList[5] || ''
      } else if (payload.largeDeviceImageList) {
        newTranslations[language].customImageLargeDevice1 = payload.largeDeviceImageList[0] || ''
        newTranslations[language].customImageLargeDevice2 = payload.largeDeviceImageList[1] || ''
        newTranslations[language].customImageLargeDevice3 = payload.largeDeviceImageList[2] || ''
        newTranslations[language].customImageLargeDevice4 = payload.largeDeviceImageList[3] || ''
        newTranslations[language].customImageLargeDevice5 = payload.largeDeviceImageList[4] || ''
        newTranslations[language].customImageLargeDevice6 = payload.largeDeviceImageList[5] || ''
      }

      const whatToDoNext = {
        ...state.whatToDoNext,
        translations: newTranslations
      };
      saveDataToSessions(whatToDoNext);
      return { ...state, whatToDoNext: whatToDoNext }
    },
    updateWhatToDoNext(state, { payload }) {
      let whatToDoNext = {
        ...state.whatToDoNext,
        displayPriority: parseInt(payload.displayPriority) || parseInt(state.whatToDoNext.displayPriority),
      };
      if (payload.displayInGuestView != null) {
        whatToDoNext = {
          ...whatToDoNext,
          displayInGuestView: payload.displayInGuestView
        }
      }
      saveDataToSessions(whatToDoNext);
      return { ...state, whatToDoNext: whatToDoNext }
    },
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
    assembleWhatToDoNext(state, { payload }) {
      const data = whatToDoNextDataMapping(payload.whatToDoNext);
      const whatToDoNext = {
        ...state.whatToDoNext,
        ...data,
        translations: {
          ...state.whatToDoNext.translations,
          ...data.translations,
        },
      };
      // saveToSessionStorage(sessionDataKey.origionalData, campaign);
      // saveToSessionStorage(sessionDataKey.objectKey, campaign);
      saveDataToSessions(whatToDoNext);
      return {
        ...state,
        whatToDoNext: whatToDoNext,
      };
    },
    assembleWhatToDoNextList(state, { payload }) {

      const whatToDoNextList = parseWhatToDoNextList(payload.list);
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        whatToDoNextList: whatToDoNextList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
        availableWhatToDoNextNamesList: whatToDoNextList.map((item) => item.name),
      };
    },
    assembleAllWhatToDoNextList(state, { payload }) {
      const whatToDoNextList = parseWhatToDoNextList(payload.list)

      return {
        ...state,
        allWhatToDoNextList:
          payload.page > 1
            ? [...state.allWhatToDoNextList, ...whatToDoNextList]
            : whatToDoNextList,
      };
    }
  },
  effects: {
    updateWhatToDoNextDisplayPriority: [
      function* ({ payload }, { all, select, put }) {
        const serviceArgs = [updateWhatToDoNext, payload];
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess(data) {
          yield afterAction({ success: true });
        }
        function* failed(data) {
          yield afterAction({ success: false });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],
    getWhatToDoNextList: [
      function* ({ payload }, { put }) {
        const displayPriority = payload.sort

        const serviceArgs = [
          getWhatToDoNextList,
          displayPriority,
          { ...payload },
        ];

        function* onSuccess(data) {
          const whatToDoNexts = data.whatToDoNexts;
          if (payload.isSelectorLoad) {
            yield put({
              type: 'assembleAllWhatToDoNextList',
              payload: {
                list: whatToDoNexts.edges,
                page,
              },
            });
          } else {
            yield put({
              type: 'assembleWhatToDoNextList',
              payload: {
                list: whatToDoNexts.edges,
                totalCount: whatToDoNexts.totalCount,
                pageInfo: whatToDoNexts.pageInfo,
              },
            });
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getWhatToDoNext: [
      function* ({ payload }, { all, select, put }) {
        const id = convertPKToId('WhatToDoNextNode', payload.id);
        const serviceArgs = [getWhatToDoNext, id];

        function* onSuccess(data) {
          yield all([
            put({
              type: 'assembleWhatToDoNext',
              payload: { whatToDoNext: data.whatToDoNext },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.success },
            }),
          ]);
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],

    updateOneWhatToDoNext: [
      function* ({ payload }, { select, put, all }) {
        const whatToDoNext = yield select(
          (state) => state.whatToDoNextList.whatToDoNext,
        );
        const updateWhatToDoNextInput = assembleUpdateWhatToDoNextData(whatToDoNext);
        const serviceArgs = [updateWhatToDoNext, updateWhatToDoNextInput];
        function* onSuccess(data) {
          const whatToDoNext = data?.updateWhatToDoNext?.node;
          yield put({
            type: 'assembleWhatToDoNext',
            payload: { whatToDoNext: data.whatToDoNext },
          });
          yield put({
            type: 'updateState',
            payload: {
              updateStatus: !whatToDoNext ? APIStatus.failed : APIStatus.success,
            },
          });
          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        };
        function* failed(data) {
          yield put({ type: 'updateState', payload: { updateStatus: APIStatus.failed } });
        };
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],
  },
};

