import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { EarningRuleRewardType } from '../../../config/CustomEnums';
import { useDispatch, useSelector } from 'react-redux';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchSubcategory = parsedSearch['subcategory'] || '';

  const [subcategory, setSubcategory] = useState(
    ArrayStringData(searchSubcategory),
  );
  const [isRVM, setIsRVM] = useState(parsedSearch['reverse_vending_machine']);
  const { subCategoryList } = useSelector((state) => ({
    subCategoryList: state.storeCategoryList.subCategoryList,
  }));

  // useEffect(() => {
  //   dispatch(createAction('storeCategoryList/getAllStoreSubcategoryList')({}));
  // }, [dispatch]);

  const content = [
    {
      title: 'Subcategory',
      data: subCategoryList,
      value: subcategory,
      setValue: setSubcategory,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Reverse Vending Machine',
      data: [
        { name: 'Yes', pk: 'Yes' },
        { name: 'No', pk: 'No' },
      ],
      value: isRVM,
      setValue: setIsRVM,
      componment: FilterTypeEnum.singleChoice,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
