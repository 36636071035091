import React, { useState } from 'react';
import { Image } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';
import './CustomTitleWithDropDown.scss';
import FilterableDropdown from '../base/FilterableDropdown';
import { createAction } from '../../utils';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import AuthButton from '../base/AuthButton';
import DeleteIcon from '../../assets/images/deleteIcon.png';

export function CustomTitleWithDropDown(props) {
  const dispatch = useDispatch();

  const isImageField = props.isImageField || false;
  const showMultiLine = props.showMultiLine || false;
  const needFilter = props.needFilter;
  const defaultValue = props.defaultValue || null;
  const title = props.title;
  const titleTips = props.titleTips || "";
  const source = props.source || [];
  const listSource = props.listSource || false;
  const sourceShowId = props.sourceShowId || false;
  const extraTitleClass = props.extraTitleClass || '';
  const filterableItemClassName = props.filterableItemClassName || '';
  const setValue = props.setValue || (() => {});
  const errors = props.errors || [];
  const errorName = props.errorName || props.title?.toLowerCase();
  const errorMessage = props.errorMessage || `Please provide ${errorName}`;
  const disabled = props.disabled || false;
  const onFocus = props.onFocus || (() => {});
  const addButtonInfo = props.addButtonInfo || {};
  const defaultFilter = props.defaultFilter || {};

  const loadMoreAction = props.loadMoreAction;
  const localFilterAction = props.localFilterAction || (() => {});
  const filterAction = props.filterAction;

  const onSelectChange = (value) => {
    setValue(value);
  };
  // const options = source?.map((item) => {
  //   return { value: item, label: item?.name };
  // });

  const options = source?.map((item) => {
    if (listSource){
      return { value: item, label: item };
    }
    if (sourceShowId) {
      return { value: item, label: `${item?.name} (ID: ${item?.pk})`}
    }
    return { value: item, label: item?.name };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setFilterKey] = useState('');

  const loadMore = () => {
    if (!loadMoreAction) {
      return;
    };
    const nextPage = currentPage + 1;

    dispatch(
      createAction(loadMoreAction)({
        page: nextPage,
        search: filterKey,
        searchKey: filterKey,
        isSelectorLoad: true,
        ...defaultFilter,
      }),
    );

    console.log("defaultFilter:", defaultFilter)
    setCurrentPage(nextPage);
  };

  const filter = (search) => {
    if (!filterAction) {
      localFilterAction(search)
      return;
    };
    let nextPage = currentPage + 1;
    if (search !== filterKey) {
      nextPage = 1;
    }
    console.log('@@59: ', filterAction, search, defaultFilter);
    dispatch(
      createAction(filterAction)({
        page: nextPage,
        search: search,
        searchKey: search,
        isSelectorLoad: true,
        ...defaultFilter,
      }),
    );

    setCurrentPage(nextPage);
    setFilterKey(search);
  };

  return (
    <div style={{ display: 'grid', width: '100%' }}>
      {title ? (
        <CustomTitleLabel className={props.titleStyle} title={title} />
      ) : null}
      {titleTips ? (
        <label className="tips-message" style={{marginBottom: "14px"}}>{titleTips}</label>
      ) : null}
      {disabled ? (
        <label className="custom-record-normal-font custom-timeline-time-color">
          {defaultValue?.label || '-'}
        </label>
      ) : (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <FilterableDropdown
              isImageField={isImageField}
              showMultiLine={showMultiLine}
              selectChange={onSelectChange}
              isPromptDropdown={true}
              options={options}
              filterableItemClassName={filterableItemClassName}
              defaultValue={defaultValue}
              needFilter={needFilter}
              customClass={`custom-title-dropdown ${extraTitleClass} ${
                errors.includes(errorName) ? 'error-field' : ''
              }`}
              onToggle={onFocus}
              loadMoreAction={loadMore}
              filterAction={filter}
            />
            {props.showRemoveButton ? (
              <button
                className="reset-button"
                style={{ marginTop: -20, marginLeft: 0, marginRight: 20 }}
                onClick={props.onRemoveButtonClick}
              >
                <Image src={DeleteIcon} className="content-delete-button" />
              </button>
            ) : null}
            <AuthButton style={{}} {...addButtonInfo} />
          </div>
          <ErrorFieldMessage
            error={errors.includes(errorName)}
            id={errorName}
            message={errorMessage}
          />
        </>
      )}
    </div>
  );
}

export function CustomTitleWithDropDownAndInput(props) {
  const dispatch = useDispatch();

  const { needFilter, customClass, unit, inputCustomClass } = props;
  const defaultValue = props.defaultValue || null;
  const defaultInputValue = props.defaultInputValue || null;
  const title = props.title;
  const source = props.source || [];
  const setValue = props.setValue || (() => {});
  const setInputValue = props.setInputValue || (() => {});
  const errors = props.errors || [];
  const disabled = props.disabled || false;
  const onFocus = props.onFocus || (() => {});
  const inputOnFocusChange = props.inputOnFocusChange || (() => {});

  const onSelectChange = (value) => {
    setValue(value);
  };
  const options = source?.map((item) => {
    return { value: item.pk, label: item?.name };
  });

  const isError = (field) => {
    return errors.includes(field);
  };

  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} />
      {disabled ? (
        <label className="custom-record-normal-font custom-timeline-time-color">
          {defaultValue?.label} {defaultInputValue}
        </label>
      ) : (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <FilterableDropdown
              customClass={`custom-title-dropdown ${customClass} ${
                isError(title.toLowerCase()) ? 'error-field' : ''
              }}`}
              selectChange={onSelectChange}
              isPromptDropdown={false}
              options={options}
              defaultValue={defaultValue}
              needFilter={needFilter}
              onToggle={onFocus}
            />

            <input
              type="number"
              onChange={({ target }) => setInputValue(target.value)}
              className={`custom-number-input-long ${inputCustomClass} ${
                isError(title.toLowerCase()) ? 'error-field' : ''
              }`}
              value={defaultInputValue}
              onFocus={inputOnFocusChange}
            />
            <label className="help-message">{unit}</label>
          </div>
          <ErrorFieldMessage
            error={isError(title.toLowerCase())}
            id={title.toLowerCase()}
            message={`Please provide ${title.toLowerCase()}`}
          />
        </>
      )}
    </div>
  );
}
