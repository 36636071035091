import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './CreateCampaignStepOne.scss';
import { Collapse } from 'react-bootstrap';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../../base/ItemTypeSet';
import CouponSelectDropdown from './CouponSelectDropdown';
import EarningRulesSelectDropdown from './EarningRulesSelectDropdown';
import CampaignLinkPrompt from './CampaignLinkPrompt';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import { OnlyContinueButton } from '../../base/BottomStepComponent';
import { createAction } from '../../../utils';
import { useHistory } from 'react-router-dom';
import {
  getCampaignTypeSelectPromptConfig,
  CampaignTypesConfig,
} from './CreateCampaignStepOneConfigs';
import ContentSections from '../../base/ContentSections';
import Loading from '../../base/Loading';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import { ContinueCreate } from '../CampaignUrlConfig';
import BasePrompt from '../../base/prompt/BasePrompt';

function CreateCampaignStepOne(props) {
  const {
    campaignType,
    linkedCoupon,
    linkedEarningRules,
    status,
    promptShowed,
  } = useSelector((state) => ({
    campaignType: state.createCampaign.campaign.campaignType,
    linkedCoupon: state.createCampaign.campaign.linkedCoupon,
    linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
    status: state.createCampaign.createStatus,
    promptShowed: state.createCampaign.promptShowed,
  }));
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const [showLinkPrompt, setShowLinkPrompt] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectRef = useRef('linkDropdown');
  // const [createNewLinkTitle, setCreateNewLinkTitle] = useState();

  const promptconfig = getCampaignTypeSelectPromptConfig(campaignType);

  const setCoupon = (value) => {
    dispatch(
      createAction('createCampaign/getAndLinkCouponDetail')({
        couponId: value?.pk,
        name: value.name,
      }),
    );
  };

  const linkSet = () => {
    const linkFiledShow =
      !campaignType || campaignType === CampaignType.generalMessageCampaign;
    return (
      <Collapse in={!linkFiledShow}>
        <div className="step-link-coupon-area-select" ref={selectRef}>
          {campaignType === CampaignType.couponsCampaign ? (
            <CouponSelectDropdown
              title={'Link to coupon set'}
              defaultValue={{
                value: linkedCoupon,
                label: linkedCoupon?.name,
              }}
              setValue={setCoupon}
              addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            />
          ) : (
            <EarningRulesSelectDropdown
              title={'Link to earning rule set'}
              defaultValue={{
                value: linkedEarningRules,
                label: linkedEarningRules?.name,
              }}
              addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            />
          )}
        </div>
      </Collapse>
    );
  };

  const showPrompt = () => {
    if (promptShowed) {
      return;
    }
    if (!showLinkPrompt) {
      return;
    }
    return (
      <CampaignLinkPrompt
        handleContinue={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          dispatch({ type: 'createCampaign/stepChange', payload: { step: 0 } });
        }}
        closePrompt={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          if (!linkedCoupon && !linkedEarningRules) {
            window.scrollTo(0, selectRef.current.offsetTop);
          }
        }}
      />
    );
  };

  const campaignTypeSelectArea = () => {
    return (
      <div
        className="step-type-area campaign-step-one-bottom"
        style={{
          justifyContent:
            fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
              ? 'center'
              : 'flex-start',
        }}
      >
        {CampaignTypesConfig.map((typeItem) => (
          <ItemTypeSet
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={campaignType}
            onClick={(id) => {
              dispatch({
                type: 'createCampaign/typeChange',
                payload: { campaignType: id },
              });
              if (id !== CampaignType.generalMessageCampaign) {
                if (!promptShowed) {
                  setShowLinkPrompt(true);
                }
              }
            }}
          />
        ))}
      </div>
    );
  };

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Campaign Type</label>
          {campaignTypeSelectArea()}
          {linkSet()}
          {showPrompt()}
          <BasePrompt
            show={showCreateNewLinkPrompt}
            closeAction={() => setShowCreateNewLinkPrompt(false)}
            rightButton={{
              text: promptconfig.button,
              action: () => {
                const pathname =
                  campaignType === CampaignType.couponsCampaign
                    ? '/coupons/create'
                    : '/earns/create';
                history.push({
                  pathname: pathname,
                  state: { from: history.location, ...ContinueCreate },
                });
              },
            }}
            title={promptconfig.title}
            description={promptconfig.message}
          />
        </div>
      )}
    </>,
  ];

  const continueAction = () =>
    dispatch({ type: 'createCampaign/stepChange', payload: { step: 0 } });

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={continueAction}
        disabledContinue={
          !campaignType ||
          (campaignType === CampaignType.couponsCampaign && !linkedCoupon) ||
          (campaignType === CampaignType.earningRulesCampaign &&
            !linkedEarningRules?.pk)
        }
      />
    </>
  );
}

export default CreateCampaignStepOne;
