import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isShowError, useCompare } from '../../../utils';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import i18n from '../../../I18n';
import { ErrorHandleFields } from './ErrorHandleFields';
import CustomTagSection from '../../../components/base/CustomTagSection';


const GeneralSection = ({
  language,
  translations,
  errorFields,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(translations?.[language]?.name || "");
  const [tag, setTag] = useState(translations?.[language]?.tag || []);

  const isError = (field) => {
    return isShowError(field, errorFields, language);
  };

  const vals = {
    name,
    tag,
  };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'newsFeedModel/updateTranslations', payload: { name, tag, language } });
    }
  });

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>
      <CustomTitleWithInput
        title={i18n.t('name', { locale: language })}
        defaultValue={name}
        setValue={(value) => {
          setName(value);
        }}
        error={{
          id: `${ErrorHandleFields.name.name}-${language}`,
          error: isError(ErrorHandleFields.name.name),
          message: isError(ErrorHandleFields.name.name)
            ? ErrorHandleFields.name.message
            : ''
        }}
      />
      <CustomTagSection
        tagTitle={i18n.t('input_tag', { locale: language })}
        tagDescription={i18n.t('input_tag_description', { locale: language })}
        stateTag={tag}
        setStateTag={setTag}
        tagSecondTitle={i18n.t('tag', { locale: language })}
        tagSecondDescription={i18n.t('tag_description', { locale: language })}
        errorId={`${ErrorHandleFields.tag.name}-${language}`}
        isError={isError(ErrorHandleFields.tag.name)}
        errorMessage={ErrorHandleFields.tag.message}
      />
    </>
  )
}


const mapStateToProps = (state) => ({
  translations: state.newsFeedModel.oneObject.translations || {},
  errorFields: state.newsFeedModel.errorFields,
})

export default connect(mapStateToProps)(GeneralSection);
