import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getPagedCommunities = (search = {}) => {
  const pageSize = search.pageSize || 20;
  const afterCursor = search.afterCursor || '';
  const searchValue = search.search ? `nameIcontains:"${search.search}"` : '';
  const orderBy = search.sort || '-pk';
  const query = `{
    communities(first:${pageSize}, after:"${afterCursor}", orderBy:"${orderBy}", ${searchValue}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          numberOfCustomer
          communityCode
          coverPhoto
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getOneObject = (id) => {
  const query = `{
    community(id: "${id}") {
      id
      pk
      name
      numberOfCustomer
      communityCode
      communityLimit
      coverPhoto
      rewardType
      rewardPoints
      creationDate
      lastModifiedDate
      rewardCouponTemplate {
        id
        name
        pk
      }
      rewardCouponQuantity
      carbonSavingQuantity
      termsAndCondition
      translations {
        edges {
          node {
            pk
            language
            name
            termsAndCondition
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteItems = (ids) => {
  const query = `mutation DeleteCommunities($input: DeleteCommunitiesInput!) {
    deleteCommunities(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdate = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}Community($input: ${action}CommunityInput!) {
    ${action.toLowerCase()}Community(input: $input) {
      success
        node {
          id
          pk
          name 
        }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unsetCustomerCommunity = (ids, communityId) => {
  const query = `mutation DeleteCustomersCommunityCode($input: DeleteCustomersCommunityCodeInput!) {
    deleteCustomersCommunityCode(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids, communityId: parseInt(communityId) } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
