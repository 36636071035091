import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignType, EarningRuleType } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
import ActivePeriodSection from './ActivePeriodSection';
import CouponCampaignSection from './CouponCampaignSection';
import PropertiesGeneralSection from './PropertiesGeneralSection';
import VisiblePeriodSection from './VisiblePeriodSection';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';

const dataArray = [];
function CreateCampaignStepThree(props) {
  const { campaignType, isMonthlyOrWeekly } = useSelector((state) => ({
    campaignType: state.createCampaign.campaign.campaignType,
    isMonthlyOrWeekly: state.createCampaign.campaign.isMonthlyOrWeekly,
  }));
  const dispatch = useDispatch();
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (onCheck.value) {
      let formatedData = {};
      dataArray.forEach((item) => {
        formatedData = {
          ...formatedData,
          ...item,
        };
      });
      dispatch({
        type: 'createCampaign/stepChange',
        payload: {
          data: formatedData,
          isBack: onCheck.isBack,
          step: 2,
        },
      });
    }
    stopCheck();
  }, [dispatch, onCheck.isBack, onCheck.value]);

  const submitAction = (data) => {
    dataArray.push(data);
  };

  const sections = [
    <PropertiesGeneralSection
      onSubmit={onCheck.value}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
    />,
    <ActivePeriodSection
      onSubmit={onCheck.value}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
    />,
    <VisiblePeriodSection
      onSubmit={onCheck.value}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
    />,
    campaignType === CampaignType.couponsCampaign ? (
      <CouponCampaignSection
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(data);
        }}
      />
    ) : null,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          isChecking({ isBack: false });
        }}
        backAction={() => {
          isChecking({ isBack: true });
        }}
      />
    </>
  );
}

export default CreateCampaignStepThree;
