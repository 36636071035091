import { BaseHelper } from './BaseGraphQLAPIHelper';

const missionNode = `
  id
  pk
  name
  language
  creationDate
  lastModifiedDate
  detailSectionTitle
  detailSectionContent
  plainDescriptionContent
  startDate
  endDate
  displayStartDate
  displayEndDate
  isPublished
  iconType
  triggerPageType
  rewardPoints
  rewardCouponTemplate {
    pk
    name
  }
  rewardCouponQuantity
  category
  joinedTimes
  recyclingItems
  missionEarningType
  habitualSetLevels
  habitualProgressUnit
  habitualLevels {
    edges {
      node {
        pk
        id
        levelGrade
        levelName
        joinedTimes
        recyclingItems
        numberOfPoints
        couponQuantity
        couponTemplate {
          pk
          id
          name
        }
        translations {
          edges{
            node{
              id
              pk
              language
              levelName
            }
          }
        }
      }
    }
  }
  communityOnly
  community {
    pk
    name
  }
  secondaryTypeLimit
  totalTarget
  individualTarget
  missionReminderEligible
  missionReminderIneligible
  missionReminderComplete
  missionReminderExpired
  linkToCampaign {
    id
    pk
    name
    earningCampaignTypeEarningRule {
      id
      pk
      name
      type
    }
    translations {
      edges {
        node {
          name
          language
        }
      }
    }
  }
  translations {
    edges {
      node {
        pk
        language
        name
        detailSectionTitle
        detailSectionContent
        plainDescriptionContent
        habitualProgressUnit
        missionReminderEligible
        missionReminderIneligible
        missionReminderComplete
        missionReminderExpired
      }
    }
  }
`;

export const getMissions = ({
  afterCursor = '',
  order,
  search = '',
  others = {},
}) => {
  let moreSearch = '';
  let orderBy = '-pk';
  if (order) {
    orderBy = order;
  };
  let userFilter = '';
  if (others?.type) {
    userFilter += `, category: "${others?.type === 'all' ? '' : others?.type}"`;
  }

  if (others?.communityId) {
    userFilter += `, communityOnly: true, communityIn: "${others?.communityId}"`;
  } else {
    userFilter += ", communityOnly: false";
  }
  const query = `
  {
    missions(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains: "${search}"${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          startDate
          endDate
          displayStartDate
          displayEndDate
          isPublished
          category
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMission = (id) => {
  const query = `
  {
    mission(id: "${id}") {
      ${missionNode}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createMission = (inputBody) => {
  const query = `
  mutation CreateMission($input: CreateMissionInput!) {
    createMission(input: $input) {
      node {
        ${missionNode}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateMission = (inputBody) => {
  const query = `
  mutation UpdateMission($input: UpdateMissionInput!) {
    updateMission(input: $input) {
      node {
        ${missionNode}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteMissions = (ids) => {
  const query = `
  mutation DeleteMissions($input: DeleteMissionsInput!) {
    deleteMissions(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishMission = (input) => {
  const query = `mutation PublishMission($input: PublishMissionInput!) {
    publishMission(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishMission = (input) => {
  const query = `mutation UnpublishMission($input: UnpublishMissionInput!) {
    unpublishMission(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unLinkCommunity = (input) => {
  const query = `mutation UnLinkCommunity($input: UnLinkCommunityInput!) {
    unlinkCommunity(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}