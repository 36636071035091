import {
  getPointTransactionRecords,
  getOnePointTransactionRecord,
} from '../services/TransactionRecordsAPIHelper';
import {
  createAction,
  capitalizeFirstLetter,
  convertCursorToNumber,
  convertNumberToCursor,
} from '../utils';
import { loading } from './LoadingUtil';
import { formatDate, getDisplayDate } from '../utils/TimeFormatUtil';
import { POINT_TRANSACTION_TYPE } from '../containers/record/pointRecords/PointTransactionList';
import { POINT_RECORD_TYPE } from '../components/pointRecord/CreatePointRecordStepOne';

function getInitState() {
  return {
    pointTransactionList: [],
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', linked: true },
      {
        displayName: 'Name (preferred name)',
        fieldName: 'name',
        linked: false,
        orderField: 'customerFirstName',
      },
      {
        displayName: 'Membership\r\nID',
        fieldName: 'membershipId',
        orderField: 'membershipId',
      },
      { displayName: 'Point Type', fieldName: 'pointDisplayType' },
      { displayName: 'Value', fieldName: 'points', orderField: 'value' },
      { displayName: 'Record Type', fieldName: 'transactionDisplayType' },
      { displayName: 'Remark', fieldName: 'remarks' },
      { displayName: 'Small heading', fieldName: 'content' },
      {
        displayName: 'Create at',
        fieldName: 'creationDate',
        orderField: 'creationDate',
      },
    ],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    currentLastCursor: '',
    currentPage: 0,
    totalPage: 0,
    totalCount: 0,
    checkedList: [],
    currentPagePointTransactionList: [],
    selectedPointTransaction: {},
    maxPointValue: 0,
  };
}

function parsePointTransactionType(transactionType) {
  switch (transactionType) {
    case 'CUSTOMER_EARN':
      return POINT_TRANSACTION_TYPE.CUSTOMER_EARN;
    case 'CUSTOMER_USED':
      return POINT_TRANSACTION_TYPE.CUSTOMER_USED;
    case 'ADMIN_EDITED':
      return POINT_TRANSACTION_TYPE.ADMIN_EDITED;
    case 'EXPIRED':
      return POINT_TRANSACTION_TYPE.EXPIRED;
    default:
      break;
  }
}

function parsePointType(pointType) {
  switch (pointType) {
    case 'TYPE_POINT_ADD':
      return POINT_RECORD_TYPE.TYPE_ADD;
    case 'TYPE_POINT_REMOVE':
      return POINT_RECORD_TYPE.TYPE_REMOVE;
    default:
      break;
  }
}

function parsePointTransactionRecord(item) {
  return {
    pk: item.pk,
    id: item.id,
    membershipId: item.customer?.membershipId,
    ssoUid: item.customer?.ssoUid,
    pointType: item.pointType,
    pointDisplayType: parsePointType(item.pointType),
    transactionType: item.transactionType,
    transactionDisplayType: parsePointTransactionType(item.transactionType),
    points: item.value,
    remarks: item.remarks,
    content: item.content,
    usedType: item.usedType,
    usedDisplayType: item.usedType
      ? capitalizeFirstLetter(item.usedType?.replace(/_/g, ' ').toLowerCase())
      : null,
    useDate: item.usedDate
      ? formatDate(item.usedDate, 'DD MMM yyyy (ddd), HH:mm a')
      : null,
    creationDate: getDisplayDate(item.creationDate),
    expiryDate: item.expireAtDate
      ? formatDate(item.expireAtDate, 'DD MMM yyyy (ddd), HH:mm a')
      : null,
    name: item.customer
      ? item.customer?.nickname
        ? `${item.customer?.firstName} ${item.customer?.lastName} (${item.customer?.nickname})`
        : `${item.customer?.firstName} ${item.customer?.lastName}`
      : null,
    administratorName: item.administrator?.username,
    customer: item.customer,
    createdDate: formatDate(item.creationDate),
    expiredTime: formatDate(item.expireAtDate),
    source: item.transactionType,
  };
}

export default {
  namespace: 'pointTransactionList',
  state: getInitState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    getCurrentPagePointRecords: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [getPointTransactionRecords, afterCursor, payload];
        function* onSuccess(data) {
          const pointTransactionData = data.pointTransactions.edges;
          const pageInfo = data.pointTransactions.pageInfo;
          const totalCount = data.pointTransactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const pointTransactionList = pointTransactionData.map((item) =>
            parsePointTransactionRecord(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPagePointTransactionList: pointTransactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
              maxPointValue: data.pointTransactions.maxPointValue,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSinglePointRecord: [
      function* ({ payload }, { put }) {
        const { pointRecordPK } = payload;
        const pointRecordID = btoa(`PointTransactionNode:${pointRecordPK}`);
        const serviceArgs = [getOnePointTransactionRecord, pointRecordID];
        function* onSuccess(data) {
          const pointRecordData = data.pointTransaction;
          yield put(
            createAction('updateState')({
              selectedPointTransaction: parsePointTransactionRecord(
                pointRecordData,
              ),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
