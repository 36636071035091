import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, connect } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { createAction } from '../../../utils';
import { useLocation, useHistory } from 'react-router-dom';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import {
  MissionType,
  PublishTagType,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';

const TabsConfig = [
  { key: MissionType.allTypes, name: 'All Types' },
  { key: MissionType.onboardingMission, name: 'Onboarding Mission' },
  { key: MissionType.campaignLinked, name: 'Campaign Linked' },
  { key: MissionType.habitual, name: 'Habitual' },
  { key: MissionType.group, name: 'Group' },
];

function MissionList({
  missionList,
  listDisplayFields,
  checkedList,
  pageInfo,
  totalCount,
  totalPage,
}) {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const deletePks = deleteItems?.map(item => item.pk);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);
  let currentPage = parseInt(searchQuery?.page);
  if (isNaN(currentPage)) {
    currentPage = 1;
  }

  const searchKey = searchQuery?.search;
  const fetchData = () => {
    dispatch(
      createAction('mission/getCurrentPageMissions')({
        ...searchQuery,
        page: currentPage,
        searchKey,
      }),
    );
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeMission,
    },
  ];

  const tabs = [
    {
      name: 'List Of Mission',
      content: (
        <BaseTabListContainer
          hideTab={false}
          tabs={TabsConfig}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: false,
          }}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={missionList}
              totalPage={totalPage}
              currentPage={currentPage}
              customClassName="mission-list"
              model={'mission'}
              permissionGroup={PermissionCodes.mission}
              deleteInfo={{
                data: [],
                title: 'Mission',
                relatedName: '',
                onComfirm: {},
                relatedSections: null,
              }}
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`mission/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeMission,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.changeMission,
                  },
                  {
                    name: 'Duplicate',
                    action: () => {
                      dispatch(
                        createAction(
                          'mission/duplicate',
                        )({
                          id: item.pk,
                          afterAction: fetchData,
                        }),
                      );
                    },
                    requires: PermissionCodes.changeMission,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={[
                      {
                        name:
                          item.status === PublishTagType.published
                            ? 'Unpublish'
                            : 'Publish',
                        action: () => {
                          dispatch(
                            createAction('mission/publishOrUnpublishMission')({
                              id: item.pk,
                              publish: item.status !== PublishTagType.published,
                              afterAction: fetchData,
                            }),
                          );
                        },
                        requires: PermissionCodes.changeMission,
                      },
                    ]}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];
  const buttons = [
    <AuthButton
      title="Create Mission"
      action={() => {
        history.push('mission/create');
      }}
      requires={PermissionCodes.changeMission}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: "",
          title: NavBarNames.mission,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="Mission"
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch(
            createAction('mission/delete')({
              deletePks,
              afterAction: fetchData,
            }),
          );
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  listDisplayFields: state.mission.listDisplayFields,
  pageInfo: state.mission.pageInfo,
  totalCount: state.mission.totalCount,
  totalPage: state.mission.totalPage,
  missionList: state.mission.currentPageMissionList,
  checkedList: state.mission.checkedList,
});

export default connect(mapStateToProps)(MissionList);
