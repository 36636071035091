import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getMessages = (
  afterCursor = '',
  rank = true,
  search = '',
  otherSearch = {},
) => {
  let orderBy = '-pk';
  if (!rank) {
    orderBy = 'pk';
  }
  if (otherSearch?.sort) {
    orderBy = otherSearch.sort;
  }

  let moreSearch = '';
  if (otherSearch.message_channel) {
    moreSearch += `, channelsIn: "${otherSearch.message_channel}"`;
  }
  if (otherSearch.delivery_time) {
    // const deliveryTimeRange = otherSearch.delivery_time.split(',');
    // moreSearch += `, scheduledDateGte: "${deliveryTimeRange[0]}", scheduledDateLte: "${deliveryTimeRange[1]}"`;
    moreSearch += `, scheduledDateEqual: "${otherSearch.delivery_time}"`;
  }
  if (otherSearch.status) {
    moreSearch += `, statusIn: "${otherSearch.status}"`;
  }

  if (otherSearch.message_type) {
    moreSearch += `, messageType: "${otherSearch.message_type}"`;
  }
  const query = `
    {
        messages(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", titleIcontains: "${search}" ${moreSearch}) {
          latestWelcomeMessageId
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              sendingState
              isDraft
              channels {
                  edges {
                      node {
                          pk
                      }
                  }
              }
              messageType
              scheduledDate
              title
              translations {
                edges {
                  cursor
                  node {
                    id
                    language
                    title
                  }
                }
              }
              informationBar {
                creationDate
                lastModifiedDate
                language
                informationType
                name
                content
                scheduledStartDate
                scheduledEndDate
                isAllUser
                pk
                translations {
                  edges {
                      node {
                          language
                          name
                          content
                          id
                          pk
                      }
                  }
                }
              }
              targetedCustomerGroups {
                  edges {
                      node {
                          pk
                          name
                      }
                  }
              }
              campaign {
                  pk
                  name
              }
            }
          }
        }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMessage = (id) => {
  const query = `
  {
    message(id: "${id}") {
      id
      pk
      channels {
          edges {
              node {
                  pk
              }
          }
      }
      messageType
      scheduledDate
      title
      content
      photo
      url
      isExclusive
      targetedCustomerGroups {
          edges {
              node {
                  pk
                  name
              }
          }
      }
      targetedSegments {
          edges {
              node {
                  pk
                  name
              }
          }
      }
      campaign {
          pk
          name
      }
      informationBar {
        creationDate
        lastModifiedDate
        language
        informationType
        name
        content
        scheduledStartDate
        scheduledEndDate
        isAllUser
        pk
        translations {
          edges {
              node {
                  language
                  name
                  content
                  id
                  pk
              }
          }
        }
      }
      sendingState
      translations {
          edges {
              node {
                language
                title
                content
                photo
                id
                pk
              }
          }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createInformationBarMessage = (inputBody) => {
  const query = `
  mutation CreateInformationBarMessage($input: CreateInformationBarMessageInput!) {
    createInformationBarMessage(input: $input) {
      informationBarNode {
        creationDate
        lastModifiedDate
        id
        language
        informationType
        name
        content
        url
        scheduledStartDate
        scheduledEndDate
        isAllUser
        pk
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createMessage = (inputBody) => {
  const query = `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      node {
          id
          pk
          channels {
              edges {
                  node {
                      pk
                  }
              }
          }
          scheduledDate
          title
          content
          photo
          url
          isExclusive
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteMessages = (ids) => {
  const query = `
  mutation DeleteMessages($input: DeleteMessagesInput!) {
    deleteMessages(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateMessage = (id) => {
  const query = `
  mutation CopyMessage($input: CopyMessageInput!) {
    copyMessage(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateInformationBarMessage = (inputBody) => {
  const query = `
  mutation UpdateInformationBarMessage($input: UpdateInformationBarMessageInput!) {
    updateInformationBarMessage(input: $input) {
      informationBarNode {
        creationDate
        lastModifiedDate
        id
        language
        informationType
        name
        content
        url
        scheduledStartDate
        scheduledEndDate
        isAllUser
        pk
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateMessage = (inputBody) => {
  const query = `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      node {
          id
          pk
          scheduledDate
          title
          content
          photo
          url
          isExclusive
          sendingState
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendTestMessageBeforeCreate = (inputBody) => {
  const query = `
  mutation TestMessageBeforeCreation($input: TestMessageBeforeCreationInput!) {
    testMessageBeforeCreation(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendTestMessageBeforeUpdate = (inputBody) => {
  const query = `
  mutation TestMessageBeforeUpdate($input: TestMessageBeforeUpdateInput!) {
    testMessageBeforeUpdate(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
