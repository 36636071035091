const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Campaign name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Campaign type', fieldName: 'typeDisplayWithExclusive' },
    { displayName: 'Brand', fieldName: 'brand', orderField: 'brand' },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
    },
    {
      displayName: 'Last Modified Date',
      fieldName: 'displayLastModifiedDate',
      orderField: 'lastModifiedDate',
    },
  ],
});

export default {
  namespace: 'whatsNewCampaignList',
  state: { ...getInitialState() },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {},
};
