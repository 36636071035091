import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { sessionDataKey } from '../../../models/RewardCodeModel';
import CreateRewardCodeStepOne from '../../../components/rewardCode/CreateRewardCodeStepOne';
import CreateRewardCodeStepTwo from '../../../components/rewardCode/CreateRewardCodeStepTwo';
import Loading from '../../../components/base/Loading';


const CreateRewardCode = ({
  stepConfig,
  currentStep,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'rewardCode/getRewardCode',
        payload: {
          id: params.id,
          afterAction: () => {
            setLoading(false);
          }
        }
      });
    } else {
      dispatch({
        type: 'rewardCode/loadRewardCodeFromCookie',
        payload: {
          afterAction: () => {
            setLoading(false);
          }
        }
      });
    }

    return () => {
      dispatch({ type: 'rewardCode/clearState' });
    };
  }, []);

  const stepContent = loading
    ? [<Loading />]
    : [
      <CreateRewardCodeStepOne />,
      <CreateRewardCodeStepTwo />
    ];

  return (
    <ScrollableComponent
      tempSave={(save) => {
        dispatch({
          type: 'rewardCode/saveOrRemoveRewardCodeFromCookie',
          payload: save,
        });
      }}
      sessionDataKey={sessionDataKey}
      content={stepContent}
      currentStep={currentStep}
      stepsConfig={stepConfig}
      breadcrumb={
        <CustomBreadcrumb
          name={
            params.id
              ? 'Edit Registration Code'
              : 'Create Registration Code'
          }
        />
      }
      caution={{
        detail: '',
        title: params.id
          ? 'Edit Registration Code'
          : 'Create Registration Code'
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  stepConfig: state.rewardCode.stepConfig,
  currentStep: state.rewardCode.currentStep,
});

export default connect(mapStateToProps)(CreateRewardCode);
