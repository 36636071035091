import { getNewBarcodes, getNewBarcode, getExistedBarcodeList, deleteNewBarcode, approveNewBarcode, rejectNewBarcode } from '../services/BarcodeApiHelper';
import {
  convertNumberToCursor,
  createAction,
  convertCursorToNumber,
  getFileNameFromUrl,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  LanguageConfig,
  BarcodeVolumeConversion,
  BarcodeValidateStatus,
  ImageDomain,
} from '../config/CustomEnums';
import { productTypes } from './BarcodeModel';

export const NewBarcodeError = {
  productImage: {
    name: 'productImage',
    message: 'Please provide an image',
  },
  productType: {
    name: 'productType',
    message: 'Please provide a type',
  },
  productNameEn: {
    name: 'productNameEn',
    message: 'Please provide a name',
  },
  productNameZh: {
    name: 'productNameZh',
    message: 'Please provide a name',
  },
  linkedBrand: {
    name: 'linkedBrand',
    message: 'Please provide a brand',
  },
  volumeQuantity: {
    name: 'volumeQuantity',
    message: 'Please provide a correct number',
  },
  volumeConversion: {
    name: 'volumeConversion',
    message: 'Please provide a type',
  },
};


const getInitialState = () => ({
  listDisplayFields: [
    {
      displayName: 'ID',
      fieldName: 'pk',
      linked: true,
      orderField: 'pk',
    },
    { displayName: 'Brand', fieldName: 'brand' },
    { displayName: 'Recyclable Type', fieldName: 'productTypeName' },
    { displayName: 'Photo', fieldName: 'defaultProductImage' },
    { displayName: 'Product name', fieldName: 'productName' },
    { displayName: 'Barcodes', fieldName: 'code' },
    { displayName: 'Volume', fieldName: 'volumeEntire' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  newBarcodeList: [],
  currentPageNewBarcodeList: [],
  existedBarcodeList: [],
  currentPageExistedBarcodeList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  newBarcodeDetail: {},
  errorFields: [],
});

const parseNewBarcode = (data) => {
  if (!data) {
    return {}
  };

  const linkedBarcode = data.barcode?.pk
    ? {
      id: data.barcode?.pk,
      name: `ID:${data.barcode?.pk} - ${data.barcode?.productName}`,
      productName: data.barcode?.productName,
    }
    : null;

  const linkedBrand = data.linkedBrand?.pk
    ? {
      pk: data.linkedBrand?.pk,
      name: data.linkedBrand?.name,
    }
    : null;
  const linkedBrandName = data.linkedBrand?.name;
  const status = BarcodeValidateStatus?.[data.barcodeStatus]?.name;
  const productType = {
    id: data.productType?.pk,
    name: data.productType?.name,
  };
  const volumeQuantity = data.volumeQuantity;
  const volumeConversion = BarcodeVolumeConversion?.[data.volumeConversion];
  const volumeConversionName = BarcodeVolumeConversion?.[data.volumeConversion]?.name;
  const volumeEntire = `${volumeQuantity}${volumeConversionName}`

  const translationsEdges = data.translations?.edges;
  let translations = {};
  if (translationsEdges?.length > 0) {
    translationsEdges.forEach((item) => {
      const node = item.node;
      translations[node.language] = {
        id: node.pk,
        language: node.language,
        productName: node.productName,
        productNameMapped: node.productNameMapped
      };
    });
  };
  translations[LanguageConfig.english] = {
    language: LanguageConfig.english,
    productName: data.productName,
  };

  const defaultProductImage = productTypes.find(item => item.pk == productType.id)?.iconName;

  return {
    id: data.pk,
    pk: data.pk,
    nextNewBarcodePk: data.nextNewBarcodePk,
    linkedBarcode,
    linkedBrand,
    brand: linkedBrandName || data.brandName || '',
    code: data.code || '',
    status,
    productType,
    productTypeName: data.productType?.name,
    defaultProductImage: data.productImage || `${ImageDomain}${defaultProductImage}`,
    productImage: data.productImage,
    productName: data.productName,
    volumeMapped: data.volumeMapped,
    brandMapped: data.brandMapped,
    productNameMapped: data.productNameMapped,
    volumeQuantity,
    volumeConversion: {
      label: volumeConversion?.name,
      value: volumeConversion?.value,
    },
    volumeEntire,
    translations,
  };
};

const getNewBarcodeError = (data) => {
  let errorFields = [];
  if (!data?.barcode) {
    // if (!data?.productImage) {
    //   errorFields.push(NewBarcodeError.productImage.name);
    // };
    if (!data?.productType) {
      errorFields.push(NewBarcodeError.productType.name);
    };
    if (!data?.productNameEn) {
      errorFields.push(NewBarcodeError.productNameEn.name);
    };
    if (!data?.productNameZh) {
      errorFields.push(NewBarcodeError.productNameZh.name);
    };
    if (!data?.linkedBrand) {
      errorFields.push(NewBarcodeError.linkedBrand.name);
    };
    if (!data?.volumeQuantity || data?.volumeQuantity <= 0) {
      errorFields.push(NewBarcodeError.volumeQuantity.name);
    };
    if (!data?.volumeConversion) {
      errorFields.push(NewBarcodeError.volumeConversion.name);
    };
    if(data.volumeConversion && ["G", "ML", "PCS"].includes(data.volumeConversion)) {
      if(parseInt(data.volumeQuantity || "") != data.volumeQuantity) {
        errorFields.push(NewBarcodeError.volumeQuantity.name);
      }
    }
  }

  return errorFields;
};

const assembleApproveNewBarcode = (data = {}) => {
  let productImage = data.productImage;
  if (data.productImage?.type) {
    productImage = data.productImage.value;
  };
  return {
    id: data.id,
    barcodeId: data.barcode || null,
    productType: data.productType,
    productName: data.productNameEn?.toUpperCase(),
    productImage: getFileNameFromUrl(productImage),
    volumeQuantity: data.volumeQuantity,
    volumeConversion: data.volumeConversion,
    linkedBrand: data.linkedBrand,
    translations: [
      { language: LanguageConfig.traditionalChinese, productName: data.productNameZh }
    ]
  };
}


export default {
  namespace: 'barcodeValidate',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
    updateList(state, { payload }) {
      const page = payload?.page;
      const currentPageList = payload?.currentPageNewBarcodeList;
      return {
        ...state,
        newBarcodeList: page > 1 ? [...state.newBarcodeList, ...currentPageList] : currentPageList,
      }
    },
    updateExistedBarcodeList(state, { payload }) {
      const page = payload?.page;
      const currentPageList = payload?.list;
      return {
        ...state,
        currentPageExistedBarcodeList: currentPageList,
        existedBarcodeList: page > 1 ? [...state.existedBarcodeList, ...currentPageList] : currentPageList,
      }
    },
  },
  effects: {
    getNewBarcodeList: [
      function* ({ payload }, { put }) {
        const { page, searchKey, sort } = payload;

        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getNewBarcodes,
          {
            afterCursor,
            order: sort,
            search: searchKey,
            others: payload,
          }
        ];
        function* onSuccess(data) {
          const pageInfo = data.newbarcodes.pageInfo;
          const totalCount = data.newbarcodes.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const newBarcodesData = data.newbarcodes.edges;
          const newBarcodeList = newBarcodesData.map((item) =>
            parseNewBarcode(item.node),
          );
          yield put({
            type: 'updateState',
            payload: {
              currentPageNewBarcodeList: newBarcodeList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              totalCount,
              currentLastCursor,
              totalPage: Math.ceil(totalCount / 20),
            }
          });
          yield put({
            type: 'updateList',
            payload: {
              currentPageNewBarcodeList: newBarcodeList,
              page: page,
            },
          })
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getNewBarcode: [
      function* ({ payload }, { put, select }) {
        const { id } = payload;
        const newBarcodeID = btoa(`NewBarcodeNode:${id}`);
        const serviceArgs = [getNewBarcode, newBarcodeID];

        function* onSuccess(data) {
          const newBarcodeData = data.newbarcode;
          const newBarcodeDetail = parseNewBarcode(newBarcodeData);
          yield put(
            createAction('updateState')({
              newBarcodeDetail: newBarcodeDetail,
              originalNewBarcodeDetail: newBarcodeDetail,
            }),
          );

          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getExistedBarcodeList: [
      function* ({ payload }, { put }) {
        const page = payload?.page;
        const searchKey = payload?.searchKey;

        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getExistedBarcodeList,
          {
            afterCursor,
            search: searchKey,
          }
        ];
        function* onSuccess(data) {
          const barcodesData = data.barcodes.edges;
          const barcodesList = barcodesData.map(item => {
            return {
              id: item.node?.pk,
              name: `ID:${item.node?.pk} - ${item.node?.productName}`,
              productName: item.node?.productName,
            };
          });
          yield put({
            type: 'updateExistedBarcodeList',
            payload: {
              list: barcodesList,
              page: page,
            },
          })
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const ids = payload?.ids;
        const { checkedList } = yield select((state) => ({
          checkedList: state.barcodeValidate.checkedList,
        }));
        const pks = ids || checkedList.map((item) => item.pk);
        const serviceArgs = [deleteNewBarcode, pks];
        const afterAction = payload?.afterAction || (() => { });
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { checkedList: [] },
          });
          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    checkNewBarcode: [
      function* ({ payload }, { select, put, all }) {
        const errorFields = getNewBarcodeError(payload.detail || {});
        console.log("@@369", errorFields)
        yield put({
          type: 'updateState',
          payload: {
            errorFields
          }
        })
        if (errorFields.length == 0) {
          yield put({ type: 'approveNewBarcode', payload })
        }
      },
      { type: 'takeLatest' },
    ],
    approveNewBarcode: [
      function* ({ payload }, { select, put, all }) {
        const input = assembleApproveNewBarcode(payload.detail || {})
        console.log("@@310", input)
        const serviceArgs = [approveNewBarcode, input];
        const afterAction = payload?.afterAction || (() => { });
        function* onSuccess(data) {
          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    rejectNewBarcode: [
      function* ({ payload }, { select, put, all }) {
        const input = {
          id: payload?.id,
          rejectReason: payload?.rejectReason || 0,
        }
        const serviceArgs = [rejectNewBarcode, input];
        const afterAction = payload?.afterAction || (() => { });
        function* onSuccess(data) {
          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
