import React, { useEffect, useState } from 'react';
import FilterableDropdown from '../base/FilterableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { CreatePointRecordError } from '../../models/CreatePointRecordModel';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { LanguageConfig } from '../../config/CustomEnums';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';

function CreatePointRecordGeneral(props) {
  // const isEdit = props.isEdit

  const dispatch = useDispatch();

  const {
    customers,
    errorFields,
    defaultSelectedCustomer,
    transactionType,
  } = useSelector((state) => ({
    customers: state.customerList.customerList,
    errorFields: state.createPointRecord.errorFields,
    defaultSelectedCustomer: state.createPointRecord.pointRecord.customer,
    transactionType: state.createPointRecord.pointRecord.transactionType,
  }));
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: defaultSelectedCustomer.owner,
    value: defaultSelectedCustomer,
  });

  const ownerOptions = customers.map((customer) => {
    return {
      value: customer,
      label: customer.owner,
    };
  });

  useEffect(() => {
    firstError(
      `${CreatePointRecordError.selectCustomer.name}`,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = () => {
    return isShowError(
      CreatePointRecordError.selectCustomer.name,
      errorFields,
      LanguageConfig.english,
    );
  };

  return (
    <>
      <div className="first-section-title">GENERAL</div>

      <CustomerFilterableDropdown
        title={'Customer'}
        // customers={customers}
        showMembershipId={true}
        defaultSelectedCustomer={selectedCustomer}
        selectCustomer={(item) => {
          setSelectedCustomer(item);
          dispatch(
            createAction('createPointRecord/updatePointRecordState')({
              customer: item.value,
            }),
          );
        }}
        errorFields={errorFields}
        errorName={CreatePointRecordError.selectCustomer.name}
        errorMessage={CreatePointRecordError.selectCustomer.message}
      />

      {/* {isEdit ? (
        <div
          style={{
            display: 'inline-grid',
          }}
        >
          <CustomTitleLabel title={'Transaction Type'} />
          <label className="custom-record-normal-font custom-timeline-time-color">
            {`${transactionType || '-'}`}
          </label>
        </div>
      ) : null} */}
    </>
  );
}

export default CreatePointRecordGeneral;
