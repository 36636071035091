import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import ProfileSection from '../../../components/mission/ProfileSection';
import ContentSection from '../../../components/mission/ContentSection';

function MissionDetail({
  missionDetail,
  missionAPIStatus
}) {
  const queryString = require('query-string');
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      createAction('mission/getMission')({
        id: params.id,
      }),
    );
  }, [params.id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('mission/clearState')());
    };
  }, []);

  const tabs = [
    {
      name: '',
      content:
        missionAPIStatus === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              sections={[
                <ProfileSection />,
                <ContentSection />
              ]}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => history.push('edit/')}
      requires={PermissionCodes.changeMission}
    />
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: 'View Mission',
        }}
        breadcrumb={<CustomBreadcrumb name={missionDetail?.name}/>}
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
      <OnlyBackButton
        backAction={() => {
          dispatch(createAction('mission/clearState')());
          history.push({
            pathname: '/mission',
            search: queryString.stringify({
              page: 1,
              rank: true,
              search: '',
            }),
          });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  missionDetail: state.mission.missionDetail,
  missionAPIStatus: state.mission.missionAPIStatus,
});

export default connect(mapStateToProps)(MissionDetail);
