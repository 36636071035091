import React from 'react';
import ContentSections from '../base/ContentSections';
import GeneralSection from './GeneralSection';
import ActivePeriodSection from './ActivePeriodSection';
import KeyVisualSection from './KeyVisualSection';

function CreateGreetingCardStepOne() {

  const sections = [
    <GeneralSection />,
    <KeyVisualSection />,
    <ActivePeriodSection />,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
    </>
  );
}

export default CreateGreetingCardStepOne;
