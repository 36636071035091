import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateGreetingSectionError } from '../../models/GreetingSectionModel';
import { IMAGE_TYPES } from '../../models/UploadFilesModel';
import { isShowError } from '../../utils';
import ImageUploader from '../base/ImageUploader';
import CustomRadios from '../base/CustomRadios';
import { Row } from 'react-bootstrap';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { BackgroundImageStyle } from '../../config/CustomEnums';

const BackgroundImageStyleOptions = [
  {
    label: 'Yellow',
    value: BackgroundImageStyle.YELLOW,
  },
  {
    label: 'Custom',
    value: BackgroundImageStyle.CUSTOM,
  },
];

function KeyVisualSection({
  backgroundImageStyle,
  backgroundImageGuestView,
  backgroundImageSignedInView,
  errorFields,
}) {
  const dispatch = useDispatch();
  const defaultImages = [backgroundImageGuestView]?.filter(item => item);
  const defaultImages2 = [backgroundImageSignedInView]?.filter(item => item);
  const [guestViewPhoto, setGuestViewPhoto] = useState(defaultImages);
  const [userViewPhoto, setUserViewPhoto] = useState(defaultImages2);

  const getImageUrl = (data) => {
    if (!data) {
      return '';
    }
    if (data?.type !== IMAGE_TYPES.TYPE_URL) {
      return '';
    }
    return data?.value;
  };

  const updateGreetingSectionDetail = (payload) => {
    dispatch({
      type: 'greetingSection/updateGreetingSectionDetail',
      payload,
    });
  }

  return (
    <>
      <label className="create-section-title">background image</label>
      {/* Background image style */}
      <div>
        <label className="create-section-label create-section-label-bottom-space">
          Background image style
        </label>
        <Row className="featured-exclusive-row">
          <CustomRadios
            disabled={false}
            onChange={(value) => {
              updateGreetingSectionDetail({ backgroundImageStyle: value })
            }}
            default={backgroundImageStyle}
            options={BackgroundImageStyleOptions}
          />
        </Row>
        <ErrorFieldMessage
          id={CreateGreetingSectionError.backgroundImageStyle.name}
          error={isShowError(
            CreateGreetingSectionError.backgroundImageStyle.name,
            errorFields,
          )}
          message={CreateGreetingSectionError.backgroundImageStyle.message}
        />
      </div>
      {backgroundImageStyle == BackgroundImageStyle.CUSTOM &&
        <>
          {/* Custom Image (Guest view) */}
          <>
            <label className="create-section-label create-section-label-bottom-space">
              Custom Image (Guest view)
            </label>
            <label className="create-campaign-cover-photo-reminder">
              Suggested Image Size: 750px * 440px (Less than 2MB, support JPG, PNG and GIF only)
            </label>
            <ImageUploader
              minWidth={375}
              minHeight={220}
              images={guestViewPhoto}
              onImageStateChange={(newState) => {
                setGuestViewPhoto(newState);
                updateGreetingSectionDetail({ backgroundImageGuestView: getImageUrl(newState?.[0]) })
              }}
              aspect={75 / 44}
              maxImageNum={1}
              language={'en'}
              photoSectionTitle={'custom_image_guest'}
              uploadImageClicked={() => { }}
              errorMessage={`${isShowError(
                CreateGreetingSectionError.customImageGuest.name,
                errorFields,
              )
                ? CreateGreetingSectionError.customImageGuest.message
                : ''
                }`}
            />
          </>
          {/* Custom Image (Signed-in view) */}
          <>
            <label className="create-section-label create-section-label-bottom-space">
              Custom image (Signed-in view)
            </label>
            <label className="create-campaign-cover-photo-reminder">
              Suggested Image Size: 750px * 380px (Less than 2MB, support JPG, PNG and GIF only)
            </label>
            <ImageUploader
              minWidth={375}
              minHeight={190}
              images={userViewPhoto}
              onImageStateChange={(newState) => {
                setUserViewPhoto(newState);
                updateGreetingSectionDetail({ backgroundImageSignedInView: getImageUrl(newState?.[0]) })
              }}
              aspect={75 / 38}
              maxImageNum={1}
              language={'en'}
              photoSectionTitle={'custom_image_signed_in'}
              uploadImageClicked={() => { }}
              errorMessage={`${isShowError(
                CreateGreetingSectionError.customImageSignedIn.name,
                errorFields,
              )
                ? CreateGreetingSectionError.customImageSignedIn.message
                : ''
                }`}
            />
          </>

        </>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  backgroundImageStyle: state.greetingSection.greetingSection.backgroundImageStyle,
  backgroundImageGuestView: state.greetingSection.greetingSection.backgroundImageGuestView,
  backgroundImageSignedInView: state.greetingSection.greetingSection.backgroundImageSignedInView,
  errorFields: state.greetingSection.errorFields,
});

export default connect(mapStateToProps)(KeyVisualSection);
