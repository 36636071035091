import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import GuideImageGeneralSection from './GuideImageGeneralSection';
import GuideImageInstruction from './GuideImageInstruction';
import './GuideImageEdit.scss';
import Loading from '../../../components/base/Loading';
import { guideTypeStrings } from '../../../models/GuideImageModel';

const GuideImageEdit = ({
  detail,
  detailApiStatus,
  saveApiStatus,
  errorFields,
  errorTab,
  receiptPartnerListCompleted,
  oclPartnerListCompleted,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const hideGeneralSection = [guideTypeStrings.PHOTO_TAKING, guideTypeStrings.BARCODE].includes(detail.typeTitle);

  const getTabs = (language) => {
    if (language === LanguageConfig.english && !hideGeneralSection) {
      return [
        <GuideImageGeneralSection />,
        <GuideImageInstruction
          language={language}
          type='first'
        />,
        <GuideImageInstruction
          language={language}
          type='second'
        />,
      ]
    } else {
      return [
        <GuideImageInstruction
          language={language}
          type='first'
        />,
        <GuideImageInstruction
          language={language}
          type='second'
        />,
      ];
    };
  };

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
    hansContainer: getTabs(LanguageConfig.simplifiedChinese),
  };

  const content =
    detailApiStatus === APIStatus.success
      && receiptPartnerListCompleted
      && oclPartnerListCompleted
      ? [
        <ContentSections
          fieldsError={errorFields}
          errorTab={errorTab}
          languageTabContent={languageTabConfig}
          hidePreview={true}
        />,
      ] : [
        <Loading />
      ];

  useEffect(() => {
    const guideID = params.id;
    if (guideID) {
      dispatch({ type: 'guideImage/getGuideImage', payload: { id: guideID } });
    }
    dispatch({
      type: 'brand/getCurrentPageBrands',
      payload: {
        page: 1,
        isAll: true,
        moreSearch: {
          sort: 'name',
          receiptPartner: true,
        },
      }
    });
    dispatch({
      type: 'brand/getCurrentPageBrands',
      payload: {
        page: 1,
        isAll: true,
        moreSearch: {
          sort: 'name',
          oclPartner: true,
        },
      }
    });
    return () => {
      dispatch({ type: 'guideImage/clearData' });
      dispatch({
        type: 'brand/updateState',
        payload: {
          receiptPartnerList: [],
          receiptPartnerListCompleted: false,
          oclPartnerList: [],
          oclPartnerListCompleted: false,
        }
      })
    }
  }, []);

  useEffect(() => {
    if (saveApiStatus === APIStatus.success) {
      history.push('/guide_image');
    }
  }, [saveApiStatus]);

  return (
    <>
      <ScrollableComponent
        caution={{
          detail: '',
          title: detail.typeTitle,
        }}
        breadcrumb={<CustomBreadcrumb name={detail.typeTitle} />}
        content={content}
        currentStep={0}
      />
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={(event) => {
          dispatch({
            type: 'guideImage/updateGuideImage',
            payload: {},
          })
        }}
        backAction={() => history.goBack()}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  detail: state.guideImage.guideDetail,
  errorFields: state.guideImage.errorFields,
  errorTab: state.guideImage.errorTab,
  detailApiStatus: state.guideImage.detailApiStatus,
  saveApiStatus: state.guideImage.saveApiStatus,
  receiptPartnerListCompleted: state.brand.receiptPartnerListCompleted,
  oclPartnerListCompleted: state.brand.oclPartnerListCompleted,
});

export default connect(mapStateToProps)(GuideImageEdit);
