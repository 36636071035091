import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getGreetingSectionList = (
  afterCursor,
  others,
) => {
  let orderBy = '-pk';
  if (others?.orderBy) {
    orderBy = others?.orderBy;
  };
  // const filterName = others?.searchKey || '';

  const query = `{
      greetingSections(after:"${afterCursor}", orderBy: "${orderBy}") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            name
            missionCardStyle
            startDate
            endDate
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGreetingSection = (id) => {
  const query = `{
    greetingSection(id: "${id}") {
      pk
      id
      name
      missionCardStyle
      isCustomBackgroundUsed
      backgroundImageGuestView
      backgroundImageSignedInView
      startDate
      endDate
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createGreetingSection = (input) => {
  const query = `mutation CreateGreetingSection($input: CreateGreetingSectionInput!) {
    createGreetingSection(input: $input) {
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGreetingSection = (input) => {
  const query = `mutation UpdateGreetingSection($input: UpdateGreetingSectionInput!) {
    updateGreetingSection(input: $input) {
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteGreetingSections = (ids) => {
  const query = `mutation DeleteGreetingSections($input: DeleteGreetingSectionsInput!) {
    deleteGreetingSections(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
