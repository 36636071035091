import React, { useState, useEffect } from 'react';
import { GroupMissionItemTypeList, LanguageConfig, MissionType } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateMissionError, MissionEarningIcon, MissionEarningIconList, MissionIconList, HabitualMisstionType, MissionTriggerList } from '../../models/MissionModel';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomEditor from '../base/CustomEditor';
import CustomInputWithMaxLength from '../base/CustomInputWithMaxLength';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import './MissionGeneralSection.scss';
import LinkedCampaignSection from './LinkedCampaignSection';
import SetLevelsSection from './SetLevelsSection';
import GroupMissionSection from './GroupMissionSection';

function MissionGeneralSection({
  language = LanguageConfig.english,
  onSubmit = false,
  onSubmitData = (() => { }),
  communityList,
  missionDetail,
  errorFields,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const isEdit = !!params?.id;

  const fromCommunity = history.location?.state?.fromCommunity || {};
  const communityId = parseInt(fromCommunity?.pathname?.split('/')?.[2] || '') || null;

  const {
    icon,
    trigger,
    translations = {},
    missionType,
    linkedCampaign,
    joinedTimes,
    recyclingItems,
    communityOnly,
    linkedCommunity = {},
    missionEarningType,
    setLevels,
    missionItems,
    totalTarget,
    individualTarget,
  } = missionDetail;
  const missionTitle = translations?.[language]?.name;
  const missionDescriptionTitle = translations?.[language]?.descriptionTitle;
  const missionDesctiptionContent = translations?.[language]?.descriptionContent;
  const habitualLevels = translations?.[language]?.habitualLevels || [];
  const progressUnit = translations?.[language]?.progressUnit || '';
  const isOnBoarding = missionType === MissionType.onboardingMission;
  const isHabitual = missionType === MissionType.habitual;
  const isGroup = missionType === MissionType.group;
  const reminderEligible = translations?.[language]?.reminderEligible;
  const reminderIneligible = translations?.[language]?.reminderIneligible;
  const reminderComplete = translations?.[language]?.reminderComplete;
  const reminderExpired = translations?.[language]?.reminderExpired;

  const isRecycling = missionEarningType?.value?.value === MissionEarningIcon.PHOTO_TAKING.value;

  useEffect(() => {
    if (onSubmit) {
      const data = {
        language: language,
        name: missionTitle,
        descriptionTitle: missionDescriptionTitle,
        descriptionContent: missionDesctiptionContent,
        icon: icon,
        trigger: trigger,
        communityOnly: communityOnly,
        linkedCommunity: linkedCommunity,
        missionType: missionType,
        linkedCampaign: linkedCampaign,
        joinedTimes: joinedTimes,
        recyclingItems: recyclingItems,
        missionEarningType: missionEarningType,
        setLevels: setLevels,
        progressUnit: progressUnit,
        habitualLevels: habitualLevels,
        missionItems,
        totalTarget,
        individualTarget,
        reminderEligible,
        reminderIneligible,
        reminderComplete,
        reminderExpired,
      };
      if (language !== LanguageConfig.english && translations[language]?.pk) {
        data.pk = translations[language]?.pk;
        data.id = translations[language]?.id;
      }
      onSubmitData(data);
    }
  }, [onSubmit]);

  useEffect(() => {
    firstError(
      `${CreateMissionError.title.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      CreateMissionError.icon.name,
      errorFields,
      language,
    );
    firstError(
      CreateMissionError.triggerPage.name,
      errorFields,
      language,
    );
  }, [errorFields]);

  const isError = (field, onlyEnglish = true) => {
    if (!onlyEnglish) {
      return errorFields.indexOf(`${field}-${language}`) > -1
    };
    return errorFields.indexOf(field) > -1 && language == LanguageConfig.english;
  };

  const section = (missionType, language) => {
    switch (missionType) {
      case MissionType.onboardingMission:
        return language === LanguageConfig.english ? (
          <CustomTitleWithDropDown
            needFilter={false}
            title={'Trigger page'}
            titleStyle='mission-trigger-page-title'
            source={MissionTriggerList}
            defaultValue={trigger}
            setValue={(value) => {
              dispatch({
                type: 'mission/updateMissionDetail',
                payload: { trigger: value },
              });
            }}
            loadMoreAction={() => { }}
            filterAction={() => { }}
            errors={errorFields}
            errorName={CreateMissionError.triggerPage.name}
          />
        ) : null;
      case MissionType.campaignLinked:
        return language === LanguageConfig.english ? (
          <LinkedCampaignSection
            linkedCampaign={linkedCampaign}
            joinedTimes={joinedTimes}
            recyclingItems={recyclingItems}
            disableChangeCampaign={isEdit}
            errorFields={errorFields}
            isRecycling={icon?.value?.value === MissionEarningIcon.PHOTO_TAKING.value}
          />
        ) : null;
      case MissionType.habitual:
        return (
          <>
            <CustomInputWithMaxLength
              sectionTitle={i18n.t('mission_progress_unit', { locale: language })}
              value={progressUnit}
              valueChange={(value) => {
                dispatch({
                  type: 'mission/updateMissionDetailTranslations',
                  payload: { progressUnit: value, language },
                });
              }}
              maxLength={10}
              showCount
              error={isShowError(CreateMissionError.progressUnit.name, errorFields, language)}
              errorMessage={CreateMissionError.progressUnit.message}
              errorId={`${CreateMissionError.progressUnit.name}-${language}`}
            />
            <label className="tips-message">{i18n.t('mission_unit_max', { locale: language })}</label>
            {language === LanguageConfig.english ? (
              <LinkedCampaignSection
                isRecycling={isRecycling}
                linkedCampaign={linkedCampaign}
                joinedTimes={joinedTimes}
                recyclingItems={recyclingItems}
                disableChangeCampaign={isEdit}
                errorFields={errorFields}
                hideNumberSection={true}
                showRemoveButton={true}
              />
            ) : null}
            <SetLevelsSection
              errorFields={errorFields}
              missionDetail={missionDetail}
              setLevels={setLevels}
              habitualLevels={habitualLevels}
              isRecycling={
                isRecycling
                || linkedCampaign?.value?.earningRule?.type === MissionEarningIcon.PHOTO_TAKING.value
              }
              language={language}
            />
          </>
        );
      case MissionType.group:
        return (
          <GroupMissionSection
            language={language}
            missionEarningType={missionEarningType}
            linkedCampaign={linkedCampaign}
            disableChange={isEdit}
            errorFields={errorFields}
            totalTarget={totalTarget}
            individualTarget={individualTarget}
            reminderEligible={reminderEligible}
            reminderIneligible={reminderIneligible}
            reminderComplete={reminderComplete}
            reminderExpired={reminderExpired}
          />
        );
      default:
        break;
    }
  }

  const getMissionIconList = (missionType) => {
    switch (missionType) {
      case MissionType.onboardingMission:
        return MissionIconList;
      case MissionType.campaignLinked:
        return MissionEarningIconList;
      case MissionType.habitual:
        return HabitualMisstionType;
      case MissionType.group:
        return HabitualMisstionType;
      default:
        break;
    }
  }

  const maxMissionTitle = () => {
    if (isHabitual) {
      return language === LanguageConfig.english ? 56 : 26;
    } else if (isGroup) {
      return language === LanguageConfig.english ? 70 : 34;
    } else {
      return 23;
    }
  };

  const maxMissionDescription = () => {
    if (isHabitual) {
      return language === LanguageConfig.english ? 145 : 72;
    } else if (isGroup) {
      return language === LanguageConfig.english ? 130 : 70;
    };
    return '';
  }

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>

      <CustomInputWithMaxLength
        sectionTitle={i18n.t('name', { locale: language })}
        value={missionTitle}
        valueChange={(value) => {
          dispatch({
            type: 'mission/updateMissionDetailTranslations',
            payload: { name: value, language },
          });
        }}
        maxLength={maxMissionTitle()}
        showCount
        error={isError(CreateMissionError.title.name)}
        errorMessage={CreateMissionError.title.message}
        errorId={`${CreateMissionError.title.name}-${language}`}
      />

      {isHabitual || isGroup ? (
        <>
          <CustomTitleLabel
            title={i18n.t('description_content', { locale: language })}
          />
          <label className="tips-message">{i18n.t("mission_description_max", { locale: language, max: maxMissionDescription() })}</label>
          <textarea
            onChange={({ target }) => {
              dispatch({
                type: 'mission/updateMissionDetailTranslations',
                payload: { descriptionContent: target.value, language },
              });
            }}
            className={`custom-textarea-input-box custom-textarea-input-font ${isError(CreateMissionError.descriptionContent.name, false) || isError(CreateMissionError.groupDescriptionContent.name, false)
              ? 'error-field'
              : ''
              }`}
            value={missionDesctiptionContent}
          />
          <ErrorFieldMessage
            id={`${CreateMissionError.descriptionContentEmpty.name}`}
            error={isError(CreateMissionError.descriptionContentEmpty.name, true)}
            message={CreateMissionError.descriptionContentEmpty.message}
          />
          <ErrorFieldMessage
            id={`${CreateMissionError.descriptionContent.name}-${language}`}
            error={isError(CreateMissionError.descriptionContent.name, false)}
            message={i18n.t('mission_description_content_error', { locale: language })}
          />
          <ErrorFieldMessage
            id={`${CreateMissionError.groupDescriptionContent.name}-${language}`}
            error={isError(CreateMissionError.groupDescriptionContent.name, false)}
            message={i18n.t('group_mission_description_content_error', { locale: language })}
          />
        </>
      ) : (
        <>
          <CustomInputWithMaxLength
            sectionTitle={i18n.t('description_title', { locale: language })}
            value={missionDescriptionTitle}
            valueChange={(value) => {
              dispatch({
                type: 'mission/updateMissionDetailTranslations',
                payload: { descriptionTitle: value, language },
              });
            }}
            maxLength={36}
            showCount
          />

          <CustomTitleLabel title={i18n.t('description_content_optional', { locale: language })} />
          <CustomEditor
            initialValue={missionDesctiptionContent}
            onValueChange={(value) => {
              dispatch({
                type: 'mission/updateMissionDetailTranslations',
                payload: { descriptionContent: value, language },
              });
            }}
          />
        </>
      )}

      {language === LanguageConfig.english
        ? <>
          {
            isGroup && communityOnly
              ? <>
                <CustomTitleWithDropDown
                  title={'Link to community'}
                  source={communityList}
                  defaultValue={linkedCommunity}
                  loadMoreAction={'communityModel/getList'}
                  filterAction={'communityModel/getList'}
                  showRemoveButton={true}
                  disabled={isEdit || communityId}
                  onRemoveButtonClick={() => {
                    dispatch({
                      type: 'mission/updateMissionDetail',
                      payload: { linkedCommunity: {} },
                    });
                  }}
                  defaultFilter={{}}
                  setValue={(value) => {
                    dispatch({
                      type: 'mission/updateMissionDetail',
                      payload: { linkedCommunity: value },
                    });
                  }}
                />
                <ErrorFieldMessage
                  id={CreateMissionError.linkedCommunity.name}
                  error={isError(CreateMissionError.linkedCommunity.name)}
                  message={CreateMissionError.linkedCommunity.message}
                />
              </>
              : null
          }

          <CustomTitleWithDropDown
            isImageField={!(isHabitual || isGroup)}
            disabled={!isOnBoarding && isEdit}
            needFilter={false}
            title={isHabitual || isGroup ? 'Mission earning type' : 'Mission icon'}
            source={getMissionIconList(missionType)}
            defaultValue={isHabitual || isGroup ? missionEarningType : icon}
            setValue={(value) => {
              const payload = isHabitual || isGroup ? {
                missionEarningType: value,
                linkedCampaign: {
                  label: null,
                  value: null,
                }
              } : {
                icon: value,
              }
              dispatch({
                type: 'mission/updateMissionDetail',
                payload: payload,
              });
            }}
            loadMoreAction={() => { }}
            filterAction={() => { }}
            errors={errorFields}
            errorName={CreateMissionError[isHabitual || isGroup ? 'missionEarningType' : 'icon'].name}
            errorMessage={CreateMissionError[isHabitual || isGroup ? 'missionEarningType' : 'icon'].message}
            showRemoveButton={isHabitual || isGroup}
            onRemoveButtonClick={() => {
              dispatch({
                type: 'mission/updateMissionDetail',
                payload: { missionEarningType: '' },
              });
            }}
          />
          <ErrorFieldMessage
            id={CreateMissionError.groupMissionEarningTypeOrLinkedCampaign.name}
            error={isError(CreateMissionError.groupMissionEarningTypeOrLinkedCampaign.name)}
            message={CreateMissionError.groupMissionEarningTypeOrLinkedCampaign.message}
          />
          {
            (isHabitual || isGroup) && isRecycling
              ? <CustomTitleWithDropDown
                title={'Mission item(s)'}
                source={GroupMissionItemTypeList}
                defaultValue={missionItems}
                disabled={isEdit}
                needFilter={false}
                showRemoveButton={false}
                setValue={(value) => {
                  dispatch({
                    type: 'mission/updateMissionDetail',
                    payload: { missionItems: value },
                  });
                }}
                errors={errorFields}
                errorName={CreateMissionError.missionItems.name}
              />
              : null
          }
        </>
        : null
      }
      {section(missionType, language)}
    </>
  );
}

const mapStateToProps = (state) => ({
  communityList: state.communityModel.communityList,
  missionDetail: state.mission.missionDetail,
  errorFields: state.mission.errorFields,
});

export default connect(mapStateToProps)(MissionGeneralSection);
