import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import {
  TRANSACTION_RECORD_TYPE,
  TRANSACTION_EVENT_TYPE,
} from './TransactionList';
import {
  OnlyBackButton,
  SaveAndBackButtons,
  SaveAndBackWithOutTempButtons,
} from '../../../components/base/BottomStepComponent';
import { transactionQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import TransactionActionPrompt from '../../../components/transactions/TransactionActionPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Loading from '../../../components/base/Loading';
import { getGeneralSectionData, getNumberSectionData } from '../../../components/transactions/CreateTransactionStepFour';
import CreateTransactionStepFourContentSection from '../../../components/transactions/CreateTransactionStepFourContentSection';
import CreateTransactionStepFourSection from '../../../components/transactions/CreateTransactionStepFourSection';
import { EarningRuleType } from '../../../config/CustomEnums';
import TransactionPhotoTakingOverview from './TransactionPhotoTakingOverview';

const totalValueOnChange = (value) => {
  const onlyNumber = value?.replace(/[^0-9.]/g, '') || "";
  const numberList = onlyNumber?.split('.') || [""];
  const validValue = numberList?.map((item, index) => {
    if (index === 1) {
      return item?.length > 2 ? item?.slice(0, 2) : item
    }
    return item
  })
  return (`${validValue?.[0] || ""}${validValue?.length > 1 ? "." : ""}${validValue?.[1] || ""}`);
};

const TransactionOverView = ({
  selectedTransaction,
  transaction,
  nextTransactionPk,
}) => {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const transactionPK = useParams().id;

  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [promptActionTitle, setPromptActionTitle] = useState();
  const [rejectReason, setRejectReason] = useState({ value: null, label: null });

  const isRecycling = [EarningRuleType.photoTaking, EarningRuleType.barcode].includes(selectedTransaction?.transactionType)

  useEffect(() => {
    dispatch({
      type: 'transactions/getTransaction',
      payload: { transactionPK, withRecentTransactions: true }
    });
    return () => {
      dispatch({ type: 'transactions/clearSelectedTransaction' })
      dispatch({ type: 'createTransaction/clearDetailFields' })
    }
  }, [dispatch, transactionPK]);

  const getFieldsWithTransactionType = () => {
    const transactionType = selectedTransaction.transactionDisplayType;
    let eventType = [];
    let dateExtraData = [
      [{ title: 'Transaction date', field: 'transactionDetailDisplayDate' }],
    ];
    let extraData = [];
    switch (transactionType) {
      case TRANSACTION_RECORD_TYPE.TYPE_ONLINE:
        eventType = [{ title: 'Online event type', field: 'onlineEventType' }];
        extraData = [
          [{ title: 'Shipping fee', field: 'shippingFee' }],
          [{ title: 'Other charges', field: 'otherCharge' }],
          [{ title: 'Total value', field: 'displayTotalValue' }],
        ];
        break;
      case TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING:
        eventType = [{ title: 'Status', field: 'displayOfflineEventType' }];
        extraData = [
          [{ title: 'Total Amount', field: 'totalRewardAmount' }],
          [{ title: 'Receipt image', field: 'receiptImage' }],
          [{ title: 'Total Carbon', field: 'totalRewardCarbon' }],
          [{ title: 'Total Point', field: 'totalRewardPoint' }],
        ];
        break;
      case TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS:
        eventType = [
          { title: 'Offline event type', field: 'displayOfflineEventType' },
        ];
        break;
      case TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING:
        eventType = [{ title: 'Status', field: 'displayOfflineEventType' }];
        extraData = [
          [{ title: 'Water volume', field: 'waterVolume' }],
          [{ title: 'Campaign', field: 'campaignName' }],
          [{ title: 'Machine ID', field: 'invoiceId' }],
        ];
        break;
      default:
        break;
    }

    const data = [
      [{ title: 'Customer', field: 'name' }, { title: 'Membership ID', field: 'membershipId' }],
      [{ title: 'Creation date', field: 'transactionDetailCreationDate' }],

      ...dateExtraData,
      [{ title: 'Merchant Name', field: 'merchantName' }],
      [{ title: 'Store', field: 'storeName' }],
      eventType,
      [{ title: 'Staff name', field: 'staffName' }],
      [{ title: 'Remarks (optional)', field: 'remarks' }],
      ...extraData,
    ];


    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
      const generalPurchaseEditField =
        transaction?.offlineEventType === "WAITING_FOR_APPROVAL"
          ? [
            [{
              title: 'Transaction Amount',
              field: 'displayTotalValue',
              editable: true,
              editAction: 'createTransaction/changeTotalValue',
              editError: transaction?.editTotalValueError,
              editValue: transaction?.editTotalValue,
              inputOnChange: totalValueOnChange,
            }],

            [{
              title: 'Payment date',
              field: 'transactionDetailDisplayDate',
              editable: true,
              description: 'Please input the required date format: 2023-05-10 15:22:00',
              placeholder: 'Input date format (e.g 2023-05-10 15:22:00)',
              editAction: 'createTransaction/changeTransactionDate',
              editError: transaction?.editDateError,
              editValue: transaction?.editDate,
            }],
          ]
          : [];

      const generalPurchaseRewardField =
        transaction?.offlineEventType === "APPROVED"
          ? [[{ title: 'REWARDS TO EARN UPON APPROVAL', field: '', isSeparateTitle: true }],
          [{ title: 'displayGeneralPurchaseSubType', field: 'displayGeneralPurchaseSubType' }],
          [
            { title: 'Total Carbon', field: 'totalRewardCarbon', titleClassName: 'transaction-general-purchase-detail-margin-top-0' },
            { title: 'Total CW Point', field: 'totalRewardPoint', titleClassName: 'transaction-general-purchase-detail-margin-top-0' },
            { title: '', field: '' }
          ]]
          : [];
      const generalPurchaseField = [
        [
          { title: 'Customer', field: 'name' },
          { title: 'Membership ID', field: 'membershipId' },
          { title: 'Creation date', field: 'transactionDetailCreationDate' }
        ],
        [
          { title: 'Status', field: 'displayOfflineEventType' },
          { title: 'Duplicated with OCL record?', field: 'duplicatedWithOclRecord' },
          { title: 'Duplicated with receipt scanning record?', field: 'duplicatedWithReceiptRecord' },
        ],

        [{ title: 'RECEIPT DETAILS', field: '', isSeparateTitle: true }],
        [
          { title: 'Transaction Amount', field: 'displayTotalValue', titleClassName: 'transaction-general-purchase-detail-title-color' },
          { title: 'Payment date', field: 'transactionDetailDisplayDate', titleClassName: 'transaction-general-purchase-detail-title-color' },
          { title: 'General Purchase Type', field: 'displayGeneralPurchaseSubType', titleClassName: 'transaction-general-purchase-detail-title-color' }
        ],
        [
          { title: 'Merchant Name', field: 'merchantName', titleClassName: 'transaction-general-purchase-detail-title-color' },
          { title: 'Branch', field: 'branch' },
          { title: '', field: '' }
        ],
        [
          { title: 'Payment method', field: 'displayGeneralPurchasePaymentMethod' },
          { title: 'Remarks (optional)', field: 'remarks' },
          { title: '', field: '' }
        ],
        [{ title: 'Receipt image', field: 'receiptImage' }],

        ...generalPurchaseEditField,

        [{ title: 'SKU', field: 'purchasedItems', isSeparateTitle: true }],

        ...generalPurchaseRewardField,
      ]
      return generalPurchaseField;
    }

    return data;
  };

  const getContentSections = () => {
    if (transaction?.transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) {
      return [
        <CreateTransactionStepFourSection title="GENERAL" fieldsData={getGeneralSectionData(transaction)} />,
        <CreateTransactionStepFourContentSection />,
        <CreateTransactionStepFourSection title="ADD CARBON / CW POINT(S)" fieldsData={getNumberSectionData(transaction)} />,
      ];
    };

    let sections = [
      <MembershipInfoCard
        title="TRANSACTION DETAILS"
        data={
          selectedTransaction.transactionDisplayType ===
            TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
            ? transaction
            : selectedTransaction
        }
        fields={getFieldsWithTransactionType(
          selectedTransaction.transactionDisplayType,
        )}
      />,
    ];
    let purchaseItemSectionList = selectedTransaction.photoTakingItems?.edges?.map(
      (item, index) => {
        return (
          <MembershipInfoCard
            title={`Recycling ITEM #${index + 1}`}
            data={item.node}
            fields={[
              [{ title: 'Recycling name', field: 'name' }],
              [{ title: 'Amount', field: 'quantity' }],
            ]}
          />
        );
      },
    );

    sections = sections.concat(purchaseItemSectionList);
    return sections;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          {isRecycling
            ?
            <TransactionPhotoTakingOverview />
            : <ContentSections
              sections={getContentSections()}
              hidePreview={true}
            // sectionStyle="transaction-record-detail-section"
            />
          }
        </div>
      ),
    },
  ];

  const goBackToList = () => {
    return history.push({
      pathname: '/transactions',
      search: queryString.stringify({
        [transactionQueryKey.searchKey]: '',
        [transactionQueryKey.page]: 1,
        [transactionQueryKey.rank]: true,
        [transactionQueryKey.tab]: 'all',
      }),
    });
  };

  const backAction = () => {
    dispatch(createAction('transactions/clearSelectedTransaction')());
    goBackToList();
  };

  const redirectToNextTransaction = () => {
    if (!nextTransactionPk) {
      backAction();
      return;
    }
    dispatch(createAction('transactions/clearSelectedTransaction')());
    history.push({
      pathname: `/transactions/${nextTransactionPk}`
    })
  };

  console.log('@@offlineEventType:', selectedTransaction);

  const transactionAction = (actionType) => {
    const promptAction = actionType || promptActionTitle;
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    ) {
      dispatch(
        createAction('createTransaction/updateTransactionSKU')({
          checkSku: promptAction === "approve",
          afterActions: () => {
            dispatch(
              createAction(
                `transactions/${promptAction}Transaction`,
              )({
                transactionPK: selectedTransaction.pk,
                afterActions: () => redirectToNextTransaction(),
                ...promptAction === 'reject'
                  ? { rejectReason: rejectReason }
                  : {}
                ,
              }),
            );
          },
        }),
      );
    } else {
      dispatch(
        createAction(`transactions/${promptAction}Transaction`)({
          transactionPK: selectedTransaction.pk,
          afterActions: () => redirectToNextTransaction(),
          ...promptAction === 'reject'
            ? { rejectReason: rejectReason }
            : {}
          ,
        }),
      );
    }
  };

  const getBottomSection = () => {
    if (
      selectedTransaction.displayOfflineEventType ===
      TRANSACTION_EVENT_TYPE.TYPE_WAITING
    ) {
      return (
        <SaveAndBackButtons
          backAction={backAction}
          saveTempText={'Reject'}
          saveTempAction={() => {
            transactionAction('reject');
          }}
          secondSaveTempText={'Reject with message'}
          secondSaveTempAction={() => {
            setPromptActionTitle('reject');
            setShowActionPrompt(true);
          }}
          saveText={'Approve'}
          saveAction={() => {
            setPromptActionTitle('approve');
            setShowActionPrompt(true);
          }}
          tempRequires={PermissionCodes.changeTransaction}
          saveRequires={PermissionCodes.changeTransaction}
        />
      );
    }
    if (
      selectedTransaction.displayOfflineEventType ===
      TRANSACTION_EVENT_TYPE.TYPE_APPROVED
    ) {
      if (
        selectedTransaction.transactionDisplayType ===
        TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
      ) {
        return (
          <SaveAndBackButtons
            backAction={backAction}
            saveTempText={'Recall'}
            saveTempAction={() => {
              setPromptActionTitle('recall');
              setShowActionPrompt(true);
            }}
            saveText={'Save'}
            saveAction={() => {
              dispatch(
                createAction('createTransaction/updateTransactionSKU')({
                  checkSku: true,
                  afterActions: () => redirectToNextTransaction(),
                }),
              );
            }}
            tempRequires={PermissionCodes.changeTransaction}
            saveRequires={PermissionCodes.changeTransaction}
          />
        );
      }
      return (
        <SaveAndBackWithOutTempButtons
          backAction={backAction}
          saveText={'Recall'}
          actionText={'Recall'}
          saveAction={() => {
            setPromptActionTitle('recall');
            setShowActionPrompt(true);
          }}
          saveRequires={PermissionCodes.changeTransaction}
        />
      );
    }

    return (
      <OnlyBackButton
        backAction={() => {
          goBackToList();
        }}
      />
    );
  };

  if (transactionPK && !selectedTransaction?.pk) {
    return <Loading customClass={'common-full-height'} />
  }

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view transaction
          title: 'View transaction',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={[]}
        hideHeaderAndFooter={isRecycling}
      />
      <div className={`header-and-footer footer ${isRecycling ? 'hide-header-and-footer' : ''}`}>
        {getBottomSection()}
      </div>
      <TransactionActionPrompt
        event={promptActionTitle}
        item={selectedTransaction}
        show={showActionPrompt}
        onHide={() => setShowActionPrompt(false)}
        action={transactionAction}
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedTransaction: state.transactions.selectedTransaction,
  transaction: state.createTransaction.transaction,
  nextTransactionPk: state.transactions.selectedTransaction.nextTransactionPk,
});

export default connect(mapStateToProps)(TransactionOverView);
