import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import { LanguageConfig, MissionType } from '../../config/CustomEnums';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { connect, useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import { useParams, useHistory } from 'react-router-dom';
import MissionGeneralSection from './MissionGeneralSection';

function CreateMissionStepTwo({
  errorFields,
  errorTab,
}) {
  let dataArray = [];
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [error, setError] = useState(false);
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });

  const fromCommunity = history.location?.state?.fromCommunity || {};
  const fromCommunityPathName = fromCommunity?.pathname || null;

  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (fromCommunityPathName) {
      dispatch({
        type: 'communityModel/getList',
        payload: {},
      });
    }
  }, [])

  useEffect(() => {
    if (!onCheck.value) {
      setError(errorFields.length > 0);
    } else {
      let formatedData = {};
      dataArray.forEach((item, index) => {
        const language = item.language;
        if (language === LanguageConfig.english) {
          formatedData = {
            ...formatedData,
            ...item,
          };
        } else {
          formatedData = {
            ...formatedData,
            translations: {
              ...formatedData.translations,
              [language]: {
                ...item,
              },
            },
          };
        }
      });
      let checkValid = true;
      if (formatedData?.missionType === MissionType.habitual && !onCheck.isBack) {
        if (!formatedData?.missionEarningType?.value && !formatedData?.linkedCampaign?.value?.pk) {
          dispatch(
            createAction('navBars/updateState')({
              saveDiscardToastShowing: {
                value: true,
                type: "Please provide 'Mission earning type' or 'Link to campaign'",
              },
            }),
          );
          setError(true);
          stopCheck();
          checkValid = false;
        } else if (formatedData?.missionEarningType?.value && formatedData?.linkedCampaign?.value?.pk) {
          dispatch(
            createAction('navBars/updateState')({
              saveDiscardToastShowing: {
                value: true,
                type: "Cannot select 'Mission earning type' and 'Link to campaign' at the same time",
              },
            }),
          );
          setError(true);
          stopCheck();
          checkValid = false;
        };
      }
      if (checkValid) {
        dispatch(
          createAction('mission/stepChange')({
            data: formatedData,
            isBack: onCheck.isBack,
            step: 1,
            fromCommunity: !!fromCommunityPathName,
          }),
        );
        setError(false);
        stopCheck();
      }
    }
  }, [onCheck.value, onCheck.isBack, errorFields.length, dataArray, dispatch]);

  const getTabs = (language) => {
    return [
      <MissionGeneralSection
        language={language}
        onSubmit={onCheck.value}
        onSubmitData={(data) => {
          dataArray.push(data);
        }}
      />,
    ];
  };

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
    hansContainer: getTabs(LanguageConfig.simplifiedChinese),
  };

  return (
    <>
      <ContentSections
        fieldsError={error}
        errorTab={errorTab}
        languageTabContent={languageTabConfig}
        hidePreview={true}
      />
      <ContinueWithBackButtons
        continueAction={() => {
          isChecking({ isBack: false });
        }}
        backAction={() => {
          if (fromCommunityPathName) {
            history.push({
              pathname: fromCommunityPathName,
              search: fromCommunity?.search,
              hash: fromCommunity?.hash,
            });
            return;
          };
          isChecking({ isBack: true });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  errorFields: state.mission.errorFields,
  errorTab: state.mission.errorTab,
});

export default connect(mapStateToProps)(CreateMissionStepTwo);
