import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignType } from '../../../config/CustomEnums';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { brandList, customerGroupList, segmentList, levelList } = useSelector(
    (state) => ({
      brandList: state.brand.brandList,
      customerGroupList: state.customerGroup.groupList,
      segmentList: state.segments.segmentList,
      levelList: state.levels.levelList,
    }),
  );

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchPublishStatus = parsedSearch['status'] || '';
  const searchExpiredStatus = parsedSearch['expired'] || '';
  const searchMessageChannel = parsedSearch['message_channel'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchActivePeriod = parsedSearch['active_period'] || '';
  const searchVisiblePeriod = parsedSearch['visible_period'] || '';

  const searchTargetCustomer = parsedSearch['target_customer'] || '';
  const searchGroups = parsedSearch['groupsIn'] || '';
  const searchSegments = parsedSearch['segmentsIn'] || '';
  const searchLevels = parsedSearch['levelsIn'] || '';

  const [type, setType] = useState(searchType);
  const [status, setStatus] = useState(searchPublishStatus);
  const [expired, setExpired] = useState(searchExpiredStatus);
  const [messageChannel, setMessageChannel] = useState(
    ArrayStringData(searchMessageChannel),
  );
  const [brand, setBrand] = useState(ArrayStringData(searchBrand));
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [visiblePeriod, setVisiblePeriod] = useState(searchVisiblePeriod);
  const [targetCustomer, setTargetCustomer] = useState(searchTargetCustomer);
  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  const [levels, setLevels] = useState(ArrayStringData(searchLevels));

  // useEffect(() => {
  //   dispatch(createAction('brand/getCurrentPageBrands')({}));
  // }, []);

  const content = [
    {
      title: 'Type',
      data: [
        { pk: CampaignType.couponsCampaign, name: 'Coupon' },
        { pk: CampaignType.earningRulesCampaign, name: 'Earning' },
        { pk: CampaignType.generalMessageCampaign, name: 'General Message' },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Message channel',
      data: [
        { name: 'Inbox', pk: 'INBOX' },
        { name: 'Push', pk: 'PUSH_NOTIFICATION' },
      ],
      value: messageChannel,
      setValue: setMessageChannel,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Brand',
      data: brandList,
      value: brand,
      setValue: setBrand,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Expired',
      data: [
        { name: 'Unexpired', pk: 'false' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Expired', pk: 'true' },
      ],
      value: expired,
      setValue: setExpired,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Target customer',
      data: [
        {
          name: 'Customer group',
          pk: 'groupsIn',
          subComponment: {
            data: customerGroupList,
            value: groups,
            setValue: setGroups,
            componment: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
        {
          name: 'Segment',
          pk: 'segmentsIn',
          subComponment: {
            data: segmentList,
            value: segments,
            setValue: setSegments,
            componment: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
        // {
        //   name: 'Level',
        //   pk: 'levelsIn',
        //   subComponment: {
        //     data: levelList,
        //     value: levels,
        //     setValue: setLevels,
        //     componment: FilterTypeEnum.choice,
        //     className: 'filter-list-items-sub-area',
        //   },
        // },
      ],
      value: targetCustomer,
      setValue: setTargetCustomer,
      componment: FilterTypeEnum.singleChoice,
      className: 'filter-list-items-has-sub-area-height',
    },
    {
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Visible period',
      data: [
        { name: 'Inbox', pk: 'inbox' },
        { name: 'Push', pk: 'push' },
      ],
      value: visiblePeriod,
      setValue: setVisiblePeriod,
      componment: FilterTypeEnum.dateRange,
    },
  ];

  // const confirmAction = () => {
  //   let search = {};
  //   if (targetCustomer) {
  //     search['target_customer'] = targetCustomer;
  //     if (selectedTarget) {
  //       search['selected_target'] = selectedTarget.join(',');
  //     }
  //   }

  //   backAction();

  //   return search;
  // };

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
