import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../I18n';
import ImageUploader from '../base/ImageUploader';
import { LanguageConfig } from '../../config/CustomEnums';
import './CreateBrandContent.scss';
import { createAction, removeElementFromArray } from '../../utils';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { BrandActivityTypeList, CreateBrandError } from '../../models/BrandModel';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { useParams } from 'react-router-dom';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import Loading from '../base/Loading';
import { useLocation } from 'react-router-dom';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import deleteImage from '../../assets/images/earning/deletex1.png';
import { Button, Image } from 'react-bootstrap';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import ContentSections from '../base/ContentSections';

function CreateBrandContent({ language, errorFields, selectedBrand = {} }) {
  const params = useParams();
  const brandName = selectedBrand.name
  const description = selectedBrand.brandDescription
  const icon = selectedBrand.coverPhoto
  const promotionPhoto = selectedBrand.promotionPhoto
  const displayPriority = selectedBrand.displayPriority
  const activityType = selectedBrand.activityType
  const brandTag = selectedBrand.brandTag
  const brandPageLink = selectedBrand.brandPageLink
  const relatedStoreNames = selectedBrand.relatedStoreNames
  const isForcedInactive = selectedBrand.isForcedInactive
  const showInBarcode = selectedBrand.showInBarcode
  const barcodePartner = selectedBrand.barcodePartner
  const receiptPartner = selectedBrand.receiptPartner
  const oclPartner = selectedBrand.oclPartner
  const dispatch = useDispatch();
  useEffect(() => {
    if (language === LanguageConfig.english) {
      [
        CreateBrandError.brandName.name,
        CreateBrandError.brandIcon.name,
        CreateBrandError.brandPromotionPhoto.name,
        CreateBrandError.brandDisplayPriority.name
      ].forEach(item => {
        firstError(
          item,
          errorFields,
          LanguageConfig.english,
        )
      });
    }
  }, [errorFields]);

  const isError = (field) => {
    if (language !== LanguageConfig.english) {
      return false;
    }
    return isShowError(field, errorFields, LanguageConfig.english);
  };

  const relatedStores = () => {
    return (
      <>
        <div>
          <CustomTitleLabel title={'Related store'} />
        </div>
        <label className="related-store-name">{relatedStoreNames || '-'}</label>
      </>
    );
  };

  console.log("brandTag:", brandTag, language)

  return (
    <>
      <div className="first-section-title">{i18n.t('general', { locale: language })}</div>
      <CustomTitleLabel title={i18n.t('name', { locale: language })} />
      <div>
        <input
          type="text"
          className={`text-input-field date-picker ${isError(CreateBrandError.brandName.name) ? 'error-field' : ''
            }`}
          value={brandName}
          onChange={(e) => {

            dispatch(
              createAction('brand/setFieldToSession')({
                name: e.target.value,
                language: language
              }),
            );
            dispatch(
              createAction('brand/updateSelectedBrand')({
                name: e.target.value,
                language: language
              }),
            );
          }}
        />
      </div>
      <div>
        <ErrorFieldMessage
          id={`${CreateBrandError.brandName.name}`}
          error={isError(CreateBrandError.brandName.name)}
          message={CreateBrandError.brandName.message}
        />
      </div>
      <CustomTitleLabel title={i18n.t('description_content_optional', { locale: language })} />
      <CustomEditor
        initialValue={description}
        onValueChange={(data) => {
          dispatch(
            createAction('brand/updateSelectedBrand')({
              brandDescription: data,
              language: language
            }),
          );
        }}
      />
      {language !== LanguageConfig.english ? null : <>
        <CustomTitleWithSwitch
          title="Hide in map"
          defaultValue={isForcedInactive}
          setValue={(isChecked) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                isForcedInactive: isChecked,
              }),
            );
          }}
        />

        <CustomTitleWithSwitch
          title="Show in the barcode list"
          defaultValue={showInBarcode}
          setValue={(isChecked) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                showInBarcode: isChecked,
              }),
            );
          }}
        />

        <CustomTitleWithSwitch
          title="Barcode Scanning Partner"
          defaultValue={barcodePartner}
          setValue={(isChecked) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                barcodePartner: isChecked,
              }),
            );
          }}
        />

        <CustomTitleWithSwitch
          title="Receipt partner"
          defaultValue={receiptPartner}
          setValue={(isChecked) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                receiptPartner: isChecked,
              }),
            );
          }}
        />

        <CustomTitleWithSwitch
          title="OCL partner"
          defaultValue={oclPartner}
          setValue={(isChecked) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                oclPartner: isChecked,
              }),
            );
          }}
        />

        <div className="second-section">
          <div className="second-section-title">Logo image</div>
          <div className="second-section_description">
            {i18n.t('square_cover_photo_suggest')}
          </div>
          <span id={CreateBrandError.brandIcon.name} />
          <ImageUploader
            images={icon ? [icon] : []}
            aspect={1 / 1}
            maxImageNum={1}
            language={LanguageConfig.english}
            onImageStateChange={(data) => {
              dispatch(
                createAction('brand/updateSelectedBrand')({
                  coverPhoto: data?.[0],
                }),
              );
            }}
            uploadImageClicked={() => { }}
            errorMessage={
              isError(CreateBrandError.brandIcon.name)
                ? CreateBrandError.brandIcon.message
                : ''
            }
          />
        </div>

        {params.id ? relatedStores() : null}

        <div className="second-section">
          <div className="second-section-title">Promotion photo</div>
          <div className="second-section_description">
            {i18n.t('cover_photo_suggest')}
          </div>
          <span id={CreateBrandError.brandPromotionPhoto.name} />
          <ImageUploader
            images={promotionPhoto ? [promotionPhoto] : []}
            aspect={3 / 2}
            minWidth={900}
            minHeight={600}
            maxImageNum={1}
            language={LanguageConfig.english}
            onImageStateChange={(data) => {
              dispatch(
                createAction('brand/updateSelectedBrand')({
                  promotionPhoto: data?.[0],
                }),
              );
            }}
            uploadImageClicked={() => { }}
            errorMessage={
              isError(CreateBrandError.brandPromotionPhoto.name)
                ? CreateBrandError.brandPromotionPhoto.message
                : ''
            }
          />
        </div>

        <CustomTitleWithInput
          title={'Display order'}
          defaultValue={displayPriority}
          setValue={(value) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                displayPriority: value,
              }),
            );
          }}
          type={'number'}
          error={{
            id: CreateBrandError.brandDisplayPriority.name,
            error: isError(CreateBrandError.brandDisplayPriority.name),
            message: isError(CreateBrandError.brandDisplayPriority.name)
              ? CreateBrandError.brandDisplayPriority.message
              : ''
          }}
        />
        <label className="create-message-suggest">
          {`Minimum value is 1. The smaller value, the higher priority.`}
        </label>
      </>}
      <div className="second-section">
        <div className="second-section-title">{i18n.t('brand_tag', { locale: language })}</div>
        <div className="second-section_description create-brand-description">
          {i18n.t('brand_tag_max_length', { locale: language })}
        </div>
        <textarea
          onChange={({ target }) => {
            const currentBrandTag = target.value.split(',');
            console.log("currentBrandTag:", currentBrandTag)
            dispatch(
              createAction('brand/updateSelectedBrand')({
                brandTag: currentBrandTag,
                language: language
              }),
            );
          }}
          className="custom-textarea-input-box custom-textarea-input-font "
          onFocus={() => { }}
          value={(brandTag || []).join(',')}
        />
      </div>
      <ErrorFieldMessage
        extra={'create-brand-error-message'}
        id={CreateBrandError.brandTag.name}
        error={isError(CreateBrandError.brandTag.name)}
        message={CreateBrandError.brandTag.message}
      />

      <CustomTitleLabel title={i18n.t('brand_tag', { locale: language })} />
      <div>
        {brandTag?.map((element, index) => {
          if (!element?.length) {
            return null;
          };
          return (
            <div className="brand-tag-delete-button" key={`brand-tag-${index}`}>
              <label className="custom-textarea-input-font">
                {element}
              </label>
              <Button
                className="custom-image-button"
                onClick={() => {
                  dispatch(
                    createAction('brand/updateSelectedBrand')({
                      brandTag: removeElementFromArray(brandTag, element),
                      language: language
                    }),
                  );
                }}
              >
                <Image src={deleteImage} />
              </Button>
            </div>
          );
        })}
      </div>
      {language !== LanguageConfig.english ? null : <>
        <CustomTitleLabel title="Target URL from web browser(Optional)" />
        <div className="second-section_description create-brand-description">
          {'Support redirection to URL only'}
        </div>
        <textarea
          onChange={({ target }) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                brandPageLink: target.value,
              }),
            );
          }}
          className="custom-textarea-input-box custom-textarea-input-font"
          value={brandPageLink}
        />
        <ErrorFieldMessage
          extra={'create-brand-error-message'}
          id={CreateBrandError.brandPageLink.name}
          error={isError(CreateBrandError.brandPageLink.name)}
          message={CreateBrandError.brandPageLink.message}
        />

        <CustomAddRemoveMutipleSelector
          title={'Activity type(Optional)'}
          data={BrandActivityTypeList}
          selectedList={activityType}
          setValue={(value) => {
            dispatch(
              createAction('brand/updateSelectedBrand')({
                activityType: value,
              }),
            );
          }}
          sourceReminderText={'All activity types'}
          amidReminderText={'Selected activity type'}
          error={{
            id: null,
            error: false,
            message: null
          }}
        />
      </>}
    </>
  );
}

function BrandDetailContent() {
  const { errorFields, selectedBrand } = useSelector(state => ({ errorFields: state.brand.errorFields, selectedBrand: state.brand.selectedBrand }))
  const location = useLocation();
  if (location?.pathname?.includes('edit') && selectedBrand.isForcedInactive === undefined) {
    return <Loading />;
  }
  const languageTabConfig = {
    enContainer: [<CreateBrandContent language={LanguageConfig.english} errorFields={errorFields} selectedBrand={selectedBrand} />],
    hantContainer: [<CreateBrandContent language={LanguageConfig.traditionalChinese} errorFields={errorFields} selectedBrand={selectedBrand?.brandTranslations?.[LanguageConfig.traditionalChinese]} />,],
    hansContainer: [<CreateBrandContent language={LanguageConfig.simplifiedChinese} errorFields={errorFields} selectedBrand={selectedBrand?.brandTranslations?.[LanguageConfig.simplifiedChinese]} />],
  };

  return (<ContentSections
    fieldsError={errorFields}
    languageTabContent={languageTabConfig}
    hidePreview
  />)
}

export default BrandDetailContent;
