import React from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import FilterableDropdown from '../base/FilterableDropdown';
import { useSelector } from 'react-redux';

function StaffNameSelectDropdown(props) {
  const selectStaff = props.selectStaff || (() => {});
  const defaultStaff = props.selectedStaff;

  const { adminList } = useSelector((state) => ({
    adminList: state.admin.adminList,
  }));

  const options = () => {
    return adminList.map((item) => {
      return {
        value: item,
        label: item.name,
      };
    });
  };

  console.log('@23', defaultStaff);

  return (
    <>
      <CustomTitleLabel title="Staff name" />
      <FilterableDropdown
        defaultValue={{
          value: defaultStaff,
          label: defaultStaff?.name ? defaultStaff.name : defaultStaff,
        }}
        options={options()}
        selectChange={(item) => {
          selectStaff(item.value);
        }}
      />
    </>
  );
}

export default StaffNameSelectDropdown;
