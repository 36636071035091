export const ErrorHandleFields = {
  name: {
    name: 'name',
    message: 'Please provide a title'
  },
  description: {
    name: 'description',
    message: 'Please provide a description'
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo'
  },
  displayIn: {
    name: 'displayIn',
    message: 'Please select an option'
  },
  tag: {
    name: 'tag',
    message: 'Please provide correct tags.'
  },
};
