import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { SplashAdTypes } from '../../../models/SplashAdModel';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchSplashAdStyle = parsedSearch['splash_ad_style'] || '';
  const searchExpired = parsedSearch['expired'] || '';
  const searchStatus = parsedSearch['status'] || '';
  const searchVisiblePeriod = parsedSearch['visible_period'] || '';

  const [splashAdStyle, setSplashAdStyle] = useState(searchSplashAdStyle);
  const [expired, setExpired] = useState(searchExpired);
  const [status, setStatus] = useState(searchStatus);
  const [visiblePeriod, setVisiblePeriod] = useState(searchVisiblePeriod);

  const content = [
    {
      title: 'Splash ad style',
      data: [
        { pk: SplashAdTypes.IMAGE.value, name: SplashAdTypes.IMAGE.label },
        { pk: SplashAdTypes.IMAGE_WITH_GOT_IT_BUTTON.value, name: SplashAdTypes.IMAGE_WITH_GOT_IT_BUTTON.label },
        { pk: SplashAdTypes.IMAGE_WITH_SEE_MORE_BUTTON.value, name: SplashAdTypes.IMAGE_WITH_SEE_MORE_BUTTON.label },
      ],
      value: splashAdStyle,
      setValue: setSplashAdStyle,
      componment: FilterTypeEnum.singleChoice,
    },
    {
      title: 'Expired',
      data: [
        { name: 'Unexpired', pk: 'false' },
        { name: 'Expired', pk: 'true' },
      ],
      value: expired,
      setValue: setExpired,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Visible period',
      value: visiblePeriod,
      setValue: setVisiblePeriod,
      componment: FilterTypeEnum.dateRange,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
