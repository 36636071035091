import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateRewardCodeError } from '../../models/RewardCodeModel';
import PeriodSection from '../campaign/campaignCreation/PeriodSection';

const ActivePeriodSection =({
  rewardCodeDetail,
  errorFields,
}) => {
  const dispatch = useDispatch();

  const {
    isAlwaysActivePeriod,
    activeStartDate,
    activeEndDate,
  } = rewardCodeDetail || {};

  return (
    <PeriodSection
      disabled={false}
      title="Active Period"
      titleDescription="Redemption code available time"
      maxDate={null}
      minDate={null}
      switchButtonTitle="It is all-time active"
      isAlwaysPeriod={isAlwaysActivePeriod}
      switchOnChange={(value) => {
        dispatch({
          type: 'rewardCode/updateDetail',
          payload: { isAlwaysActivePeriod: value },
        });
      }}
      startDate={activeStartDate}
      startDateChange={(value) => {
        dispatch({
          type: 'rewardCode/updateDetail',
          payload: { activeStartDate: value },
        });
      }}
      filterStartDate={(date) => {
        return true;
      }}
      endDate={activeEndDate}
      endDateChange={(value) => {
        dispatch({
          type: 'rewardCode/updateDetail',
          payload: { activeEndDate: value },
        });
      }}
      filterEndDate={(date) => {
        return true;
      }}
      hideTime={false}
      errorFields={errorFields}
      fieldName={CreateRewardCodeError.endDate.name}
      fieldErrorMessage={CreateRewardCodeError.endDate.message}
    />
  );
}


const mapStateToProps = (state) => ({
  rewardCodeDetail: state.rewardCode.rewardCodeDetail,
  errorFields: state.rewardCode.errorFields,
});

export default connect(mapStateToProps)(ActivePeriodSection);
