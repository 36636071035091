import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateGreetingSectionError, greetingCardThemeColorList } from '../../models/GreetingSectionModel';
import { isShowError } from '../../utils';
import CustomInputWithTitle from '../customer/CustomInputWithTitle';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function GeneralSection({
  greetingSectionName,
  errorFields,
}) {
  const dispatch = useDispatch();

  const updateGreetingSectionDetail = (payload) => {
    dispatch({
      type: 'greetingSection/updateGreetingSectionDetail',
      payload,
    });
  }

  return (
    <>
      <div className="first-section-title">GENERAL</div>
      <CustomInputWithTitle
        title={'Name'}
        defaultValue={greetingSectionName}
        setValue={(value) => {
          updateGreetingSectionDetail({ name: value })
        }}
        error={{
          id: 'generalName',
          error: isShowError(CreateGreetingSectionError.generalName.name, errorFields),
          message: CreateGreetingSectionError.generalName.message
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  greetingSectionName: state.greetingSection.greetingSection.name,
  errorFields: state.greetingSection.errorFields,
});

export default connect(mapStateToProps)(GeneralSection);
