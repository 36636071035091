import { BaseHelper } from './BaseGraphQLAPIHelper';

const AFC_SETTINGS_RECORD_NODE = `{
    id
    pk

    level1Points
    level1CarbonSavings
    level2Points
    level2CarbonSavings
    level3Points
    level3CarbonSavings
    level4Points
    level4CarbonSavings
    level5Points
    level5CarbonSavings

    level6ExtraCarbonSavings

    afcCampaignId
    errorReportingEmailList
}`;

export const getAfcSettings = (id) => {
    const query = `{
      afcSettingsRecord(id: "${id}") ${AFC_SETTINGS_RECORD_NODE}
    }`;
    const variables = {};
    return BaseHelper.callGraphQLAPI({
      query,
      variables,
    });
};



  
const updateAfcSettingsContent = `
node {
  id
  pk

  level1Points
  level1CarbonSavings
  level2Points
  level2CarbonSavings
  level3Points
  level3CarbonSavings
  level4Points
  level4CarbonSavings
  level5Points
  level5CarbonSavings

  level6ExtraCarbonSavings

  afcCampaignId
  errorReportingEmailList
}
errors {
  field
  messages
}
`

export const updateAfcSettings = (values) => {
    let query = `mutation UpdateAfcSettingsRecord($input: UpdateAfcSettingsRecordInput!) {
      updateAfcSettingsRecord(input: $input) {
        ${updateAfcSettingsContent}
      }
    }
    `;
    const variables = {
      input: values,
    };
  
    return BaseHelper.callGraphQLAPI({ query, variables });
  };
  