import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getAppVersions = (afterCursor, rank, search, moreSearch) => {
  let order = rank ? '-pk' : 'pk';
  if (moreSearch?.sort) {
    order = moreSearch.sort;
  }
  const searchKey = search || '';

  const query = `
    {
        appVersions(first: 20, after: "${afterCursor}", orderBy: "${order}") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            creationDate
            lastModifiedDate
            androidAppVersionName
            iosAppVersionName
            pk
          }
        }
      }
    }
    `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteAppVersion = (pks) => {
  const query = `
    mutation DeleteAppVersions($input: DeleteAppVersionsInput!) {
        deleteAppVersions(input: $input) {
       success
     }
   }
   `;
  const variables = {
    input: {
      ids: pks,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createAppVersion = (values) => {
  let query = `mutation CreateAppVersion($input: CreateAppVersionInput!) {
    createAppVersion(input: $input) {
      node {
        id
        pk
        androidAppVersionName
        iosAppVersionName
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
