import {
  importCustomers,
  createDownloadTask,
  downloadFile,
  getSystemTasks,
  cancelSystemTask,
  getExportJobFileUrl,
} from '../services/DownloadAndImportAPIHelper';
import { createAction, getFileNameFromUrl, gotoOutWeb } from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';
import { DownloadTag } from '../config/CustomEnums';
import { ToastType } from './NavBarModel';

export const ImportResource = {
  customer: 'CUSTOMER',
  coupon: 'COUPON',
  transaction: 'TRANSACTION',
  couponRecord: 'COUPON_RECORD',
  pointRecotd: 'POINTS_RECORD',
  generateCodeCoupon: 'GENERATE_CODE_COUPON',
  generateQRCodeCoupon: 'GENERATE_QR_CODE_COUPON',
  generateImageCoupon: 'GENERATE_IMAGE_COUPON',
  stores: 'STORE',
  customerGroup: 'CUSTOMER_GROUP',
  customerInCommunity: 'CUSTOMER_IN_COMMUNITY',
  barcodeListing: "BARCODE_LISTING",
  newBarcode: "NEW_BARCODE",
  rewardSingleCode: "REWARD_SINGLE_CODE",
};

const parseStatus = (item) => {
  switch (item.status) {
    case 'COMPLETED':
      if (item.type === 'EXPORT_JOB') return DownloadTag.completed;
      return DownloadTag.importCompleted;
    case 'PENDING':
    case 'PROCESSING':
      if (item.type === 'EXPORT_JOB') return DownloadTag.downloading;
      return DownloadTag.importing;
    case 'ERROR':
      return DownloadTag.error;
    default:
      return '';
  }
};

export default {
  namespace: 'downloadAndImport',
  state: {
    customerDownloads: [],
    customerImports: [],
    systemTasks: [],
    downloadingCount: 0,
    error: null,
    success: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    getSystemTasks: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getSystemTasks];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              systemTasks: data?.systemTasks.edges.map((item) => {
                return {
                  ...item.node,
                  name: item.node.fileName,
                  displayStatus: parseStatus(item.node),
                };
              }),
              downloadingCount: data?.systemTasks.pendingAndProcessingCount,
              // data?.systemTasks.edges?.filter(
              //   (item) =>
              //     item.node.status === 'PENDING' ||
              //     item.node.status === 'PROCESSING',
              // ).length,
            },
          });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    importFile: [
      function* ({ payload }, { call, put }) {
        if (!payload.file.import) {
          return;
        }
        const data = {
          resource: payload.from,
          file: getFileNameFromUrl(payload.file.import),
          related: payload.related,
        };

        const serviceArgs = [importCustomers, data];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });

          yield put({
            type: 'uploadFiles/updateState',
            payload: {
              importedFile: '',
            },
          });

          yield put({
            type: 'updateState',
            payload: {
              error: {},
            },
          });
        }

        function* onFailed(data) {
          yield put({
            type: 'uploadFiles/updateState',
            payload: {
              importedFile: '',
            },
          });
          if (data.errors) {
            yield put({
              type: 'updateState',
              payload: {
                error: {
                  title: `Invalid ${payload.from.toLowerCase()} csv. file`,
                  content: `This file does not match the ${payload.from.toLowerCase()}.  Please check and try again.`,
                },
              },
            });
          }
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    createDownloadTask: [
      function* ({ payload }, { call, put }) {
        const data = {
          resource: payload.from,
        };
        if (payload?.related) {
          data.related = payload.related;
        }
        const serviceArgs = [createDownloadTask, data];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    downloadFile: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [downloadFile, payload.id];
        function* onSuccess(data) {
          // yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    cancelTask: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [cancelSystemTask, { id: payload.pk }];
        function* onSuccess(data) {
          yield put({ type: 'getSystemTasks' });
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getExportJobFileUrl: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getExportJobFileUrl, { id: payload.pk }];
        function* onSuccess(data) {
          const fileUrl = data?.getExportJobFileUrl?.fileUrl;
          gotoOutWeb(fileUrl)
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.serverError,
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ]
  },
};
