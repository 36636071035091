import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from './CustomBaseComponments';
import { useCompare } from '../../utils';

function ReferralSection(props) {
  const radioOptions = [
    { label: 'Inviter', value: 'INVITER' },
    { label: 'Invitee', value: 'INVITEE' },
    { label: 'Both', value: 'BOTH_INVITER_AND_INVITEE' },
  ];

  const defaultValue = props.defaultValue || {};

  const [referralType, setReferralType] = useState(defaultValue.referralType);
  const [maxInvitees, setMaxInvitees] = useState(defaultValue.maxInvitees);
  const vals = { referralType, maxInvitees };
  const hasValsChanged = useCompare(vals);
  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultValue.referralType === undefined) {
      setReferralType('INVITER');
    }
  }, []);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  return (
    <>
      <label className="create-section-title">REFERRAL</label>
      <CustomTitleLabel title="Beneficiary" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={(value) => setReferralType(value)}
          default={referralType}
          options={radioOptions}
        />
      </div>

      {referralType !== 'INVITEE' ? (
        <>
          <CustomNumberInputWithUnit
            title="Maximum number of invitees (optional, for inviters only)"
            unit="number of invitees"
            defaultValue={maxInvitees}
            setValue={setMaxInvitees}
          />
        </>
      ) : null}
    </>
  );
}

export default ReferralSection;
