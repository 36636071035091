import React from 'react';
import './CreateTransactionStepFourSection.scss';

const showdown = require('showdown');
const converter = new showdown.Converter();
converter.setOption('simpleLineBreaks', true);
function CreateTransactionStepFourSection({
  title = '',
  fieldsData = [],
}) {

  const getSingleField = (field, value, secondName, secondValue) => {
    const isRemark = field === 'Remarks (optional)';
    return (
      <div className='create-transaction-step-four-section-line'>
        <div className='create-transaction-step-four-section-field'>
          <label className="create-section-label create-section-label-bottom-space">
            {field}
          </label>
          {
            isRemark
              ? <label
                className="create-section-field-input"
                dangerouslySetInnerHTML={{
                  __html: value ? converter.makeHtml(value) : '-'
                }}
              >
              </label>
              : <label
                className="create-section-field-input"
              >
                {value || '-'}
              </label>
          }
        </div>
        {
          secondName
            ? <div className='create-transaction-step-four-section-field'>
              <label className="create-section-label create-section-label-bottom-space">
                {secondName}
              </label>
              <label className="create-section-field-input">
                {secondValue || '-'}
              </label>
            </div>
            : null
        }
      </div>
    );
  };

  return (
    <>
      <label className="create-section-title">{title}</label>
      {fieldsData.map(field => (
        getSingleField(field.name, field.value, field?.secondName, field?.secondValue)
      ))}
    </>
  );
}

export default CreateTransactionStepFourSection;
