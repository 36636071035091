import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';


function SingleCodeFilter({
  backAction = () => { }
}) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchStatus = parsedSearch['status'] || '';
  const searchExpired = parsedSearch['expired'] || '';
  const searchUsed = parsedSearch['used'] || '';

  const [status, setStatus] = useState(searchStatus);
  const [expired, setExpired] = useState(searchExpired);
  const [used, setUsed] = useState(searchUsed);

  const content = [
    // {
    //   title: 'Status',
    //   data: [
    //     { name: 'Inactive', pk: 'false' },
    //     { name: 'Active', pk: 'true' },
    //   ],
    //   value: status,
    //   setValue: setStatus,
    //   componment: FilterTypeEnum.singleChoiceSegment,
    // },
    {
      title: 'Expired',
      data: [
        { name: 'Unexpired', pk: 'false' },
        { name: 'Expired', pk: 'true' },
      ],
      value: expired,
      setValue: setExpired,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Used',
      data: [
        { name: 'Not used', pk: 'false' },
        { name: 'Used', pk: 'true' },
      ],
      value: used,
      setValue: setUsed,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default SingleCodeFilter;
