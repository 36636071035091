import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import CustomSwitchButton from '../../../components/base/CustomSwitchButton';
import PreviewImage from '../../../components/base/prompt/PreviewImage';
import CustomerInformation from './CustomerInformation';
import './TransactionPhotoTakingOverview.scss';
import { EarningRuleType } from '../../../config/CustomEnums';
import RecentTransactions from './RecentTransactions'

const TransactionBaseDetail = [
  { name: 'Status', field: 'displayOfflineEventType' },
  { name: 'Creation date', field: 'transactionDetailCreationDate' },
  { name: 'Transaction date', field: 'transactionDetailDisplayDate' },
  { name: 'Merchant Name', field: 'merchantName' },
  { name: 'Store', field: 'storeName' },
  { name: 'Building name', field: 'buildingName' },
  { name: 'Within Radius?', field: 'withinRadius' },
  { name: 'Total Carbon', field: 'totalRewardCarbon' },
  { name: 'Total Point', field: 'totalRewardPoint' },
  { name: 'Remarks (optional)', field: 'remarks' },
];

export const WithinRadiusLabelType = {
  "true": {
    name: "Yes",
    color: "color_teal_4",
  },
  "false": {
    name: "No",
    color: "color_sunset_4",
  },
};

const LabelWithBackgroundColor = ({ label = null }) => {
  if (label == null)
    return <label className="create-section-field-input">{'-'}</label>
  const content = WithinRadiusLabelType?.[label]?.name || '';
  const color = WithinRadiusLabelType?.[label]?.color || '';

  return (
    <div className='within-radius-label-container'>
      <div className={`within-radius-label ${color}`}>
        <label className={`create-section-field-input within-radius-label-text ${color}`} >
          {`${content}`}
        </label>
      </div>
    </div>
  );
};


const TransactionPhotoTakingDetail = ({
  transaction = {},
  setEnlargeImage = (() => { }),
  jointBeautyOnPress = (() => { }),
  disabled = false,
}) => {
  return (
    <>
      <label className="create-section-title">TRANSACTION DETAILS</label>
      <div className="transaction-photo-taking-detail-container">
        <div className='transaction-photo-taking-base-detail'>
          {TransactionBaseDetail?.map(item => (
            <div className='transaction-photo-taking-detail-single-field' key={item?.name}>
              <label className="create-section-label transaction-photo-taking-detail-single-field-title">{item?.name}</label>
              {item.field == 'withinRadius'
                ? <LabelWithBackgroundColor label={transaction?.[item.field]} />
                : <label className="create-section-field-input">{transaction?.[item.field] || '-'}</label>}
            </div>
          ))}
        </div>
        <div className='transaction-photo-taking-image'>
          <div className='transaction-photo-taking-detail-single-field'>
            <label className="create-section-label">Receipt image</label>
            <Image
              src={transaction?.receiptImage || ''}
              className='transaction-photo-taking-detail-image'
              onClick={() => setEnlargeImage(true)}
            />
          </div>
          <div className='transaction-photo-taking-detail-single-field'>
            <label className="create-section-label">Joint beauty</label>
            <CustomSwitchButton
              disabled={disabled}
              defaultChecked={transaction?.jointBeauty}
              onChange={jointBeautyOnPress}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const singleFieldInline = (title = '-', content = '-') => {
  return (
    <div className='transaction-photo-taking-single-recycling-item-field'>
      <label className="create-section-label single-recycling-item transaction-photo-taking-single-recycling-item-field-label">{title}</label>
      <label className="create-section-field-input transaction-photo-taking-single-recycling-item-field-label">{content}</label>
    </div>
  );
};

const barcodeFieldInLine = (item, index) => {
  console.log("barcodeFieldInLine:", item)
  const typeLabel = { label: "New Barcode", color: '#6AB3FF' }
  const barcode = item?.node?.barcode
  const productName = item?.node?.barcode?.productName || item?.node?.submittedNewBarcode?.productName || '-'
  if (barcode) {
    if (barcode.linkedBrand?.barcodePartner) {
      typeLabel["label"] = "Partner"
    } else {
      typeLabel["label"] = "Non Partner"
      typeLabel["color"] = "#878787"
    }
  }
  return (
    <div className='transaction-photo-taking-single-recycling-item' key={index}>
      <div className="barcode-items-item-container" >
        <label >{`#Item ${index + 1}`}</label>
        <label style={{ color: typeLabel.color }}>{typeLabel.label}</label>
      </div>
      <div className="barcode-items-item-container" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div style={{ display: "flex", flexDirection: 'column' }}>
          <label >{item?.node?.storeSubcategory?.name}</label>
          <label >{productName}</label>
        </div>
        <label >{item?.node?.quantity}</label>
      </div>
    </div>
  );
};

const recyclingData = (transaction) => {
  console.log("transaction?.barcodeItems?.edges1:", transaction)
  console.log("transaction?.barcodeItems?.edges2:", transaction?.photoTakingItems?.edges)
  if (transaction?.transactionType === EarningRuleType.photoTaking) {
    return <>
      {
        transaction?.photoTakingItems?.edges?.map((item, index) => (
          <div className='transaction-photo-taking-single-recycling-item' key={index}>
            {singleFieldInline(`#Item ${index + 1}`, item?.node?.name)}
            {singleFieldInline('Amount', item?.node?.quantity)}
          </div>
        ))
      }</>
  }
  return <>
    {
      transaction?.barcodeItems?.edges?.map((item, index) => barcodeFieldInLine(
        item, index
      ))
    }</>

}

const TransactionPhotoTakingRecyclingItem = ({
  transaction = {},
}) => {
  const items = recyclingData(transaction)
  return (
    <>
      <label className="create-section-title">RECYCLING ITEM(S)</label>
      <label className="create-section-field-input">
        {`Total amount: ${transaction?.totalRewardAmount || '-'}`}
      </label>
      {
        items
      }
    </>
  )
};



const TransactionPhotoTakingOverview = ({
  transaction,
  updatingJointBeauty,
}) => {
  const dispatch = useDispatch();
  const [jointBeauty, setJointBeauty] = useState(transaction?.jointBeauty);
  const [enlargeImage, setEnlargeImage] = useState(false);

  const jointBeautyOnPress = () => {
    dispatch({
      type: 'transactions/updateTransactionJointBeauty',
      payload: {
        id: transaction?.pk,
        jointBeauty: !jointBeauty,
      }
    });
    setJointBeauty(!jointBeauty);
  };


  return (
    <>
      <div className="create-section-and-preview-container row" style={{ marginBottom: '30px' }}>
        <div className="content-no-preview">
          <div className="create-step-section-item customer-information">
            <CustomerInformation />
          </div>
          <div className='transaction-photo-taking-container'>
            <div className='create-step-section-item transaction-photo-taking-detail'>
              <TransactionPhotoTakingDetail
                transaction={transaction}
                setEnlargeImage={setEnlargeImage}
                jointBeautyOnPress={jointBeautyOnPress}
                disabled={updatingJointBeauty}
              />
            </div>
            <div className='create-step-section-item transaction-photo-taking-recycling-item'>
              <TransactionPhotoTakingRecyclingItem
                transaction={transaction}
              />
            </div>
          </div>
        </div>
      </div>
      <RecentTransactions />
      <PreviewImage
        show={enlargeImage}
        onClose={() => {
          setEnlargeImage(false);
        }}
        imageUrl={transaction?.receiptImage || ''}
        extraClass={{ width: 'auto', maxWidth: '100%', height: 315 * 1.7 }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  transaction: state.transactions.selectedTransaction,
  updatingJointBeauty: state.transactions.updatingJointBeauty,
})

export default connect(mapStateToProps)(TransactionPhotoTakingOverview);
