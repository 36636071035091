import { SavingStatus } from '../config/CustomEnums';
import { getLevels } from '../services/LevelAPIHelper';
import { apiWithResponseHandle } from './LoadingUtil';

const parseLevel = (item) => {
  return {
    ...item,
    name: item.levelName,
  };
};

const parseLevelList = (data) => {
  return data.map((item) => {
    return parseLevel(item.node);
  });
};

const getInitialState = () => ({
  levelList: [],
  levelTabList: [],
});

export default {
  namespace: 'levels',
  state: getInitialState,

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    setLevelTabList(state, { payload }) {
      const levelTabs = payload.levelList.map((item) => ({
        key: item.node.pk.toString(),
        name: item.node.levelName,
      }));
      return {
        ...state,
        levelTabList: [{ key: 'all', name: 'All Types' }].concat(levelTabs),
        levelList: levelTabs,
      };
    },
  },
  effects: {
    getLevelList: [
      function* ({ payload }, { call, select, put }) {
        return;
        const serviceArgs = [getLevels];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { levelList: parseLevelList(data?.levels.edges) },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
