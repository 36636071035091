import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import MembershipInfoEditCard from '../../../components/customer/MembershipInfoEditCard';
import PersonalInfoEditCard from '../../../components/customer/PersonalInfoEditCard';
import ContactInfoEditCard from '../../../components/customer/ContactInfoEditCard';
import Loading from '../../../components/base/Loading';
import {
  CheckStatus,
  DELETE_RELATED_SECTIONS,
  SavedStatus,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CustomerEditForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { id } = params;

  const { customer, checked, errorFields, saved } = useSelector((state) => ({
    customer: state.customerList.customer || {},
    checked: state.customerList.checked,
    errorFields: state.customerList.errorFields,
    saved: state.customerList.saved,
  }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [sections, setSections] = useState([<Loading />]);

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(createAction('customerList/updateCustomer')({}));
    }
  }, [dispatch, checked, errorFields]);

  useEffect(() => {
    if (
      // saved === SavedStatus.savedWithFail ||
      saved === SavedStatus.savedWithSuccess
    ) {
      history.push('/customers#List%20of%20Customers');
    }
  }, [saved, history]);

  useEffect(() => setDidMount(true), []);

  useEffect(() => {
    dispatch(createAction('customerList/getOneCustomer')({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (didMount && customer.pk) {
      setSections([
        <MembershipInfoEditCard
          title="Membership Information"
          customer={customer}
          errorFields={errorFields}
        />,
        <PersonalInfoEditCard
          title="Membership Information"
          customer={customer}
          errorFields={errorFields}
        />,
        <ContactInfoEditCard
          title="Contact Information"
          customer={customer}
          errorFields={errorFields}
        />,
      ]);
    }
  }, [didMount, customer, errorFields]);

  // const sections = [
  //   <MembershipInfoEditCard
  //     title="Membership Information"
  //     customer={customer}
  //   />,
  //   <PersonalInfoEditCard title="Membership Information" customer={customer} />,
  //   <ContactInfoEditCard title="Contact Information" customer={customer} />,
  // ];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText="Delete"
        saveText={'Updated'}
        backAction={() => history.goBack()}
        saveAction={() => {
          // dispatch({ type: 'customerList/updateState', payload:{checked : CheckStatus.initOrNotChecked} });
          dispatch({ type: 'customerList/checkValsValid' });
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteCustomer}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Customer',
          content: 'Did you confirm to delete this customer?',
        }}
        title={'Customer'}
        data={customer}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `customerList/updateState`,
            payload: { checkedList: [customer] },
          });
          dispatch(
            createAction('customerList/delete')({
              afterAction: () => {
                history.push('/customers#List%20of%20Customers');
              },
            }),
          );
        }}
      />
    </>,
  ];

  return (
    <div className="customer">
      <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            //   dispatch({
            //     type: 'createEarningRules/removeEarningRuleFromCookie',
            //   });
          }
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={customer.name} />}
        caution={{
          detail: '', //You can modify some customer information, also can delete this customer.
          title: customer.name,
          model: 'customerList',
        }}
      />
    </div>
  );
}

export default CustomerEditForm;
