import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';

function Filter({ backAction = () => { } }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchDisplayIn = parsedSearch['display_in'] || '';
  const searchPublishStatus = parsedSearch['status'] || '';

  const [displayIn, setDisplayIn] = useState(searchDisplayIn);
  const [status, setStatus] = useState(searchPublishStatus);

  const content = [
    {
      title: 'Display in',
      data: [
        { pk: "GREEN_INFO_HUB", name: 'Green info hub' },
        { pk: "COMMUNITY", name: 'Community' },
        { pk: "BOTH", name: 'Both' },
      ],
      value: displayIn,
      setValue: setDisplayIn,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { pk: 'false', name: 'Unpublished' },
        { pk: 'true', name: 'Published' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
