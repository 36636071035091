import React, { useState, useEffect, useRef } from 'react';
import './Header.scss';
import {
  Image,
  OverlayTrigger,
  Popover,
  Button,
  Overlay,
  Collapse,
} from 'react-bootstrap';
import settingIcon from '../../assets/images/setting_icon.svg';
import usePhoto from '../../assets/images/userPhoto.jpg';
import downloadIcon from '../../assets/images/download.svg';
import closeIcon from '../../assets/images/close.svg';
import taskUnio from '../../assets/images/taskUnio.svg';
import { withRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CustomStatusTagFromText } from '../../components/base/CustomStatusTag';
import { DownloadTag, EarningRuleType } from '../../config/CustomEnums';
import { createAction, checkHasPermission } from '../../utils';
import { CustomTitleLabel } from '../../components/earning/CustomBaseComponments';
import LogoutBackButton from '../../assets/images/logout_dropdown.svg';
import AuthButton from '../../components/base/AuthButton';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useHistory } from 'react-router-dom';
function Header(props) {
  const [showTasks, setShowTasks] = useState(false);
  const [isOpenLogoutDropdown, setIsOpenLogoutDropdown] = useState(false);
  const dispatch = useDispatch();
  const target = useRef(null);
  const history = useHistory();
  const { user, systemTasks, downloadingCount, selectedTransaction } = useSelector((state) => ({
    user: state.users,
    systemTasks: state.downloadAndImport.systemTasks,
    downloadingCount: state.downloadAndImport.downloadingCount,
    selectedTransaction: state.transactions.selectedTransaction,
  }));

  const isRecycling = [EarningRuleType.photoTaking, EarningRuleType.barcode].includes(selectedTransaction?.transactionType)
  
  const logout = () => {
    history.push({ pathname: '/login', state: { isLogout: true } });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will run every 2 mins!');
      if (checkHasPermission(user, PermissionCodes.addExportjob)) {
        dispatch(createAction('downloadAndImport/getSystemTasks')({}));
      }
    }, 1000 * 60 * 2);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getCancelOrDownload = (item) => {
    switch (item.displayStatus) {
      case DownloadTag.downloading:
        return (
          <button
            type="button"
            className="btn-link reset-button download-cancel-button"
            onClick={() => {
              dispatch(
                createAction('downloadAndImport/cancelTask')({ pk: item.pk }),
              );
            }}
          >
            Cancel
          </button>
        );
      case DownloadTag.completed:
        return (
          <a
            onClick={() => {
              dispatch({ type: 'downloadAndImport/getExportJobFileUrl', payload: { pk: item.pk }})
            }}
            className="download-cancel-button download-button"
          >
            Download
          </a>
          // <button
          //   type="button"
          //   className="btn-link reset-button download-cancel-button download-button"
          //   onClick={() => {
          //     // dispatch(
          //     //   createAction('downloadAndImport/downloadFile')({ id: item.id }),
          //     // );
          //     window.location = item.fileUrl;
          //   }}
          // >
          //   Download
          // </button>
        );
      default:
        return null;
    }
  };

  const downloadLine = (item, showBorder) => {
    return (
      <>
        <div className="system-task-row">
          <Image src={taskUnio} className="download-icon" />
          <label className="header-text download-title">
            {item.name.length > 31
              ? item.name.substring(0, 28) + '...'
              : item.name}
          </label>
          <div
            className={`d-flex flex-column ${
              item.status !== DownloadTag.canceled
                ? 'system-task-row-status-downloading'
                : 'system-task-row-status'
            } `}
          >
            {CustomStatusTagFromText(
              item.displayStatus,
              'system-task-row-status-label',
            )}
            {getCancelOrDownload(item)}
          </div>
        </div>
        {showBorder ? <div className="system-task-row-border" /> : null}
      </>
    );
  };

  return (
    <div className={`header-and-footer header ${isRecycling ? 'hide-header-and-footer' : ''}`}>
    <div className="header-container">
      <Image src={settingIcon} className="setting-icon" />
      <label className="header-text">Setting</label>
      <div
        onMouseEnter={()=> setIsOpenLogoutDropdown(true)}
        onMouseLeave={()=> setIsOpenLogoutDropdown(false)}
        className="header-user-area"
      >
        <div className="header-user-area-photo-area">
          <Image src={usePhoto} className="uesr-photo" />
          <Collapse in={isOpenLogoutDropdown}>
            <div className="header-user-area-logout-dropdown" onClick={logout}>
              <div className="header-user-area-logout-dropdown-container">
                <Image
                  className="header-user-area-logout-image"
                  src={LogoutBackButton}
                />
                <label className="header-user-area-logout-text">LOGOUT</label>
              </div>
            </div>
          </Collapse>
        </div>
        <label className="header-text">{user.payload?.username}</label>
      </div>
      {checkHasPermission(user, PermissionCodes.addExportjob) ? (
        <button
          className="system-task-area"
          ref={target}
          onClick={() => {
            setShowTasks(!showTasks);
            dispatch(createAction('downloadAndImport/getSystemTasks')({}));
          }}
          requires={PermissionCodes.addExportjob}
        >
          <Image src={downloadIcon} className="download-icon" />
          <label className="header-text">System task</label>
          {downloadingCount > 0 ? (
            <label className="badge-icon">{downloadingCount}</label>
          ) : null}
        </button>
      ) : null}

      {/* <AuthButton
        customClass="btn-link reset-button system-task-area"
        ref={target}
        action={() => {
          setShowTasks(!showTasks);
          dispatch(createAction('downloadAndImport/getSystemTasks')({}));
        }}
        requires={PermissionCodes.addExportjob}
      >
        <Image src={downloadIcon} className="download-icon" />
        <label className="header-text">System task</label>
        {downloadingCount > 0 ? (
          <label className="badge-icon">{downloadingCount}</label>
        ) : null}
      </AuthButton> */}
      <Overlay target={target.current} show={showTasks} placement="bottom">
        {(props) => (
          // <Tooltip id="overlay-example" {...props} arrowProps={null}>
          <div
            {...props}
            className="overlay-trigger"
            style={{
              backgroundColor: '#ffffff',
              padding: '2px 10px',
              color: 'white',
              borderRadius: 3,
              ...props.style,
              zIndex: 3,
            }}
          >
            <Popover
              id={`popover-positioned-bottom`}
              style={{}}
              arrowProps={null}
            >
              <Popover.Title>
                <div className="system-task-header">
                  <label className="system-task">System task</label>

                  <button
                    className="custom-image-button margin-left-auto"
                    onClick={() => {
                      setShowTasks(false);
                    }}
                  >
                    <Image src={closeIcon} />
                  </button>
                </div>
              </Popover.Title>
              <Popover.Content>
                {systemTasks.length > 0 ? (
                  <div className="system-task-content">
                    {systemTasks.map((item, index) => {
                      return downloadLine(item, index < systemTasks.length - 1);
                    })}
                  </div>
                ) : (
                  <div className="system-task-no-content">
                    <CustomTitleLabel title="No task here !" />
                    <label className="tips-message">
                      No task here ! There is no uploaded or downloaded task at
                      the moment
                    </label>
                  </div>
                )}
              </Popover.Content>
            </Popover>
          </div>
          // </Tooltip>
        )}
      </Overlay>
      {/* <OverlayTrigger
        trigger="click"
        key="bottom"
        placement="bottom"
        show={showTasks}
        onToggle={() => {}}
        overlay={
          <div className="overlay-trigger">
            <Popover
              id={`popover-positioned-bottom`}
              style={{ maxWidth: '350px', width: '600px' }}
            >
              <Popover.Title>
                <div className="system-task-header">
                  <label className="system-task">System task</label>

                  <Button
                    className="custom-image-button margin-left-10-percent"
                    onClick={() => {
                      setShowTasks(false);
                    }}
                  >
                    <Image src={closeIcon} />
                  </Button>
                </div>
              </Popover.Title>
              <Popover.Content>
                {systemTasks.map((item, index) => {
                  return downloadLine(item, index < systemTasks.length - 1);
                })}
              </Popover.Content>
            </Popover>
          </div>
        }
      >
        <div className="system-task-area">
          <Image src={downloadIcon} className="download-icon" />
          <label className="header-text">System task</label>
          <label className="badge-icon">1</label>
        </div>
      </OverlayTrigger> */}
    </div>
    </div>
  );
}

export default withRouter(Header);
