import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import { useCompare } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import {
  SavedStatus,
  LanguageConfig,
  DELETE_RELATED_SECTIONS,
  CheckStatus,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import ImageUploader from '../../../components/base/ImageUploader';

import CustomEditor from '../../../components/base/CustomEditor';
import i18n from '../../../I18n';
import { ErrorHandleFields } from './ErrorHandleFields';
import RewardSectionCommon from '../../../components/base/RewardSectionCommon';
import Loading from '../../../components/base/Loading';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';
import './CommunityChange.scss';

const getError = (name, errorFields, errorHandle) => {
  return {
    id: name,
    error: errorFields?.fields?.includes(name),
    message: errorFields?.messages?.map((item) => {
      if (item.field === name) {
        return errorHandle[item.field][item.errorType];
      }
    }),
  };
};

function CommunityForm({ object, checked, errorFields, saved }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [name, setName] = useState(object.name);
  const [termsAndCondition, setTermsAndCondition] = useState(
    object.termsAndCondition,
  );
  const [communityCode, setCommunityCode] = useState(object.communityCode);
  const [communityLimit, setCommunityLimit] = useState(object.communityLimit);
  const [coverPhoto, setCoverPhoto] = useState(object.coverPhoto);
  const [rewardType, setRewardType] = useState(object.rewardType);
  const [rewardPoints, setRewardPoints] = useState(object.rewardPoints);
  const [rewardCouponTemplate, setRewardCouponTemplate] = useState(
    object.rewardCouponTemplate,
  );
  const [rewardCouponQuantity, setRewardCouponQuantity] = useState(
    object.rewardCouponQuantity,
  );
  const [carbonSavingQuantity, setCarbonSavingQuantity] = useState(
    object.carbonSavingQuantity,
  );

  const vals = {
    name,
    coverPhoto,
    communityCode,
    communityLimit,
    rewardType,
    rewardPoints,
    rewardCouponTemplate,
    rewardCouponQuantity,
    carbonSavingQuantity,
    termsAndCondition,
  };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'communityModel/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch({
        type: 'communityModel/createOrUpdate',
        payload: {
          afterAction: id ? () => history.push('/communities') : () => {},
        },
      });
    }
  }, [dispatch, checked]);

  const getLanguageTab = (language) => {
    const sections = [
      <>
        <div style={{ marginTop: '-30px' }}>
          <CustomTitleWithInput
            title={i18n.t('name', { locale: language })}
            defaultValue={name ? name[language] : null}
            setValue={(value) => {
              setName({
                ...name,
                [language]: value,
              });
            }}
            error={
              language === LanguageConfig.english
                ? getError('name', errorFields, ErrorHandleFields)
                : {}
            }
          />
          <>
            <CustomTitleLabel
              title={i18n.t('community_terms_condition', { locale: language })}
            />
            <CustomEditor
              initialValue={
                termsAndCondition ? termsAndCondition[language] : null
              }
              onValueChange={(data) => {
                setTermsAndCondition({
                  ...termsAndCondition,
                  [language]: data,
                });
              }}
              error={
                language === LanguageConfig.english
                  ? getError(
                      'termsAndCondition',
                      errorFields,
                      ErrorHandleFields,
                    ).error
                  : false
              }
              errorMessage={ErrorHandleFields.termsAndCondition.required}
            />
          </>

          {language === 'en' ? (
            <>
              <CustomTitleWithInput
                title={'Community Code'}
                defaultValue={communityCode}
                setValue={setCommunityCode}
                error={getError(
                  'communityCode',
                  errorFields,
                  ErrorHandleFields,
                )}
              />
              <CustomTitleWithInput
                type={'number'}
                title={'Community Limit'}
                defaultValue={communityLimit}
                setValue={setCommunityLimit}
                error={getError(
                  'communityLimit',
                  errorFields,
                  ErrorHandleFields,
                )}
                customInputClassName='create-community-community-limit'
              />
              <div style={{ display: 'grid' }}>
                <CustomTitleLabel title={'Logo Image'} />
                <label className="create-message-suggest">
                  {i18n.t('square_cover_photo_suggest')}
                </label>
                <ImageUploader
                  minWidth={600}
                  minHieght={600}
                  images={coverPhoto ? [coverPhoto] : []}
                  onImageStateChange={(newState) => {
                    setCoverPhoto(newState[0]);
                  }}
                  aspect={1 / 1}
                  maxImageNum={1}
                  uploadImageClicked={() => {}}
                  language={language}
                />
                <ErrorFieldMessage
                  id={`coverPhoto-${language}`}
                  error={
                    getError('coverPhoto', errorFields, ErrorHandleFields).error
                  }
                  message={ErrorHandleFields.coverPhoto.required}
                />
              </div>
            </>
          ) : null}
        </div>
      </>,
    ];
    if (language === LanguageConfig.english) {
      sections.push(
        <RewardSectionCommon
          customizeTitle="Reward for joining the community (optional)"
          typeChange={setRewardType}
          couponChange={setRewardCouponTemplate}
          carbonChange={setCarbonSavingQuantity}
          pointsChange={setRewardPoints}
          couponQuantityChange={setRewardCouponQuantity}
          initialValue={{
            rewardType: rewardType,
            couponTemplate: rewardCouponTemplate,
            carbon: carbonSavingQuantity,
            points: rewardPoints,
            couponQuantity: rewardCouponQuantity,
          }}
        />,
      );
    }
    return sections;
  };

  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  const stepSet = [
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete Community' : null}
        saveText={params.id ? 'Updated' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={() => {
          dispatch({
            type: 'communityModel/checkValsValid',
            payload: { coverPhoto: coverPhoto },
          });
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.changeCommunity}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Community',
          content: 'Did you confirm to delete this Community?',
        }}
        title={'Community'}
        data={object}
        relatedSections={DELETE_RELATED_SECTIONS.COMMUNITY}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'communityModel/delete',
            payload: {
              id: object.pk,
              afterAction: () => {
                history.push('/communities');
              },
            },
          });
        }}
      />
    </>,
  ];

  const getButtons = () => {
    return [
      {
        text: 'Back to community list',
        action: () => {
          history.push({
            pathname: '/communities',
          });
        },
      },
    ];
  };

  return (
    <div className="customer">
      <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            dispatch({
              type: 'communityModel/removeFromCookie',
            });
          }
        }}
        showFinishPop={saved === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Communityis successfully created.',
          buttons: getButtons(),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={id ? object.name?.[LanguageConfig.english] : ''}
          />
        }
        caution={{
          detail: '',
          title: id
            ? object.name?.[LanguageConfig.english]
            : 'Create Community',
          model: id ? null : 'communityModel',
        }}
      />
    </div>
  );
}

function CommunityChange() {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  const { object, checked, errorFields, saved, objectLoading } = useSelector(
    (state) => ({
      object: state.communityModel.oneObject || {},
      checked: state.communityModel.checked,
      errorFields: state.communityModel.errorFields,
      saved: state.communityModel.saved,
      objectLoading: state.communityModel.objectLoading,
    }),
  );
  useEffect(() => {
    dispatch({ type: 'communityModel/getObject', payload: { id } });
    return () => {
      dispatch({ type: 'communityModel/clearData' });
    };
  }, []);

  if (objectLoading) {
    return <ContentSections sections={[<Loading />]} hidePreview />;
  }
  return (
    <CommunityForm
      object={object}
      saved={saved}
      checked={checked}
      errorFields={errorFields}
    />
  );
}

export default CommunityChange;
