import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { isShowError, createAction } from "../../utils";
import CustomInputWithTitle from "../customer/CustomInputWithTitle";
import { CustomTitleWithDropDown } from "../customer/CustomTitleWithDropDown";
import CustomSwitchButton from "../base/CustomSwitchButton";
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ImageUploader from "../base/ImageUploader";
import {
  APIStatus,
  BarcodeVolumeConversion,
  LanguageConfig,
} from "../../config/CustomEnums";
import i18n from "../../I18n";
import { IMAGE_TYPES } from "../../models/UploadFilesModel";
import { useParams } from 'react-router-dom';

function WhatToDoNextGeneralCard({
  language,
}) {
  const dispatch = useDispatch();

  const {
    nameFromTranslation,
    displayInGuestView,
    translations,
    displayPriority
  } = useSelector((state) => ({
    displayInGuestView: state.whatToDoNextList.whatToDoNext.displayInGuestView,
    nameFromTranslation: state.whatToDoNextList.whatToDoNext.translations[language].name,
    displayPriority: state.whatToDoNextList.whatToDoNext.displayPriority,
    translations: state.whatToDoNextList.whatToDoNext.translations,
    customImageSmallDeviceList: [
      state.whatToDoNextList.whatToDoNext.customImageSmallDevice1,
      state.whatToDoNextList.whatToDoNext.customImageSmallDevice2,
      state.whatToDoNextList.whatToDoNext.customImageSmallDevice3,
      state.whatToDoNextList.whatToDoNext.customImageSmallDevice4,
      state.whatToDoNextList.whatToDoNext.customImageSmallDevice5,
      state.whatToDoNextList.whatToDoNext.customImageSmallDevice6,
    ],
    customImageLargeDeviceList: [
      state.whatToDoNextList.whatToDoNext.customImageLargeDevice1,
      state.whatToDoNextList.whatToDoNext.customImageLargeDevice2,
      state.whatToDoNextList.whatToDoNext.customImageLargeDevice3,
      state.whatToDoNextList.whatToDoNext.customImageLargeDevice4,
      state.whatToDoNextList.whatToDoNext.customImageLargeDevice5,
      state.whatToDoNextList.whatToDoNext.customImageLargeDevice6,
    ]
  }));

  const numberInput = ({
    id,
    labelText,
    type,
    valueChange,
    fieldDescription,
    defaultValue = undefined,
    onError = false,
  }) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {labelText}
        </label>
        <div>
          <input
            value={defaultValue}
            type="number"
            min="0"
            className={`coupon-campaign-section-input ${onError ? 'error-field' : ''
              }`}
            onChange={({ target }) => {
              let value = target.value;
              if (value === '') {
                value = null;
              }
              valueChange(value);
            }}
          />
          <label className="section-short-description">{type}</label>
        </div>
        {fieldDescription ? (
          <label
            id={id}
            className={`${onError
              ? 'error-field-message-style'
              : 'coupon-campaign-section-caution-description'
              } `}
          >
            {fieldDescription}
          </label>
        ) : null}
      </>
    );
  };

  const updateData = useCallback((data) => {
    dispatch({
      type: 'whatToDoNextList/updateWhatToDoNext',
      payload: data,
    });
  }, [nameFromTranslation, displayInGuestView, displayPriority])
  const displayInGuestViewText = displayInGuestView ? 'yes' : 'no';
  return (
    <>
      <div className="first-section-title">{i18n.t('what_to_do_next_general', { locale: language })}</div>
      <CustomTitleLabel title={i18n.t('what_to_do_next_name', { locale: language })} />
      <label>{nameFromTranslation}</label>
      {/* display in guest view */}
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('what_to_do_next_display_in_guest_view', { locale: language })}
      </label>
      {language !== LanguageConfig.english
        ? (i18n.t(displayInGuestViewText, { locale: language }))
        : (
          <CustomSwitchButton
            defaultChecked={displayInGuestView}
            onChange={(value) => {
              updateData({ displayInGuestView: value })
            }}
          />
        )}
      {/* display order */}
      {numberInput({
        id: 'whatToDoNextDisplayPriority',
        labelText: `${i18n.t('what_to_do_next_display_order', { locale: language })}*`,
        valueChange: (value) => {
          updateData({ displayPriority: value })
        },
        fieldDescription:
          'Minimum value is 1. The smaller value, the higher priority.',
        defaultValue: displayPriority,
      })}
    </>
  );
}

export default WhatToDoNextGeneralCard