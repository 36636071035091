import React from 'react';
import './DisplayOrderTableData.scss';
const DisplayOrderTableData = ({ item, onDone, editable, fieldName }) => {
  // console.log(item);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [value, setValue] = React.useState(item[fieldName]);
  React.useEffect(() => {
    setValue(item[fieldName]);
  }, [item]);
  return (
    <td>
      <div
        className={`all-list-scrollable-area-table-item-common sort-disabled`}
      >
        <div className={`all-list-scrollable-area-text-common list-new-line`}>
          {isEditMode ? (
            <div style={{ lineHeight: '35px' }}>
              <input
                type="number"
                min="1"
                value={value}
                className="order-input"
                onChange={({ target }) => {
                  setValue(target.value);
                }}
              />
              <div
                className="order-edit-done-btn sort-disabled"
                onClick={() => {
                  setIsEditMode(false);
                  onDone && onDone(item, parseInt(value));
                }}
              >
                Done
              </div>
            </div>
          ) : (
            <>
              <div>{value || '-'}</div>
              {editable ? (
                <div
                  className="order-edit-btn sort-disabled"
                  onClick={() => setIsEditMode(true)}
                >
                  Edit
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </td>
  );
};
export default DisplayOrderTableData;
