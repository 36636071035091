import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Image } from 'react-bootstrap';
import { getImageUrl, isShowError } from '../../utils';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import i18n from '../../I18n';
import { CreateSplashAdError, SplashAdRedirectList, SplashAdRedirectType, SplashAdTypesList } from '../../models/SplashAdModel';
import { LanguageConfig } from '../../config/CustomEnums';
import CustomRadiosWithImage from '../base/CustomRadiosWithImage';
import CheckedRadio from '../../assets/images/checked_radio.svg';
import UncheckedRadio from '../../assets/images/unchecked_radio.svg';
import './GeneralSection.scss';
import ImageUploader from '../base/ImageUploader';
import CustomRadios from '../base/CustomRadios';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CampaignSelectDropdown from '../message/CampaignSelectDropdown';

const GeneralSection = ({
  language,
  splashAdDetail,
  errorFields,
}) => {
  const dispatch = useDispatch();
  const {
    displayPriority,
    type,
    defaultLoadingDelay,
    loadingDelay,
    alwaysDisplay,
    displayFrequencyLimit,
    displayFrequencyLimitNumberOfDays,
    redirectType,
    redirectUrl,
    inAppCampaign,
    translations = {},
  } = splashAdDetail || {};

  const splashAdTitle = translations?.[language]?.name;
  const coverPhoto = translations?.[language]?.coverPhoto;
  const [coverPhotos, setCoverPhotos] = useState(coverPhoto ? [coverPhoto] : []);

  const isError = (field) => {
    return isShowError(field, errorFields, language);
  };

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>
      <CustomTitleWithInput
        title={i18n.t('name', { locale: language })}
        defaultValue={splashAdTitle}
        setValue={(value) => {
          dispatch({
            type: 'splashAd/updateSplashAdDetailTranslations',
            payload: { name: value, language },
          });
        }}
        error={{
          id: CreateSplashAdError.name.name,
          error: isError(CreateSplashAdError.name.name),
          message: isError(CreateSplashAdError.name.name)
            ? CreateSplashAdError.name.message
            : ''
        }}
      />
      {
        language === LanguageConfig.english
          ? <>
            <label className="create-section-label create-section-label-bottom-space">Splash Ad style</label>
            <Row className="featured-exclusive-row">
              <CustomRadiosWithImage
                haveImage
                onChange={(value) => {
                  console.log("@@splashAd change type", value)
                  dispatch({
                    type: 'splashAd/updateSplashAdDetail',
                    payload: { type: value },
                  });
                }}
                default={type}
                options={SplashAdTypesList}
              />
            </Row>

            <label className="create-section-label create-section-label-bottom-space">Splash Ad loading delay time</label>
            <Row className="featured-exclusive-row">
              <div
                className={`radio-button-container splash-ad-radio`}
                onClick={() => {
                  if (defaultLoadingDelay) {
                    return;
                  }
                  console.log("@@splashAd change loading delay is default true")
                  dispatch({
                    type: 'splashAd/updateSplashAdDetail',
                    payload: {
                      defaultLoadingDelay: true,
                    },
                  });
                }}
              >
                <Image
                  src={defaultLoadingDelay ? CheckedRadio : UncheckedRadio}
                  className="radio-button"
                />
                <label className={'radio-button-label'}>3 seconds</label>
              </div>
              <div
                className={`radio-button-container splash-ad-radio`}
                onClick={() => {
                  if (!defaultLoadingDelay) {
                    return;
                  }
                  console.log("@@splashAd change loading delay is default false")
                  dispatch({
                    type: 'splashAd/updateSplashAdDetail',
                    payload: {
                      defaultLoadingDelay: false,
                    },
                  });
                }}
              >
                <Image
                  src={!defaultLoadingDelay ? CheckedRadio : UncheckedRadio}
                  className="radio-button"
                />
                <label className={'radio-button-label'}>Customised</label>
                <input
                  type="number"
                  min={0}
                  onChange={({ target }) => {
                    console.log("@@splash ad change loading delay", target.value)
                    dispatch({
                      type: 'splashAd/updateSplashAdDetail',
                      payload: {
                        loadingDelay: target.value,
                      },
                    });
                  }}
                  value={defaultLoadingDelay ? '' : loadingDelay}
                  className={`splash-ad-input ${isError(CreateSplashAdError.loadingDelay.name) ? 'error-field' : ''}`}
                />
                <label className={'radio-button-label'}>seconds</label>
              </div>
            </Row>
            <ErrorFieldMessage
              id={CreateSplashAdError.loadingDelay.name}
              error={isError(CreateSplashAdError.loadingDelay.name)}
              message={CreateSplashAdError.loadingDelay.message}
            />

            <label className="create-section-label create-section-label-bottom-space">Splash Ad display frequency</label>
            <Row className="featured-exclusive-row">
              <div
                className={`radio-button-container splash-ad-radio`}
                onClick={() => {
                  if (alwaysDisplay) {
                    return;
                  }
                  console.log("@@splashAd change display frequency is always true")
                  dispatch({
                    type: 'splashAd/updateSplashAdDetail',
                    payload: {
                      alwaysDisplay: true,
                    },
                  });
                }}
              >
                <Image
                  src={alwaysDisplay ? CheckedRadio : UncheckedRadio}
                  className="radio-button"
                />
                <label className={'radio-button-label'}>Always display</label>
              </div>
              <div
                className={`radio-button-container splash-ad-radio`}
                onClick={() => {
                  if (!alwaysDisplay) {
                    return;
                  }
                  console.log("@@splashAd change display frequency is always false")
                  dispatch({
                    type: 'splashAd/updateSplashAdDetail',
                    payload: {
                      alwaysDisplay: false,
                    },
                  });
                }}
              >
                <Image
                  src={!alwaysDisplay ? CheckedRadio : UncheckedRadio}
                  className="radio-button"
                />
                <input
                  type="number"
                  min={1}
                  onChange={({ target }) => {
                    console.log("@@splash ad change displayFrequencyLimit", target.value)
                    dispatch({
                      type: 'splashAd/updateSplashAdDetail',
                      payload: {
                        displayFrequencyLimit: target.value,
                      },
                    });
                  }}
                  value={alwaysDisplay ? '' : displayFrequencyLimit}
                  className={`splash-ad-input ${isError(CreateSplashAdError.displayFrequencyLimit.name) ? 'error-field' : ''}`}
                />
                <label className={'radio-button-label'}>times per</label>
                <input
                  type="number"
                  min={1}
                  onChange={({ target }) => {
                    console.log("@@splash ad change displayFrequencyLimitNumberOfDays", target.value)
                    dispatch({
                      type: 'splashAd/updateSplashAdDetail',
                      payload: {
                        displayFrequencyLimitNumberOfDays: target.value,
                      },
                    });
                  }}
                  value={alwaysDisplay ? '' : displayFrequencyLimitNumberOfDays}
                  className={`splash-ad-input ${isError(CreateSplashAdError.displayFrequencyLimitNumberOfDays.name) ? 'error-field' : ''}`}
                />
                <label className={'radio-button-label'}>day(s)</label>
              </div>
            </Row>
            <ErrorFieldMessage
              id={CreateSplashAdError.displayFrequencyLimit.name}
              error={
                isError(CreateSplashAdError.displayFrequencyLimit.name)
                || isError(CreateSplashAdError.displayFrequencyLimitNumberOfDays.name)
              }
              message={CreateSplashAdError.displayFrequencyLimit.message}
            />

            <CustomTitleWithInput
              title={'Display order'}
              tips={'Minimum value is 1. The smaller value, the higher priority.'}
              defaultValue={displayPriority}
              setValue={(value) => {
                dispatch({
                  type: 'splashAd/updateSplashAdDetail',
                  payload: {
                    displayPriority: value,
                  },
                });
              }}
              type={'number'}
              error={
                isError(CreateSplashAdError.displayOrder.name)
                  ? {
                    id: CreateSplashAdError.displayOrder.name,
                    error: true,
                    message: CreateSplashAdError.displayOrder.message,
                  }
                  : {}
              }
            />
          </>
          : null
      }

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('splash_ad_cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={1600}
        enableGif
        images={coverPhotos}
        onImageStateChange={(newState) => {
          console.log("@@257", newState)
          dispatch({
            type: 'splashAd/updateSplashAdDetailTranslations',
            payload: { coverPhoto: getImageUrl(newState?.[0]), language },
          });
          setCoverPhotos(newState);
        }}
        uploadImageClicked={() => { }}
        aspect={9 / 16}
        maxImageNum={1}
        language={language}
        errorMessage={`${isError(CreateSplashAdError.image.name)
          ? CreateSplashAdError.image.message
          : ''
          }`}
      />

      {
        language === LanguageConfig.english
          ? <>
            <label className="create-section-label create-section-label-bottom-space">Call to action (Optional)</label>
            <Row className="featured-exclusive-row">
              <CustomRadios
                onChange={(value) => {
                  console.log("@@splashAd change call to action", value)
                  dispatch({
                    type: 'splashAd/updateSplashAdDetail',
                    payload: { redirectType: value },
                  });
                }}
                default={redirectType}
                options={SplashAdRedirectList}
              />
            </Row>
            <ErrorFieldMessage
              id={CreateSplashAdError.redirectType.name}
              error={isError(CreateSplashAdError.redirectType.name)}
              message={CreateSplashAdError.redirectType.message}
            />
            {
              redirectType === SplashAdRedirectType.EXTERNAL_URL.value
                ? <>
                  <label className="tips-message">Support redirection to URL only</label>
                  <textarea
                    onChange={({ target }) => {
                      console.log("@@change splash ad redirection url", target.value)
                      dispatch({
                        type: 'splashAd/updateSplashAdDetail',
                        payload: { redirectUrl: target.value },
                      });
                    }}
                    className="custom-textarea-input-box custom-textarea-input-font"
                    value={redirectUrl}
                  />
                  <ErrorFieldMessage
                    id={CreateSplashAdError.externalUrl.name}
                    error={isError(CreateSplashAdError.externalUrl.name)}
                    message={CreateSplashAdError.externalUrl.message}
                  />
                </>
                : null
            }
            {
              redirectType === SplashAdRedirectType.IN_APP_CAMPAIGN.value
                ? <>
                  <label className="tips-message">Select the In-app campaign</label>
                  <CampaignSelectDropdown
                    title={''}
                    defaultValue={{ 
                      label: inAppCampaign ? `${inAppCampaign?.name} (ID: ${inAppCampaign?.pk})` : '', 
                      value: inAppCampaign
                    }}
                    sourceShowId
                    showMultiLine
                    setValue={(value) => {
                      dispatch({
                        type: 'splashAd/updateSplashAdDetail',
                        payload: { inAppCampaign: value },
                      });
                    }}
                  />
                  <ErrorFieldMessage
                    id={CreateSplashAdError.inAppCampaign.name}
                    error={isError(CreateSplashAdError.inAppCampaign.name)}
                    message={CreateSplashAdError.inAppCampaign.message}
                  />
                </>
                : null
            }
          </>
          : null
      }
    </>
  )
}


const mapStateToProps = (state) => ({
  splashAdDetail: state.splashAd.splashAdDetail,
  errorFields: state.splashAd.errorFields,
})

export default connect(mapStateToProps)(GeneralSection);
