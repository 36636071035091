import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateMissionError } from '../../models/MissionModel';
import { compareDate, getCurrentDay } from '../../utils/TimeFormatUtil';
import PeriodSection from '../campaign/campaignCreation/PeriodSection';

function VisiblePeriodSection({
  onSubmit = false,
  onSubmitAction = (() => { }),
  isAlwaysVisiblePeriod,
  visibleStartDate,
  visibleEndDate,
  errorFields,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        isAlwaysVisiblePeriod: isAlwaysVisiblePeriod,
        visibleStartDate: visibleStartDate,
        visibleEndDate: visibleEndDate,
      });
    }
  }, [onSubmit]);

  return (
    <PeriodSection
      disabled={false}
      title="Visible Period"
      maxDate={null}
      minDate={null}
      titleDescription={'Display this mission on app.'}
      switchButtonTitle="It is an all-time visible mission"
      isAlwaysPeriod={isAlwaysVisiblePeriod}
      switchOnChange={(value) => {
        dispatch({
          type: 'mission/updateMissionDetail',
          payload: { isAlwaysVisiblePeriod: value},
        });
      }}
      startDate={visibleStartDate}
      startDateChange={(value) => {
        dispatch({
          type: 'mission/updateMissionDetail',
          payload: { visibleStartDate: value},
        });
      }}
      filterStartDate={(date) => {
        return true;
      }}
      endDate={visibleEndDate}
      endDateChange={(value) => {
        dispatch({
          type: 'mission/updateMissionDetail',
          payload: { visibleEndDate: value},
        });
      }}
      filterEndDate={(date) => {
        return true;
      }}
      hideTime={false}
      errorFields={errorFields}
      fieldName={CreateMissionError.visibleEndDate.name}
      fieldErrorMessage={CreateMissionError.visibleEndDate.message}
    />
  );
}


const mapStateToProps = (state) => ({
  isAlwaysVisiblePeriod: state.mission.missionDetail.isAlwaysVisiblePeriod,
  visibleStartDate: state.mission.missionDetail.visibleStartDate,
  visibleEndDate: state.mission.missionDetail.visibleEndDate,
  errorFields: state.mission.errorFields,
});

export default connect(mapStateToProps)(VisiblePeriodSection);
