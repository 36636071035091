import React from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

function CustomInputWithTitle({ title, titleStyle, type, disabled, defaultValue, placeholder, setValue, error = {} }) {
  return (
    <div style={{ display: 'grid' }}>
      <CustomTitleLabel title={title} className={titleStyle || ''} />
      <input
        type={ type || 'text'}
        disabled={disabled || false}
        onChange={({ target }) => setValue(target.value)}
        value={defaultValue || ''}
        placeholder={placeholder || ''}
        className={`custom-markdown-area-title ${
          error.error ? 'error-field' : ''
        } custom-markdown-area-title-short custom-input-with-title`}
        onFocus={() => {}}
      />
      <ErrorFieldMessage
        id={error.id}
        error={error.error}
        message={error.message}
      />
    </div>
  );
}

export default CustomInputWithTitle;
