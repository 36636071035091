import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import './QRCodeScanningSection.scss';
import { CSVReader } from 'react-papaparse';
import {
  CustomTitleLabel,
  CustomNumberInput,
  CustomNumberInputWithUnit,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { Button, Image } from 'react-bootstrap';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import deleteImage from '../../assets/images/earning/deletex1.png';
import { removeElementFromArray, useCompare } from '../../utils';
import { EarningRuleType } from '../../config/CustomEnums';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
const buttonRef = createRef();

function QRCodeScanningSection(props) {
  const camma = ',';

  const dispatch = useDispatch();

  const defaultValue = props.defaultValue || {};
  const [inputQRCodes, setInputQRCodes] = useState(defaultValue.qrCodes || []);
  const [importQRCodes, setImportQRCodes] = useState([]);
  const [qrCodes, setQRCodes] = useState(defaultValue.qrCodes || []);
  const [showFileBox, setShowFileBox] = useState(false);
  const [overallLimit, setOverallLimit] = useState(defaultValue.overallLimit);
  const [periodicLimit, setPeriodicLimit] = useState(
    defaultValue.periodicLimit,
  );
  const [periodicLimitDays, setPeriodicLimitDays] = useState(
    defaultValue.periodicLimitDays,
  );
  const [perHeadLimit, setPerHeadLimit] = useState(defaultValue.perHeadLimit);
  const [perHeadPeriodicLimit, setPerHeadPeriodicLimit] = useState(
    defaultValue.perHeadPeriodicLimit,
  );
  const [perHeadPeriodicLimitDays, setPerHeadPeriodicLimitDays] = useState(
    defaultValue.perHeadPeriodicLimitDays,
  );

  const [machineId, setMachineId] = useState(defaultValue.machineId);

  const vals = {
    qrCodes,
    overallLimit,
    periodicLimit,
    periodicLimitDays,
    perHeadLimit,
    perHeadPeriodicLimit,
    perHeadPeriodicLimitDays,
    machineId,
  };

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => { });
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));

  const hasValChanged = useCompare(vals);
  useEffect(() => {
    if (hasValChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.qrCodes.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');

    let importedQRCodes = [];
    data.map((element) => {
      const hasValueData = removeElementFromArray(element.data, '');
      if (hasValueData.length > 0) {
        // importQRCodes.push(hasValueData);
        importedQRCodes = [...importedQRCodes, ...hasValueData];
      }
    });
    setImportQRCodes(importedQRCodes);
    // setQRCodes([...qrCodes, ...importQRCodes]);
    setQRCodesWithoutDuplicate(inputQRCodes, importedQRCodes);

    setShowFileBox(true);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
      setQRCodes(
        qrCodes.filter(function (val) {
          return importQRCodes.indexOf(val) === -1;
        }),
      );
      setImportQRCodes([]);

      setShowFileBox(false);
    }
  };

  const setQRCodesWithoutDuplicate = (inputQRCodes, importQRCodes) => {
    const nonEmptyInputQRcodes = removeElementFromArray(inputQRCodes, '');
    const nonEmptyImportQRcodes = removeElementFromArray(importQRCodes, '');

    const importRemoveDuplicateFromInput = nonEmptyImportQRcodes.filter(
      (val) => !nonEmptyInputQRcodes.includes(val),
    );

    setQRCodes([...importRemoveDuplicateFromInput, ...nonEmptyInputQRcodes]);
  };

  const qrCodesCom = (type) => {
    const isActivationCode = type === EarningRuleType.activationCode;
    const isExtraReward = type === EarningRuleType.qrCodeRecyclingExtraRewards;
    return (
      <>
        <label className="create-section-title">{isActivationCode ? 'Activation Code' : 'QR CODE SCANNING'}</label>

        <CustomTitleLabel title={isActivationCode ? 'Input codes' : 'Input QR codes'} />
        <label className="tips-message">
          {isActivationCode ? 'Separate the codes by comma.' : 'Separate the QR codes by comma.'}
        </label>
        <textarea
          onChange={({ target }) => {
            const currentQRcodes = target.value.split(camma);
            setInputQRCodes(currentQRcodes);
            setQRCodesWithoutDuplicate(currentQRcodes, importQRCodes);
          }}
          className="custom-textarea-input-box custom-textarea-input-font"
          onFocus={() => { }}
          value={inputQRCodes.join(camma)}
        />

        <ErrorFieldMessage
          id={`${EarningRuleErrorHandleField.qrCodes.name}`}
          error={isError('qrCodes')}
          message={`${EarningRuleErrorHandleField.qrCodes.message}`}
        />

        {isExtraReward ? <></> :
          <>
            <CustomTitleLabel title={isActivationCode ? 'Import codes' : 'Import QR codes'} />
            <label className="tips-message">
              {isActivationCode ? 'Upload a CSV file contains codes.' : 'Upload a CSV file contains QR codes.'}
            </label>

            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              onError={handleOnError}
              noClick
              noDrag
              onRemoveFile={handleOnRemoveFile}
            >
              {({ file }) => (
                <div>
                  <div style={{ display: showFileBox ? 'flex' : 'none' }}>
                    <div className="custom-file-box">{file && file.name}</div>
                    <Button
                      className="custom-image-button"
                      onClick={handleRemoveFile}
                    >
                      <Image src={deleteImage} />
                    </Button>
                  </div>

                  <Button
                    className="multiple-select-option-add btn-add-button-common upload-file-button"
                    onClick={handleOpenDialog}
                  >
                    Choose file
                  </Button>
                </div>
              )}
            </CSVReader>

            <ErrorFieldMessage
              id={`${EarningRuleErrorHandleField.qrCodes.name}`}
              error={isError('qrCodes')}
              message={`${EarningRuleErrorHandleField.qrCodes.message}`}
            />

            <CustomTitleLabel title={isActivationCode ? 'codes' : 'QR codes'} />
            <div>
              {qrCodes.map((element) => {
                return (
                  <div className="qr-codes-delete-button">
                    <label className="custom-textarea-input-font qr-codes-name-label">
                      {element}
                    </label>
                    <Button
                      className="custom-image-button"
                      onClick={() => {
                        setQRCodes(removeElementFromArray(qrCodes, element));
                        setInputQRCodes(
                          removeElementFromArray(inputQRCodes, element),
                        );
                        setImportQRCodes(
                          removeElementFromArray(importQRCodes, element),
                        );
                      }}
                    >
                      <Image src={deleteImage} />
                    </Button>
                  </div>
                );
              })}
            </div>
          </>
        }
      </>
    );
  };

  return (
    <>
      {defaultValue.type === EarningRuleType.qrCodeScanning ||
        defaultValue.type === EarningRuleType.activationCode ||
        defaultValue.type === EarningRuleType.qrCodeRecyclingExtraRewards
        ? (
          qrCodesCom(defaultValue.type)
        ) : (
          <>
            <label className="create-section-title">Water filling</label>
            <CustomTitleWithInput
              title="Machine id (optional)"
              setValue={setMachineId}
              defaultValue={machineId}
            />
          </>
        )}

      {defaultValue.type === EarningRuleType.qrCodeRecyclingExtraRewards
        ? <></>
        : <>
          <CustomTitleLabel title="Overall limit (optional)" />
          <label className="tips-message">
            Maximum number of times to scan QR code check-in per campaign.
          </label>
          <CustomNumberInputWithUnit
            unit="times"
            setValue={setOverallLimit}
            defaultValue={overallLimit}
          />

          <CustomTitleLabel title="Periodic limit (optional)" />
          <label className="tips-message">
            Maximum number of times to scan QR code check-in per period.
          </label>
          <CustomNumberMutipleInputWithUnit
            per="times per"
            unit="days"
            setFirstValue={setPeriodicLimit}
            firstDefaultValue={periodicLimit}
            setSecondValue={setPeriodicLimitDays}
            secondDefaultValue={periodicLimitDays}
          />

          <CustomTitleLabel title="Per head limit (optional)" />
          <label className="tips-message">
            Maximum number of times to scan QR code check-in per customer.
          </label>
          <CustomNumberInputWithUnit
            unit="times"
            setValue={setPerHeadLimit}
            defaultValue={perHeadLimit}
          />

          <CustomTitleLabel title="Per head periodic limit (optional)" />
          <label className="tips-message">
            Maximum number of times to scan QR code check-in per customer per
            period.
          </label>
          <CustomNumberMutipleInputWithUnit
            per="times per"
            unit="days"
            setFirstValue={setPerHeadPeriodicLimit}
            firstDefaultValue={perHeadPeriodicLimit}
            setSecondValue={setPerHeadPeriodicLimitDays}
            secondDefaultValue={perHeadPeriodicLimitDays}
          />
        </>
      }
    </>
  );
}

export default QRCodeScanningSection;
