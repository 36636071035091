import {
  convertNumberToCursor,
  convertCursorToNumber,
  convertPKToId,
} from '../utils';
import {
  getFeedbackList,
  getFeedbackDetail,
  deleteFeedbacks,
} from '../services/StoreFeedbackAPIHelper';
import { APIStatus, ReportTypes } from '../config/CustomEnums';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { TimeFormater } from '../utils/TimeFormatUtil';
import moment from 'moment';

const ReadableReportTypes = {
  [ReportTypes.InaccurateLocation]: 'Inaccurate location',
  [ReportTypes.InaccurateOpeningHours]: 'Inaccurate opening hours',
  [ReportTypes.InaccurateTag]: 'Inaccurate private / public tag',
  [ReportTypes.CommonRecyclable]:
    'Common recyclables, materials that are available',
  [ReportTypes.Others]: 'Others',
  [ReportTypes.Close]: 'Restaurant closed down',
  [ReportTypes.Available]: 'Not available',
  [ReportTypes.MissingLocation]: 'Missing location',
  [ReportTypes.MissingPhotos]: 'Missing photos',
  [ReportTypes.ShoppingCategory]: 'Inaccurate green shopping category',
  [ReportTypes.ShopClose]: 'Shop closed down',
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Store name', fieldName: 'name' },
    {
      displayName: 'Create time',
      fieldName: 'creationDate',
      orderField: 'creationDate',
    },
    {
      displayName: 'Store ID',
      fieldName: 'storeId',
    },

    { displayName: 'Activity type', fieldName: 'activityType' },
    { displayName: 'Report type', fieldName: 'reportTypeValue' },
  ],
  pagedFeedbackRecordList: [],
  detailApiStatus: APIStatus.none,
  feedbackDetail: {},
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  activityType: [],
});

const getStoreActiivtyType = (subcategories) => {
  const ids = [];
  const subcategoryNames =
    subcategories?.edges?.map((item) => {
      ids.push(item.node?.pk);
      return item.node?.name;
    }) || [];
  return { names: subcategoryNames.toString(), ids };
};

function assembleFeedbackItem(data) {
  return {
    pk: data.pk,
    id: data.id,
    creationDate: moment(data.creationDate).format(
      TimeFormater.dayMonthYearWeekTime,
    ),
    name: data.store?.name,
    storeId: data.store?.pk,
    userGPS: [
      { title: 'Latitude', value: data.latitude },
      { title: 'Longitude', value: data.longitude },
    ],
    userId: data.customer?.pk,
    activityType: getStoreActiivtyType(data.subcategory).names,
    activityIds: getStoreActiivtyType(data.subcategory).ids,
    reportType: data.reportType,
    reportTypeValue: ReadableReportTypes[data.reportType],
    aditionalInformation: data.addInfo,
    relevantImages: data.images,
    userIsAtCorrectLocation: `${data.reportLocation}`,
    correctLocationDescription: data.reportLocationInfo,
    correctOpenHours: data.openingHours,
    whatIsMissing: data.missingItems?.toString(),
    whatIsExtra: data.extraItems?.toString(),
    correctLocationTag: data.locationTag,
    whatCanBeRecyclable: data.whatCanBeRecyclable,
    restaurantName: data.restaurantName,
    greenShoppingType: data.greenShoppingType?.name,
    reportShopName: data.shopName,
  };
}

function assembleFeedbackList(data) {
  return data?.map((item) => assembleFeedbackItem(item.node));
}

export default {
  namespace: 'storeFeedback',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    assembleFeedbackList(state, { payload }) {
      const list = assembleFeedbackList(payload.list);
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        pagedFeedbackRecordList: [...state.pagedFeedbackRecordList, ...list],
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },

    assembleFeedbackDetail(state, { payload }) {
      const item = assembleFeedbackItem(payload.data);
      return {
        ...state,
        feedbackDetail: item,
        detailApiStatus: APIStatus.success,
      };
    },

    resetFeedbacks(state, { payload }) {
      return getInitialState();
    },

    resetFeedbackDetail(state, { payload }) {
      return { ...state, feedbackDetail: {}, detailApiStatus: APIStatus.none };
    },
  },

  effects: {
    getFeedbackList: [
      function* ({ payload }, { put }) {
        const { moreSearch } = payload;
        const page = moreSearch?.page || 1;
        const pageCursor = convertNumberToCursor((page - 1) * 20 - 1);
        const serviceArgs = [getFeedbackList, pageCursor, moreSearch];
        function* onSuccess(data) {
          const datas = data.customerFeedbacks;
          yield put({
            type: 'assembleFeedbackList',
            payload: {
              list: datas.edges,
              totalCount: datas.totalCount,
              pageInfo: datas.pageInfo,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getFeedbackDetail: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: { detailApiStatus: APIStatus.calling },
        });
        const id = convertPKToId('CustomerFeedbackNode', payload.id);
        const serviceArgs = [getFeedbackDetail, id];
        function* onSuccess(data) {
          console.log('getFeedbackDetailData', data);
          yield put({
            type: 'assembleFeedbackDetail',
            payload: { data: data?.customerFeedback },
          });
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { detailApiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { call, select, put }) {
        const selectedIds = yield select(
          (state) => state.storeFeedback.checkedList,
        );
        const deleteIds = selectedIds?.map((item) => item.pk);
        const afterAction = payload.afterAction || (() => {});

        const serviceArgs = [deleteFeedbacks, deleteIds];

        function* onSuccess(data) {
          yield put({ type: 'updateState', payload: { checkedList: [] } });
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
