import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateGreetingCardError } from '../../models/GreetingCardModel';
import { IMAGE_TYPES } from '../../models/UploadFilesModel';
import { isShowError } from '../../utils';
import ImageUploader from '../base/ImageUploader';

function KeyVisualSection({
  greetingPhoto,
  errorFields,
}) {
  const dispatch = useDispatch();
  const defaultImages = [greetingPhoto]?.filter(item => item);
  const [photo, setPhoto] = useState(defaultImages);

  const getImageUrl = (data) => {
    if (!data) {
      return '';
    }
    if (data?.type !== IMAGE_TYPES.TYPE_URL) {
      return '';
    }
    return data?.value;
  };

  return (
    <>
      <label className="create-section-title">KEY VISUAL</label>
      <label className="create-section-label create-section-label-bottom-space">
        Cover photo
      </label>
      <label className="create-campaign-cover-photo-reminder">
        Suggested Image Size: 686 px * 260 px  (Less than 2MB, support JPG, PNG and GIF only.)
      </label>
      <ImageUploader
        minWidth={343}
        minHeight={130}
        images={photo}
        onImageStateChange={(newState) => {
          setPhoto(newState);
          dispatch({
            type: 'greetingCard/updateGreetingCardDetail',
            payload: { greetingPhoto: getImageUrl(newState?.[0]) },
          });
        }}
        aspect={343 / 130}
        maxImageNum={1}
        language={'en'}
        photoSectionTitle={'cover_photo'}
        uploadImageClicked={() => {}}
        errorMessage={`${isShowError(
          CreateGreetingCardError.greetingPhoto.name,
          errorFields,
        )
          ? CreateGreetingCardError.greetingPhoto.message
          : ''
          }`}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  greetingPhoto: state.greetingCard.greetingCard.greetingPhoto,
  errorFields: state.greetingCard.errorFields,
});

export default connect(mapStateToProps)(KeyVisualSection);
