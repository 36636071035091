import { APIStatus } from '../config/CustomEnums';
import {
  getProgressiveProfilingLifetimePoints,
  updateProgressiveProfilingLifetimePoints,
} from '../services/ProgressiveProfilingAPIHelper';
import { convertPKToId } from '../utils';

import { apiWithResponseHandle, loading } from './LoadingUtil';

const getInitialState = () => ({
  part1LifetimePoints: 0,
  part2LifetimePoints: 0,

  apiStatus: APIStatus.none,

  errorFields: {},
});

export default {
  namespace: 'progressiveProfiling',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getProgressiveProfilingLifetimePoints: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });
        const id = convertPKToId(
          'CustomerProgressiveProfilingLifetimePointsNode',
          '1',
        );

        const serviceArgs = [getProgressiveProfilingLifetimePoints, id];
        function* onSuccess(data) {
          console.log(
            'getProgressiveProfilingLifetimePoints onSuccess: ',
            data,
          );
          yield all([
            put({
              type: 'updateState',
              payload: {
                apiStatus: APIStatus.none,
                part1LifetimePoints: data.customerProgressiveProfilingLifetimePoints?.part1LifetimePoints,
                part2LifetimePoints: data.customerProgressiveProfilingLifetimePoints?.part2LifetimePoints,
              },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getProgressiveProfilingLifetimePoints onError: ', err);
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log(
            'getProgressiveProfilingLifetimePoints arguments error: ',
            err,
          );
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],

    updateProgressiveProfilingLifetimePoints: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: {
            apiStatus: APIStatus.loading,
          },
        });

        let params = { ...payload };
        params.id = '1';

        const serviceArgs = [updateProgressiveProfilingLifetimePoints, params];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              apiStatus: APIStatus.success,
              formChanged: false,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },

      { type: 'takeLatest' },
    ],
  },
};
