import onboardingImage from '../../assets/images/mission/onboarding.png';
import campaignLinkedImage from '../../assets/images/mission/campaignLinked.png';
import habitualImage from '../../assets/images/mission/habitual.png';
import { MissionType } from '../../config/CustomEnums';

export const MissionTypesConfig = [
  {
    id: MissionType.onboardingMission,
    title: 'Onboarding Mission',
    image: onboardingImage,
    description: 'Onboarding mission can complete only one time for the first join customers',
  },
  {
    id: MissionType.campaignLinked,
    title: 'Campaign Linked',
    image: campaignLinkedImage,
    description:
      'Campaign linked mission can be linked with earning / coupon campaign. Customers can complete only one time',
  },
  {
    id: MissionType.habitual,
    title: 'Habitual',
    image: habitualImage,
    description:
      'Habitual mission can be linked with earning / coupon campaign and has a level setting with different mission periods',
  },
  {
    id: MissionType.group,
    title: 'Group',
    image: onboardingImage,
    description:
      'Group mission can complete only one time for the first join customers',
  }
];
