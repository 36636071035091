import React, { useState, useEffect } from 'react';
import './CreateCampaignStepTwo.scss';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignType, LanguageConfig } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
import GeneralSection from './GeneralSection';
import DetailSection from './DetailSection';
import InstructionSection from './InstructionSection';
import KeyVisualSection from './KeyVisualSection';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';
import {
  defaultPreviewImage,
  getPreviewLayoutImages,
  getTipsImages,
} from './CampaignStepTwoPreviewConfig';

let dataArray = [];
function CreateCampaignStepTwo(props) {
  const { campaignType, errorFields, campaign } = useSelector((state) => ({
    campaignType: state.createCampaign.campaign.campaignType,
    errorFields: state.createCampaign.errorFields,
    campaign: state.createCampaign.campaign,
  }));
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const [activeSection, setActiveSection] = useState();
  const dispatch = useDispatch();
  const inputWithImageConfig = getPreviewLayoutImages(campaignType);
  const tipsImageConfig = getTipsImages(campaignType);
  const defaultImage = defaultPreviewImage(campaignType);
  const [error, setError] = useState(false);
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  console.log('@type', campaignType)

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    dispatch({ type: 'brand/getCurrentPageBrands', payload: { isAll: true } });
  }, []);

  useEffect(() => {
    if (!onCheck.value) {
      dataArray = [];
      setError(errorFields.length > 0);
    } else {
      let formatedData = {
        [LanguageConfig.english]: {},
        [LanguageConfig.simplifiedChinese]: {},
        [LanguageConfig.traditionalChinese]: {},
      };
      dataArray.forEach((item, index) => {
        const language = item.language;
        const data = item.data;
        formatedData = {
          campaignType,
          ...formatedData,
          [language]: {
            ...formatedData[language],
            ...data,
          },
        };
      });
      dispatch({
        type: 'createCampaign/stepChange',
        payload: {
          data: formatedData,
          isBack: onCheck.isBack,
          step: 1,
        },
      });
      setError(false);
      stopCheck();
    }
  }, [dispatch, errorFields, onCheck.isBack, onCheck.value]);

  const submitAction = (language, data) => {
    dataArray.push({ language, data });
  };

  const getTabs = (language) => {
    return [
      <GeneralSection
        focusChange={(inputArea) => setActiveSection(inputArea)}
        language={language}
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(language, data);
        }}
      />,
      <KeyVisualSection
        focusChange={(inputArea) => setActiveSection(inputArea)}
        language={language}
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(language, data);
        }}
      />,
      campaignType === CampaignType.generalMessageCampaign ? null : (
        <InstructionSection
          focusChange={(inputArea) => setActiveSection(inputArea)}
          language={language}
          onSubmit={onCheck.value}
          onSubmitAction={(data) => {
            submitAction(language, data);
          }}
          titleTips={tipsImageConfig.instructionTitle}
          contentTips={tipsImageConfig.instructionContent}
          defaultValue={campaign.translations[language]}
          valueChangedAction={'createCampaign/setFieldToSession'}
        />
      ),
      <DetailSection
        focusChange={(inputArea) => setActiveSection(inputArea)}
        language={language}
        onSubmit={onCheck.value}
        onSubmitAction={(data) => {
          submitAction(language, data);
        }}
        titleTips={tipsImageConfig.detailsTitle}
        contentTips={tipsImageConfig.detailsContent}
        defaultValue={campaign.translations[language]}
        valueChangedAction={'createCampaign/setFieldToSession'}
      />,
    ];
  };

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
    hansContainer: getTabs(LanguageConfig.simplifiedChinese),
  };

  return (
    <>
      <ContentSections
        fieldsError={error}
        languageTabContent={languageTabConfig}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultImage}
        activeSection={activeSection}
      />
      <ContinueWithBackButtons
        continueAction={() => {
          isChecking({ isBack: false });
        }}
        backAction={() => {
          isChecking({ isBack: true });
        }}
      />
    </>
  );
}

export default CreateCampaignStepTwo;
