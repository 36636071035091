import React, { Component } from 'react';
import { connect } from 'react-redux';
import './GenerateCouponView.scss';
import { createAction } from '../../../utils';
import {
  COUPON_TEMPLATE_TYPE,
  LanguageConfig,
} from '../../../config/CustomEnums';
import { withRouter } from 'react-router-dom';
import { DropDownWithDefaultSelect } from '../../base/ActionsDropdown';
import CustomFileUploader from '../../base/CustomFileUploader';
import { CouponURLFragments } from '../CouponUrlConfig';
import BasePrompt from '../../base/prompt/BasePrompt';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import { ImportResource } from '../../../models/DownloadImportModel';

@connect(({ createCoupon, uploadFiles, downloadAndImport }) => ({
  ...createCoupon.couponTemplate,
  generateCouponStatus: createCoupon.generateCouponStatus,
  importedFile: uploadFiles.importedFile,
  importError: downloadAndImport.error,
  uploading: uploadFiles.uploading,
}))
class GenerateCouponView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedStore:
        props.shouldBeUsedInStores && props.selectedStores?.length > 0
          ? props.selectedStores[0].storeName
          : null,
      quantity: 0,
      errorMessage: '',
      generateButtonClicked: false,
    };
  }

  componentDidUpdate(prev) {
    if (
      prev.importError !== this.props.importError &&
      Object.keys(this.props.importError || {}).length <= 0
    ) {
      this.props.history.push({
        pathname: `/coupons/${this.props.couponTemplate}`,
        hash: CouponURLFragments.list,
      });

      this.modalOnHide();
    }
  }

  relateBrandView = () => {
    if (this.props.selectedBrand) {
      return (
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Related brand</div>
          </div>
          <div className="second-section-content">
            {this.props.selectedBrand.name}
          </div>
        </div>
      );
    }
  };

  applicableStoreView = () => {
    if (
      this.props.shouldBeUsedInStores &&
      this.props.selectedStores &&
      this.props.selectedStores.length > 0
    ) {
      const storeList = this.props.selectedStores.map((store) => {
        return {
          name: store.storeName,
          action: () => this.setState({ currentSelectedStore: store }),
        };
      });
      return (
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Applicable stores</div>
          </div>
          <DropDownWithDefaultSelect actions={storeList} />
        </div>
      );
    }
  };

  modalOnHide = () => {
    this.props.dispatch(
      createAction('createCoupon/updateCouponTemplate')({
        generateCoupon: false,
        notSaveToSession: true,
      }),
    );
  };

  generateCouponButtonClicked = () => {
    const selectedStore = this.state.currentSelectedStore?.storePK;
    switch (this.props.couponFormat) {
      case COUPON_TEMPLATE_TYPE.storeCode:
        if (!this.state.quantity) {
          this.setState({
            errorMessage: 'This field is required.',
          });
          break;
        }

        this.props.dispatch(
          createAction('createCoupon/generateCoupon')({
            quantity: this.state.quantity,
            store: selectedStore,
            redirectAction: (couponSetID) => {
              if (
                this.props.history.location.pathname.includes('edit') ||
                this.props.toSingleCouponList
              ) {
                this.props.history.push({
                  pathname: `/coupons/${couponSetID}`,
                  hash: CouponURLFragments.list,
                });
              }
            },
          }),
        );

        this.modalOnHide();
        break;
      default:
        this.props.dispatch(
          createAction('downloadAndImport/importFile')({
            from:
              this.props.couponFormat === COUPON_TEMPLATE_TYPE.couponCode || this.props.couponFormat === COUPON_TEMPLATE_TYPE.couponMTRCode
                ? ImportResource.generateCodeCoupon
                : this.props.couponFormat === COUPON_TEMPLATE_TYPE.couponQRCode
                ? ImportResource.generateQRCodeCoupon
                : ImportResource.generateImageCoupon,
            file: this.props.importedFile,
            related: `${this.props.couponTemplate}|${selectedStore || ''}`,
          }),
        );
        break;
    }

    // this.props.dispatch(
    //   createAction('createCoupon/updateCouponTemplate')({
    //     generateCoupon: false,
    //     notSaveToSession: true,
    //   }),
    // );
  };

  otherBody = () => {
    return (
      <>
        {this.relateBrandView()}
        {this.applicableStoreView()}
        {this.props.couponFormat === COUPON_TEMPLATE_TYPE.storeCode ? (
          <div className="second-section quantity_section">
            <div className="second-title-section">
              <div className="second-section-title">Quantity</div>
            </div>
            <div className="d-flex">
              <input
                type="text"
                className={`day-input-fields ${
                  this.state.errorMessage ? 'error-field' : ''
                }`}
                onChange={(e) => this.setState({ quantity: e.target.value })}
              />
              <label className="days-label">numbers of coupons</label>
            </div>
            <div className="error-field-message-style">
              {this.state.errorMessage}
            </div>
          </div>
        ) : this.props.couponFormat === COUPON_TEMPLATE_TYPE.couponCodeImage ? (
          <div className="d-flex flex-column">
            <CustomTitleLabel title="Import coupon code images in zip." />
            <label className="create-message-suggest">
              Please select or drag and drop the .zip files which you want to
              import. Max 500Mb for the zip file size per Suggested Image Size:
              945 px * 1800 px At least : 315 px * 557 px(Less than 2MB, support
              JPG, PNG and GIF only)batch
            </label>
            <CustomFileUploader
              title="Select files"
              accept=".zip"
              showFileName={true}
            />
          </div>
        ) : (
          <div class="d-flex flex-column">
            <CustomTitleLabel title="Import code in .csv or Import QR code in .csv." />
            <label className="create-message-suggest">
              Please select or drag and drop the .csv files which you want to
              import.
            </label>
            <CustomFileUploader
              title="Select files"
              accept=".csv"
              showFileName={true}
            />
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <BasePrompt
        show={this.props.generateCoupon}
        closeAction={() => {
          this.modalOnHide();
          this.props.dispatch({
            type: 'uploadFiles/updateState',
            payload: { importedFile: null },
          });
        }}
        rightButton={{
          text: 'Generate Coupons',
          action: this.generateCouponButtonClicked,
          // disabled:
          //   this.props.couponFormat === COUPON_TEMPLATE_TYPE.couponCodeImage &&
          //   this.props.uploading,
          // custom: <CustomFileUploader title="Import .zip" accept=".zip" />,
        }}
        title={'Generate Single Coupons'}
        description={`Generate Single Coupons by using the coupon set "${
          this.props.translations[LanguageConfig.english].name
            ? this.props.translations[LanguageConfig.english].name
            : ''
        }"`}
        otherBody={this.otherBody}
      />
    );
  }
}

export default withRouter(GenerateCouponView);
