import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image } from 'react-bootstrap';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import { firstError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { useCompare } from '../../utils';
import { useHistory } from 'react-router-dom';
import RewardSection from './RewardSection';
import CustomContentFilterDropDown from '../base/CustomContentFilterDropDown';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import { EarningRuleRewardType } from '../../config/CustomEnums';


function RewardExtraPointSection(props) {
  const defaultValue = props.defaultValue || {};

  const { categoryList } = useSelector((state) => ({
    categoryList: state.storeCategoryList.categoryList,
  }));

  const [quantity, setQuantity] = useState(defaultValue.quantity);
  const [carbons, setCarbons] = useState(defaultValue.carbons || {});

  const [content, setContent] = useState([]);

  const dispatch = useDispatch();

  const vals = {
    carbons,
    quantity,
  };
  const hasValchanged = useCompare(vals);
  useEffect(() => {
    if (hasValchanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => { });
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.coupons.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  useEffect(() => {
    const rewardsSubcategories = defaultValue.rewards
      ? Object.keys(defaultValue.rewards)
      : [];

    const initContent = [];

    for (let i = 0; i < categoryList.length; i++) {
      const category = categoryList[i];

      for (let f = 0; f < category.subcategories.length; f++) {
        const subcategory = category.subcategories[f];
        if (rewardsSubcategories.includes(subcategory.pk.toString())) {
          initContent.push({
            title: subcategory.name,
            data: subcategory,
            componment: (
              <RewardSection
                onSubmit={onSubmit}
                onSubmitAction={(data) => {
                  onSubmitAction(data);
                }}
                defaultValue={
                  defaultValue.rewards
                    ? defaultValue.rewards[subcategory.pk]
                    : {}
                }
                hideTitle
                hideRewardType
                hideCarbonInput
                customPointsTitle="Extra CW Points for users"
                defaultRewardType={EarningRuleRewardType.points}
                groupKey={subcategory.pk}
              />
            ),
          });
        }
      }
    }

    setContent([...content, ...initContent]);
  }, []);

  const getData = () => {
    let data = {};
    for (let i = 0; i < categoryList.length; i++) {
      const category = categoryList[i];
      data[category.name] = category.subcategories?.filter(
        (item) => content.filter((val) => val.data.pk === item.pk).length <= 0,
      );
    }

    return data;
  };

  return (
    <>
      <label className="create-section-title">REWARDS</label>

      {content.map((item, index) => {
        return (
          <div key={`reward_content_${index}`}>
            <label className="create-section-title-without-upper">
              Item {index + 1}: {item.title}
            </label>
            <div
              className={`reward-content-area ${errorFields.includes('rewards') ? 'error-field' : ''
                }`}
            >
              <div className="display-flex-area">
                <div className="reward-content">
                  <RewardSection
                    onSubmit={onSubmit}
                    onSubmitAction={(data) => {
                      onSubmitAction(data);
                    }}
                    defaultValue={
                      defaultValue.rewards
                        ? defaultValue.rewards[item.data.pk]
                        : {}
                    }
                    hideTitle
                    hideRewardType
                    hideCarbonInput
                    groupKey={item.data.pk}
                    customPointsTitle="Extra CW Points for users"
                    defaultRewardType={EarningRuleRewardType.points}
                  />
                </div>
                <button
                  className="reset-button content-delete-button-area"
                  onClick={() => {
                    const afterRemoveContent = content.filter(
                      (val) => val.title !== item.title,
                    );
                    setContent(afterRemoveContent);

                    dispatch({
                      type: 'createEarningRules/removeVals',
                      payload: { rewardsGroupKey: item.data.pk },
                    });
                  }}
                >
                  <Image src={DeleteIcon} className="content-delete-button" />
                </button>
              </div>
            </div>
          </div>
        );
      })}

      <ErrorFieldMessage
        id={'rewards'}
        error={errorFields.includes('rewards')}
        message={EarningRuleErrorHandleField['rewards'].message}
      />

      <CustomContentFilterDropDown
        title="Add requirement"
        data={getData()}
        content={content}
        addContentAction={(section) => {
          setContent([
            ...content,
            {
              title: section.name,
              data: section,
            },
          ]);
        }}
      />
    </>
  );
}

export default RewardExtraPointSection;
