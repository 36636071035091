import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { createAction } from '../../utils';
function CampaignSelectDropdown(props) {
  const title = props.title || null;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const { campaignList } = useSelector((state) => ({
    campaignList: state.campaignList.allCampaignList,
  }));

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      createAction('campaignList/getCampaignList')({
        isSelectorLoad: true,
        isExpired: false,
        isPublished: true,
        ...(props.defaultFilter || {}),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      setValue={onSelectChange}
      source={campaignList}
      sourceShowId={props.sourceShowId || false}
      showMultiLine={props.showMultiLine || false}
      defaultValue={defaultValue}
      defaultFilter={props.defaultFilter || {}}
      loadMoreAction={'campaignList/getCampaignList'}
      filterAction={'campaignList/getCampaignList'}
      showRemoveButton={props.showRemoveButton}
      onRemoveButtonClick={props.onRemoveButtonClick}
      errors={props.errors}
      errorName={props.errorName}
      errorMessage={props.errorMessage}
    />
  );
}

export default CampaignSelectDropdown;
