import {
  getPagedNewsFeeds,
  getOneObject,
  deleteItems,
  createOrUpdate,
  publishOrUnpublishNewsFeed,
  deleteNewsFeedsInCommunity,
} from '../services/NewsFeedAPIHelper';
import {
  convertNumberToCursor,
  convertPKToId,
  convertCursorToNumber,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeUndefinedFieldFromDict,
  removeFromSessionStorage,
  getFileNameFromUrl,
  addDomainToImage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  SavedStatus,
  CheckStatus,
  LanguageConfig,
  PublishTagType,
} from '../config/CustomEnums';
import { ErrorHandleFields } from '../containers/engagement/newsFeed/ErrorHandleFields';
import { getDisplayDate } from '../utils/TimeFormatUtil';

const sessionKey = 'tempNewsFeed';

const DISPLAY_IN_TYPE = {
  "COMMUNITY": "community",
  "GREEN_INFO_HUB": "green info hub",
  "BOTH": "both",
}

const parseSingleTranslation = (node) => {
  if (!node) {
    return {};
  };
  const result = {
    id: node.pk || null,
    name: node.name || "",
    description: node.description || "",
    tag: node.tag?.split(',') || [],
    coverPhoto: addDomainToImage(node.coverPhoto) || null,
    detailPhoto1: addDomainToImage(node.detailPhoto1) || null,
    detailPhoto2: addDomainToImage(node.detailPhoto2) || null,
    detailPhoto3: addDomainToImage(node.detailPhoto3) || null,
    detailPhoto4: addDomainToImage(node.detailPhoto4) || null,
  };

  return result;
}


const parseObject = (object) => {
  if (!object) {
    return {};
  }

  const translationsEdges = object?.translations?.edges;
  let translations = {};
  if (translationsEdges?.length > 0) {
    translationsEdges.forEach((item) => {
      translations[item.node?.language] = parseSingleTranslation(item.node);
    });
  };
  translations[LanguageConfig.english] = parseSingleTranslation(object);

  return {
    ...object,
    communities: object?.communities?.edges?.map(item => item.node) || [],
    displayInShown: DISPLAY_IN_TYPE?.[object?.displayIn] || "",
    displayLastModifiedDate: getDisplayDate(object?.lastModifiedDate),
    status: object?.isPublished ? PublishTagType.published : PublishTagType.unPublished,
    translations: translations,
  };
};

const getInitTranslation = () => ({
  name: "",
  description: "",
  tag: [],
  coverPhoto: null,
  detailPhoto1: null,
  detailPhoto2: null,
  detailPhoto3: null,
  detailPhoto4: null,
})

const getInitState = () => {
  return {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Name', fieldName: 'name' },
      { displayName: 'Cover Photo', fieldName: 'coverPhoto' },
      { displayName: 'Display In', fieldName: 'displayInShown' },
      { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
      { displayName: 'Status', fieldName: 'status' },
    ],
    pagedList: [],
    allList: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    totalPage: 0,
    totalCount: 0,
    currentPage: 0,
    checkedList: [],
    oneObject: {
      translations: {
        [LanguageConfig.english]: getInitTranslation(),
        [LanguageConfig.simplifiedChinese]: getInitTranslation(),
        [LanguageConfig.traditionalChinese]: getInitTranslation(),
      },
    },
    checked: CheckStatus.initOrNotChecked,
    errorFields: [],
    saved: SavedStatus.init,
    formChanged: false,
    objectLoading: true,
  };
};

const assembleSingleTranslation = (data = {}, language, removeId) => {
  let idField = {};
  let languageField = {};
  if (!removeId && data?.id) {
    idField = { id: data.id };
  };
  if (language !== LanguageConfig.english) {
    languageField = { language: language };
  };
  const result = {
    ...idField,
    ...languageField,
    name: data.name || '',
    description: data.description || '',
    tag: data.tag?.filter(item => !!item?.length)?.join(',') || null,
    coverPhoto: data.coverPhoto ? getFileNameFromUrl(data?.coverPhoto) : null,
    detailPhoto1: data.detailPhoto1 ? getFileNameFromUrl(data?.detailPhoto1) : null,
    detailPhoto2: data.detailPhoto2 ? getFileNameFromUrl(data?.detailPhoto2) : null,
    detailPhoto3: data.detailPhoto3 ? getFileNameFromUrl(data?.detailPhoto3) : null,
    detailPhoto4: data.detailPhoto4 ? getFileNameFromUrl(data?.detailPhoto4) : null,
  }

  return result;
};

const assembleTranslations = (translations, removeId) => {
  const traditionalChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.traditionalChinese],
    LanguageConfig.traditionalChinese,
    removeId
  );
  const simplifiedChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.simplifiedChinese],
    LanguageConfig.simplifiedChinese,
    removeId
  );
  return [
    traditionalChinese,
    simplifiedChinese,
  ]
}

const assembleNewsFeed = (oneObject, removeId) => {
  const enData = oneObject.translations?.[LanguageConfig.english] || {};
  const enTranslation = assembleSingleTranslation(enData, LanguageConfig.english, removeId);
  const translations = assembleTranslations(oneObject.translations, removeId);

  return {
    ...enTranslation,
    displayIn: oneObject.displayIn,
    communities: oneObject.communities?.map(item => item.pk) || null,
    translations,
  };
}


export default {
  namespace: 'newsFeedModel',
  state: getInitState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateAllList(state, { payload }) {
      const { page, pagedList } = payload;

      return {
        ...state,
        allList:
          page > 1 ? [...state.allList, ...pagedList] : pagedList,
      };
    },
    loadDataFromCookie(state, { payload }) {
      const sessionObject = getObjectFromSessionStorage(sessionKey) || {};
      const tempObject = payload?.data || sessionObject;

      saveToSessionStorage(sessionKey, tempObject);
      return {
        ...state,
        oneObject: tempObject,
        objectLoading: false,
      };
    },
    updateVals(state, { payload }) {
      const changedData = {
        ...state.oneObject,
        ...removeUndefinedFieldFromDict(payload),
      };

      saveToSessionStorage(sessionKey, changedData);
      return {
        ...state,
        oneObject: changedData,
        formChanged: true,
      };
    },
    updateTranslations(state, { payload }) {
      const object = state.oneObject;
      const detail = {
        ...object,
        translations: {
          ...object.translations,
          [payload.language]: {
            ...object.translations?.[payload.language],
            ...payload,
          }
        }
      };

      saveToSessionStorage(sessionKey, detail);
      return {
        ...state,
        oneObject: detail,
        formChanged: true,
      }
    },
    checkValsValid(state, { payload }) {
      const object = state.oneObject;
      const errorFields = [];
      let checked = CheckStatus.initOrNotChecked;

      if (!object?.translations?.[LanguageConfig.english]?.name) {
        errorFields.push(ErrorHandleFields.name.name);
      }

      if (!object?.translations?.[LanguageConfig.english]?.description) {
        errorFields.push(ErrorHandleFields.description.name);
      }

      if (!object?.translations?.[LanguageConfig.english]?.coverPhoto) {
        errorFields.push(ErrorHandleFields.coverPhoto.name);
      }

      if (!object?.displayIn) {
        errorFields.push(ErrorHandleFields.displayIn.name);
      }

      [
        LanguageConfig.english,
        LanguageConfig.simplifiedChinese,
        LanguageConfig.traditionalChinese
      ]?.forEach(language => {
        const tag = object?.translations?.[language]?.tag || [];
        const tagInvalid = tag?.filter(item => item?.length > 50)?.length
        if (tag?.length > 10 || tagInvalid) {
          errorFields.push(ErrorHandleFields.tag.name);
        }
      })

      if (errorFields.length > 0) {
        checked = CheckStatus.checkedWithFail;
      } else {
        checked = CheckStatus.checkedWithSuccess;
      }

      return {
        ...state,
        checked,
        errorFields,
      };
    },
    removeFromCookie(state, { payload }) {
      removeFromSessionStorage(sessionKey);
      return { ...state, formChanged: false };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },
  effects: {
    getList: [
      function* ({ payload }, { put, select }) {
        const page = payload.page || 1;
        const afterCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedNewsFeeds, { ...payload, afterCursor }];
        function* onSuccess(data) {
          const listData = data?.newsFeeds || {};
          const pageInfo = listData.pageInfo || {};
          const currentLastCursor = pageInfo.endCursor;
          const totalCount = listData.totalCount;
          const list = listData?.edges?.map((item) => parseObject(item.node));

          yield put({
            type: 'updateState',
            payload: {
              pagedList: list,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
          yield put({
            type: 'updateAllList',
            payload: {
              page,
              pagedList: list,
            }
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getObject: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'updateState', payload: { objectLoading: true } });
        if (!payload?.id) {
          yield put({ type: 'loadDataFromCookie' });
          return;
        }
        const serviceArgs = [
          getOneObject,
          convertPKToId('NewsFeedNode', payload.id),
        ];
        function* onSuccess(data) {
          yield put({
            type: 'loadDataFromCookie',
            payload: {
              data: parseObject(data.newsFeed),
              noCookie: true,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { select, put, all }) {
        const id = payload?.id;
        const { checkedList } = yield select((state) => ({
          checkedList: state.newsFeedModel.checkedList,
        }));
        const pks = id || checkedList.map((item) => item.pk);
        const serviceArgs = [deleteItems, pks];
        const afterAction = payload?.afterAction || (() => { });
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { checkedList: [] },
          });
          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const oneObject = yield select(
          (state) => state.newsFeedModel.oneObject,
        );
        const removeId = payload?.removeId || false;
        const afterAction = payload?.afterAction || (() => { });

        const data = assembleNewsFeed(oneObject, removeId);

        if (!removeId && oneObject.pk) {
          data.id = oneObject.pk;
        }

        const serviceArgs = [createOrUpdate, data];
        function* onSuccess(data) {
          if (
            ('createNewsFeed' in data && data.createNewsFeed.errors) ||
            ('updateNewsFeed' in data && data.updateNewsFeed.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                saved: SavedStatus.savedWithFail,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                formChanged: false,
                saved: SavedStatus.savedWithSuccess,
                checked: CheckStatus.initOrNotChecked,
              },
            });

            removeFromSessionStorage(sessionKey);
          }

          afterAction();
        }

        function* onFail(data) {
          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
              saved: SavedStatus.savedWithFail,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail, onFail);
      },
      { type: 'takeLatest' },
    ],

    duplicate: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'clearData' });

        const newsFeedId = payload.data?.pk;
        const afterAction = payload.afterAction || (() => { });
        yield put.resolve({
          type: 'getObject',
          payload: { id: newsFeedId },
        });
        const translations = yield select(
          (state) => state.newsFeedModel.oneObject.translations,
        );

        const englishName = translations[LanguageConfig.english].name;
        const copyName = `Copy of ${englishName}`;
        yield put.resolve({
          type: 'updateTranslations',
          payload: { name: copyName, language: LanguageConfig.english },
        });
        yield put.resolve({ type: 'createOrUpdate', payload: { removeId: true } });
        afterAction();
      },
      { type: 'takeLatest' },
    ],

    publishOrUnpublishNewsFeed: [
      function* ({ payload }, { select, put }) {
        const afterAction = payload.afterAction || (() => { });

        const serviceArgs = [
          publishOrUnpublishNewsFeed,
          {
            id: payload.id,
            isPublish: payload.isPublish,
          },
        ];
        function* onSuccess(data) {
          afterAction();
        }

        function* onError(response) {
          afterAction();
        }

        function* onArgumentsError(response) {
          afterAction();
        }

        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
    ],

    deleteNewsFeedsInCommunity: [
      function* ({ payload }, { select, put, all }) {
        const communityId = payload?.communityId;
        const id = payload?.id;
        const afterAction = payload?.afterAction || (() => { });
        const { checkedList } = yield select((state) => ({
          checkedList: state.newsFeedModel.checkedList,
        }));
        const pks = id || checkedList.map((item) => item.pk);

        const serviceArgs = [deleteNewsFeedsInCommunity, { communityId, ids: pks }];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { checkedList: [] },
          });
          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ]
  },
};
