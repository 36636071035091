import React, { useState, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LanguageConfig } from '../../config/CustomEnums';

const LanguageList = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

const showdown = require('showdown');

const converter = new showdown.Converter();

converter.setOption('simpleLineBreaks', true);

const BaseMutipleLanguageContentCard = ({ fields, data }) => {
  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(800);

  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, []);

  const fieldValueText = (fieldName, value, index) => {
    return (
      <td
        key={`fieldValueText-${fieldName}-${index}`}
        style={{
          maxWidth: `${(tableWidth - 161) / 3}px`,
          whiteSpace: 'pre-wrap',
          lineBreak: 'anywhere',
        }}
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value) : '-',
        }}
      ></td>
    );
  };

  const sectionValues = (field) => {
    return (
      <>
        {LanguageList.map((language, index) => {
          return fieldValueText(
            field?.displayName,
            data?.[field?.name]?.[language],
            index,
          );
        })}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">CONTENT</label>
      <PerfectScrollbar>
        <table ref={tableRef} className="campaign-category-detail-table">
          <thead>
            <th></th>
            <th>English</th>
            <th>繁體</th>
            <th>简体</th>
          </thead>
          <tbody>
            {fields.map((field) => (
              <tr>
                <td>{field.displayName}</td>
                {sectionValues(field)}
              </tr>
            ))}
          </tbody>
        </table>
      </PerfectScrollbar>
    </>
  );
};

export default BaseMutipleLanguageContentCard;
