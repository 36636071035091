export const CampaignType = {
  allTypes: 'all',
  generalMessageCampaign: 'GENERAL_MESSAGE_CAMPAIGN',
  earningRulesCampaign: 'EARNING_CAMPAIGN',
  couponsCampaign: 'COUPON_CAMPAIGN',
};

export const StepStatus = {
  error: 'error',
  processing: 'processing',
  finished: 'finished',
  waiting: 'waiting',
};

export const LanguageConfig = {
  english: 'en',
  simplifiedChinese: 'zh-Hans',
  traditionalChinese: 'zh-Hant',
};

export const LanguageUpperConfig = {
  english: 'EN',
  simplifiedChinese: 'ZH_HANS',
  traditionalChinese: 'ZH_HANT',
};

export const EarningRuleType = {
  allTypes: 'all',
  generalPurchase: 'GENERAL_PURCHASE',
  memberReferral: 'MEMBER_REFERRAL',
  birthday: 'BIRTHDAY',
  qrCodeScanning: 'QR_CODE_SCANNING',
  gpsCheckIn: 'GPS_CHECK_IN',
  newMember: 'NEW_MEMBER',
  monthlyAchievement: 'MONTHLY',
  weeklyAchievement: 'WEEKLY',

  habit: 'HABIT',
  photoTaking: 'PHOTO_TAKING',
  waterFilling: 'WATER_FILLING',
  activeTracking: 'ACTIVE_TRACKING',

  carbonImprove: 'CARBON_IMPROVE',
  firstTry: 'FIRST_TRY',
  activationCode: 'ACTIVATION_CODE',
  barcode: "BARCODE",
  qrCodeRecyclingExtraRewards: "QR_CODE_RECYCLING_EXTRA_REWARDS",
};

export const EarningRuleTypeKey = {
  All: 'allTypes',
  GENERAL_PURCHASE: 'generalPurchase',
  MEMBER_REFERRAL: 'memberReferral',
  BIRTHDAY: 'birthday',
  QR_CODE_SCANNING: 'qrCodeScanning',
  GPS_CHECK_IN: 'gpsCheckIn',
  NEW_MEMBER: 'newMember',
  MONTHLY_ACHIEVEMENT: 'monthlyAchievement',
  WEEKLY_ACHIEVEMENT: 'weeklyAchievement',

  HABIT: 'habit',
  PHOTO_TAKING: 'photoTaking',
  WATER_FILLING: 'waterFilling',
  ACTIVE_TRACKING: 'activeTracking',
  CARBON_IMPROVE: 'carbonImprove',
  FIRST_TRY: 'firstTry',
  ACTIVATION_CODE: 'activationCode',
};

export const LanguageTabTransLation = {
  english: 'English',
  simplifiedChinese: '简体',
  traditionalChinese: '繁体',
};

export const LanguageTransLation = {
  en: 'English',
  'zh-Hans': '简体',
  'zh-Hant': '繁体',
};

export const AmOrPm = {
  am: 'am',
  pm: 'pm',
};

export const SavingStatus = {
  onSaving: 'onSaving',
  onFinished: 'onFinished',
};

export const APIStatus = {
  success: 'success',
  calling: 'calling',
  failed: 'failed',
  none: 'none',
};

export const MessageChannel = {
  push: 'Push',
  email: 'Email',
  sms: 'SMS',
  web: 'Web',
  inbox: 'Inbox',
  informationBar: 'Information Bar'
};

export const BrandStatus = {
  Yes: 'Yes',
  No: 'No',
}

export const CheckStatus = {
  initOrNotChecked: -1,
  checkedWithFail: 0,
  checkedWithSuccess: 1,
};

export const SavedStatus = {
  init: -1,
  savedWithFail: 0,
  savedWithSuccess: 1,
};

export const EarningRuleRewardType = {
  coupons: 'COUPON',
  points: 'POINTS',
  carbon: 'CARBON',
};

export const StatusTag = {
  active: 'Active',
  activeRequired: 'Active(Acquired)',
  deactive: 'Deactive',
  inactive: 'Inactive',
};

export const PublishTagType = {
  published: 'Published',
  unPublished: 'Unpublished',
  expired: 'Expired',
};

export const DownloadTag = {
  completed: 'Exported',
  canceled: 'Canceled',
  downloading: 'Exporting',
  importing: 'Importing',
  importCompleted: 'Imported',
  error: 'Error',
};

export const MessageTag = {
  scheduled: 'Scheduled',
  saved: 'Saved',
  sent: 'Sent',
  sending: 'Sending',
  error: 'Failed',
};

export const ConditionTag = {
  EQUAL_TO: 'Equal to',
  NOT_EQUAL_TO: 'Not equal to',
  IS_EMPTY: 'Is empty',
  IS_NOT_EMPTY: 'Is not empty',
  BETWEEN: 'Between',
  LESS_THAN: 'Less than',
  GREATER_THAN: 'greater than',
  IN: 'In',
};

export const DaysTag = {
  '1_DAY': '1 day',
  '7_DAYS': '7 days',
  '1_MONTH': '1 month',
  '3_MONTHS': '3 months',
  '6_MONTHS': '6 months',
  '1_YEAR': '1 year',
};

const domain =
  process.env.REACT_APP_IMAGE;

export const ImageDomain = `${domain}/media/`;

export const GREEN_SHOPPING_CATEGORY_ID = parseInt(process.env.GREEN_SHOPPING_CATEGORY_ID) || null;
export const PLANT_MILK_CATEGORY_ID = parseInt(process.env.PLANT_MILK_CATEGORY_ID) || null;
export const PAPER_SUBCATEGORY_ID = parseInt(process.env.PAPER_SUBCATEGORY_ID) || 23;
export const FOOD_WASTE_SUBCATEGORY_ID = parseInt(process.env.FOOD_WASTE_SUBCATEGORY_ID) || null;

export const GenderOptions = [
  { name: 'Male', value: 'MALE' },
  { name: 'Female', value: 'FEMALE' },
  { name: 'Others', value: 'NOT_DISCLOSED' },
  { name: 'Prefer not to say', value: 'PREFER_NOT_TO_SAY' },
];

export const MonthOptions = [
  { name: 'January', value: '1' },
  { name: 'February', value: '2' },
  { name: 'March', value: '3' },
  { name: 'April', value: '4' },
  { name: 'May', value: '5' },
  { name: 'June', value: '6' },
  { name: 'July', value: '7' },
  { name: 'August', value: '8' },
  { name: 'September', value: '9' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

export const CountryCodeOptions = [
  { name: '+852', value: 852 },
  { name: '+853', value: 853 },
  { name: '+86', value: 86 },
];

export const BANNER_CONTENT_TYPE = {
  ALL_TYPE: 'all',
  CAMPAIGN: 'CAMPAIGN',
  PRODUCT: 'PRODUCT',
};

export const POINT_RECORD_TYPES = [
  { key: 'TYPE_POINT_ADD', name: 'Add Points' },
  { key: 'TYPE_POINT_REMOVE', name: 'Remove Points' },
];

export const COUPON_TEMPLATE_TYPE = {
  storeCode: 'STORE_CODE',
  couponCode: 'COUPON_CODE',
  couponQRCode: 'COUPON_QR_CODE',
  couponCodeImage: 'COUPON_CODE_IMAGE',
  couponMTRCode: 'COUPON_MTR_CODE',
};

export const COUPON_TEMPLATE_TYPE_NAME = {
  STORE_CODE: 'Store code',
  COUPON_CODE: 'Coupon code',
  COUPON_QR_CODE: 'Coupon QR code',
  COUPON_CODE_IMAGE: 'Coupon code image',
};

export const DELETE_RELATED_SECTIONS = {
  CUSTOMER: [
    'AppInstallation',
    'CampaignParticipationRecord',
    'Coupon',
    'CouponTransaction',
    'PointTransaction',
    'Transaction',
  ],
  SEGMENT: ['Campaign'],
  CUSTOMER_GROUP: ['Campaign', 'ParticipationCustomer'],
  CAMPAIGN: [
    'Message',
    'CampaignParticipationRecord',
    'CouponTransaction',
    'PointsTransaction',
    'Segment Campaign Characteristic',
    'Banner',
  ],
  CAMPAIGN_CATEGORY: ['Campaign'],
  MESSAGE: ['InboxMessage'],
  EARNING_RULE: ['Campaign'],
  COUPON_TEMPLATE: ['Campaign', 'Coupon', 'EarningRule'],
  COUPON: ['CouponTransaction'],
  BRAND: ['Campaign', 'CouponTemplate', 'Store'],
  STORE: ['CampaignParticipationRecord', 'Coupon', 'Transaction'],
  STORE_CATEGORY: ['StoreSubcategory'],
  TRANSACTION: [
    'CampaignParticipationRecord',
    'PurchasedItem',
    'Coupon transaction',
    'Point transaction',
  ],
  POINT_RECORD: [],
  COUPON_RECORD: [],
  ADMIN_GROUP: ['ParticipationAdmin'],
  ADMIN: ['CouponTransaction', 'ImportJob', 'ExportJob'],
  APPVERSION: ['App Version'],
  BANNER: [],
  AFC_DATA_RECORD: [],
  COMMUNITY: ["Customers"],
  NEWS_FEED: [],
  SPLASH_AD: [],
  REWARD_CODE: [],
};

export const ActivityTypes = {
  RECYCLING: 'RECYCLING',
  GREEN_DINING: 'GREEN_DINING',
  WATER_FILLING: 'WATER_FILLING',
};

export const ReportTypes = {
  InaccurateLocation: 'LOCATION',
  InaccurateOpeningHours: 'HOURS',
  InaccurateTag: 'TAG',
  CommonRecyclable: 'COMMON',
  Others: 'OTHERS',
  Close: 'CLOSE',
  Available: 'AVAILABLE',
  MissingLocation: 'MISSING',
  MissingPhotos: 'MISSING_PHOTOS',
  ShoppingCategory: 'SHOPPING_CATEGORY',
  ShopClose: 'SHOP_CLOSE',
};

export const TokenErrors = {
  Expiry: 'Signature has expired',
  DecodingError: 'Error decoding signature',
  RefreshTokenInvalid: "Invalid refresh token",
  RefreshTokenExpired: "Refresh token is expired",
  NoPermission: "You do not have permission to perform this action"

};

export const CategoryType = {
  EARNING_CAMPAIGN: 'EARNING_CAMPAIGN',
  REWARD_CAMPAIGN: 'REWARD_CAMPAIGN',
  BOTH: 'BOTH',
};

export const AfcDataStatus = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAIL'
}

export const RejectReasons = [
  {
    id: 1,
    name: "[Recycling] Oops! Your recycling record has been rejected (Carton / Beverage Carton) 😟"
  },
  {
    id: 2,
    name: "💡[Recycling] Recycling reminder: Eligible recycling items for CW points reward"
  },
  {
    id: 3,
    name: "[Recycling] Oops! Your recycling record has been rejected (Incorrect information)"
  },
  {
    id: 4,
    name: "[Recycling] Oops! Your recycling record has been rejected (Bulk submission)"
  },
  {
    id: 5,
    name: "[Recycling] Oops! Your recycling record has been rejected (Unable to identify your recyclables)"
  },
  {
    id: 6,
    name: "💡[Recycling] Recycling reminder: Photos should be taken in front of the recycling bins 📸"
  },
  {
    id: 7,
    name: "💡[Recycling] Recycling reminder: Recycling record can only be submitted once only"
  },
  {
    id: 8,
    name: "💡[Recycling] Recycling reminder: Remember to empty and clean your recyclable"
  },
  {
    id: 9,
    name: "💡[Recycling] Recycling reminder: Do not submit recycling record with screenshots"
  },
  {
    id: 10,
    name: "❗️[Recycling] Reminder: Duplicated submissions are NOT allowed"
  },
  {
    id: 11,
    name: "[Recycling] Oops! Your recycling record has been rejected (Takeaway Plastic Container)😟"
  },
  {
    id: 12,
    name: "💡[Recycling] Recycling reminder: Eligible recycling items of Takeaway Plastic Container"
  },
  // {
  //   id: 13,
  //   name: "💡[Barcode] New barcode submission reminder: Eligible recycling items for CW Points reward"
  // },
  // {
  //   id: 14,
  //   name: "[Barcode] Oops! Your new barcode submission has been rejected (Unable to identify the product)😟"
  // },
  {
    id: 15,
    name: "[Barcode] Oops! Your barcode submission has been rejected (Unable to match your recyclables)"
  },
  {
    id: 16,
    name: "Oops! Your receipt has been rejected (Incorrect merchant) 😟"
  },
  {
    id: 17,
    name: "Oops! Your receipt has been rejected (Ineligible merchant) 😟"
  },
  {
    id: 18,
    name: "💡Capture receipt reminder: Receipt can only be submitted once only"
  },
  {
    id: 19,
    name: "💡Capture receipt reminder: Eligible types of receipt for CW points reward"
  },
  {
    id: 20,
    name: "Oops! Your receipt has been rejected (Incorrect transaction amount) 😟"
  },
  {
    id: 21,
    name: "💡Capture receipt reminder: Submit your receipt within 7 days of transaction"
  },
  {
    id: 22,
    name: "💡[Octopus record] Capture receipt reminder: Each green action record can be submitted once only"
  },
]

export const CustomerMarkedLabelType = {
  "DUPLICATED_ITEMS": {
    name: "Duplicated items",
    color: "color_mustard",
  },
  "NOT_IN_FRONT_OF_BIN": {
    name: "Not in front of bin*",
    color: "color_mustard",
  },
  "EXCEPTION_NOT_IN_FRONT_OF_BIN": {
    name: "[Exception] Not in front of bin",
    color: "color_teal",
  },
  "WRONG_QUANTITY": {
    name: "Bulk - wrong quantity (< 5 Delta)*",
    color: "color_mustard",
  },
  "FRIENDS_SUBMISSION": {
    name: "!! Friends submission !!",
    color: "color_carbon",
  },
  "CARBON_DIRTY_RECYCLABLES": {
    name: "Dirty recyclables*",
    color: "color_mustard",
  },
};

export const CustomerMarkedLabelTypeList = [
  { name: "Duplicated items", value: "DUPLICATED_ITEMS" },
  { name: "Not in front of bin*", value: "NOT_IN_FRONT_OF_BIN" },
  { name: "[Exception] Not in front of bin", value: "EXCEPTION_NOT_IN_FRONT_OF_BIN" },
  { name: "Bulk - wrong quantity (< 5 Delta)*", value: "WRONG_QUANTITY" },
  { name: "!! Friends submission !!", value: "FRIENDS_SUBMISSION" },
  { name: "Dirty recyclables*", value: "CARBON_DIRTY_RECYCLABLES" },
];

export const MissionType = {
  allTypes: 'all',
  onboardingMission: 'ONBOARDING',
  campaignLinked: 'CAMPAIGN_LINKED',
  habitual: 'HABITUAL',
  group: 'GROUP',
};

export const GroupMissionItemType = {
  null: { name: "All", value: null },
  "4": { name: "Beverage Plastic Bottles", value: 4 },
  "5": { name: "Personal Care Plastic Bottles", value: 5 },
  "9": { name: "Aluminium Cans", value: 9 },
  "7": { name: "Beverage Carton", value: 7 },
  "10": { name: "Glass Bottles", value: 10 },
  "6": { name: "Takeaway Containers", value: 6 },
};

export const GroupMissionItemTypeList = [
  { name: "All", value: null },
  { name: "Beverage Plastic Bottles", value: 4 },
  { name: "Personal Care Plastic Bottles", value: 5 },
  { name: "Aluminium Cans", value: 9 },
  { name: "Beverage Carton", value: 7 },
  { name: "Glass Bottles", value: 10 },
  { name: "Takeaway Containers", value: 6 },
];

export const BarcodeType = {
  "all": 'all',
  "partner": 'partner',
  "nonPartner": 'nonPartner'
}

export const BarcodeProductTypeList = [
  { name: "Aluminium Cans", id: 9 },
  { name: "Beverage Carton", id: 7 },
  { name: "Beverage Plastic Bottles", id: 4 },
  { name: "Glass Bottles", id: 10 },
  { name: "Personal Care Plastic Bottles", id: 5 },
];

export const BarcodeVolumeConversion = {
  "ML": {
    name: "mL",
    value: "ML",
  },
  "L": {
    name: "L",
    value: "L",
  },
  "G": {
    name: "g",
    value: "G",
  },
  "KG": {
    name: "kg",
    value: "KG",
  },
  "PCS": {
    name: "pcs",
    value: "PCS",
  }
};

export const BarcodeValidateStatus = {
  "PENDING": {
    name: "Pending",
    value: "PENDING",
  },
  "WAITING_FOR_APPROVAL": {
    name: "Waiting for approval",
    value: "WAITING_FOR_APPROVAL",
  },
  "APPROVED": {
    name: "Approved",
    value: "APPROVED",
  },
  "REJECTED": {
    name: "Rejected",
    value: "REJECTED",
  },
};

export const NewBarcodeRejectReasons = [
  {
    id: 1,
    name: "💡[Barcode] New barcode submission reminder: Eligible recycling items for CW Points reward"
  },
  {
    id: 2,
    name: "[Barcode] Oops! Your new barcode submission has been rejected (Unable to identify the product)😟"
  },
];
export const PaymentMethod = {
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  OCTOPUS: 'OCTOPUS',
};

export const WhatToDoNextKey = {
  GETTING_STARTED: 'GETTING_STARTED',
  HOW_TO_EARN_CW_POINTS: 'HOW_TO_EARN_CW_POINTS',
  TAKE_PHOTO_RECYCLING: 'TAKE_PHOTO_RECYCLING',
  SCAN_BARCODE_RECYCLING: 'SCAN_BARCODE_RECYCLING',
  LINK_YOUR_OCTOPUS: 'LINK_YOUR_OCTOPUS',
  GUIDE_TO_SUBMIT_ACTION: 'GUIDE_TO_SUBMIT_ACTION',
  MTR_RIDE_PROGRESS: 'MTR_RIDE_PROGRESS',
  STEPS_PROGRESS: 'STEPS_PROGRESS',
  MY_COUPON: 'MY_COUPON',
  RECYCLING_TIPS: 'RECYCLING_TIPS',
  EXPLORE_GO_GREEN_MAP: 'EXPLORE_GO_GREEN_MAP',
};

export const WhatToDoNextKeyVisualSections = [WhatToDoNextKey.GETTING_STARTED, WhatToDoNextKey.HOW_TO_EARN_CW_POINTS]

export const BackgroundImageStyle = {
  YELLOW: 'YELLOW',
  CUSTOM: 'CUSTOM',
};

export const MissionCardStyleType = {
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
  SKY: 'SKY',
};

export const CommunityUniversity = [
  {
    id: parseInt(process.env.CITY_UNIVERSITY) || null,
    name: "City University",
    hall: [
      "Hall 1",
      "Hall 2",
      "Hall 3",
      "Hall 9",
    ],
  },
  {
    id: parseInt(process.env.BAPTIST_UNIVERSITY) || null,
    name: "Baptist University",
    hall: [
      "Tower A",
      "Tower B",
    ],
  },
  {
    id: parseInt(process.env.LINGNAN_UNIVERSITY) || null,
    name: "Lingnan University",
    hall: [
      "Hall A",
      "Hall B",
      "Hall C",
      "Hall D",
      "Hall E",
      "Hall F",
      "Hall G",
      "Hall H",
    ],
  },
  {
    id: parseInt(process.env.CHINESE_UNIVERSITY_OF_HONG_KONG) || null,
    name: "Chinese University of Hong Kong",
    hall: [
      "Chih Hsing Hall",
      "Chan Chun Ha Hostel",
      "Adam Schall Residence",
      "Student Hostel 2 (Low Block)",
      "International Block 4",
    ],
  },
  {
    id: parseInt(process.env.THE_UNIVERSITY_OF_HONG_KONG) || null,
    name: "The University of Hong Kong",
    hall: [
      "Simon KY Lee Hall",
      "University Hall",
    ],
  },
  {
    id: parseInt(process.env.THE_HONG_KONG_UNIVERSITY_OF_SCIENCE_AND_TECHNOLOGY) || null,
    name: "The Hong Kong University of Science and Technology",
    hall: [
      "Hall 1",
      "Hall 2",
      "Hall 3",
      "Hall 4",
      "Hall 5",
      "Hall 6",
      "Hall 7",
      "Hall 8",
      "Hall 9",
    ],
  },
  {
    id: parseInt(process.env.HONG_KONG_POLYTECHNIC_UNIVERSITY) || null,
    name: "Hong Kong Polytechnic University",
    hall: [
      "Homantin Hall",
    ],
  },
  {
    id: parseInt(process.env.EDUCATION_UNIVERSITY_OF_HONG_KONG) || null,
    name: "Education University of Hong Kong",
    hall: [
      "Grantham Hall",
    ],
  },
]

export const CommunityUniversityIdList = CommunityUniversity.map(item => item.id);

export const CampaignHomeSection = {
  earnSection: {
    name: 'Earn',
    value: 'EARN_SECTION',
  },
  redeemSection: {
    name: 'Redeem',
    value: 'REDEEM_SECTION',
  }
}
