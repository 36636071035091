import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

import ErrorFieldMessage from '../base/ErrorFieldMessage';

function CustomTitleWithInput({
  title,
  defaultValue,
  setValue,
  error = {},
  type = 'text',
  tips,
  focusChange = () => {},
  customInputClassName = '',
}) {
  // console.log('@@15: ', error);

  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} />
      {tips ? <label className="tips-message">{tips}</label> : null}
      <input
        type={type}
        onChange={({ target }) => setValue(target.value)}
        value={defaultValue || ''}
        className={`custom-markdown-area-title ${
          error.error ? 'error-field' : ''
        } custom-markdown-area-title-short ${
          type === 'number' ? 'custom-number-input-short' : ''
        } ${customInputClassName}`}
        onFocus={focusChange}
      />
      <ErrorFieldMessage id={error.id} error={error} message={error.message} />
    </div>
  );
}

export default CustomTitleWithInput;
