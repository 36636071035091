import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getAfcDataRecords = (afterCursor, search = {}) => {
  // let order = rank ? '-pk' : 'pk';
  console.log('@5', search)
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
 
  if (search.searchKey) {
    searchString += `, searchKey: "${search.searchKey}"`;
  }

  if (search.status) {
    searchString += `, statusIn: "${search.status}"`;
  }

  if (search.transaction_date) {
    searchString += `, transactionDateEqual: "${search.transaction_date}"`;
  }


  const query = `
    {
        afcDataRecords(first: 20, after: "${afterCursor}", ${searchString}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            creationDate
            lastModifiedDate
            octopusId
            stationFrom
            stationTo
            pk
            status
            customer {
                membershipId
            }
          }
        }
      }
    }
    `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

const AFC_DATA_RECORD_NODE = `{
    id
    pk
    customer {
      id
      pk
      membershipId
      firstName
      lastName
      nickname
    }  
    stationFrom
    stationTo
    octopusId
    maskedOctopusId
    transactionDatetime
    status
}`;

export const getOneAfcDataRecord = (id) => {
    const query = `{
      afcDataRecord(id: "${id}") ${AFC_DATA_RECORD_NODE}
    }`;
    const variables = {};
    return BaseHelper.callGraphQLAPI({
      query,
      variables,
    });
};
  
const createUpdateAfcDataRecordContent = `
node {
  id
  pk
  customer {
    id
  }
  octopusId
  maskedOctopusId
  stationFrom
  stationTo
  status
}
errors {
  field
  messages
}
`

export const createAfcDataRecord = (values) => {
  let query = `mutation CreateAfcDataRecord($input: CreateAfcDataRecordInput!) {
    createAfcDataRecord(input: $input) {
      ${createUpdateAfcDataRecordContent}
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};


export const updateAfcDataRecord = (values) => {
  let query = `mutation UpdateAfcDataRecord($input: UpdateAfcDataRecordInput!) {
    updateAfcDataRecord(input: $input) {
      ${createUpdateAfcDataRecordContent}
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};


export const deleteAfcDataRecord = (id) => {
  const query = `mutation DeleteAfcDataRecord($input: DeleteAfcDataRecordInput!) {
    deleteAfcDataRecord(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
