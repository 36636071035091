import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { getImageUrl, isShowError, useCompare } from '../../../utils';
import i18n from '../../../I18n';
import { ErrorHandleFields } from './ErrorHandleFields';
import ImageUploader from '../../../components/base/ImageUploader';
import { LanguageConfig } from '../../../config/CustomEnums';
import CustomRadios from '../../../components/base/CustomRadios';
import CustomAddRemoveMutipleSelector from '../../../components/customer/CustomAddRemoveMutipleSelector';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';


const getDisplayInOptions = () => [
  {
    label: "Green Info Hub",
    value: "GREEN_INFO_HUB",
  },
  {
    label: "Community",
    value: "COMMUNITY",
  },
  {
    label: "Both",
    value: "BOTH",
  },
];


const KeyVisualSection = ({
  language,
  newsFeedDisplayIn,
  newsFeedCommunity,
  translations,
  errorFields,
  communityList,
}) => {
  const dispatch = useDispatch();
  const newsFeedCoverPhoto = translations?.[language]?.coverPhoto || "";
  const newsFeedDetailPhoto1 = translations?.[language]?.detailPhoto1 || "";
  const newsFeedDetailPhoto2 = translations?.[language]?.detailPhoto2 || "";
  const newsFeedDetailPhoto3 = translations?.[language]?.detailPhoto3 || "";
  const newsFeedDetailPhoto4 = translations?.[language]?.detailPhoto4 || "";
  const newsFeedOtherPhoto = [
    newsFeedDetailPhoto1,
    newsFeedDetailPhoto2,
    newsFeedDetailPhoto3,
    newsFeedDetailPhoto4
  ].filter(item => !!item)

  const [coverPhoto, setCoverPhoto] = useState(newsFeedCoverPhoto ? [newsFeedCoverPhoto] : []);
  const [otherPhotos, setOtherPhotos] = useState(newsFeedOtherPhoto);
  const [displayIn, setDisplayIn] = useState(newsFeedDisplayIn || null);
  const [communities, setCommunities] = useState(newsFeedCommunity || []);


  const isError = (field) => {
    return isShowError(field, errorFields, language);
  };

  const vals = {
    coverPhoto,
    otherPhotos,
    displayIn,
    communities,
  };
  const valsHasChanged = useCompare(vals);


  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'newsFeedModel/updateVals', payload: { displayIn, communities } });
      dispatch({
        type: 'newsFeedModel/updateTranslations',
        payload: {
          coverPhoto: getImageUrl(coverPhoto?.[0]),
          detailPhoto1: getImageUrl(otherPhotos?.[0]),
          detailPhoto2: getImageUrl(otherPhotos?.[1]),
          detailPhoto3: getImageUrl(otherPhotos?.[2]),
          detailPhoto4: getImageUrl(otherPhotos?.[3]),
          language
        }
      });
    }
  });

  return (
    <>
      <label className="create-section-title">
        {i18n.t('key_visual', { locale: language })}
      </label>
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={600}
        images={coverPhoto}
        onImageStateChange={(newState) => {
          setCoverPhoto(newState);
        }}
        aspect={3 / 2}
        maxImageNum={1}
        language={language}
        photoSectionTitle={'cover_photo'}
        uploadImageClicked={() => { }}
        errorMessage={`${isError(ErrorHandleFields.coverPhoto.name)
          ? ErrorHandleFields.coverPhoto.message
          : ''
          }`}
      />
      {
        language === LanguageConfig.english
          ? <>
            <label className="create-section-label create-section-label-bottom-space">
              {i18n.t('campaign.display_in', { locale: language })}
            </label>
            <Row className="featured-exclusive-row">
              <CustomRadios
                disabled={false}
                onChange={(value) => {
                  setDisplayIn(value);
                }}
                default={displayIn}
                options={getDisplayInOptions()}
              />
            </Row>
            <ErrorFieldMessage
              id={`${ErrorHandleFields.displayIn.name}-${language}`}
              error={isError(ErrorHandleFields.displayIn.name)}
              message={ErrorHandleFields.displayIn.message}
            />
            {displayIn === "COMMUNITY" || displayIn === "BOTH"
              ? <CustomAddRemoveMutipleSelector
                title={"Community List"}
                data={communityList}
                selectedList={communities}
                setValue={(value) => {
                  setCommunities(value);
                }}
                sourceReminderText={'Select Community'}
                amidReminderText={'Please select community'}
                loadMoreAction={'communityModel/getList'}
              />
              : null
            }
          </>
          : null
      }


      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('other_photos', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={600}
        images={otherPhotos}
        onImageStateChange={(newState) => {
          setOtherPhotos(newState);
        }}
        aspect={3 / 2}
        maxImageNum={4}
        language={language}
        photoSectionTitle={'other_photo'}
        uploadImageClicked={() => { }}
      />
    </>
  )
}


const mapStateToProps = (state) => ({
  newsFeedDisplayIn: state.newsFeedModel.oneObject.displayIn,
  newsFeedCommunity: state.newsFeedModel.oneObject.communities || [],
  translations: state.newsFeedModel.oneObject.translations || {},
  errorFields: state.newsFeedModel.errorFields,
  communityList: state.communityModel.communityList,
})

export default connect(mapStateToProps)(KeyVisualSection);
