import React, { useState, useEffect } from 'react';
import { Button, Image, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import '../../utils/BtnCommonTheme.scss';
import '../segment/Segment.scss';

import NarrowUp from '../../assets/images/drop_down_not_show_narrow.svg';
import Search from '../../assets/images/drop_down_filter.svg';

function ContentFilterDropDown(props) {
  const sectionSelectAction = props.addContent || (() => { });
  const settedParams = props.settedParams || [];
  const contentSections = props.data || [];

  const [isOpen, setIsOpen] = useState({
    Demographic: false,
    Behavioral: false,
  });
  const openNarrow = (section) => {
    setIsOpen({ ...isOpen, [section]: !isOpen[section] });
  };
  const [contentSelections, setContentSelections] = useState(contentSections);
  const [filterKey, setFilterKey] = useState('');
  const [selectedSections, setSelectedSections] = useState(settedParams);

  useEffect(() => {
    setSelectedSections(props.settedParams);
  }, [props.settedParams]);

  useEffect(() => {
    setFilterKey('');
    setContentSelections(contentSections);
  }, [props.show]);

  return (
    <div className="content-add-button-selection-area">
      {/* <input className="content-add-button-selection-filter" /> */}
      <div className="filterable-search-bar content-add-button-selection-filter">
        <Image
          src={Search}
          className="filterable-search-bar-icon search_bar_icon"
        />
        <input
          autoFocus
          className="filterable-search-bar-input"
          placeholder="Search for..."
          onChange={(e) => {
            const value = e.target.value;
            setFilterKey(value);

            let filteredResult = {};
            Object.keys(contentSections).map(
              (key) =>
              (filteredResult[key] = contentSections[key].filter(
                (val) => val.name?.toLowerCase()?.indexOf(value.toLowerCase()) >= 0,
              )),
            );
            setContentSelections(filteredResult);
          }}
          value={filterKey}
        />
      </div>
      <div className="content-add-button-selection-section-area">
        {Object.keys(contentSelections).map((section, index) => {
          return (
            <div key={`content_selections_section_${index}`}>

              <ul className="content-add-button-selection-section">
                <label>{section}</label>
                <button
                  className="reset-button margin-left-auto"
                  onClick={() => openNarrow(section)}
                >
                  <Image
                    src={NarrowUp}
                    className={`filterable-toogle-area-indicator ${isOpen[section] ? 'rtoate180' : ''
                      }`}
                  />
                </button>
              </ul>

              {isOpen[section]
                ? contentSelections[section].map((val, index) => {
                  return selectedSections?.filter((item) =>
                    item.pk ? item.pk === val.pk : item === val,
                  ).length > 0 ? null : (
                    <Dropdown.Item
                      key={`content_selections_item${index}`}
                      onClick={() => {
                        sectionSelectAction(val);
                        setSelectedSections([...selectedSections, val]);
                      }}
                    >
                      <div className="display-flex-area">
                        <div className="li-icon" />
                        <label className="li-label">{val?.name || val}</label>
                      </div>
                    </Dropdown.Item>
                  );
                })
                : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function CustomContentFilterDropDown({
  title,
  data,
  content,
  addContentAction = (section) => { },
}) {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  return (
    <Dropdown
      onToggle={(isOpen) => {
        setIsShowDropdown(isOpen);
      }}
    >
      <Dropdown.Toggle
        className="btn-back-button-common content-add-button"
        variant="none"
        id={`dropdown`}
      >
        + {title}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ zIndex: 2 }}>
        <ContentFilterDropDown
          data={data}
          show={isShowDropdown}
          addContent={addContentAction}
          settedParams={content?.map((item) => item.title)}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CustomContentFilterDropDown;
