import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import ActivePeriodSection from './ActivePeriodSection';
import VisiblePeriodSection from './VisiblePeriodSection';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import RewardSection from './RewardSection';

function CreateMissionStepThree({

}) {
  const dataArray = [];
  const dispatch = useDispatch();
  const history = useHistory();
  const fromCommunity = history.location?.state?.fromCommunity || {};
  const fromCommunityPathName = fromCommunity?.pathname || null;

  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (onCheck.value) {
      let formatedData = {};
      dataArray.forEach((item) => {
        formatedData = {
          ...formatedData,
          ...item,
        };
      });
      dispatch({
        type: 'mission/stepChange',
        payload: {
          data: formatedData,
          isBack: onCheck.isBack,
          step: 2,
          fromCommunity: !!fromCommunityPathName,
        },
      });
    }
    stopCheck();
  }, [dispatch, onCheck.isBack, onCheck.value]);

  const submitAction = (data) => {
    dataArray.push(data);
  };

  const sections = [
    <ActivePeriodSection
      key={'mission-active-period-section'}
      onSubmit={onCheck.value}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
    />,
    <VisiblePeriodSection
      key={'mission-visible-period-section'}
      onSubmit={onCheck.value}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
    />,
    <RewardSection
      key={'mission-reward-section'}
      onSubmit={onCheck.value}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
    />
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          isChecking({ isBack: false });
        }}
        backAction={() => {
          isChecking({ isBack: true });
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps)(CreateMissionStepThree);
