import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import './ImageUploader.scss';
import XButton from '../../assets/images/x-button.svg';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { connect } from 'react-redux';
import { IMAGE_TYPES, ERROR_TYPE } from '../../models/UploadFilesModel';
import LoadingIcon from '../../assets/images/loading';
import Lottie from 'react-lottie';
import { createAction, readFile } from '../../utils';

const DEFAULT_MAX_IMAGE_SIZE = 2;

@connect(({ uploadFiles }) => ({
  ...uploadFiles,
}))
class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: null,
      modalShow: false,
      imageType: null,
      errorMessage: props.errorMessage,
      cropDetail: {},
      cropperDisble:false
    };
    this.uploadingImagesMap = {};
    this.cropper = React.createRef();
    // format init images
    if (props.images && props.images.length > 0) {
      if (typeof props.images[0] === 'string') {
        props.onImageStateChange(this.getDefaultImages());
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.uploadedImages !== prevProps.uploadedImages) {
      const uploadedImageUuids = [];
      this.getDefaultImages().forEach((item, index) => {
        if (
          item.type === IMAGE_TYPES.TYPE_UPLOADING &&
          this.props.failedImage[item.value]
        ) {
          this.deleteImageBtnClicked(index);
        } else if (
          item.type === IMAGE_TYPES.TYPE_UPLOADING &&
          item.value in this.props.uploadedImages
        ) {
          uploadedImageUuids.push(item.value);
        }
      });
      if (uploadedImageUuids.length > 0 && this.props.onImageStateChange) {
        const newImageState = [];
        this.getDefaultImages().forEach((item, index) => {
          if (uploadedImageUuids.includes(item.value)) {
            const imageData = {
              type: IMAGE_TYPES.TYPE_URL,
              value: this.props.uploadedImages[item.value],
              oldValue: item.value,
            };
            newImageState.push(imageData);
          } else {
            newImageState.push(item);
          }
        });
        console.log(newImageState);
        this.props.onImageStateChange(newImageState);
      }
    }
  }

  getDefaultImages() {
    const images = this.props.images;
    const newImages = images.map((item) => {
      return {
        oldValue: item?.oldValue || '',
        type: item?.type || IMAGE_TYPES.TYPE_URL,
        value: item?.value || item,
      };
    });
    return newImages;
  }

  turnLeftButtonClicked = () => {
    this.cropper.current.cropper.rotate(-90);
  };

  turnRightButtonClicked = () => {
    this.cropper.current.cropper.rotate(90);
  };

  showResult = async () => {
    this.setState({
      modalShow: false,
      errorMessage: '',
    });
    if (
      typeof this.cropper.current.cropper.getCroppedCanvas() === 'undefined'
    ) {
      return;
    }
    const croppedCanvas = this.cropper.current.cropper.getCroppedCanvas();
    const minWidth = this.props.minWidth || 600;
    const minHeight = this.props.minHeight || 600;

    if (croppedCanvas.height < minHeight || croppedCanvas.width < minWidth) {
      this.setState({
        errorMessage: `Image dimensions could not be smaller than ${minWidth} * ${minHeight} px.`,
      });
      return;
    }
    // else if (croppedCanvas.height < 600 || croppedCanvas.width < 600) {
    //   this.setState({
    //     errorMessage:
    //       'Image dimensions could not be smaller than 600 px * 600 px.',
    //   });
    //   return;
    // } else if (croppedCanvas.height > 1800 || croppedCanvas.width > 2700) {
    //   this.setState({
    //     errorMessage:
    //       'Image dimensions could not be bigger than 2700 px * 1800 px.',
    //   });
    //   return;
    // }

    const croppedImage = this.cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL('image/jpeg', 0.96);
    const realLength = (croppedImage.length * 3) / 4;
    const maxImageSize = this.props.maxImageSize || DEFAULT_MAX_IMAGE_SIZE;
    if (realLength >= maxImageSize * 1000 * 1000) {
      this.setState({
        errorMessage: `Image size could not be bigger than ${maxImageSize}M.`,
      });
      return;
    }

    const uuid = uuidv4();
    this.props.dispatch(
      createAction('uploadFiles/uploadCroppedImage')({
        croppedImage: {
          imageDataUrl: croppedImage,
          imageType: this.state.imageType,
          imageName: uuid,
        },
        secert: this.props.secert,
      }),
    );
    this.uploadingImagesMap[uuid] = croppedImage;
    if (this.props.onImageStateChange) {
      this.props.onImageStateChange([
        ...(this.getDefaultImages() || []),
        { type: IMAGE_TYPES.TYPE_UPLOADING, value: uuid },
      ]);
    }
    this.setState({
      imageSrc: null,
    });
  };

  checkGifMinWidth(imageUrl) {
    return new Promise((resolve, reject) => {
      const minWidth = this.props.minWidth || 600;
      const minHeight = this.props.minHeight || 600;
      const originalImg = new Image();
      originalImg.onload = () => {
        const height = originalImg.height;
        const width = originalImg.width;
        console.log("@@201", height, width)
        if (height < minHeight || width < minWidth) {
          this.setState({
            errorMessage: `Image dimensions could not be smaller than ${minWidth} * ${minHeight} px.`,
          });
          reject();
          return;
        };
        resolve();
      };
      originalImg.src = imageUrl;
    })
  }

  onDropFileChange = async (files) => {
    const maxImageSize = this.props.maxImageSize || DEFAULT_MAX_IMAGE_SIZE;
    this.props.uploadImageClicked();
    if (this.getDefaultImages().length === this.props.maxImageNum) {
      return;
    }
    if (files.length > 0) {
      const file = files[0];
      console.log(file.type);
      if (file.size / 1024 / 1024 > maxImageSize) {
        this.setState({
          errorMessage: `Image dimensions could not be bigger than ${maxImageSize}MB.`,
        });
        return;
      }
      if (
        file.type !== 'image/png' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/gif' &&
        file.type !== 'image/jpeg'
      ) {
        this.setState({
          errorMessage: ERROR_TYPE.wrongPhotoType,
        });
        return;
      }
      const imageDataUrl = await readFile(file);
      if (this.props.cropperDisble || (this.props.enableGif && file.type === 'image/gif')) {
        let minError = false;
        await this.checkGifMinWidth(imageDataUrl).then(() => {
          console.log("@@218 check image minSize success")
        }).catch(() => {
          console.log("@@220, check image minSize error")
          minError = true;
        })
        if (minError) {
          return;
        }

        const uuid = uuidv4();
        this.props.dispatch(
          createAction('uploadFiles/uploadCroppedImage')({
            croppedImage: {
              imageDataUrl: imageDataUrl,
              imageType: this.state.imageType,
              imageName: uuid,
            },
          }),
        );
        this.uploadingImagesMap[uuid] = imageDataUrl;
        if (this.props.onImageStateChange) {
          this.props.onImageStateChange([
            ...(this.getDefaultImages() || []),
            { type: IMAGE_TYPES.TYPE_UPLOADING, value: uuid },
          ]);
        }
        this.setState({
          imageSrc: null,
        });
        return;
      }

      this.setState({
        imageSrc: imageDataUrl,
        modalShow: true,
        imageType: file.type,
      });
    }
  };

  deleteImageBtnClicked = (index) => {
    if (index > this.getDefaultImages().length) {
      return;
    }
    const image = this.getDefaultImages()[index];
    if (this.props.failedImage[image.value]) {
      this.setState({
        errorMessage: ERROR_TYPE.photoUploadFailed,
      });
    }
    if (image.value in this.uploadingImagesMap) {
      delete this.uploadingImagesMap[image.value];
    }
    if (this.props.onImageStateChange) {
      const newImages = [];
      this.getDefaultImages().forEach((oldImage, oldIndex) => {
        if (index !== oldIndex) {
          newImages.push(oldImage);
        }
      });
      this.props.onImageStateChange(newImages);
    }
  };

  uploadImage = {
    en: 'Upload Image',
    'zh-Hant': '上傳圖片',
    'zh-Hans': '上传图片',
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingIcon,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const isSquare = this.props.aspect === 1 / 1;
    return (
      <div className="upload-image-container">
        <div className="demo-image-section">
          {this.getDefaultImages()?.map((item, index) => {
            const { type, value } = item;
            let src = '';
            if (type === IMAGE_TYPES.TYPE_URL) {
              src = value;
            } else if (type === IMAGE_TYPES.TYPE_UPLOADING) {
              if (value in this.uploadingImagesMap) {
                src = this.uploadingImagesMap[value];
              }
            }
            return (
              <div key={index}>
                <div className="demo-image-div">
                  <img
                    alt="img"
                    src={src}
                    className={
                      this.props.showAspect
                        ? 'show-aspect'
                        : `demo-image ${
                            isSquare
                              ? 'demo-image-square'
                              : 'demo-image-rectangle'
                          }`
                    }
                    onLoad={() => {
                      const uuid = item.oldValue;
                      if (uuid && uuid in this.uploadingImagesMap) {
                        delete this.uploadingImagesMap[uuid];
                        this.props.dispatch({
                          type: 'uploadFiles/finishUploadImages',
                          payload: {
                            uuid,
                          },
                        });
                      }
                    }}
                  />
                  <div
                    className="uploading-background"
                    hidden={type === IMAGE_TYPES.TYPE_UPLOADING ? false : true}
                  ></div>
                  {type === IMAGE_TYPES.TYPE_UPLOADING ||
                  type === IMAGE_TYPES.TYPE_UPLOADED ? (
                    <Lottie
                      options={defaultOptions}
                      style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      height={80}
                      width={80}
                    />
                  ) : null}
                  <img
                    alt="x-button"
                    src={XButton}
                    className="x-button"
                    onClick={() => this.deleteImageBtnClicked(index)}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Dropzone
          accept={['image/jpeg', 'image/png', 'image/gif']}
          onDrop={(acceptedFiles) => this.onDropFileChange(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="drop-upload-image-section">
              <div {...getRootProps()}>
                <input
                  {...getInputProps({
                    disabled:
                      this.getDefaultImages().length === this.props.maxImageNum
                        ? true
                        : false,
                  })}
                />
                <button
                  className="custom-upload-btn"
                  disabled={
                    this.getDefaultImages().length === this.props.maxImageNum
                      ? true
                      : false
                  }
                  onClick={() => this.props.uploadImageClicked()}
                >
                  {this.uploadImage[this.props.language]}
                </button>
              </div>
            </section>
          )}
        </Dropzone>
        <div className="error-field-message-style">
          {this.state.errorMessage || this.props.errorMessage}
        </div>

        <Modal
          centered
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
        >
          <Modal.Header closeButton>
            <label>
              {Math.floor(this.state.cropDetail.width)} px *{' '}
              {Math.floor(this.state.cropDetail.height)} px
            </label>
          </Modal.Header>
          <Modal.Body>
            <Cropper
              ref={this.cropper}
              src={this.state.imageSrc}
              style={{ height: 'auto', width: '100%', maxHeight: 500 }}
              aspectRatio={this.props.aspect}
              guides={false}
              rotatable={true}
              viewMode={1}
              crop={(event) => {
                this.setState({ cropDetail: event.detail });
              }}
              dragMode="move"
              cropBoxMovable={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.turnLeftButtonClicked}
              className="turn_left_btn crop_image_btn"
            >
              Left
            </button>
            <button
              onClick={this.turnRightButtonClicked}
              className="turn_right_btn crop_image_btn"
            >
              Right
            </button>
            <button
              onClick={() => this.setState({ modalShow: false })}
              className="crop_image_btn cancel"
            >
              Cancel
            </button>
            <button
              onClick={this.showResult}
              className="crop_image_btn confirm"
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ImageUploader;
