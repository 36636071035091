import { authAPIHelper } from '../services/AuthApiHelper';
import {
  addAuthorization,
  removeAuthorization,
  updateRefreshToken,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '../services/BaseRefreshHelper';
import Cookies from 'cookies-js';
import { apiWithResponseHandle } from './LoadingUtil';

const userPermissionsKey = 'userPermissions';
const allPermissionsKey = 'allPermissions';

function toBase64(str) {
  return window.btoa(encodeURIComponent(str));
}

function fromBase64(str) {
  return decodeURIComponent(window.atob(str));
}

const removeAPIHelperToken = () => {
  removeAuthorization();
};

const setUpAPIHelperToken = (token) => {
  addAuthorization(token);
};

const getPermissionsFromStorage = (key) => {
  const storagePermissions = localStorage.getItem(key) || '[]';
  let permissions = [];
  try {
    permissions = JSON.parse(fromBase64(storagePermissions));
  } catch (err) {
    localStorage.removeItem(key);
  }
  return permissions;
};

export default {
  namespace: 'users',
  state: {
    isLogin: false,
    firstLogin: false,
    loginFailed: false,
    isSuperuser: false,
    allPermissions: [],
    userPermissions: [],
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *startUp({ payload }, { call, put, select }) {
      const accessToken = Cookies.get(ACCESS_TOKEN);
      const refreshToken = Cookies.get(REFRESH_TOKEN);
      const userPermissions = getPermissionsFromStorage(userPermissionsKey);
      const allPermissions = getPermissionsFromStorage(allPermissionsKey);
      yield put({
        type: 'updateState',
        payload: {
          userPermissions,
          allPermissions,
        },
      });
      const modelAccessToken = yield select((state) => state.users.accessToken);
      const modelRefreshToken = yield select(
        (state) => state.users.refreshToken,
      );
      const token = accessToken || modelAccessToken || '';
      const refreshKey = refreshToken || modelRefreshToken || '';
      Cookies.set(REFRESH_TOKEN, refreshKey);
      console.log('startUp refreshKey:', refreshKey);
      if (refreshKey) {
        yield put({ type: 'updateState', payload: { isLogin: true } });
      } else {
        yield put({ type: 'logout' });
      }
      setUpAPIHelperToken(token);
    },
    *login({ payload }, { call, put }) {
      const { username, password } = payload;
      const serviceArgs = [authAPIHelper.createAccessToken, {
        username,
        password,
      }];

      function* onSuccess(data) {
        const tokenAuthData = data?.tokenAuth;
        if (tokenAuthData) {
          setUpAPIHelperToken(tokenAuthData.token);
          updateRefreshToken(tokenAuthData.refreshToken);
          const userPermissions =
            tokenAuthData.administrator.allPermissions?.map(
              (val) => val.split('.')[1],
            );
          const allPermissions = tokenAuthData.administrator.allPermissions;
          try {
            const userPermissionString = JSON.stringify(userPermissions);
            console.log('permissions:', userPermissionString.length);
            localStorage.setItem(
              userPermissionsKey,
              toBase64(userPermissionString),
            );
            const allPermissionsString = JSON.stringify(allPermissions);
            localStorage.setItem(
              allPermissionsKey,
              toBase64(allPermissionsString),
            );
          } catch (err) {
            console.log('setLocalError');
          }
          yield put({
            type: 'updateState',
            payload: {
              accessToken: tokenAuthData.token,
              payload: {
                username: tokenAuthData.payload.username,
                exp: tokenAuthData.payload.exp,
              },
              refreshToken: tokenAuthData.refreshToken,
              refreshExpiresIn: tokenAuthData.refreshExpiresIn,
              isLogin: true,
              loginFailed: false,
              isSuperuser: tokenAuthData.administrator.isSuperuser,
              allPermissions,
              userPermissions,
              username: tokenAuthData.administrator.username,
              firstLogin: true,
            },
          });
        } else {
          yield put({
            type: 'updateState',
            payload: {
              loginFailed: true,
              isLogin: false,
            },
          });
        }
      }
      function* onFailed(response) {
        yield put({
          type: 'updateState',
          payload: {
            loginFailed: true,
            isLogin: false,
          },
        });
      }

      yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
    },

    logout: [
      function* ({ payload }, { call, put, select }) {
        const refreshToken = Cookies.get(REFRESH_TOKEN);
        const accessToken = Cookies.get(ACCESS_TOKEN);
        yield call(authAPIHelper.logout, refreshToken, accessToken);
        removeAPIHelperToken();
        localStorage.removeItem(userPermissionsKey);
        localStorage.removeItem(allPermissionsKey);
        yield put({
          type: 'updateState',
          payload: {
            isLogin: false,
            firstLogin: false,
            allPermissions: [],
            userPermissions: [],
          },
        });
      },
      { type: 'takeLatest' },
    ],
  },
};
