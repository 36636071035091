import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './LanguageTab.scss';
import { LanguageConfig } from '../../config/CustomEnums';
function LanguageTabs(props) {
  const enContainer = props.enContainer;
  const hantContainer = props.hantContainer;
  const hansContainer = props.hansContainer;
  const error = props.fieldsError;
  const [activeTab, setActiveTab] = useState(LanguageConfig.english);
  useEffect(() => {
    const errorTab = props.errorTab || LanguageConfig.english;
    if (error) {
      setActiveTab(errorTab);
    }
  }, [error]);

  return (
    <Tabs
      className="language-tab-container"
      activeKey={activeTab}
      onSelect={(key) => setActiveTab(key)}
    >
      <Tab eventKey={LanguageConfig.english} title="English">
        {enContainer}
      </Tab>
      <Tab eventKey={LanguageConfig.traditionalChinese} title="繁體">
        {hantContainer}
      </Tab>
      {hansContainer && <Tab eventKey={LanguageConfig.simplifiedChinese} title="简体">
        {hansContainer}
      </Tab>}
    </Tabs>
  );
}

export default LanguageTabs;
