import {
  createMission,
  deleteMissions,
  getMission,
  getMissions,
  publishMission,
  unLinkCommunity,
  unPublishMission,
  updateMission
} from '../services/MissionApiHelper';
import {
  convertNumberToCursor,
  createAction,
  convertCursorToNumber,
  removeFromSessionStorage,
  saveToSessionStorage,
  getObjectFromSessionStorage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { formatDate, getCampignListDisplayTime } from '../utils/TimeFormatUtil';
import {
  LanguageConfig,
  APIStatus,
  PublishTagType,
  MissionType,
  GroupMissionItemType,
} from '../config/CustomEnums';
import newImage from '../assets/images/mission/new.png';
import readImage from '../assets/images/mission/read.png';
import readTipsImage from '../assets/images/mission/readTips.png';
import stepsImage from '../assets/images/mission/steps.png';
import linkImage from '../assets/images/mission/link.png';
import earnImage from '../assets/images/mission/earn.png';
import facebookImage from '../assets/images/mission/facebook.png';
import viewImage from '../assets/images/mission/view.png';
import locationImage from '../assets/images/mission/location.png';
import recyclingImage from '../assets/images/mission/recycling.png';
import greenDiningImage from '../assets/images/mission/greenDining.png';
import greenShoppingImage from '../assets/images/mission/greenShopping.png';
import plantMilkImage from '../assets/images/mission/plantMilk.png';
import generalImage from '../assets/images/mission/general.png';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { getTranslationForCampaign } from './CreateCampaignModel';
import { checkCampaignIsExpired } from '../utils/TimeFormatUtil';

export const sessionDataKey = {
  objectKey: 'createMission',
  stepEndKey: 'createMissionStepEnd',
  origionalData: 'createMissionOriginalData',
};

const MissionEarnType = {
  'PHOTO_TAKING': {
    name: 'Recycling',
    icon: recyclingImage,
    value: 'PHOTO_TAKING'
  },
  'GREEN_DINING': {
    name: 'Green Dining',
    icon: greenDiningImage,
    value: 'GREEN_DINING'
  },
  'GREEN_SHOPS': {
    name: 'Green Shopping',
    icon: greenShoppingImage,
    value: 'GREEN_SHOPS'
  },
  'GREEN_CAFE': {
    name: 'Plant Milk',
    icon: plantMilkImage,
    value: 'GREEN_CAFE'
  },
}

export const MissionEarningIcon = {
  ...MissionEarnType, 'GENERAL': {
    name: 'General',
    icon: generalImage,
    value: 'GENERAL'
  },
}
const HabitualEarningType = {
  ...MissionEarnType,
  'MEMBER_REFERRAL': {
    name: 'Member Referral',
    icon: generalImage,
    value: 'MEMBER_REFERRAL'
  },

  'QR_CODE_SCANNING': {
    name: 'QR Code Scanning',
    icon: generalImage,
    value: 'QR_CODE_SCANNING'
  },

  'ACTIVE_TRACKING': {
    name: 'Active Tracking',
    icon: generalImage,
    value: 'ACTIVE_TRACKING'
  },
}


export const MissionIcon = {
  'MISSION_LIST': {
    name: 'Checklist',
    icon: newImage,
    value: 'MISSION_LIST'
  },
  'RECYCLING_GUIDE': {
    name: 'Guide',
    icon: readImage,
    value: 'RECYCLING_GUIDE'
  },
  'RECYCLING_TIPS': {
    name: 'Light Bulb',
    icon: readTipsImage,
    value: 'RECYCLING_TIPS'
  },
  'STEPS': {
    name: 'Steps',
    icon: stepsImage,
    value: 'STEPS'
  },
  'LINK_OCTOPUS': {
    name: 'Linkage',
    icon: linkImage,
    value: 'LINK_OCTOPUS'
  },
  'READ_EARN_TOTURIAL': {
    name: 'Earning',
    icon: earnImage,
    value: 'READ_EARN_TOTURIAL'
  },
  'FACEBOOK': {
    name: 'Facebook',
    icon: facebookImage,
    value: 'FACEBOOK'
  },
  'VIEW_ACHIEVEMENTS': {
    name: 'Badge',
    icon: viewImage,
    value: 'VIEW_ACHIEVEMENTS'
  },
  'REPORTING_LOCATION': {
    name: 'Location',
    icon: locationImage,
    value: 'REPORTING_LOCATION'
  },
};

export const NumberOfLevels = {
  1: {
    name: '1 levels',
    value: 1,
  },
  2: {
    name: '2 levels',
    value: 2,
  },
  3: {
    name: '3 levels',
    value: 3,
  },
  4: {
    name: '4 levels',
    value: 4,
  },
  5: {
    name: '5 levels',
    value: 5,
  },
};

export const MissionIconList = Object.values(MissionIcon);
export const MissionEarningIconList = Object.values(MissionEarningIcon);
export const NumberOfLevelsList = Object.values(NumberOfLevels);
export const HabitualMisstionType = Object.values(HabitualEarningType)

export const MissionTrigger = {
  'MISSION_LIST': {
    name: 'When user arrives at Mission List',
    value: 'MISSION_LIST'
  },
  'RECYCLING_GUIDE': {
    name: 'When user opens Recycling guide photo',
    value: 'RECYCLING_GUIDE'
  },
  'RECYCLING_TIPS': {
    name: 'When user lands on recycling tips page',
    value: 'RECYCLING_TIPS'
  },
  'STEPS': {
    name: 'When "Join Now" button disappears',
    value: 'STEPS'
  },
  'LINK_OCTOPUS': {
    name: 'When "Link Octopus" button disappears',
    value: 'LINK_OCTOPUS'
  },
  'READ_EARN_TOTURIAL': {
    name: 'When user enters "How to Earn CW Points?" page',
    value: 'READ_EARN_TOTURIAL'
  },
  'FACEBOOK': {
    name: 'When user clicks on "Facebook" icon in Profile page',
    value: 'FACEBOOK'
  },
  'VIEW_ACHIEVEMENTS': {
    name: 'When user enters My Achievements page',
    value: 'VIEW_ACHIEVEMENTS'
  },
  'REPORTING_LOCATION': {
    name: 'When user clicks on "Submit" button in the report form',
    value: 'REPORTING_LOCATION'
  },
};

export const MissionTriggerList = Object.values(MissionTrigger);

export const CreateMissionError = {
  title: {
    name: 'title',
    message: 'Please provide a title'
  },
  descriptionContentEmpty: {
    name: 'descriptionContentEmpty',
    message: 'Please provide a description content'
  },
  descriptionContent: {
    name: 'descriptionContent',
    message: 'Max. 145 characters'
  },
  groupDescriptionContent: {
    name: 'groupDescriptionContent',
    message: 'Max. 130 characters'
  },
  icon: {
    name: 'icon',
    message: 'Please select an icon.',
  },
  missionEarningType: {
    name: 'missionEarningType',
    message: 'Please select a Mission earning type.',
  },
  triggerPage: {
    name: 'triggerPage',
    message: 'Please select a trigger page.',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a mission cannot be on or before the start date and time.',
  },
  visibleEndDate: {
    name: 'visibleEndDate',
    message:
      'The end date & time of a mission cannot be on or before the start date and time.',
  },
  rewards: {
    name: 'rewards',
    message: 'Please provide rewards'
  },
  points: {
    name: 'points',
    message: 'Please provide valid points'
  },
  couponQuantity: {
    name: 'couponQuantity',
    message: 'Please provide valid coupons'
  },
  linkedCommunity: {
    name: 'linkedCommunity',
    message: 'Please select a community'
  },
  linkedCampaign: {
    name: 'linkedCampaign',
    message: 'Please select a campaign'
  },
  joinedTimes: {
    name: 'joinedTimes',
    message: 'Please provide vaild Joined times'
  },
  recyclingItems: {
    name: 'recyclingItems',
    message: 'Please provide vaild Recycling items'
  },
  setLevels: {
    name: 'setLevels',
    message: 'Please select the number of levels'
  },
  progressUnit: {
    name: 'progressUnit',
    message: 'Please provide Progress unit'
  },
  groupMissionEarningTypeOrLinkedCampaign: {
    name: 'groupMissionEarningTypeOrLinkedCampaign',
    message: 'Please provide mission earning type or link to campaign'
  },
  missionItems: {
    name: 'missionItems',
    message: 'Please provide Mission item(s)'
  },
  totalTarget: {
    name: 'totalTarget',
    message: 'Minimum mission target is at least 10 items/times'
  },
  individualTarget: {
    name: 'individualTarget',
    message: 'Minimum requirement is at least 1 quantity which is submitted successfully'
  },
  reminderEligible: {
    name: 'reminderEligible',
    message: 'Please provide Reminder bar: Eligible to collect'
  },
  reminderEligibleMax: {
    name: 'reminderEligibleMax',
    message: 'Max. 42 characters'
  },
  reminderIneligible: {
    name: 'reminderIneligible',
    message: 'Please provide Reminder bar: Ineligible to collect'
  },
  reminderIneligibleMax: {
    name: 'reminderIneligibleMax',
    message: 'Max. 42 characters'
  },
  reminderComplete: {
    name: 'reminderComplete',
    message: 'Please provide Reminder bar: Completed'
  },
  reminderCompleteMax: {
    name: 'reminderCompleteMax',
    message: 'Max. 42 characters'
  },
  reminderExpired: {
    name: 'reminderExpired',
    message: 'Please provide Reminder bar: Expired'
  },
  reminderExpiredMax: {
    name: 'reminderExpiredMax',
    message: 'Max. 42 characters'
  },
};

const MISSION_TYPE = {
  ONBOARDING: 'Onboarding Mission',
  CAMPAIGN_LINKED: 'Campaign Linked',
  HABITUAL: 'Habitual',
  GROUP: 'Group',
}

const groupMissionReminder = [
  "reminderEligible",
  "reminderIneligible",
  "reminderComplete",
  "reminderExpired",
];

const stepNames = ['Type', 'Content', 'Properties', 'Preview'];
const communityTargetStepNames = ['Content', 'Properties', 'Preview'];

const getInitialState = () => ({
  missionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'name',
      linked: true,
      orderField: 'name',
    },
    {
      displayName: 'Mission Type',
      fieldName: 'displayMissionType',
      orderField: '',
    },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
    },
    { displayName: 'Status', fieldName: 'status' },
  ],
  currentPageMissionList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  missionDetail: {
    translations: {
      [LanguageConfig.english]: {},
      [LanguageConfig.simplifiedChinese]: {},
      [LanguageConfig.traditionalChinese]: {},
    },
  },
  errorFields: [],
  errorTab: LanguageConfig.english,
  stepConfig: defaultStep(stepNames),
  communityTargetStepConfig: defaultStep(communityTargetStepNames),
  currentStep: 0,
  missionAPIStatus: APIStatus.none,
  missionCreated: false,
});

const getTranlationsData = (data) => {
  const translationsEdges = data?.translations?.edges;
  let translations = {};
  if (translationsEdges?.length > 0) {
    translationsEdges.forEach((item) => {
      const node = item.node;
      translations[node.language] = {
        ...node,
      };
    });
  };
  translations[LanguageConfig.english] = {
    language: LanguageConfig.english,
    ...data,
  }
  return translations;
}

const getHabitualLevelsWithLanguage = (habitualLevels, language) => {
  const result = [];
  const habitualLevelsEdges = habitualLevels?.edges;
  if (habitualLevelsEdges?.length > 0) {
    habitualLevelsEdges.sort((previousItem, currentItem) => {
      return previousItem.node?.levelGrade > currentItem.node?.levelGrade ? 1 : -1
    }).forEach((item) => {
      const node = item.node;
      const translations = getTranlationsData(node);
      if (language !== LanguageConfig.english) {
        result.push({
          ...node,
          ...translations[language],
          joinedTimes: null,
          recyclingItems: null,
        });
        return;
      }
      result.push(translations[language]);
    })
  }
  return result;
}

const parseMission = (data) => {
  const isHabitual = data.category === MissionType.habitual;
  const isGroup = data.category === MissionType.group;
  const isCampaignLinked = data.category === MissionType.campaignLinked;
  const icon = {
    label: isCampaignLinked ? MissionEarningIcon?.[data.iconType]?.name : MissionIcon?.[data.iconType]?.name,
    value: isCampaignLinked ? MissionEarningIcon?.[data.iconType] : MissionIcon?.[data.iconType]
  };
  let missionEarningType = {
    label: null,
    value: null,
  };
  if (isHabitual || isGroup) {
    missionEarningType = {
      label: HabitualEarningType?.[data.missionEarningType]?.name,
      value: HabitualEarningType?.[data.missionEarningType],
    }
  }

  const trigger = {
    label: MissionTrigger?.[data.triggerPageType]?.name,
    value: MissionTrigger?.[data.triggerPageType]
  };

  const linkedCommunity = {
    label: data?.community?.name,
    value: data?.community,
  }

  const linkedCampaign = {
    label: data?.linkToCampaign?.name,
    value: {
      ...data?.linkToCampaign,
      earningRule: data?.linkToCampaign?.earningCampaignTypeEarningRule,
      translations: getTranslationForCampaign(data?.linkToCampaign, true),
    },
  }

  const setLevels = {
    label: NumberOfLevels?.[data?.habitualSetLevels]?.name,
    value: NumberOfLevels?.[data?.habitualSetLevels],
  }

  const translationsEdges = data?.translations?.edges;
  let translations = {};
  if (translationsEdges?.length > 0) {
    translationsEdges.forEach((item) => {
      const node = item.node;
      translations[node.language] = {
        id: node.pk,
        language: node.language,
        name: node.name,
        descriptionTitle: node.detailSectionTitle,
        descriptionContent: isHabitual || isGroup ? node.plainDescriptionContent : node.detailSectionContent,
        habitualLevels: getHabitualLevelsWithLanguage(data.habitualLevels, node.language),
        progressUnit: node.habitualProgressUnit,
        reminderEligible: node.missionReminderEligible,
        reminderIneligible: node.missionReminderIneligible,
        reminderComplete: node.missionReminderComplete,
        reminderExpired: node.missionReminderExpired,
      };
    });
  };
  translations[LanguageConfig.english] = {
    language: LanguageConfig.english,
    name: data.name,
    descriptionTitle: data.detailSectionTitle,
    descriptionContent: isHabitual || isGroup ? data.plainDescriptionContent : data.detailSectionContent,
    habitualLevels: getHabitualLevelsWithLanguage(data.habitualLevels, LanguageConfig.english),
    progressUnit: data.habitualProgressUnit,
    reminderEligible: data.missionReminderEligible,
    reminderIneligible: data.missionReminderIneligible,
    reminderComplete: data.missionReminderComplete,
    reminderExpired: data.missionReminderExpired,
  }

  const isExpired = checkCampaignIsExpired(data.endDate);
  let status = PublishTagType.unPublished;
  if (data.isPublished) {
    status = PublishTagType.published;
  };
  if (isExpired) {
    status = PublishTagType.expired;
  };

  const missionItems = {
    label: GroupMissionItemType?.[data.secondaryTypeLimit]?.name,
    value: GroupMissionItemType?.[data.secondaryTypeLimit],
  }

  return {
    pk: data.pk,
    name: data.name,
    isPublished: data.isPublished,
    status: status,
    descriptionTitle: data.detailSectionTitle,
    descriptionContent: isHabitual || isGroup ? data.plainDescriptionContent : data.detailSectionContent,
    icon,
    trigger,
    translations,
    isAlwaysActivePeriod: !data.endDate,
    activeStartDate: data.startDate,
    activeEndDate: data.endDate,
    displayActivePeriod: getCampignListDisplayTime(
      data.startDate,
      data.endDate,
    ),
    isAlwaysVisiblePeriod: !data.displayEndDate,
    visibleStartDate: data.displayStartDate,
    visibleEndDate: data.displayEndDate,
    displayVisiblePeriod: getCampignListDisplayTime(
      data.displayStartDate,
      data.displayEndDate,
    ),
    points: data.rewardPoints,
    couponTemplate: data?.rewardCouponTemplate,
    couponQuantity: data?.rewardCouponQuantity,
    missionType: data?.category,
    displayMissionType: MISSION_TYPE?.[data?.category],
    missionEarningType,
    communityOnly: data?.communityOnly,
    linkedCommunity,
    linkedCampaign,
    joinedTimes: data?.joinedTimes,
    recyclingItems: data?.recyclingItems,
    setLevels: setLevels,
    missionItems: missionItems,
    totalTarget: data?.totalTarget,
    individualTarget: data?.individualTarget,
  };
};

const assembleSingleTranslation = (data = {}, language, removeId, isPlain) => {
  let idField = {};
  if (!removeId && data?.id) {
    idField = { id: data.id };
  };
  return {
    ...idField,
    language,
    name: data.name || '',
    detailSectionTitle: data.descriptionTitle || '',
    detailSectionContent: isPlain ? '' : data.descriptionContent || '',
    plainDescriptionContent: isPlain ? data.descriptionContent || '' : '',
    habitualProgressUnit: data.progressUnit || '',
    missionReminderEligible: data.reminderEligible,
    missionReminderIneligible: data.reminderIneligible,
    missionReminderComplete: data.reminderComplete,
    missionReminderExpired: data.reminderExpired,
  };
};

const assembleTranslations = (translations = {}, removeId, isPlain) => {
  const traditionalChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.traditionalChinese],
    LanguageConfig.traditionalChinese,
    removeId,
    isPlain,
  );
  const simplifiedChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.simplifiedChinese],
    LanguageConfig.simplifiedChinese,
    removeId,
    isPlain
  );
  return [
    traditionalChinese,
    simplifiedChinese,
  ]
};

const getHabitualTranslation = (data = [], levelGrade, language, removeId) => {
  let idField = {};
  let levelName = '';
  const singleLevel = data.filter((item) => item.levelGrade === levelGrade)[0];
  if (singleLevel) {
    if (!removeId && data?.id) {
      idField = { id: data.id };
    };
    levelName = singleLevel.levelName;
  }
  return {
    ...idField,
    language,
    levelName,
  };
};

const assembleHabitualLevels = (translations = {}, removeId) => {
  const result = [];
  const habitualLevels = translations?.[LanguageConfig.english]?.habitualLevels || [];
  habitualLevels.forEach((item, index) => {
    let idField = {};
    const traditionalChinese = getHabitualTranslation(
      translations?.[LanguageConfig.traditionalChinese]?.habitualLevels,
      item.levelGrade,
      LanguageConfig.traditionalChinese,
      removeId,
    );
    const simplifiedChinese = getHabitualTranslation(
      translations?.[LanguageConfig.simplifiedChinese]?.habitualLevels,
      item.levelGrade,
      LanguageConfig.simplifiedChinese,
      removeId,
    );
    if (!removeId && item?.pk) {
      idField = { id: item.pk };
    };
    result.push({
      ...idField,
      levelGrade: item.levelGrade,
      levelName: item.levelName,
      joinedTimes: item.joinedTimes || null,
      recyclingItems: item.recyclingItems || null,
      numberOfPoints: item.numberOfPoints || null,
      couponQuantity: item.couponTemplate?.pk ? (item.couponQuantity || null) : null,
      couponTemplate: item.couponTemplate?.pk || null,
      translations: [
        traditionalChinese,
        simplifiedChinese,
      ],
    });
  });
  return result;
};

const assembleMission = (detail = {}, removeId = false) => {
  const now = new Date();
  const isHabitual = detail.missionType === MissionType.habitual;
  const isGroup = detail.missionType === MissionType.group;

  let startDate = null;
  let endDate = null;
  if (detail.isAlwaysActivePeriod) {
    startDate = detail.activeStartDate < now ? detail.activeStartDate : now;
  } else {
    startDate = detail.activeStartDate;
    endDate = detail.activeEndDate;
  };
  let displayStartDate = null;
  let displayEndDate = null;
  if (detail.isAlwaysVisiblePeriod) {
    displayStartDate = detail.visibleStartDate < now ? detail.visibleStartDate : now;
  } else {
    displayStartDate = detail.visibleStartDate;
    displayEndDate = detail.visibleEndDate;
  };

  const translations = assembleTranslations(detail.translations, removeId, isHabitual || isGroup);

  let linkToCampaign = null;
  if (!detail.missionEarningType?.value?.value) {
    linkToCampaign = detail.linkedCampaign?.value?.pk || null;
  };

  return {
    name: detail.translations?.[LanguageConfig.english]?.name,
    detailSectionTitle: detail.translations?.[LanguageConfig.english]?.descriptionTitle || '',
    detailSectionContent: isHabitual || isGroup ? '' : detail.translations?.[LanguageConfig.english]?.descriptionContent || '',
    plainDescriptionContent: isHabitual || isGroup ? detail.translations?.[LanguageConfig.english]?.descriptionContent || '' : '',
    startDate,
    endDate,
    displayStartDate,
    displayEndDate,
    iconType: detail.icon?.value?.value,
    triggerPageType: detail.trigger?.value?.value,
    rewardPoints: detail.points || null,
    rewardCouponTemplate: detail.couponTemplate?.pk || null,
    rewardCouponQuantity: detail.couponTemplate?.pk ? (detail.couponQuantity || null) : null,
    translations,
    type: 'EDUCATIONAL',
    category: detail.missionType,
    communityOnly: detail.communityOnly || false,
    community: detail.linkedCommunity?.value?.pk || null,
    linkToCampaign: linkToCampaign,
    joinedTimes: detail.joinedTimes || null,
    recyclingItems: detail.recyclingItems || null,
    habitualProgressUnit: detail.translations?.[LanguageConfig.english]?.progressUnit || '',
    habitualSetLevels: detail.setLevels?.value?.value,
    habitualLevels: assembleHabitualLevels(detail.translations, removeId),
    missionEarningType: detail.missionEarningType?.value?.value || null,
    secondaryTypeLimit:
      detail.missionEarningType?.value?.value == MissionEarningIcon.PHOTO_TAKING.value && detail?.missionItems?.value?.value
        ? detail?.missionItems?.value?.value
        : null,
    totalTarget: detail?.totalTarget,
    individualTarget: detail?.individualTarget,
    missionReminderEligible: detail.translations?.[LanguageConfig.english]?.reminderEligible || '',
    missionReminderIneligible: detail.translations?.[LanguageConfig.english]?.reminderIneligible || '',
    missionReminderComplete: detail.translations?.[LanguageConfig.english]?.reminderComplete || '',
    missionReminderExpired: detail.translations?.[LanguageConfig.english]?.reminderExpired || '',
  };
};

const isEmpty = (value) => {
  return !value || value === '0';
};

const checkStepTwoFields = (data, isBack) => {
  let errorFields = [];
  let errorTab = LanguageConfig.english;
  const isHabitual = data?.missionType === MissionType.habitual;
  const isGroup = data?.missionType === MissionType.group;
  let isRecycling = false;
  if (isHabitual) {
    isRecycling = data?.missionEarningType?.value?.value === MissionEarningIcon.PHOTO_TAKING.value ||
      data?.linkedCampaign?.value?.earningRule?.type === MissionEarningIcon.PHOTO_TAKING.value
  } else if (isGroup) {
    isRecycling = data?.missionEarningType?.value?.value === MissionEarningIcon.PHOTO_TAKING.value
  } else {
    isRecycling = data?.icon?.value?.value === MissionEarningIcon.PHOTO_TAKING.value;
  }
  if (!data?.name) {
    errorFields.push(CreateMissionError.title.name);
  };
  if (isHabitual) {
    const habitualLevels = data?.habitualLevels || [];
    if (!data?.descriptionContent) {
      errorFields.push(CreateMissionError.descriptionContentEmpty.name);
    };
    if (data?.descriptionContent?.length > 145) {
      errorFields.push(`${CreateMissionError.descriptionContent.name}-${LanguageConfig.english}`);
    };
    if (data?.translations?.[LanguageConfig.simplifiedChinese]?.descriptionContent?.length > 72) {
      errorFields.push(`${CreateMissionError.descriptionContent.name}-${LanguageConfig.simplifiedChinese}`);
      errorTab = LanguageConfig.simplifiedChinese;
    };
    if (data?.translations?.[LanguageConfig.traditionalChinese]?.descriptionContent?.length > 72) {
      errorFields.push(`${CreateMissionError.descriptionContent.name}-${LanguageConfig.traditionalChinese}`);
      errorTab = LanguageConfig.traditionalChinese;
    };
    if (data?.missionEarningType?.value?.value === MissionEarningIcon.PHOTO_TAKING.value && !data?.missionItems) {
      errorFields.push(CreateMissionError.missionItems.name);
    };
    if (!data?.setLevels) {
      errorFields.push(CreateMissionError.setLevels.name);
    };
    if (!data?.progressUnit) {
      errorFields.push(CreateMissionError.progressUnit.name);
    };
    habitualLevels.forEach((item, index) => {
      const preLevelData = index > 0 ? habitualLevels[index - 1] : {};
      const recyclingItems = parseInt(item?.recyclingItems)
      const joinedTimes = parseInt(item?.joinedTimes)
      const preRecyclingItems = parseInt(preLevelData?.recyclingItems)
      const preJoinedTimes = parseInt(preLevelData?.joinedTimes)
      if (!item?.levelName) {
        errorFields.push(`level${item.levelGrade}Name`);
      };

      if (isRecycling) {
        if (!recyclingItems || recyclingItems < 1 || recyclingItems <= preRecyclingItems) {
          errorFields.push(`level${item.levelGrade}RecyclingItems`);
        };
      } else {
        if (!joinedTimes || joinedTimes < 1 || joinedTimes <= preJoinedTimes) {
          errorFields.push(`level${item.levelGrade}JoinedTimes`);
        };
      };
    })
  } else if (isGroup) {
    if (data?.communityOnly && !data?.linkedCommunity?.value?.pk) {
      errorFields.push(CreateMissionError.linkedCommunity.name);
    }
    if (!data?.missionEarningType?.value?.value && !data?.linkedCampaign?.value?.pk) {
      errorFields.push(CreateMissionError.groupMissionEarningTypeOrLinkedCampaign.name);
    };
    if (isRecycling && !data?.missionItems) {
      errorFields.push(CreateMissionError.missionItems.name);
    };
    if (!data?.descriptionContent) {
      errorFields.push(CreateMissionError.descriptionContentEmpty.name);
    };
    if (data?.descriptionContent?.length > 130) {
      errorFields.push(`${CreateMissionError.groupDescriptionContent.name}-${LanguageConfig.english}`);
    };
    if (data?.translations?.[LanguageConfig.simplifiedChinese]?.descriptionContent?.length > 70) {
      errorFields.push(`${CreateMissionError.groupDescriptionContent.name}-${LanguageConfig.simplifiedChinese}`);
      errorTab = LanguageConfig.simplifiedChinese;
    };
    if (data?.translations?.[LanguageConfig.traditionalChinese]?.descriptionContent?.length > 70) {
      errorFields.push(`${CreateMissionError.groupDescriptionContent.name}-${LanguageConfig.traditionalChinese}`);
      errorTab = LanguageConfig.traditionalChinese;
    };
    if (!data?.totalTarget || data?.totalTarget < 10) {
      errorFields.push(CreateMissionError.totalTarget.name);
    };
    if (!data?.individualTarget || data?.individualTarget < 1) {
      errorFields.push(CreateMissionError.individualTarget.name);
    };
    groupMissionReminder.forEach(item => {
      const reminder = data?.[item];
      if (!reminder) {
        errorFields.push(CreateMissionError?.[item].name);
      };
      if (reminder?.length > 44) {
        errorFields.push(`${CreateMissionError?.[`${item}Max`].name}-${LanguageConfig.english}`);
      };
      if (data?.translations?.[LanguageConfig.simplifiedChinese]?.[item]?.length > 22) {
        errorFields.push(`${CreateMissionError?.[`${item}Max`].name}-${LanguageConfig.simplifiedChinese}`);
        errorTab = LanguageConfig.simplifiedChinese;
      };
      if (data?.translations?.[LanguageConfig.traditionalChinese]?.[item]?.length > 22) {
        errorFields.push(`${CreateMissionError?.[`${item}Max`].name}-${LanguageConfig.traditionalChinese}`);
        errorTab = LanguageConfig.traditionalChinese;
      };
    })
  } else if (data?.missionType === MissionType.onboardingMission) {
    if (!data?.icon) {
      errorFields.push(CreateMissionError.icon.name);
    };
    if (!data?.trigger) {
      errorFields.push(CreateMissionError.triggerPage.name);
    };
  } else if (data?.missionType === MissionType.campaignLinked) {
    if (!data?.icon) {
      errorFields.push(CreateMissionError.icon.name);
    };
    if (!data?.linkedCampaign) {
      errorFields.push(CreateMissionError.linkedCampaign.name);
    };
    const recyclingItems = parseInt(data?.recyclingItems)
    const joinedTimes = parseInt(data?.joinedTimes)
    if (isRecycling) {
      if (!recyclingItems || recyclingItems < 1) {
        errorFields.push(CreateMissionError.recyclingItems.name);
      };
    } else {
      if (!joinedTimes || joinedTimes < 1) {
        errorFields.push(CreateMissionError.joinedTimes.name);
      };
    };
  };
  return {
    data,
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    errorTab,
  };
};

const checkStepThreeFields = (data, isBack) => {
  let errorFields = [];
  if (
    !data?.isAlwaysActivePeriod &&
    data?.activeStartDate.getTime() >= data?.activeEndDate.getTime()
  ) {
    errorFields.push(CreateMissionError.activeEndDate.name);
  };
  if (
    !data?.isAlwaysVisiblePeriod &&
    data?.visibleStartDate.getTime() >= data?.visibleEndDate.getTime()
  ) {
    errorFields.push(CreateMissionError.visibleEndDate.name);
  };

  if (data?.isHabitual) {
    if (data?.habitualLevels && data?.habitualLevels.length) {
      data.habitualLevels.forEach((item, index) => {
        if (
          isEmpty(item?.numberOfPoints) &&
          (isEmpty(item?.couponQuantity) || !item?.couponTemplate?.pk)
        ) {
          errorFields.push(`level${item.levelGrade}Rewards`);
        };

        if (item?.numberOfPoints && item?.numberOfPoints < 0) {
          errorFields.push(`level${item.levelGrade}NumberOfPoints`);
        };
        if ((item?.couponQuantity && item?.couponQuantity < 0) || (isEmpty(item?.couponQuantity) && data?.couponTemplate?.pk)) {
          errorFields.push(`level${item.levelGrade}CouponQuantity`);
        };
      })
    }
  } else {
    if (
      isEmpty(data?.points) &&
      (isEmpty(data?.couponQuantity) || !data?.couponTemplate?.pk)
    ) {
      errorFields.push(CreateMissionError.rewards.name);
    };

    if (data?.points && data?.points < 0) {
      errorFields.push(CreateMissionError.points.name);
    };
    if ((data?.couponQuantity && data?.couponQuantity < 0) || (isEmpty(data?.couponQuantity) && data?.couponTemplate?.pk)) {
      errorFields.push(CreateMissionError.couponQuantity.name);
    };
  };

  return {
    data,
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
  };
};


export default {
  namespace: 'mission',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateMissionDetail(state, { payload }) {
      const missionDetail = {
        ...state.missionDetail,
        ...payload,
      };
      console.log("@@saveToSessionStorage, updateMissionDetail")
      saveToSessionStorage(sessionDataKey.objectKey, missionDetail);
      return {
        ...state,
        missionDetail,
      }
    },
    updateMissionDetailTranslations(state, { payload }) {
      const missionDetail = {
        ...state.missionDetail,
        translations: {
          ...state.missionDetail.translations,
          [payload.language]: {
            ...state.missionDetail.translations[payload.language],
            ...payload,
          }
        }
      };
      console.log("@@saveToSessionStorage, updateMissionDetailTranslations")
      saveToSessionStorage(sessionDataKey.objectKey, missionDetail);
      return {
        ...state,
        missionDetail
      }
    },
    clearState(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      const data = payload.data;
      const fromCommunity = payload?.fromCommunity;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {}, errorTab: LanguageConfig.english };
      if (step === 1) {
        result = checkStepTwoFields(data, isBack);
      }

      if (step === 2) {
        result = checkStepThreeFields(data, isBack);
      }
      const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );

      let communityTargetStepConfig = defaultStep(communityTargetStepNames);
      if (fromCommunity) {
        communityTargetStepConfig = getNewStepConfig(
          step - 1,
          state.communityTargetStepConfig,
          result.invalid,
          isBack,
        );
      }

      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }

      return {
        ...state,
        currentStep: fromCommunity ? step - 1 : step,
        stepConfig: stepConfig,
        communityTargetStepNames: communityTargetStepConfig,
        errorFields: errorFields,
        errorTab: result.errorTab,
      };
    },
    saveOrRemoveMissionFromCookie(state, { payload }) {
      console.log("@@saveOrRemoveMissionFromCookie", payload)
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

  },
  effects: {
    loadMissionFromCookie: [
      function* ({ payload }, { put, select }) {
        const fromCommunity = payload?.fromCommunity || false;
        const communityId = payload?.communityId || null;
        const mission = getObjectFromSessionStorage(sessionDataKey.objectKey);
        console.log("@@1057", mission);

        if (mission && !!mission?.communityOnly == fromCommunity) {
          yield put({
            type: 'updateState',
            payload: {
              missionDetail: mission,
            }
          })
        }

        if (fromCommunity) {
          yield put({
            type: 'updateMissionDetail',
            payload: {
              missionType: MissionType.group,
              communityOnly: true
            },
          });
        }

        if (fromCommunity && communityId) {
          yield put.resolve({
            type: 'communityModel/getObject',
            payload: { id: communityId },
          })

          const { communityDetail } = yield select((state) => ({
            communityDetail: state.communityModel.oneObject,
          }));

          yield put({
            type: 'updateMissionDetail',
            payload: {
              linkedCommunity: {
                label: communityDetail?.name?.en || '',
                value: communityDetail
              }
            },
          });

        }
      }
    ],

    getCurrentPageMissions: [
      function* ({ payload }, { put }) {
        const { page, searchKey, sort } = payload;

        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getMissions,
          {
            afterCursor,
            order: sort,
            search: searchKey,
            others: payload,
          }
        ];
        function* onSuccess(data) {
          const pageInfo = data.missions.pageInfo;
          const totalCount = data.missions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const missionData = data.missions.edges;
          const missionList = missionData.map((item) =>
            parseMission(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPageMissionList: missionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              totalCount,
              currentLastCursor,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getMission: [
      function* ({ payload }, { put, select }) {
        const { id } = payload;
        const missionID = btoa(`MissionNode:${id}`);
        const serviceArgs = [getMission, missionID];

        yield put(
          createAction('updateState')({
            missionAPIStatus: APIStatus.calling,
          }),
        );

        function* onSuccess(data) {
          const missionData = data.mission;
          const mission = parseMission(missionData);
          yield put(
            createAction('updateState')({
              missionDetail: mission,
              missionAPIStatus: APIStatus.success,
            }),
          );

          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { put, select }) {
        const serviceArgs = [deleteMissions, payload?.deletePks];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { call, select, put }) {
        yield put.resolve({ type: 'clearState' });
        const id = payload.id;
        const afterAction = payload.afterAction || (() => { });
        yield put.resolve({
          type: 'getMission',
          payload: { id },
        });
        const { englishName, missionType } = yield select(
          (state) => ({
            englishName: state.mission.missionDetail.translations?.[LanguageConfig.english]?.name,
            missionType: state.mission.missionDetail?.missionType
          })
        );
        const isHabitual = missionType == MissionType.habitual;
        const isGroup = missionType == MissionType.group;
        const copyOfName = `Copy of ${englishName}`;
        let maxLength = 23;
        if (isHabitual) {
          maxLength = 56;
        } else if (isGroup) {
          maxLength = 70;
        };
        if (copyOfName?.length > maxLength) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: `Please make sure the mission name cannot exceed ${maxLength} characters`,
              },
            },
          });
          afterAction();
          return;
        }

        yield put.resolve({
          type: 'updateMissionDetailTranslations',
          payload: { language: LanguageConfig.english, name: copyOfName },
        });
        yield put.resolve({
          type: 'createOrUpdateMission',
          payload: { removeId: true }
        });
        afterAction();
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateMission: [
      function* ({ payload }, { put, select }) {
        const id = payload?.id || null;
        const { missionDetail } = yield select((state) => ({
          missionDetail: state.mission.missionDetail,
        }));
        const newMissionDetail = assembleMission(missionDetail, payload?.removeId);
        if (!payload?.removeId && id) {
          newMissionDetail.id = id;
        };

        const serviceArgs = [id ? updateMission : createMission, newMissionDetail];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(successData) {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put(
            createAction('updateState')({
              missionCreated: true,
            }),
          );
          if (payload.hasPublishOrUnpublishAction && successData) {
            const missionNode = successData?.createMission?.node || successData?.updateMission?.node;
            yield put(
              createAction('publishOrUnpublishMission')({
                id: missionNode?.pk,
                publish: !missionNode?.isPublished,
              }),
            );
          };
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
    ],
    publishOrUnpublishMission: [
      function* ({ payload }, { put }) {
        const serviceArgs = [payload?.publish ? publishMission : unPublishMission, { id: payload.id }];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteCommunityTarget: [
      function* ({ payload }, { put, select }) {
        const deletePks = payload?.deletePks;
        const serviceArgs = [unLinkCommunity, { ids: deletePks }];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
