import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { TRANSACTION_RECORD_TYPE } from '../containers/record/records/TransactionList';
import {
  createTransaction,
  updateTransaction,
  updateTransactionItems,
  createTransactions
} from '../services/TransactionRecordsAPIHelper';
import { loading } from './LoadingUtil';
import {
  createAction,
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
} from '../utils';
import { APIStatus, LanguageConfig } from '../config/CustomEnums';
import { GeneralPurchaseSubType } from './CreateEarningRulesModel';
import { parsePurchaseItems } from './TransactionModel';

export const sessionDataKey = {
  objectKey: 'createTransaction',
  stepEndKey: 'createTransactionStepEnd',
  origionalData: 'createTransactionOriginalData',
};

const oclDateRegex = /^\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}$/

export const CreateTransactionError = {
  customer: {
    name: 'customer',
    message: 'Please provide a customer.',
  },
  customerLeast: {
    name: 'customerLeast',
    message: 'Please select at least one customer / target customer group / segment.',
  },
  transactionDate: {
    name: 'transactionDate',
    message: 'Please provide a transaction date.',
  },
  oclDate: {
    name: 'oclDate',
    message: 'Incorrect format',
  },
  store: {
    name: 'store', //optional when type is POS
    message: 'Please provide a store.',
  },
  posInvoiceId: {
    name: 'posInvoiceID',
    message: 'Please provide a pos invoice id.',
  },
  invoiceId: {
    name: 'invoiceID',
    message: 'Please provide a invoicd id.',
  },
  skuItems: {
    name: 'skuItems',
    message: 'Please provide correct skus',
  },
  offlineEventType: {
    name: 'offlineEventType',
    message: 'Please provide a offline event type.',
  },
  relatedTransactionType: {
    name: 'relatedTransactionType',
    message: 'Please provide a related transaction type.',
  },
  staffName: {
    name: 'staffName',
    message: 'Please provide a staff name.',
  },
  totalValue: {
    name: 'totalValue',
    message: 'Please provide a total value.',
  },
  totalValueFormat: {
    name: 'totalValueFormat',
    messages: 'Please enter total value in digits.',
  },
  purchasedItmeName: {
    name: 'purchasedItemName',
    message: 'Please provide a product name.',
  },
  purchasedItemQuantity: {
    name: 'purchasedItemQuanitity',
    message: 'Please provide a quantity.',
  },
  purchasedItemQuantityFormat: {
    name: 'purchasedItemQuantityFormat',
    message: 'Please enter quantity in digits.',
  },
  purchasedItemValue: {
    name: 'purchasedItemValue',
    message: 'Please provide a value.',
  },
  purchasedItemValueFormat: {
    name: 'purchasedItemValueFormat',
    message: 'Please enter value in digits.',
  },
  receiptImage: {
    name: 'receiptImage',
    message: 'Please provide a receipt image.',
  },
  creditCardSlipImage: {
    name: 'creditCardSlipImage',
    message: 'Please provide a credit card slip image.',
  },

  recylingImage: {
    name: 'recylingImage',
    message: 'Please provide a recyling image.',
  },
  recylingItemsLess: {
    name: 'recylingItemsLess',
    message: 'Please provide at least 1 recyling item.',
  },
  recylingItemsMore: {
    name: 'recylingItemsMore',
    message: 'Recyling items cannot more than 3 items.',
  },
  recylingItemAmount: {
    name: 'recylingItemAmount',
    message: 'Please provide recyling item amount.',
  },
  recylingTotalAmountLimit: {
    name: 'recylingTotalAmountLimit',
    message: 'Recyling item amount cannot more than xxx.',
  },
  recylingTotalAmountMiss: {
    name: 'recylingTotalAmountMiss',
    message: 'The store not related to campaign, please re-select store',
  },
  merchantName: {
    name: 'merchantName',
    message: 'Please provide merchant name',
  },
  waterFillingMachineId: {
    name: 'waterFillingMachineId',
    message: 'Please fill in either Machine id or Related campaign.',
  },
  invalidMachineId: {
    name: 'invalidMachineId',
    message:
      'Invalid machine ID. We did not find a earning rule that matches this machine ID. Please check and try again.',
  },
  rewardCarbonValue: {
    name: 'rewardCarbonValue',
    message: 'Please provide a positive value.'
  },
  rewardPointValue: {
    name: 'rewardPointValue',
    message: 'Please provide a positive value.'
  },
  paymentMethod: {
    name: 'paymentMethod',
    message: 'Please choose a payment method.'
  },
  transactionAmountValue: {
    name: 'TransactionAmountValue',
    message: 'Please provide a valid transaction amount value.'
  },
  transactionCampaign: {
    name: 'transactionCampaign',
    message: 'Please provide a campaign related to the merchant name',
  },
  transactionTotalValue: {
    name: 'totalValue',
    message: 'Please provide a correct number.'
  }
};

export const TRANSACTION_RELATED_TYPE = {
  RECYCLING: {
    label: 'Recycling',
    value: 'RECYCLING'
  },
  WATER_FILLING: {
    label: 'Water filling',
    value: 'WATER_FILLING'
  },
  GREEN_DINING: {
    label: 'General purchase',
    value: 'GREEN_DINING'
  },
  OTHERS: {
    label: 'Others',
    value: 'OTHERS'
  },
};

export const TRANSACTION_REWARD_TYPE = {
  CARBON_ONLY: {
    label: "Carbon Savings only",
    value: "CARBON_ONLY",
  },
  POINT_ONLY: {
    label: "CW Point(s) only",
    value: "POINT_ONLY",
  },
  BOTH: {
    label: "Both Carbon Savings and CW Point(s)",
    value: "BOTH",
  },
}

const getTranslation = () => {
  return {
    smallHeading: '',
  }
}

function getTransactionInit() {
  return {
    pk: null,
    id: null,
    ssoUid: null,
    name: null,
    transactionType: null,
    transactionDisplayType: null,
    merchantName: null,
    storeName: null,
    creationDate: null,
    transactionDate: null,
    oclDate: null,
    transactionDetailDisplayDate: null,
    transactionDetailCreationDate: null,
    totalValue: null,
    onlineEventType: null,
    offlineEventType: null,
    displayOfflineEventType: null,
    staffName: null,
    shippingFee: null,
    otherCharge: null,
    remarks: null,
    posInvoiceId: null,
    invoiceId: null,
    receiptImage: null,
    creditCardSlipImage: null,
    purchasedItems: [
      {
        productName: null,
        sku: null,
        category: null,
        brand: null,
        quantity: null,
        value: null,
      },
    ],
    customer: {},
    campaign: {},
    store: {},
    customerGroup: [],
    customerSegment: [],
    relatedTransactionType: null,
    rewardType: null,
    rewardCarbonValue: null,
    rewardPointValue: null,
    translations: {
      [LanguageConfig.english]: getTranslation(),
      [LanguageConfig.traditionalChinese]: getTranslation(),
      [LanguageConfig.simplifiedChinese]: getTranslation(),
    },
  };
}

export const stepNameList = ['Type', 'Content', 'Purchase Detail'];
export const adminCreateStepNameList = ['Type', 'Content', 'Purchase Detail', 'Preview'];

function getInitState() {
  return {
    transaction: getTransactionInit(),
    errorFields: [],
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    selectedType: null,
    loadingStatus: APIStatus.none,
    transactionStatusChanged: false,
  };
}

function checkStepTwo(data, isBack) {
  const selectedCustomer = data.customer;
  const transactionDisplayType = data.transactionDisplayType;
  let errorFields = [];
  if (!(transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) && !selectedCustomer?.pk) {
    errorFields.push(CreateTransactionError.customer.name);
  }
  if (!data.transactionDate) {
    errorFields.push(CreateTransactionError.transactionDate.name);
  }
  if (
    !data.store?.storePK &&
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING
  ) {
    errorFields.push(CreateTransactionError.store.name);
  }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS &&
    !data.posInvoiceId
  ) {
    errorFields.push(CreateTransactionError.posInvoiceId.name);
  }
  // if (
  //   transactionDisplayType ===
  //     TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM &&
  //   !data.invoiceId
  // ) {
  //   errorFields.push(CreateTransactionError.invoiceId.name);
  // }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS &&
    !data.offlineEventType
  ) {
    errorFields.push(CreateTransactionError.offlineEventType.name);
  }
  // if (!data.staffName) {
  //   errorFields.push(CreateTransactionError.staffName.name);
  // }
  // if (!data.totalValue || data.totalValue === '') {
  //   errorFields.push(CreateTransactionError.totalValue.name);
  // }
  // if (data.totalValue && !parseInt(data.totalValue)) {
  //   errorFields.push(CreateTransactionError.totalValueFormat.name);
  // }
  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) {
    if (!selectedCustomer?.pk && !data?.customerGroup?.length && !data?.customerSegment?.length) {
      errorFields.push(CreateTransactionError.customerLeast.name)
    };
    if (!data.relatedTransactionType) {
      errorFields.push(CreateTransactionError.relatedTransactionType.name);
    };
  };
  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
    if (data?.oclDate && !oclDateRegex.test(data?.oclDate)) {
      errorFields.push(CreateTransactionError.oclDate.name);
    }
  }
  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data,
  };
}

function checkStepThree(data, isBack, selectStoreCampaign) {
  const transactionDisplayType = data.transactionDisplayType;
  let errorFields = [];
  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    const purchasedItems = data.purchasedItems;
    purchasedItems.forEach((item, index) => {
      if (!item.productName) {
        errorFields.push(
          `${CreateTransactionError.purchasedItmeName.name}-${index}`,
        );
      }
      if (!item.quantity) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemQuantity.name}-${index}`,
        );
      }
      if (item.quantity && !parseInt(item.quantity)) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemQuantityFormat.name}-${index}`,
        );
      }
      if (!item.value) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemValue.name}-${index}`,
        );
      }
      if (item.value && !parseInt(item.value)) {
        errorFields.push(
          `${CreateTransactionError.purchasedItemValueFormat.name}-${index}`,
        );
      }
    });
  } else if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING
  ) {
    const recylingImage = data.recylingImage;
    if (!recylingImage) {
      errorFields.push(CreateTransactionError.recylingImage.name);
    }

    const recylingItems = data.recylingItems;
    if (selectStoreCampaign) {
      if (Object.keys(recylingItems || {}).length <= 0) {
        errorFields.push(CreateTransactionError.recylingItemsLess.name);
      } else if (Object.keys(recylingItems || {}).length > 3) {
        errorFields.push(CreateTransactionError.recylingItemsMore.name);
      } else {
        let totalAmount = 0;
        Object.keys(recylingItems).forEach((key) => {
          if (!recylingItems[key].amount) {
            errorFields.push(
              CreateTransactionError.recylingItemAmount.name +
                recylingItems[key].pk,
            );
          } else {
            totalAmount += parseInt(recylingItems[key].amount);
          }
          // else if (recylingItems[key].amount > 15) {
          //   errorFields.push(
          //     CreateTransactionError.recylingItemAmountMore.name +
          //       recylingItems[key].pk,
          //   );
          // }
        });

        if (
          totalAmount >
          (selectStoreCampaign.earningRule
            ?.photoTakingTypePerHeadPerDayTotalQuantityLimit || 0)
        ) {
          errorFields.push(
            CreateTransactionError.recylingTotalAmountLimit.name,
          );
        }

        // errorFields.push(CreateTransactionError.recylingItemAmountMore.name);
      }
    } else {
      errorFields.push(CreateTransactionError.recylingTotalAmountMiss.name);
    }
    console.log('@@295: ', errorFields);
    // if (!receiptImage) {
    //   errorFields.push(CreateTransactionError.receiptImage.name);
    // }
    // if (!data.creditCardSlipImage) {
    //   errorFields.push(CreateTransactionError.creditCardSlipImage.name);
    // }
  } else if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
  ) {
    const receiptImage = data.receiptImage;
    if (!receiptImage) {
      errorFields.push(CreateTransactionError.receiptImage.name);
    }
    if (!data.merchantName) {
      errorFields.push(CreateTransactionError.merchantName.name);
    }
    if (!data.totalValue || isNaN(data.totalValue) || data.totalValue < 0) {
      errorFields.push(CreateTransactionError.transactionAmountValue.name);
    }
    if (!data.paymentMethod) {
      errorFields.push(CreateTransactionError.paymentMethod.name);
    }
    if (!data.campaign?.pk) {
      errorFields.push(CreateTransactionError.transactionCampaign.name);
    }
  } else if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING
  ) {
    if (!data.machineId && !data.campaign) {
      errorFields.push(CreateTransactionError.waterFillingMachineId.name);
    }
  } else if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE
  ) {
    if ((!data.rewardType 
      || data.rewardType === TRANSACTION_REWARD_TYPE.CARBON_ONLY.value
      || data.rewardType === TRANSACTION_REWARD_TYPE.BOTH.value)
      && (!data.rewardCarbonValue || parseInt(data.rewardCarbonValue) <= 0)
    ) {
      errorFields.push(CreateTransactionError.rewardCarbonValue.name)
    }
    if ((data.rewardType === TRANSACTION_REWARD_TYPE.POINT_ONLY.value
      || data.rewardType === TRANSACTION_REWARD_TYPE.BOTH.value)
      && (!data.rewardPointValue || parseInt(data.rewardPointValue) <= 0)
    ) {
      errorFields.push(CreateTransactionError.rewardPointValue.name)
    }
  }
  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data,
  };
}

function parsePhotoUrlForAPI(image) {
  let imageUrl = image;
  if (image && image.value) {
    imageUrl = image.value;
  }
  return getFileNameFromUrl(imageUrl);
}

function parseCreateTransactionInputBody(data, isUpdate = false) {
  const receiptImage = data.receiptImage;
  const creditCardSlipImage = data.creditCardSlipImage;
  const transactionType = data.transactionDisplayType;
  let inputBody = {
    date: data.transactionDate,
    offlineEventType: data.offlineEventType,
    staffName: data.staffName,
    customer: data.customer?.pk,
    // totalValue: data.totalValue || 0,
    store: data.store.storePK,
    remarks: data.remarks,
  };
  if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    inputBody = {
      ...inputBody,
      posInvoiceId: data.posInvoiceId,
    };
  } else if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING) {
    inputBody = {
      ...inputBody,
      invoiceId: data.invoiceId,

      receiptImage: parsePhotoUrlForAPI(data.recylingImage),
      // creditCardSlipImage: parsePhotoUrlForAPI(creditCardSlipImage),
    };
  } else if (
    transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
  ) {
    inputBody = {
      ...inputBody,
      merchantName: data.merchantName.value,
      totalValue: data.totalValue,
      generalPurchasePaymentMethod: data.paymentMethod,
      // generalPurchaseSubType: data.generalPurchaseSubType || GeneralPurchaseSubType.GREEN_DINING.value,
      receiptImage: parsePhotoUrlForAPI(receiptImage),
      oclDate: data.oclDate ? new Date(data.oclDate) : null,
      campaign: data.campaign?.pk,
    };
  } else if (
    transactionType === TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING
  ) {
    inputBody = {
      ...inputBody,
      invoiceId: data.machineId,
      campaign: data.campaign?.pk,
      waterVolume: data.waterVolume,
    };
  }
  if (!isUpdate) {
    inputBody = {
      ...inputBody,
      transactionType: data.transactionType,
    };
  }
  if (isUpdate) {
    inputBody = {
      ...inputBody,
      id: data.pk,
    };
  }
  console.log('@@288: ', data, inputBody);
  return inputBody;
}

function parsePurchasedItemInputBody(data, transactionPK) {
  const inputBody = {
    // transaction: transactionPK,
    name: data.productName,
    sku: data.sku,
    category: data.category,
    brand: data.brand,
    quantity: data.quantity,
    value: data.value,
    id: data.pk,
  };
  return inputBody;
}

function parseBarcodeItemInputBody(data, barcode) {
  const inputBody = {
    storeSubcategory: data.pk,
    quantity: data.amount,
  };
  if(barcode) {
    inputBody.barcode = barcode.pk
  }
  return inputBody;
}

function parsePhotoTakingItemInputBody(data, transactionPK) {
  const inputBody = {
    // transaction: transactionPK,
    name: data.name,
    storeSubcategory: data.pk,
    quantity: data.amount,
  };
  return inputBody;
}

function getTranslationDataForCreateTransaction(transaction, language, update=false) {
  let languageField = {};
  const translation = transaction?.translations?.[language] || {};
  if (language !== LanguageConfig.english) {
    languageField = {
      language: language,
    };
    if (translation.pk && update) {
      languageField['id'] = translation.pk;
    }
  }

  const result = {
    ...languageField,
    smallHeading: translation?.smallHeading,
  };
  return result;
}

function parseAdminCreateItems(transaction) {
  const ENTranslation = getTranslationDataForCreateTransaction(
    transaction,
    LanguageConfig.english
  );
  const TCTranslation = getTranslationDataForCreateTransaction(
    transaction,
    LanguageConfig.traditionalChinese
  );
  const SCTranslation = getTranslationDataForCreateTransaction(
    transaction,
    LanguageConfig.simplifiedChinese
  );
  let generalPurchaseSubType = transaction?.generalPurchaseSubType || GeneralPurchaseSubType.GREEN_DINING.value;
  if (transaction?.relatedTransactionType?.value != TRANSACTION_RELATED_TYPE.GREEN_DINING.value) {
    generalPurchaseSubType = null;
  }
  const inputBody = {
    ...ENTranslation,
    customerGroup: transaction?.customerGroup?.map(item => item.pk) || [],
    customerSegment: transaction?.customerSegment?.map(item => item.pk) || [],
    relatedTransactionType: transaction?.relatedTransactionType?.value,
    generalPurchaseSubType: generalPurchaseSubType,
    rewardType: transaction?.rewardType || TRANSACTION_REWARD_TYPE.CARBON_ONLY.value,
    rewardCarbonValue: transaction?.rewardType == TRANSACTION_REWARD_TYPE.POINT_ONLY.value 
      ? null 
      : transaction?.rewardCarbonValue,
    rewardPointValue: (transaction?.rewardType == TRANSACTION_REWARD_TYPE.CARBON_ONLY.value || !transaction?.rewardType)
      ? null
      : transaction?.rewardPointValue,
    translations: [TCTranslation, SCTranslation],
  }
  return inputBody;
}

export default {
  namespace: 'createTransaction',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateTransactionState(state, { payload }) {
      const oldTransaction = state.transaction;
      const language = payload.language;
      let transaction = {};
      if (language) {
        delete payload.language;
        transaction = {
          ...oldTransaction,
          translations: {
            ...oldTransaction.translations,
            [language]: {
              ...oldTransaction.translations[language],
              ...payload,
            },
          },
        };
      } else {
        transaction = { ...oldTransaction, ...payload };
      }
      console.log('@@433: ', transaction);
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    updateTransactionType(state, { payload }) {
      const { type } = payload;
      let originalType = null;
      if (type === TRANSACTION_RECORD_TYPE.TYPE_ONLINE) {
        originalType = 'ONLINE';
      } else if (type === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
        originalType = 'OFFLINE_POS';
      } else if (type === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
        originalType = 'OFFLINE_REWARD_CLAIM';
      } else if (type === TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING) {
        originalType = 'PHOTO_TAKING';
      } else if (type === TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING) {
        originalType = 'QR_CODE_SCANNING';
      } else if (type === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) {
        originalType = 'ADMIN_CREATE';
      }
      const oldType = state.transaction.transactionDisplayType;
      if (type === oldType) {
        return {
          ...state,
        };
      }
      let transaction = getObjectFromSessionStorage('transactionDisplayType');
      if (!transaction) {
        transaction = getInitState().transaction;
      }
      transaction.transactionDisplayType = type;
      transaction.transactionType = originalType;
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    loadTransactionFromCookie(state, { payload }) {
      const transaction = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!transaction) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, transaction);
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    saveOrRemoveTransactionFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    addPurchasedItem(state, { payload }) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [...state.transaction.purchasedItems, { ...payload }],
        },
      };
    },
    updatePruchasedItems(state, { payload }) {
      const { index, data } = payload;
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [
            ...state.transaction.purchasedItems.slice(0, index),
            {
              ...state.transaction.purchasedItems[index],
              ...data,
            },
            ...state.transaction.purchasedItems.slice(index + 1),
          ],
        },
      };
    },
    changeRecylingRecords(state, { payload }) {
      const { amount, item, deleted } = payload;
      let recylingItems = {};
      let removedRecylings = [];
      if (deleted && state.transaction.recylingItems) {
        delete state.transaction.recylingItems[item.pk];
        removedRecylings.push(item.pk);
      } else if (item.pk) {
        recylingItems[item.pk] = { ...item, amount };
      }
      const transaction = {
        ...state.transaction,
        recylingItems: {
          ...state.transaction.recylingItems,
          ...recylingItems,
        },
        removedRecylings: [
          ...(state.transaction.removedRecylings || []),
          ...removedRecylings,
        ],
      };
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      // console.log('@@407: ', recylingItems, payload);
      return {
        ...state,
        transaction,
      };
    },
    changeTransactionDate(state, { payload }) {
      const editDate = payload?.value;
      const transaction = {
        ...state.transaction,
        editDate,
      };
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction,
      };
    },
    changeTotalValue(state, { payload }) {
      const editTotalValue = payload?.value;
      const transaction = {
        ...state.transaction,
        editTotalValue,
      };
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction,
      };
    },
    updateEditTransaction(state, { payload }) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          ...payload,
        }
      }
    },
    changeSkuItems(state, { payload }) {
      const { sku, index, deleted } = payload;
      const skuItems = [...(state.transaction.skuItems || [])];
      if (deleted && skuItems.length > index) {
        skuItems.splice(index, 1);
      }
      if (!deleted) {
        skuItems[index] = sku;
      }
      const transaction = {
        ...state.transaction,
        skuItems,
      };
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction,
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {} };
      if (step === 1) {
        result = checkStepTwo(state.transaction, isBack);
      }
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );
      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        stepConfig,
        currentStep: step,
        errorFields: result.errorFields,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
    clearDetailFields(state, { payload }) {
      return {
        ...state,
        errorFields: [],
        editDateError: null,
        editTotalValueError: null,
        transaction: {
          ...state.transaction,
          editDate: null,
          editTotalValue: null,
        }
      }
    },
  },

  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldTransaction = yield select(
        (state) => state.createTransaction.transaction,
      );
      const transaction = { ...oldTransaction, ...payload };

      saveToSessionStorage(sessionDataKey.objectKey, transaction);
    },

    checkStepThree: [
      function* ({ payload }, { put, select }) {
        const isBack = payload.isBack;
        const afterActions = payload.afterActions || (() => {});
        const transaction = yield select(
          (state) => state.createTransaction.transaction,
        );
        const stepConfig = yield select(
          (state) => state.createTransaction.stepConfig,
        );
        const selectStoreCampaign = yield select(
          (state) => state.campaignList.allCampaignList,
        );

        const result = checkStepThree(
          transaction,
          isBack,
          selectStoreCampaign ? selectStoreCampaign[0] : null,
        );
        const newStepConfig = getNewStepConfig(
          2,
          stepConfig,
          result.invalid,
          isBack,
        );
        yield put(
          createAction('updateState')({
            stepConfig: newStepConfig,
            errorFields: result.errorFields,
          }),
        );
        if (!result.invalid) {
          yield afterActions();
        }
      },
      { type: 'takeLatest' },
    ],
    createTransaction: [
      function* ({ payload }, { call, select, put }) {
        const transaction = yield select(
          (state) => state.createTransaction.transaction,
        );

        const data = parseCreateTransactionInputBody(transaction);
        if (
          transaction.transactionDisplayType ===
          TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
        ) {
          const purchasedItems = yield select(
            (state) => state.createTransaction.transaction.purchasedItems,
          );

          data.purchasedItemsInput = purchasedItems.map((item) => {
            return parsePurchasedItemInputBody(item);
          });
        }

        if (
          transaction.transactionDisplayType ===
          TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING
        ) {
          const recylingItems = yield select(
            (state) => state.createTransaction.transaction.recylingItems,
          );

          data.photoTakingItemsInput = Object.values(recylingItems).map(
            (item) => {
              return parsePhotoTakingItemInputBody(item);
            },
          );

          data.barcodeItemsInput = Object.values(recylingItems).map(
            (item) => {
              return parseBarcodeItemInputBody(item, transaction.barcode);
            },
          );
        }
        if (
          transaction.transactionDisplayType ===
          TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
        ) {
          const skuItems = yield select(
            (state) => state.createTransaction.transaction.skuItems,
          );
          const skuApiItems = [];
          (skuItems || []).forEach((sku) => {
            if (sku && sku.length > 0) {
              skuApiItems.push({ sku });
            }
          });
          data.purchasedItemsInput = skuApiItems;
        }
        if (
          transaction.transactionDisplayType ===
          TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE
        ) {
          const adminCreateItems = parseAdminCreateItems(transaction);
          Object.keys(adminCreateItems || {}).forEach(item => {
            data[item] = adminCreateItems[item];
          })
        };
        if(transaction.barcode) {
          data.transactionType = "BARCODE"
        }
        let serviceArgs = [createTransaction, data];
        if(transaction.transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) {
          serviceArgs = [createTransactions, data];
        }

        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onSuccess(data) {
          const transactionData = data.createTransaction?.node;
          yield put(
            createAction('updateTransactionState')({
              pk: transactionData?.pk,
              id: transactionData?.id,
            }),
          );

          yield put({
            type: 'updateState',
            payload: { 
              transactionStatusChanged: true,
              loadingStatus: APIStatus.success,
            },
          });
          removeFromSessionStorage(sessionDataKey.objectKey);
        }

        function* onError(error) {
          console.log('createTransactionModel onError :', error);
          yield put({
            type: 'updateState',
            payload: { loadingStatus: APIStatus.failed },
          });
        }

        function* onArgumentsError(error) {
          yield put({
            type: 'updateState',
            payload: { 
              loadingStatus: APIStatus.failed,
            },
          });
          if (
            transaction.transactionDisplayType ===
              TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING &&
            error === 'WRONG_MACHINE_ID'
          ) {
            const stepConfig = yield select(
              (state) => state.createTransaction.stepConfig,
            );
            const newStepConfig = getNewStepConfig(2, stepConfig, true, false);
            yield put(
              createAction('updateState')({
                stepConfig: newStepConfig,
                errorFields: [CreateTransactionError.invalidMachineId.name],
              }),
            );
            saveToSessionStorage(sessionDataKey.stepEndKey, false);
          }
        }
          yield put({
            type: 'updateState',
            payload: { 
              loadingStatus: APIStatus.calling,
            },
          });
        yield loading(serviceArgs, onSuccess, onError, onArgumentsError);
      },
      { type: 'takeLatest' },
    ],

    updateTransaction: [
      function* ({ payload }, { put, select }) {
        const transaction = yield select(
          (state) => state.createTransaction.transaction,
        );
        let parsedInputBody = parseCreateTransactionInputBody(
          transaction,
          true,
        );

        if (
          transaction.transactionDisplayType ===
          TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
        ) {
          const purchasedItems = yield select(
            (state) => state.createTransaction.transaction.purchasedItems,
          );

          parsedInputBody.purchasedItemsInput = purchasedItems.map((item) => {
            return parsePurchasedItemInputBody(item, transaction.pk);
          });
        }

        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const serviceArgs = [updateTransaction, parsedInputBody];
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({
            type: 'updateState',
            payload: { 
              transactionStatusChanged: true,
              loadingStatus: APIStatus.success
            },
          });
        }
        function* onError(error) {
          console.log('createTransactionModel onError :', error);
          yield put({
            type: 'updateState',
            payload: { loadingStatus: APIStatus.failed },
          });
        }
        yield put({
          type: 'updateState',
          payload: { 
            loadingStatus: APIStatus.calling,
          },
        });
        yield loading(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateTransactionSKU: [
      function* ({ payload }, { put, select }) {
        yield put(
          createAction('updateState')({
            errorFields: [],
          }),
        );
        const { transaction } = yield select(
          (state) => ({
            transaction: state.createTransaction.transaction,
          })
        );
        const skuItems = transaction.skuItems || [];
        const purchasedItems = transaction.purchasedItems || [];
        const skuApiItems = [];
        let emptySku = 0;
        let duplicateSku = 0;
        for (let i= 0; i < skuItems.length; i++) {
          const sku = skuItems[i];
          if (sku && sku.length > 0) {
            if (skuApiItems.map(item => item.sku).includes(sku)) {
              duplicateSku += 1;
            }
            skuApiItems.push({ sku });
          } else {
            emptySku += 1;
          }
        }

        const checkSku = (transaction?.campaign && payload?.checkSku) || false;

        if (checkSku && (!skuApiItems?.length || emptySku || duplicateSku)) { //check skuItems is empty, return error
          yield put(
            createAction('updateState')({
              errorFields: [CreateTransactionError.skuItems.name],
            }),
          );
          return;
        };
        let parsedInputBody = {
          transaction: transaction.pk,
          checkSku,
          purchasedItemsInput: skuApiItems,
          purchasedItemsToDelete: purchasedItems.map((item) => item.pk),
        };

        console.log("@@1117", transaction)
        yield put({ type: 'updateEditTransaction', payload: { editDateError: null, editTotalValueError: null }})
        if (
          transaction.transactionType === "OFFLINE_REWARD_CLAIM"
          && transaction.offlineEventType === 'WAITING_FOR_APPROVAL'
        ) {
          const editDate = transaction.editDate || '';
          const editTotalValue = transaction.editTotalValue || '';
          console.log("@@1148", editDate, editTotalValue)

          if (editDate && !oclDateRegex.test(editDate)) {
            yield put({ type: 'updateEditTransaction', payload: { editDateError: CreateTransactionError.oclDate.message }})
            return;
          };
          if (editTotalValue && editTotalValue < 0) {
            yield put({ type: 'updateEditTransaction', payload: { editTotalValueError: CreateTransactionError.transactionTotalValue.message }})
            return;
          };

          parsedInputBody["totalValue"] = editTotalValue || null;
          parsedInputBody["date"] = editDate ? new Date(editDate) : null;
        }

        const serviceArgs = [updateTransactionItems, parsedInputBody];
        function* onSuccess(response) {
          const purchasedItems = response?.updateTransactionItems?.node?.purchasedItems?.edges || [];
          const parsedPurchasedItems = parsePurchaseItems(purchasedItems);
          yield put({ 
            type: 'updateState',
            payload: {
              transaction: {
                ...transaction,
                purchasedItems: parsedPurchasedItems,
              }
            }
          });

          const { afterActions} = payload;
          if (afterActions) {
            afterActions();
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
