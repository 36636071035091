import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CommunityOverview from './CommunityOverview';
import CommunityCustomers from './CommunityCustomers';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { getHashKeyString } from '../../../utils';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ImportResource } from '../../../models/DownloadImportModel';
import CommunityNewsFeeds from './CommunityNewsFeeds';
import CommunityTarget from './CommunityTarget';

const queryString = require('query-string');

export const URLQueryKeys = {
  searchKey: 'searchKey',
  rank: 'rank',
  page: 'page',
};

export const URLFragments = {
  overview: 'Overview',
  list: 'All Joined Users',
  newsFeed: 'News Feed',
  communityTarget: 'Community Target',
};

const CommunityDetail = ({
  object = {},
  isSuperuser,
  userPermissions,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [buttons, setButtons] = useState([]);
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    dispatch({
      type: 'communityModel/getObject',
      payload: { id, notSaveToSession: true },
    });
    return () => {
      dispatch({ type: 'communityModel/clearData' });
      dispatch({ type: 'customerList/clearData' });
    };
  }, []);

  const tabs = [
    {
      name: URLFragments.overview,
      content: <CommunityOverview />,
    },
    isSuperuser ||
      userPermissions.filter(
        (val) => val.split('_')[1] === PermissionCodes.customer,
      ).length > 0
      ? {
        name: URLFragments.list,
        content: <CommunityCustomers />,
      }
      : {},
    isSuperuser ||
      userPermissions.filter(
        (val) => val.split('_')[1] === PermissionCodes.newsFeed,
      ).length > 0
      ? {
        name: URLFragments.newsFeed,
        content: <CommunityNewsFeeds />,
      }
      : {},
    isSuperuser ||
      userPermissions.filter(
        (val) => val.split('_')[1] === PermissionCodes.mission,
      ).length > 0
      ? {
        name: URLFragments.communityTarget,
        content: <CommunityTarget />,
      }
      : {},
  ];

  const defaultActiveKey =
    getHashKeyString(history.location.hash) || URLFragments.overview;


  const getCommunityDetailButtons = () => {
    if (defaultActiveKey === URLFragments.newsFeed) {
      return [
        <></>,
        <AuthButton
          title="Create News Feed"
          action={() => {
            history.push({
              pathname: '/news_feeds/create',
              state: { fromCommunity: location }
            });
          }}
          requires={PermissionCodes.changeNewsFeed}
          customClass={'btn-further'}
        />,
      ]
    }
    else if (defaultActiveKey === URLFragments.communityTarget) {
      return [
        <></>,
        <AuthButton
          title="Create Community Target"
          action={() => {
            console.log("@@45", location)
            history.push({
              pathname: `/communities/${id}/target/create`,
              state: { fromCommunity: location, communityName: object?.name?.en }
            });
          }}
          requires={PermissionCodes.changeMission}
          customClass={'btn-further'}
        />,
      ]
    }
    else {
      return [
        <AuthButton
          title="Export CSV"
          customClass="btn-back-button-common btn-download"
          action={() => {
            dispatch({
              type: 'downloadAndImport/createDownloadTask',
              payload: {
                from: ImportResource.customerInCommunity,
                related: { community: id },
              },
            });
          }}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title="Edit"
          action={() => {
            history.push({
              pathname: `/communities/${id}/edit`,
            });
          }}
          requires={PermissionCodes.changeCommunity}
        />,
      ]
    }
  }

  useEffect(() => {
    setButtons(getCommunityDetailButtons())
  }, [defaultActiveKey, object?.name?.en])


  return (
    <CustomListComponent
      breadcrumb={<CustomBreadcrumb name={object?.name?.en} />}
      defaultActiveKey={defaultActiveKey}
      onTabChange={(key) => {
        let search = '';
        if (key !== URLFragments.overview) {
          search = queryString.stringify({
            [URLQueryKeys.page]: 1,
            [URLQueryKeys.searchKey]: '',
            [URLQueryKeys.rank]: true,
          });
        }
        history.push({
          pathname: `/communities/${id}/`,
          hash: key,
          search: search,
        });
      }}
      buttons={buttons}
      tabs={tabs}
      title={object?.name?.en}
    />
  );
}

const mapStateToProps = (state) => ({
  object: state.communityModel.oneObject,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
});

export default connect(mapStateToProps)(CommunityDetail);
