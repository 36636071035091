import { BaseHelper } from './BaseGraphQLAPIHelper';
import { CategoryType } from '../config/CustomEnums';
export const getCampaignCategoryList = (
  afterCursor,
  searchKey,
  otherSearch,
) => {
  console.log('otherSearch:', otherSearch);
  let filter = '';
  if (searchKey) {
    filter += `, nameIcontains:"${searchKey}"`;
  }

  if (otherSearch?.status) {
    filter += `, isActive: ${otherSearch.status}`;
  }
  let orderBy = 'displayPriority';
  if (otherSearch?.sort) {
    orderBy = otherSearch.sort;
  }
  if (otherSearch?.categoryType) {
    filter += `, categoryTypeIn: "${otherSearch.categoryType},${CategoryType.BOTH}"`;
  }

  const query = `{
    campaignCategories(first: 20, after:"${afterCursor}", orderBy: "${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          displayPriority
          isForcedInactive
          categoryType
          translations(first: 10) {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignCategory = (id) => {
  const query = `{
    campaignCategory(id: "${id}") {
      id
      pk
      name
      displayPriority
      isForcedInactive
      categoryType
      translations {
        edges {
          node {
            language
            name
            id
            pk
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const updateCampaignCategory = (input) => {
  const query = `mutation UpdateCampaignCategory($input: UpdateCampaignCategoryInput!) {
    updateCampaignCategory(input: $input) {
      node {
        id
        pk
        name
        displayPriority
        isForcedInactive
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteCampaignCategories = (input) => {
  const query = `mutation DeleteCampaignCategories($input: DeleteCampaignCategoriesInput!) {
    deleteCampaignCategories(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
