import {
  getMessages,
  getMessage,
  deleteMessages,
  duplicateMessage,
} from '../services/MessageApiHelper';
import {
  convertNumberToCursor,
  createAction,
  convertCursorToNumber,
} from '../utils';
import { loading } from './LoadingUtil';
import { formatDate } from '../utils/TimeFormatUtil';
import {
  LanguageConfig,
  MessageChannel,
  MessageTag,
  APIStatus,
} from '../config/CustomEnums';
import { InformationBarMessageType, MessageType } from '../components/message/CreateMessageConfig';

const getInitialState = () => ({
  messageList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'name',
      linked: true,
      orderField: 'title',
    },
    { displayName: 'Message channel', fieldName: 'displayChannels' },
    { displayName: 'Message type', fieldName: 'displayMessageType' },
    {
      displayName: 'Related campaign',
      fieldName: 'relatedCampaignName',
      orderField: 'campaign',
    },
    { displayName: 'Target customers', fieldName: 'displayTargetCustomer' },
    {
      displayName: 'Delivery time',
      fieldName: 'deliveryDate',
      orderField: 'scheduledDate',
    },
    { displayName: 'Status', fieldName: 'status' },
  ],
  currentPageMessageList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  latestWelcomeMessageId: null,
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  message: {},
  errorFields: [],
});

const parseChannel = (channelName) => {
  if (channelName === 'INBOX') {
    return MessageChannel.inbox;
  }
  if (channelName === 'PUSH_NOTIFICATION') {
    return MessageChannel.push;
  }
};

const parseMessageTranslations = (translations) => {
  let parsedTranslations = {};
  translations.forEach((item) => {
    const translationData = item.node;
    let language = translationData.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    } else if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    parsedTranslations[language] = {
      name: translationData.title,
      content: translationData.content,
      photo: translationData.photo,
      id: translationData.id,
      pk: translationData.pk,
    };
  });
  return parsedTranslations;
};


const parseInformationBarTranslations = (translations) => {
  let parsedTranslations = {};
  translations.forEach((item) => {
    const translationData = item.node;
    let language = translationData.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    } else if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    parsedTranslations[language] = {
      name: translationData.name,
      content: translationData.content,
      id: translationData.id,
      pk: translationData.pk,
    };
  });
  return parsedTranslations;
};

const parseMessageStatus = (sendStatus, isDraft) => {
  switch (sendStatus) {
    case 'STATE_COMPLETED':
      return MessageTag.sent;
    case 'STATE_INITIAL':
      if (isDraft) {
        return MessageTag.saved;
      }
      return MessageTag.scheduled;
    case 'STATE_SENDING':
      return MessageTag.sending;
    case 'STATE_ERROR':
      return MessageTag.error;
    default:
      break;
  }
};

const parseMessage = (data, latestWelcomeMessageId) => {
  let parsedChannelList = data.channels.edges?.map((item) =>
    parseChannel(item.node.pk),
  );
  const customerGroupList = data.targetedCustomerGroups?.edges?.map(
    (item) => item.node.name,
  );
  const segmentsNameList = data.targetedSegments?.edges?.map(
    (item) => item.node?.name,
  );

  let messageType = data.MessageType
  let displayMessageType = data.messageType !== MessageType.welcomeMessage
    ? 'General Message'
    : 'Welcome Message'

  let informationBarData = data.informationBar

  if (informationBarData) {
    const translations = informationBarData.translations
    informationBarData.translations = translations?.edges?.length > 0
      ? parseInformationBarTranslations(translations.edges)
      : {}

    messageType = informationBarData.informationType === 'OTHERS' ? 'Others' : 'InformationBarMessageType.maintenance'

    displayMessageType = informationBarData.informationType === 'OTHERS' ? 'Information Bar - Others' : 'Information Bar - Maintenance'

    informationBarData.displayScheduledStartDate = formatDate(informationBarData.scheduledStartDate, 'DD MMM yyyy (ddd),HH:mm a')
    informationBarData.displayScheduledEndDate = formatDate(informationBarData.scheduledEndDate, 'DD MMM yyyy (ddd),HH:mm a')

    parsedChannelList.unshift('Information Bar')
  }


  return {
    ...data,
    pk: data.pk,
    id: data.id,
    name: data.title,
    content: data.content,
    photo: data.photo,
    channels: parsedChannelList,
    displayChannels: parsedChannelList.toString(),
    relatedCampaign: {
      pk: data.campaign?.pk,
      name: data.campaign?.name,
    },
    relatedCampaignName: data.campaign?.name,
    targetCustomerGroup: data.targetedCustomerGroups?.edges.map(
      (item) => item.node,
    ),
    isLatestWelcomeMessage:
      data.messageType === MessageType.welcomeMessage
        ? latestWelcomeMessageId === data.pk
        : null,
    messageType: messageType,
    displayTargetCustomer: customerGroupList.toString(),
    targetedSegments: data.targetedSegments?.edges.map((item) => item.node),
    displayTargetedSegments:
      segmentsNameList?.length > 0 ? segmentsNameList.toString() : null,
    scheduledDate: data.scheduledDate,
    deliveryDate: formatDate(data.scheduledDate, 'DD MMM yyyy (ddd),HH:mm a'),
    translations:
      data.translations?.edges?.length > 0
        ? parseMessageTranslations(data.translations.edges)
        : {},
    status: parseMessageStatus(data.sendingState, data.isDraft),
    displayMessageType: displayMessageType,
    url: data.url,
    informationBar: informationBarData
  };
};

export default {
  namespace: 'messageList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearMessageState(state, { payload }) {
      return {
        ...state,
        message: {},
      };
    },
  },
  effects: {
    getCurrentPageMessages: [
      function* ({ payload }, { put }) {
        const { page, rank, searchKey } = payload;
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getMessages,
          afterCursor,
          rank,
          searchKey,
          payload,
        ];
        function* onSuccess(data) {
          const pageInfo = data.messages.pageInfo;
          const totalCount = data.messages.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const messageData = data.messages.edges;
          const latestWelcomeMessageId = data.messages.latestWelcomeMessageId;
          const messageList = messageData.map((item) =>
            parseMessage(item.node, latestWelcomeMessageId),
          );
          yield put(
            createAction('updateState')({
              currentPageMessageList: messageList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              totalCount,
              currentLastCursor,
              latestWelcomeMessageId,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getMessage: [
      function* ({ payload }, { put, select }) {
        const { messagePK } = payload;
        const latestWelcomeMessageId = yield select(
          (state) => state.messageList.latestWelcomeMessageId,
        );
        const messageID = btoa(`MessageNode:${messagePK}`);
        const serviceArgs = [getMessage, messageID];
        yield put(
          createAction('createMessage/updateState')({
            createStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          const messageData = data.message;
          const message = parseMessage(messageData, latestWelcomeMessageId);
          yield put(
            createAction('updateState')({
              message,
            }),
          );
          yield put(
            createAction('createMessage/updateState')({
              message: message,
              createStatus: APIStatus.success,
            }),
          );
          yield put({
            type: 'createMessage/updateWithStepConfig',
            payload: {
              message: message,
              createStatus: APIStatus.success,
            },
          });

          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.messageList.checkedList,
        }));

        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteMessages, pks];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        const serviceArgs = [duplicateMessage, payload.data.pk];
        function* onSuccess() {
          const afterActions = payload.afterAction || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
