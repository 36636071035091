import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { GuideImageError } from '../../../models/GuideImageModel';
import { firstError } from '../../../components/campaign/campaignCreation/CreateCampaignHandleError';
import ImageUploader from '../../../components/base/ImageUploader';
import CustomInputWithMaxLength from '../../../components/base/CustomInputWithMaxLength';

function GuideImageInstruction({
  language = LanguageConfig.english,
  type,
  guideDetail,
  errorFields,
}) {
  const titleName = `${type}Title`;
  const imageName = `${type}Image`;
  const dispatch = useDispatch();
  const {
    translations = {},
  } = guideDetail;
  const title = translations?.[language]?.[titleName];
  const image = translations?.[language]?.[imageName];

  const isError = (field) => {
    return errorFields.indexOf(`${field}-${language}`) > -1;
  };

  useEffect(() => {
    firstError(
      `${GuideImageError?.[titleName]?.name}-${language}`,
      errorFields,
      language,
    );
    firstError(
      `${GuideImageError?.[imageName]?.name}-${language}`,
      errorFields,
      language,
    );
  }, [errorFields]);

  return (
    <>
      <label className="create-section-title">
        {i18n.t('guide_instruction', { locale: language })}
      </label>

      <CustomInputWithMaxLength
        sectionTitle={i18n.t('instruction_section_title', { locale: language })}
        value={title}
        valueChange={(value) => {
          dispatch({
            type: 'guideImage/updateGuideTranslations',
            payload: {
              language: language,
              [titleName]: value,
            }
          })
        }}
        customInputContainerClassName={"custom-input-with-max-length-with-count-619"}
        maxLength={30}
        showCount
        error={isError(GuideImageError?.[titleName]?.name)}
        errorMessage={i18n.t(GuideImageError?.[titleName]?.message, { locale: language })}
        errorId={`${GuideImageError?.[titleName]?.name}-${language}`}
      />

      <label className="create-section-label guide-image-title-image">
        {i18n.t('guide_image', { locale: language })}
      </label>

      <ImageUploader
        images={image ? [image] : []}
        showAspect
        cropperDisble
        maxImageNum={1}
        language={language}
        onImageStateChange={(data) => {
          dispatch({
            type: 'guideImage/updateGuideTranslations',
            payload: {
              language: language,
              [imageName]: data?.[0],
            }
          })
        }}
        uploadImageClicked={() => { }}
        errorMessage={
          isError(GuideImageError?.[imageName]?.name)
            ? i18n.t(GuideImageError?.[imageName]?.message, { locale: language })
            : ''
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  guideDetail: state.guideImage.guideDetail,
  errorFields: state.guideImage.errorFields,
});

export default connect(mapStateToProps)(GuideImageInstruction);
