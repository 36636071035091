import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import FilterableDropdown from '../base/FilterableDropdown';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { LanguageConfig } from '../../config/CustomEnums';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function MerchantNameFilterableDropdown(props) {
  const defaultSelectedMerchantName = props.defaultSelectedMerchantName;
  const moreSearch = props.moreSearch || [];
  const title = props.title;
  const titleTips = props.titleTips || '';
  const errorName = props.errorName || '';
  const errorMessage = props.errorMessage || '';
  const errorFields = props.errorFields || [];
  const { merchantNames} = useSelector((state) => ({
    merchantNames: state.earningRuleList.earningRuleMerchantNames,
  }));

  const [searchName, setSearchName] = useState('');
  const [merchantNamesList, setMerchantNamesList] = useState(merchantNames || []);

  const localFilterAction = (search = '') => {
    setSearchName(search);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction('earningRuleList/getEarningRuleMerchantNames')({ moreSearch }));

  }, []);


  useEffect(() => {
    firstError(errorName, errorFields, LanguageConfig.english);
  }, [errorFields, errorName]);

  useEffect(() => {
    let editedMerchantNames = merchantNames;
    if (searchName) {
      editedMerchantNames = editedMerchantNames.filter(
        item => item?.toLowerCase()?.includes(searchName?.toLowerCase())
      );
    };
    setMerchantNamesList(editedMerchantNames);
  }, [merchantNames?.length, searchName])

  const setMerchantName = (merchantName) => {
    if (defaultSelectedMerchantName?.value !== merchantName?.value) {
      dispatch({
        type: 'createTransaction/updateTransactionState',
        payload: {
          campaign: {},
        }
      });
      dispatch({
        type: 'campaignList/getCampaignList',
        payload: {
          isSelectorLoad: true,
          isExpired: false,
          isPublished: true,
          expired: 'false',
          isAllActive: true,
          merchantName: merchantName?.value,
        }
      });
    }
    dispatch(
      createAction('createTransaction/updateTransactionState')({
        merchantName: merchantName
      }),
    );
  };

  return (
    <>
      <CustomTitleWithDropDown
        title={title}
        titleTips={titleTips}
        source={merchantNamesList}
        listSource={true}
        defaultValue={defaultSelectedMerchantName}
        setValue={setMerchantName}
        needFilter
        localFilterAction={localFilterAction}
        // defaultFilter={{ moreSearch }}
        errors={errorFields}
        errorName={errorName}
        errorMessage={errorMessage}
        showRemoveButton={props.showRemoveButton}
        onRemoveButtonClick={props.onRemoveButtonClick}
      />
    </>
  );
}

export default MerchantNameFilterableDropdown;
