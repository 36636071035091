import {
  getPagedCommunities,
  getOneObject,
  deleteItems,
  createOrUpdate,
  unsetCustomerCommunity,
} from '../services/CommunityAPIHelper';
import {
  convertNumberToCursor,
  convertPKToId,
  convertCursorToNumber,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeUndefinedFieldFromDict,
  removeFromSessionStorage,
  getFileNameFromUrl,
  getObjectTranslations,
  getFormatedField,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  SavedStatus,
  CheckStatus,
  LanguageConfig,
  EarningRuleRewardType,
} from '../config/CustomEnums';
import { ErrorHandleFields } from '../containers/merchants/community/ErrorHandleFields';
import moment from 'moment';
import { TimeFormater } from '../utils/TimeFormatUtil';

const sessionKey = 'tempCommunity';

const parseObject = (object) => {
  if (!object) {
    return {};
  }
  const translations = getObjectTranslations(object);
  object.translations = translations;
  return {
    ...object,
    creationDateDisplay: moment(object.creationDate).format(
      TimeFormater.dayMonthYearWeekTime,
    ),
    lastModifiedDateDisplay: moment(object.lastModifiedDate).format(
      TimeFormater.dayMonthYearWeekTime,
    ),
  };
};

const getInitState = () => {
  return {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Name', fieldName: 'name' },
      { displayName: 'Logo', fieldName: 'coverPhoto' },
      {
        displayName: 'Community Code',
        fieldName: 'communityCode',
      },
      {
        displayName: 'Customers',
        fieldName: 'numberOfCustomer',
      },
    ],
    pagedList: [],
    communityList: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    totalPage: 0,
    totalCount: 0,
    currentPage: 0,
    checkedList: [],
    oneObject: {},
    checked: CheckStatus.initOrNotChecked,
    errorFields: {},
    saved: SavedStatus.init,
    formChanged: false,
    objectLoading: true,

    relatedCustomerInfo: {
      listDisplayFields: [
        { displayName: 'ID', fieldName: 'pk' },
        { displayName: 'Name', fieldName: 'name', linked: false },
        {
          displayName: 'Member ID',
          fieldName: 'membershipId',
        },
        {
          displayName: 'Joined Date',
          fieldName: 'joinCommunityDate',
        },
      ],
      listDisplayFieldsWithHall: [
        { displayName: 'ID', fieldName: 'pk' },
        { displayName: 'Name', fieldName: 'name', linked: false },
        {
          displayName: 'Member ID',
          fieldName: 'membershipId',
        },
        {
          displayName: 'Hall',
          fieldName: 'communityHall',
        },
        {
          displayName: 'Joined Date',
          fieldName: 'joinCommunityDate',
        },
      ],
    },
    relatedNewsFeedInfo: {
      listDisplayFields: [
        { displayName: 'ID', fieldName: 'pk' },
        { displayName: 'Name', fieldName: 'name', linked: false },
        { displayName: 'Cover Photo', fieldName: 'coverPhoto' },
        { displayName: 'Display In', fieldName: 'displayInShown' },
        { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
        { displayName: 'Status', fieldName: 'status' },
      ],
    },
    relatedCommunityTarget: {
      listDisplayFields: [
        { displayName: 'ID', fieldName: 'pk' },
        { displayName: 'Name', fieldName: 'name', linked: false, },
        { displayName: 'Mission Type', fieldName: 'displayMissionType' },
        { displayName: 'Active Period', fieldName: 'displayActivePeriod', },
        { displayName: 'Visible Period', fieldName: 'displayVisiblePeriod', },
        { displayName: 'Status', fieldName: 'status' },
      ],
    }
  };
};

export default {
  namespace: 'communityModel',
  state: getInitState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateCommunityList(state, { payload }) {
      const { page, pagedList } = payload;

      return {
        ...state,
        communityList:
          page > 1 ? [...state.communityList, ...pagedList] : pagedList,
      };
    },
    loadDataFromCookie(state, { payload }) {
      const sessionObject = getObjectFromSessionStorage(sessionKey) || {};
      const tempObject = payload?.data || sessionObject;
      const name = getFormatedField('name', tempObject);
      const termsAndCondition = getFormatedField(
        'termsAndCondition',
        tempObject,
      );
      tempObject.name = name;
      tempObject.termsAndCondition = termsAndCondition;

      return {
        ...state,
        oneObject: tempObject,
        objectLoading: false,
      };
    },
    changeVals(state, { payload }) {
      const object = getObjectFromSessionStorage(sessionKey) || {};
      const data = payload.vals || {};
      const changedData = {
        ...object,
        ...removeUndefinedFieldFromDict(data),
      };
      saveToSessionStorage(sessionKey, changedData);
      return {
        ...state,
        formChanged: true,
      };
    },
    checkValsValid(state, { payload }) {
      const sessionObject = getObjectFromSessionStorage(sessionKey) || {};
      const object = {
        ...state.oneObject,
        ...removeUndefinedFieldFromDict(sessionObject),
      };
      const errorFields = { fields: [], messages: [] };
      let checked = CheckStatus.initOrNotChecked;
      const codeRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+){8}$/;
      if (!object?.name?.en) {
        errorFields.fields.push(ErrorHandleFields.name);
        errorFields.messages.push({
          field: ErrorHandleFields.name,
          errorType: 'required',
        });
      }
      Object.keys(ErrorHandleFields).map((field) => {
        if (['name', 'termsAndCondition'].includes(field)) {
          if (!object[field].en) {
            errorFields.fields.push(field);
            errorFields.messages.push({ field, errorType: 'required' });
          }
        } else if (
          ['communityCode'].includes(field) &&
          (!codeRegex.test(object[field]) || object[field].length !== 8)
        ) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'required' });
        } else if (
          field === 'communityLimit' && object[field]?.length > 0 && object[field] <= 0
        ) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'valid' });
        } else if (field === 'coverPhoto' && !object[field]) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'length' });
        }
      });

      if (errorFields.fields.length > 0) {
        checked = CheckStatus.checkedWithFail;
      } else {
        checked = CheckStatus.checkedWithSuccess;
      }

      return {
        ...state,
        checked,
        errorFields,
      };
    },
    removeFromCookie(state, { payload }) {
      removeFromSessionStorage(sessionKey);
      return { ...state, formChanged: false };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },
  effects: {
    getList: [
      function* ({ payload }, { put, select }) {
        const page = payload.page || 1;
        const afterCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedCommunities, { ...payload, afterCursor }];
        function* onSuccess(data) {
          const listData = data?.communities || {};
          const pageInfo = listData.pageInfo || {};
          const currentLastCursor = pageInfo.endCursor;
          const totalCount = listData.totalCount;
          const list = listData.edges.map((item) => parseObject(item.node));
          yield put({
            type: 'updateState',
            payload: {
              pagedList: list,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
          yield put({
            type: 'updateCommunityList',
            payload: {
              page,
              pagedList: list,
            }
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const id = payload?.id;
        const { checkedList } = yield select((state) => ({
          checkedList: state.communityModel.checkedList,
        }));
        const pks = id || checkedList.map((item) => item.pk);
        const serviceArgs = [deleteItems, pks];
        const afterAction = payload?.afterAction || (() => { });
        function* onSuccess(data) {
          yield all([
            put({
              type: 'communityModel/updateState',
              payload: { checkedList: [] },
            }),
          ]);
          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getObject: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'updateState', payload: { objectLoading: true } });
        if (!payload?.id) {
          yield put({ type: 'loadDataFromCookie' });
          return;
        }
        const serviceArgs = [
          getOneObject,
          convertPKToId('CommunityNode', payload.id),
        ];
        function* onSuccess(data) {
          yield put({
            type: 'loadDataFromCookie',
            payload: {
              data: parseObject(data.community),
              noCookie: true,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const oneObject = yield select(
          (state) => state.communityModel.oneObject,
        );
        const sessionObject = getObjectFromSessionStorage(sessionKey) || {};
        const source = {
          ...oneObject,
          ...removeUndefinedFieldFromDict(sessionObject),
        };
        const data = {
          name: source.name[LanguageConfig.english],
          coverPhoto: getFileNameFromUrl(
            source.coverPhoto.value || source.coverPhoto,
          ),
          termsAndCondition: source.termsAndCondition[LanguageConfig.english],
          communityCode: source.communityCode,
          communityLimit: source.communityLimit || null,
          rewardType: source.rewardType,
          rewardPoints:
            source.rewardType === EarningRuleRewardType.points
              ? source.rewardPoints || null
              : null,
          rewardCouponQuantity:
            source.rewardType === EarningRuleRewardType.coupons
              ? source.rewardCouponQuantity || null
              : null,
          carbonSavingQuantity: source.carbonSavingQuantity || null,
          rewardCouponTemplate:
            source.rewardType === EarningRuleRewardType.coupons
              ? source.rewardCouponTemplate?.pk
              : null,
          translations:
            source.translations && Object.keys(source.translations).length > 0
              ? Object.keys(source.translations).map((lang) => ({
                language: source.translations[lang].language,
                name: source.name[source.translations[lang].language],
                termsAndCondition:
                  source.termsAndCondition[
                  source.translations[lang].language
                  ],
                id: source.translations[lang].pk,
              }))
              : [
                LanguageConfig.traditionalChinese,
                LanguageConfig.simplifiedChinese,
              ].map((lang) => ({
                language: lang,
                name: source.name[lang],
                termsAndCondition: source.termsAndCondition[lang],
              })),
        };

        if (source.pk) {
          data.id = source.pk;
        }
        const afterAction = payload?.afterAction || (() => { });
        const serviceArgs = [createOrUpdate, data];
        function* onSuccess(data) {
          if (
            ('createCommunity' in data && data.createCommunity.errors) ||
            ('updateCommunity' in data && data.updateCommunity.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                saved: SavedStatus.savedWithFail,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                formChanged: false,
                saved: SavedStatus.savedWithSuccess,
                checked: CheckStatus.initOrNotChecked,
              },
            });

            removeFromSessionStorage(sessionKey);
          }

          afterAction();
        }

        function* onFail(data) {
          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
              saved: SavedStatus.savedWithFail,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail, onFail);
      },
      { type: 'takeLatest' },
    ],
    unsetCustomerCommunity: [
      function* ({ payload }, { select, put, all }) {
        const id = payload?.id;
        const communityId = payload?.communityId;
        const { checkedList } = yield select((state) => ({
          checkedList: state.customerList.checkedList,
        }));
        const pks = id ? [id] : checkedList.map((item) => item.pk);
        const serviceArgs = [unsetCustomerCommunity, pks, communityId];
        const afterAction = payload?.afterAction || (() => { });
        function* onSuccess(data) {
          yield all([
            put({
              type: 'communityModel/updateState',
              payload: { checkedList: [] },
            }),
          ]);
          afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
