import React, { useState, useEffect } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../utils/TimeFormatUtil';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateMessageError } from '../../models/CreateMessageModel';
import { LanguageConfig } from '../../config/CustomEnums';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomAddRemoveMutipleSelector from '../customer/CustomAddRemoveMutipleSelector';
import AddCustomerGroupSegmentPrompt, { NewPromptType } from '../base/prompt/AddCustomerGroupSegmentPrompt';

function InformationBarMessageSettingSection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = props.onSubmit || false;
  const onSubmitData = props.onSubmitData || (() => { });
  const [createNewType, setCreateNewType] = useState(NewPromptType.none);

  const {
    defaultScheduleStartDate,
    defaultScheduleEndDate,
    errorFields,
    defaultMessage
  } = useSelector((state) => ({
    customerGroupList: state.customerGroup.groupList,
    segmentList: state.segments.allSegmentList,
    targetCustomerGroup: state.createMessage.message.targetCustomerGroup,
    targetedSegments: state.createMessage.message.targetedSegments,
    defaultScheduleStartDate: state.createMessage.message.informationBar.scheduledStartDate,
    defaultScheduleEndDate: state.createMessage.message.informationBar.scheduledEndDate,
    errorFields: state.createMessage.errorFields,
    defaultMessage: state.createMessage.message

  }));

  console.log('setting', defaultMessage)

  const [scheduledStartDate, setScheduleStartDate] = useState();
  const [scheduledEndDate, setScheduleEndDate] = useState(defaultScheduleEndDate);
  const [displayScheduledStartDate, setDisplayScheduledStartDate] = useState()
  const [displayScheduledEndDate, setDisplayScheduledEndDate] = useState()

  useEffect(() => {
    if (onSubmit) {
      const formatData = {
        informationBar: {
          scheduledStartDate: scheduledStartDate,
          scheduledEndDate: scheduledEndDate,
          // displayScheduledStartDate: displayScheduledStartDate,
          // displayScheduledEndDate: displayScheduledEndDate
        }
      };
      onSubmitData(formatData);
    }
  });

  useEffect(() => {
    firstError(
      CreateMessageError.scheduledDate.name,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = () => {
    return isShowError(
      CreateMessageError.scheduledDate.name,
      errorFields,
      LanguageConfig.english,
    );
  };

  return (
    <>
      <label className="create-section-title">SCHEDULE TIME</label>
      <CustomTitleLabel title="Start time" />
      <CustomDateTimeSelect
        defaultTime={
          defaultScheduleStartDate ? new Date(defaultScheduleStartDate) : new Date()
        }
        onTimeChange={(datetime) => {
          setScheduleStartDate(datetime);
          const scheduledStartDate = formatDate(
            datetime,
            'DD MMM yyyy (ddd),HH:mm a',
          );

          setDisplayScheduledStartDate(scheduledStartDate)

          dispatch(
            createAction('createMessage/updateMessageState')({
              informationBar: {
                scheduledStartDate: datetime,
                displayScheduledStartDate: scheduledStartDate
              },

            }),
          );
        }}
        error={isError()}
      />
      <CustomTitleLabel title="End time" />
      <CustomDateTimeSelect
        defaultTime={
          defaultScheduleEndDate ? new Date(defaultScheduleEndDate) : new Date()
        }
        onTimeChange={(datetime) => {
          setScheduleEndDate(datetime);
          const scheduledEndDate = formatDate(
            datetime,
            'DD MMM yyyy (ddd),HH:mm a',
          );
          setDisplayScheduledEndDate(scheduledEndDate)

          dispatch(
            createAction('createMessage/updateMessageState')({
              informationBar: {
                scheduledEndDate: datetime,
                displayScheduledEndDate: scheduledEndDate
              },
            }),
          );
        }}
        error={isError()}
      />
      {/* <ErrorFieldMessage
        id={CreateMessageError.scheduledDate.name}
        error={isError()}
        message={CreateMessageError.scheduledDate.message}
      /> */}
    </>
  );
}

export default InformationBarMessageSettingSection;
