import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LanguageConfig, LanguageTabTransLation } from '../../config/CustomEnums';

const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

function CreateTransactionStepFourContentSection() {
  const { translations } = useSelector((state) => ({
    translations: state.createTransaction.transaction.translations,
  }));

  const showdown = require('showdown');
  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);
  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
        dangerouslySetInnerHTML={{
          __html: value ? converter.makeHtml(value) : '-',
        }}
      ></td>
    );
  };

  const sectionValues = (valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) =>
          fieldValueText(translations?.[language]?.[valueKey], index),
        )}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">CONTENT</label>
      <PerfectScrollbar>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
              {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText('Small heading (optional)')}
              {sectionValues('smallHeading')}
            </tr>
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}

export default CreateTransactionStepFourContentSection;
