import { APIStatus, CheckStatus, SavedStatus } from '../config/CustomEnums';
import {
  deleteAppVersion,
  getAppVersions,
  createAppVersion,
} from '../services/AppVersionApiHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  delay,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import { getCampignPeriodDateTime } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';

const getInitialState = () => ({
  oneAdminGroup: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'iOS version', fieldName: 'iosAppVersionName' },
    { displayName: 'Android version', fieldName: 'androidAppVersionName' },
    { displayName: 'Create at', fieldName: 'creationDate' },
  ],
  appVersionList: [],
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  checkedList: [],
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  saved: SavedStatus.init,
  formChanged: false,
  apiStatus: APIStatus.none,
});

const appVersionSessionKey = 'tempAppVersion';

const parseAppVersion = (item) => {
  return {
    ...item,
    creationDate: getCampignPeriodDateTime(item.creationDate),
  };
};

const parseAppVersionList = (data) => {
  return data.map((item) => parseAppVersion(item.node));
};

export default {
  namespace: 'appVersion',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    loadAppVersionFromCookieSession(state, { payload }) {
      const appVersion =
        getObjectFromSessionStorage(appVersionSessionKey) || {};
      return {
        ...state,
        appVersion: appVersion,
      };
    },

    removeAppVersionFromCookie(state, { payload }) {
      removeFromSessionStorage(appVersionSessionKey);
      return {
        ...state,
        formChanged: false,
      };
    },

    changeVals(state, { payload }) {
      let appVersion = getObjectFromSessionStorage(appVersionSessionKey);
      let data = {};
      if (payload.vals) {
        data = payload.vals;
      }
      appVersion = { ...appVersion, ...data };
      saveToSessionStorage(appVersionSessionKey, appVersion);
      return {
        ...state,
        formChanged: true,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getAppVersions: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getAppVersions,
          pageCursor,
          payload.reverse,
          payload.search,
          payload.moreSearch,
        ];
        function* onSuccess(data) {
          const pageInfo = data?.appVersions?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor1;
          const totalCount = data?.appVersions?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              appVersionList: parseAppVersionList(data?.appVersions?.edges),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { all, put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.appVersion.checkedList,
        }));
        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });
        const serviceArgs = [deleteAppVersion, pks];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                checkedList: [],
                formChanged: false,
              },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          yield put({
            type: 'updateState',
            payload: {
              checkedList: [],
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    createAppversion: [
      function* ({ payload }, { put, select }) {
        const apiStatus = yield select((state) => state.apiStatus);
        const iOSVersion = yield select((state) => payload.iOSVersion);
        const androidVersion = yield select((state) => payload.androidVersion);
        yield put({
          type: 'updateState',
          payload: {
            apiStatus: APIStatus.loading,
          },
        });

        let params = {
          iosAppVersionName: iOSVersion,
          androidAppVersionName: androidVersion,
        };
        const serviceArgs = [createAppVersion, params];
        function* onSuccess(data) {
          removeFromSessionStorage(appVersionSessionKey);
          yield put({
            type: 'updateState',
            payload: {
              apiStatus: APIStatus.success,
              formChanged: false,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};

export const AppVersionErrorHandleFields = {
  required: {
    androidVersion: 'Please provide android app Vesion',
    iOSVersion: 'Please provide iOS app Version',
  },
};
