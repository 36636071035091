import {
  getRewardSingleCodes,
  deleteRewardSingleCodes,
} from '../services/RewardCodeApiHelper'
import {
  convertNumberToCursor,
  convertCursorToNumber,
} from '../utils';
import { loading } from './LoadingUtil';
import {
  getDisplayDate
} from '../utils/TimeFormatUtil';

const statusTag = {
  active: 'Active',
  inactive: 'Inactive',
  used: 'Used',
  expired: 'Expired',
};

const getInitialState = () => ({
  rewardSingleCodeList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Registration code', fieldName: 'code' },
    { displayName: 'Owner', fieldName: 'customerName' },
    { displayName: 'Active Period', fieldName: 'displayActivePeriod' },
    { displayName: 'Create At', fieldName: 'displayCreationDate' },
    { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  currentPageRewardSingleCodeList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
});

const parseRewardSingleCode = (data, rewardCodeDetail) => {
  let status = statusTag.active;
  if (data.isUsed) {
    status = statusTag.used;
  }
   else if (data.isExpired) {
    status = statusTag.expired;
  }
  else if (!data.isActive) {
    status = statusTag.inactive;
  }

  return {
    pk: data.pk,
    code: data.code,
    customer: data.customer,
    customerName: data.customer ? `${data.customer?.firstName} ${data.customer?.lastName}` : '',
    isAlwaysActivePeriod: rewardCodeDetail?.isAlwaysActivePeriod,
    activeStartDate: rewardCodeDetail?.activeStartDate,
    activeEndDate: rewardCodeDetail?.activeEndDate,
    displayActivePeriod: rewardCodeDetail?.displayActivePeriod,
    creationDate: data.creationDate,
    displayCreationDate: getDisplayDate(data.creationDate),
    lastModifiedDate: data.lastModifiedDate,
    displayLastModifiedDate: getDisplayDate(data.lastModifiedDate),
    isActive: data.isActive,
    isExpired: data.isExpired,
    isUsed: data.isUsed,
    status,
  };
};

export default {
  namespace: 'rewardSingleCode',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
  },
  effects: {
    getCurrentPageRewardSingleCodes: [
      function* ({ payload }, { put, select }) {
        const { page, searchKey, sort } = payload;

        const { rewardCodeDetail } = yield select((state) => ({
          rewardCodeDetail: state.rewardCode.rewardCodeDetail,
        }));

        if (!rewardCodeDetail?.pk) {
          return;
        };

        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getRewardSingleCodes,
          {
            afterCursor,
            order: sort,
            search: searchKey,
            others: payload,
          }
        ];
        function* onSuccess(data) {
          const pageInfo = data.rewardSingleCodes.pageInfo;
          const totalCount = data.rewardSingleCodes.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const rewardSingleCodeData = data.rewardSingleCodes.edges;
          const rewardSingleCodeList = rewardSingleCodeData.map((item) =>
            parseRewardSingleCode(item.node, rewardCodeDetail)
          );
          yield put({
            type: 'updateState',
            payload: {
              currentPageRewardSingleCodeList: rewardSingleCodeList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              totalCount,
              currentLastCursor,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteRewardSingleCode: [
      function* ({ payload }, { put, select }) {
        const serviceArgs = [deleteRewardSingleCodes, payload?.deletePks];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
