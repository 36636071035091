import React, { useState } from 'react';
import BaseActionModal from '../coupon/couponList/BaseActionModalView';
import { capitalizeFirstLetter } from '../../utils';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { RejectReasons } from '../../config/CustomEnums';
import './TransactionActionPrompt.scss';

function TransactionActionPrompt(props) {
  const event = props.event; //approve, reject, recall
  const item = props.item;
  const show = props.show;
  const onHide = props.onHide || (() => { });
  const action = props.action || (() => { });
  const rejectReason = props.rejectReason || { value: null, label: null };
  const setRejectReason = props.setRejectReason || (() => { });
  const [reasons, setReasons] = useState(RejectReasons);

  const promptTitle = event
    ? `${capitalizeFirstLetter(event)} transaction`
    : '';
  let promptMessage = event
    ? `${capitalizeFirstLetter(event)} the transaction below?`
    : '';
  if (event === 'recall') {
    promptMessage = 'Confirm to recall? Once confirm, points that have been reward to the user be withdrawn.';
  }
  const promptDetailItems = [
    {
      title: 'Invoice',
      content:
        item?.transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
          ? item?.posInvoiceId
          : item?.invoiceId,
    },
    { title: 'Total recycling amount', content: item?.totalRewardAmount },
    { title: 'Total value', content: item?.totalValue },
    { title: 'Total reward carbon', content: item?.totalRewardCarbon },
    { title: 'Total reward points', content: item?.totalRewardPoint },
    { title: 'Total reward coupons', content: item?.totalRewardCoupon },
  ];
  const promptRightButton = event ? capitalizeFirstLetter(event) : '';

  const clearRejectReason = () => {
    setRejectReason({ value: null, label: null });
  };

  const handleOnHide = () => {
    onHide();
    setReasons(RejectReasons);
    clearRejectReason();
  };

  const localFilterAction = (search = '') => {
    setReasons(RejectReasons.filter(item => item.name.toLowerCase().includes(search.toLowerCase())));
  };

  const getActionPromptDetailItems = () => {
    return (
      <div>
        {promptDetailItems.map((item, index) => {
          if (item.content === null || item?.content?.length === 0 || item?.content === 0) {
            return null;
          }
          return (
            <div className="deactive-content-row" key={index}>
              <label className="deactive-itme-name">{item.title}</label>
              <label className="deactive-item-value">{item.content}</label>
            </div>
          );
        })}
        {event === "reject"
          ? <CustomTitleWithDropDown
            title={"Reason to reject"}
            defaultValue={rejectReason}
            setValue={setRejectReason}
            source={reasons}
            localFilterAction={localFilterAction}
            showRemoveButton={true}
            onRemoveButtonClick={clearRejectReason}
            extraTitleClass='reject-reason-dropdown'
          />
          : null
        }
      </div>
    );
  };

  return (
    <BaseActionModal
      modalClassName={'transaction-modal'}
      className={'transaction-action-prompt'}
      showModal={show}
      onHide={handleOnHide}
      modalTitle={promptTitle}
      modalGeneralContent={promptMessage}
      modalDetailContent={getActionPromptDetailItems()}
      actionName={promptRightButton}
      action={() => {
        action();
      }}
    />
  );
}

export default TransactionActionPrompt;
