import React, { useState } from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
    FilterTypeEnum,
} from '../../components/base/BaseFilter';
import { ArrayStringData } from '../../utils';
import { productTypes } from '../../models/BarcodeModel'
import { BarcodeType, BarcodeValidateStatus } from '../../config/CustomEnums'

function Filter({ backAction = () => { } }) {
    const location = useLocation();
    const brandList = useSelector((state) => state.brand.brandList);
    const parsedSearch = queryString.parse(location.search);
    const searchBrands = parsedSearch['brand'] || '';
    const searchProducts = parsedSearch['product_type'] || '';
    const searchBarcodeType = parsedSearch['type'];
    const searchBarcodeStatus = parsedSearch['barcodeStatus'] || '';

    const typeData = [
        { name: "All", pk: BarcodeType.all },
        { name: "Partner", pk: BarcodeType.partner },
        { name: "Non Partner", pk: BarcodeType.nonPartner }
    ]

    const [brands, setBrands] = useState(ArrayStringData(searchBrands));
    const [selectedTypes, setProductTypes] = useState(ArrayStringData(searchProducts));
    const [barcodeType, setBarcodeType] = useState(searchBarcodeType)
    const [barcodeStatus, setBarcodeStatus] = useState(ArrayStringData(searchBarcodeStatus));

    const content = [
        {
            title: 'Product Type',
            data: productTypes,
            value: selectedTypes,
            setValue: setProductTypes,
            componment: FilterTypeEnum.choice,
        },
        {
            title: 'Type',
            data: typeData,
            value: barcodeType,
            setValue: setBarcodeType,
            componment: FilterTypeEnum.singleChoice,
        },
        {
            title: 'Brand',
            data: brandList,
            value: brands,
            setValue: setBrands,
            componment: FilterTypeEnum.choice,
        },
        {
            title: 'Status',
            key: 'barcodeStatus',
            data: [
                {
                    name: BarcodeValidateStatus.WAITING_FOR_APPROVAL.name,
                    pk: BarcodeValidateStatus.WAITING_FOR_APPROVAL.value,
                },
                {
                    name: BarcodeValidateStatus.APPROVED.name,
                    pk: BarcodeValidateStatus.APPROVED.value,
                },
                {
                    name: BarcodeValidateStatus.REJECTED.name,
                    pk: BarcodeValidateStatus.REJECTED.value,
                },
            ],
            value: barcodeStatus,
            setValue: setBarcodeStatus,
            componment: FilterTypeEnum.choice,
        },
    ];

    return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
