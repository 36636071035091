import React from 'react';
import { useSelector } from 'react-redux';
import MutipleLanguageContentCard from '../../../components/base/BaseMutipleLanguageContentCard';
import ContentSections from '../../../components/base/ContentSections';
import Loading from '../../../components/base/Loading';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';

function CommunityOverview() {
  const { object, objectLoading } = useSelector((state) => ({
    object: state.communityModel.oneObject || {},
    objectLoading: state.communityModel.objectLoading,
  }));

  return (
    <ContentSections
      sections={
        objectLoading
          ? [<Loading />]
          : [
              <MembershipInfoCard
                title="information"
                data={object}
                fields={[
                  [{ title: 'Community Code', field: 'communityCode' }],
                  [{ title: 'Community Limit', field: 'communityLimit' }],
                  [{ title: 'Photo image', field: 'coverPhoto' }],
                  [{ title: 'All Joined Users', field: 'numberOfCustomer' }],
                  [{ title: 'Create at', field: 'creationDateDisplay' }],
                  [{ title: 'Last Modified ', field: 'lastModifiedDateDisplay' }],
                ]}
              />,
              <MutipleLanguageContentCard
                fields={[
                  { displayName: 'Title', name: 'name' },
                  {
                    displayName: 'Terms & Condition',
                    name: 'termsAndCondition',
                  },
                ]}
                data={object}
              />,
            ]
      }
      hidePreview
    />
  );
}

export default CommunityOverview;
