import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import AuthButton from '../../../components/base/AuthButton';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { NavBarNames } from '../../../config/NavBarNameList';
import { createAction } from '../../../utils';
import BaseListContainer from '../../base/BaseListContainer';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import DeletePrompt from '../../../components/base/DeletePrompt';

function GuideImageList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { listFields, dataList, pageInfo, totalPage, totalCount } = useSelector(
    (state) => ({
      listFields: state.guideImage.listDisplayFields,
      dataList: state.guideImage.guideImageList,
      pageInfo: state.guideImage.pageInfo,
      totalPage: state.guideImage.totalPage,
      totalCount: state.guideImage.totalCount,
    }),
  );

  const tabs = [
    {
      name: 'List of Guide Images',
      content: (
        <BaseTabListContainer
          hideTab={true}
          hideSearch={true}
          tabs={[]}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'guideImage'}
              permissionGroup={PermissionCodes.viewGuidemodel}
              actions={'Edit'}
              deleteInfo={() => {}}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: `guideImage/clearData` });
    dispatch(
      createAction('guideImage/getGuideImages')({
        page: 1,
        reverse: true,
      }),
    );
  }, [dispatch, history.location]);

  return (
      <CustomListComponent
        caution={{
          title: NavBarNames.guideImage,
        }}
        breadcrumb={<CustomBreadcrumb />}
        buttons={[]}
        tabs={tabs}
        hideTab={true}
      />
  );
}

export default GuideImageList;
