import {
  getSplashAds,
  getSplashAd,
  createSplashAd,
  updateSplashAd,
  deleteSplashAds,
  publishSplashAd,
  unPublishSplashAd,
} from '../services/SplashAdApiHelper';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  removeFromSessionStorage,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  getFileNameFromUrl,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { getCampignListDisplayTime, getDisplayDate } from '../utils/TimeFormatUtil';
import {
  LanguageConfig,
  APIStatus,
  PublishTagType,
} from '../config/CustomEnums';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { getTranslationForCampaign } from './CreateCampaignModel';
import { checkCampaignIsExpired } from '../utils/TimeFormatUtil';
import imageTypeImage from '../assets/images/splashAd/image_type.png';
import seeMoreTypeImage from '../assets/images/splashAd/see_more_type.png';
import gotItTypeImage from '../assets/images/splashAd/got_it_type.png';

export const SplashAdTypes = {
  "IMAGE": {
    value: "IMAGE",
    label: 'Image',
    image: imageTypeImage,
  },
  "IMAGE_WITH_SEE_MORE_BUTTON": {
    value: "IMAGE_WITH_SEE_MORE_BUTTON",
    label: 'Image & "See more" button',
    image: seeMoreTypeImage,
  },
  "IMAGE_WITH_GOT_IT_BUTTON": {
    value: "IMAGE_WITH_GOT_IT_BUTTON",
    label: 'Image & "Got it!" button',
    image: gotItTypeImage,
  }
};

export const SplashAdTypesList = Object.values(SplashAdTypes);

export const SplashAdRedirectType = {
  null: {
    label: "none",
    value: null,
  },
  "EXTERNAL_URL": {
    label: "External URL",
    value: "EXTERNAL_URL"
  },
  "IN_APP_CAMPAIGN": {
    label: "In-app campaign",
    value: "IN_APP_CAMPAIGN"
  }
};

export const SplashAdRedirectList = Object.values(SplashAdRedirectType);

export const sessionDataKey = {
  objectKey: 'createSplashAd',
  stepEndKey: 'createSplashAdStepEnd',
  origionalData: 'createSplashAdOriginalData',
};

export const CreateSplashAdError = {
  name: {
    name: 'name',
    message: 'Please provide a name'
  },
  displayOrder: {
    name: 'displayOrder',
    message: 'Please provide a display order',
  },
  loadingDelay: {
    name: 'loadingDelay',
    message: 'Please provide a correct number',
  },
  displayFrequencyLimit: {
    name: 'displayFrequencyLimit',
    message: 'Please provide a correct number',
  },
  displayFrequencyLimitNumberOfDays: {
    name: 'displayFrequencyLimitNumberOfDays',
    message: 'Please provide a correct number',
  },
  image: {
    name: 'image',
    message: 'Please provide an image',
  },
  redirectType: {
    name: 'redirectType',
    message: 'Please provide a call to action',
  },
  externalUrl: {
    name: 'externalUrl',
    message: 'Please provide an external url',
  },
  inAppCampaign: {
    name: 'inAppCampaign',
    message: 'Please select in app campaign',
  },
  visibleEndDate: {
    name: 'visibleEndDate',
    message: 'The end date & time of a splash ad cannot be on or before the start date and time.',
  },
};

const stepNames = [''];

const getInitialState = () => ({
  splashAdList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    { displayName: 'Name', fieldName: 'name' },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Display order', fieldName: 'displayPriority', orderField: 'displayPriority' },
    { displayName: 'Splash ad Style', fieldName: 'typeDisplay' },
    { displayName: 'Visible Period', fieldName: 'displayVisiblePeriod' },
    { displayName: 'Last Modified', fieldName: 'lastModifiedDateDisplay' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  currentPageSplashAdList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  splashAdDetail: {
    type: SplashAdTypes.IMAGE.value,
    defaultLoadingDelay: true,
    loadingDelay: 3,
    alwaysDisplay: true,
    redirectType: null,
    translations: {
      [LanguageConfig.english]: {},
      [LanguageConfig.simplifiedChinese]: {},
      [LanguageConfig.traditionalChinese]: {},
    },
  },
  errorFields: [],
  errorTab: LanguageConfig.english,
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  splashAdAPIStatus: APIStatus.none,
  splashAdCreated: false,
});

const parseSplashAd = (data) => {
  const type = data?.type ? SplashAdTypes?.[data?.type] : SplashAdTypes.IMAGE;

  const translationsEdges = data?.translations?.edges;
  let translations = {};
  if (translationsEdges?.length > 0) {
    translationsEdges.forEach((item) => {
      const node = item.node;
      translations[node.language] = {
        id: node.pk,
        language: node.language,
        name: node.name,
        coverPhoto: node.coverPhoto,
      };
    });
  };
  translations[LanguageConfig.english] = {
    language: LanguageConfig.english,
    name: data.name,
    coverPhoto: data.coverPhoto,
  }

  const isExpired = checkCampaignIsExpired(data.displayEndDate);
  let status = PublishTagType.unPublished;
  if (data.isPublished) {
    status = PublishTagType.published;
  };
  if (isExpired) {
    status = PublishTagType.expired;
  };

  return {
    pk: data.pk,
    name: data.name,
    coverPhoto: data.coverPhoto,
    displayPriority: data.displayPriority,
    type: type?.value,
    typeDisplay: type?.label,
    defaultLoadingDelay: data.loadingDelay === 3,
    loadingDelay: data.loadingDelay,
    alwaysDisplay: !data.displayFrequencyLimit || !data.displayFrequencyLimitNumberOfDays,
    displayFrequencyLimit: data.displayFrequencyLimit,
    displayFrequencyLimitNumberOfDays: data.displayFrequencyLimitNumberOfDays,
    redirectType: data.redirectType || null,
    redirectUrl: data.redirectUrl,
    inAppCampaign: data.campaign,
    isAlwaysVisiblePeriod: !data.displayEndDate,
    visibleStartDate: data.displayStartDate,
    visibleEndDate: data.displayEndDate,
    displayVisiblePeriod: getCampignListDisplayTime(
      data.displayStartDate,
      data.displayEndDate,
    ),
    translations,
    lastModifiedDateDisplay: getDisplayDate(data.lastModifiedDate),
    isPublished: data.isPublished,
    status,
  };
};

const assembleSingleTranslation = (data = {}, language) => {
  let idField = {};
  if (data?.id) {
    idField = { id: data.id };
  };
  return {
    ...idField,
    language,
    name: data.name || '',
    coverPhoto: getFileNameFromUrl(data.coverPhoto) || null,
  };
};

const assembleTranslations = (translations = {}) => {
  const traditionalChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.traditionalChinese],
    LanguageConfig.traditionalChinese,
  );
  const simplifiedChinese = assembleSingleTranslation(
    translations?.[LanguageConfig.simplifiedChinese],
    LanguageConfig.simplifiedChinese,
  );
  return [
    traditionalChinese,
    simplifiedChinese,
  ]
};

const assembleSplashAd = (detail = {}) => {
  const now = new Date();

  let displayStartDate = null;
  let displayEndDate = null;
  if (detail.isAlwaysVisiblePeriod) {
    displayStartDate = detail.visibleStartDate < now ? detail.visibleStartDate : now;
  } else {
    displayStartDate = detail.visibleStartDate;
    displayEndDate = detail.visibleEndDate;
  };

  const translations = assembleTranslations(detail.translations);

  return {
    name: detail.translations?.[LanguageConfig.english]?.name,
    coverPhoto: getFileNameFromUrl(detail.translations?.[LanguageConfig.english]?.coverPhoto) || null,
    displayPriority: detail.displayPriority,
    type: detail.type,
    loadingDelay: detail.defaultLoadingDelay ? 3 : detail.loadingDelay,
    displayFrequencyLimit: detail.alwaysDisplay ? null : detail.displayFrequencyLimit,
    displayFrequencyLimitNumberOfDays: detail.alwaysDisplay ? null : detail.displayFrequencyLimitNumberOfDays,
    redirectType: detail.redirectType || null,
    redirectUrl: detail.redirectType === SplashAdRedirectType.EXTERNAL_URL.value ? detail.redirectUrl : null,
    campaign: detail.redirectType === SplashAdRedirectType.IN_APP_CAMPAIGN.value ? detail.inAppCampaign?.pk : null,
    displayStartDate,
    displayEndDate,
    translations,
  };
};

const checkStepOneFields = (data) => {
  console.log("@@checkStepOneFields", data)
  let errorFields = [];
  let errorTab = LanguageConfig.english;
  if (!data?.translations?.[LanguageConfig.english]?.name) {
    errorFields.push(CreateSplashAdError.name.name);
  };
  if (!data?.displayPriority) {
    errorFields.push(CreateSplashAdError.displayOrder.name);
  };
  if (!data?.translations?.[LanguageConfig.english]?.coverPhoto) {
    errorFields.push(CreateSplashAdError.image.name);
  };
  if (!data?.defaultLoadingDelay && ((!data?.loadingDelay && data?.loadingDelay != 0) || data?.loadingDelay < 0)) {
    errorFields.push(CreateSplashAdError.loadingDelay.name);
  };
  if (!data?.alwaysDisplay && (!data?.displayFrequencyLimit || data?.displayFrequencyLimit <= 0)) {
    errorFields.push(CreateSplashAdError.displayFrequencyLimit.name);
  };
  if (!data?.alwaysDisplay && (!data?.displayFrequencyLimitNumberOfDays || data?.displayFrequencyLimitNumberOfDays <= 0)) {
    errorFields.push(CreateSplashAdError.displayFrequencyLimitNumberOfDays.name);
  };
  if (data?.type === SplashAdTypes.IMAGE_WITH_SEE_MORE_BUTTON.value) {
    if (!data?.redirectType) {
      errorFields.push(CreateSplashAdError.redirectType.name);
    };
    if (data?.redirectType === SplashAdRedirectType.EXTERNAL_URL && !data?.redirectUrl) {
      errorFields.push(CreateSplashAdError.externalUrl.name);
    };
    if (data?.redirectType === SplashAdRedirectType.IN_APP_CAMPAIGN && !data?.inAppCampaign?.pk) {
      errorFields.push(CreateSplashAdError.inAppCampaign.name);
    };
  };

  const visibleStartDate = new Date(data?.visibleStartDate);
  const visibleEndDate = new Date(data?.visibleEndDate);
  if (
    !data?.isAlwaysVisiblePeriod &&
    visibleStartDate.getTime() >= visibleEndDate.getTime()
  ) {
    errorFields.push(CreateSplashAdError.visibleEndDate.name);
  }
  return {
    data,
    invalid: errorFields.length > 0,
    errorFields: errorFields,
    errorTab,
  };
};


export default {
  namespace: 'splashAd',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateSplashAdDetail(state, { payload }) {
      const splashAdDetail = {
        ...state.splashAdDetail,
        ...payload,
      };
      console.log("@@updateSplashAdDetail", splashAdDetail)

      saveToSessionStorage(sessionDataKey.objectKey, splashAdDetail);
      return {
        ...state,
        splashAdDetail,
      }
    },
    updateSplashAdDetailTranslations(state, { payload }) {
      const splashAdDetail = {
        ...state.splashAdDetail,
        translations: {
          ...state.splashAdDetail.translations,
          [payload.language]: {
            ...state.splashAdDetail.translations[payload.language],
            ...payload,
          }
        }
      };
      console.log("@@updateSplashAdDetailTranslations", splashAdDetail)

      saveToSessionStorage(sessionDataKey.objectKey, splashAdDetail);
      return {
        ...state,
        splashAdDetail
      }
    },
    clearState(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
    saveOrRemoveSplashAdFromCookie(state, { payload }) {
      console.log("@@saveOrRemoveSplashAdFromCookie", payload)
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

  },
  effects: {
    loadSplashAdFromCookie: [
      function* ({ payload }, { put, select }) {
        const splashAd = getObjectFromSessionStorage(sessionDataKey.objectKey);
        console.log("@@379", splashAd);

        if (splashAd) {
          yield put({
            type: 'updateState',
            payload: {
              splashAdDetail: splashAd,
            }
          })
        }
        if (payload?.afterAction) {
          payload?.afterAction();
        };
      }
    ],

    getCurrentPageSplashAds: [
      function* ({ payload }, { put }) {
        const { page, searchKey, sort } = payload;

        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getSplashAds,
          {
            afterCursor,
            order: sort,
            search: searchKey,
            others: payload,
          }
        ];
        function* onSuccess(data) {
          const pageInfo = data.splashAds.pageInfo;
          const totalCount = data.splashAds.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const splashAdData = data.splashAds.edges;
          const splashAdList = splashAdData.map((item) =>
            parseSplashAd(item.node),
          );
          yield put({
            type: 'updateState',
            payload: {
              currentPageSplashAdList: splashAdList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              totalCount,
              currentLastCursor,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSplashAd: [
      function* ({ payload }, { put, select }) {
        const { id } = payload;
        const splashAdID = btoa(`SplashAdNode:${id}`);
        const serviceArgs = [getSplashAd, splashAdID];

        yield put({
          type: 'updateState',
          payload: {
            splashAdAPIStatus: APIStatus.calling,
          },
        });

        function* onSuccess(data) {
          const splashAdData = data.splashAd;
          const splashAd = parseSplashAd(splashAdData);

          yield put({
            type: 'updateState',
            payload: {
              splashAdDetail: splashAd,
              splashAdAPIStatus: APIStatus.success,
            },
          });

          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    checkValid: [
      function* ({ payload }, { put, select }) {
        const { splashAdDetail } = yield select((state) => ({
          splashAdDetail: state.splashAd.splashAdDetail,
        }));
        const result = checkStepOneFields(splashAdDetail);
        console.log("@@465", splashAdDetail, result)

        yield put({
          type: 'updateState',
          payload: {
            errorFields: result.errorFields,
            errorTab: result.errorTab,
          },
        });

        if (!result.invalid) {
          yield put({
            type: 'createOrUpdateSplashAd',
            payload: {
              id: splashAdDetail?.pk,
              hasPublishOrUnpublishAction: payload?.hasPublishOrUnpublishAction,
              afterAction: payload?.afterAction,
            }
          })
        };
      },
    ],
    delete: [
      function* ({ payload }, { put, select }) {
        const serviceArgs = [deleteSplashAds, payload?.deletePks];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateSplashAd: [
      function* ({ payload }, { put, select }) {
        const id = payload?.id || null;
        const { splashAdDetail } = yield select((state) => ({
          splashAdDetail: state.splashAd.splashAdDetail,
        }));
        let newSplashAdDetail = assembleSplashAd(splashAdDetail);
        if (id) {
          newSplashAdDetail.id = id;
        };
        if (payload?.displayPriority) {
          const originalSplashAd = payload?.originalSplashAd || {};
          console.log("@@491", originalSplashAd)
          newSplashAdDetail = {
            id,
            name: originalSplashAd.name,
            coverPhoto: getFileNameFromUrl(originalSplashAd.coverPhoto) || null,
            displayPriority: payload?.displayPriority,
            type: originalSplashAd.type,
            loadingDelay: originalSplashAd.loadingDelay,
            redirectType: originalSplashAd.redirectType,
            redirectUrl: originalSplashAd.redirectUrl,
            campaign: originalSplashAd.inAppCampaign?.pk,
          };
        };

        const serviceArgs = [id ? updateSplashAd : createSplashAd, newSplashAdDetail];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(successData) {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({
            type: 'updateState',
            payload: {
              splashAdCreated: !payload?.displayPriority,
            },
          });
          if (payload.hasPublishOrUnpublishAction && successData) {
            const splashAdNode = successData?.createSplashad?.node || successData?.updateSplashad?.node;
            yield put.resolve({
              type: 'publishOrUnpublishSplashAd',
              payload: {
                id: splashAdNode?.pk,
                publish: !splashAdNode?.isPublished,
              },
            });
          };
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
    ],
    publishOrUnpublishSplashAd: [
      function* ({ payload }, { put }) {
        const serviceArgs = [payload?.publish ? publishSplashAd : unPublishSplashAd, { id: payload.id }];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => { });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
