import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { loading } from './LoadingUtil';
import {
  APIStatus,
  LanguageConfig,
  MessageChannel,
} from '../config/CustomEnums';
import {
  createMessage,
  sendTestMessageBeforeCreate,
  sendTestMessageBeforeUpdate,
  updateMessage,
  createInformationBarMessage,
  updateInformationBarMessage
} from '../services/MessageApiHelper';
import {
  createAction,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { InformationBarMessageType, MessageType } from '../components/message/CreateMessageConfig';

export const CreateMessageError = {
  name: {
    name: 'name',
    message: 'Please provide a name.',
  },
  content: {
    name: 'content',
    message: 'Please provide a content.',
  },
  title: {
    name: 'title',
    message: 'Please provide a title.',
  },
  infoBarName: {
    name: 'infoBarName',
    message: 'Please provide a name.',
  },
  infoBarContent: {
    name: 'infoBarContent',
    message: 'Please provide a content.',
  },
  messageId: {
    name: 'messageId',
    message: 'Please provide a message.',
  },
  scheduledDate: {
    name: 'scheduledDate',
    message: 'Please provide a schedule time.',
  },
};

export const sessionDataKey = {
  objectKey: 'createMessage',
  stepEndKey: 'createMessageStepEnd',
  origionalData: 'createMessageOriginalData',
  objectKeyInformationBar: 'createInformationBarMessage',
  stepEndKeyInformationBar: 'createInformationBarMessageStepEnd',
  originalDataInformationBar: 'createInformationBarMessageOriginalData',
};

const getMessageInitState = () => {
  return {
    pk: null,
    id: null,
    name: null,
    content: null,
    photo: null,
    channels: [],
    displayChannels: null,
    relatedCampaign: {
      pk: null,
      name: null,
    },
    informationBar: {
      id: null,
      name: '',
      content: '',
      scheduledStartDate: null,
      scheduledEndDate: null,
      displayScheduledStartDate: null,
      displayScheduledEndDate: null,
      translations: {},
      messageId: null,
      message: null
    },
    relatedCampaignName: null,
    targetCustomerGroup: null,
    displayTargetCustomer: null,
    targetedSegments: null,
    displayTargetedSegments: null,
    scheduledDate: null,
    deliveryDate: null,
    translations: {},
    messageType: null,
  };
};

const stepNames = ['Channel', 'Content', 'Property', 'Preview & Send'];

const getInitialState = () => {
  return {
    message: getMessageInitState(),
    errorFields: [],
    stepConfig: defaultStep(stepNames),
    currentStep: 0,
    languageTag: LanguageConfig.english,
    createStatus: APIStatus.none,
    scheduledMessage: false,
  };
};

const parsePhotoName = (photo) => {
  let photoNameArray = [];
  let tempPhoto = photo;
  if (Array.isArray(tempPhoto) && tempPhoto.length > 0) {
    tempPhoto = tempPhoto[0];
  } else {
    return null;
  }
  if (tempPhoto.type) {
    photoNameArray = tempPhoto.value.split('/');
  } else {
    photoNameArray = tempPhoto.split('/');
  }
  const photoName = photoNameArray[photoNameArray.length - 1];
  return photoName;
};

const parseInformationBarMessageInputBody = (message, isEdit) => {
  console.log('@parse', message)
  let inputBody = {}

  const informationBar = message.informationBar

  let isAllUser = true

  if (message?.targetCustomerGroups?.length > 0 || message?.targetedSegments?.length > 0) {
    isAllUser = false
  }

  const translation = informationBar.translations['zh-Hant']

  delete translation.message
  delete translation.messageTitle

  const zhHans = {
    id: translation.pk,
    name: translation.name,
    content: translation.content,
    language: translation.language
  }
  delete zhHans['message']


  if (message.messageType == InformationBarMessageType.maintenance) {
    inputBody = {
      informationBar: {
        name: informationBar.name,
        content: informationBar.content,
        informationType: 'MAINTENANCE',
        isAllUser: isAllUser,
        scheduledStartDate: informationBar.scheduledStartDate,
        scheduledEndDate: informationBar.scheduledEndDate,
        messageId: informationBar.messageId,
        translations: [
          translation
        ]
      }
    }
  } else if (message.messageType == InformationBarMessageType.others) {
    inputBody = {
      title: message.name,
      content: message.content,
      photo: message.photo ? parsePhotoName(message.photo) : null,
      targetedCustomerGroups:
        message.targetCustomerGroup?.length > 0
          ? message.targetCustomerGroup.map((item) => item.pk)
          : [],
      targetedSegments: message.targetedSegments?.length
        ? message.targetedSegments.map((item) => item.pk)
        : [],
      url: message.url,
      informationBar: {
        informationType: 'OTHERS',
        name: message.informationBar.name,
        content: message.informationBar.content,
        scheduledStartDate: message.informationBar.scheduledStartDate,
        scheduledEndDate: message.informationBar.scheduledEndDate,
        isAllUser: isAllUser,
        translations: [
          // translation,
          zhHans
        ]
      }
    }
  };

  if (isEdit) {
    inputBody.id = message.pk
    // inputBody.informationBar.id = message.informationBar.pk
  }

  return inputBody;
};

const parseMessageInputBody = (message, isDraft, isEdit = false) => {
  const parsedChannels = message.channels.map((item) => {
    if (item === MessageChannel.inbox) {
      return 'INBOX';
    }
    if (item === MessageChannel.push) {
      return 'PUSH_NOTIFICATION';
    }
  });

  let inputBody = {
    channels: parsedChannels,
    scheduledDate: message.scheduledDate || new Date(),
    title: message.name,
    content: message.content,
    photo: message.photo ? parsePhotoName(message.photo) : null,
    isExclusive:
      message.targetCustomerGroup?.length > 0 ||
      message.targetedSegments?.length > 0,
    targetedCustomerGroups:
      message.targetCustomerGroup?.length > 0
        ? message.targetCustomerGroup.map((item) => item.pk)
        : [],
    targetedSegments: message.targetedSegments?.length
      ? message.targetedSegments.map((item) => item.pk)
      : [],
    campaign: message.relatedCampaign?.pk,
    isDraft: isDraft,
    messageType: message.messageType,
    url: message.url,
  };

  if (isEdit) {
    inputBody.id = message.pk;
  }

  return inputBody;
};

const parseMessageTranslationInput = (translation, messagePK, language) => {
  return {
    // source: messagePK,
    language: language,
    title: translation.name,
    content: translation.content,
    photo: translation.photo ? parsePhotoName(translation.photo) : null,
    id: translation.pk,
  };
};

const checkMaintenanceStepTwoFields = (data, isBack) => {
  const name = data.informationBar?.name;
  const content = data.informationBar?.content;
  const messageId = data.informationBar?.messageId;
  let errorFields = [];
  if (!name) {
    errorFields.push(CreateMessageError.name.name);
  }
  if (!content) {
    errorFields.push(CreateMessageError.content.name);
  }
  if (!messageId) {
    errorFields.push(CreateMessageError.messageId.name);
  }
  return {
    data,
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
  };
};

const checkOthersStepTwoFields = (data, isBack) => {
  const name = data?.name;
  const content = data?.content;
  const infoBarName = data.informationBar?.name;
  const infoBarContent = data.informationBar?.content;
  let errorFields = [];
  if (!name || name.length == 0) {
    errorFields.push(CreateMessageError.title.name);
  }
  if (!content || content.length == 0) {
    errorFields.push(CreateMessageError.content.name);
  }
  if (!infoBarName || infoBarName.length == 0) {
    errorFields.push(CreateMessageError.infoBarName.name);
  }
  if (!infoBarContent || infoBarContent.length == 0) {
    errorFields.push(CreateMessageError.infoBarContent.name);
  }
  return {
    data,
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
  };
};

const checkStepTwoFields = (data, isBack) => {
  const content = data.content;
  let errorFields = [];
  if (!content) {
    errorFields.push(CreateMessageError.content.name);
  }
  return {
    data,
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
  };
};

const checkStepThreeFields = (data, isBack) => {
  const scheduledDate = data.scheduledDate;
  let errorFields = [];
  if (!scheduledDate) {
    errorFields.push(CreateMessageError.scheduledDate.name);
  }

  return {
    data: {
      ...data,
      isExclusive:
        data.targetedSegments?.length > 0 ||
        data.targetCustomerGroup?.length > 0,
    },
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
  };
};

const setStepConfig = (messageType) => {
  return messageType === MessageType.welcomeMessage
    ? defaultStep(stepNames.filter((val) => val !== 'Property'))
    : defaultStep(stepNames);
};

export default {
  namespace: 'createMessage',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateWithStepConfig(state, { payload }) {
      return {
        ...state,
        ...payload,
        stepConfig: setStepConfig(payload.message?.messageType),
      };
    },
    updateInformationBarMessageState(state, { payload }) {

    },
    updateMessageState(state, { payload }) {
      console.log('update')
      console.log(state)
      console.log(payload)

      const informationBar = {
        ...state.message.informationBar,
        ...payload.informationBar
      }
      const message = {
        ...state.message,
        ...payload,
        informationBar: informationBar
      };
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: message,
        stepConfig:
          state.currentStep === 0
            ? setStepConfig(message.messageType)
            : state.stepConfig,
      };
    },

    updateMessageTranslation(state, { payload }) {
      const { language } = payload;
      const message = {
        ...state.message.translations,
        [language]: {
          ...payload,
        },
      };
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: {
          ...state.message,
          translations: message,
        },
      };
    },

    loadMessageFromCookie(state, { payload }) {
      const message = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!message) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, message);
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: message,
        stepConfig: setStepConfig(message.messageType),
      };
    },

    saveOrRemoveMessageFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    stepChange(state, { payload }) {
      console.log('@step', payload)
      console.log('@step', state.message)
      const isBack = payload.isBack;
      const data = payload.data;
      const message = state.message;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: [] };

      console.log("@stepdata", payload)

      if (message.messageType === InformationBarMessageType.maintenance) {
        if (step === 1) {
          result = checkMaintenanceStepTwoFields(data, isBack);
        } else {
          result.data = data
        }
      } else if (message.messageType === InformationBarMessageType.others) {
        if (step === 1) {
          result = checkOthersStepTwoFields(data, isBack);
        } else {
          result.data = data
        }
      } else if (step === 1 && (message.messageType !== InformationBarMessageType.maintenance || messageType !== InformationBarMessageType.others)) {
        result = checkStepTwoFields(data, isBack);
      } else if (step === 2 && (message.messageType !== InformationBarMessageType.maintenance || messageType !== InformationBarMessageType.others)) {
        result = checkStepThreeFields(message, isBack);
      }

      const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );

      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }

      return {
        ...state,
        currentStep: step,
        stepConfig,
        errorFields: errorFields,
        message: {
          ...state.message,
          ...result.data,
          informationBar: {
            ...state.message.informationBar,
            ...result.data?.informationBar
          }
        },
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    createInformationBarMessage: [
      function* ({ payload }, { select, put }) {
        const message = yield select((state) => state.createMessage.message);

        const parsedInputBody = parseInformationBarMessageInputBody(message, false);

        let input = {}
        if (message.messageType === InformationBarMessageType.maintenance) {
          input = parsedInputBody

        } else if (message.messageType === InformationBarMessageType.others) {
          input = {
            ...parsedInputBody,
            translations: [
              message.translations[LanguageConfig.traditionalChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.traditionalChinese],
                  message.pk,
                  LanguageConfig.traditionalChinese,
                )
                : {},
              message.translations[LanguageConfig.simplifiedChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.simplifiedChinese],
                  message.pk,
                  LanguageConfig.simplifiedChinese,
                )
                : {},
            ],
          }
        }

        const serviceArgs = [
          createInformationBarMessage,
          input
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(data) {
          console.log('success')
          console.log(data)
          const informationBarMessageData = data.createInformationBarMessage.informationBarNode;
          yield put(
            createAction('updateMessageState')({
              informationBar: {
                pk: informationBarMessageData.pk,
                id: informationBarMessageData.id
              }
            }),
          );
          yield put(
            createAction('updateState')({
              scheduledMessage: true,
            }),
          );

          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateInformationBarMessage: [
      function* ({ payload }, { select }) {
        const message = yield select((state) => state.createMessage.message);

        if (message.displayMessageType === "Information Bar - Others") {
          message.informationBar.informationType = "OTHERS"
        } else if (message.displayMessageType === "Information Bar - Maintenance") {
          message.informationBar.informationType = "MAINTENANCE"
        }

        const inputBody = parseInformationBarMessageInputBody(message, true);

        console.log('@message', message)

        let input = {
          informationBar: {
            ...inputBody.informationBar
          }
        }

        if (message.messageType === InformationBarMessageType.others) {
          input = {
            ...inputBody,
            translations: [
              message.translations[LanguageConfig.traditionalChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.traditionalChinese],
                  message.pk,
                  LanguageConfig.traditionalChinese,
                )
                : {},
              message.translations[LanguageConfig.simplifiedChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.simplifiedChinese],
                  message.pk,
                  LanguageConfig.simplifiedChinese,
                )
                : {},
            ],
          }
        }

        const serviceArgs = [
          updateInformationBarMessage,
          input
        ];

        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createMessage: [
      function* ({ payload }, { select, put }) {
        const { isDraft } = payload;
        const message = yield select((state) => state.createMessage.message);
        const inputBody = parseMessageInputBody(message, isDraft);
        const serviceArgs = [
          createMessage,
          {
            ...inputBody,
            translations: [
              message.translations[LanguageConfig.traditionalChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.traditionalChinese],
                  message.pk,
                  LanguageConfig.traditionalChinese,
                )
                : {},
              message.translations[LanguageConfig.simplifiedChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.simplifiedChinese],
                  message.pk,
                  LanguageConfig.simplifiedChinese,
                )
                : {},
            ],
          },
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(data) {
          const messageData = data.createMessage.node;

          yield put(
            createAction('updateMessageState')({
              pk: messageData.pk,
              id: messageData.id,
            }),
          );
          yield put(
            createAction('updateState')({
              scheduledMessage: true,
            }),
          );

          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    testMessageSend: [
      function* ({ payload }, { select }) {
        const message = yield select((state) => state.createMessage.message);
        const customer = payload.customer;
        const isEdit = payload.isEdit;
        let inputBody = parseMessageInputBody(message);
        inputBody.customer = customer.pk;
        if (!customer?.pk) {
          return;
        }
        let translations = message.translations;
        let translationInputBody = [];
        if (translations[LanguageConfig.traditionalChinese]) {
          const traditionalChineseInputBody = parseMessageTranslationInput(
            translations[LanguageConfig.traditionalChinese],
            message.pk,
            LanguageConfig.traditionalChinese,
          );
          delete traditionalChineseInputBody['source'];
          translationInputBody.push(traditionalChineseInputBody);
        }
        if (translations[LanguageConfig.simplifiedChinese]) {
          const simplifiedChineseInputBody = parseMessageTranslationInput(
            translations[LanguageConfig.simplifiedChinese],
            message.pk,
            LanguageConfig.simplifiedChinese,
          );
          delete simplifiedChineseInputBody['source'];
          translationInputBody.push(simplifiedChineseInputBody);
        }
        inputBody = {
          ...inputBody,
          translations: translationInputBody,
        };
        let testService = sendTestMessageBeforeCreate;
        if (isEdit) {
          inputBody.id = message.pk;
          testService = sendTestMessageBeforeUpdate;
        } else {
          inputBody.isDraft = true;
        }
        const serviceArgs = [testService, inputBody];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateMessage: [
      function* ({ payload }, { select, put }) {
        const { isDraft } = payload;
        const message = yield select((state) => state.createMessage.message);
        const inputBody = parseMessageInputBody(message, isDraft, true);
        const serviceArgs = [
          updateMessage,
          {
            ...inputBody,
            translations: [
              message.translations[LanguageConfig.traditionalChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.traditionalChinese],
                  message.pk,
                  LanguageConfig.traditionalChinese,
                )
                : {},
              message.translations[LanguageConfig.simplifiedChinese]
                ? parseMessageTranslationInput(
                  message.translations[LanguageConfig.simplifiedChinese],
                  message.pk,
                  LanguageConfig.simplifiedChinese,
                )
                : {},
            ],
          },
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          // yield put(createAction('createAndUpdateMessageTranslation')());
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
