import { BaseHelper } from './BaseGraphQLAPIHelper';

const splashAdNode = `
  id
  pk
  creationDate
  lastModifiedDate
  name
  coverPhoto
  displayPriority
  type
  loadingDelay
  displayFrequencyLimit
  displayFrequencyLimitNumberOfDays
  redirectType
  redirectUrl
  campaign {
    pk
    name
  }
  displayStartDate
  displayEndDate
  isPublished
  translations {
    edges {
      node {
        pk
        language
        name
        coverPhoto
      }
    }
  }
`;

export const getSplashAds = ({
  afterCursor = '',
  order,
  search = '',
  others = {},
}) => {
  console.log("@@42", others)
  let orderBy = 'displayPriority';
  if (order) {
    orderBy = order;
  };
  let userFilter = '';
  if (others?.splash_ad_style) {
    userFilter += `, type: "${others?.splash_ad_style}"`;
  }
  if (others?.status) {
    userFilter += `, isPublished: ${others?.status}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others?.expired}`;
  }
  if (others?.visible_period) {
    const visiblePeriod = others?.visible_period?.split(',');
    userFilter += `, displayStartDateGte: "${visiblePeriod[0]}", displayEndDateLte: "${visiblePeriod[1]}"`;
  }

  const query = `
  {
    splashAds(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains: "${search}"${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          creationDate
          lastModifiedDate
          name
          coverPhoto
          displayPriority
          type
          loadingDelay
          redirectType
          redirectUrl
          campaign {
            pk
            name
          }
          displayStartDate
          displayEndDate
          isPublished
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSplashAd = (id) => {
  const query = `
  {
    splashAd(id: "${id}") {
      ${splashAdNode}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createSplashAd = (inputBody) => {
  const query = `
  mutation CreateSplashAd($input: CreateSplashAdInput!) {
    createSplashad(input: $input) {
      node {
        ${splashAdNode}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateSplashAd = (inputBody) => {
  const query = `
  mutation UpdateSplashAd($input: UpdateSplashAdInput!) {
    updateSplashad(input: $input) {
      node {
        ${splashAdNode}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSplashAds = (ids) => {
  const query = `
  mutation DeleteSplashAds($input: DeleteSplashAdsInput!) {
    deleteSplashads(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishSplashAd = (input) => {
  const query = `mutation PublishSplashAd($input: PublishSplashAdInput!) {
    publishSplashad(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishSplashAd = (input) => {
  const query = `mutation UnpublishSplashAd($input: UnpublishSplashAdInput!) {
    unpublishSplashad(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
