import React, { Component } from 'react';
import CouponGeneral from '../../../components/coupon/couponCreation/CouponGeneral';
import './CreateCouponStepOne.scss';
import CouponKeyVisual from '../../../components/coupon/couponCreation/CouponKeyVisual';
import CouponInstructionSection from '../../../components/coupon/couponCreation/CouponInstructionSection';
import CouponDefaultPreview from '../../../assets/images/coupon/preview/SingleCoupon_default.png';
import CouponGeneralName from '../../../assets/images/coupon/preview/SingleCoupon_title.png';
import CouponGeneralBrand from '../../../assets/images/coupon/preview/SingleCoupon_brand.png';
import CouponGeneralStore from '../../../assets/images/coupon/preview/SingleCoupon_store.png';
import CouponKeyVisualImage from '../../../assets/images/coupon/preview/SingleCoupon_image.png';
import CouponInstructionTitle from '../../../assets/images/coupon/preview/SingleCoupon_instructionTitle.png';
import CouponInstructionContent from '../../../assets/images/coupon/preview/SingleCoupon_instructionContent.png';
import CouponDetailTitle from '../../../assets/images/coupon/preview/SingleCoupon_detailTitle.png';
import CouponDetailContent from '../../../assets/images/coupon/preview/SingleCoupon_detailContent.png';
import { LanguageConfig } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import CouponDetailSection from '../../../components/coupon/couponCreation/CouponDetailSection';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';

const inputWithImageConfig = {
  name: CouponGeneralName,
  relatedBrand: CouponGeneralBrand,
  selectedStores: CouponGeneralStore,
  coverPhoto: CouponKeyVisualImage,
  otherPhotos: CouponKeyVisualImage,
  instructionTitle: CouponInstructionTitle,
  instructionContent: CouponInstructionContent,
  detailsTitle: CouponDetailTitle,
  detailsContent: CouponDetailContent,
};
const defaultImage = CouponDefaultPreview;

@connect(({ createCoupon }) => ({
  couponEnData:
    createCoupon.couponTemplate.translations[LanguageConfig.english],
  currentStep: createCoupon.currentStep,
  errorFields: createCoupon.errorFields,
}))
class CreateCouponStepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: null,
      continueBtnClicked: false,
    };
    this.dataArray = [];
  }

  componentDidUpdate(prevProps, preState) {
    const currentContinue = this.state.continueBtnClicked;
    if (currentContinue && currentContinue !== preState.continueBtnClicked) {
      const formatCouponSetData = this.parseDataArray();
      this.props.dispatch(
        createAction('createCoupon/stepChange')({
          data: formatCouponSetData,
          step: 0,
          isBack: false,
        }),
      );
    }
    if (prevProps.errorFields !== this.props.errorFields && this.props.errorFields.length > 0) {
      this.setState({
        continueBtnClicked: false
      })
    }
  }

  parseDataArray = () => {
    let formatData = {};
    this.dataArray.forEach((item) => {
      const language = item.language;
      const data = item.data;
      const translations = formatData.translations;
      const formDataWithLanguage = translations?.[language] || {};
      formatData = {
        ...formatData,
        translations: {
          ...translations,
          [language]: {
            ...formDataWithLanguage,
            ...data,
          },
        },
      };
    });
    return formatData;
  };

  render() {
    const getTabs = (language) => {
      const onSubmit = this.state.continueBtnClicked;
      return [
        <CouponGeneral
          language={language}
          onSubmit={onSubmit}
          onSubmitAction={(data) => {
            this.dataArray.push({ language, data });
          }}
          inputChange={(inputArea) =>
            this.setState({ activeSection: inputArea })
          }
        />,
        <CouponKeyVisual
          onSubmit={onSubmit}
          onSubmitAction={(data) => {
            this.dataArray.push({ language, data });
          }}
          inputChange={(inputArea) =>
            this.setState({ activeSection: inputArea })
          }
          language={language}
        />,
        <CouponInstructionSection
          language={language}
          onSubmit={onSubmit}
          onSubmitAction={(data) => {
            this.dataArray.push({ language, data });
          }}
          inputChange={(inputArea) =>
            this.setState({ activeSection: inputArea })
          }
        />,
        <CouponDetailSection
          language={language}
          onSubmit={onSubmit}
          onSubmitAction={(data) => {
            this.dataArray.push({ language, data });
          }}
          inputChange={(inputArea) =>
            this.setState({ activeSection: inputArea })
          }
        />,
      ];
    };

    const languageTabConfig = {
      enContainer: getTabs(LanguageConfig.english),
      hantContainer: getTabs(LanguageConfig.traditionalChinese),
      hansContainer: getTabs(LanguageConfig.simplifiedChinese),
    };
    return (
      <div>
        <ContentSections
          languageTabContent={languageTabConfig}
          inputWithImageConfig={inputWithImageConfig}
          defaultImage={defaultImage}
          activeSection={this.state.activeSection}
        />
        <OnlyContinueButton
          continueAction={() => {
            this.setState({
              continueBtnClicked: true,
            });
          }}
        />
      </div>
    );
  }
}

export default CreateCouponStepOne;
