import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { Button } from 'react-bootstrap';


const AfcDataRecordDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { apiStatus, afcDataRecord } = useSelector((state) => ({
    afcDataRecord: state.afcDataRecords.afcDataRecord,
    apiStatus: state.afcDataRecords.apiStatus
  }));

  console.log('@28', afcDataRecord)
  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={afcDataRecord}
                fields={[
                  [{ title: 'First Name', field: 'firstName' }],
                  [{ title: 'Last Name', field: 'lastName' }],
                  [{ title: 'Membership Id', field: 'membershipId' }],
                  [{ title: 'Octopus Id', field: 'octopusId' }],
                  [{ title: 'Masked Octopus Id', field: 'maskedOctopusId' }],
                  [{ title: 'Station From', field: 'stationFrom' }],
                  [{ title: 'Station To', field: 'stationTo' }],
                  [{ title: 'Transaction Datetime', field: 'displayTransactionDatetime' }],
                  [{ title: 'Status', field: 'status' }],
                ]}
                // extraDataFields={{ numberOfcustomers: customerTotalCount }}
              />,
            ]}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <Button
      className="btn-further"
      onClick={() => {
        // dispatch({ type: 'customerList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
    >
      Edit
    </Button>,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    const recordId = params.id;
    if (recordId) {
      dispatch(
        createAction('afcDataRecords/getOneAfcDataRecord')({ id: recordId }),
      );
    }
    return () => {
      dispatch({ type: 'afcDataRecords/clearData' });
    };
  }, []);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: afcDataRecord?.pk,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={afcDataRecord?.pk} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

export default AfcDataRecordDetail;
