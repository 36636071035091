export const PermissionCodes = {
  administratorgroup: 'administratorgroup',
  administrator: 'administrator',

  brand: 'brand',
  store: 'store',
  storecategory: 'storecategory',
  storeFeedback: 'storeFeedback',

  campaign: 'campaign',
  campaigncategory: 'campaigncategory',
  mission: 'mission',
  coupontemplate: 'coupontemplate',
  coupon: 'coupon',
  earningrule: 'earningrule',
  message: 'message',

  customer: 'customer',
  customergroup: 'customergroup',
  segment: 'segment',

  pointtransaction: 'pointtransaction',
  transaction: 'transaction',
  coupontransaction: 'coupontransaction',

  addAdministratorGroup: 'add_administratorgroup',
  changeAdministratorGroup: 'change_administratorgroup',
  deleteAdministratorGroup: 'delete_administratorgroup',
  // view_administratorgroup
  addAdministrator: 'add_administrator',
  changeAdministrator: 'change_administrator',
  deleteAdministrator: 'delete_administrator',
  viewAdministrator: 'view_administrator',

  //   addCustomer: 'add_customer',
  changeCustomer: 'change_customer',
  deleteCustomer: 'delete_customer',
  viewCustomer: 'view_customer',

  addSegment: 'add_segment',
  changeSegment: 'change_segment',
  deleteSegment: 'delete_segment',
  viewSegment: 'view_segment',

  addCustomerGroup: 'add_customergroup',
  changeCustomerGroup: 'change_customergroup',
  deleteCustomerGroup: 'delete_customergroup',
  // viewCustomerGroup: 'view_customergroup',

  addCampaign: 'add_campaign',
  changeCampaign: 'change_campaign',
  deleteCampaign: 'delete_campaign',
  // viewCampaign: 'view_campaign',

  addCampaignCategory: 'add_campaigncategory',
  changeCampaignCategory: 'change_campaigncategory',
  deleteCampaignCategory: 'delete_campaigncategory',
  viewCampaignCategory: 'view_campaigncategory',

  changeMission: 'change_mission',
  viewMission: 'view_mission',

  addEarningRule: 'add_earningrule',
  changeEarningRule: 'change_earningrule',
  deleteEarningRule: 'delete_earningrule',
  // viewEarningRule: 'view_earningrule',

  addMessage: 'add_message',
  changeMessage: 'change_message',
  deleteMessage: 'delete_message',
  viewMessage: 'view_message',

  addCouponTemplate: 'add_coupontemplate',
  changeCouponTemplate: 'change_coupontemplate',
  deleteCouponTemplate: 'delete_coupontemplate',
  viewCouponTemplate: 'view_coupontemplate',
  addCoupon: 'add_coupon',
  changeCoupon: 'change_coupon',
  deleteCoupon: 'delete_coupon',
  viewCoupon: 'view_coupon',

  addBrand: 'add_brand',
  changeBrand: 'change_brand',
  deleteBrand: 'delete_brand',
  //   viewBrand: 'view_brand',

  addStore: 'add_store',
  changeStore: 'change_store',
  deleteStore: 'delete_store',
  viewStore: 'view_store',

  addStorecategory: 'add_storecategory',
  changeStorecategory: 'change_storecategory',
  deleteStorecategory: 'delete_storecategory',
  viewSorecategory: 'view_storecategory',

  addTransaction: 'add_transaction',
  changeTransaction: 'change_transaction',
  deleteTransaction: 'delete_transaction',
  viewTransaction: 'view_transaction',

  //   addCouponTransaction: 'add_coupontransaction',
  //   changeCouponTransaction: 'change_coupontransaction',
  //   deleteCouponTransaction: 'delete_coupontransaction',
  viewCouponTransaction: 'view_coupontransaction',

  addPointtransaction: 'add_pointtransaction',
  viewPointtransaction: 'view_pointtransaction',
  changePointtransaction: 'change_pointtransaction',

  publishCampaign: 'publishcampaign',
  unPublishCampaign: 'unpublishcampaign',

  addExportjob: 'add_exportjob',
  addImportjob: 'add_importjob',

  addCustomerPoints: 'add_customer_points',
  removeCustomerPoints: 'remove_customer_points',
  testMessage: 'test_message',

  // home manage
  banners: 'home_banners',
  productsCategory: 'home_products_category',
  featuredCampaign: 'campaign',
  whatsNewCampaign: 'campaign',
  greetingCard: 'greetingmodel',
  viewGreetingCard: 'view_greetingmodel',
  changeGreetingCard: 'change_greetingmodel',
  greetingSection: 'greetingmodel',
  viewGreetingSection: 'view_greetingmodel',
  changeGreetingSection: 'change_greetingmodel',
  featuredProduct: 'home_featured_product',


  whatToDoNext: 'what_to_do_next',
  changeWhatToDoNext: 'change_what_to_do_next',
  viewWhatToDoNext: 'view_what_to_do_next',

  addBanner: 'add_banner',
  deleteBanner: 'delete_banner',
  changeBanner: 'change_banner',

  feedbackList: 'customerfeedback',
  deleteFeedbackRecords: 'change_customerfeedback',
  viewFeedbacks: 'view_customerfeedback',

  viewMarkedLabel: 'view_marked_label',
  changeMarkedLabel: 'change_marked_label',

  appVersion: 'appversionmodel',
  viewAppVersion: 'view_appversionmodel',
  changeAppVersion: 'change_appversionmodel',

  guideImage: 'guidemodel',
  changeGuidemodel: "change_guidemodel",
  viewGuidemodel: "view_guidemodel",
  community: 'community',
  viewCommunity: "view_community",
  changeCommunity: "change_community",

  splashAd: "splashad",
  viewSplashAd: "view_splashad",
  changeSplashAd: "change_splashad",

  rewardCode: "rewardcodeset",
  viewRewardCode: "view_rewardcodeset",
  changeRewardCode: "change_rewardcodeset",

  newsFeed: "newsfeed",
  viewNewsFeed: "view_newsfeed",
  changeNewsFeed: "change_newsfeed",
  publishNewsFeed: "publish_newsfeed",
  unpublishNewsFeed: "unpublish_newsfeed",

  progressiveProfilingLifetimePoints: "progressiveprofilinglifetimepoints",
  viewProgressiveProfilingLifetimePoints: "view_progressiveprofilinglifetimepoints",
  changeProgressiveProfilingLifetimePoints: "change_progressiveprofilinglifetimepoints",

  barcode: "barcode",
  viewBarcode: "view_barcode",
  changeBarcode: "change_barcode",
  publishBarcode: "publish_barcode",
  unpublishBarcode: "unpublish_barcode",
  submittednewbarcode: "submittednewbarcode",
  viewSubmittednewbarcode: "view_submittednewbarcode",
  changeSubmittednewbarcode: "change_submittednewbarcode",
};
