import React from 'react';
import { connect } from 'react-redux';
import { LanguageConfig, MissionType } from '../../config/CustomEnums';
import { getReadablePeriod } from '../../utils/TimeFormatUtil';

function ProfileSection({
  points,
  couponTemplate,
  couponQuantity,
  isAlwaysActivePeriod,
  activeStartDate,
  activeEndDate,
  isAlwaysVisiblePeriod,
  visibleEndDate,
  visibleStartDate,
  missionType,
  translations = {},
}) {
  console.log("@@13", isAlwaysActivePeriod)

  const isHabitual = missionType === MissionType.habitual;
  const enHabitualLevels = translations?.[LanguageConfig.english]?.habitualLevels || [];

  const profileField = (field, value) => {
    return (
      <div className='d-flex flex-column' style={{width: '278px'}}>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        <label className="create-section-field-input profile-section-label-bottom">
          {value || '-'}
        </label>
      </div>
    );
  };

  return (
    <>
      <label className="create-section-title">PROFILES</label>
      <div className='d-flex flex-row'>
        {profileField(
          'Active period',
          getReadablePeriod(activeStartDate, activeEndDate, isAlwaysActivePeriod),
        )}
        {profileField(
          'Visible period',
          getReadablePeriod(visibleStartDate, visibleEndDate, isAlwaysVisiblePeriod),
        )}
      </div>
      <label className="create-section-title" style={{ marginTop: '30px' }}>Rewards</label>
      {isHabitual ? (
        enHabitualLevels.map((item, index) => (
          <div className="d-flex flex-row">
            {profileField(`Level ${item.levelGrade} reward`, '')}
            {profileField('Points', item.numberOfPoints)}
            {profileField('Quantity', item.couponQuantity)}
            {profileField('Coupon set', item.couponTemplate?.name)}
          </div>
        ))
      ) : (
        <div className="d-flex flex-row">
          {profileField('Points', points)}
          {profileField('Quantity', couponQuantity)}
          {profileField('Coupon set', couponTemplate?.name)}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  points: state.mission.missionDetail.points,
  couponTemplate: state.mission.missionDetail.couponTemplate,
  couponQuantity: state.mission.missionDetail.couponQuantity,
  isAlwaysActivePeriod: state.mission.missionDetail.isAlwaysActivePeriod,
  activeStartDate: state.mission.missionDetail.activeStartDate,
  activeEndDate: state.mission.missionDetail.activeEndDate,
  isAlwaysVisiblePeriod: state.mission.missionDetail.isAlwaysVisiblePeriod,
  visibleStartDate: state.mission.missionDetail.visibleStartDate,
  visibleEndDate: state.mission.missionDetail.visibleEndDate,
  missionType: state.mission.missionDetail.missionType,
  translations: state.mission.missionDetail.translations,
});

export default connect(mapStateToProps)(ProfileSection);