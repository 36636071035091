import React, { useState, useEffect } from 'react';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './MessageGeneralSection.scss';
import ImageUploader from '../base/ImageUploader';
import { useSelector } from 'react-redux';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateMessageError } from '../../models/CreateMessageModel';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomEditor from '../base/CustomEditor';

function MessageGeneralSection(props) {
  const language = props.language || LanguageConfig.english;
  const onSubmit = props.onSubmit || false;
  const onSubmitData = props.onSubmitData || (() => { });

  const { title, content, photo, url, translations, errorFields } = useSelector(
    (state) => ({
      title: state.createMessage.message.name,
      content: state.createMessage.message.content,
      photo: state.createMessage.message.photo,
      url: state.createMessage.message.url,
      translations: state.createMessage.message.translations,
      errorFields: state.createMessage.errorFields,
    }),
  );

  const [messageTitle, setMessageTitle] = useState(
    language === LanguageConfig.english ? title : translations[language]?.name,
  );
  const [messageContent, setMessageContent] = useState(
    language === LanguageConfig.english
      ? content
      : translations[language]?.content,
  );
  const [messagePhoto, setMessagePhoto] = useState(
    language === LanguageConfig.english ? photo : translations[language]?.photo,
  );
  const [messageUrl, setMessageUrl] = useState(url);

  useEffect(() => {
    if (onSubmit) {
      const data = {
        language: language,
        name: messageTitle,
        content: messageContent,
        photo: messagePhoto,
        url: messageUrl,
      };
      if (language !== LanguageConfig.english && translations[language]?.pk) {
        data.pk = translations[language]?.pk;
        data.id = translations[language]?.id;
      }
      onSubmitData(data);
    }
  }, [
    language,
    messagePhoto,
    messageTitle,
    messageContent,
    messageUrl,
    onSubmit,
    onSubmitData,
    translations,
  ]);

  useEffect(() => {
    firstError(
      `${CreateMessageError.content.name}-${language}`,
      errorFields,
      language,
    );
  });

  const isError = () => {
    return isShowError(CreateMessageError.content.name, errorFields, language);
  };

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>
      <CustomTitleLabel
        title={i18n.t('title(optional)', { locale: language })}
      />
      <label className="create-message-suggest">
        {i18n.t('use_in_inbox_push', { locale: language })}
      </label>
      <input
        type="text"
        className={`text-input-field title-message-input`}
        defaultValue={messageTitle}
        onChange={(e) => setMessageTitle(e.target.value)}
      />

      <CustomTitleLabel title={i18n.t('content', { locale: language })} />
      <label className="create-message-suggest">
        {i18n.t('use_in_inbox_push', { locale: language })}
      </label>

      <CustomEditor
        initialValue={messageContent}
        onValueChange={(data) => setMessageContent(data)}
        toolBars={['link']}
        pureCopy
      />

      <ErrorFieldMessage
        id={`${CreateMessageError.content.name}-${language}`}
        error={isError()}
        message={`${CreateMessageError.content.message}`}
      />

      <CustomTitleLabel
        title={i18n.t('image(optional)', { locale: language })}
      />
      <label className="create-message-suggest">
        {i18n.t('message_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={560}
        minHeight={420}
        images={
          messagePhoto
            ? Array.isArray(messagePhoto)
              ? messagePhoto.map((photo) => photo)
              : [messagePhoto]
            : []
        }
        onImageStateChange={(newState) => {
          setMessagePhoto(newState);
        }}
        aspect={4 / 3}
        maxImageNum={1}
        maxImageSize={1}
        language={language}
        uploadImageClicked={() => { }}
      />
      {language == LanguageConfig.english
        ? <>
          <CustomTitleLabel title={"Url(optional)"} />
          <input
            type="text"
            className={`text-input-field title-message-input`}
            defaultValue={messageUrl}
            onChange={(e) => setMessageUrl(e.target.value)}
          />
        </>
        : null
      }
    </>
  );
}

export default MessageGeneralSection;
