import { apiWithResponseHandle } from './LoadingUtil';
import { APIStatus } from '../config/CustomEnums';
import { createGreetingSection, deleteGreetingSections, getGreetingSection, getGreetingSectionList, updateGreetingSection } from '../services/GreetingSectionAPIHelper';
import { convertCursorToNumber, convertNumberToCursor, convertPKToId, delay, getFileNameFromUrl, getObjectFromSessionStorage, removeFromSessionStorage, saveToSessionStorage } from '../utils';
import { getCampignListDisplayTime } from '../utils/TimeFormatUtil';
import { BackgroundImageStyle, MissionCardStyleType } from '../config/CustomEnums';

export const CreateGreetingSectionError = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a general name.'
  },
  backgroundImageStyle: {
    name: 'backgroundImageStyle',
    message: 'Please select a background image style.',
  },
  customImageGuest: {
    name: 'customImageGuest',
    message: 'Please provide a custom image for guest view',
  },
  customImageSignedIn: {
    name: 'customImageSignedIn',
    message: 'Please provide a custom image for signed-in user view',
  },
  missionCardStyle: {
    name: 'missionCardStyle',
    message: 'Please select a mission card style.',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a greeting section cannot be on or before the start date and time.',
  },
};

export const sessionDataKey = {
  objectKey: 'createGreetingSection',
  stepEndKey: 'createGreetingSectionStepEnd',
  origionalData: 'createGreetingSectionOriginalData',
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Mission card style', fieldName: 'missionCardStyle' },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
  ],
  greetingSection: {},
  stepConfig: [],
  greetingSectionList: [],
  totalCount: null,
  totalPage: null,
  pageInfo: {},
  checkedList: [],
  errorFields: [],
  loadingStatus: APIStatus.none,
  createStatus: APIStatus.none,
  formChanged: false,
});

const parseGreetingSectionDetail = (data = {}) => {
  let displayActivePeriod = getCampignListDisplayTime(
    data.startDate,
  );
  if (!data.endDate) {
    displayActivePeriod = 'All time active';
  };

  return ({
    id: data.id,
    pk: data.pk,
    name: data.name,
    missionCardStyle: data.missionCardStyle,
    missionCardStyleDisplay: getMissCardStyleDisplay(),
    activeStartDate: data.startDate,
    activeEndDate: data.endDate,
    backgroundImageStyle: data.isCustomBackgroundUsed ? BackgroundImageStyle.CUSTOM : BackgroundImageStyle.YELLOW,
    backgroundImageStyleDisplay: getBackgroundImageStyleDisplay(),
    backgroundImageGuestView: data.backgroundImageGuestView,
    backgroundImageSignedInView: data.backgroundImageSignedInView,
    displayActivePeriod,
  });

  function getBackgroundImageStyleDisplay() {
    switch (data.isCustomBackgroundUsed) {
      case true:
        return 'Custom'
      case false:
        return 'Yellow'
      default:
        return 'Yellow'
    }
  }

  function getMissCardStyleDisplay() {
    switch (data.missionCardStyle) {
      case MissionCardStyleType.GREEN:
        return 'Green'
      case MissionCardStyleType.SKY:
        return 'Sky'
      default:
        return 'Yellow'
    }
  }
};

const parseGreetingSectionList = (list) => {
  return list.map(item => parseGreetingSectionDetail(item?.node));
};

const checkFields = (data) => {
  const {
    name,
    backgroundImageStyle,
    backgroundImageGuestView,
    backgroundImageSignedInView,
    missionCardStyle,
    activeStartDate,
    activeEndDate,
  } = data;

  let errorFields = [];
  if (!name) {
    errorFields.push(CreateGreetingSectionError.generalName.name);
  };
  if (!backgroundImageStyle) {
    errorFields.push(CreateGreetingSectionError.backgroundImageStyle.name);
  } else if (data?.backgroundImageStyle == BackgroundImageStyle.CUSTOM) {
    if (!backgroundImageGuestView) {
      errorFields.push(CreateGreetingSectionError.customImageGuest.name);
    };
    if (!backgroundImageSignedInView) {
      errorFields.push(CreateGreetingSectionError.customImageSignedIn.name);
    };
  }

  if (!missionCardStyle) {
    errorFields.push(CreateGreetingSectionError.missionCardStyle.name);
  };
  console.log("@@107", data);
  if (activeStartDate?.getTime() >= activeEndDate?.getTime()) {
    errorFields.push(CreateGreetingSectionError.activeEndDate.name);
  };

  console.log("@@137", data, errorFields)
  return {
    invalid: errorFields.length > 0,
    errorFields: errorFields,
    data,
  };
};

const getDataForCreateGreetingSection = (data) => {
  console.log("@@121", data)
  const isCustomBackgroundUsed = data?.backgroundImageStyle == BackgroundImageStyle.CUSTOM;
  let result = {
    name: data?.name,
    isCustomBackgroundUsed,
    missionCardStyle: data?.missionCardStyle,
    backgroundImageGuestView: isCustomBackgroundUsed ? getFileNameFromUrl(data?.backgroundImageGuestView) || null : null,
    backgroundImageSignedInView: isCustomBackgroundUsed ? getFileNameFromUrl(data?.backgroundImageSignedInView) || null : null,
    startDate: data?.activeStartDate,
    endDate: data?.activeEndDate,
    isDefaultStyle: false
  }

  return result;
};

export default {
  namespace: 'greetingSection',
  state: { ...getInitialState() },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
    updateGreetingSectionDetail(state, { payload }) {
      const newGreetingSection = {
        ...state.greetingSection,
        ...payload,
      };

      saveToSessionStorage(sessionDataKey.objectKey, newGreetingSection);
      return {
        ...state,
        greetingSection: newGreetingSection,
      };
    },
    assembleGreetingSection(state, { payload }) {
      const greetingSectionData = parseGreetingSectionDetail(payload.greetingSection);
      saveToSessionStorage(sessionDataKey.origionalData, greetingSectionData);
      saveToSessionStorage(sessionDataKey.objectKey, greetingSectionData);
      return {
        ...state,
        greetingSection: greetingSectionData,
      };
    },
    assembleGreetingSectionList(state, { payload }) {
      const greetingSectionList = parseGreetingSectionList(payload.list);
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        greetingSectionList: greetingSectionList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },
    loadGreetingSectionFromCookie(state, { payload }) {
      const greetingSection = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!greetingSection) {
        return {
          ...state,
          loadingStatus: APIStatus.success,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, greetingSection);
      saveToSessionStorage(sessionDataKey.objectKey, greetingSection);
      return {
        ...state,
        greetingSection: greetingSection,
        loadingStatus: APIStatus.success,
      };
    },
    saveOrRemoveGreetingSectionFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
  },
  effects: {
    getList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';

        const serviceArgs = [
          getGreetingSectionList,
          pageCursor,
          { ...payload },
        ];

        function* onSuccess(data) {
          console.log("@@55", data)
          const greetingSections = data?.greetingSections;
          yield put({
            type: 'assembleGreetingSectionList',
            payload: {
              list: greetingSections?.edges,
              totalCount: greetingSections?.totalCount,
              pageInfo: greetingSections?.pageInfo,
            },
          })
        };
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getDetail: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { loadingStatus: APIStatus.calling },
        });
        const id = convertPKToId('GreetingSectionNode', payload.id);
        const serviceArgs = [getGreetingSection, id];
        function* onSuccess(data) {
          console.log('@@116, greeting section detail', data);
          yield all([
            put({
              type: 'assembleGreetingSection',
              payload: { greetingSection: data?.greetingSection },
            }),
            put({
              type: 'updateState',
              payload: { loadingStatus: APIStatus.success },
            }),
          ]);
        }
        function* onError(err) {
          console.log('greeting section onError :', err);
          yield put({
            type: 'updateState',
            payload: { loadingStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('greeting section arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { loadingStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    checkFields: [
      function* ({ payload }, { select, put }) {
        const greetingSection = yield select((state) => state.greetingSection.greetingSection);
        const result = checkFields(greetingSection);
        yield put({
          type: 'updateState',
          payload: {
            errorFields: result.errorFields,
          }
        });
        if (!result.invalid) {
          yield put({
            type: 'createOrUpdateGreetingSection',
            payload: { id: payload?.id }
          });
        }
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateGreetingSection: [
      function* ({ payload }, { call, select, put, all }) {
        const originalGreetingSection = yield select(
          (state) => state.greetingSection.greetingSection,
        );
        const createGreetingSectionData = getDataForCreateGreetingSection(originalGreetingSection);
        if (originalGreetingSection?.pk && payload?.id) {
          createGreetingSectionData.id = originalGreetingSection?.pk;
        };
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        const serviceArgs = [createGreetingSectionData?.id ? updateGreetingSection : createGreetingSection, createGreetingSectionData];
        function* onSuccess(data) {
          const greetingSection = data?.[createGreetingSectionData?.id ? 'updateGreetingSection' : 'createGreetingSection']?.node;
          console.log("@@312", greetingSection)
          yield put({
            type: 'updateState',
            payload: {
              createStatus: !greetingSection ? APIStatus.failed : APIStatus.success,
            },
          });
          yield put({ type: 'saveOrRemoveGreetingSectionFromCookie', payload: false });
        };

        function* failed(data) {
          yield put({ type: 'updateState', payload: { createStatus: APIStatus.failed } });
        };

        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { call, select, put }) {
        const deleteItems = yield select(state => state.greetingSection.checkedList);
        const deletePks = deleteItems?.map(item => item.pk);
        const selectedIds = payload?.deletePks || deletePks;
        console.log("@@354", selectedIds)
        const afterAction = payload.afterAction || (() => { });

        const serviceArgs = [deleteGreetingSections, selectedIds];
        yield apiWithResponseHandle(serviceArgs);

        yield delay(1000);
        afterAction();
      },
      { type: 'takeLatest' },
    ],
  },
};
