import { BaseHelper } from './BaseGraphQLAPIHelper';
import { convertPKToId } from '../utils';

export const getWhatToDoNextList = (
  displayPriority,
) => {

  let order = ''

  if (displayPriority) {
    order = displayPriority
  }

  const query = `{
    whatToDoNexts(orderBy: "${order}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          creationDate
          lastModifiedDate
          key
          language
          id
          name
          coverPhoto
          displayInGuestView
          displayPriority
          customImageSmallDevice1
          customImageSmallDevice2
          customImageSmallDevice3
          customImageSmallDevice4
          customImageSmallDevice5
          customImageSmallDevice6
          customImageLargeDevice1
          customImageLargeDevice2
          customImageLargeDevice3
          customImageLargeDevice4
          customImageLargeDevice5
          customImageLargeDevice6
          pk
          translations {
              edges {
                  cursor
                  node {
                      creationDate
                      lastModifiedDate
                      language
                      id
                      name
                      customImageSmallDevice1
                      customImageSmallDevice2
                      customImageSmallDevice3
                      customImageSmallDevice4
                      customImageSmallDevice5
                      customImageSmallDevice6
                      customImageLargeDevice1
                      customImageLargeDevice2
                      customImageLargeDevice3
                      customImageLargeDevice4
                      customImageLargeDevice5
                      customImageLargeDevice6
                      pk
                  }
              }
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });

}

export const getWhatToDoNext = (id) => {
  const query = `
  {
    whatToDoNext(id: "${id}") {
      creationDate
      lastModifiedDate
      language
      id
      key
      name
      coverPhoto
      displayInGuestView
      displayPriority
      customImageSmallDevice1
      customImageSmallDevice2
      customImageSmallDevice3
      customImageSmallDevice4
      customImageSmallDevice5
      customImageSmallDevice6
      customImageLargeDevice1
      customImageLargeDevice2
      customImageLargeDevice3
      customImageLargeDevice4
      customImageLargeDevice5
      customImageLargeDevice6
      pk
      translations {
        edges {
            cursor
            node {
                creationDate
                lastModifiedDate
                language
                id
                name
                customImageSmallDevice1
                customImageSmallDevice2
                customImageSmallDevice3
                customImageSmallDevice4
                customImageSmallDevice5
                customImageSmallDevice6
                customImageLargeDevice1
                customImageLargeDevice2
                customImageLargeDevice3
                customImageLargeDevice4
                customImageLargeDevice5
                customImageLargeDevice6
                pk
            }
         }
      }
    }
  }
  `
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const updateWhatToDoNext = (input) => {
  const query = `mutation UpdateWhatToDoNext($input: UpdateWhatToDoNextInput!) {
    updateWhatToDoNext(input: $input) {
        node {
            creationDate
            lastModifiedDate
            language
            id
            name
            coverPhoto
            displayInGuestView
            displayPriority
            customImageSmallDevice1
            customImageSmallDevice2
            customImageSmallDevice3
            customImageSmallDevice4
            customImageSmallDevice5
            customImageSmallDevice6
            customImageLargeDevice1
            customImageLargeDevice2
            customImageLargeDevice3
            customImageLargeDevice4
            customImageLargeDevice5
            customImageLargeDevice6
            pk
            translations {
                edges {
                    node {
                        creationDate
                        lastModifiedDate
                        language
                        id
                        name
                        customImageSmallDevice1
                        customImageSmallDevice2
                        customImageSmallDevice3
                        customImageSmallDevice4
                        customImageSmallDevice5
                        customImageSmallDevice6
                        customImageLargeDevice1
                        customImageLargeDevice2
                        customImageLargeDevice3
                        customImageLargeDevice4
                        customImageLargeDevice5
                        customImageLargeDevice6
                        pk
                    }
                }
            }
        }
        errors {
          field
          messages
      }
    }
}
 `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};