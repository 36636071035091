import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CreateMissionError } from '../../models/MissionModel';
import { compareDate, getCurrentDay } from '../../utils/TimeFormatUtil';
import PeriodSection from '../campaign/campaignCreation/PeriodSection';

function ActivePeriodSection({
  onSubmit = false,
  onSubmitAction = (() => { }),
  isAlwaysActivePeriod,
  activeStartDate,
  activeEndDate,
  errorFields,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        isAlwaysActivePeriod: isAlwaysActivePeriod,
        activeStartDate: activeStartDate,
        activeEndDate: activeEndDate,
      });
    }
  }, [onSubmit]);

  return (
    <PeriodSection
      disabled={false}
      title="Active Period"
      maxDate={null}
      minDate={null}
      titleDescription={'Mission available time.'}
      switchButtonTitle="It is an all-time active mission"
      isAlwaysPeriod={isAlwaysActivePeriod}
      switchOnChange={(value) => {
        dispatch({
          type: 'mission/updateMissionDetail',
          payload: { isAlwaysActivePeriod: value},
        });
      }}
      startDate={activeStartDate}
      startDateChange={(value) => {
        dispatch({
          type: 'mission/updateMissionDetail',
          payload: { activeStartDate: value},
        });
      }}
      filterStartDate={(date) => {
        return true;
      }}
      endDate={activeEndDate}
      endDateChange={(value) => {
        dispatch({
          type: 'mission/updateMissionDetail',
          payload: { activeEndDate: value},
        });
      }}
      filterEndDate={(date) => {
        return true;
      }}
      hideTime={false}
      errorFields={errorFields}
      fieldName={CreateMissionError.activeEndDate.name}
      fieldErrorMessage={CreateMissionError.activeEndDate.message}
    />
  );
}


const mapStateToProps = (state) => ({
  isAlwaysActivePeriod: state.mission.missionDetail.isAlwaysActivePeriod,
  activeStartDate: state.mission.missionDetail.activeStartDate,
  activeEndDate: state.mission.missionDetail.activeEndDate,
  errorFields: state.mission.errorFields,
});

export default connect(mapStateToProps)(ActivePeriodSection);
