import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, saveImage } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, GREEN_SHOPPING_CATEGORY_ID, PLANT_MILK_CATEGORY_ID, ReportTypes } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import PreviewImage from '../../../components/base/prompt/PreviewImage';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import './FeedbackDetail.scss';
import DownloadImagePrompt from '../../../components/base/prompt/DownloadImagePrompt';

const commonFields = [
  [{ title: 'Creation Time', field: 'creationDate' }],
  [{ title: 'Store ID', field: 'storeId' }],
  [
    {
      title: 'Store Name',
      field: 'name',
    },
  ],
  [
    {
      title: 'Report User Location',
      field: 'userGPS',
    },
  ],
  [
    {
      title: 'Report User ID',
      field: 'userId',
    },
  ],
  [{ title: 'Activity Type', field: 'activityType' }],

  [{ title: 'Report Type', field: 'reportTypeValue' }],
  [{ title: 'Additional Information', field: 'aditionalInformation' }],
  [
    {
      title: 'Relevant Images',
      field: 'relevantImages',
    },
  ],
];

const specialFields = (type) => {
  if (type === ReportTypes.InaccurateLocation) {
    return [
      [
        {
          title:
            'Whether the user is currently currently at the report location',
          field: 'userIsAtCorrectLocation',
        },
      ],
      [
        {
          title: 'Correct location description',
          field: 'correctLocationDescription',
        },
      ],
    ];
  }
  if (type === ReportTypes.InaccurateOpeningHours) {
    return [
      [
        {
          title: 'Correct opening hours',
          field: 'correctOpenHours',
        },
      ],
    ];
  }
  if (type === ReportTypes.CommonRecyclable) {
    return [
      [
        {
          title: 'What is missing?',
          field: 'whatIsMissing',
        },
      ],
      [
        {
          title: 'What is extra?',
          field: 'whatIsExtra',
        },
      ],
    ];
  }
  if (type === ReportTypes.InaccurateTag) {
    return [
      [
        {
          title: 'Correct location tag',
          field: 'correctLocationTag',
        },
      ],
    ];
  }
  return [];
};

const specificActivityFields = (reportType, activityIds) => {
  const fields = [];
  if (reportType !== ReportTypes.MissingLocation) {
    return [];
  }

  fields.push([
    {
      title: 'Correct location description',
      field: 'correctLocationDescription',
    },
  ]);

  if (activityIds?.includes(1)) {
    fields.push([
      { title: 'What types of recyclables', field: 'whatIsMissing' },
    ]);
  }
  if (activityIds?.includes(2)) {
    fields.push([{ title: 'Restaurant name', field: 'restaurantName' }]);
  }
  if (activityIds?.includes(GREEN_SHOPPING_CATEGORY_ID) || activityIds?.includes(PLANT_MILK_CATEGORY_ID)) {
    fields.push([
      { title: 'Report Shop Name', field: 'reportShopName' },
    ]);
  };
  if (activityIds?.includes(GREEN_SHOPPING_CATEGORY_ID)) {
    fields.push([
      { title: 'Green Shopping Type', field: 'greenShoppingType' },
    ]);
  };
  return fields;
};

const DetailInfo = ({ data, enlargeImageUrl }) => {
  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(800);
  const [showDownloadPromot, setShowDownloadPromot] = useState(false);
  const fields = [
    ...commonFields,
    ...specificActivityFields(data?.reportType, data?.activityIds),
    ...specialFields(data?.reportType),
  ];
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, []);

  const images = (images) => {
    return (
      <>
        <div className="feedback-relevant-images-container">
          {!images?.length
            ? '-'
            : images?.map((image) => (
                <img
                  src={image}
                  alt=""
                  className="feedback-relevant-image"
                  onClick={() => enlargeImageUrl(image)}
                />
              ))}
        </div>
        <DownloadImagePrompt
          images={images}
          show={showDownloadPromot}
          closeAction={() => {
            setShowDownloadPromot(false);
          }}
        />
      </>
    );
  };

  const commonLabel = (labelValue) => {
    return (
      <label
        className={`custom-record-normal-font custom-timeline-time-color list-new-line`}
      >
        {labelValue || '-'}
      </label>
    );
  };

  const userLocation = (coordinates) => {
    return (
      <>
        {coordinates?.map((coordinate) => {
          return <>{commonLabel(`${coordinate.title}: ${coordinate.value}`)}</>;
        })}
      </>
    );
  };

  const fieldValues = (data, field) => {
    switch (field) {
      case 'relevantImages':
        return images(data[field]);
      case 'userGPS':
        return userLocation(data[field]);
      default:
        return commonLabel(data[field]);
    }
  };
  const allImages = data?.relevantImages || [];
  return (
    <>
      <label className="create-section-title">CONTENT</label>
      <table ref={tableRef}>
        {fields.map((row) => {
          return (
            <tr>
              {row.map((column) => {
                return (
                  <td
                    style={{
                      display: 'inline-grid',
                      width: `${tableWidth / row.length}px`,
                    }}
                  >
                    <CustomTitleLabel
                      title={
                        column.field === 'relevantImages' &&
                        allImages.length > 0 ? (
                          <>
                            {column.title}
                            <label
                              onClick={() => {
                                if (allImages.length === 1) {
                                  saveImage(allImages[0]);
                                } else {
                                  setShowDownloadPromot(true);
                                }
                              }}
                              style={{
                                marginLeft: 16,
                                fontSize: 16,
                                color: '#4E8DD7',
                                lineHeight: '20px',
                                cursor: 'pointer',
                              }}
                            >
                              Download
                            </label>
                          </>
                        ) : (
                          column.title
                        )
                      }
                    />

                    {fieldValues(data, column.field)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
    </>
  );
};

function FeedbackDetail() {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('storeFeedback/getFeedbackDetail')({ id: params.id }),
      );
    }
    return () => {
      dispatch({ type: 'storeFeedback/resetFeedbackDetail' });
    };
  }, [dispatch, params.id]);

  const { feedbackDetail, detailApiStatus } = useSelector((state) => ({
    feedbackDetail: state.storeFeedback.feedbackDetail,
    detailApiStatus: state.storeFeedback.detailApiStatus,
  }));
  const [enlargeImageUrl, setEnlargeUrl] = useState();

  const tabs = [
    {
      name: '',
      content: (
        <ContentSections
          sections={
            detailApiStatus === APIStatus.calling
              ? [<Loading />]
              : [
                  <DetailInfo
                    data={feedbackDetail}
                    enlargeImageUrl={setEnlargeUrl}
                  />,
                ]
          }
          hidePreview
        />
      ),
    },
  ];

  const buttons = [];

  return (
    <div className="feedback-container">
      <CustomListComponent
        caution={{
          detail: '',
          title: feedbackDetail.name,
        }}
        breadcrumb={<CustomBreadcrumb name={feedbackDetail.name} />}
        buttons={buttons}
        tabs={tabs}
      />
      <PreviewImage
        show={!!enlargeImageUrl}
        onClose={() => {
          setEnlargeUrl(null);
        }}
        imageUrl={enlargeImageUrl}
        extraClass={{ width: 315 * 1.7, height: 315 * 1.7 }}
      />
    </div>
  );
}

export default FeedbackDetail;
