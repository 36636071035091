import React from 'react';
import './CustomStatusTag.scss';
import { checkCampaignIsExpired } from '../../utils/TimeFormatUtil';
import {
  StatusTag,
  DownloadTag,
  MessageTag,
  PublishTagType,
  BrandStatus
} from '../../config/CustomEnums';
import { COUPON_STATUS } from '../../models/CouponListModel';
const TagType = {
  published: 'Published',
  unPublished: 'Unpublished',
  expired: 'Expired',
};
const getFormatedStatus = (campaign) => {
  if (!campaign.isPublished) {
    return {
      status: TagType.unPublished,
      className: 'custom-tag-label-un-publish',
    };
  }

  const isExpired = checkCampaignIsExpired(campaign.endDate);
  if (!isExpired) {
    return { status: TagType.published, className: 'custom-tag-label-publish' };
  }
  return { status: TagType.expired, className: 'custom-tag-label-expired' };
};

function CustomStatusTag(props) {
  const campaign = props.item;
  const tag = getFormatedStatus(campaign);
  return (
    <label className={`custom-tag-label ${tag.className}`}>{tag.status}</label>
  );
}

export const CustomStatusTagFromText = (status, extraStyle) => {
  let statusClass = '';
  switch (status) {
    case StatusTag.active:
    case DownloadTag.completed:
    case MessageTag.scheduled:
    case MessageTag.sent:
    case MessageTag.sending:
    case DownloadTag.importCompleted:
    case COUPON_STATUS.ACTIVE_ACQUIRED:
    case COUPON_STATUS.ACTIVE:
    case PublishTagType.published:
    case BrandStatus.Yes:
      statusClass = 'custom-tag-label-publish';
      break;
    case StatusTag.deactive:
    case COUPON_STATUS.EXPIRED:
    case COUPON_STATUS.USED:
    case PublishTagType.expired:
    case DownloadTag.error:
    case MessageTag.error:
      statusClass = 'custom-tag-label-expired';
      break;
    case DownloadTag.canceled:
    case StatusTag.inactive:
    case MessageTag.saved:
    case COUPON_STATUS.INACTIVE:
    case PublishTagType.unPublished:
    case BrandStatus.No:
      statusClass = 'custom-tag-label-un-publish';
      break;
    case DownloadTag.downloading:
    case DownloadTag.importing:
      statusClass = 'custom-tag-label-downloading';
      break;
    default:
      statusClass = '';
      break;
  }

  return (
    <label className={`custom-tag-label ${statusClass} ${extraStyle}`}>
      {status}
    </label>
  );
};

export default CustomStatusTag;
