import React, { useEffect, useState } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useDispatch, connect } from 'react-redux';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { sessionDataKey } from '../../../models/GreetingSectionModel';
import CreateGreetingSectionStepOne from '../../../components/greetingSection/CreateGreetingSectionStepOne';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';

function CreateGreetingSection({
  greetingSectionDetailName,
  createStatus,
  loadingStatus,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [completeProcess, setShowCompleteProcess] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'greetingSection/getDetail',
        payload: {
          id: params.id,
        }
      });
    } else {
      dispatch({
        type: 'greetingSection/loadGreetingSectionFromCookie',
      });
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'greetingSection/clearData' });
    };
  }, [dispatch]);

  useEffect(() => {
    console.log("createStatus useEffect")
    if (createStatus === APIStatus.success) {
      if (params?.id) {
        gotoGreetingSectionList();
        return;
      };
      setShowCompleteProcess(true);
    };
  }, [createStatus])

  const stepContent = [
    loadingStatus === APIStatus.success
      ? <CreateGreetingSectionStepOne />
      : <Loading />
  ];

  const gotoGreetingSectionList = () => {
    history.push({
      pathname: '/greeting_section',
    });
  };

  return (
    <>
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({
            type: 'greetingSection/saveOrRemoveGreetingSectionFromCookie',
            payload: save,
          });
        }}
        sessionDataKey={sessionDataKey}
        stepsConfig={[]}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? greetingSectionDetailName : ''} />
        }
        caution={{
          detail: '',
          title: params.id ? 'Edit ' + greetingSectionDetailName : 'Greeting Section',
        }}
        showFinishPop={completeProcess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Greeting section is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to greeting section list',
              action: gotoGreetingSectionList,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: gotoGreetingSectionList,
          onClose: gotoGreetingSectionList,
        }}
      />

      <SaveAndBackButtons
        backAction={gotoGreetingSectionList}
        saveText={params.id ? 'Update' : 'Save'}
        saveAction={() => {
          dispatch({
            type: 'greetingSection/checkFields',
            payload: { id: params.id },
          })
        }}
      />

    </>
  );
}


const mapStateToProps = (state) => ({
  greetingSectionDetailName: state.greetingSection.greetingSection.name,
  createStatus: state.greetingSection.createStatus,
  loadingStatus: state.greetingSection.loadingStatus,
});

export default connect(mapStateToProps)(CreateGreetingSection);

