import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { CustomerMarkedLabelType, CustomerMarkedLabelTypeList } from '../../../config/CustomEnums';
import upArrow from '../../../assets/images/labelWithBackgroundColorButtonUp.svg'
import downArrow from '../../../assets/images/labelWithBackgroundColorButtonDown.svg'
import AuthButton from '../../../components/base/AuthButton';
import { SaveButton, SaveTempButton } from '../../../components/base/BottomStepComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CustomTitleWithDropDown } from '../../../components/customer/CustomTitleWithDropDown';

const LabelInline = ({
  title = '',
  content = '',
  extraStyle = ''
}) => {
  return (
    <div className={`label-inline ${extraStyle}`}>
      <label className='create-section-label' >
        {title}
      </label>
      <label className='create-section-field-input customer-information-content' >
        {content}
      </label>
    </div>
  );
};

const LabelWithBackgroundColor = ({
  labelType = '',
  amount = 0,
  editMode = false,
  addOrDecrease = () => { }
}) => {
  const content = CustomerMarkedLabelType?.[labelType]?.name || '';
  const color = CustomerMarkedLabelType?.[labelType]?.color || '';

  return (
    <div className={`customer-information-single-user-label ${color}`}>
      <label className='create-section-field-input label-with-background-color-label' >
        {`${content} (${amount})`}
      </label>
      {
        editMode
          ? <div className='customer-information-edit-label-amount'>
            <Button
              className='label-with-background-color-button up'
              onClick={() => addOrDecrease(1)}
            >
              <Image
                src={upArrow}
                className='label-with-background-color-button-image'
              />
            </Button>
            <Button
              className='label-with-background-color-button down'
              onClick={() => addOrDecrease(-1)}
            >
              <Image
                src={downArrow}
                className='label-with-background-color-button-image'
              />
            </Button>
          </div>
          : null
      }
    </div>
  );
};

const CustomerInformation = ({
  customerId = null,
  customerName = '',
  customerMembershipId = '',
  customerLabels = [],
  customerResetLabel = false,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [filterableUserLabel, setFilterableUserLabel] = useState(CustomerMarkedLabelTypeList);
  const [userLabel, setUserLabel] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({ name: null, value: null });
  const [searchName, setSearchName] = useState('');

  const localLabel = userLabel?.map(item => item?.labelType) || [];

  const resetCustomerMarkedLabel = () => {
    setUserLabel([
      ...customerLabels
    ]);
    setSelectedLabel({ name: null, value: null });
  };

  useEffect(() => {
    if (customerResetLabel) {
      resetCustomerMarkedLabel();
    };
  }, [customerResetLabel]);

  useEffect(() => {
    let editedFilterableUserLabel = CustomerMarkedLabelTypeList?.filter(
      item => !localLabel?.includes(item.value)
    );

    if (searchName) {
      editedFilterableUserLabel = editedFilterableUserLabel.filter(
        item => item.value.toLowerCase().includes(searchName?.toLowerCase())
      );
    };
    setFilterableUserLabel(editedFilterableUserLabel);
  }, [userLabel?.length, searchName])

  const addOrDecreaseLabel = (index, diffenence = 0) => {
    if (index < 0 || index > 3) {
      return;
    };
    let editedUserLabel = userLabel;
    editedUserLabel[index] = {
      "labelType": editedUserLabel[index]?.labelType,
      "amount": editedUserLabel[index]?.amount + diffenence
    };

    if (!editedUserLabel?.[index]?.amount) {
      editedUserLabel.splice(index, 1);
    };

    setUserLabel([
      ...editedUserLabel,
    ]);
  };

  const addUserLabel = () => {
    if (
      !selectedLabel?.value ||
      userLabel?.filter(item => item.labelType == selectedLabel?.value?.value)?.length
    ) {
      return;
    };

    let editedUserLabel = userLabel;
    editedUserLabel.push({
      labelType: selectedLabel?.value?.value,
      amount: 1,
    });

    setSelectedLabel({ name: null, value: null });
    setUserLabel([
      ...editedUserLabel,
    ]);
  };

  const localFilterAction = (search) => {
    setSearchName(search);
  };

  const cancelEdit = () => {
    resetCustomerMarkedLabel();
    setEditMode(false);
  };

  const saveEdit = () => {
    dispatch({
      type: 'transactions/updateCustomerMarkedLabel',
      payload: {
        customer: customerId,
        markedLabels: userLabel,
      },
    });
    setEditMode(false);
  };

  return (
    <>
      {editMode
        ? null
        : <div className='customer-information-auth-button'>
          <AuthButton
            title="Edit"
            action={() => {
              setEditMode(true);
            }}
            requires={PermissionCodes.changeMarkedLabel}
          />
        </div>
      }
      <label className="create-section-title">CUSTOMER INFORMATION</label>
      <div className="customer-information-name">
        <LabelInline title='Customer name' content={customerName} />
        <LabelInline title='Membership ID' content={customerMembershipId} extraStyle='customer-information-membership-id' />
      </div>
      <LabelInline title='User label (count)' content='' extraStyle='customer-information-user-label' />
      {userLabel?.length
        ? <div className='customer-information-user-label-container'>
          {
            userLabel?.map((item, index) => (
              <LabelWithBackgroundColor
                key={`user-label-${index}`}
                labelType={item?.labelType}
                amount={item?.amount}
                editMode={editMode}
                addOrDecrease={(diffenence) => {
                  addOrDecreaseLabel(index, diffenence)
                }}
              />
            ))
          }
        </div>
        : <LabelInline title='' content='-' />
      }
      {editMode && userLabel?.length < 4
        ? <div className='customer-information-add-user-label'>
          <CustomTitleWithDropDown
            title={'Add user label'}
            source={filterableUserLabel}
            defaultValue={selectedLabel}
            setValue={setSelectedLabel}
            localFilterAction={localFilterAction}
            addButtonInfo={{
              title: '+Add user label',
              action: addUserLabel,
              customClass: 'btn-back-button-common',
            }}
          />
        </div>
        : null
      }

      {editMode
        ? <div className='customer-information-cancel-save-button'>
          <div className='customer-infromation-cancel-button'>
            <SaveTempButton
              text='Cancel'
              action={cancelEdit}
            />
          </div>
          <div className='customer-information-save-button'>
            <SaveButton
              text='Save'
              action={saveEdit}
            />
          </div>
        </div>
        : null
      }

    </>
  )
};

const mapStateToProps = (state) => ({
  customerId: state.transactions.selectedTransaction.customer?.pk,
  customerName: state.transactions.selectedTransaction.name,
  customerMembershipId: state.transactions.selectedTransaction.membershipId,
  customerLabels: state.transactions.selectedTransaction.customerLabels,
  customerResetLabel: state.transactions.selectedTransaction.customerResetLabel,
});

export default connect(mapStateToProps)(CustomerInformation);
