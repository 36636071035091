import React from 'react';
import './ListButtonsGroup.scss';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';
import { Image } from 'react-bootstrap';
import CSVMoreButton from '../../assets/images/csv_more_btn.svg';
import { useMainContentWidth } from '../../utils/ScreenUtil';
import { checkHasPermission } from '../../utils';

const ListButtonsGroup = ({
  extraButtons = [],
  extraPopContent = [],
  hideExtraButtonWidth = 800,
  hideAllButtonWidth = 580,
  primaryButton = null,
  primaryPopContent = null,
  hideAllPopPosition = 'bottomRight',
  hideExtraPopPosition = 'bottom',
}) => {
  const mainContentWidth = useMainContentWidth();
  const { user } = useSelector((state) => ({
    user: state.users,
  }));
  const hasPermission = (requires) => {
    console.log(requires);
    let newRequires = requires;
    if (!Array.isArray(requires)) {
      newRequires = [requires];
    }
    return (
      newRequires.filter((val) => checkHasPermission(user, val)).length > 0
    );
  };
  const getPopContent = () => (
    <div className="popover-items-container">
      {extraPopContent.map((item, index) =>
        hasPermission(item.requires) ? (
          <div
            className={`popover-item ${index === 0 ? 'popover-no-border' : ''}`}
            onClick={item.action}
          >
            {item.content}
          </div>
        ) : null,
      )}
      {primaryPopContent &&
      hasPermission(primaryPopContent.requires) &&
      mainContentWidth <= hideAllButtonWidth ? (
        <div
          className={`popover-item ${
            extraPopContent.length === 0 ? 'popover-no-border' : ''
          }`}
          onClick={primaryPopContent.action}
        >
          {primaryPopContent.content}
        </div>
      ) : null}
    </div>
  );
  const extraPopContentLength = extraPopContent.filter((item) =>
    hasPermission(item.requires),
  ).length;
  const hasPrimaryPopContent =
    primaryPopContent && hasPermission(primaryPopContent.requires);
  if (mainContentWidth <= hideAllButtonWidth) {
    if (extraPopContentLength === 0 && !hasPrimaryPopContent) {
      return null;
    }
    return (
      <Popover
        placement={hideAllPopPosition}
        arrowPointAtCenter
        content={getPopContent()}
        trigger="click"
      >
        <Image className="btn-popover" src={CSVMoreButton} />
      </Popover>
    );
  }
  if (mainContentWidth <= hideExtraButtonWidth) {
    return extraPopContentLength === 0 ? (
      primaryButton
    ) : (
      <div className="extra-btn-container">
        <div
          className={
            primaryButton
              ? 'extra-btn-container extra-btn-container-has-primary'
              : 'extra-btn-container'
          }
        >
          <Popover
            placement={hideExtraPopPosition}
            arrowPointAtCenter
            content={getPopContent()}
            trigger="click"
          >
            <Image className="btn-popover" src={CSVMoreButton} />
          </Popover>
        </div>
        {primaryButton}
      </div>
    );
  }
  return (
    <div className="extra-btn-container">
      <div
        className={
          primaryButton
            ? 'extra-btn-container extra-btn-container-has-primary'
            : 'extra-btn-container'
        }
      >
        {extraButtons}
      </div>
      {primaryButton}
    </div>
  );
};
export default ListButtonsGroup;
