import React, { useEffect, useState } from 'react';
import ContentSections from '../base/ContentSections';
import WhatToDoNextGeneralCard from './WhatToDoNextGeneralCard';
import WhatToDoNextKeyVisualCard from './WhatToDoNextKeyVisualCard';
import { LanguageConfig, WhatToDoNextKeyVisualSections } from '../../config/CustomEnums';
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from "../../utils";


function CreateWhatToDoNextCard() {
  const dispatch = useDispatch();
  const params = useParams();

  const [activeSection, setActiveSection] = useState();
  const { whatToDoNextKey } = useSelector((state) => ({
    whatToDoNextKey: state.whatToDoNextList.whatToDoNext.key,
  }));

  const isKeyVisualVisible = WhatToDoNextKeyVisualSections.includes(whatToDoNextKey)

  const getTabs = (language) => {
    return [
      <WhatToDoNextGeneralCard language={language} />,
      isKeyVisualVisible && <WhatToDoNextKeyVisualCard language={language} />,
    ];
  }

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
  };

  return (
    <>
      <ContentSections
        languageTabContent={languageTabConfig}
        hidePreview={true}
        activeSection={activeSection}
      />
    </>
  );
}

export default CreateWhatToDoNextCard;
