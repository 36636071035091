import React, { Component } from 'react';
import './CouponInstructionSection.scss';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import i18n from '../../../I18n';

@connect(({ createCoupon }) => ({
  couponTranslation: createCoupon.couponTemplate.translations,
}))
class CouponDetailSection extends Component {
  constructor(props) {
    super(props);
    const showdown = require('showdown');
    this.converter = new showdown.Converter();
    this.converter.setOption('simpleLineBreaks', true);
    this.state = {
      detailTitle: props.couponTranslation[props.language]?.detailSectionTitle,
      detailContent:
        props.couponTranslation[props.language]?.detailSectionContent,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.couponTranslation !== this.props.couponTranslation) {
      this.setState({
        detailTitle: this.props.couponTranslation[this.props.language]
          ?.detailSectionTitle,
        detailContent: this.props.couponTranslation[this.props.language]
          ?.detailSectionContent,
      });
    }

    if (this.props.onSubmit) {
      this.props.onSubmitAction({
        detailSectionTitle: this.state.detailTitle,
        detailSectionContent: this.state.detailContent,
      });
    }
  }

  formChanged = () => {
    this.props.dispatch({
      type: 'createCoupon/updateState',
      payload: { formChanged: true },
    });
  };

  render() {
    return (
      <CustomMarkDownArea
        areaTitle={i18n.t('detail', { locale: this.props.language })}
        title={{
          label: i18n.t('detail_section_title', {
            locale: this.props.language,
          }),
          value: this.state.detailTitle,
          valueChange: (value) => {
            this.formChanged();
            this.setState({
              detailTitle: value,
            });
          },
          focus: () => this.props.inputChange('detailsTitle'),
        }}
        content={{
          label: i18n.t('detail_section_content', {
            locale: this.props.language,
          }),
          value: this.state.detailContent,
          valueChange: (value) => {
            this.formChanged();
            this.setState({
              detailContent: value,
            });
          },
          focus: () => this.props.inputChange('detailsContent'),
        }}
      />
    );
  }
}

export default CouponDetailSection;
