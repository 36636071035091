import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import {
  APIStatus,
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';

function GreetingCardDetail({
  greetingCardDetail,
  loadingStatus,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const sections = [
    loadingStatus === APIStatus.calling
      ? <Loading />
      : <MembershipInfoCard
        title="properties"
        data={greetingCardDetail}
        fields={[
          [{ title: 'Name', field: 'name' }],
          [{ title: 'Theme color', field: 'themeColorDisplay' }],
          [{ title: 'Active period', field: 'displayActivePeriod' }],
          [{ title: 'Cover image', field: 'greetingPhoto' }],
        ]}
      />,
  ]

  const tabs = [
    {
      name: 'Detail',
      content: (
        <ContentSections
          sections={sections}
          hidePreview
        />
      ),
    },
  ];

  const buttons =
    !greetingCardDetail?.activeEndDate
      ? []
      : [
        <AuthButton
          title="Edit"
          action={() => {
            history.push({
              pathname: 'edit/',
            });
          }}
          requires={PermissionCodes.changeGreetingCard}
        />,
      ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('greetingCard/getDetail')({ id: params.id }),
      );
    }
    return () => {
      dispatch({ type: 'greetingCard/clearState' });
    };
  }, [dispatch, params.id]);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: greetingCardDetail?.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={greetingCardDetail?.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  greetingCardDetail: state.greetingCard.greetingCard,
  loadingStatus: state.greetingCard.loadingStatus,
});

export default connect(mapStateToProps)(GreetingCardDetail);
