import React, { useState, useEffect } from 'react';
import './CustomListComponent.scss';
import CustomTips from '../base/CustomTips';
import { TabContainer, TabPane, Nav, TabContent } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkHasPermission } from '../../utils';

function CustomListComponent(props) {
  const breadcrumb = props.breadcrumb;
  const caution = props.caution;
  const buttons = props.buttons || [];
  const renderTabsRight = props.renderTabsRight;
  const renderCompare = props.renderCompare;
  const tabs = props.tabs || [];
  const title = props.title || '';
  const defaultActiveKey = props.defaultActiveKey || '';
  const onTabChange = props.onTabChange || (() => {});
  const [activeTab, setActiveTab] = useState();
  const hideTab = props.hideTab;
  const hideHeaderAndFooter = props.hideHeaderAndFooter || false;
  const location = useLocation();
  const history = useHistory();

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  useEffect(() => {
    console.log('location list change');
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setActiveTab(defaultActiveKey);
  }, [defaultActiveKey]);
  const getCaution = () => {
    return caution ? (
      <CustomTips detail={caution.detail} renderExtra={renderCompare}>
        <label className="list-section-container-header-title">
          {caution.title}
        </label>
      </CustomTips>
    ) : null;
  };

  const getTabs = () => {
    return (
      <TabContainer
        activeKey={activeTab || tabs[0]?.name}
        className={`list-section-container-content-bar`}
        onSelect={(key) => {
          onTabChange(key);
        }}
      >
        <Nav className="list-section-container-content-bar nav nav-tabs">
          {tabs.map((tab) => (
            <Nav.Link className="nav-item" eventKey={tab.name}>
              {tab.name}
            </Nav.Link>
          ))}

          {renderTabsRight && renderTabsRight()}
        </Nav>
        <TabContent>
          {tabs.map((tab, index) => (
            <TabPane eventKey={tab.name} key={index}>
              {tab.content}
            </TabPane>
          ))}
        </TabContent>
      </TabContainer>
    );
  };

  // if (!checkHasPermission(user)) {
  //   history.push({ pathname: '/login', state: { isLogout: true } });
  //   return;
  // }

  return (
    <>
      <div
        className={`list-section-container-header ${
          hideTab ? 'list-section-container-header-bottom' : ''
        }`}
        style={hideHeaderAndFooter ? { display: "none" } : {}}
      >
        {breadcrumb}
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left">
            {title ? (
              <label className="list-section-container-header-title">
                {title}
              </label>
            ) : null}
            {getCaution()}
          </div>
          <div className="list-section-container-header-buttons">
            {buttons.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        </div>
      </div>
      {hideTab ? tabs.map((item) => item.content) : getTabs()}
    </>
  );
}

export default CustomListComponent;
