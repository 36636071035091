import React, { useState, useEffect } from 'react';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import { useSelector, useDispatch } from 'react-redux';
import './CouponCampaignSection.scss';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import {
  CampaignErrorHandleField,
  isShowError,
  isShowErrorGroup,
  firstError,
} from './CreateCampaignHandleError';
import { Collapse } from 'react-bootstrap';
import { TARGET_USERS } from '../../../models/CreateCampaignModel';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import CustomRadios from '../../base/CustomRadios';
import { Row } from 'react-bootstrap';
import CustomAddRemoveMutipleSelector from '../../customer/CustomAddRemoveMutipleSelector';
import moment from 'moment'
import ErrorFieldMessageGroup from '../../base/ErrorFieldMessageGroup';



function CouponCampaignSection(props) {
  const {
    overallLimit,
    displayOverLimit,
    perHeadLimit,
    requiredPoints,
    discount,
    discountRequiredPoints,
    discountStartDate,
    discountEndDate,
    discountTargetedUser,
    discountTargetedCustomerGroups,
    discountTargetedCustomerSegments,
    activeStartDate,
    activeEndDate,
    couponCampaignDisplayPriority,
    errorFields,
    segments,
    groups,
  } = useSelector((state) => ({
    overallLimit: state.createCampaign.campaign.overallLimit,
    displayOverLimit: state.createCampaign.campaign.displayOverLimit,
    perHeadLimit: state.createCampaign.campaign.perHeadLimit,
    requiredPoints: state.createCampaign.campaign.requiredPoints,
    discount: state.createCampaign.campaign.discount,
    discountRequiredPoints: state.createCampaign.campaign.discountRequiredPoints,
    discountStartDate: state.createCampaign.campaign.discountStartDate,
    discountEndDate: state.createCampaign.campaign.discountEndDate,
    discountTargetedUser: state.createCampaign.campaign.discountTargetedUser,
    discountTargetedCustomerGroups: state.createCampaign.campaign.discountTargetedCustomerGroups,
    discountTargetedCustomerSegments: state.createCampaign.campaign.discountTargetedCustomerSegments,
    isDiscountForAllUser: state.createCampaign.campaign.isDiscountForAllUser,
    activeStartDate: state.createCampaign.campaign.activeStartDate,
    activeEndDate: state.createCampaign.campaign.activeEndDate,
    couponCampaignDisplayPriority:
      state.createCampaign.campaign.couponDisplayPriority,
    errorFields: state.createCampaign.errorFields,
    segments: state.segments.segmentList,
    groups: state.customerGroup.groupList,
  }));
  const dispatch = useDispatch();
  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => { });
  const [displayLimit, setDisplayLimit] = useState(displayOverLimit);
  const [overallCount, setOverallCount] = useState(overallLimit);
  const [perHeadCount, setPerHeadCount] = useState(perHeadLimit);
  const [couponDisplayPriority, setCouponDisplayPriority] = useState(
    couponCampaignDisplayPriority,
  );
  const [requiredPointsCount, setRequiredPointsCount] =
    useState(requiredPoints);

  const [discountDisplay, setDiscountDisplay] = useState(discount);
  const [discountRequiredPointsDisplay, setDiscountRequiredPoints] =
    useState(discountRequiredPoints);
  const [discountStartDateDisplay, setDiscountStartDate] =
    useState(discountStartDate);
  const [discountEndDateDisplay, setDiscountEndDate] =
    useState(discountEndDate);
  const [targetUserDisplay, setTargetUser] = useState(discountTargetedUser);
  const [targetGroupDisplay, setTargetGroupDisplay] = useState(discountTargetedCustomerGroups);
  const [targetSegmentDisplay, setTargetSegmentDisplay] = useState(discountTargetedCustomerSegments);

  const options = (source) => {
    if (!source) {
      return [];
    }
    return source.map((item) => {
      return {
        name: item.name,
        value: item,
      };
    });
  };

  const customerGroupConfig = {
    title: 'Target customer groups',
    titleTips: 'Select target customer group(s)',
    data: options(groups),
    selectedList: targetGroupDisplay,
    setValue: (option) => {
      dispatch({
        type: 'createCampaign/setFieldToSession',
        payload: { discountTargetedCustomerGroups: option },
      });
      setTargetGroupDisplay(option);
    },
    sourceReminderText: 'Select customer groups',
    amidReminderText: 'Please select customer group',
    error: {
      error: isShowError(
        CampaignErrorHandleField.customerGroup.name,
        errorFields,
      ),
      message: CampaignErrorHandleField.customerGroup.message,
    },
    selectAllTitle: 'all customer groups',
    loadMoreAction: 'customerGroup/getCurrentPageList',
    filterAction: 'customerGroup/getCurrentPageList',
    isSourceReminderFilterable: true,
  };

  const segmentConfig = {
    title: 'Target segments',
    titleTips: 'Select target customer segment(s)',
    data: options(segments),
    selectedList: targetSegmentDisplay,
    setValue: (option) => {
      dispatch({
        type: 'createCampaign/setFieldToSession',
        payload: { discountTargetedCustomerSegments: option },
      });
      setTargetSegmentDisplay(option);
    },
    sourceReminderText: 'Select customer segment(s)',
    amidReminderText: 'Please select customer segment',
    error: {
      error: isShowError(
        CampaignErrorHandleField.segment.name,
        errorFields,
      ),
      message: CampaignErrorHandleField.segment.message,
    },
    selectAllTitle: 'all segments',
    loadMoreAction: 'segments/getSegmentsList',
    filterAction: 'segments/getSegmentsList',
    isSourceReminderFilterable: true,
  };

  useEffect(() => {
    if (targetUserDisplay == null && discount == true) {
      if (discountTargetedCustomerSegments?.length > 0 || discountTargetedCustomerGroups?.length > 0)
        setTargetUser(TARGET_USERS.withTarget)
      else
        setTargetUser(TARGET_USERS.all)
    }
  }, [])

  useEffect(() => {
    if (onSubmit) {
      let discountData = {
        discountTargetedCustomerGroups: [],
        discountTargetedCustomerSegments: []
      }
      if (discountDisplay) {
        discountData = {
          discountRequiredPoints: discountRequiredPointsDisplay,
          discountStartDate: discountStartDateDisplay,
          discountEndDate: discountEndDateDisplay,
          discountTargetedUser: targetUserDisplay,
          isDiscountForAllUser: targetUserDisplay == TARGET_USERS.all ? true : false,
          discountTargetedCustomerGroups: targetUserDisplay == TARGET_USERS.all ? [] : targetGroupDisplay,
          discountTargetedCustomerSegments: targetUserDisplay == TARGET_USERS.all ? [] : targetSegmentDisplay,
        }
      }
      onSubmitAction({
        overallLimit: overallCount,
        displayOverLimit: displayLimit,
        perHeadLimit: perHeadCount,
        requiredPoints: requiredPointsCount,
        couponDisplayPriority: couponDisplayPriority,
        discount: discountDisplay,
        ...discountData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(
      [
        CampaignErrorHandleField.requiredPoints.name,
        CampaignErrorHandleField.requiredPointsWholeNumber.name,
        CampaignErrorHandleField.couponDisplayPriority.name,
        CampaignErrorHandleField.requiredPointsDiscountedRequired.name,
        CampaignErrorHandleField.requiredPointsDiscountedAmount.name,
        CampaignErrorHandleField.requiredPointsDiscountedWholeNumber.name,
        CampaignErrorHandleField.discountStartEndDate.name,
        CampaignErrorHandleField.discountStartEndDateWithin.name,
        CampaignErrorHandleField.discountTargetedUser.name,
        CampaignErrorHandleField.discountTargetedUserGroup.name,
      ], errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  const startDateChange = (date) => {
    dispatch({
      type: 'createCampaign/setFieldToSession',
      payload: { discountStartDate: date },
    });
    setDiscountStartDate(date)
  };

  const endDateChange = (date) => {
    dispatch({
      type: 'createCampaign/setFieldToSession',
      payload: { discountEndDate: date },
    });
    setDiscountEndDate(date)
  };

  const numberInput = ({
    id,
    labelText,
    type,
    valueChange,
    fieldDescription,
    defaultValue = undefined,
    onError = false,
  }) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {labelText}
        </label>
        <div>
          <input
            value={defaultValue}
            type="number"
            min="0"
            className={`coupon-campaign-section-input ${onError ? 'error-field' : ''
              }`}
            onChange={({ target }) => {
              let value = target.value;
              if (value === '') {
                value = null;
              }
              valueChange(value);
            }}
          />
          <label className="section-short-description">{type}</label>
        </div>
        {fieldDescription ? (
          <label
            id={id}
            className={`${onError
              ? 'error-field-message-style'
              : 'coupon-campaign-section-caution-description'
              } `}
          >
            {fieldDescription}
          </label>
        ) : null}
      </>
    );
  };

  const getSelectorWithConfig = (config) => {
    return (
      <CustomAddRemoveMutipleSelector
        title={config.title}
        titleTips={config.titleTips}
        data={config.data}
        selectedList={config.selectedList}
        setValue={config.setValue}
        sourceReminderText={config.sourceReminderText}
        amidReminderText={config.amidReminderText}
        sourceReminderEnabelSelectAll={true}
        addButton={config.addButton}
        error={config.error ?? {}}
        requires={config.requires}
        selectAllTitle={config.selectAllTitle}
        loadMoreAction={config.loadMoreAction}
        defaultFilter={config.defaultFilter}
        isSourceReminderFilterable={config.isSourceReminderFilterable}
        filterAction={config.filterAction}
      />
    );
  };

  console.log('errorFields:', errorFields, couponDisplayPriority);

  return (
    <>
      <label className="create-section-title">Coupon Campaign</label>

      {numberInput({
        id: 'couponDisplayPriority',
        labelText: 'Display Priority',
        valueChange: (value) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: { couponDisplayPriority: value },
          });
          setCouponDisplayPriority(value);
        },
        fieldDescription:
          'Minimum value is 1. The smaller value, the higher priority.',
        defaultValue: couponDisplayPriority,
        onError: isShowError(
          CampaignErrorHandleField.couponDisplayPriority.name,
          errorFields,
        ),
      })}

      {numberInput({
        id: 'couponOverallLimit',
        labelText: 'Overall limit (Optional)',
        type: 'number of coupons',
        valueChange: (value) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: { overallLimit: value },
          });
          setOverallCount(value);
        },
        fieldDescription: `It should not be greater than 1000, the current number of coupon
          left in stock.`,
        defaultValue: overallCount,
        onError: false,
      })}

      <label className="create-section-label create-section-label-bottom-space">
        Display the overall limit in the campaign
      </label>
      <CustomSwitchButton
        defaultChecked={displayLimit}
        onChange={(isChecked) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: { displayOverLimit: isChecked },
          });
          setDisplayLimit(isChecked);
        }}
      />

      {numberInput({
        labelText: 'Per head limit (Optional)',
        type: 'number of coupons',
        valueChange: (value) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: { perHeadLimit: value },
          });
          setPerHeadCount(value);
        },
        defaultValue: perHeadCount,
        onError: false,
      })}

      {numberInput({
        labelText: 'Required points',
        type: 'points',
        valueChange: (value) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: { requiredPoints: value },
          });
          setRequiredPointsCount(value);
        },
        defaultValue: requiredPointsCount,
        onError: isShowErrorGroup(
          [CampaignErrorHandleField.requiredPoints, CampaignErrorHandleField.requiredPointsWholeNumber, CampaignErrorHandleField.requiredPointsDiscountedWholeNumber],
          errorFields,
        ),
      })}
      <ErrorFieldMessageGroup errorFields={errorFields} isShowError={CampaignErrorHandleField.isShowError}
        errors={[CampaignErrorHandleField.requiredPoints, CampaignErrorHandleField.requiredPointsWholeNumber]} />

      <label className="create-section-label create-section-label-bottom-space">
        Discount
      </label>
      <CustomSwitchButton
        defaultChecked={discountDisplay}
        onChange={(isChecked) => {
          dispatch({
            type: 'createCampaign/setFieldToSession',
            payload: { discount: isChecked },
          });
          setDiscountDisplay(isChecked)
        }}
      />

      <Collapse in={discountDisplay}>
        <div>
          {numberInput({
            labelText: 'Required points (Discounted)',
            type: 'points',
            valueChange: (value) => {
              dispatch({
                type: 'createCampaign/setFieldToSession',
                payload: { discountRequiredPoints: value },
              });
              setDiscountRequiredPoints(value)
            },
            defaultValue: discountRequiredPointsDisplay,
            onError: isShowErrorGroup(
              [CampaignErrorHandleField.requiredPointsDiscountedRequired, CampaignErrorHandleField.requiredPointsDiscountedAmount, CampaignErrorHandleField.requiredPointsDiscountedWholeNumber],
              errorFields,
            ),
          })}

          <ErrorFieldMessageGroup errorFields={errorFields} isShowError={CampaignErrorHandleField.isShowError}
            errors={[CampaignErrorHandleField.requiredPointsDiscountedRequired, CampaignErrorHandleField.requiredPointsDiscountedAmount, CampaignErrorHandleField.requiredPointsDiscountedWholeNumber]} />

          <div>
            <label className="create-section-label create-section-label-bottom-space">
              Discount start date
            </label>
            <CustomDateTimeSelect
              disabled={!discountDisplay}
              defaultTime={discountStartDate}
              onTimeChange={startDateChange}
              hideTime={false}
            />
            <label className="create-section-label create-section-label-bottom-space">
              Discount end date
            </label>
            <CustomDateTimeSelect
              disabled={!discountDisplay}
              defaultTime={discountEndDate}
              onTimeChange={endDateChange}
              hideTime={false}
            />
            <ErrorFieldMessageGroup errorFields={errorFields} isShowError={CampaignErrorHandleField.isShowError} errors={[CampaignErrorHandleField.discountStartEndDate, CampaignErrorHandleField.discountStartEndDateWithin]} />

            <div>
              <label className="create-section-label create-section-label-bottom-space">
                Target users
              </label>
              <Row className="featured-exclusive-row">
                <CustomRadios
                  onChange={(value) => {
                    setTargetUser(value);
                  }}
                  default={targetUserDisplay}
                  options={Object.entries(TARGET_USERS).map(([key, value]) => ({ label: value, value: value }))}
                />
              </Row>
              <ErrorFieldMessage
                id={CampaignErrorHandleField.discountTargetedUser.name}
                error={isShowError(
                  CampaignErrorHandleField.discountTargetedUser.name,
                  errorFields,
                )}
                message={CampaignErrorHandleField.discountTargetedUser.message}
              />
            </div>
            <Collapse in={targetUserDisplay == TARGET_USERS.withTarget}>
              <div>
                {getSelectorWithConfig(customerGroupConfig)}
                {getSelectorWithConfig(segmentConfig)}
                <ErrorFieldMessage
                  id={CampaignErrorHandleField.discountTargetedUserGroup.name}
                  error={isShowError(
                    CampaignErrorHandleField.discountTargetedUserGroup.name,
                    errorFields,
                  )}
                  message={CampaignErrorHandleField.discountTargetedUserGroup.message}
                />
              </div>
            </Collapse>


          </div>
        </div>

      </Collapse>


    </>
  );
}

export default CouponCampaignSection;
