import React from 'react';
import { useDispatch } from 'react-redux';
import { LanguageConfig } from '../../config/CustomEnums';
import { CreateMissionError, MissionEarningIcon } from '../../models/MissionModel';
import CustomInputWithTitle from '../customer/CustomInputWithTitle';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomInputWithMaxLength from '../base/CustomInputWithMaxLength';
import LinkedCampaignSection from './LinkedCampaignSection';

const GroupMissionSection = ({
  language,
  missionEarningType,
  linkedCampaign,
  disableChange,
  errorFields,
  totalTarget,
  individualTarget,
  reminderEligible,
  reminderIneligible,
  reminderComplete,
  reminderExpired,
}) => {
  const dispatch = useDispatch();

  const isError = (field, onlyEnglish = true) => {
    if (!onlyEnglish) {
      return errorFields.indexOf(`${field}-${language}`) > -1
    };
    return errorFields.indexOf(field) > -1 && language == LanguageConfig.english;
  };

  const isRecycling = missionEarningType?.value?.value === MissionEarningIcon.PHOTO_TAKING.value;
  const dimLinkedCampaign = !!missionEarningType?.value?.value;


  return (
    <>
      {language == LanguageConfig.english && dimLinkedCampaign
        ? <>
          <CustomTitleLabel title={'Link to campaign'} className={dimLinkedCampaign ? 'custom-title-label-dimmed-color' : ''} />
          <input
            type="text"
            disabled={true}
            value={''}
            className={`custom-markdown-area-title  custom-markdown-area-title-short custom-title-input-dimmed-length`}
          />
        </>
        : null
      }
      {
        language == LanguageConfig.english && !dimLinkedCampaign
          ? <LinkedCampaignSection
            isRecycling={isRecycling}
            linkedCampaign={linkedCampaign}
            disableChangeCampaign={disableChange}
            errorFields={errorFields}
            hideNumberSection={true}
            showRemoveButton={true}
          />
          : null
      }

      {language == LanguageConfig.english
        ? <div style={{ opacity: disableChange ? 0.5 : 1 }}>
          <CustomInputWithTitle
            title="Mission target"
            type={'number'}
            disabled={disableChange}
            defaultValue={totalTarget}
            placeholder={"Enter in digit"}
            setValue={(value) => {
              dispatch({
                type: 'mission/updateMissionDetail',
                payload: { totalTarget: value },
              });
            }}
            error={{
              id: 'totalTarget',
              error: isError(CreateMissionError.totalTarget.name),
              message: CreateMissionError.totalTarget.message
            }}
          />
          {
            isError(CreateMissionError.totalTarget.name)
              ? null
              : <label className={`tips-message margin-top-8`}>
                Minimum mission target is at least 10 items/times
              </label>
          }
          <CustomInputWithTitle
            title="Reminder bar: Min. requirement checking"
            type={'number'}
            disabled={disableChange}
            defaultValue={individualTarget}
            placeholder={"Enter in digit"}
            setValue={(value) => {
              dispatch({
                type: 'mission/updateMissionDetail',
                payload: { individualTarget: value },
              });
            }}
            error={{
              id: 'individualTarget',
              error: isError(CreateMissionError.individualTarget.name),
              message: CreateMissionError.individualTarget.message
            }}
          />
          {
            isError(CreateMissionError.individualTarget.name)
              ? null
              : <label className={`tips-message margin-top-8`}>
                Minimum requirement is at least 1 quantity which is submitted successfully
              </label>
          }
        </div>
        : null
      }

      <CustomInputWithMaxLength
        sectionTitle={i18n.t('reminderEligible', { locale: language })}
        value={reminderEligible}
        valueChange={(value) => {
          dispatch({
            type: 'mission/updateMissionDetailTranslations',
            payload: { reminderEligible: value, language },
          });
        }}
        placeholder={i18n.t('enterCopywriting', { locale: language })}
        customInputContainerClassName={"custom-input-with-max-length-with-count-619"}
        maxLength={language == LanguageConfig.english ? 42 : 22}
        showCount
        error={isError(CreateMissionError.reminderEligible.name)}
        errorMessage={CreateMissionError.reminderEligible.message}
        errorId={'reminderEligible'}
      />
      <label className={`tips-message margin-top-8 ${isError(CreateMissionError.reminderEligibleMax.name, false) ? 'error-field-message-style' : ''}`}>
        {i18n.t('group_mission_reminder_max', { locale: language, max: language == "en" ? 42 : 22 })}
      </label>

      <CustomInputWithMaxLength
        sectionTitle={i18n.t('reminderIneligible', { locale: language })}
        value={reminderIneligible}
        valueChange={(value) => {
          dispatch({
            type: 'mission/updateMissionDetailTranslations',
            payload: { reminderIneligible: value, language },
          });
        }}
        placeholder={i18n.t('enterCopywriting', { locale: language })}
        customInputContainerClassName={"custom-input-with-max-length-with-count-619"}
        maxLength={language == LanguageConfig.english ? 42 : 22}
        showCount
        error={isError(CreateMissionError.reminderIneligible.name)}
        errorMessage={CreateMissionError.reminderIneligible.message}
        errorId={'reminderIneligible'}
      />
      <label className={`tips-message margin-top-8 ${isError(CreateMissionError.reminderIneligibleMax.name, false) ? 'error-field-message-style' : ''}`}>
        {i18n.t('group_mission_reminder_max', { locale: language, max: language == "en" ? 42 : 22 })}
      </label>

      <CustomInputWithMaxLength
        sectionTitle={i18n.t('reminderCompleted', { locale: language })}
        value={reminderComplete}
        valueChange={(value) => {
          dispatch({
            type: 'mission/updateMissionDetailTranslations',
            payload: { reminderComplete: value, language },
          });
        }}
        placeholder={i18n.t('enterCopywriting', { locale: language })}
        customInputContainerClassName={"custom-input-with-max-length-with-count-619"}
        maxLength={language == LanguageConfig.english ? 42 : 22}
        showCount
        error={isError(CreateMissionError.reminderComplete.name)}
        errorMessage={CreateMissionError.reminderComplete.message}
        errorId={'reminderComplete'}
      />
      <label className={`tips-message margin-top-8 ${isError(CreateMissionError.reminderCompleteMax.name, false) ? 'error-field-message-style' : ''}`}>
        {i18n.t('group_mission_reminder_max', { locale: language, max: language == "en" ? 42 : 22 })}
      </label>

      <CustomInputWithMaxLength
        sectionTitle={i18n.t('reminderExpired', { locale: language })}
        value={reminderExpired}
        valueChange={(value) => {
          dispatch({
            type: 'mission/updateMissionDetailTranslations',
            payload: { reminderExpired: value, language },
          });
        }}
        placeholder={i18n.t('enterCopywriting', { locale: language })}
        customInputContainerClassName={"custom-input-with-max-length-with-count-619"}
        maxLength={language == LanguageConfig.english ? 42 : 22}
        showCount
        error={isError(CreateMissionError.reminderExpired.name)}
        errorMessage={CreateMissionError.reminderExpired.message}
        errorId={'reminderExpired'}
      />
      <label className={`tips-message margin-top-8 ${isError(CreateMissionError.reminderExpiredMax.name, false) ? 'error-field-message-style' : ''}`}>
        {i18n.t('group_mission_reminder_max', { locale: language, max: language == "en" ? 42 : 22 })}
      </label>
    </>
  )
}


export default GroupMissionSection;
