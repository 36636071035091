import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useDispatch, connect } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useLocation, useHistory } from 'react-router-dom';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import {
  BarcodeType,
  PublishTagType,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import { ImportResource } from '../../../models/DownloadImportModel';
import Filter from './Filter'

const TabsConfig = [
  { key: BarcodeType.all, name: 'All Types' },
  { key: BarcodeType.partner, name: 'Partner Barcode' },
  { key: BarcodeType.nonPartner, name: 'Non-Partner Barcode' },
]

function BarcodeList({
  list,
  listDisplayFields,
  checkedList,
  pageInfo,
  totalCount,
  totalPage,
  importedFile,
}) {
  const hiddenFileInput = useRef(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const deletePKs = deleteItems?.map(item => item.pk);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const searchQuery = queryString.parse(location.search);
  let currentPage = parseInt(searchQuery?.page);
  if (isNaN(currentPage)) {
    currentPage = 1;
  }
  const searchKey = searchQuery?.search;
  const fetchData = () => {
    dispatch({
      type: "barcodes/getCurrentPageList", payload: {
        ...searchQuery,
        page: currentPage,
        searchKey,
      }
    })
  };

  useEffect(() => {
    dispatch({
      type: 'brand/getCurrentPageBrands', payload: {
        isAll: true, moreSearch: {
          showInBarcode: true,
        },
        isAllActive: true,
      }
    })
  }, [])

  useEffect(() => {
    fetchData();
    console.log("Fetching barcodes...")
  }, [location]);

  useEffect(() => {
    if (importedFile) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.barcodeListing,
          file: importedFile,
        }
      });
    }
  }, [importedFile]);

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeBarcode,
    },
  ];

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={false}
          tabs={TabsConfig}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={list}
              totalPage={totalPage}
              currentPage={currentPage}
              customClassName="mission-list"
              model={'barcodes'}
              permissionGroup={PermissionCodes.barcode}
              deleteInfo={{
                data: [],
                title: 'Barcode',
                relatedName: '',
                onComfirm: {},
                relatedSections: null,
              }}
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`barcodes/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeBarcode,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.changeBarcode,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={[
                      {
                        name:
                          item.status === PublishTagType.published
                            ? 'Unpublish'
                            : 'Publish',
                        action: () => {
                          dispatch({
                            type: "barcodes/publishOrUnpublishObj", payload: {
                              id: item.pk,
                              publish: item.status !== PublishTagType.published,
                              afterAction: fetchData,
                            }
                          })
                        },
                        requires: PermissionCodes.changeBarcode,
                      },
                    ]}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  const exportCSVAction = () => {
    dispatch({
      type: "downloadAndImport/createDownloadTask", payload: {
        from: ImportResource.barcodeListing,
      }
    })
  };

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    dispatch({
      type: 'uploadFiles/uploadFile',
      payload: {
        file: fileUploaded,
        source: event,
      }
    });
    event.target.value = null;
  };

  const buttons = [
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Export .csv"
      action={exportCSVAction}
      requires={PermissionCodes.addExportjob}
    />,

    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Import"
      action={handleImportClick}
      requires={PermissionCodes.addImportjob}
    />,

    <AuthButton
      title="Create Barcode"
      action={() => {
        history.push('barcodes/create');
      }}
      requires={PermissionCodes.changeBarcode}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv, .zip"
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: "",
          title: NavBarNames.barcodeListing,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="Barcodes"
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch({
            type: "barcodes/delete", payload: {
              deletePKs,
              afterAction: fetchData,
            }
          })
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  listDisplayFields: state.barcodes.listDisplayFields,
  pageInfo: state.barcodes.pageInfo,
  totalCount: state.barcodes.totalCount,
  totalPage: state.barcodes.totalPage,
  list: state.barcodes.currentPageList,
  checkedList: state.barcodes.checkedList,
  importedFile: state.uploadFiles.importedFile,
});

export default connect(mapStateToProps)(BarcodeList);
