import React from 'react';
import './CustomTips.scss';
import { Popover, OverlayTrigger, Image } from 'react-bootstrap';
import Info from '../../assets/images/info.svg';
// import { ReactComponent as TestImage } from '../../assets/images/campaign/general_without_detail_title.svg';

const getContent = (imageCom, detail) => {
  if (!imageCom) {
    return detail;
  }

  return (
    <div className="custom-tips-area-image-container">
      <label className="custom-tips-area-image-label">{detail}</label>
      <Image src={imageCom} className="custom-tips-area-image-content" />
      {/* <TestImage className="custom-tips-area-image-content" /> */}
      {/* {imageCom} */}
    </div>
  );
};

function CustomTips(props) {
  const children = props.children || null;
  const { detail, iconClassName, renderExtra, innerImage } = props;
  if (!detail) {
    return <div className="tips-pointer-container">{children}</div>;
  }

  return (
    <div className="custom-tips-area">
      <Image src={innerImage} width={0} height={0} />
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={
          <Popover
            id={`popover-positioned-${detail}`}
            className={`caution-container ${
              innerImage ? 'caution-container-width-with-image' : ''
            }`}
          >
            <Popover.Content
              className={`caution-content ${
                innerImage ? 'caution-content-image' : ''
              }`}
            >
              {getContent(innerImage, detail)}
            </Popover.Content>
          </Popover>
        }
      >
        <div className="tips-pointer-container">
          {children}
          <Image src={Info} className={iconClassName || 'caution-icon'} />
          {/* {innerImage} */}
        </div>
      </OverlayTrigger>
      {renderExtra && renderExtra()}
    </div>
  );
}

export default CustomTips;
