import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { APIStatus } from '../../config/CustomEnums';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import { GeneralPurchaseSubType } from '../../models/CreateEarningRulesModel';
import { TRANSACTION_RELATED_TYPE, TRANSACTION_REWARD_TYPE } from '../../models/CreateTransactionModel';
import { createAction } from '../../utils';
import { formatDate } from '../../utils/TimeFormatUtil';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import Loading from '../base/Loading';
import CreateTransactionStepFourContentSection from './CreateTransactionStepFourContentSection';
import CreateTransactionStepFourSection from './CreateTransactionStepFourSection';

export const getGeneralSectionData = (transaction, stepFourPreview = false) => {
  const transactionDate = transaction?.transactionDate ? new Date(transaction?.transactionDate) : new Date();
  const transactionDisplayDate = formatDate(transactionDate, 'DD MMM yyyy (ddd),HH:mm a');
  const fieldsData = [
    { name: "Customer", value: transaction?.customer?.name, secondName: "Membership ID", secondValue: transaction?.customer?.membershipId },
    ...(stepFourPreview 
      ? [
          { name: "Target customer groups", value: transaction?.customerGroup?.map(item => item.name).join(', ') },
          { name: "Target segments", value: transaction?.customerSegment?.map(item => item.name).join(', ') },
        ]
      : []
    ),
    { name: "Transaction date", value: transactionDisplayDate },
    { name: "Related transaction type", value: transaction?.relatedTransactionType?.label },
    ...(
      transaction?.relatedTransactionType?.value == TRANSACTION_RELATED_TYPE.GREEN_DINING.value
      ? [{ 
          name: "General Purchase Type", 
          value: GeneralPurchaseSubType?.[transaction?.generalPurchaseSubType]?.label || GeneralPurchaseSubType.GREEN_DINING.label 
        }]
      : []
    ),
    { name: "Staff name (optional)", value: transaction?.staffName },
    { name: "Remarks (optional)", value: transaction?.remarks },
  ];
  return fieldsData;
};

export const getNumberSectionData = (transaction) => {
  let fieldsData = [];
  if (transaction?.rewardType === TRANSACTION_REWARD_TYPE.POINT_ONLY.value) {
    fieldsData = [
      { name: "Reward type", value: TRANSACTION_REWARD_TYPE?.[transaction?.rewardType]?.label },
      { name: "Total Point", value: transaction?.rewardPointValue },
    ];
  }
  else if (transaction?.rewardType === TRANSACTION_REWARD_TYPE.BOTH.value) {
    fieldsData = [
      { name: "Reward type", value: TRANSACTION_REWARD_TYPE?.[transaction?.rewardType]?.label },
      { name: "Total Carbon", value: transaction?.rewardCarbonValue },
      { name: "Total Point", value: transaction?.rewardPointValue },
    ];
  }
  else {
    fieldsData = [
      { name: "Reward type", value: TRANSACTION_REWARD_TYPE?.[transaction?.rewardType]?.label || TRANSACTION_REWARD_TYPE.CARBON_ONLY.label },
      { name: "Total Carbon", value: transaction?.rewardCarbonValue },
    ];
  }
  return fieldsData;
};


function CreateTransactionStepFour() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { transaction, loadingStatus } = useSelector((state) => ({
    transaction: state.createTransaction.transaction,
    loadingStatus: state.loading.status,
  }));
  const transactionType = transaction.transactionDisplayType;

  const getSections = () => {
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE) {
      return [
        <CreateTransactionStepFourSection title="GENERAL" fieldsData={getGeneralSectionData(transaction, true)} />,
        <CreateTransactionStepFourContentSection />,
        <CreateTransactionStepFourSection title="ADD CARBON / CW POINT(S)" fieldsData={getNumberSectionData(transaction)} />,
      ];
    };
    return [];
  };

  return (
    <>
      <ContentSections
        sections={
          loadingStatus === APIStatus.calling
          ? [<Loading />]
          : getSections()
        }
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() =>
          dispatch(
            createAction('createTransaction/stepChange')({
              isBack: true,
              step: 3,
            }),
          )
        }
        saveText={'Submit'}
        saveButtonDisabled={loadingStatus === APIStatus.calling}
        saveAction={() => {
          if (location.pathname.includes('edit')) {
            dispatch(
              createAction('createTransaction/updateTransaction')(),
            );
          } else {
            dispatch(
              createAction('createTransaction/createTransaction')(),
            );
          };
        }}
      />
    </>
  );
}

export default CreateTransactionStepFour;
