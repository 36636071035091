import React, { useEffect, useState } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import { createAction, useCompare } from '../../utils';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomRadioWithTitleAndRelated from './CustomRadioWithTitleAndRelated';
import { CustomerErrorHandleFields } from '../../containers/customers/customer/CustomerErrorHandleFields';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import CommunityFilterableDropdown from '../../containers/merchants/community/CommunityFilterableDropdown';

function CustomTitleWithInput({ title, defaultValue, setValue, error = {} }) {
  return (
    <div style={{ display: 'grid' }}>
      <CustomTitleLabel title={title} />
      <input
        type="text"
        onChange={({ target }) => setValue(target.value)}
        value={defaultValue || ''}
        className={`custom-markdown-area-title ${
          error.error ? 'error-field' : ''
        } custom-markdown-area-title-short`}
        onFocus={() => {}}
      />
      <ErrorFieldMessage id={error.id} error={error} message={error.message} />
    </div>
  );
}

function PersonalInfoEditCard({ title, customer, errorFields }) {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(customer.firstName);
  const [lastName, setLastName] = useState(customer.lastName);
  const [chineseFirstName, setChineseFirstName] = useState(
    customer.chineseFirstName,
  );
  const [chineseLastName, setChineseLastName] = useState(
    customer.chineseLastName,
  );
  const [nickName, setNickName] = useState(customer.nickname);
  const [gender, setGender] = useState(customer.gender);
  const [octopusId, setOctopusId] = useState(customer.octopusId || '');
  const [community, setCommunity] = useState({
    label: customer.communityName,
    value: customer.community,
  });
  // const [preferredMessageLanguage, setPreferredMessageLanguage] = useState({
  //   label: customer.preferredMessageLanguageDisplay,
  //   value: customer.preferredMessageLanguage,
  // });
  const [isAssignedAsTestingCustomer, setIsAssignedAsTestingCustomer] =
    useState(customer.isAssignedAsTestingCustomer);
  console.log('@@community:', community);
  const vals = {
    firstName,
    lastName,
    chineseFirstName,
    chineseLastName,
    nickName,
    gender,
    octopusId,
    // preferredMessageLanguage,
    isAssignedAsTestingCustomer,
    community,
  };

  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'customerList/changeVals', payload: { vals } });
    }
  });
  console.log('@@68: ', errorFields);
  return (
    <div>
      <label className="create-section-title">Personal Information</label>

      <CustomTitleWithInput
        title={'First name'}
        defaultValue={firstName}
        setValue={setFirstName}
        error={{
          id: 'firstName',
          error: errorFields?.fields?.includes('firstName'),
          message: errorFields?.messages?.map((item) => {
            if (item.field === 'firstName') {
              return CustomerErrorHandleFields[item.field][item.errorType];
            }
          }),
        }}
      />

      <CustomTitleWithInput
        title={'Last name'}
        defaultValue={lastName}
        setValue={setLastName}
        error={{
          id: 'lastName',
          error: errorFields?.fields?.includes('lastName'),
          message: errorFields?.messages?.map((item) => {
            if (item.field === 'lastName')
              return CustomerErrorHandleFields[item.field][item.errorType];
          }),
        }}
      />

      {/* <CustomTitleWithInput
        title={'Chinese First name(Optional)'}
        defaultValue={''}
        setValue={setChineseFirstName}
        error={{}}
      />

      <CustomTitleWithInput
        title={'Chinese Last name(Optional)'}
        defaultValue={''}
        setValue={setChineseLastName}
        error={{}}
      /> */}

      <CustomTitleWithInput
        title={'Preferred name (optional)'}
        defaultValue={nickName}
        setValue={setNickName}
        error={{}}
      />

      <CustomRadioWithTitleAndRelated
        title="Gender (optional)"
        options={[
          { label: 'Male', value: 'MALE' },
          { label: 'Female', value: 'FEMALE' },
          { label: 'Others', value: 'NOT_DISCLOSED' },
          { label: 'Prefer not to say', value: 'PREFER_NOT_TO_SAY'},
        ]}
        defaultValue={gender}
        setValue={setGender}
      />

      {/* <CustomTitleWithDropDown
        title={'Preferred message language'}
        source={[
          { name: 'English', value: 'en' },
          { name: '简体', value: 'zh-Hant' },
          { name: '繁体', value: 'zh-Hans' },
        ]}
        defaultValue={preferredMessageLanguage}
        needFilter={false}
        setValue={setPreferredMessageLanguage}
        errors={errorFields?.fields}
      /> */}

      <CustomTitleWithSwitch
        title={'Assign as test customer'}
        defaultValue={isAssignedAsTestingCustomer}
        setValue={setIsAssignedAsTestingCustomer}
      />

      <CustomTitleWithInput
        title={'Octopus ID (optional)'}
        defaultValue={octopusId}
        setValue={setOctopusId}
        error={{}}
      />

      <CommunityFilterableDropdown
        title={'Community(Optional)'}
        defaultSelectedObject={community}
        selectedObject={setCommunity}
        onRemoveButtonClick={() => setCommunity(null)}
      />
    </div>
  );
}

export default PersonalInfoEditCard;
