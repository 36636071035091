import React, { useState, useEffect } from 'react';
import './EarningRuleList.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import {
  DELETE_RELATED_SECTIONS,
  EarningRuleType,
} from '../../../config/CustomEnums';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { createAction } from '../../../utils';
import queryString from 'query-string';
import Filter from './Filter';

function EarningRuleList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
  } = useSelector((state) => ({
    listFields: state.earningRuleList.listDisplayFields,
    dataList: state.earningRuleList.earningRuleTypeList,
    pageInfo: state.earningRuleList.pageInfo,
    totalPage: state.earningRuleList.totalPage,
    totalCount: state.earningRuleList.totalCount,
    checkedList: state.earningRuleList.checkedList,
  }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const buttons = [
    <AuthButton
      title="Create Earning Rules"
      action={() => {
        dispatch({ type: 'earningRuleList/clearData' });
        history.push({
          pathname: '/earns/create',
        });
      }}
      requires={PermissionCodes.addEarningRule}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteEarningRule,
    },
  ];

  const tabs = [
    {
      name: 'List Of Earnning Rules',
      content: (
        <BaseTabListContainer
          hideTab={true}
          maxTabWidth={670}
          tabs={[
            { key: EarningRuleType.allTypes, name: 'All Type' },
            { key: EarningRuleType.qrCodeScanning, name: 'QR Code' },
            { key: EarningRuleType.gpsCheckIn, name: 'GPS' },
            { key: EarningRuleType.memberReferral, name: 'Referral' },
            // { key: EarningRuleType.generalPurchase, name: 'Purchase' },
            { key: EarningRuleType.newMember, name: 'New Member' },
            { key: EarningRuleType.birthday, name: 'Birthday' },
            {
              key: EarningRuleType.weeklyAchievement,
              name: 'Weekly Achievement',
            },
            {
              key: EarningRuleType.monthlyAchievement,
              name: 'Monthly Achievement',
            },
          ]}
          groupActions={groupActions}
          // defaultTabKey={levels}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'earningRuleList'}
              permissionGroup={PermissionCodes.earningrule}
              specificActions={[
                {
                  fields: ['type'],
                  conditions: [
                    EarningRuleType.carbonImprove,
                    EarningRuleType.firstTry,
                  ],
                  actions: ['Edit'],
                },
              ]}
              deleteInfo={{
                data: [],
                title: 'earning rules',
                relatedName: 'campaigns',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.EARNING_RULE,
              }}
              actions={['Edit', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    const type = parsedSearch['type'] || '';
    const searchKey = parsedSearch['search'] || '';
    const stringRank = parsedSearch['rank'] || 'true';
    const stringPage = parsedSearch['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('earningRuleList/getEarningRuleListWithTypes')({
        ...parsedSearch,
        page,
        reverse: rank,
        search: searchKey,
        type: type, //filters.levels.join(','),
      }),
    );
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Campaign can link this rules to let customer earn points or coupons',
          title: NavBarNames.earnRules,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'earning rules'}
        relatedName={'campaigns'}
        relatedSections={DELETE_RELATED_SECTIONS.EARNING_RULE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('earningRuleList/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

export default EarningRuleList;
