import {
  getCampaignStores,
  updateCampaign,
} from '../services/CampaignAPIHelper';
import { apiWithResponseHandle } from './LoadingUtil';

export default {
  namespace: 'campaignStore',
  state: {
    allStores: [],
    selectedStores: [],
    searchKey: '',
    sortKey: '',
    loading: true,
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState(state, { payload }) {
      return {
        allStores: [],
        selectedStores: [],
        searchKey: '',
        sortKey: '',
        loading: true,
      };
    },
  },
  effects: {
    getCampaignStores: [
      function* ({ payload }, { call, select, put }) {
        const { campaignID } = payload;
        const serviceArgs = [getCampaignStores, campaignID];
        function* onSuccess(data) {
          const stores = data?.stores?.edges;
          const selectedStores = data?.campaign?.stores?.edges;
          yield put({
            type: 'updateState',
            payload: {
              allStores: stores,
              loading: false,
              selectedStores,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateCampaignStores: [
      function* ({ payload }, { call, select, put }) {
        const { campaignID, selectedStoreIds, afterAction } = payload;
        yield put({
          type: 'updateState',
          payload: {
            loading: true,
          },
        });
        const serviceArgs = [updateCampaign, {
          id: campaignID,
          stores: selectedStoreIds,
        }];

        yield apiWithResponseHandle(serviceArgs);

        yield put({
          type: 'updateState',
          payload: {
            loading: false,
          },
        });
        if (afterAction) {
          yield afterAction();
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
