import { APIStatus, CheckStatus, SavedStatus } from '../config/CustomEnums';
import {
  getAfcAccountBindingRecords,
  getOneAfcAccountBindingRecord,
  createAfcAccountBindingRecord,
  updateAfcAccountBindingRecord,
  deleteAfcAccountBindingRecord
} from '../services/AfcAccountBindingRecordAPIHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  delay,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';

import { getCampignPeriodDateTime } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';

const getInitialState = () => ({
  oneAdminGroup: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    { displayName: 'Membership ID', fieldName: 'membershipId' },
    { displayName: 'External Account Type', fieldName: 'externalAccountType' },
    { displayName: 'External Account Number', fieldName: 'externalAccountNumber' },
    { displayName: 'Status', fieldName: 'status' },
    { displayName: 'Data', fieldName: 'creationDate' },
  ],
  afcAccountBindingRecords: [],
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  checkedList: [],
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  saved: SavedStatus.init,
  formChanged: false,
  apiStatus: APIStatus.none,
  afcAccountBindingRecord: {}
});


const parseAfcAccountBindingRecord = (item) => {
  return {
    ...item,
    creationDate: getCampignPeriodDateTime(item.creationDate),
    membershipId: item.customer ? item.customer.membershipId : '',
    firstName: item.customer ? item.customer.firstName : '',
    lastName: item.customer ? item.customer.lastName : ''
  };
};


const parseAfcAccountBindingRecords = (data) => {
  return data.map((item) => parseAfcAccountBindingRecord(item.node));
};

export default {
  namespace: 'afcAccountBindingRecords',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getAfcAccountBindingRecords: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getAfcAccountBindingRecords,
          pageCursor,
          payload.reverse,
          payload.search,
          {...payload}
        ];
        function* onSuccess(data) {
          const pageInfo = data?.afcAccountBindingRecords?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor1;
          const totalCount = data?.afcAccountBindingRecords?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              afcAccountBindingRecords: parseAfcAccountBindingRecords(data?.afcAccountBindingRecords?.edges),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getOneAfcAccountBindingRecord: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });
        const id = convertPKToId('AfcAccountBindingRecordNode', payload.id);
        const serviceArgs = [getOneAfcAccountBindingRecord, id];
        console.log('@119', payload, id)
        function* onSuccess(data) {
          console.log('getOneAfcAccountBindingRecord onSuccess :', data);
          yield all([
            put({
              type: 'updateState',
              payload: { apiStatus: APIStatus.none, afcAccountBindingRecord: parseAfcAccountBindingRecord(data.afcAccountBindingRecord) },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneAfcAccountBindingRecord onError :', err);
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneAfcAccountBindingRecord arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],

  },
};
