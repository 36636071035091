import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';

function CouponSelectDropdown(props) {
  const dispatch = useDispatch();
  const title = props.title;
  const titleTips = props.titleTips || null;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const sourceShowId = props.sourceShowId || false;
  const addButtonInfo = props.addButtonInfo || {};

  const { couponTemplateList } = useSelector((state) => ({
    couponTemplateList: state.couponList.couponTemplateList,
  }));

  useEffect(() => {
    dispatch(
      createAction('couponList/getCurrentPageTemplateList')({
        isSelectorLoad: true,
      }),
    );
  }, [dispatch]);

  const onSelectChange = (value) => {
    setValue(value?.value);
    console.log('@@23: ', value);
  };
  return (
    <CustomTitleWithDropDown
      showMultiLine={props?.showMultiLine || false}
      title={title}
      titleTips={titleTips}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={couponTemplateList}
      sourceShowId={sourceShowId}
      loadMoreAction={'couponList/getCurrentPageTemplateList'}
      filterAction={'couponList/getCurrentPageTemplateList'}
      addButtonInfo={addButtonInfo}
      showRemoveButton={props?.showRemoveButton}
      onRemoveButtonClick={props?.onRemoveButtonClick}
      errors={props.errors}
      errorName={props.errorName}
      errorMessage={props.errorMessage}
    />
  );
}

export default CouponSelectDropdown;
