import { useDispatch, useSelector, dispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import BaseEditSection from '../../base/BaseEditSection';
import { createAction, getError, useCompare } from '../../../utils';
import { AppVersionErrorHandleFields } from '../../../models/AppVersionModel';
import { APIStatus } from '../../../config/CustomEnums';

function CreateAppVersion() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorFields, setErrorFields] = useState({ fields: [], messages: [] });
  const apiStatus = useSelector((state) => state.appVersion.apiStatus);
  const appversionState = useSelector((state) => state.appVersion);
  const { appVersion } = useSelector((state) => ({
    appVersion: state.appVersion.appVersion || {},
  }));

  useEffect(() => {
    dispatch({ type: 'appVersion/loadAppVersionFromCookieSession' });
  }, [dispatch]);

  useEffect(() => {
    if (apiStatus === APIStatus.success) {
      history.push('/app_version');
    }
  }, [apiStatus]);

  useEffect(() => {
    return () => {
      dispatch(createAction('appVersion/clearData')({}));
    };
  }, []);

  const [iOSVersion, setiOSVersion] = useState(appVersion.iOSVersion);
  const [androidVersion, setAndroidVersion] = useState('');
  const vals = { iOSVersion, androidVersion };
  const valsHasChanged = useCompare(vals);

  useEffect(() => {
    setiOSVersion(appVersion.iOSVersion);
    setAndroidVersion(appVersion.androidVersion);
    setTimeout(() => {
      dispatch({
        type: 'appVersion/updateState',
        payload: { formChanged: false },
      });
    }, 500);
  }, [dispatch, appVersion]);

  useEffect(() => {
    if (valsHasChanged) {
      dispatch({ type: 'appVersion/changeVals', payload: { vals } });
    }
  });

  const sections = [
    <BaseEditSection
      title="General"
      fields={[
        <CustomTitleWithInput
          title={'iOS version'}
          defaultValue={iOSVersion}
          setValue={(value) => {
            let data = value;
            data = data.replace(/[^0-9.]/g, '');
            setiOSVersion(data);
          }}
          error={getError(
            'iOSVersion',
            errorFields,
            AppVersionErrorHandleFields,
          )}
        />,

        <CustomTitleWithInput
          title={'Android version'}
          defaultValue={androidVersion}
          setValue={(value) => {
            let data = value;
            data = data.replace(/[^0-9.]/g, '');
            setAndroidVersion(data);
          }}
          error={getError(
            'androidVersion',
            errorFields,
            AppVersionErrorHandleFields,
          )}
        />,
      ]}
    />,
  ];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={(event) => {
          const fields = [];
          const messages = [];
          if (iOSVersion === '' || iOSVersion === undefined) {
            fields.push('iOSVersion');
            messages.push({
              field: 'iOSVersion',
              errorType: 'required',
            });
          }
          if (androidVersion === '' || androidVersion === undefined) {
            fields.push('androidVersion');
            messages.push({
              field: 'androidVersion',
              errorType: 'required',
            });
          }
          setErrorFields({
            fields,
            messages,
          });
          if (fields.length > 0) {
            return;
          }
          dispatch(
            createAction('appVersion/createAppversion')({
              iOSVersion: iOSVersion,
              androidVersion: androidVersion,
            }),
          );
        }}
        backAction={() => history.goBack()}
      />
    </>,
  ];
  return (
    <ScrollableComponent
      tempSave={(save) => {
        if (!save) {
          dispatch({
            type: 'appVersion/removeAppVersionFromCookie',
          });
        }
      }}
      content={stepSet}
      currentStep={0}
      breadcrumb={<CustomBreadcrumb name={'Create App Version'} />}
      caution={{
        detail: '',
        title: 'Create App Version',
        model: 'appVersion',
      }}
    />
  );
}

export default CreateAppVersion;
